import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { Router, Route, Link } from 'react-router'

var ResetPasswordSuccess = React.createClass({
  render() {
    return (
      <div className="lr-out-box">
        <div className="comment-box1">
          <div className="kb-box create-success">
            <i></i>
          </div>
          <div className="kb-box t-center">
            <span className="green-font">重置密码成功</span>
          </div>
          <div className="kb-box t-center">
            <Link className="a-blue" to="/login">
              返回登录页面
            </Link>
          </div>
        </div>
      </div>
    )
  }
})

export default connect(null, null)(ResetPasswordSuccess)
