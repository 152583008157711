import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { routerActions } from 'react-router-redux'
import { KBPopover, KBPopoverTop, KBButton } from 'components'

var KBToReportCenter = React.createClass({
  toReportCenter() {
    const { routerActions } = this.props
    KBPopoverTop.close()
    KBPopover.close()
    routerActions.push(`/admin/report`)
  },

  render() {
    const { title, message } = this.props

    return (
      <div>
        <header className="kb-form-header">
          <h2 className="header-title">{title || '报表导出'}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopoverTop.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <p>{message || '报表已成功导出,可以前往报表中心下载'}</p>
          </div>
          <div className="kb-row">
            <div className="kb-form-fr">
              <KBButton
                className="certain-btn"
                onClick={() => {
                  KBPopoverTop.close()
                  KBPopover.close()
                }}
              >
                取消
              </KBButton>
              <KBButton
                className="certain-btn"
                onClick={this.toReportCenter}
                style={{ marginLeft: 20 }}
              >
                前往报表中心
              </KBButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validate = values => {
  const errors = {}
  return errors
}

const formConfig = {
  form: 'KBToReportCenter',
  fields: [],
  validate: validate,
  touchOnBlur: false
}

KBToReportCenter = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(KBToReportCenter)

export default KBToReportCenter
