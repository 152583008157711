import React, { Component, PropTypes } from 'react'
import { Link } from 'react-router'
import { routerActions } from 'react-router-redux'
import {
  ORG_SUBSCRIPTION_STATES,
  ORG_SUBSCRIPTION_STATES_CLASS_NEW,
  SUBSCRIPTION_TYPES_OBJ
} from 'app/constants'
import { KBTipsy } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import ContractNoPassForm from './ContractNoPassForm'
import { KBPopover } from 'components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { subscriptionsAction, successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { fmoney, getTaxAmountOfContract, formatYearDaySOL } from 'utils/kbUtil'
import moment from 'moment'
import { getUrlOfOrgDetailPage } from 'utils/kbUrl'
import {
  canAdminFinance
} from 'app/reducers/role'

var InvoicesContractBlock = React.createClass({
  linkSubscriptionsDetail(m) {
    const { routerActions, loc_id, from } = this.props

    if (from == 'crm') {
      return routerActions.push(
        `/admin/crm/winsubscription/1/organizations/${m.organization_id}/subscriptions/${m.id}?typeSource=crmrecord`
      )
    }

    if (loc_id) {
      return routerActions.push(
        `/admin/locations/${loc_id}/organizations/${m.organization_id}/subscriptions/${m.id}`
      )
    }

    routerActions.push(
      `/admin/invoices/1/organizations/${m.organization_id}/subscriptions/${m.id}`
    )
  },
  setSubscriptionSuccess(id) {
    const { updateSubscriptionsAction, successAction } = this.props

    KBPopoverConfirm({
      name: '合同审批',
      context: '是否通过该合同!',
      callback: () => {
        return api.updateSubScriptionApprove(id, { pass: true }).then(
          json => {
            successAction({ message: '合同审批通过操作成功' })
            updateSubscriptionsAction.success(json)
            KBPopover.close()
          },
          errors => {
            updateSubscriptionsAction.failure(errors)
          }
        )
      }
    })
  },

  deleteSubscription(m) {
    let id = m.id
    if (!id) {
      return
    }
    const {
      deleteSubscriptionsAction,
      deleteCallback,
      successAction
    } = this.props
    KBPopover.plugins.confirm('删除合同', '是否要删除合同' + m.serial, {
      confirm: () => {
        api.deleteSubscription(id).then(
          json => {
            deleteSubscriptionsAction.success(json, {
              id,
              key: 'subscriptions'
            })
            successAction({
              message: '删除合同成功'
            })
            deleteCallback && deleteCallback(m)
            KBPopover.close()
          },
          errors => {
            deleteSubscriptionsAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  openAudit(subscription) {
    const { routerActions } = this.props
    const { id, location_id, organization_id } = subscription

    routerActions.push(
      `/admin/crm/opportunities/sub/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=audit&typeSource=crmrecord`
    )
    // routerActions.push(`/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new`)
  },

  setSubscriptionFailure(id) {
    KBPopover.show(<ContractNoPassForm id={id} />)
  },
  toOpportunity(id, e) {
    e.stopPropagation()
    e.preventDefault()
    const { routerActions } = this.props
    routerActions.push(`/admin/crm/opportunities/${id}`)
  },
  render() {
    const { subscription, isContractSetting, from, loc_id, isNoAdmin } = this.props
    const stateType_class = ORG_SUBSCRIPTION_STATES_CLASS_NEW
    const stateType = ORG_SUBSCRIPTION_STATES
    // if(subscription.status == 'approving' && subscription.step == 'detail'){
    //   subscription.status = 'waiting_approving'
    // }else if(subscription.status == 'approving' && subscription.step == 'audit') {
    //   subscription.status = 'already_approving'
    // }
    let contract_status =
      subscription.status == 'expiring' ? 'active' : subscription.status
    let isHaveNot =
      subscription.status == 'active' &&
      moment(subscription.start_date).isAfter(new Date())
        ? true
        : false
    let taxAmount = getTaxAmountOfContract(
      subscription.total_amount,
      subscription.tax_rate
    )
    let rentAmountWithoutTax = subscription.total_amount - taxAmount
    return (
      <tr
        key={subscription.id}
        onClick={this.linkSubscriptionsDetail.bind(null, subscription)}
      >
        <td onClick={e => e.stopPropagation()}>
          <Link
            className="color-link"
            to={getUrlOfOrgDetailPage(
              subscription.location_id,
              subscription.sales_customer.foreign_id
            )}
          >
            {subscription.sales_customer.name}
          </Link>
        </td>
        {from == 'crm' ? (
          <td
            onClick={this.toOpportunity.bind(null, subscription.opportunity.id)}
          >
            {subscription.opportunity.title}
          </td>
        ) : null}
        <td className="a-hover">
          <KBTipsy content={subscription.serial}>
            <span className="contract-serial color-link">
              {subscription.serial}
            </span>
          </KBTipsy>
        </td>
        <td>{SUBSCRIPTION_TYPES_OBJ[subscription.subscription_type] || '-'}</td>
        <td style={{ width: 70 }}>
          <span
            style={{ marginLeft: 0, whiteSpace: 'nowrap' }}
            className={`finance-default ${stateType_class[contract_status]}`}
          >
            {stateType[contract_status]}
          </span>
        </td>
        {from == 'crm' ? (
          <td>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{formatYearDaySOL(subscription.start_date)}</span>
              </div>
            </div>
          </td>
        ) : (
          <td>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{formatYearDaySOL(subscription.start_date)}</span>-
                <span>{formatYearDaySOL(subscription.expire_date)}</span>
              </div>
              {subscription.status == 'expiring' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 5
                  }}
                >
                  <img
                    src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon-expiring.png"
                    alt="icon"
                    style={{ width: 15 }}
                  />
                  <span
                    style={{
                      marginLeft: 5,
                      color: '#dd5a55',
                      fontSize: 12,
                      marginTop: 1
                    }}
                  >
                    快到期
                  </span>
                </div>
              ) : isHaveNot ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 5
                  }}
                >
                  <img
                    src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon-havenot.png"
                    alt="icon"
                    style={{ width: 15 }}
                  />
                  <span
                    style={{
                      marginLeft: 5,
                      color: '#2ea1f8',
                      fontSize: 12,
                      marginTop: 1
                    }}
                  >
                    未开始
                  </span>
                </div>
              ) : null}
            </div>
          </td>
        )}
        {from == 'crm' ? (
          <td>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{formatYearDaySOL(subscription.expire_date)}</span>
              </div>
              {subscription.status == 'expiring' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 5
                  }}
                >
                  <img
                    src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon-expiring.png"
                    alt="icon"
                    style={{ width: 15 }}
                  />
                  <span
                    style={{
                      marginLeft: 5,
                      color: '#dd5a55',
                      fontSize: 12,
                      marginTop: 1
                    }}
                  >
                    快到期
                  </span>
                </div>
              ) : isHaveNot ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 5
                  }}
                >
                  <img
                    src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon-havenot.png"
                    alt="icon"
                    style={{ width: 15 }}
                  />
                  <span
                    style={{
                      marginLeft: 5,
                      color: '#2ea1f8',
                      fontSize: 12,
                      marginTop: 1
                    }}
                  >
                    未开始
                  </span>
                </div>
              ) : null}
            </div>
          </td>
        ) : null}
        <td>{subscription.units}</td>
        <td>{subscription.units - subscription.remain_units}</td>
        <td>¥&nbsp;{fmoney(subscription.total_amount || 0)}</td>
        <td>¥&nbsp;{fmoney(subscription.deposit || 0)}</td>
        <td>¥&nbsp;{fmoney(taxAmount)}</td>
        <td>¥&nbsp;{fmoney(rentAmountWithoutTax)}</td>
        {from == 'crm' ? (
          <td>
            {(subscription.status === 'approving' ||
            subscription.status === 'rejected') && isNoAdmin ? (
              <div style={{ whiteSpace: 'nowrap' }}>
                <button
                  className="certain-btn"
                  style={{ padding: '5px 8px' }}
                  onClick={e => {
                    this.openAudit(subscription)
                    e.stopPropagation()
                  }}
                >
                  进入审批流程
                </button>
              </div>
            ) : null}
          </td>
        ) : (
          <td className="t-right">
            {(subscription.status == 'approving' ||
              subscription.status == 'rejected') &&
            isContractSetting && isNoAdmin ? (
              <div style={{ whiteSpace: 'nowrap' }}>
                <button
                  className="certain-btn"
                  style={{ padding: '5px 8px' }}
                  onClick={e => {
                    this.openAudit(subscription)
                    e.stopPropagation()
                  }}
                >
                  进入审批流程
                </button>
              </div>
            ) : null}
          </td>
        )}
        <td>
          {(subscription.status == 'approving' ||
          subscription.status == 'draft' ||
          subscription.status == 'rejected')&& isNoAdmin ? (
            <i
              className="iconfont icon-delete"
              onClick={e => {
                e.stopPropagation()
                this.deleteSubscription(subscription)
              }}
            />
          ) : null}
        </td>
      </tr>
    )
  }
})

InvoicesContractBlock.PropTypes = {
  subscription: PropTypes.object.isRequired,
  loc_id: PropTypes.number.isRequired
}

function mapStateToProps(state, props) {
  const { location, loc_id } = props
  const { user } = state
  const isNoAdmin = canAdminFinance(user, loc_id)
  return { isNoAdmin }
}

function mapDispatchToProps(dispatch) {
  return {
    getSubscriptionsAction: bindActionCreators(
      subscriptionsAction.get,
      dispatch
    ),
    updateSubscriptionsAction: bindActionCreators(
      subscriptionsAction.update,
      dispatch
    ),
    deleteSubscriptionsAction: bindActionCreators(
      subscriptionsAction.delete,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesContractBlock)
