import React, { Component, PropTypes } from 'react'

var KBButton = React.createClass({
  render() {
    const { submitting, className, style, type, onClick, disabled, ...rest } = this.props

    if(type === 'clear') {
      // 搜索列表中，清空搜索条件样式的按钮
      return (
        <div className="clear-criteria" style={{marginLeft: '20px', marginTop: 0}} onClick={onClick}>
          <i className="iconfont icon-close" />
          <span>清除所有筛选条件</span>
        </div>
      )
    }

    return submitting ? (
      <button
        disabled={true}
        type={type}
        className={`${className} certain-btn-disabled`}
        style={style}
      >
        {this.props.children}中
      </button>
    ) : (
      <button
        disabled={disabled}
        type={type}
        className={`${className}${disabled ? ' certain-btn-disabled' : ''}`}
        style={style}
        onClick={onClick}
      >
        {this.props.children}
      </button>
    )
  }
})

export default KBButton
