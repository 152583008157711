import {
  canAccessIsRoles,
  canAccessLocation,
  canAdminLocation,
  canAdminSpace,
  canAccessLocationOfRoles,
  canAccessSpaceFinance,
  canAccessSpaceCRM,
  canAccessSpaceRepair,
  canAccessSpaceFaq,
  canAccessSpaceAPP,
  canAccessSpaceStatistic,
  canAccessSpaceEnterprise,
  canAccessSpaceHr,
  canAccessSpaceShop,
  canAccessSpaceTaskFeedback,
  canAccessSpaceRoles,
  helpDesk,
  canAdminLocationActivity,
  isGroupContentManager,
  canAccessLandlord
} from 'app/reducers/role'

var LoginRedirect = {
  checkAccess(user = {}) {
    const { routerActions, apiCallFailureActions } = this.props
    let roles = canAccessIsRoles(user)

    if (!user.id) {
      routerActions.replace('/login')
      return
    }

    if (roles.length <= 0) {
      apiCallFailureActions({
        status: 422,
        message: '您暂时没有权限'
      })
      return false
    }

    if (canAccessLocationOfRoles(user)) {
      this.toLocation()
      return false
    }



    // if(){
    //   return this.toInvoices()
    // }

    return this.checkOtherRouter(user)
  },

  checkOtherRouter(user) {
    if (canAccessSpaceFinance(user)|| canAccessSpaceRoles(user,['invoice_check', 'invoice_confirm', 'invoice_check_notify'])) {
      return this.toInvoices()
    }

    if (canAccessSpaceCRM(user)) {
      return this.toCRM()
    }

    if (canAccessSpaceTaskFeedback(user)) {
      return this.toTaskFeedback()
    }

    if (canAccessSpaceRepair(user)) {
      return this.toTask()
    }

    if (canAccessSpaceFaq(user)) {
      return this.toFaq()
    }

    if (canAccessSpaceAPP(user)) {
      return this.toApp()
    }

    if (canAccessSpaceStatistic(user)) {
      return this.toStatistic()
    }

    if (canAccessSpaceEnterprise(user)) {
      return this.toEnterprise()
    }

    if (canAccessSpaceHr(user)) {
      return this.toHr()
    }

    if (canAccessSpaceShop(user)) {
      return this.toShop()
    }

    if(helpDesk(user)){
      this.toHr()
      return false
    }

    if(canAdminLocationActivity(user)){
      this.toLocation()
      return false
    }

    if(canAccessLandlord(user)) {
      this.toLocation()
      return false
    }

    if(isGroupContentManager(user, '', false, '/admin/locations')) {
      this.toLocation()
      return false
    }

    return false
  },

  toLocation() {
    const { routerActions } = this.props
    routerActions.replace('/admin/locations')
  },

  toInvoices() {
    const { routerActions } = this.props
    routerActions.replace('/admin/invoices')
  },

  toTask() {
    const { routerActions } = this.props
    routerActions.replace('/admin/tasks/repair/type/my')
  },

  toTaskFeedback() {
    const { routerActions } = this.props
    routerActions.replace('/admin/tasks/feedback')
  },

  toCRM() {
    const { routerActions } = this.props
    routerActions.replace('/admin/crm')
  },

  toFaq() {
    const { routerActions } = this.props
    routerActions.replace('/admin/faq')
  },

  toApp() {
    const { routerActions } = this.props
    routerActions.replace('/admin/faq/application')
  },

  toStatistic() {
    const { routerActions } = this.props
    routerActions.replace('/admin/statistic')
  },

  toEnterprise() {
    const { routerActions } = this.props
    routerActions.replace('/admin/service')
  },

  toHr() {
    const { routerActions } = this.props
    routerActions.replace('/admin/employee')
  },

  toShop() {
    const { routerActions } = this.props
    routerActions.replace('/admin/shopping')
  }
}

export default LoginRedirect
