import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'
import _ from 'lodash'

// 0可以是默认值
const getDefaultVal = (value, defaultValue) => {
  if(value === 0) return value
  return value || defaultValue
}

var KBInput = React.createClass({
  getInitialState() {
    const { field={}, value } = this.props
    return {
      inputValue: getDefaultVal(field.value, value)
    }
  },

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.field, this.props.field)) {
      this.setState({
        inputValue: getDefaultVal(nextProps.field.value, nextProps.value)
      })
    }
  },

  render() {
    const {
      field,
      placeholder,
      type,
      title,
      className,
      titleClass,
      inputClass,
      label,
      isRequired,
      isRequiredR,
      hint,
      disabled,
      inputStyle,
      errorStyle,
      onChange,
      value,
      fistFoucs,
      style,
      inputContainerStyle,
      maxLength
    } = this.props
    const { inputValue } = this.state
    const inputChange = onChange || field.onChange
    let fieldName = field.name

    return (
      <div className={className} style={style}>
        {title && placeholder && !label && (
          <span className={titleClass}>{title}</span>
        )}
        {title && (
          <label className={titleClass}>
            {isRequiredR ? <span className="must-fillR">*</span> : ''}
            {title}
            {isRequired ? <span className="must-fill">*</span> : ''}
          </label>
        )}
        <div style={inputContainerStyle}>
          <input
            className={classNames(
              inputClass,
              field.touched && field.error ? 'kb-input-error' : 'kb-input'
            )}
            style={inputStyle}
            disabled={disabled ? 'disabled' : ''}
            type={type}
            placeholder={placeholder}
            value={(inputValue===null||inputValue===undefined)?'':inputValue}
            autoFocus={fistFoucs ? 'autofocus' : false}
            onChange={inputChange}
            maxLength={maxLength}
          />
          {field.touched && field.error ? (
            <p className="lr-error" style={errorStyle}>
              {field.error}
            </p>
          ) : (
            <p style={{ color: '#c8c8c8', fontSize: 12, padding: '4px 0 0 0' }}>
              {hint}
            </p>
          )}
        </div>
      </div>
    )
  }
})

KBInput.propTypes = {
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string
}

KBInput.defaultProps = {
  type: 'text',
  className: 'kb-box'
}

export default KBInput
