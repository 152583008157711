import React, { Component } from 'react'
import { Select } from 'antd'

// 基于antd
export default React.createClass({
  getInitialState() {
    return {

    }
  },
  onChange(val) {
    const { onChange, field } = this.props
    if(onChange) {
      onChange(val)
    } else {
      field.onChange(val)
    }
  },
  render() {
    const { label, options = [], isRequired, field, hint, ...restProps } = this.props
    return (
      <div className="kb-form-group kb-form-alone kb-input">
        <label>
          {label}
          {
            isRequired && <span className="must-fill">*</span>
          }
        </label>
        <Select value={field.value || undefined} onChange={this.onChange} {...restProps}>
          {options.map(({ label, value }) => {
            return (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            )
          })}
        </Select>

        {/* 提示文字 */}
        {hint && (
          <p
            style={{
              color: '#c8c8c8',
              fontSize: 12,
              padding: '2px 0 0 11px'
            }}
          >
            {hint}
          </p>
        )}

        {/* 错误文字 */}
        {field.error && field.touched && (
          <p className="lr-error">{field.error}</p>
        )}
      </div>
    )
  }
})
