import React, { Component, PropTypes } from 'react'
import ReactDOM from 'react-dom'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { fmoney, arrayToObject } from 'utils/kbUtil'
import { invoicesActions } from 'app/actions'
import { AA_PAYMENT_INFORMATION } from 'app/constants/hint'
import {
  INVOICE_CERTIFICATE_PAYMENT_TYPES,
  INVOICE_SALES_PAYMENTS,
  LINE_ITEM_TYPES_OBJ
} from 'app/constants'
import {
  KBInput,
  KBTextarea,
  KBFormError,
  KBButton,
  KBPopover,
  KBImageCropperInput,
  KBDateInput,
  KBCheckBox,
} from 'components'
import InvoiceCertPhotoUpload from './InvoiceCertPhotoUpload'
import moment from 'moment'

var InvoiceCertificationForm = React.createClass({
  getInitialState() {
    return {
      payment_type: '',
      remaining: 0
    }
  },

  componentDidMount() {
    const {
      fields: { payment_time, amount, total_left_amount },
      invoice
    } = this.props

    let total_amount = invoice && parseFloat(invoice.total_amount).toFixed(2)
    total_amount = isNaN(total_amount) ? 0 : total_amount

    let paid_amount = invoice && parseFloat(invoice.paid_amount).toFixed(2)
    paid_amount = isNaN(paid_amount) ? 0 : paid_amount

    const remaining = parseFloat(total_amount - paid_amount).toFixed(2)

    let today = moment(new Date()).format('YYYY-MM-DD')
    payment_time.onChange(today)
    total_left_amount.onChange(remaining)
    this.setState({
      remaining
    })
  },

  createInvoiceCertForm(model) {
    // 此处调用 api 创建凭证
    const { invoice } = this.props

    if (!model.vouchers) {
      delete model.vouchers
    }

    delete model.total_left_amount
    model.line_items_amount &&
      model.line_items_amount.length > 0 &&
      model.line_items_amount.map(l_item => {
        delete l_item.left_amount
        return l_item
      })

    return api.putManualPay(invoice.id, model).then(
      json => {
        this.props.updateInvoicesActions.success(json)
        return true
      },
      error => {
        this.props.updateInvoicesActions.failure(error)
      }
    )
  },

  saveAndClose() {
    setTimeout(KBPopover.close, 100)
  },

  saveAndUpload() {
    const { showSelf } = this.props
    setTimeout(() => {
      KBPopover.close()
      showSelf()
    }, 100)
  },

  lineItemAmountChange(e, i) {
    const value = e.target.value
    const numberedValue = parseFloat(value || 0)
    const {
      fields: { line_items_amount, amount }
    } = this.props
    let currentTotalAmount = 0
    line_items_amount.map((line_item, index) => {
      if (i == index) {
        line_item.paid_amount.onChange(value)
        currentTotalAmount += numberedValue
        return null
      }
      currentTotalAmount += parseFloat(line_item.paid_amount.value || 0)
    })
    amount.onChange(currentTotalAmount.toFixed(2))
  },

  totalAmountChange(e) {
    const value = e.target.value
    let numberedValue = parseFloat(value || 0)
    const {
      fields: { line_items_amount, amount },
      line_items
    } = this.props
    amount.onChange(value)
    line_items &&
      line_items.length > 0 &&
      line_items.map((item, index) => {
        const leftAmount = parseFloat(item.total_amount - item.paid_amount)
        if (numberedValue >= leftAmount) {
          line_items_amount[index].paid_amount.onChange(leftAmount.toFixed(2))
          numberedValue -= leftAmount
          return null
        }
        line_items_amount[index].paid_amount.onChange(numberedValue.toFixed(2))
        numberedValue = 0
      })
  },

  selectPaymentType(type) {
    const {
      fields: { payment_mode }
    } = this.props

    payment_mode.onChange(type)
    this.setState({
      payment_type: type
    })
  },

  fillAllLineItems(total_left) {
    const {
      fields: { line_items_amount, amount },
      line_items
    } = this.props
    line_items.length > 0 &&
      line_items.map((item, index) => {
        if (item.paid_amount == item.total_amount) {
          return null
        }
        const leftAmount = parseFloat(item.total_amount - item.paid_amount)
        line_items_amount[index].paid_amount.onChange(leftAmount.toFixed(2))
      })
    amount.onChange(total_left || 0)
  },

  render() {
    const {
      fields: {
        amount,
        payment_mode,
        payment_time,
        vouchers,
        notes,
        line_items_amount
      },
      error,
      submitting,
      handleSubmit,
      lineItemsObj
    } = this.props
    const { payment_type, remaining } = this.state

    return (
      <form onSubmit={handleSubmit(this.createInvoiceCertForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">上传付款凭证</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError err={error} />
        <div className="kb-form-container">
          <div className="invoice-certification-form">
            <div className="kb-row kb-form-66">
              <KBInput
                className="kb-form-group kb-div-48"
                isRequired={true}
                title="金额"
                inputClass={`kb-input input-dollar ${
                  remaining < 0 ? 'kb-input-disabled' : ''
                }`}
                disabled={remaining < 0}
                fistFoucs={true}
                field={amount}
                onChange={this.totalAmountChange}
                type="number"
                hint={
                  remaining < 0
                    ? '退款账单只支持一次性支付，点击右侧“剩余金额”按钮可快速填充支付金额'
                    : ''
                }
              />
              <div className="kb-form-group kb-div-c18">
                <label>&nbsp;</label>
                <div>
                  <span
                    className="money-left"
                    onClick={
                      remaining < 0
                        ? this.fillAllLineItems.bind(null, remaining)
                        : this.totalAmountChange.bind(null, {
                            target: { value: remaining }
                          })
                    }
                  >
                    <span style={{ fontWeight: 'normal' }}>全部剩余金额</span>
                    （¥{fmoney(remaining, 2)}）
                  </span>
                </div>
              </div>
            </div>
            <div className="kb-row">
              <KBDateInput
                type="date"
                title="实际付款日期"
                isRequired={true}
                className="kb-form-group kb-form-210 kb-form-alone"
                field={payment_time}
              />
            </div>
            <fieldset className="fieldset">
              <legend
                className="m-none"
                style={{ paddingTop: 20, fontSize: 16 }}
              >
                付款款项分配
              </legend>
              <table className="content-table">
                <thead>
                  <tr>
                    <th>款项</th>
                    <th>应付金额</th>
                    <th>已付金额</th>
                    <th>待付金额</th>
                    <th style={{ width: 165 }}>本次支付</th>
                  </tr>
                </thead>
                <tbody>
                  {line_items_amount &&
                    line_items_amount.length > 0 &&
                    line_items_amount.map((item, index) => {
                      if (!item.line_item_id.value) {
                        return null
                      }
                      const {
                        description,
                        total_amount,
                        paid_amount,
                        line_item_type
                      } = lineItemsObj[item.line_item_id.value]
                      const left_amount = fmoney(item.left_amount.value || 0, 2)
                      const isRefundItem = parseFloat(total_amount || 0) < 0
                      return (
                        <tr>
                          <td>
                            {(description && description.split('(')[0]) ||
                              (line_item_type &&
                                LINE_ITEM_TYPES_OBJ[line_item_type]) ||
                                ''}
                          </td>
                          <td>¥ {fmoney(total_amount || 0, 2)}</td>
                          <td>¥ {fmoney(paid_amount || 0, 2)}</td>
                          <td>¥ {left_amount}</td>
                          <td style={{ width: 140 }}>
                            {left_amount == 0 ? (
                              <div
                                style={{
                                  width: '100%',
                                  height: 36,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  border: '1px solid #CED0DA',
                                  color: '#ACB7C2',
                                  backgroundColor: '#F8FAFC',
                                  borderRadius: 4
                                }}
                              >
                                款项已结清
                              </div>
                            ) : (
                              <KBInput
                                className="kb-form-group kb-div-100"
                                inputClass={`input-dollar ${
                                  isRefundItem ? 'kb-input-disabled' : ''
                                }`}
                                disabled={isRefundItem}
                                field={item.paid_amount}
                                onChange={e => {
                                  this.lineItemAmountChange(e, index)
                                }}
                                type="number"
                              />
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </fieldset>

            <fieldset className="fieldset">
              <legend
                className="m-none"
                style={{ paddingTop: 20, fontSize: 16 }}
              >
                支付信息
              </legend>
              <div className="kb-row">
                <div className="kb-form-group kb-div-210 kb-form-alone">
                  <label>
                    付款方式
                    <span className="must-fill">*</span>
                  </label>
                  <div>
                    {INVOICE_CERTIFICATE_PAYMENT_TYPES &&
                      INVOICE_CERTIFICATE_PAYMENT_TYPES.map((type, index) => {
                        return (
                          <span
                            key={index}
                            onClick={this.selectPaymentType.bind(null, type.id)}
                            className={`payment-type-choice ${
                              payment_type == type.id
                                ? 'payment-type-choice-active'
                                : ''
                            }`}
                          >
                            {INVOICE_SALES_PAYMENTS[type.id]}
                          </span>
                        )
                      })}
                    {payment_mode.touched && payment_mode.error ? (
                      <p className="lr-error">{payment_mode.error}</p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className="kb-row">
                <KBTextarea
                  className="kb-form-group kb-form-210 kb-form-alone"
                  field={notes}
                  title="备注"
                />
              </div>
              <div className="kb-row">
                <InvoiceCertPhotoUpload
                  isRequired={false}
                  title="上传凭证"
                  hint="上传付款扫描件或照片，不大于10M"
                  multiple={true}
                  photoShow={true}
                  preView={false}
                  className="kb-form-group kb-form-210 kb-form-alone"
                  field={vouchers}
                  prefix="images/invoice/"
                />
              </div>
            </fieldset>
          </div>

          <div className="kb-row fixed-form-btn">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
              onClick={
                amount.error ||
                payment_mode.error ||
                vouchers.error ||
                line_items_amount.find(li => li.paid_amount.error)
                  ? null
                  : this.saveAndClose
              }
            >
              保存并关闭
            </KBButton>
            {/* <KBButton className="certain-btn" type="submit" submitting = { submitting } onClick={ amount.error || payment_mode.error || vouchers.error || line_items_amount.find(li => li.paid_amount.error) ? null : this.saveAndUpload }>保存并继续上传</KBButton> */}
          </div>
        </div>
      </form>
    )
  }
})

InvoiceCertificationForm.PropTypes = {
  invoice: PropTypes.object.isRequired
}

function mapStateToProps(state, ownProps) {
  const {
    invoice: { line_items }
  } = ownProps
  const lineItemsObj = arrayToObject(line_items || [])

  return {
    line_items,
    lineItemsObj,
    initialValues: {
      line_items_amount:
        (line_items &&
          line_items.length > 0 &&
          line_items.map(item => {
            let newItem = {}
            let leftAmount =
              parseFloat(item.total_amount || 0) -
              parseFloat(item.paid_amount || 0)
            newItem.line_item_id = item.id
            newItem.left_amount = leftAmount.toFixed(2) || 0
            newItem.paid_amount = 0
            return newItem
          })) ||
        []
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getInvoicesActions: bindActionCreators(invoicesActions.get, dispatch),
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch)
  }
}

const validateLineItems = line_item => {
  const errors = {}
  //   if(line_item.paid_amount && parseFloat(line_item.paid_amount) < 0) {
  //     errors.paid_amount = '支付金额不能小于0'
  //   }

  if (line_item.paid_amount) {
    if (parseFloat(line_item.paid_amount) > 0) {
      if (
        parseFloat(line_item.paid_amount) > parseFloat(line_item.left_amount)
      ) {
        errors.paid_amount = '支付金额不能超过待付金额'
      }
    } else if (parseFloat(line_item.paid_amount) < 0) {
      if (
        parseFloat(line_item.paid_amount) < parseFloat(line_item.left_amount)
      ) {
        errors.paid_amount = '支付金额不能超过待付金额'
      }
    }
  }

  return errors
}

const validate = values => {
  const errors = {}

  if (valid.required(values.amount)) {
    errors.amount = '请输入金额'
  }

  //   if (values.amount && parseFloat(values.amount) < 0) {
  //     errors.amount = '请输入不小于0的金额数目'
  //   }

  if (values.amount) {
    if (parseFloat(values.amount) > 0) {
      if (parseFloat(values.amount) > parseFloat(values.total_left_amount)) {
        errors.amount = '输入的金额不能超过全部剩余金额'
      }
    } else if (parseFloat(values.amount) < 0) {
      if (parseFloat(values.amount) < parseFloat(values.total_left_amount)) {
        errors.amount = '输入的金额不能超过全部剩余金额'
      }
    }
  }

  if (valid.required(values.payment_time)) {
    errors.payment_time = '请选择实际付款日期'
  }

  if (valid.required(values.payment_mode) || values.payment_mode == '-1') {
    errors.payment_mode = '请选择付款方式'
  }

  errors.line_items_amount = values.line_items_amount.map(validateLineItems)

  // if (valid.required(values.vouchers)) {
  //   errors.vouchers = '请上传凭证图片'
  // }

  return errors
}

const formConfig = {
  form: 'InvoiceCertificationForm',
  fields: [
    'amount',
    'total_left_amount',
    'payment_mode',
    'payment_time',
    'vouchers',
    'notes',
    'line_items_amount[].line_item_id',
    'line_items_amount[].paid_amount',
    'line_items_amount[].left_amount'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoiceCertificationForm)
