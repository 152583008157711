import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { invoicesActions, taxesAction, successState } from 'app/actions'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput,
  KBTextarea,
  KBSelect,
  KBRadio
} from 'components'
import { routerActions } from 'react-router-redux'
import { toPercent } from 'utils/kbUtil'
import { getTaxes } from 'app/selectors'
import { LINE_ITEM_TYPES, POINT_TYPES } from 'app/constants'
import _ from 'lodash'

var OrderForm = React.createClass({
  getInitialState() {
    return {
      billType: []
    }
  },
  componentDidMount() {
    const { getTaxesAction } = this.props
    api.getSelfDefinedLineItemTypes().then(
      ({ json }) => {
        let NEW_LINE_ITEM_TYPES = JSON.parse(JSON.stringify(LINE_ITEM_TYPES))
        NEW_LINE_ITEM_TYPES.splice(NEW_LINE_ITEM_TYPES.length - 2, 0, ...json)
        this.setState({
          billType: NEW_LINE_ITEM_TYPES
        })
      },
      error => {}
    )
    api.getSpaceTaxes().then(
      json => {
        getTaxesAction.success(json)
      },
      error => {
        getTaxesAction.failure(error)
      }
    )
  },

  updateInvoicesAdjust(model) {
    const {
      updateInvoicesActions,
      invoice_id,
      successActions,
      contract_id
    } = this.props
    const { billType } = this.state

    for (let i = 0; i < model.line_items.length; i++) {
      for (let j = 0; j < billType.length; j++) {
        if (
          model.line_items[i].line_item_type == billType[j].id &&
          billType[j].charge_code
        ) {
          model.line_items[i].line_item_type = 'self_defined'
          model.line_items[i].self_defined_line_item_type_id = billType[j].id
        }
      }
    }

    if (contract_id) {
      model.line_items.map(item => {
        item.sales_subscription_id = contract_id
        return item
      })
    }

    return api.updateInvoicesAdjust(invoice_id, model).then(json => {
      updateInvoicesActions.success(json)
      successActions({ message: '添加账款成功！' })
      KBPopover.close()
      return true
    })
  },

  goCustom() {
    const { routerActions } = this.props
    routerActions.replace('/admin/spaces_setting/invoice')
    KBPopover.close()
  },

  render() {
    const {
      fields: { line_items },
      error,
      submitting,
      handleSubmit,
      taxes
    } = this.props
    const { billType } = this.state
    // const newTaxes = taxes.filter(taxe=>taxe.rate===0 || taxe.rate === 0.05 || taxe.rate === 0.06 || taxe.rate === 0.09)
    const newTaxes = [...(taxes || [])].sort((a,b) => a.rate - b.rate)
    return (
      <form onSubmit={handleSubmit(this.updateInvoicesAdjust)}>
        <header className="kb-form-header">
          <h2 className="header-title">添加账款</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <div className="form-content">
              <header className="kb-nav-header">
                <div className="region-title">
                  <i className="iconfont icon-adjust" />
                  <span>账款项</span>
                </div>
                <div className="kb-nav-line pact-line">
                  <div style={{ width: '480px' }}></div>
                </div>
              </header>
              {line_items && line_items.length > 0 ? (
                <ul className="region-input-box">
                  {line_items &&
                    line_items.map((line, index) => {
                      return (
                        <li key={index}>
                          <div className="kb-row">
                            {line_items.length > 1 ? (
                              <i
                                className="iconfont icon-close"
                                onClick={() => line_items.removeField(index)}
                              />
                            ) : null}
                            <div className="kb-row kb-form-66">
                              <div className="kb-form-group kb-div-c39">
                                <label style={{ position: 'relative' }}>
                                  单价
                                  <span
                                    style={{
                                      color: '#F62D51',
                                      position: 'absolute',
                                      top: 3,
                                      left: '-8px'
                                    }}
                                  >
                                    *
                                  </span>
                                </label>
                                <div>
                                  <input type="text" {...line.unit_price} />
                                  {line.unit_price.touched &&
                                    line.unit_price.error && (
                                      <p className="lr-error">
                                        {' '}
                                        {line.unit_price.error}{' '}
                                      </p>
                                    )}
                                </div>
                              </div>
                              <div className="kb-form-group kb-div-c39">
                                <label
                                  style={{ position: 'relative' }}
                                  className="label-rt"
                                >
                                  数量
                                  <span
                                    style={{
                                      color: '#F62D51',
                                      position: 'absolute',
                                      top: 3,
                                      left: '-8px'
                                    }}
                                  >
                                    *
                                  </span>
                                </label>
                                <div>
                                  <input type="number" {...line.units} />
                                  {line.units.touched && line.units.error && (
                                    <p className="lr-error">
                                      {' '}
                                      {line.units.error}{' '}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="kb-row">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <KBSelect
                                  style={{ width: '80%' }}
                                  isRequired={true}
                                  defaultValue="-1"
                                  field={line.line_item_type}
                                  arrayData={billType}
                                  title="类型"
                                  className="kb-form-group kb-form-c18 kb-form-alone"
                                />
                                <span
                                  style={{
                                    width: '15%',
                                    height: '34px',
                                    marginTop: '30px'
                                  }}
                                  className="cancel-btn"
                                  onClick={this.goCustom}
                                >
                                  自定义
                                </span>
                              </div>
                            </div>
                            {line.line_item_type.value &&
                            line.line_item_type.value == 'renting_fee' ? (
                              <div className="kb-row">
                                <KBRadio
                                  isRequired={true}
                                  className="kb-form-group kb-form-c18 kb-form-alone"
                                  rightLabel="label-rt"
                                  title="租赁内容"
                                  arrayType={POINT_TYPES}
                                  inClassName="kb-radio-box"
                                  divClassName="kb-form-group kb-div-48"
                                  itemStyle={{ width: '80px' }}
                                  field={line.charge_code}
                                />
                              </div>
                            ) : null}
                            <div className="kb-row">
                              <KBSelect
                                isRequired={true}
                                defaultValue="-1"
                                field={line.sales_tax_id}
                                arrayData={newTaxes}
                                title="税率"
                                className="kb-form-group kb-form-c18 kb-form-alone"
                              />
                            </div>
                            <div className="kb-row">
                              <KBTextarea
                                field={line.description}
                                title="说明"
                                isRequired={true}
                                className="kb-form-group kb-form-c18 kb-form-alone"
                              />
                            </div>
                          </div>
                        </li>
                      )
                    })}
                </ul>
              ) : null}
              <div className="f-left">
                <button
                  className="bordered-btn"
                  style={{ marginLeft: 0 }}
                  onClick={() => {
                    line_items.addField()
                  }}
                  type="button"
                >
                  <i className="iconfont icon-add" />
                  账款
                </button>
              </div>
            </div>
          </fieldset>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

OrderForm.propTypes = {
  invoice_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
}

function mapStateToProps(state, props) {
  let taxes = Object.assign([], getTaxes(state))
  taxes = _.cloneDeep(taxes).map(tax => {
    let name = tax.name
    tax.name = `${name} (税率:${toPercent(tax.rate, 2)})`
    return tax
  })

  return {
    taxes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch),
    getTaxesAction: bindActionCreators(taxesAction.get, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validateLine = line => {
  const errors = {}
  if (valid.isNumber(line.unit_price)) {
    errors.unit_price = '请输入正确的价格, 小数点保留2位'
  }

  if (valid.required(line.units) || line.units == 0) {
    errors.units = '请输入数量并且不等于0'
  }
  if (valid.required(line.line_item_type)) {
    errors.line_item_type = '请选择类型'
  }

  if (!line.charge_code && line.line_item_type == 'renting_fee') {
    errors.charge_code = '请选择租赁内容'
  }

  if (!line.sales_tax_id || line.sales_tax_id == -1) {
    errors.sales_tax_id = '请选择税率'
  }

  if(valid.required(line.description)){
    errors.description = '请输入说明'
  }

  return errors
}

const validate = values => {
  const errors = {}

  errors.line_items = values.line_items.map(validateLine)

  return errors
}

const formConfig = {
  form: 'OrderForm',
  fields: [
    'line_items[].units',
    'line_items[].unit_price',
    'line_items[].sales_tax_id',
    'line_items[].line_item_type',
    'line_items[].charge_code',
    'line_items[].description'
  ],
  validate: validate,
  touchOnBlur: false,
  initialValues: {
    line_items: [
      {
        units: 1,
        line_items: 0,
        description: ''
      }
    ]
  }
}

OrderForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrderForm)

export default OrderForm
