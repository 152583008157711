import React from 'react'
import * as valid from 'utils/validate'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import * as selectors from 'app/selectors'
import {
  KBDateInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import moment from 'moment'

var OnlyTimeReportForm = React.createClass({
  componentDidMount() {
    const {
      fields: { start_date, end_date, location_id },
      loc_id
    } = this.props

    if (loc_id) {
      location_id.onChange(loc_id)
    }
    start_date.onChange(
      moment()
        .startOf('month')
        .toDate()
    )
    end_date.onChange(new Date())
  },

  toReportCenter() {
    const { reportText } = this.props
    KBPopoverTop.show(
      <KBToReportCenter
        title={`${reportText}导出`}
        message={`${reportText}已成功导出，可以前往报表中心下载`}
      />
    )
  },

  exportReport(model) {
    model.start_date = moment(model.start_date).format('YYYY-MM-DD')
    model.end_date = moment(model.end_date).format('YYYY-MM-DD')

    const { type } = this.props

    if (!model.location_id) {
      delete model.location_id
    }

    if (type === 'contract') {
      model.subscription_type = 'normal'
      return api.salesSubscriptionsReport(model).then(res => {
        this.toReportCenter && this.toReportCenter()
        return true
      })
    }

    if (type === 'parking_contract') {
      model.subscription_type = 'parking'
      return api.salesSubscriptionsReport(model).then(res => {
        this.toReportCenter && this.toReportCenter()
        return true
      })
    }

    if (type === 'payment') {
      return api.exportInvoicePayments(model).then(res => {
        this.toReportCenter && this.toReportCenter()
        return true
      })
    }

    if (type === 'office' || type === 'parking') {
      model.area_types =
        type === 'parking'
          ? 'parking'
          : 'public_office,private_office,flexible_office'
      return api.getOfficeRentingReport(model).then(res => {
        this.toReportCenter && this.toReportCenter()
        return true
      })
    }

    if (type === 'invoice_app') {
      return api.getInvoicingApplicationsReport(model).then(res => {
        this.toReportCenter && this.toReportCenter()
        return true
      })
    }
  },

  renderLocation() {
    const {
      fields: { location_id }
    } = this.props
    const { locations, loc_id, reportText } = this.props
    const oneClass = {
      className: 'kb-form-group kb-form-c18 kb-form-alone'
    }

    let hasLocId = loc_id ? true : false
    // 获取已选择的分店
    let selectedLoc =
      locations &&
      locations.filter(data => {
        return data.id == loc_id
      })
    return (
      <div className="kb-row" onClick={this.setScrollTop}>
        <KBUserInput
          title="分店"
          placeholder={'请选择分店'}
          {...oneClass}
          field={location_id}
          disabled={hasLocId}
          hint={
            hasLocId
              ? null
              : `选择要导出的分店，不选则默认导出当前空间的全部${reportText}`
          }
          selectedUsers={selectedLoc}
          multi={false}
          users={locations}
        />
      </div>
    )
  },

  render() {
    const {
      fields: { start_date, end_date },
      error,
      submitting,
      handleSubmit,
      reportText
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.exportReport)}>
        <header className="kb-form-header">
          <h2 className="header-title">导出{reportText}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={error} />
        <div
          className="kb-form-container-m"
          ref={ref => {
            this.invoicesScroll = ref
          }}
        >
          {this.renderLocation()}
          <div className="kb-row kb-form-66" onClick={this.setScrollTop}>
            <KBDateInput
              type="date"
              title="开始日期"
              isRequired={true}
              isShowCenter={true}
              className="kb-form-group kb-div-c39"
              field={start_date}
            />
            <KBDateInput
              type="date"
              title="结束日期"
              titleClass="label-rt"
              isShowCenter={true}
              className="kb-form-group kb-div-c39"
              field={end_date}
            />
          </div>
          <div className="fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn m-left-sm"
              type="submit"
              submitting={submitting}
            >
              确认导出
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const locations = selectors.getSerializeLocation(state)
  const { type } = props
  const REPORT_TEXT_OBJ = {
    payment: '支付信息',
    contract: '合同信息',
    parking_contract: '车位合同信息',
    office: '房间租赁情况',
    parking: '车位租赁情况',
    invoice_app: '发票信息'
  }
  const reportText = REPORT_TEXT_OBJ[type] || '报表'

  return {
    locations,
    reportText
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  if (valid.required(values.start_date)) {
    errors.start_date = '请选择开始时间'
  }

  if (valid.required(values.end_date)) {
    errors.end_date = '请选择结束时间'
  }

  return errors
}

const formConfig = {
  form: 'OnlyTimeReportForm',
  fields: ['location_id', 'start_date', 'end_date'],
  validate: validate,
  touchOnBlur: false
}

OnlyTimeReportForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OnlyTimeReportForm)

export default OnlyTimeReportForm
