import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import {
  KBLoadingContainer,
  KBTipsy,
  KBPopover,
  KBRangeDate,
  KBStatisticNumber,
  KBDropdown
} from 'components'
import { areaActions, informActions, apiCallFailure } from 'app/actions'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { AREAS_LIST_STATE } from 'app/constants'
import AreasForm from './AreasForm'
import {
  checkObject,
  percent,
  getFilterAndQuery,
  queryFilter,
  kbCloseDropDown,
  isXingKuSpace
} from 'utils/kbUtil'
import _ from 'lodash'
import moment from 'moment'
import { canAccessLocation, canAccessLocationRoles } from 'app/reducers/role'
import { KBNoData } from 'components'

const AREA_TYPE_ICON_CLASS = {
  public_office: 'icon-Openspace',
  private_office: 'icon-Enclosedspace',
  flexible_office: 'icon-flexibleSpace'
}

const AREA_TYPES = [
  {
    id: 'public_office,private_office,flexible_office',
    name: '全部'
  },
  {
    id: 'public_office',
    name: '开放办公区'
  },
  {
    id: 'private_office',
    name: '独立办公室'
  },
  {
    id: 'flexible_office',
    name: '灵活办公区'
  }
]

const AREA_STATES = [
  {
    id: '',
    name: '全部'
  },
  {
    id: 'free',
    name: '未出租'
  },
  {
    id: 'already_rented',
    name: '已出租'
  }
]

const TIME_LINE_STATES = [
  {
    icon_img: 'img_icon_bufenchuzu@3x.png',
    name: '部分出租'
  },
  {
    icon_img: 'img_icon_yiyuding@2x.png',
    name: '已预订'
  },
  {
    icon_img: 'img_icon_nothire@2x.png',
    name: '租期已满未退租'
  },
  {
    icon_img: 'img_icon_kuaidoaqi@2x.png',
    name: '快到期'
  },
  {
    icon_img: 'img_icon_unsettled@2x.png',
    name: '未入驻'
  },
  {
    icon_img: 'img_icon_yuqi@2x.png',
    name: '有账单逾期'
  },
  {
    icon_img: 'img_icon_no@2x.png',
    name: '不可用'
  },
  {
    icon_img: 'img_icon_weixiu@3x.png',
    name: '维修中'
  }
]

const TIME_LINE_STATES_IMG_CLASS = {
  reserved: 'img_icon_reserved2x.png',
  not_rent_out: 'img_icon_nothire2@2x.png',
  expiring: 'img_icon_expiring2x.png',
  not_settle_in: 'img_icon_notenter2@2x.png',
  overdue: 'img_icon_overdue@2x.png',
  not_available: 'img_icon_notuse@2x.png',
  reparing: 'Group_28_Copy_3@3x.png'
}

var AreasTimeLine = React.createClass({
  getInitialState() {
    const { query, locations, loc_id } = this.props
    let locationsArr = Object.values(locations)
    let initFilters = query
    if (!initFilters.area_type) {
      initFilters.area_type = 'public_office,private_office,flexible_office'
    }
    if (!initFilters.state) {
      initFilters.state = ''
    }
    if (locationsArr && locationsArr.length > 0) {
      initFilters.location_id = loc_id || locationsArr[0].id
    }

    return {
      filters: initFilters,
      loading: true
    }
  },
  // from: moment().subtract(3, 'months').format('YYYY-MM-DD'),
  // to: moment().add(8, 'months').format('YYYY-MM-DD')
  componentDidMount() {
    const { filters } = this.state
    this.mounted = true

    this._loadData(filters)
  },

  componentWillReceiveProps(nextprops) {
    let locationsArr = Object.values(nextprops.locations)
    if (
      JSON.stringify(this.props.locations) !==
        JSON.stringify(nextprops.locations) &&
      locationsArr.length > 0
    ) {
      this._setFilter({ location_id: nextprops.loc_id || locationsArr[0].id })
    }
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(model) {
    const {
      apiCallFailureActions,
      getAreasAction,
      loc_id,
      routerActions
    } = this.props
    const { filters } = this.state
    let location_id =
      (model && model.location_id) || (filters && filters.location_id) || loc_id
    if (!location_id) {
      apiCallFailureActions({
        status: 'error',
        message: '请先选择分店!'
      })
      this.setState({ loading: false })
      return
    }

    let params = (model && Object.assign({}, filters, model)) || {}
    if (model && model.from && model.to) {
      params.subscription_start_date = moment(model.from).format('YYYY-MM-DD')
      params.subscription_end_date = moment(model.to).format('YYYY-MM-DD')
    }

    let queryFiltersStr = queryFilter({ ...params })

    if (params && params.state && params.state == 'already_rented') {
      params.state = 'reserved,assigned,expiring'
    }
    if (params && params.state && params.state == 'free') {
      params.state = 'free,partial_free'
    }

    delete params.from
    delete params.to

    if (params.location_id) {
      delete params.location_id
    }

    if (!params.state) {
      delete params.state
    }

    params.excludes = 'desks'
    api
      .getAreas({
        location_id,
        per_page: 1000,
        ...params,
        includes: 'subscriptions,organizations'
      })
      .then(
        json => {
          getAreasAction.success(json, {
            entityName: 'areas'
          })

          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }

          if (loc_id) {
            routerActions.replace(
              `/admin/locations/${loc_id}/areas/desks/time_line${queryFiltersStr}`
            )
          } else {
            routerActions.replace(
              `/admin/crm/inventory_timeline${queryFiltersStr}`
            )
          }
        },
        error => {
          getAreasAction.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  toDeskList(area) {
    const { routerActions, loc_id } = this.props
    const { filters } = this.state
    if (filters.state != 'all' && filters.state != area.state) {
      return
    }
    routerActions.push(
      `/admin/locations/${loc_id}/areas/desks/list?area_id=${area.id}`
    )
  },

  _setFilter(obj) {
    let newFilt = obj || {}
    let newFilters = Object.assign({}, this.state.filters, newFilt)
    this.setState({ filters: newFilters, loading: true, page: 1 })
    this['location_idDropdown'] && this['location_idDropdown'].hide()
    this._loadData(newFilters)
  },

  _refresh() {
    this['location_idDropdown'] && this['location_idDropdown'].hide()

    setTimeout(this._loadData)
  },

  _clearFilters() {
    const { query } = this.props
    this.setState({
      filters: {
        location_id: query.location_id || '',
        area_type: 'public_office,private_office,flexible_office',
        state: ''
      },
      loading: true,
      page: 1
    })
    this._refresh()
  },

  changeDate(range) {
    let subscription_start_date = range.from
      ? moment(range.from).format('YYYY-MM-DD')
      : ''
    let subscription_end_date = range.to
      ? moment(range.to).format('YYYY-MM-DD')
      : ''
    let timeFilter =
      subscription_start_date && subscription_end_date
        ? { subscription_start_date, subscription_end_date }
        : {}
    this._setFilter(timeFilter)
  },

  _onAreaCreated(area) {
    const { loc_id } = this.props
    this.props.routerActions.push(
      `/admin/locations/${loc_id}/areas/desks/list?area_id=${area.id}`
    )
  },

  createArea() {
    let { loc_id } = this.props
    KBPopover.showForm(
      <AreasForm loc_id={loc_id} onAreaCreated={this._onAreaCreated} />
    )
  },

  getMonths(from, to) {
    const startDate = from
      ? moment(from).format('YYYY-MM')
      : moment()
          .startOf('year')
          .format('YYYY-MM')
    const endDate = to
      ? moment(to).format('YYYY-MM')
      : moment()
          .endOf('year')
          .format('YYYY-MM')
    let dates = []
    let date = startDate
    dates.push(date)
    while (date != endDate) {
      date = moment(date)
        .add(1, 'month')
        .format('YYYY-MM')
      dates.push(date)
    }

    return dates
  },

  getYearByMonths(months) {
    let year = {}
    months.map(month => {
      let y = month.split('-')[0]
      let yearKeys = Object.keys(year)
      if (yearKeys.includes(y)) {
        year[y] += 1
      } else {
        year[y] = 1
      }
    })

    return year
  },

  getTimePeriodWidth(month, subscription) {
    let startOfMonth = moment(month)
      .startOf('month')
      .format('YYYY-MM-DD')
    let endOfMonth = moment(month)
      .endOf('month')
      .format('YYYY-MM-DD')
    // 当月的起止日期
    let som = moment(startOfMonth).format('X')
    let eom = moment(endOfMonth).format('X')
    let lengthOfMonth = eom - som

    // 当前合同的起止日期
    let sos = subscription.start_date
      ? moment(subscription.start_date).format('X')
      : ''
    let eos = subscription.end_date
      ? moment(subscription.end_date).format('X')
      : ''
    let lengthOfSub = eos - som

    let widthPercent = Math.floor((lengthOfSub * 100) / lengthOfMonth) + '%'

    return widthPercent
  },

  getSubsInThisMonth(m, subscriptions) {
    let subInThisMonth = []

    let startOfMonth = moment(m)
      .startOf('month')
      .format('YYYY-MM-DD')
    let endOfMonth = moment(m)
      .endOf('month')
      .format('YYYY-MM-DD')
    // 当月的起止日期
    let som = moment(startOfMonth).format('X')
    let eom = moment(endOfMonth).format('X')
    let lengthOfMonth = eom - som

    subscriptions &&
      subscriptions.map(subscription => {
        let sub = subscription.subscription
        let thisSub = { style: {} }
        // 当前合同的起止日期
        let sos = sub.start_date ? moment(sub.start_date).format('X') : ''
        let eos = sub.end_date ? moment(sub.end_date).format('X') : ''
        if (sos < som && eos < eom && eos > som) {
          // 合同日期范围部分在本月内（左侧） 合同结束日期
          let lengthOfSub = eos - som
          thisSub.style.left = '0px'
          thisSub.style.zIndex = 9
          thisSub.style.borderRight = '1px solid #fff'
          thisSub.style.width =
            Math.floor((lengthOfSub * 100) / lengthOfMonth) + '%'
          thisSub.style.backgroundColor = '#87a8ff'
          subInThisMonth.push({ thisSub, subscription })
        } else if (sos <= som && eom <= eos) {
          // 合同日期范围覆盖本月
          thisSub.style.width = '100%'
          thisSub.style.left = 0
          thisSub.style.zIndex = 9
          thisSub.style.backgroundColor = '#87a8ff'
          subInThisMonth.push({ thisSub, subscription })
        } else if (som <= sos && eos <= eom) {
          // 合同日期范围完全在本月内
          let lengthOfSub = eos - sos
          let lengthOfLeft = sos - som
          thisSub.style.width =
            Math.floor((lengthOfSub * 100) / lengthOfMonth) + '%'
          thisSub.style.left =
            Math.floor((lengthOfLeft * 100) / lengthOfMonth) + '%'
          thisSub.style.zIndex = 9
          thisSub.style.borderLeft = '1px solid #fff'
          thisSub.style.borderRight = '1px solid #fff'
          thisSub.style.backgroundColor = '#87a8ff'
          subInThisMonth.push({ thisSub, subscription })
        } else if (som < sos && sos < eom && eom < eos) {
          // 合同日期范围部分在本月内（右侧） 合同开始日期
          let lengthOfSub = eom - sos
          thisSub.style.right = '0px'
          thisSub.style.zIndex = 9
          thisSub.style.borderLeft = '1px solid #fff'
          thisSub.style.width =
            Math.floor((lengthOfSub * 100) / lengthOfMonth) + '%'
          thisSub.style.backgroundColor = '#87a8ff'
          subInThisMonth.push({ thisSub, subscription })
        }
      })

    return subInThisMonth
  },

  getCurrentSubStatus(area, month, subscription) {
    let sub = subscription.subscription
    let reservedSubState = ['active', 'draft', 'approving']
    // 快到期
    if (sub.status == 'expiring') {
      return 'expiring'
    }
    if (reservedSubState.includes(sub.status)) {
      // 正常租用
      if (area.state == 'assigned') {
        return
      }
      // 已预订
      if (
        sub.status == 'approving' ||
        moment(sub.start_date).format('X') > moment().format('X')
      ) {
        return 'reserved'
      }
      if (
        subscription.overdue_end_date &&
        subscription.overdue_end_date.length
      ) {
        return 'overdue'
      }
      // 未入驻
      return 'not_settle_in'
    }
    // 租期已满未退租
    if (
      (area.state != 'free' || area.state != 'partial_free') &&
      moment(sub.expire_date).format('X') < moment().format('X')
    ) {
      return 'not_rent_out'
    }
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, ['location_idDropdown'], type)
  },

  render() {
    const { filters, loading } = this.state
    const { state, subscription_start_date, subscription_end_date } = filters
    const {
      areas,
      thisLocation,
      location_entering_rate_calc_type,
      loc_id,
      locations,
      space_id,
      isManager
    } = this.props
    let statistic = thisLocation && thisLocation.statistic
    const enteringRate = percent(
      statistic ? statistic.desks_count : 0,
      statistic ? statistic.renting_desks_count : 0
    )

    let months = this.getMonths(subscription_start_date, subscription_end_date)
    let year = this.getYearByMonths(months)
    let years = Object.keys(year)

    return (
      <div className="kb-content" style={{ padding: 0 }}>
        <div
          className="kb-content-container"
          style={{ height: 'auto', float: 'left', width: '100%' }}
        >
          <header className="nav-header">
            <div className="nav-section-action-bar" style={{ marginTop: 0 }}>
              {loc_id && Object.keys(locations).length > 0 ? (
                <div
                  className="task-status-title"
                  style={{ display: 'inline-block' }}
                >
                  <i className="iconfont icon-position" />
                  <span>{locations[loc_id].name || ''}</span>
                </div>
              ) : (
                <KBDropdown
                  ref={ref => {
                    this.location_idDropdown = ref
                  }}
                >
                  <KBDropdown.KBDropdownTrigger>
                    <div
                      className="task-status-title"
                      onClick={this.closeDropDowns.bind(
                        null,
                        'location_idDropdown'
                      )}
                    >
                      <i className="iconfont icon-position" />
                      <span>
                        {this.state.filters.location_id &&
                        Object.keys(locations).length > 0
                          ? locations[this.state.filters.location_id].name
                          : '选择分店'}
                      </span>
                      <span className="task-deg" />
                    </div>
                  </KBDropdown.KBDropdownTrigger>
                  <KBDropdown.KBDropdownContent>
                    <div>
                      <ul className="task-type">
                        {locations &&
                          Object.keys(locations).map((location_id, index) => {
                            let location = locations[location_id]
                            return (
                              <li
                                key={index}
                                onClick={() =>
                                  this._setFilter({ location_id: location.id })
                                }
                              >
                                <span>{location.name}</span>
                              </li>
                            )
                          })}
                      </ul>
                    </div>
                  </KBDropdown.KBDropdownContent>
                </KBDropdown>
              )}
              <KBRangeDate
                format="YYYY/MM/DD"
                from={subscription_start_date || ''}
                to={subscription_end_date || ''}
                callback={this.changeDate}
              />
              {loc_id && isManager ? (
                <button
                  className="bordered-btn f-right"
                  onClick={this.createArea}
                >
                  添加新分区
                </button>
              ) : null}
            </div>
            {subscription_start_date || subscription_end_date ? (
              <div
                className="clear-criteria"
                style={{ marginTop: 5 }}
                onClick={this._clearFilters}
              >
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            ) : null}
            {thisLocation && thisLocation.statistic ? (
              <div className="chart-out_box t-center m-updown">
                <div className="chart-box t-left" style={{ marginRight: 30 }}>
                  {isXingKuSpace(space_id) ? (
                    <KBStatisticNumber
                      number={
                        parseFloat(statistic.outer_entering_rate || 0).toFixed(
                          2
                        ) + '%'
                      }
                      name="实际入驻率"
                    />
                  ) : (
                    <KBStatisticNumber
                      number={enteringRate + '%'}
                      name="工位使用率"
                    />
                  )}
                </div>
                <div className="chart-box t-left" style={{ marginRight: 30 }}>
                  {isXingKuSpace(space_id) ? (
                    <KBStatisticNumber
                      number={
                        location_entering_rate_calc_type == 'by_desk'
                          ? statistic.total_capacity || 0
                          : parseFloat(
                              statistic.physical_capacity || 0
                            ).toFixed(2)
                      }
                      unit={
                        location_entering_rate_calc_type == 'by_desk'
                          ? '个'
                          : '㎡'
                      }
                      name={
                        location_entering_rate_calc_type == 'by_desk'
                          ? '出租工位总和'
                          : '出租面积总和'
                      }
                    />
                  ) : (
                    <KBStatisticNumber
                      number={
                        location_entering_rate_calc_type == 'by_desk'
                          ? statistic.total_capacity || 0
                          : parseFloat(statistic.total_capacity || 0).toFixed(2)
                      }
                      unit={
                        location_entering_rate_calc_type == 'by_desk'
                          ? '个'
                          : '㎡'
                      }
                      name={
                        location_entering_rate_calc_type == 'by_desk'
                          ? '额定总工位数'
                          : '额定总面积数'
                      }
                    />
                  )}
                </div>
                <div className="chart-box t-left" style={{ marginRight: 30 }}>
                  <KBStatisticNumber
                    number={statistic.desks_count || 0}
                    unit="个"
                    name="实际总工位数"
                  />
                </div>
                <div className="chart-box t-left" style={{ marginRight: 30 }}>
                  {isXingKuSpace(space_id) ? (
                    <KBStatisticNumber
                      number={
                        location_entering_rate_calc_type == 'by_desk'
                          ? statistic.occupied_desks_count || 0
                          : parseFloat(
                              statistic.occupied_desks_count || 0
                            ).toFixed(2)
                      }
                      unit={
                        location_entering_rate_calc_type == 'by_desk'
                          ? '个'
                          : '㎡'
                      }
                      name={
                        location_entering_rate_calc_type == 'by_desk'
                          ? '已出租工位总和'
                          : '已出租面积总和'
                      }
                    />
                  ) : (
                    <KBStatisticNumber
                      number={
                        location_entering_rate_calc_type == 'by_desk'
                          ? statistic.occupied_desks_count || 0
                          : parseFloat(
                              statistic.occupied_desks_count || 0
                            ).toFixed(2)
                      }
                      unit={
                        location_entering_rate_calc_type == 'by_desk'
                          ? '个'
                          : '㎡'
                      }
                      name={
                        location_entering_rate_calc_type == 'by_desk'
                          ? '已出租工位数'
                          : '已出租面积数'
                      }
                    />
                  )}
                </div>
                <div className="chart-box t-left">
                  <KBStatisticNumber
                    number={statistic.renting_desks_count || 0}
                    unit="个"
                    name="实际入驻工位数"
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </header>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 30px',
              marginTop: 20
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between'
              }}
            >
              {TIME_LINE_STATES.map((data, index) => {
                return (
                  <div
                    key={index}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <img
                      src={`https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/${data.icon_img}`}
                      style={{
                        display: 'inline-block',
                        width: 19,
                        height: 19,
                        marginRight: 10
                      }}
                    />
                    <span>{data.name}</span>
                  </div>
                )
              })}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 30px',
              marginTop: 20
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              {AREA_TYPES.map((json, index) => {
                const isChecked = json.id == filters.area_type
                return (
                  <div
                    key={index}
                    className={`border-show_status ${
                      isChecked ? 'border-show_status_active' : ''
                    }`}
                    onClick={() => this._setFilter({ area_type: json.id })}
                  >
                    {json.id !=
                    'public_office,private_office,flexible_office' ? (
                      <span>
                        <i
                          className={`iconfont ${
                            AREA_TYPE_ICON_CLASS[json.id]
                          }`}
                          style={{ marginRight: 10 }}
                        >
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </i>
                      </span>
                    ) : (
                      ''
                    )}
                    <span className="status-text">{json.name}</span>
                  </div>
                )
              })}
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              {AREA_STATES.map((json, index) => {
                const isChecked = json.id == filters.state
                return (
                  <div
                    key={index}
                    className={`border-show_status ${
                      isChecked ? 'border-show_status_active' : ''
                    }`}
                    onClick={() => this._setFilter({ state: json.id })}
                  >
                    {json.id != '' ? (
                      <span
                        className={`color-show ${'area-list_' + json.id}`}
                      />
                    ) : (
                      ''
                    )}
                    <span className="status-text">{json.name}</span>
                  </div>
                )
              })}
            </div>
          </div>

          <div
            style={{
              float: 'left',
              width: '100%',
              height: '100%',
              padding: '0 30px 50px'
            }}
          >
            <KBLoadingContainer loading={loading}>
              {areas && areas.length ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative'
                  }}
                >
                  <div
                    style={{ float: 'left', width: '30%', overflowX: 'auto' }}
                  >
                    <table className="timeline-table">
                      <thead>
                        <tr>
                          <th colSpan="3">{'\u00A0'}</th>
                        </tr>
                      </thead>
                      <thead style={{ fontSize: '12px' }}>
                        <tr>
                          <th style={{ textAlign: 'left' }}>办公区名称</th>
                          <th>人数</th>
                          <th>面积(㎡)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {areas &&
                          areas.map((area, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  style={{ textAlign: 'left', paddingLeft: 20 }}
                                >
                                  <i
                                    className={`iconfont ${
                                      AREA_TYPE_ICON_CLASS[area.area_type]
                                    }`}
                                    style={{ marginRight: 7 }}
                                  >
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                  </i>
                                  {(area.name && (
                                    <Link
                                      className="color-link"
                                      to={`/admin/locations/${area.location_id}/areas/desks/list?area_id=${area.id}`}
                                    >
                                      {area.name}
                                    </Link>
                                  )) ||
                                    'N/A'}
                                </td>
                                <td>{area.real_capacity || 'N/A'}</td>
                                <td>{area.size || 'N/A'}</td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      float: 'left',
                      width: '70%',
                      overflowX: 'auto',
                      overflowY: 'hidden'
                    }}
                  >
                    <table className="timeline-table timeline-table-right">
                      <thead>
                        <tr>
                          {years &&
                            years.map((y, index) => {
                              return (
                                <th key={index} colSpan={year[y]}>
                                  {y}年
                                </th>
                              )
                            })}
                        </tr>
                      </thead>
                      <thead style={{ fontSize: '12px' }}>
                        <tr>
                          {months &&
                            months.map((m, index) => {
                              return (
                                <th key={index}>{moment(m).month() + 1}月</th>
                              )
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {areas &&
                          areas.map((area, index) => {
                            let allSubscriptions = area.subscriptions || []
                            let subscriptions = allSubscriptions.filter(
                              data => {
                                return (
                                  data.subscription.status == 'active' ||
                                  data.subscription.status == 'expiring' ||
                                  data.subscription.status == 'draft' ||
                                  data.subscription.status == 'approving'
                                )
                              }
                            )
                            return (
                              <tr key={index}>
                                {months &&
                                  months.map((m, index) => {
                                    let color = {
                                      not_available: '#d3d3d3',
                                      assigned: '#87a8ff',
                                      free: '#dbebff',
                                      partial_free: '#9288E1'
                                    }
                                    let subInThisMonth = this.getSubsInThisMonth(
                                      m,
                                      subscriptions
                                    )
                                    let leftPercent = this.getTimePeriodWidth(
                                      m,
                                      {
                                        start_date: moment(m)
                                          .startOf('month')
                                          .format('YYYY-MM-DD'),
                                        end_date: moment().format('YYYY-MM-DD')
                                      }
                                    )
                                    return (
                                      <td key={index}>
                                        {moment().format('YYYY-MM') == m ? (
                                          <span
                                            style={{
                                              display: 'inline-block',
                                              width: 1,
                                              height: '100%',
                                              backgroundColor: '#FF2424',
                                              position: 'absolute',
                                              left: `${leftPercent}`,
                                              top: 0,
                                              zIndex: 10
                                            }}
                                          />
                                        ) : null}
                                        <span
                                          className="period-span"
                                          style={{
                                            left: 0,
                                            zIndex: 1,
                                            width: '100%',
                                            backgroundColor: '#DBEBFF'
                                          }}
                                        >
                                          {area.area_type == 'public_office' &&
                                          subInThisMonth.length ? (
                                            <span
                                              className="period-span"
                                              style={{
                                                top: 0,
                                                left: 0,
                                                zIndex: 1,
                                                width: '100%',
                                                backgroundColor:
                                                  color[area.state]
                                              }}
                                            />
                                          ) : (
                                            (subInThisMonth.length &&
                                              subInThisMonth.map(
                                                (data, index) => {
                                                  let style = data.thisSub.style
                                                  // TODO: 需要查一下哪些字段没有导致了获取不到当前 line 的 state
                                                  let state_class =
                                                    TIME_LINE_STATES_IMG_CLASS[
                                                      this.getCurrentSubStatus(
                                                        area,
                                                        m,
                                                        data.subscription
                                                      )
                                                    ]
                                                  return (
                                                    <span
                                                      key={index}
                                                      className="period-span"
                                                      style={{
                                                        top: 0,
                                                        ...style
                                                      }}
                                                    >
                                                      {style.left &&
                                                      style.width != '100%' &&
                                                      state_class ? (
                                                        <img
                                                          src={`https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/${state_class}`}
                                                          style={{
                                                            width: 26,
                                                            height: 26,
                                                            position:
                                                              'absolute',
                                                            zIndex: 10,
                                                            top: 0,
                                                            right: 0
                                                          }}
                                                          alt=""
                                                        />
                                                      ) : null}
                                                    </span>
                                                  )
                                                }
                                              )) ||
                                            null
                                          )}
                                        </span>
                                      </td>
                                    )
                                  })}
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <KBNoData
                  tipMessage="暂无数据"
                  outterStyle={{ marginTop: 100 }}
                />
              )}
            </KBLoadingContainer>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const { params, location } = ownProps
  const { user } = state
  let space_id = Object.keys(state.entities.spaces || {})[0]
  let loc_id = params.id

  let locations = selectors.getSerializeLocationObj(state)
  let location_id = location.query.location_id || loc_id

  let allAreas = Object.assign(
    [],
    selectors.getAreasOfLocation(state, location_id)
  )
  let areas = allAreas.filter(area => {
    return (
      area.area_type == 'public_office' ||
      area.area_type == 'private_office' ||
      area.area_type == 'flexible_office'
    )
  })

  var param = getFilterAndQuery(location.query, [])

  let thisLocation = selectors.getSerializeLocationObj(state, location_id)

  let isManager = canAccessLocation(user, loc_id)
  let isSales = canAccessLocationRoles(user, loc_id, ['sales'])

  return {
    isManager,
    isSales,
    space_id,
    loc_id,
    locations,
    thisLocation,
    areas,
    ...param
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    getAreasAction: bindActionCreators(areaActions.replace, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AreasTimeLine)
