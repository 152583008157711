import React, { Component, PropTypes } from 'react'
import ReactDOM from 'react-dom'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { invoicesActions } from 'app/actions'
import { KBFormError, KBButton, KBPopover } from 'components'
import InvoiceCertPhotoUpload from './InvoiceCertPhotoUpload'

var InvoiceCertificationFileForm = React.createClass({
  updateInvoiceCertForm(model) {
    const { invoice, payment, updateInvoicePayment } = this.props

    if (!model.vouchers) {
      delete model.vouchers
    }

    model.payment_id = (payment && payment.id) || ''
    return api.putResetVouchers(invoice.id, model).then(json => {
      let updatedPayment = (json && json.response && json.response.body) || {}
      let updatedInvoice = updateInvoicePayment(updatedPayment)
      this.props.updateInvoicesActions.success(updatedInvoice)
      KBPopover.close()
      return true
    })
  },

  render() {
    const {
      fields: { vouchers },
      error,
      submitting,
      handleSubmit,
      invoice
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.updateInvoiceCertForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">上传付款凭证文件</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container">
          <div className="invoice-certification-form">
            <div className="kb-row">
              <InvoiceCertPhotoUpload
                isRequired={true}
                title="上传凭证"
                hint="上传付款扫描件或照片，不大于10M"
                multiple={true}
                photoShow={true}
                preView={false}
                className="kb-form-group kb-form-210 kb-form-alone"
                field={vouchers}
                prefix="images/invoice/"
              />
            </div>
          </div>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

InvoiceCertificationFileForm.PropTypes = {
  invoice: PropTypes.object.isRequired
}

function mapStateToProps(state, ownProps) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.vouchers)) {
    errors.vouchers = '请上传凭证图片或文件'
  }

  return errors
}

const formConfig = {
  form: 'InvoiceCertificationFileForm',
  fields: ['payment_id', 'vouchers'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoiceCertificationFileForm)
