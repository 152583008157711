import { mergeKayState, omitKayState } from './reducer_utils'
import _ from 'lodash'
import { combineReducers } from 'redux'
import * as actions from '../actions'

function crm(state = [], action) {
  if (action.type == actions.USER_LOGGED_OUT) {
    return {}
  }
  switch (action.type) {
    case 'DELETELEADS_SUCCESS':
    case 'DELETECONTACTS_SUCCESS':
    case 'DELETEOPPORTUNITIES_SUCCESS':
      return omitKayState(state, action)
      break
    default:
      if (
        _.endsWith(action.type, 'LEADS_SUCCESS') ||
        _.endsWith(action.type, 'CONTACTS_SUCCESS') ||
        _.endsWith(action.type, 'OPPORTUNITIES_SUCCESS')
      ) {
        return mergeKayState(state, action)
      }
      return state
      break
  }
}

export default crm
