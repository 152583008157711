import React, { Component, PropTypes } from 'react'
import { api, apiUtils } from 'app/services'
import { apiCallFailure } from 'app/actions'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { groupLocationsByCity } from 'utils/kbUtil'
import LocationOfCity from './LocationOfCity'
import { KBTableWithoutData } from 'components'

var LocationsShow = React.createClass({
  getInitialState() {
    return {
      type: 'all'
    }
  },

  componentDidMount() {},

  changeLocationType(type) {
    this.setState({
      type: type
    })
  },

  render() {
    const { locations, cities } = this.props
    const { type } = this.state
    let locationsArray = locations
    if (type == 'show') {
      locationsArray = locations.filter(location => location.public)
    }
    if (type == 'hidden') {
      locationsArray = locations.filter(location => !location.public)
    }
    let groups = groupLocationsByCity(locationsArray)

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span>分店展示</span>
          </div>
        </header>
        <div className="nav-section-content-container">
          <div className="wechat-authorization m-updown">
            <img
              src="https://media-ssl.kuban.io/static/web/v1/images/locationAppShow.png"
              alt="wechat"
            />
            <div>
              <p>
                设置在微信客户端或App中对外展示的分店。
                <br />
                用户可以查看分店的基本信息，管理公司和
                <br />
                预订工位，会议室等。
              </p>
            </div>
          </div>
          <div
            className="className='select-progress dc-mr-smail'"
            style={{ paddingTop: 20 }}
          >
            <label
              onClick={() => {
                this.changeLocationType('all')
              }}
              style={{ marginRight: 0, padding: '7px 9px' }}
              className={`border-show_status ${
                type == 'all' ? 'border-show_status_active' : ''
              }`}
            >
              <span
                className={`color-show`}
                style={{
                  border: 0,
                  width: 16,
                  height: 16,
                  marginRight: 6,
                  backgroundColor: `${type == 'all' ? '#ffffff' : '#2ea1f8'}`
                }}
              ></span>
              <span>全部</span>
            </label>
            <label
              onClick={() => {
                this.changeLocationType('show')
              }}
              style={{ marginRight: 0, padding: '7px 9px' }}
              className={`border-show_status ${
                type == 'show' ? 'border-show_status_active' : ''
              }`}
            >
              <span
                className={`color-show`}
                style={{
                  border: 0,
                  width: 16,
                  height: 16,
                  marginRight: 6,
                  backgroundColor: '#73bb4b'
                }}
              ></span>
              <span>展示</span>
            </label>
            <label
              onClick={() => {
                this.changeLocationType('hidden')
              }}
              style={{ marginRight: 0, padding: '7px 9px' }}
              className={`border-show_status ${
                type == 'hidden' ? 'border-show_status_active' : ''
              }`}
            >
              <span
                className={`color-show`}
                style={{
                  border: 0,
                  width: 16,
                  height: 16,
                  marginRight: 6,
                  backgroundColor: '#f2a73d'
                }}
              ></span>
              <span>隐藏</span>
            </label>
          </div>
          {Object.keys(groups).map(city_id => {
            if (!city_id || !cities) {
              return ''
            }
            let city_locations = groups[city_id]

            return (
              <div key={city_id} className="m-top">
                <header className="border-bottom" style={{ paddingBottom: 10 }}>
                  <span style={{ fontSize: 18 }}>
                    {(cities[city_id] || {}).name}
                  </span>
                </header>
                <table className="content-table">
                  <thead>
                    <tr>
                      <th width={200}>名字</th>
                      <th width={460}>地址</th>
                      <th width={100}>平米数</th>
                      <th />
                    </tr>
                  </thead>
                  <KBTableWithoutData
                    hasData={city_locations.length > 0}
                    tableHeadNum="3"
                    tipMessage="暂无分店信息"
                  >
                    {city_locations &&
                      city_locations.map(location => (
                        <LocationOfCity key={location.id} location={location} />
                      ))}
                  </KBTableWithoutData>
                </table>
              </div>
            )
          })}
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  let locations = selectors.getLocations(state) || []
  if (!locations.length) {
    locations = selectors.getSerializeLocation(state) || []
  }
  const cities = selectors.getCities(state) || []

  return {
    locations,
    cities
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationsShow)
