import React from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { LINE_ITEM_TYPES } from 'app/constants'
import { orgActions, apiCallFailure } from 'app/actions'
import * as selectors from 'app/selectors'
import {
  KBPagination,
  KBTableWithoutData,
  KBLoadingContainer,
  KBDropdown,
  KBPopover,
  KBPopoverTop,
  KBToReportCenter,
  KBRangeDate
} from 'components'
import { KBDropSelect } from 'components/plugins'
import { KBPopoverConfirm } from 'components/tools'
import {
  paginate,
  queryFilter,
  getFilterAndQuery,
  kbCloseDropDown,
  fmoney,
  getServiceErrorMessage
} from 'utils/kbUtil'
import moment from 'moment'
import { KBDayPicker } from 'components/views'

const DROP_DOWNS = [
  'location_idDropdown',
  'filterDate',
  'lineItemType',
  'company'
]

var StatisticInvoiceMonthly = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      loading: false,
      pagination: null,
      filters,
      invoices: []
    }
  },

  componentDidMount() {
    this.mounted = true

    this.loadData()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.loadData(nextProps.page)
    }
  },

  getOrganizations() {
    const { getOrganizationsAction, location_id } = this.props
    api
      .getOrganizations({
        location_id,
        per_page: 1000,
        includes: 'details',
        organization_type: 'normal'
      })
      .then(
        json => {
          getOrganizationsAction.success(json)
        },
        errors => {
          getOrganizationsAction.failure(errors)
        }
      )
  },

  loadData(newPage) {
    const { filters, orgs } = this.state
    const { routerActions, query, location_id } = this.props
    const per_page = 10
    let pageObj = {
      per_page,
      page: newPage || this.props.page
    }

    let params = Object.assign({}, filters, location_id ? { location_id } : {})
    if (!(params.start_date && params.end_date)) {
      params.start_date = moment()
        .startOf('month')
        .format('YYYY-MM-DD')
      params.end_date = moment()
        .endOf('month')
        .format('YYYY-MM-DD')
    }
    let queryFiltersStr = queryFilter({ ...query, ...params, ...pageObj })

    this.setState({ loading: true })
    api
      .getMonthlyInvoicesStatistics({ ...params, ...pageObj })
      .then(
        json => {
          let pagination = paginate(json.response)
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin${
              this.props.location_id
                ? `/locations/${this.props.location_id}`
                : ''
            }/invoices/monthly_invoices${queryFiltersStr}`
          )
          if (this.mounted) {
            this.setState({
              invoices: json.json,
              pagination
            })
          }
        },
        errors => {
          const { apiCallFailureActions } = this.props
          return apiCallFailureActions({
            status: 'error',
            message: errors.message || getServiceErrorMessage(errors)
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
        if (!(orgs && orgs.length > 0)) {
          this.getOrganizations()
        }
      })
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters })
    this.location_idDropdown && this.location_idDropdown.hide()
    this.filterDate && this.filterDate.hide()
    this.company && this.company.hide()

    setTimeout(this.loadData)
  },

  _clearFilters() {
    const { routerActions } = this.props
    this.setState({ filters: {} })
    setTimeout(this.loadData)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  orgSelectCallBack(data) {
    if (!(data && data.sales_customer_id)) {
      return
    }

    this._setFilter({ sales_customer_id: String(data.sales_customer_id) })
  },

  selectLineItemTypeCallBack(data) {
    this._setFilter({
      line_item_type: data.map(json => json.id).join(',')
    })
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="月汇总账单统计导出"
        message="月汇总账单统计表格已成功导出，可以前往报表中心下载"
      />
    )
  },

  exportInvoiceMonthlyStatistics() {
    KBPopoverConfirm({
      name: '导出月汇总账单统计表格',
      context:
        '您即将导出当前列表筛选条件下的月汇总账单统计表格，是否确认导出？',
      callback: () => {
        const { location_id, apiCallFailureActions, filters } = this.props
        const params = Object.assign(
          {},
          filters,
          location_id ? { location_id } : {}
        )
        params.as_json = false
        return api.postMonthlyInvoicesStatistics(params).then(
          () => {
            this.toReportCenter && this.toReportCenter()
            KBPopover.close()
          },
          error => {
            apiCallFailureActions({
              status: 'error',
              message: error.message || '服务器出问题了，请稍后重试哦~'
            })
            KBPopover.close()
          }
        )
      }
    })
  },

  changeDate(range) {
    const start_date = range.from
      ? moment(range.from)
          .startOf('month')
          .format('YYYY-MM-DD')
      : ''
    const end_date = range.to
      ? moment(range.to)
          .endOf('month')
          .format('YYYY-MM-DD')
      : ''
    this._setFilter({ start_date, end_date })
  },

  _renderFilters() {
    const {
      locations,
      location_id,
      orgs,
      currentOrg,
      defaultLineItemTypes
    } = this.props
    const { filters } = this.state
    const { start_date, end_date } = filters

    return (
      <div className="nav-section-action-bar">
        <div className="select-progress">
          {location_id && Object.keys(locations).length > 0 ? (
            <div
              className="task-status-title"
              style={{ display: 'inline-block' }}
            >
              <i className="iconfont icon-position" />
              <span>{locations[location_id].name || ''}</span>
            </div>
          ) : (
            <KBDropdown ref="location_idDropdown">
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(
                    null,
                    'location_idDropdown'
                  )}
                >
                  <i className="iconfont icon-position" />
                  <span>
                    {this.state.filters.location_id &&
                    Object.keys(locations).length > 0
                      ? locations[this.state.filters.location_id].name
                      : '选择园区'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ location_id: '' })}>
                      全部
                    </li>
                    {locations &&
                      Object.keys(locations).map((location_id, index) => {
                        let location = locations[location_id]
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              this._setFilter({ location_id: location.id })
                            }
                          >
                            <span>{location.name}</span>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          )}
          <KBRangeDate
            title="账单所属月份范围"
            format="YYYY-MM-DD"
            from={start_date || ''}
            to={end_date || ''}
            callback={this.changeDate}
            closeClick={this.closeDropDowns}
            dropDown_name="filterDate"
            ref={ref => {
              this.filterDate = ref
            }}
          />
          <KBDropSelect
            ref={ref => {
              this.company = ref
            }}
            dropDown_name="company"
            selectedData={currentOrg}
            closeClick={this.closeDropDowns}
            options={{ defaultSingleContent: '选择客户名称' }}
            defaultData={orgs}
            multi={false}
            callback={this.orgSelectCallBack}
            style={{ marginLeft: 20 }}
          />
          <KBDropSelect
            selectedData={defaultLineItemTypes}
            ref={ref => {
              this.lineItemType = ref
            }}
            options={{ defaultSelectPrompt: '选择事项' }}
            defaultData={LINE_ITEM_TYPES}
            multi={true}
            showImg={false}
            callback={this.selectLineItemTypeCallBack}
            dropDown_name="lineItemType"
            closeClick={this.closeDropDowns}
            style={{ marginLeft: 20 }}
          />
        </div>
      </div>
    )
  },

  render() {
    const { loading, invoices, pagination } = this.state

    return (
      <div className="kb-content-container clear-fix">
        <div>
          <header className="nav-header">
            <div className=" clear-fix">
              <div className="nav-section-header-title f-left">
                <span>月汇总账单统计</span>
              </div>
              <div className="f-right">
                <button
                  className="c-btn-secondary"
                  onClick={this.exportInvoiceMonthlyStatistics}
                >
                  导出月汇总账单统计
                </button>
              </div>
            </div>
          </header>
          <div className="nav-section-content-container">
            <div>
              {this._renderFilters()}
              {Object.keys(this.state.filters).length > 0 ? (
                <div className="clear-criteria" onClick={this._clearFilters}>
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              ) : null}
            </div>
            <KBLoadingContainer loading={loading}>
              <table className="content-table">
                <thead>
                  <tr>
                    <th>客户名称</th>
                    <th>单元号</th>
                    <th>账单名称</th>
                    <th>起止日期</th>
                    <th>事项</th>
                    <th>金额</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={invoices.length > 0}
                  tableHeadNum="9"
                  tipMessage="暂无账单"
                >
                  {invoices &&
                    invoices.map((invoice_items, index) => (
                      <tr key={index}>
                        {invoice_items.map((item, key) => {
                          let itemValue = item
                          if (key === invoice_items.length - 1) {
                            itemValue = '¥ ' + fmoney(item || 0)
                          }

                          return <td>{itemValue || ''}</td>
                        })}
                      </tr>
                    ))}
                </KBTableWithoutData>
              </table>
            </KBLoadingContainer>
            <KBPagination
              pagination={pagination}
              template={`/admin${
                this.props.location_id
                  ? `/locations/${this.props.location_id}`
                  : ''
              }/invoices/monthly_invoices?page=#PAGE#`}
            />
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let location_id = params.id
  let page = parseInt(location.query && location.query.page, 10) || 1
  let locations = selectors.getSerializeLocationObj(state)
  var FILTER_STATE = [
    'location_id',
    'start_date',
    'end_date',
    'sales_customer_id',
    'line_item_type'
  ]
  var param = getFilterAndQuery(location.query, FILTER_STATE)

  let orgs = selectors.getOrgsOfLocation(state, location_id)
  let currentOrg
  if (param.filters.sales_customer_id) {
    currentOrg = orgs.find(
      orgs => param.filters.sales_customer_id == orgs.sales_customer_id
    )
  }

  let defaultLineItemTypes = []
  if (param.filters && param.filters.line_item_type) {
    let line_item_types = param.filters.line_item_type.split(',')
    defaultLineItemTypes = LINE_ITEM_TYPES.filter(data =>
      line_item_types.find(id => id == data.id)
    )
  }

  return {
    page,
    locations,
    location_id,
    orgs,
    currentOrg,
    defaultLineItemTypes,
    ...param
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    getOrganizationsAction: bindActionCreators(orgActions.replace, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticInvoiceMonthly)
