import React from 'react'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { invoicesActions } from 'app/actions'
import { KBFormError, KBButton, KBRadio, KBPopover, KBForm } from 'components'

const isPass = [
  { title: '是', value: true },
  { title: '否', value: false }
]

var InvoiceCheckPaidForm = React.createClass({
  formatBoolean(value) {
    return value === 'true' || value === true ? true : false
  },

  submitInvoiceCheckPaidForm(model) {
    const { invoice_id, location_id } = this.props

    if (location_id) {
      model.location_id = location_id
    } else {
      delete model.location_id
    }

    model.pass = this.formatBoolean(model.pass)
    model.reset_payment = this.formatBoolean(model.reset_payment)

    if (model.pass !== false) {
      delete model.reset_payment
    }

    return api.putCheckPaid(invoice_id, model).then(
      json => {
        this.props.updateInvoicesActions.success(json)
        KBPopover.close()
      },
      errors => {
        this.props.updateInvoicesActions.failure(errors)
      }
    )
  },

  render() {
    const {
      fields: { pass, reset_payment },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.submitInvoiceCheckPaidForm)}
        fields={this.props.fields}
      >
        <KBFormError error={error} />
        <header className="kb-form-header">
          <h2 className="header-title">账单核销</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container-m">
          <div className="kb-row">
            <KBRadio
              className="kb-form-group kb-div-c39 kb-form-alone"
              inClassName="kb-from-initial"
              divClassName="kb-from-radio"
              title="是否通过核销"
              arrayType={isPass}
              name="check_paid_pass"
              field={pass}
            />
          </div>
          {!pass.value || pass.value === 'false' ? (
            <div className="kb-row">
              <KBRadio
                className="kb-form-group kb-div-c39 kb-form-alone"
                inClassName="kb-from-initial"
                divClassName="kb-from-radio"
                title="是否清除付款记录"
                arrayType={isPass}
                name="clear_payments"
                field={reset_payment}
              />
            </div>
          ) : null}

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state) {
  return {
    initialValues: {
      pass: true
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'InvoiceCheckPaidForm',
  fields: ['pass', 'reset_payment'],
  validate: validate,
  touchOnBlur: false
}

InvoiceCheckPaidForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoiceCheckPaidForm)

export default InvoiceCheckPaidForm
