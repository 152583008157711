import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import KBUserSelect from './KBUserSelect'

var Select = require('react-select')
var KBUserInput = React.createClass({
  getInitialState() {
    return {
      selectedUsers: this.props.selectedUsers || []
    }
  },

  handleSelectChange(value) {
    this.setState({
      selectedUsers: Array.isArray(value) ? value : [value]
    })

    this.props.callback && this.props.callback(value)
    // update form field value
    this._updateFieldValue(value)
  },

  _updateFieldValue(value) {
    if (!value) {
      this.props.field.onChange(null)
      return
    }
    if (Array.isArray(value)) {
      value = (value && value.filter(v => !!v)) || []
      let fieldValue = value.map(json => json.id).join(',')
      this.props.field.onChange(fieldValue)
    } else {
      this.props.field.onChange(value.id)
    }
  },

  componentWillReceiveProps(nextProps) {
    const { selectedUsers, field, users } = this.props
    if (
      selectedUsers &&
      nextProps.selectedUsers &&
      nextProps.selectedUsers.length != selectedUsers.length
      // JSON.stringify(nextProps.selectedUsers) !== JSON.stringify(selectedUsers)
    ) {
      this.setState({
        selectedUsers: nextProps.selectedUsers
      })
      this._updateFieldValue(nextProps.selectedUsers)
    }
    if (field.value !== nextProps.field.value && !nextProps.field.value) {
      this.setState({
        selectedUsers: []
      })
    }
  },

  componentDidMount() {
    // initialize field value with selected users
    this._updateFieldValue(this.state.selectedUsers)
  },

  render() {
    const {
      field,
      placeholder,
      noResultsText,
      title,
      titleClass,
      users,
      valueRenderer,
      disabled,
      multi,
      isRequired,
      hint,
      style,
      onInputChange
    } = this.props

    return (
      <div
        className={classNames(
          this.props.className,
          field.touched && field.error ? 'kb-input-error' : 'kb-input'
        )}
        style={style}
      >
        {title && (
          <label className={titleClass}>
            {title}
            {isRequired ? <span className="must-fill">*</span> : ''}
          </label>
        )}
        <div>
          <KBUserSelect
            multi={multi}
            placeholder={placeholder}
            noResultsText={noResultsText}
            disabled={disabled}
            valueRenderer={valueRenderer}
            selectedUsers={this.state.selectedUsers}
            users={users}
            onInputChange={onInputChange}
            onUsersSelected={this.handleSelectChange}
          />

          {field.touched && field.error ? (
            <p className="lr-error">{field.error}</p>
          ) : (
              <p style={{ color: '#c8c8c8', fontSize: 12 }}>{hint}</p>
            )}
        </div>
      </div>
    )
  }
})

KBUserInput.propTypes = {
  field: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  placeholder: PropTypes.string,
  noResultsText: PropTypes.string,
  title: PropTypes.string,
  titleClass: PropTypes.string,
  style: PropTypes.object
}

export default KBUserInput
