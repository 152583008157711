import React, { Component, PropTypes } from 'react'
import { KBAvatar, KBTipsy } from 'components'

/**
 * 原内容区域 控制行
 * @description
 * 这里控制内容其余的显隐和选择
 */
var Row = React.createClass({
  displayName: 'Row',
  // 控制多选改变还是单选改变
  changeCheckBox(e) {
    const { multi } = this.context
    if (multi) {
      this.changeMulti(e)
    } else {
      this.chagneSingle(e)
    }
  },
  // 判断当前多选是父级还是自己，调用对应的逻辑
  changeMulti(e, isUpdate) {
    const { index, state } = this.props
    const { setMultiParentRowData } = this.context
    const checked = isUpdate || e.target.checked
    if (state == 'children') {
      return this.changeMultiChildren(checked)
    }

    setMultiParentRowData(this.changeMultiParent(checked), index)
  },
  // 多选行点击子级数据的情况
  changeMultiChildren(checked) {
    var { parent, index, parentIndex } = this.props
    const { setMultiChildRowData } = this.context

    var newRowData = parent
    newRowData.childrens[index].Tchecked = checked
    if (!checked && newRowData.Tchecked) {
      const checked = newRowData.childrens.find(json => json.Tchecked)
      newRowData.Tchecked = checked ? true : false
    }
    const currentCheckedData = newRowData.childrens.filter(
      json => json.Tchecked
    )
    if (newRowData.childrens.length == currentCheckedData.length) {
      newRowData.Tchecked = true
    }
    setMultiChildRowData(newRowData, parentIndex)
  },
  // 多选行点击父级数据的情况
  changeMultiParent(checked) {
    var newRowData = this.props.rowData
    newRowData.Tchecked = checked
    newRowData.childrens =
      newRowData.childrens &&
      newRowData.childrens.map(json => {
        json.Tchecked = checked
        return json
      })
    return newRowData
  },
  // 改变单选行的情况
  chagneSingle(e) {
    const { index } = this.props
    const { setRowDataOfIndex } = this.context
    const value = e.target.value
    const checked = e.target.checked
    const newRowData = this.props.rowData
    newRowData.Tchecked = checked
    setRowDataOfIndex(newRowData, index)
  },
  contextTypes: {
    multi: PropTypes.bool,
    setRowDataOfIndex: PropTypes.func,
    setMultiParentRowData: PropTypes.func,
    setDataOfIndex: PropTypes.func,
    setSource: PropTypes.func,
    showSE: PropTypes.func,
    isChildrenLoad: PropTypes.bool,
    isMiddle: PropTypes.bool,
    type: PropTypes.string,
    getExpandChilds: PropTypes.func,
    setMultiChildRowData: PropTypes.func
  },
  // 打开扩展方法
  expandChilds(callback) {
    const { rowData, index } = this.props
    const { getExpandChilds, setDataOfIndex, type, showSE } = this.context
    const { id } = rowData

    if (rowData.isData) {
      showSE(type, id, 'expand')
      return
    }
    showSE(type, id, 'loading')
    const childrens = getExpandChilds(id)
    if (Object.prototype.toString.call(childrens) == '[object Array]') {
      setDataOfIndex(type, id, childrens)
      return
    }

    childrens.then(json => {
      setDataOfIndex(type, id, json)
      callback && typeof callback == 'function' && callback()
    })
  },
  shrinkChilds() {
    const { rowData, index } = this.props
    const { showSE, type } = this.context
    const { id } = rowData
    showSE(type, id, 'shrink')
  },
  renderFold() {
    const { rowData, state } = this.props
    const { multi, isChildrenLoad, type } = this.context
    const expandState = rowData.expandState
    const isShowSourceChild = isChildrenLoad && type == 'source' && !state
    return (
      <div className="load-btn">
        {(!expandState || expandState == 'shrink') && isShowSourceChild ? (
          <span className="toggle-trans-btn" onClick={this.expandChilds}>
            <i className="iconfont icon-navigate_next" />
          </span>
        ) : (
          ''
        )}
        {expandState == 'expand' && isShowSourceChild ? (
          <span className="toggle-trans-btn" onClick={this.shrinkChilds}>
            <i className="iconfont icon-arrow_drop_down_circle" />
          </span>
        ) : (
          ''
        )}
        {expandState == 'loading' && isShowSourceChild ? (
          <span className="trans-loading" />
        ) : (
          ''
        )}
      </div>
    )
  },
  renderAddItem() {
    const { type, multi } = this.context
    if (type == 'target') {
      return (
        <div
          className="adder-subtractor-btn"
          onClick={this.addItem.bind(null, multi ? true : false)}
        >
          <i style={{ marginRight: 0 }} className="iconfont icon-close" />
        </div>
      )
    }
    return (
      <div
        className="adder-subtractor-btn"
        onClick={this.addItem.bind(null, multi ? true : false)}
      >
        <i style={{ marginRight: 0 }} className="iconfont icon-add" />
      </div>
    )
  },
  addItem(isMulti = true) {
    const { rowData, state } = this.props
    const { setDataOfIndex, isChildrenLoad, type } = this.context
    const expandState = rowData.expandState
    const isShrink = !expandState || expandState == 'shrink'
    const isShowSourceChild = isChildrenLoad && type == 'source' && !state

    if (isChildrenLoad && type == 'source' && !state && isShrink) {
      return this.expandChilds(this.addItemOfType.bind(null, type))
    }

    if (
      isMulti &&
      isShrink &&
      (!rowData.childrens || !rowData.childrens.length) &&
      state != 'children'
    ) {
      return
    }

    this.addItemOfType(type)
  },
  addItemOfType(type) {
    const { setSource } = this.context
    this.changeMulti(null, true)

    if (type == 'source') {
      setSource('right')
      return
    }

    setSource('left')
  },
  renderContent() {
    const { rowData, labelClass, state, isGroupAdd } = this.props
    const { isMiddle } = this.context
    return (
      <label className={labelClass}>
        {this.renderFold()}
        <div className="data-name">
          {state == 'children' ? (
            <KBAvatar
              style={{ display: 'inline-block', marginRight: '5px' }}
              imgStyle={{ marginRight: 5 }}
              user={rowData}
              size={20}
            />
          ) : (
            ''
          )}
          <KBTipsy content={rowData.name}>
            <span className="limited-label-name">{rowData.name}</span>
          </KBTipsy>
        </div>
        {isMiddle ? (
          <input
            type="checkbox"
            value={rowData.id}
            checked={rowData.Tchecked}
            onChange={this.changeCheckBox}
          />
        ) : (isGroupAdd && !state) || !isGroupAdd ? (
          this.renderAddItem()
        ) : null}
      </label>
    )
  },
  // 多选行的情况
  renderMultiRow() {
    const { multi, isChildrenLoad, type } = this.context
    const { rowData, state, boxClass, isGroupAdd } = this.props
    const expandState = rowData.expandState
    const isChildrens = (rowData.childrens && rowData.childrens.length) || ''
    const isShowSourceChild = isChildrenLoad && type == 'source' && !state
    console.log(rowData,'rowData');
    return (
      <div className={boxClass}>
        {this.renderContent()}
        {(type == 'target' || expandState == 'expand' || !isShowSourceChild) &&
        isChildrens &&
        rowData.childrens ? (
          <div className="second-tier-item">
            {rowData.childrens.map((row, index) => {
              return (
                <Row
                  rowData={row}
                  state="children"
                  boxClass="second-tier"
                  labelClass="children-label"
                  parent={rowData}
                  index={index}
                  parentIndex={this.props.index}
                  isGroupAdd={isGroupAdd}
                />
              )
            })}
          </div>
        ) : (
          expandState ==='expand'?<div style={{marginLeft:20}}>没有可用房间</div>:''
        )}
      </div>
    )
  },
  renderSingleRow() {
    const { rowData, boxClass, labelClass, state } = this.props
    const { isMiddle } = this.context
    return (
      <div className={boxClass}>
        <label className={labelClass}>
          <div className="data-name">
            <KBTipsy content={rowData.name}>
              <span className="limited-label-name">{rowData.name}</span>
            </KBTipsy>
          </div>
          {isMiddle ? (
            <input
              type="checkbox"
              value={rowData.id}
              checked={rowData.Tchecked}
              onChange={this.changeCheckBox}
            />
          ) : (
            this.renderAddItem()
          )}
        </label>
      </div>
    )
  },
  render() {
    const { multi } = this.context
    console.log(this.context);
    if (multi) {
      return this.renderMultiRow()
    }

    return this.renderSingleRow()
  }
})

Row.propTypes = {
  // 单行数据
  rowData: PropTypes.object.isRequired,
  // 索引值
  index: PropTypes.number.isRequired,
  // 点击选择框更改上级组件数据
  setRowDataOfIndex: PropTypes.func.isRequired
}

export default Row
