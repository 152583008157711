import React, { Component, PropTypes } from 'react'
import Content from './Content'

var Drop = React.createClass({
  render() {
    const { multi, selectData } = this.props
    return <Content {...this.props} />
  }
})

export default Drop
