import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { activitiesCommentsAction, apiCallFailure } from 'app/actions'
import { Link } from 'react-router'
import {
  util,
  preventDefaultPropagation,
  paginate,
  formatMinuteSecondSOL
} from 'utils/kbUtil'
import { ACTIVITY_STATUS_OBJ } from 'app/constants'
import {
  KBLoadingContainer,
  KBPopover,
  KBAvatar,
  KBDropdown,
  KBPagination,
  KBTableWithoutData
} from 'components'
import * as selectors from 'app/selectors'
import moment from 'moment'
import { getUserInfoUrl } from 'utils/kbUrl'
import { KBPopoverConfirm } from 'components/tools'
import { getServiceErrorMessage } from 'utils/kbUtil'

const STAR_NUM = [1, 2, 3, 4, 5]

var ActivitiesComments = React.createClass({
  displayName: 'ActivitiesComments',

  getInitialState() {
    return {
      loading: true
    }
  },

  componentDidMount() {
    this.loadData()
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.page) {
      this.loadData(nextProps.page)
    }
  },

  loadData(page) {
    const { activity_id, getActivitiesCommentsAction } = this.props
    let params = Object.assign({}, { per_page: 20, page: page || 1 })
    api
      .getActivitiesComments(activity_id, params)
      .then(
        json => {
          getActivitiesCommentsAction.success(json, {
            entityName: 'activitiesComments'
          })
          let pagination = paginate(json.response)
          this.setState({
            pagination
          })
        },
        error => {
          getActivitiesCommentsAction.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  approveComment(comment, can_show) {
    const {
      activity_id,
      updateActivitiesCommentsAction,
      deleteActivitiesCommentsAction
    } = this.props
    const params = {
      is_show: can_show
    }

    KBPopoverConfirm({
      name: '活动评论审核',
      context: `是否确认该条活动评论审核${can_show ? '' : '不'}通过？${
        can_show ? '' : '(审核不通过将会删除该条评论)'
      }`,
      callback: () => {
        if (can_show) {
          return api
            .approveActivityComment(activity_id, comment.id, params)
            .then(
              json => {
                updateActivitiesCommentsAction.success(json)
                KBPopover.close()
                return true
              },
              error => {
                this.handleServerError(error)
              }
            )
        } else {
          return api.deleteActivityComment(activity_id, comment.id).then(
            json => {
              deleteActivitiesCommentsAction.success(json, {
                id: comment.id,
                key: 'activitiesComments'
              })
              KBPopover.close()
              return true
            },
            error => {
              this.handleServerError(error)
            }
          )
        }
      }
    })
  },

  handleServerError(error) {
    const { apiCallFailureAction } = this.props

    apiCallFailureAction({
      status: 'error',
      message: getServiceErrorMessage(error)
    })
  },

  render() {
    const { loading, pagination } = this.state
    const { activitiesComments, activity_id, loc_id } = this.props
    return (
      <div className="nav-section-container f-right">
        <header className="nav-section-header clear-fix border-bottom">
          <div className="nav-section-header-title f-left">
            <span>活动评论</span>
          </div>
        </header>
        <KBLoadingContainer loading={loading}>
          <div style={{ padding: '0 30px' }}>
            <table className="content-table">
              <thead>
                <tr>
                  <th>姓名</th>
                  <th>时间</th>
                  <th>评分</th>
                  <th>评论</th>
                  <th>审核状态</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={activitiesComments.length > 0}
                tableHeadNum="5"
                tipMessage="暂无评论"
              >
                {activitiesComments &&
                  activitiesComments.map(comment => {
                    return (
                      <tr>
                        <td>
                          <div className="vf-center">
                            <KBAvatar
                              style={{
                                display: 'inline-block',
                                marginRight: '5px'
                              }}
                              imgStyle={{ marginRight: 5 }}
                              user={comment.creator}
                              size={20}
                            />
                            <Link
                              className="color-link"
                              to={getUserInfoUrl(
                                comment.creator && comment.creator.id
                              )}
                            >
                              {(comment.creator && comment.creator.name) || ''}
                            </Link>
                          </div>
                        </td>
                        <td>{formatMinuteSecondSOL(comment.created_at)}</td>
                        <td>
                          <div className="activity-stars">
                            {STAR_NUM.map(num => {
                              return (
                                <span
                                  className={
                                    comment.score >= num ? 'star_active' : ''
                                  }
                                >
                                  <i className="iconfont icon-star2" />
                                </span>
                              )
                            })}
                          </div>
                        </td>
                        <td style={{ width: '300px' }}>{comment.comment}</td>
                        <td>
                          {comment.is_show ? (
                            <span>已通过</span>
                          ) : (
                            <KBDropdown>
                              <KBDropdown.KBDropdownTrigger>
                                <div className="color-link">
                                  <span style={{ marginRight: 10 }}>
                                    未审核
                                  </span>
                                  <i className="iconfont icon-arrow_drop_down_circle" />
                                </div>
                              </KBDropdown.KBDropdownTrigger>
                              <KBDropdown.KBDropdownContent
                                style={{
                                  left: '50%',
                                  transform: 'translateX(-50%)'
                                }}
                              >
                                <div className="user-dropdown">
                                  <a
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.approveComment(comment, true)
                                    }}
                                  >
                                    审核通过
                                  </a>
                                  <a
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.approveComment(comment, false)
                                    }}
                                  >
                                    审核不通过
                                  </a>
                                </div>
                              </KBDropdown.KBDropdownContent>
                            </KBDropdown>
                          )}
                        </td>
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
            <KBPagination
              pagination={pagination}
              template={
                loc_id
                  ? `/admin/locations/${loc_id}/activities/${activity_id}/comments?page=#PAGE#`
                  : `/admin/memberships/memberManage/activities/${activity_id}/comments?page=#PAGE#`
              }
            />
          </div>
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let loc_id = props.params.id
  let activity_id = props.params.activity_id
  let page = parseInt(props.location.query && props.location.query.page)
  let activitiesComments = selectors.getActivitiesComments(state)
  page = isNaN(page) ? 1 : page

  return {
    loc_id,
    activity_id,
    activitiesComments,
    page
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getActivitiesCommentsAction: bindActionCreators(
      activitiesCommentsAction.replace,
      dispatch
    ),
    updateActivitiesCommentsAction: bindActionCreators(
      activitiesCommentsAction.update,
      dispatch
    ),
    deleteActivitiesCommentsAction: bindActionCreators(
      activitiesCommentsAction.delete,
      dispatch
    ),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch)
  }
}

ActivitiesComments = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesComments)

export default ActivitiesComments
