import React, { Component, PropTypes } from 'react'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'

var ActivityBox = React.createClass({
  displayName: 'ActivityBox',
  getInitialState() {
    return {
      tags: []
    }
  },
  componentDidMount() {
    api.getActivitiesHotTags().then(({ json }) => {
      this.setState({
        tags: json || []
      })
    })
  },
  render() {
    const { tags } = this.state
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { tags })
    )
    return <div style={{ height: '100%' }}>{childrenWithProps}</div>
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityBox)
