import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'

var KBRadioList = React.createClass({
  displayName: 'KBRadioList',
  getInitialState() {
    return {
      radioIndex: 0
    }
  },
  changeRadio(radioIndex, e) {
    const { arrayType, callback } = this.props
    this.setState({
      radioIndex
    })
    callback && callback(arrayType[radioIndex])
  },
  render() {
    const {
      type,
      className,
      divClassName,
      arrayType,
      name,
      groupStyle
    } = this.props
    const { radioIndex } = this.state

    return (
      <div className={className} style={groupStyle}>
        {arrayType.map((data, index) => {
          return (
            <div className={divClassName} key={index}>
              <input
                type="radio"
                id={index + name + 'radio'}
                name={name}
                value={data.id}
                checked={radioIndex == index}
                onChange={this.changeRadio.bind(null, index)}
              />
              <label htmlFor={index + name + 'radio'}>{data.name}</label>
            </div>
          )
        })}
      </div>
    )
  }
})

KBRadioList.propTypes = {
  className: PropTypes.string,
  arrayType: PropTypes.array
}

KBRadioList.defaultProps = {
  className: 'kb-radio-box',
  arrayType: []
}

export default KBRadioList
