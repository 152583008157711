import React, { Component, PropTypes } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import moment from 'moment'

// Component will receive date, locale and localeUtils props
var YearMonthForm = React.createClass({
  getInitialState() {
    const { defaultYear, currentYear } = this.props
    const toMonth = new Date(currentYear + 100, 11, 31, 23, 59)
    const fromMonth = new Date(defaultYear, 0, 1, 0, 0)

    return {
      toMonth,
      fromMonth
    }
  },
  getDefaultProps() {
    return {
      defaultYear: 2016
    }
  },
  handleChange(e, value) {
    const { selectedDay, onChange } = this.props
    let currentValue = e.target.value

    if (value == 'month') {
      onChange(
        moment(selectedDay)
          .clone()
          .set('month', currentValue)
      )
      return
    }

    onChange(
      moment(selectedDay)
        .clone()
        .set('year', currentValue)
    )
  },
  render() {
    const { date, localeUtils, onChange } = this.props
    const { toMonth, fromMonth } = this.state
    const months = [
      '一月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月'
    ]
    const years = []

    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i++) {
      years.push(i)
    }

    return (
      <div className="DayPicker-Caption">
        <select
          name="month"
          onChange={e => this.handleChange(e, 'month')}
          value={date.getMonth()}
        >
          {months.map((month, i) => (
            <option key={i} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select
          name="year"
          onChange={e => this.handleChange(e, 'years')}
          value={date.getFullYear()}
        >
          {years.map((year, i) => (
            <option key={i} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    )
  }
})

YearMonthForm.propTypes = {
  defaultYear: PropTypes.number
}

export default YearMonthForm
