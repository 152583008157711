import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import {
  opportunitiesActions,
  areaActions,
  intentAreaActions
} from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Router, Route, Link } from 'react-router'
import { routerActions } from 'react-router-redux'
import {
  checkObject,
  checkNull,
  formatHoursMinutesCHS,
  formatMinuteSecondEN
} from 'utils/kbUtil'
import {
  KBLoadingContainer,
  KBPopover,
  KBTipsy,
  KBTableWithoutData,
  KBAvatar
} from 'components'
import CrmRelation from './CrmRelation'
import OpportunityForm from './OpportunityForm'
import CrmNewRecordForm from './CrmNewRecordForm'
import OrganizationForm from '../organization/OrganizationForm'
import {
  OPPORTUNITIES_SOURCE_OBJ,
  CRM_OPPORTUNITY_STATES,
  OPPORTUNITY_STATES,
  CRM_DESIRE_OBJ,
  REQUIREMENT_TYPE_OBJ,
  CRM_AREA_TYPE_OBJ,
  OPPORTUNITY_DESIRES,
  CRM_COMPANY_TYPE_OBJ
} from 'app/constants'
import * as selectors from 'app/selectors'
import { KBPopoverConfirm } from 'components/tools'
import _ from 'lodash'
import { getUserInfoUrl } from 'utils/kbUrl'
import { canAccessSpaceRoles } from 'app/reducers/role'

var CrmOpportunity = React.createClass({
  displayName: 'CrmOpportunity',
  getInitialState() {
    return {
      loading: false,
      showRecordForm: false
    }
  },
  getDefaultProps() {
    return {}
  },
  componentDidMount() {
    const { opp, getIntentOfficesAction, opp_id } = this.props
    this.mounted = true

    api.getIntentOffices(opp.id || opp_id).then(
      json => {
        getIntentOfficesAction.success(json, {
          entityName: 'intentAreas'
        })
      },
      errors => {
        getIntentOfficesAction.failure(errors)
      }
    )

    this._loadData(this.props.opp_id)
  },
  componentWillReceiveProps(nextProps) {
    if (nextProps.opp_id != this.props.opp_id) {
      this._loadData(nextProps.opp_id)
    }
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(opp_id) {
    const { getOpportunityInfo, u_id } = this.props
    if (!opp_id) {
      return
    }

    this.setState({ loading: true })
    api
      .getOpportunityInfo(opp_id)
      .then(
        json => {
          getOpportunityInfo.success(json, { user_id: u_id })
          return null
        },
        errors => {
          getOpportunityInfo.failure(errors)
        }
      )
      .finally(() => {
        if (this.mounted) {
          this.setState({ loading: false })
        }
      })
  },

  updateOpp(update_type) {
    const { u_id, opp } = this.props
    if (!opp) {
      return
    }

    let tempOpp = Object.assign({}, opp)

    tempOpp.company = checkNull(tempOpp.contact && tempOpp.contact.company)
    tempOpp.notes = checkNull(tempOpp.notes)
    tempOpp.name = checkNull(tempOpp.title)
    tempOpp.contact_name = checkNull(tempOpp.contact && tempOpp.contact.name)
    tempOpp.wechat = checkNull(tempOpp.contact && tempOpp.contact.wechat)
    tempOpp.email = checkNull(tempOpp.contact && tempOpp.contact.email)
    tempOpp.mobile = checkNull(tempOpp.contact && tempOpp.contact.mobile)
    tempOpp.employee_number = checkNull(tempOpp.employee_number)
    tempOpp.employee_months = checkNull(tempOpp.employee_months)
    tempOpp.total_number = checkNull(tempOpp.total_number)
    tempOpp.followed_at = checkNull(tempOpp.followed_at)
    tempOpp.level = checkNull(tempOpp.level)
    tempOpp.source = checkNull((tempOpp.source && tempOpp.source.id) || '')
    tempOpp.industry_id = tempOpp.industry ? tempOpp.industry.id : ''
    tempOpp.area_type = checkNull(tempOpp.area_type || '')
    tempOpp.company_type = checkNull(
      (tempOpp.contact && tempOpp.contact.company_type) || ''
    )
    tempOpp.source_text = checkNull(tempOpp.source_text || '')
    tempOpp.desire = checkNull(tempOpp.desire || '')
    tempOpp.industry_id = checkNull(
      (tempOpp.customer.industry && tempOpp.customer.industry.id) || ''
    )

    KBPopover.show(
      <OpportunityForm
        initialValues={tempOpp}
        u_id={u_id}
        opp_id={opp.id}
        opp={tempOpp}
        state="update"
        update_type={update_type || 'all'}
      />
    )
  },

  createOrganization(type, loc_id, customer, industry_id, company_name) {
    const { routerActions } = this.props
    let params = Object.assign(
      {},
      { is_important: false, opportunity_id: customer.id },
      { external_visible: false },
      { is_legal: false },
      { full_name: company_name },
      { industry_id }
    )
    KBPopover.show(
      <OrganizationForm
        idstry_id={industry_id}
        location_id={loc_id}
        onSubOrgCreated={this.toCreateSubscription}
        status={type}
        initialValues={params}
      />
    )
    // routerActions.push(`/admin/locations/${loc_id}/new_organization?source=crm&idstry_id=${industry_id}&opportunity_id=${customer.id}&opp_name=${customer.customer.name}&full_name=${company_name}`)
  },

  hideRecordForm() {
    this.setState({
      showRecordForm: false
    })
  },

  deleteVisitRecord(record_id) {
    const { opp, visit_records } = this.props

    KBPopoverConfirm({
      name: '删除参观记录',
      context: `确认删除当前参观记录？`,
      callback: () => {
        // TODO: 需要跟后端确认删除方式
        // return
        let updatedVisitRecords = visit_records.map(rec => {
          delete rec.branch_type
          delete rec.created_at
          delete rec.source_id
          delete rec.updated_at
          if (rec.id === record_id) {
            rec.deleted_at = new Date()
          }
          rec.visit_date = formatMinuteSecondEN(rec.visit_date)
          return rec
        })

        let form = {
          interest_locations: updatedVisitRecords
        }
        return api.updateOpportunity(opp.id, form).then(
          json => {
            this.props.updateOpportunity.success(json, {
              id: opp.id,
              key: 'opportunities'
            })
            KBPopover.close()
          },
          errors => {
            this.props.updateOpportunity.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  createSubscription() {
    const { opp, routerActions } = this.props
    if (opp.organization == undefined) {
      // routerActions.push(`/admin/locations/${opp.win_location.id}/organizations?type=crmrecord&id=${opp.id}&industry_id=${opp.customer.industry ? opp.customer.industry.id : ''}`)
      let location_id = opp.win_location && opp.win_location.id
      let customer_id = opp.id
      let industry_id =
        opp.customer && opp.customer.industry ? opp.customer.industry.id : ''
      let company_name = (opp.contact && opp.contact.company) || ''
      this.createOrganization(
        'crmrecord',
        location_id,
        opp,
        industry_id,
        company_name
      )
    } else {
      this.toCreateSubscription && this.toCreateSubscription()
    }
  },

  toCreateSubscription(org) {
    const { opp, routerActions } = this.props
    let org_id = (opp.organization && opp.organization.id) || (org && org.id)
    routerActions.push(
      `/admin/crm/opportunities/sub/organizations/${org_id}/subscriptions/new?opportunity_id=${opp.id}&location_id=${opp.win_location.id}&typeSource=crmrecord`
    )
  },

  toSubscription() {
    const { opp, routerActions } = this.props
    routerActions.push(
      `/admin/crm/opportunities/sub/organizations/${opp.organization.id}/subscriptions/${opp.subscription.id}?typeSource=crmrecord`
    )
  },

  render() {
    const {
      opp_id,
      opp,
      locationsObj,
      usersObj,
      intentAreas,
      interested_locs,
      visit_records
    } = this.props
    const { showRecordForm } = this.state
    const {
      interest_locations,
      desire,
      requirement_type,
      area_types,
      rentable_area,
      employee_number,
      employee_months,
      area_capacity,
      requirement_text,
      source_text,
      customer,
      contact,
      rent_type,
      source
    } = opp
    const canEditOpp = !opp.subscription

    let leader = (opp &&
      opp.sales_team &&
      opp.sales_team.find(json => json.is_leader == true)) || {
      sales_user: {
        name: '-'
      }
    }
    let org_id = (opp && opp.organization && opp.organization.id) || null
    let winState = ['success', 'entered', 'archived']
    const VISIT_RECORDS_STATE = {
      reserved: {
        name: '已预约',
        class: 'finance-default receipt-going'
      },
      visited: {
        name: '已参观',
        class: 'finance-default receipt-finish'
      },
      overdue: {
        name: '爽约',
        class: 'finance-default receipt-refunding'
      }
    }

    return (
      <div className="kb-content-container clear-fix">
        <section>
          {opp ? (
            <KBLoadingContainer loading={!opp}>
              <header className="nav-header border-bottom">
                <div className="nav-section-header-title">
                  <span>{opp.title}</span>
                  <div className="f-right">
                    {/* <button className="bordered-btn" onClick={ this.updateOpp.bind(null, 'all') }>
                    <i className="iconfont icon-edit" style={{marginTop:2}}/>编辑
                  </button> */}
                    {(opp.state == 'success' || opp.state == 'entered') &&
                    opp.win_location ? (
                      opp.subscription ? (
                        opp.organization ? (
                          <button
                            className="bordered-btn"
                            onClick={this.toSubscription}
                          >
                            <i
                              className="iconfont icon-edit"
                              style={{ marginTop: 2 }}
                            />
                            查看合同
                          </button>
                        ) : (
                          ''
                        )
                      ) : (
                        <button
                          className="bordered-btn"
                          onClick={this.createSubscription}
                        >
                          <i
                            className="iconfont icon-edit"
                            style={{ marginTop: 2 }}
                          />
                          创建合同
                        </button>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="nav-section-action-bar">
                  {opp.contact && opp.contact.company_type == 'personal' ? (
                    ''
                  ) : (
                    <div className="action-bar-type m-right-sm">
                      <span className="m-none">公司名称:</span>
                      <span>
                        {winState.includes(opp.state)
                          ? (opp.organization && opp.organization.name) || '无'
                          : opp.contact && (opp.contact.company || '无')}
                      </span>
                    </div>
                  )}
                  {interested_locs && interested_locs.length ? (
                    <div className="d-iblock m-right-sm color-nine">
                      <i className="iconfont icon-position" />
                      {interested_locs
                        .map(loc => {
                          return loc.name
                        })
                        .join(', ')}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="d-iblock color-nine">
                    <i className="iconfont icon-phone" />
                    <span>
                      {opp.contact &&
                        (opp.contact.mobile || opp.contact.phone || '无')}
                    </span>
                  </div>
                  <div className="d-iblock m-left-sm color-nine">
                    <i className="iconfont icon-WeChat" />
                    <span>{opp.contact && (opp.contact.wechat || '无')}</span>
                  </div>
                  {opp.subscription ? (
                    <div className="d-iblock m-left-sm color-nine">
                      <i className="iconfont icon-contract" />
                      <span>合同编号：</span>
                      {opp.win_location &&
                      opp.win_location.id &&
                      org_id &&
                      opp.subscription.id ? (
                        <Link
                          className="a-hover color-nine"
                          to={`/admin/locations/${opp.win_location &&
                            opp.win_location
                              .id}/organizations/${org_id}/subscriptions/${
                            opp.subscription.id
                          }`}
                        >
                          <span>{opp.subscription.serial || ''}</span>
                        </Link>
                      ) : (
                        <span>{opp.subscription.serial || ''}</span>
                      )}
                    </div>
                  ) : null}
                </div>
              </header>
              <div className="nav-section-content-container">
                <div
                  className="nav-content-1-md f-left"
                  style={{ marginTop: 30, width: 770 }}
                >
                  <div
                    className="nav-comment-container kb-form-container crm-opp-info"
                    style={{ padding: 0 }}
                  >
                    {// 已毕业和已入驻状态显示
                    opp &&
                    (opp.state == 'entered' || opp.state == 'archived') ? (
                      <div style={{ marginBottom: '30px' }}>
                        <div
                          className="kb-title m-bottom-sm"
                          style={{ border: 0 }}
                        >
                          <span className="color-six">客户状态</span>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                          {opp.win_location && opp.win_location.id && org_id ? (
                            <Link
                              to={`/admin/locations/${opp.win_location.id}/organizations/${org_id}`}
                            >
                              <KBTipsy content="点击进入公司详情">
                                <img
                                  style={{ width: '24px', cursor: 'pointer' }}
                                  src={
                                    opp.state == 'entered'
                                      ? 'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img-icon-yes@3x.png'
                                      : 'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_biye@3x.png'
                                  }
                                />
                              </KBTipsy>
                            </Link>
                          ) : (
                            <img
                              style={{ width: '24px', cursor: 'pointer' }}
                              src={
                                opp.state == 'entered'
                                  ? 'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img-icon-yes@3x.png'
                                  : 'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_biye@3x.png'
                              }
                            />
                          )}

                          <span
                            style={{
                              display: 'inline-block',
                              fontSize: '20px',
                              marginTop: '10px',
                              marginLeft: '14px',
                              verticalAlign: 'text-bottom'
                            }}
                          >
                            {OPPORTUNITY_STATES[opp.state]}
                          </span>
                          {opp.locations &&
                          opp.locations.length > 0 &&
                          opp.state == 'entered'
                            ? opp.locations.map((location, index) => {
                                if (this.props.isSpaceCRMOnly) {
                                  return (
                                    <span
                                      key={index}
                                      style={{
                                        display: 'inline-block',
                                        marginRight: '10px',
                                        fontSize: '20px',
                                        verticalAlign: 'text-bottom'
                                      }}
                                    >
                                      {location && location.name}
                                    </span>
                                  )
                                }
                                return (
                                  <Link to={`/admin/locations/${location.id}`}>
                                    <span
                                      key={index}
                                      style={{
                                        display: 'inline-block',
                                        marginRight: '10px',
                                        fontSize: '20px',
                                        verticalAlign: 'text-bottom',
                                        color: '#5fa7ec'
                                      }}
                                    >
                                      {location && location.name}
                                    </span>
                                  </Link>
                                )
                              })
                            : ''}
                        </div>
                      </div>
                    ) : (
                      <div className="opp-status-container">
                        <div className="opp-status">
                          {CRM_OPPORTUNITY_STATES.map((data, index) => {
                            return (
                              <div
                                key={index}
                                className={`single-status index-${index + 1} ${
                                  opp.state == data.id ? 'status-active' : ''
                                }`}
                              >
                                <i
                                  className={`iconfont icon-crm_status_${index +
                                    1}`}
                                />
                                <span>{data.name}</span>
                              </div>
                            )
                          })}
                        </div>
                        <span
                          className="certain-btn change-status-btn"
                          onClick={() => {
                            this.setState({ showRecordForm: true })
                          }}
                        >
                          更新客户状态
                        </span>
                      </div>
                    )}
                    {showRecordForm ? (
                      <CrmNewRecordForm
                        customer={this.props.opp}
                        createSubscription={this.createSubscription}
                        hideRecordForm={this.hideRecordForm}
                      />
                    ) : null}

                    <fieldset className="fieldset">
                      <legend>参观记录</legend>
                      <table className="content-table" style={{ marginTop: 0 }}>
                        <thead>
                          <tr>
                            <th>参观分店</th>
                            <th>状态</th>
                            <th>参观时间</th>
                            <th>带领人</th>
                            <th>备注</th>
                            <th style={{ textAlign: 'right' }}>操作</th>
                          </tr>
                        </thead>
                        <KBTableWithoutData
                          hasData={visit_records && visit_records.length > 0}
                          tableHeadNum="6"
                          tipMessage="暂无参观记录"
                          tdStyle={{ height: 180 }}
                        >
                          {visit_records.map((record, index) => {
                            let type = record.visit_state
                            return (
                              <tr key={index}>
                                <td>
                                  {record.location_id
                                    ? locationsObj[record.location_id]
                                      ? locationsObj[record.location_id].name ||
                                        ''
                                      : '暂无分店'
                                    : ''}
                                </td>
                                <td>
                                  {(type && VISIT_RECORDS_STATE[type] && (
                                    <span
                                      className={
                                        VISIT_RECORDS_STATE[type].class
                                      }
                                      style={{ marginLeft: 0 }}
                                    >
                                      {VISIT_RECORDS_STATE[type].name}
                                    </span>
                                  )) ||
                                    ''}
                                </td>
                                <td>
                                  {(record.visit_date &&
                                    formatHoursMinutesCHS(record.visit_date)) ||
                                    ''}
                                </td>
                                <td>
                                  {record.receiver_id ? (
                                    <div className="vf-center">
                                      <KBAvatar
                                        style={{
                                          display: 'inline-block',
                                          marginRight: '5px'
                                        }}
                                        imgStyle={{ marginRight: 5 }}
                                        user={usersObj[record.receiver_id]}
                                        size={20}
                                      />
                                      <Link
                                        className="color-link"
                                        to={getUserInfoUrl(record.receiver_id)}
                                      >
                                        {(usersObj[record.receiver_id] &&
                                          usersObj[record.receiver_id].name) ||
                                          ''}
                                      </Link>
                                    </div>
                                  ) : null}
                                </td>
                                <td>{record.notes || ''}</td>
                                <td style={{ textAlign: 'right' }}>
                                  <i
                                    className="iconfont icon-delete"
                                    onClick={this.deleteVisitRecord.bind(
                                      null,
                                      record.id
                                    )}
                                  />
                                </td>
                              </tr>
                            )
                          })}
                        </KBTableWithoutData>
                      </table>
                    </fieldset>

                    <fieldset className="fieldset">
                      <legend>
                        客户意向
                        {canEditOpp ? (
                          <i
                            className="iconfont icon-edit a-hover m-left-sm"
                            onClick={this.updateOpp.bind(
                              null,
                              'customer_intention'
                            )}
                          />
                        ) : null}
                      </legend>
                      <div className="form-content" style={{ marginBottom: 0 }}>
                        {desire ? (
                          <div className="kb-row">
                            <div className="kb-form-group kb-form-alone kb-div-c18">
                              <label>入驻意愿</label>
                              <div className="group-content">
                                <span>{CRM_DESIRE_OBJ[desire]}</span>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {requirement_type && requirement_type.length ? (
                          <div className="kb-row">
                            <div className="kb-form-group kb-form-alone kb-div-c18">
                              <label>意向类型</label>
                              <div className="group-content">
                                {requirement_type.includes('desk') ? (
                                  <div className="form-content crm-requirement-square">
                                    <div className="kb-row">
                                      <span className="title">
                                        {REQUIREMENT_TYPE_OBJ['desk']}
                                      </span>
                                    </div>
                                    <div className="kb-row">
                                      <div className="kb-form-group kb-form-alone kb-div-c18">
                                        <label>工位类型</label>
                                        <div className="group-content">
                                          <span>
                                            {(area_types &&
                                              area_types.length != 0 &&
                                              area_types
                                                .map(type => {
                                                  return CRM_AREA_TYPE_OBJ[type]
                                                })
                                                .join('、')) ||
                                              '暂无'}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="kb-row">
                                      <div className="kb-form-group kb-form-alone kb-div-c18">
                                        <label>出租类型</label>
                                        <div className="group-content">
                                          {rent_type == 'by_area' ? (
                                            rentable_area &&
                                            rentable_area != '-1' ? (
                                              <span>{`面积出租(${rentable_area}㎡)`}</span>
                                            ) : (
                                              '面积出租'
                                            )
                                          ) : rent_type == 'by_desk' ? (
                                            employee_number &&
                                            employee_number != '-1' ? (
                                              <span>{`工位出租(${employee_number}个)`}</span>
                                            ) : (
                                              '工位出租'
                                            )
                                          ) : (
                                            '暂无'
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="kb-row">
                                      <div className="kb-form-group kb-form-alone kb-div-c18">
                                        <label>入驻月数</label>
                                        <div className="group-content">
                                          {employee_months &&
                                          employee_months != '-1' ? (
                                            <span>{`${employee_months}个月`}</span>
                                          ) : (
                                            '暂无'
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {requirement_type.includes('field') ? (
                                  <div className="form-content crm-requirement-square">
                                    <div className="kb-row">
                                      <span className="title">
                                        {REQUIREMENT_TYPE_OBJ['field']}
                                      </span>
                                    </div>
                                    <div className="kb-row">
                                      <div className="kb-form-group kb-form-alone kb-div-c18">
                                        <label>容纳人数</label>
                                        <div className="group-content">
                                          <span>
                                            {area_capacity &&
                                            area_capacity != '-1' ? (
                                              <span>{`${area_capacity}人`}</span>
                                            ) : (
                                              '暂无'
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                                {requirement_type.includes('other') ? (
                                  <div className="form-content crm-requirement-square">
                                    <div className="kb-row">
                                      <span className="title">
                                        {REQUIREMENT_TYPE_OBJ['other']}
                                      </span>
                                    </div>
                                    <div className="kb-row">
                                      <div className="kb-form-group kb-form-alone kb-div-c18">
                                        <label>具体信息</label>
                                        <div className="group-content">
                                          <span>
                                            {requirement_text || '暂无'}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ) : null}

                        <div className="kb-row">
                          <div className="kb-form-group kb-form-alone kb-div-c18">
                            <label>意向分店</label>
                            <div className="group-content">
                              <span>
                                {interested_locs && interested_locs.length
                                  ? interested_locs.map(loc => {
                                      if (this.props.isSpaceCRMOnly) {
                                        return (
                                          <span
                                            style={{ marginRight: 10 }}
                                          >{`${locationsObj[loc.id] &&
                                            locationsObj[loc.id].name}`}</span>
                                        )
                                      }
                                      return (
                                        <Link
                                          className="a-link"
                                          style={{
                                            color: '#2ea1f8',
                                            marginRight: 10
                                          }}
                                          to={`/admin/locations/${loc.id}`}
                                        >
                                          {`${locationsObj[loc.id] &&
                                            locationsObj[loc.id].name}`}
                                        </Link>
                                      )
                                    })
                                  : '暂无'}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="kb-row">
                          <div className="kb-form-group kb-form-alone kb-div-c18">
                            <label>推荐房间</label>
                            <div className="group-content">
                              {intentAreas && intentAreas.length
                                ? intentAreas.map(area => {
                                    return (
                                      <div className="form-content crm-requirement-square intent-areas-presentation">
                                        <div className="kb-row">
                                          <div className="kb-form-group kb-form-alone kb-div-c39">
                                            <label>推荐房间</label>
                                            <div className="group-content">
                                              {this.props.isSpaceCRMOnly ? (
                                                `${area.area &&
                                                  area.area.name}${
                                                  area.location &&
                                                  area.location.id
                                                    ? `(${area.location.name})`
                                                    : ''
                                                }`
                                              ) : (
                                                <Link
                                                  className="a-link"
                                                  style={{ color: '#2ea1f8' }}
                                                  to={`/admin/locations/${area.location.id}/areas/desks/list?area_id=${area.area.id}`}
                                                >
                                                  {`${area.area &&
                                                    area.area.name}${
                                                    area.location &&
                                                    area.location.id
                                                      ? `(${area.location.name})`
                                                      : ''
                                                  }`}
                                                </Link>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="kb-row">
                                          <div className="kb-form-group kb-form-alone kb-div-c39">
                                            <label>兴趣程度</label>
                                            <div className="group-content">
                                              <span>
                                                {OPPORTUNITY_DESIRES[
                                                  area.desire
                                                ] || '暂无'}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="kb-row">
                                          <div className="kb-form-group kb-form-alone kb-div-c39">
                                            <label>备注</label>
                                            <div className="group-content">
                                              <span>
                                                {area.notes || '暂无'}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })
                                : '暂无'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset className="fieldset">
                      <legend>
                        客户信息
                        {canEditOpp ? (
                          <i
                            className="iconfont icon-edit a-hover m-left-sm"
                            onClick={this.updateOpp.bind(null, 'customer_info')}
                          />
                        ) : null}
                      </legend>
                      <div className="form-content" style={{ marginBottom: 0 }}>
                        <div className="kb-row kb-form-66">
                          <div className="kb-form-group kb-div-c39">
                            <label>客户名称</label>
                            <div className="group-content">
                              <span>
                                {(customer && customer.name) || '暂无'}
                              </span>
                            </div>
                          </div>
                          <div className="kb-form-group kb-div-173">
                            <label className="label-rt">联系人</label>
                            <div className="group-content">
                              <span>{(contact && contact.name) || '暂无'}</span>
                            </div>
                          </div>
                        </div>
                        <div className="kb-row kb-form-66">
                          <div className="kb-form-group kb-div-c39">
                            <label>电话</label>
                            <div className="group-content">
                              <span>
                                {(contact && contact.mobile) || '暂无'}
                              </span>
                            </div>
                          </div>
                          <div className="kb-form-group kb-div-173">
                            <label className="label-rt">代理人电话</label>
                            <div className="group-content">
                              <span>
                                {(contact && contact.agent_mobile) || '暂无'}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="kb-row kb-form-66">
                          <div className="kb-form-group kb-div-c39">
                            <label>邮箱</label>
                            <div className="group-content">
                              <span>
                                {(contact && contact.email) || '暂无'}
                              </span>
                            </div>
                          </div>
                          <div className="kb-form-group kb-div-173">
                            <label className="label-rt">微信号</label>
                            <div className="group-content">
                              <span>
                                {(contact && contact.wechat) || '暂无'}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="kb-row kb-form-66">
                          <div className="kb-form-group kb-div-c39">
                            <label>客户来源</label>
                            <div className="group-content">
                              <span>{(source && source.name) || '暂无'}</span>
                            </div>
                          </div>
                          <div className="kb-form-group kb-div-173">
                            <label className="label-rt">来源分类</label>
                            <div className="group-content">
                              <span>
                                {(source &&
                                  source.source_type &&
                                  OPPORTUNITIES_SOURCE_OBJ[
                                    source.source_type
                                  ]) ||
                                  '暂无'}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="kb-row kb-form-66">
                          <div className="kb-form-group kb-div-c39">
                            <label>具体来源</label>
                            <div className="group-content">
                              <span>{source_text || '暂无'}</span>
                            </div>
                          </div>
                          <div />
                        </div>
                      </div>
                    </fieldset>

                    <fieldset className="fieldset">
                      <legend>
                        公司信息
                        {canEditOpp ? (
                          <i
                            className="iconfont icon-edit a-hover m-left-sm"
                            onClick={this.updateOpp.bind(null, 'company_info')}
                          />
                        ) : null}
                      </legend>
                      <div className="form-content" style={{ marginBottom: 0 }}>
                        <div className="kb-row kb-form-66">
                          <div className="kb-form-group kb-div-c39">
                            <label>公司名称</label>
                            <div className="group-content">
                              <span>
                                {(contact && contact.company) || '暂无'}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="kb-row kb-form-66">
                          <div className="kb-form-group kb-div-c39">
                            <label>行业</label>
                            <div className="group-content">
                              <span>
                                {(contact &&
                                  contact.industry &&
                                  contact.industry.name) ||
                                  '暂无'}
                              </span>
                            </div>
                          </div>
                          <div className="kb-form-group kb-div-173">
                            <label className="label-rt">类型</label>
                            <div className="group-content">
                              <span>
                                {(contact &&
                                  contact.company_type &&
                                  CRM_COMPANY_TYPE_OBJ[contact.company_type]) ||
                                  '暂无'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <CrmRelation leader={leader} opp={opp} opp_id={opp_id} />
              </div>
            </KBLoadingContainer>
          ) : (
            ''
          )}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { user, entities } = state
  const { opp_id } = props.params

  let u_id = user.id
  let entitiesOpp = checkObject(entities, 'opportunities')
  let opp = (entitiesOpp && entitiesOpp[opp_id]) || {}
  const { interest_locations, visit_info } = opp
  const visit_records = visit_info || []
  let interested_locs = []
  // interest_locations && interest_locations.length && interest_locations.map(loc => {
  //   if (loc.visit_date) {
  //   } else {
  //     interested_locs.push(loc)
  //   }
  // })
  interested_locs = _.uniqBy(interest_locations, 'id')

  const locationsObj = selectors.getSerializeLocationObj(state)
  const usersObj = selectors.getObjectMembers(state)

  const intentAreas = selectors.getIntentAreas(state) || []

  let isSpaceCRMOnly =
    canAccessSpaceRoles(user, ['crm_admin', 'crm']) &&
    !canAccessSpaceRoles(user, ['admin'])

  return {
    u_id,
    opp_id,
    opp,
    locationsObj,
    usersObj,
    intentAreas,
    visit_records,
    interested_locs,
    isSpaceCRMOnly
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    getOpportunityInfo: bindActionCreators(opportunitiesActions.get, dispatch),
    getIntentOfficesAction: bindActionCreators(
      intentAreaActions.replace,
      dispatch
    ),
    updateOpportunity: bindActionCreators(opportunitiesActions.update, dispatch)
  }
}

CrmOpportunity.PropTypes = {
  handleSubmit: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(CrmOpportunity)
