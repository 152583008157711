import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import DayPicker, { DateUtils } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import { KBDropdown } from 'components'
import YearMonthForm from './YearMonthForm'

const defaultFormat = 'YYYY-MM-DD'

var KBDateInput = React.createClass({
  getDefaultProps() {
    return {
      placeholder: '',
      showPicker: false,
      className: 'kb-box',
      disabled: false,
      isShowCenter: false
    }
  },

  getInitialState() {
    const { disabledTimeShow, currentYear, value, isEmpty } = this.props
    let currentYear_n = parseInt(currentYear) || new Date().getFullYear()
    let defaultTime

    if (disabledTimeShow) {
      defaultTime = this.props.field.value
    } else {
      defaultTime = isEmpty
        ? this.props.field.value
        : this.props.field.value || new Date() || new Date()
    }
    return {
      value: value || defaultTime,
      currentYear: currentYear_n,
      fromMonth: new Date(2016, 0, 1, 0, 0),
      toMonth: new Date(currentYear_n + 10, 11, 31, 23, 59)
    }
  },

  componentDidMount(){
    const {field} = this.props
    const {value} = this.state
    // 组件设置初始值到field
    let changeValue = ''
    if(value){
      changeValue=moment(value).format(defaultFormat)
    }
    field.onChange(changeValue)
  },

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.field.value &&
      this.props.field.value !== nextProps.field.value
    ) {
      this.setState({ value: nextProps.field.value })
    }
  },

  handleDayClick(e, day) {
    const { disabledDays } = this.props
    if (disabledDays && disabledDays(day)) {
      return null
    }
    day = moment(day).format('YYYY-MM-DD')
    this.setState({
      value: day
    })
    this.props.field.onChange(day)
    this.props.onChange && this.props.onChange(day)
    this._hideDayPicker()
  },

  _showDayPicker() {
    this.refs.daypickerDropdown.show()
    this.setState({
      showPicker: true
    })
  },

  _hideDayPicker() {
    this.refs.daypickerDropdown.hide()
    this.setState({
      showPicker: false
    })
  },

  changeMonth(initialMonth) {
    initialMonth = moment(initialMonth).format('YYYY-MM-DD')
    this.setState({ value: initialMonth })
    this.props.field.onChange(initialMonth)
    this.props.onChange && this.props.onChange(initialMonth)
  },

  _fakeOnChange() {
    // this is a fake onChange to get around warnings:
    // warning.js:44 Warning: Failed form propType: You provided a `value` prop to a form field without an `onChange` handler.
  },

  render() {
    const {
      field,
      placeholder,
      title,
      className,
      defaultYear,
      defaultMonth = 0,
      defaultDay = 1,
      titleClass,
      isRequired,
      isRequiredR,
      hint,
      style,
      disabled,
      isShowCenter,
      isEmpty
    } = this.props
    const { value, showPicker, fromMonth, toMonth, currentYear } = this.state
    let defaultDate = new Date(defaultYear, defaultMonth, defaultDay, 0, 0)
    const selectedDay = value
      ? moment(value).toDate()
      : isEmpty
      ? moment(defaultDate).toDate()
      : new Date()
    const valueFormatted = value ? moment(value).format(defaultFormat) : ''
    const contentStyle = isShowCenter
      ? { left: '50%', transform: 'translateX(-50%)' }
      : { left: 0, transform: 'translateX(0)' }

    return (
      <div className={className} style={style}>
        {title && !placeholder && (
          <label className={titleClass}>
            {isRequiredR ? <span className="must-fillR">*</span> : ''}
            {title}
            {isRequired ? <span className="must-fill">*</span> : ''}
          </label>
        )}

        <KBDropdown ref="daypickerDropdown">
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              placeholder={placeholder}
              onChange={this._fakeOnChange}
              value={valueFormatted}
              onFocus={this._showDayPicker}
              disabled={disabled}
              className={`bg-bottom_deg ${classNames(
                field.touched && field.error ? 'kb-input-error' : 'kb-input'
              )}`}
            />
            {showPicker ? (
              <KBDropdown.KBDropdownContent style={contentStyle}>
                <DayPicker
                  dir="zh-cn"
                  locale="zh-cn"
                  ref="daypicker"
                  localeUtils={MomentLocaleUtils}
                  initialMonth={selectedDay}
                  selectedDays={day => DateUtils.isSameDay(selectedDay, day)}
                  onDayClick={this.handleDayClick}
                  disabledDays={this.props.disabledDays}
                  fromMonth={fromMonth}
                  toMonth={toMonth}
                  captionElement={
                    <YearMonthForm
                      defaultYear={defaultYear || '2016'}
                      currentYear={currentYear}
                      onChange={this.changeMonth}
                      selectedDay={selectedDay}
                    />
                  }
                />
              </KBDropdown.KBDropdownContent>
            ) : (
              ''
            )}
            {field.touched && field.error ? (
              <p className="lr-error">{field.error}</p>
            ) : (
              <p
                style={{
                  color: '#c8c8c8',
                  fontSize: 12,
                  padding: '2px 0 0 11px'
                }}
              >
                {hint}
              </p>
            )}
          </div>
        </KBDropdown>
      </div>
    )
  }
})

KBDateInput.propTypes = {
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  selectedDay: PropTypes.object,
  onChange: PropTypes.func,
  disabledDays: PropTypes.func,
  // YearMonthForm 中默认的起始year
  defaultYear: PropTypes.number,
  // 是否显示默认时间
  disabledTimeShow: PropTypes.bool
}

export default KBDateInput
