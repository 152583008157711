import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { api, apiUtils } from 'app/services'
import { announcementsActions, apiCallFailure } from 'app/actions'
import AnnouncementForm from './AnnouncementForm'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBPopover,
  KBTipsy,
  KBTableWithoutData
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { connect } from 'react-redux'
import { OPPORTUNITY_STATES } from 'app/constants'
import { util, toImageProxyDirect, formatMinuteSecondEN } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import { paginate } from 'utils/kbUtil'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'

var Announcements = React.createClass({
  displayName: 'Announcements',
  getInitialState() {
    return {
      announcements: [],
      pagination: null,
      loading: false
    }
  },

  componentDidMount() {
    this._loadData(1)
  },

  _loadData(page) {
    const { getAnnouncementsActions } = this.props
    const per_page = 30
    this.setState({ loading: true })
    api
      .getAnnouncements({ page, per_page })
      .then(
        json => {
          let pagination = paginate(json.response)
          let announcements = getEntitiesJsonArray(json, 'announcements')
          getAnnouncementsActions.success(json)

          this.setState({
            announcements,
            pagination
          })
        },
        errors => {
          getAnnouncementsActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (
      this.props.page != nextProps.page ||
      (nextProps.announcements.length &&
        nextProps.announcements.length - this.props.announcements.length == 1)
    ) {
      this._loadData(nextProps.page)
    }
  },

  createSection() {
    KBPopover.show(<AnnouncementForm />)
  },

  deleteAnnouncements(announcement) {
    const { id } = announcement
    const { deleteAnnouncementsActions } = this.props
    KBPopoverConfirm({
      name: '删除公告',
      context: `是否要删除公告` + announcement.title,
      callback: () => {
        return api
          .deleteAnnouncements(id)
          .then(
            json => {
              deleteAnnouncementsActions.success(json, {
                id,
                key: 'announcements'
              })
              this.setState({
                announcements: this.state.announcements.filter(
                  announcement => announcement.id !== id
                )
              })
              KBPopover.close()
              return true
            },
            error => {
              deleteAnnouncementsActions.failure(error)
            }
          )
          .finally(() => {
            KBPopover.close()
          })
      }
    })
  },

  render() {
    const { pagination, announcements, loading } = this.state

    return (
      <section className="kb-content-container">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span>公告管理</span>
          </div>
          <button className="bordered-btn f-right" onClick={this.createSection}>
            <i className="iconfont icon-add" />
            添加新公告
          </button>
        </header>
        <KBLoadingContainer loading={loading}>
          <div className="nav-section-content-container">
            <p style={{ marginBottom: 15, marginTop: 15 }}>
              设置App中首页的轮播图文公告，用于发布新闻或通知。只对用户显示最近的4则公告。公告必须链接到已有的网页，例如微信公众号文章或新闻网站地址。
            </p>
            <table className="content-table">
              <thead>
                <tr>
                  <th>图片</th>
                  <th>链接</th>
                  <th>创建时间</th>
                  <th>删除</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={announcements.length > 0}
                tableHeadNum="4"
                tipMessage="暂无公告"
              >
                {announcements &&
                  announcements.map((announcement, index) => {
                    return (
                      <tr key={index}>
                        <td className="announcement-img" style={{ width: 200 }}>
                          <img
                            src={toImageProxyDirect(
                              announcement.image,
                              120,
                              68
                            )}
                          />
                        </td>
                        <td style={{ width: 300 }}>
                          <KBTipsy content={announcement.link}>
                            <a
                              className="color-link t-over d-iblock"
                              style={{ maxWidth: 270 }}
                              href={announcement.link}
                              target="_blank"
                            >
                              {announcement.link}
                            </a>
                          </KBTipsy>
                        </td>
                        <td>{formatMinuteSecondEN(announcement.created_at)}</td>
                        <td>
                          <i
                            className="iconfont icon-delete"
                            alt="删除公告"
                            onClick={e => {
                              this.deleteAnnouncements(announcement)
                              e.stopPropagation()
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
            <KBPagination
              pagination={pagination}
              template={`/admin/memberships/memberManage/announcements?page=#PAGE#`}
            />
          </div>
        </KBLoadingContainer>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const announcements = selectors.getAnnouncementsOfId(state)
  const { params, location } = props
  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page
  return {
    announcements,
    page
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAnnouncementsActions: bindActionCreators(
      announcementsActions.get,
      dispatch
    ),
    deleteAnnouncementsActions: bindActionCreators(
      announcementsActions.delete,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Announcements)
