import _ from 'lodash'

function spaceRole(state = {}, action) {
  const data = action.response
  let newState = _.cloneDeep(state)
  if (action.type === 'GETSPACEROLE_SUCCESS') {
    newState = data.json
    return newState
  }

  if (action.type === 'UPDATESPACEROLE_SUCCESS' && action.key) {
    newState.roles = newState.roles.filter(r => r.role !== action.key)
    newState.roles = newState.roles.concat(data.json.roles || [])
    newState.roles_settings = data.json.roles_settings
    return newState
  }

  return state
}

export default spaceRole
