import React, { Component, PropTypes } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { util } from 'utils/kbUtil'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearErrorState } from 'app/actions'

var KBError = React.createClass({
  getInitialState() {
    return {
      error: this.props.error
    }
  },
  _hide() {
    this.setState({ error: {} })
    this._clearErrorState()
  },
  _clearErrorState() {
    const { clearErrorStateActions } = this.props
    clearErrorStateActions()
  },
  componentWillMount() {
    if (!Object.keys(this.props.error).length) {
      return
    }
    util.setTimeout(
      'error',
      () => {
        this._hide()
      },
      5000
    )
  },
  componentWillReceiveProps(nextProps) {
    if (!Object.keys(nextProps.error).length) {
      return
    }
    this.setState({ error: nextProps.error })
    util.setTimeout(
      'error',
      () => {
        this._hide()
      },
      5000
    )
  },
  render() {
    let { title, message } = this.state.error
    let err =
      title || message ? (
        <div className="popup-error">
          <span className="icon">
            <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img-icon-no@3x.png" />
          </span>
          <div className="msg-container" style={title ? { padding: 11 } : {}}>
            {title ? <span>{title}</span> : null}
            {message ? (
              <span
                className="message"
                style={title ? {} : { fontSize: '16px' }}
              >
                {message}
              </span>
            ) : null}
          </div>
          <a onClick={this._hide}>&times;</a>
        </div>
      ) : null

    return (
      <ReactCSSTransitionGroup
        transitionName="background"
        transitionEnterTimeout={750}
        transitionLeaveTimeout={750}
      >
        {err}
      </ReactCSSTransitionGroup>
    )
  }
})

KBError.propTypes = {
  error: PropTypes.object
}

function mapStateToProps(state, ownProps) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    clearErrorStateActions: bindActionCreators(clearErrorState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KBError)
