import React, { Component, PropTypes } from 'react'

/*
  hasData         : (Boolean)           列表中是否有数据
  tableHeadNum    : (String(Number))    当前 table 表头项的数目，决定提示消息占几列显示
  tipMessage      : (String)            可自定义的提示消息
  errorImgSrc     : (String)            可自定义的提示图片，无则显示默认图
*/

var KBTableWithoutData = React.createClass({
  childrenJsx() {
    let childrenType = this.props.children && this.props.children.type
    let elementTypes = ['div', 'section']
    if (childrenType && elementTypes.includes(childrenType)) {
      return (
        <tr>
          <td>{this.props.children}</td>
        </tr>
      )
    }
    return this.props.children
  },

  render() {
    const {
      hasData,
      tableHeadNum,
      tipMessage,
      errorImgSrc,
      className,
      tdStyle
    } = this.props
    const tdStyleDefault = {
      height: '280px',
      textAlign: 'center',
      color: '#c3d0e9',
      fontSize: '15px',
      cursor: 'default'
    }

    return (
      <tbody className={className}>
        {!hasData ? (
          <tr style={{ border: 0, backgroundColor: '#ffffff' }}>
            <td
              colSpan={tableHeadNum}
              style={{ ...tdStyleDefault, ...tdStyle, paddingTop: 40 }}
            >
              <img
                src={errorImgSrc || 'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/que.png'}
                style={{ width: 180 }}
              />
              {tipMessage ? (
                <div style={{ marginTop: '10px' }}>{tipMessage}</div>
              ) : null}
            </td>
          </tr>
        ) : (
          this.childrenJsx()
        )}
      </tbody>
    )
  }
})

KBTableWithoutData.PropTypes = {
  hasData: PropTypes.bool.isRequired
}

export default KBTableWithoutData
