import _ from 'lodash'

function locationSetting(state = {}, action) {
  const data = action.response
  let newState = _.cloneDeep(state)

  if (!action.location_id) {
    return state
  }

  newState[action.location_id] = newState[action.location_id] || {}

  if (action.type === 'GETLOCATIONSETTING_SUCCESS') {
    newState[action.location_id] = data.json
    return newState
  }

  if (action.type === 'UPDATELOCATIONSETTING_SUCCESS' && action.key) {
    let key = action.key
    if (typeof key === 'object') {
      key.forEach(k => {
        newState[action.location_id][k] = data.json[k]
      })
      return newState
    }
    newState[action.location_id][key] = data.json[key]
    return newState
  }

  return newState
}

export default locationSetting
