import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { api, apiUtils } from 'app/services'
import { topicsActions, sectionsActions } from 'app/actions'
import FaqSectionForm from './FaqSectionForm'
import { KBLoadingContainer, KBAvatar, KBPopover } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { connect } from 'react-redux'
import { OPPORTUNITY_STATES } from 'app/constants'
import { util, momentFromNow } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import moment from 'moment'
import FaqSectionTopics from './FaqSectionTopics'

var FaqSectionBox = React.createClass({
  displayName: 'FaqSectionBox',
  getInitialState() {
    return {
      isShow: this.props.isShow
    }
  },

  componentDidMount() {
    const { section, getTopicsActions } = this.props
    const id = section.id
    api.getTopics(id, { per_page: 30 }).then(
      json => {
        getTopicsActions.success(json)
      },
      errors => {
        getTopicsActions.failure(errors)
      }
    )
  },

  editSection() {
    const { section } = this.props
    KBPopover.show(
      <FaqSectionForm
        state="update"
        section={section}
        initialValues={section}
      />
    )
  },

  changeDisplay() {
    this.setState({
      isShow: !this.state.isShow
    })
  },

  deleteSection(section) {
    const { id } = section
    const { deleteSectionActions } = this.props
    KBPopoverConfirm({
      name: '删除分类',
      context: `删除分类${section.title}会同时删除该分类下的文章，请确认是否进行该操作`,
      callback: () => {
        return api.deleteSections(id).then(json => {
          deleteSectionActions.success(json, { id, key: 'sections' })
          KBPopover.close()
          return true
        })
      }
    })
  },

  render() {
    const { section } = this.props
    const { isShow } = this.state

    return (
      <div className="faq-box">
        <div className="faq-title">
          <span className="faq-title-content" onClick={this.changeDisplay}>
            <i
              className={`iconfont ${
                isShow ? 'icon-arrow_drop_down_circle' : 'icon-navigate_next'
              }`}
              style={{ marginRight: 10 }}
            />
            {section.title}
            <i
              className="iconfont icon-edit"
              onClick={e => {
                this.editSection()
                e.stopPropagation()
              }}
            />
            <i
              className="iconfont icon-delete"
              onClick={e => {
                this.deleteSection(section)
                e.stopPropagation()
              }}
            />
          </span>
        </div>
        {isShow ? <FaqSectionTopics section={section} /> : ''}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

FaqSectionBox.propTypes = {
  section: PropTypes.object.isRequired,
  isShow: PropTypes.bool
}

function mapDispatchToProps(dispatch) {
  return {
    getTopicsActions: bindActionCreators(topicsActions.get, dispatch),
    deleteSectionActions: bindActionCreators(sectionsActions.delete, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqSectionBox)
