import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'

var KBSelect = React.createClass({
  changeSelect(e) {
    const value = e.target.value
    const { onChange, field } = this.props
    field.onChange(value)
    onChange && onChange(value)
  },
  render() {
    const {
      field,
      className,
      defaultValue,
      defaultName,
      arrayData,
      title,
      titleClass,
      selectClass,
      selectStyle,
      style,
      isRequired,
      isRequiredR,
      hint,
      disabled
    } = this.props
    return (
      <div className={classNames(className)} style={style}>
        {title && (
          <label className={titleClass}>
            {isRequiredR ? <span className="must-fillR">*</span> : ''}
            {title}
            {isRequired ? <span className="must-fill">*</span> : ''}
          </label>
        )}
        <div>
          <select
            value={field.value || ''}
            className={selectClass}
            style={selectStyle}
            onChange={this.changeSelect}
            disabled={disabled}
          >
            {defaultValue ? (
              <option value={defaultValue}>{defaultName}</option>
            ) : (
              ''
            )}
            {arrayData &&
              Object.keys(arrayData).map(function(value, index) {
                let data = arrayData['' + value]
                return (
                  <option
                    disabled={!!data.disabled}
                    value={data.id}
                    key={index}
                  >
                    {data.name}
                  </option>
                )
              })}
          </select>
          {field.touched && field.error ? (
            <p className="lr-error">{field.error}</p>
          ) : (
            <p style={{ color: '#c8c8c8', fontSize: 12 }}>{hint}</p>
          )}
        </div>
      </div>
    )
  }
})

KBSelect.propTypes = {
  field: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  defaultName: PropTypes.string,
  className: PropTypes.string
}

KBSelect.defaultProps = {
  className: '',
  defaultValue: '',
  defaultName: '请选择'
}

export default KBSelect
