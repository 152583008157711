import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import ReactDOM from 'react-dom'
import { reduxForm, getValues } from 'redux-form'
import { areaActions } from 'app/actions'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBDateInput,
  KBUserInput,
  KBRadio,
  KBPopover,
  KBForm,
  KBFormError,
  KBButton,
  KBCheckBox,
  KBMorePhotoUpload,
  KBImageCropperInput,
  KBSelect
} from 'components'
import DeskNumber from 'components/views/DeskNumber'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import { ORIENTATION_STATES } from 'app/constants'
import { ADD_AREA } from 'app/constants/hint'
import * as selectors from 'app/selectors'
import { toImageProxyDirect } from 'utils/kbUtil'

var AreasForm = React.createClass({
  getInitialState() {
    const area = this.props.area && this.props.area.orientation
    const selectedContent = area != null ? area : ''
    return {
      area_type: this.props.area && this.props.area.area_type,
      isUpdate: !!this.props.area_id,
      selectedContent: selectedContent,
      lockChecked: this.props.area && this.props.area.lock,
      wallChecked: this.props.area && this.props.area.wall,
      locFloors: [],
      merchants: [],
      taxArray: [],
      virtualFloors: []
    }
  },

  getLocFloors() {
    const { location_id } = this.props

    api.getLocationFloors(location_id).then(
      json => {
        let locFloors = json.response.body || []
        locFloors = locFloors.map(l_f => {
          l_f.name = l_f.layer_num
          return l_f
        })
        this.setState({
          locFloors
        })
      },
      error => {
        console.log(error)
      }
    )
  },
  getAreas() {
    api.getAreasWithoutSchema({
      location_id: this.props.loc_id,
      includes: 'subscriptions,organizations,areas',
      area_type: 'floor',
      per_page: 1000
    }).then(res => {
      this.setState({
        virtualFloors: res.json || []
      })
    })
  },

  componentDidMount() {
    const {
      fields: {
        physical_rate,
        rentable_rate,
        is_tower_location,
        area_member_type
      },
      area_physical_rate,
      area_rentable_rate,
      isTowerLoc,
      area,
      location_id
    } = this.props
    let init_physical_rate = physical_rate.value || area_physical_rate
    let init_rentable_rate = rentable_rate.value || area_rentable_rate

    physical_rate.onChange(
      Math.floor(parseFloat(init_physical_rate || 0) * 1000000) / 10000
    )
    rentable_rate.onChange(
      Math.floor(parseFloat(init_rentable_rate || 0) * 1000000) / 10000
    )
    is_tower_location.onChange(isTowerLoc)
    if (!this.state.isUpdate) {
      area_member_type.onChange('fwpsw')
    }
    this.getLocFloors()
    this.getAreas()
    api.getMerchants({ location_id }).then(res => {
      const { json = [] } = res
      this.setState({ merchants: json })
    })
    api.getInvoiceApplicationSetting({ location_id }).then(res => {
      const { json = [] } = res
      this.setState(
        {
          taxArray: json.map(item => ({ ...item, name: item.account_name }))
        },
        () => {
          if (area && area.id) {
            // 给税收分类赋值
            this.applicationChange(
              (area.invoicing_application_setting || {}).id
            )
          }
        }
      )
    })
  },

  createAreaForm(model) {
    const { loc_id } = this.props
    const { placeState, selectedContent, lockChecked, wallChecked } = this.state
    model.lock = lockChecked
    model.wall = wallChecked
    model.physical_rate = Math.floor(model.physical_rate * 10000) / 1000000
    model.rentable_rate = Math.floor(model.rentable_rate * 10000) / 1000000
    model.listing_price = model.listing_price || 0
    model.lowest_price = model.lowest_price || 0
    if (model.merchant_id === '-1') model.merchant_id = null
    if (model.invoicing_application_setting_id === '-1')
      model.invoicing_application_setting_id = null
    if (model.area_type == 'floor') {
      model = {
        name: model.name,
        area_type: model.area_type
      }
    }

    if (model.redirect_mini === 'false' || !model.redirect_mini) {
      ;[
        'appid',
        'mini_id',
        'mini_path',
        'mini_qrcode_image_url',
        'mini_name',
        'mini_redirect_title',
        'mini_redirect_content'
      ].forEach(key => {
        model[key] = ''
      })
    }

    if (model.self_setuped === 'unset') {
      delete model.star_point_settings
      delete model.self_setuped
    } else {
      // 自定义
      model.settings = {
        ...model.settings,
        star_point_settings: {
          ex_rate: Number(model.ex_rate),
          de_rate: model.de_rate / 100
        }
      }

      delete model.star_point_settings
      delete model.self_setuped
      delete model.ex_rate
      delete model.de_rate
    }

    if(!model.bridgego_visible || model.bridgego_visible === 'false') {
      model.fake_order_num = 0
    }

    return api.createArea(loc_id, model).then(json => {
      this.props.createAreaAction.success(json, { location_id: loc_id })
      let area = getEntitiesJsonArray(json, 'areas')
      area && this.props.onAreaCreated && this.props.onAreaCreated(area)
      KBPopover.close()
      return json
    })
  },

  updateAreaForm(model) {
    const { area_id } = this.props
    const { placeState, selectedContent, lockChecked, wallChecked } = this.state
    model.orientation = selectedContent != 'other' ? selectedContent : ''
    model.lock = lockChecked
    model.wall = wallChecked
    model.listing_price = model.listing_price || 0
    model.lowest_price = model.lowest_price || 0
    model.physical_rate = Math.floor(model.physical_rate * 10000) / 1000000
    model.rentable_rate = Math.floor(model.rentable_rate * 10000) / 1000000
    if (model.area_type == 'floor') {
      model = {
        name: model.name,
        area_type: model.area_type
      }
    }
    if (model.merchant_id === '-1') model.merchant_id = null
    if (model.invoicing_application_setting_id === '-1')
      model.invoicing_application_setting_id = null

    if (model.redirect_mini === 'false' || !model.redirect_mini) {
      ;[
        'appid',
        'mini_id',
        'mini_path',
        'mini_qrcode_image_url',
        'mini_name',
        'mini_redirect_title',
        'mini_redirect_content'
      ].forEach(key => {
        model[key] = ''
      })
    }

    if (model.self_setuped === 'unset') {
      delete model.star_point_settings
      delete model.self_setuped
    } else {
      // 自定义
      model.settings = {
        ...model.settings,
        star_point_settings: {
          ex_rate: Number(model.ex_rate),
          de_rate: model.de_rate / 100
        }
      }

      delete model.star_point_settings
      delete model.self_setuped
      delete model.ex_rate
      delete model.de_rate
    }

    if(!model.bridgego_visible || model.bridgego_visible === 'false') {
      model.fake_order_num = 0
    }

    return api.updateArea(area_id, model).then(json => {
      this.props.updateAreaAction.success(json)
      let area = getEntitiesJsonArray(json, 'areas')
      area && this.props.areaEdited && this.props.areaEdited(area)
      KBPopover.close()
    })
  },

  AreaForm(model) {
    //liang
    model.images =
      (model.images &&
        model.images.length &&
        typeof model.images == 'string' &&
        model.images.split(',')) ||
      []
    delete model.type
    if (this.state.isUpdate) {
      // already exists
      return this.updateAreaForm(model)
    }
    return this.createAreaForm(model)
  },

  _changeAreaType(event) {
    this.setState({ area_type: event.target.value })
    const {
      fields: { area_type }
    } = this.props
    area_type.onChange(event)
  },

  changeInvoiceContent(id) {
    const {
      fields: { orientation }
    } = this.props
    orientation.onChange(id)
    this.setState({
      selectedContent: id
    })
  },

  lockSetting(status) {
    this.setState({
      lockChecked: status
    })
  },

  wallSetting(status) {
    this.setState({
      wallChecked: status
    })
  },

  changePointType(value) {
    const {
      fields: { area_member_type }
    } = this.props

    area_member_type.onChange(value)
  },

  applicationChange(val) {
    const { taxArray } = this.state
    const {
      fields: { xforceplus_tax_setting_id }
    } = this.props
    const objMain = taxArray.find(i => i.id == val)
    let valueStr = ''
    if (objMain) {
      const objItem = objMain.invoicing_xforceplus_settings.find(
        i => i.invoice_type === 'desk_reservation'
      )
      if (objItem) {
        valueStr = objItem.name
      }
    }
    xforceplus_tax_setting_id.onChange(valueStr)
  },

  render() {
    const {
      fields: {
        name,
        area_type,
        area_member_type,
        device_ids,
        floor,
        capacity,
        desk_serial_numbers,
        size,
        listing_price,
        non_entering_listing_price,
        non_entering_day_listing_price,
        lowest_price,
        day_listing_price,
        bridge_listing_price,
        physical_rate,
        rentable_rate,
        floor_ids,
        images,
        checkin_date,
        features,
        floor_info_id,
        bridgego_visible,
        merchant_id,
        invoicing_application_setting_id,
        xforceplus_tax_setting_id,
        fake_order_num,
        // 第三方小程序字段
        redirect_mini,
        appid,
        mini_id,
        mini_path,
        mini_qrcode_image_url,
        mini_name,
        mini_redirect_title,
        mini_redirect_content,
        self_setuped,
        ex_rate,
        de_rate
      },
      error,
      submitting,
      handleSubmit,
      areaState,
      values,
      loc_id,
      type,
      defaultImages,
      isScale,
      isTowerLoc,
      area = {}
    } = this.props

    const {
      placeState,
      selectedContent,
      lockChecked,
      wallChecked,
      locFloors,
      merchants,
      taxArray,
      virtualFloors
    } = this.state
    const hint = ADD_AREA
    const orientationArray = ORIENTATION_STATES

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    const POINT_TYPES = [
      {
        title: `尊享会员`,
        value: 'fwpsw'
      },
      {
        title: `高级会员`,
        value: 'fwpsin'
      }
    ]

    const bridgegoVisible = [
      {
        title: '是',
        value: true
      },
      {
        title: '否',
        value: false
      }
    ]

    const oneClass = {
      className: 'kb-form-group kb-form-210 kb-form-alone'
    }
    const priceType =
      area_type.value === 'public_office' ||
      area_type.value === 'flexible_office'
        ? '工位'
        : area_type.value === 'private_office'
        ? '房间'
        : ''
    let selectedLocationFloors = locFloors.filter(
      f => area && area.floor_info_id == f.id
    )
    const selectedFloors = (virtualFloors || []).filter(
      f => area && area.floor_ids && area.floor_ids.includes(f.id)
    )

    return (
      <KBForm
        type="reduxForm"
        ref="areaForm"
        onSubmit={handleSubmit(this.AreaForm)}
        fields={this.props.fields}
      >
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">{type ? '修改' : '添加新'}分区</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <p className="color-nine" style={{ marginLeft: 30, marginTop: 10 }}>
          {hint.area}
        </p>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              hint={hint.name}
              isRequired={true}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="名称"
              fistFoucs={true}
            />
          </div>

          <div className="kb-row">
            <div className="kb-form-group kb-form-c18 kb-form-alone">
              <label>
                <span className="must-fill">*</span>类型
              </label>
              <div
                className="kb-form-group kb-div-33"
                style={{ position: 'relative' }}
              >
                <div className="kb-form-group kb-div-11">
                  <div>
                    <input
                      type="radio"
                      {...area_type}
                      value="private_office"
                      checked={area_type.value === 'private_office'}
                      onChange={this._changeAreaType}
                      id="close-office"
                    />
                  </div>
                  <label
                    className="label"
                    style={{ marginTop: 0 }}
                    htmlFor="close-office"
                  >
                    独立办公室
                  </label>
                </div>
                {
                  // isTowerLoc ? null :
                  <div className="kb-form-group kb-div-11">
                    <div>
                      <input
                        type="radio"
                        {...area_type}
                        value="public_office"
                        checked={area_type.value === 'public_office'}
                        onChange={this._changeAreaType}
                        id="open-office"
                      />
                    </div>
                    <label
                      className="label"
                      style={{ marginTop: 0 }}
                      htmlFor="open-office"
                    >
                      开放办公区
                    </label>
                  </div>
                }
                {
                  // isTowerLoc ? null :
                  <div
                    className="kb-form-group kb-div-11"
                    style={{ width: '25%' }}
                  >
                    <div>
                      <input
                        type="radio"
                        {...area_type}
                        value="flexible_office"
                        checked={area_type.value === 'flexible_office'}
                        onChange={this._changeAreaType}
                        id="flexible-office"
                      />
                    </div>
                    <label
                      className="label"
                      htmlFor="flexible-office"
                      style={{ whiteSpace: 'nowrap', marginTop: 0 }}
                    >
                      灵活办公区
                    </label>
                  </div>
                }
                <div
                  className="kb-form-group kb-div-11"
                  style={{ width: '25%' }}
                >
                  <div>
                    <input
                      type="radio"
                      {...area_type}
                      value="floor"
                      checked={area_type.value === 'floor'}
                      onChange={this._changeAreaType}
                      id="floor"
                    />
                  </div>
                  <label
                    className="label"
                    htmlFor="floor"
                    style={{ whiteSpace: 'nowrap', marginTop: 0 }}
                  >
                    虚拟区域
                  </label>
                </div>
                {area_type.touched && area_type.error ? (
                  <p className="lr-error">
                    {area_type.touched && area_type.error}
                  </p>
                ) : (
                  <p
                    style={{
                      position: 'absolute',
                      bottom: '-10px',
                      color: '#999',
                      fontSize: 10
                    }}
                  >
                    有多个合同的开放分区不能更改为独立办公室
                  </p>
                )}
              </div>
            </div>
          </div>
          {/* 当封闭区域时设置 */}
          {area_type.value === 'private_office' ? (
            <div className="kb-row">
              <KBRadio
                isRequired={true}
                className="kb-form-group kb-form-c178 kb-form-alone"
                inClassName="kb-from-initial"
                divClassName="kb-from-radio"
                rightLabel="label-rt"
                title="分级"
                arrayType={POINT_TYPES}
                name="fwpsw"
                field={area_member_type}
              />
            </div>
          ) : null}
          {// isTowerLoc &&
          area_type.value != 'floor' ? (
            <div className="kb-row">
              <KBUserInput
                isRequired={true}
                title="所属楼层"
                className="kb-form-group kb-form-c18 kb-form-alone"
                placeholder="选择分区所属楼层"
                field={floor_info_id}
                multi={false}
                users={locFloors}
                selectedUsers={selectedLocationFloors}
              />
            </div>
          ) : null}

          {area_type.value === 'flexible_office' && (
            <div className="kb-row">
              <KBRadio
                className="kb-form-group kb-form-c178 kb-form-alone"
                inClassName="kb-from-initial"
                divClassName="kb-from-radio"
                rightLabel="label-rt"
                title="对奕桥小程序是否可见"
                arrayType={bridgegoVisible}
                name="bridgego_visible"
                field={bridgego_visible}
              />
            </div>
          )}
          {area_type.value === 'floor' ? null : (
            <div>
              <div className="kb-row">
                <KBUserInput
                  title="所属区域"
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  placeholder="选择分区所属区域"
                  field={floor_ids}
                  multi={true}
                  users={virtualFloors}
                  selectedUsers={selectedFloors}
                />
              </div>
              {
                // isTowerLoc ? null :
                <div className="kb-row kb-form-66">
                  <KBInput
                    {...halfClass}
                    isRequired={area_type.value !== 'floor' && !isTowerLoc}
                    type="number"
                    title="容纳人数"
                    field={capacity}
                  />
                  <KBInput
                    {...halfClass}
                    isRequired={area_type.value !== 'floor' && !isTowerLoc}
                    inputClass="input-area"
                    type="text"
                    title="面积"
                    titleClass="label-rt"
                    field={size}
                  />
                </div>
              }
              {/*<div className="kb-row">*/}
              {/*<div className={ oneClass.className }>*/}
              {/*<label style={{ width: '13%' }}>朝向</label>*/}
              {/*<div>*/}
              {/*<div className="kb-form-group r1_radios">*/}
              {/*{*/}
              {/*orientationArray.map((data, index) => {*/}
              {/*return (*/}
              {/*<div key={ index } className="kb-form-group r1_radio" onClick={ this.changeInvoiceContent.bind(null, data.id) }>*/}
              {/*<div className={ `radio-btn ${selectedContent == data.id ? 'radio-btn-active' : ''}` }></div>*/}
              {/*<label className="label" >{ data.name }</label>*/}
              {/*</div>*/}
              {/*)*/}
              {/*})*/}
              {/*}*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}

              {/*<div className="kb-row" >*/}
              {/*<div style={{ color: '#7F8FA4', fontSize: '11px', marginBottom: 5}}>硬件设备</div>*/}
              {/*<div className="kb-row kb-form-66">*/}
              {/*<div className="f-left">*/}
              {/*<KBCheckBox  checked = { lockChecked } callback={ this.lockSetting } style={{display: 'inline-block',marginRight: 15}} />*/}
              {/*<span style={{verticalAlign: 'middle',color: '#354052'}}>有锁</span>*/}
              {/*</div>*/}
              {/*<div className="f-right">*/}
              {/*<KBCheckBox  checked = { wallChecked } callback={ this.wallSetting } style={{display: 'inline-block',marginRight: 15}} />*/}
              {/*<span style={{verticalAlign: 'middle',color: '#354052'}}>靠墙</span>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
              {area_type.value === 'flexible_office' ? (
                <div className="kb-row kb-form-66">
                  <KBInput
                    {...halfClass}
                    isRequired={false}
                    inputClass="input-dollar-month"
                    type="text"
                    title="工位按月售价"
                    field={listing_price}
                  />
                  <KBInput
                    {...halfClass}
                    titleClass="label-rt"
                    type="text"
                    inputClass="input-dollar"
                    field={lowest_price}
                    title="最低工位价格"
                  />
                </div>
              ) : (
                <div className="kb-row kb-form-66">
                  <KBInput
                    {...halfClass}
                    isRequired={false}
                    inputClass="input-dollar"
                    type="text"
                    title={`${priceType}价格`}
                    field={listing_price}
                  />
                  {/** <KBInput {...halfClass} titleClass='label-rt' type="text" inputClass="input-dollar" field={ non_entering_listing_price } title={`${priceType}非入驻价格`}/> **/}
                  <KBInput
                    {...halfClass}
                    titleClass="label-rt"
                    type="text"
                    inputClass="input-dollar"
                    field={lowest_price}
                    title={`最低${priceType}价格`}
                  />
                </div>
              )}
              {area_type.value === 'flexible_office' ? (
                <div className="kb-row kb-form-84">
                  <div className="kb-form-66">
                    <KBInput
                      className="kb-form-group kb-div-48"
                      disabled={area.can_modify_price === false}
                      {...halfClass}
                      titleClass="label-rt"
                      type="text"
                      inputClass={`input-dollar-day ${
                        area.can_modify_price === false
                          ? 'kb-input-disabled'
                          : ''
                      }`}
                      field={non_entering_day_listing_price}
                      title="一般价格"
                      hint="针对所有注册成为凯德星会员的会员购买的价格"
                    />
                    <KBInput
                      className="kb-form-group kb-div-48"
                      disabled={area.can_modify_price === false}
                      {...halfClass}
                      isRequired={false}
                      inputClass={`input-dollar-day ${
                        area.can_modify_price === false
                          ? 'kb-input-disabled'
                          : ''
                      }`}
                      type="text"
                      title="办公楼会员价格"
                      field={day_listing_price}
                      hint="在本系统中登记的会员预定价格"
                    />
                  </div>
                  <KBInput
                    className="kb-form-group kb-div-48"
                    disabled={area.can_modify_price === false}
                    {...halfClass}
                    isRequired={false}
                    inputClass={`input-dollar-day ${
                      area.can_modify_price === false ? 'kb-input-disabled' : ''
                    }`}
                    type="text"
                    title="奕桥会员价格"
                    field={bridge_listing_price}
                    hint="所属公司有联办合同的会员预定是使用的价格"
                  />
                </div>
              ) : null}
              {/* {area_type.value === 'flexible_office' ? (
                <div className="kb-row kb-form-66">

                </div>
              ) : null} */}

              {bridgego_visible.value && bridgego_visible.value !== 'false' && (
                <div className="kb-row">
                  <KBInput
                    isRequired
                    className="kb-form-group kb-form-c18 kb-form-alone"
                    type="text"
                    title="订单基数"
                    hint="小程序显示的订单数=订单基数+实际订单数"
                    field={fake_order_num}
                  />
                </div>
              )}

              {/* 星积分设置 */}
              {area_type.value === 'flexible_office' && (
                <div
                  className="kb-row"
                  style={{
                    marginTop: '10px'
                  }}
                >
                  <KBRadio
                    className="kb-form-group kb-div-c48"
                    groupStyle={{ width: '100%' }}
                    divClassName="kb-form-group kb-div-33"
                    rightLabel="label-rt"
                    title="星积分设置"
                    arrayType={[
                      {
                        value: 'unset',
                        title: '与项目统一的星积分兑换比例和抵扣比例保持一致'
                      },
                      {
                        value: 'custom',
                        title: '设置独立的星积分兑换比例和抵扣比例'
                      }
                    ]}
                    field={self_setuped}
                    name="self_setuped"
                    isRequired
                  />
                  {self_setuped.value === 'custom' && (
                    <div>
                      <div className="kb-row">
                        <KBInput
                          isRequired
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          title="兑换比例"
                          hint="钱与虚拟币的兑换比例(1元=x币)"
                          field={ex_rate}
                        />
                      </div>
                      <div className="kb-row">
                        <KBInput
                          isRequired
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          inputClass="input-percent"
                          title="抵扣比例"
                          hint="星积分抵扣在订单中的最高使用占比，可以设置0~100，为0的则不支持星积分抵扣"
                          field={de_rate}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {area_type.value === 'flexible_office' ? (
                <div>
                  <div className="kb-row">
                    <KBSelect
                      className="kb-form-group kb-form-c18 kb-form-alone"
                      title="税盘"
                      field={invoicing_application_setting_id}
                      arrayData={taxArray}
                      defaultValue="-1"
                      onChange={this.applicationChange}
                    />
                  </div>
                  <div className="kb-row">
                    <KBInput
                      className="kb-form-group kb-form-c18 kb-form-alone"
                      title="税收分类"
                      field={xforceplus_tax_setting_id}
                      disabled
                    />
                  </div>
                  <div className="kb-row">
                    <KBSelect
                      className="kb-form-group kb-form-c18 kb-form-alone"
                      title="汇付账号"
                      field={merchant_id}
                      arrayData={merchants}
                      defaultValue="-1"
                    />
                  </div>
                </div>
              ) : null}
              {isScale ? (
                <div className="kb-row kb-form-66">
                  <KBInput
                    {...halfClass}
                    inputClass="input-percent"
                    type="number"
                    title="使用率"
                    hint="总物理面积比率"
                    field={physical_rate}
                  />
                  <KBInput
                    {...halfClass}
                    titleClass="label-rt"
                    type="number"
                    inputClass="input-percent"
                    field={rentable_rate}
                    title="签约得房率"
                    hint="可出租面积比率"
                  />
                </div>
              ) : null}
              {type ? (
                //  || isTowerLoc
                ''
              ) : (
                <DeskNumber
                  loc_id={loc_id}
                  number={capacity.value || 10}
                  field={desk_serial_numbers}
                  error={
                    desk_serial_numbers.touched && desk_serial_numbers.error
                  }
                  init={values.desk_serial_numbers}
                />
              )}
              {
                // isTowerLoc ? null :
                <div className="kb-row">
                  <KBDateInput
                    field={checkin_date}
                    title="可预订起始日期"
                    className="kb-form-c18 kb-form-group kb-form-alone"
                  />
                </div>
              }
              {/* <div className="kb-row">
                <KBInput className="kb-form-group kb-form-c18 kb-form-alone" type="text" title="分区特点" hint="描述当前分区特点的关键词，用“,”分隔" field={ features } />
              </div> */}
              <div className="kb-row">
                <KBMorePhotoUpload
                  defaultImages={defaultImages}
                  title="上传图片"
                  hint="允许多张上传,格式为png或jpg,大小不大于20M"
                  photoShow={true}
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={images}
                  text="上传分区图片"
                  prefix="images/areas/"
                  accept=".png,.jpg"
                  isPublic
                />
              </div>
            </div>
          )}

          {/* 预定咖啡提醒 */}
          {area_type.value === 'flexible_office' && (
            <div>
              <div className="kb-row">
                <KBRadio
                  className="kb-form-group kb-div-c48"
                  inClassName="kb-form-group kb-div-66"
                  groupStyle={{ width: '100%' }}
                  divClassName="kb-form-group kb-div-33"
                  rightLabel="label-rt"
                  title="增加第三方合作小程序"
                  arrayType={[
                    { value: true, title: '是' },
                    { value: false, title: '否' }
                  ]}
                  name="redirect_mini"
                  field={redirect_mini}
                />
              </div>
              {(redirect_mini.value === true ||
                redirect_mini.value === 'true') && (
                <div>
                  <div className="kb-row">
                    <KBInput
                      className="kb-form-group kb-form-c18 kb-form-alone"
                      type="text"
                      title="微信注册appid"
                      field={appid}
                      isRequired
                    />
                  </div>
                  <div className="kb-row">
                    <KBInput
                      className="kb-form-group kb-form-c18 kb-form-alone"
                      type="text"
                      title="小程序原始id"
                      field={mini_id}
                      isRequired
                    />
                  </div>
                  <div className="kb-row">
                    <KBInput
                      className="kb-form-group kb-form-c18 kb-form-alone"
                      type="text"
                      title="小程序路径"
                      field={mini_path}
                      isRequired
                    />
                  </div>
                  <div className="kb-row">
                    <KBInput
                      className="kb-form-group kb-form-c18 kb-form-alone"
                      type="text"
                      title="小程序名字"
                      field={mini_name}
                      isRequired
                    />
                  </div>
                  <div className="kb-row">
                    <KBInput
                      className="kb-form-group kb-form-c18 kb-form-alone"
                      type="text"
                      title="小程序跳转标题"
                      field={mini_redirect_title}
                      isRequired
                    />
                  </div>
                  <div className="kb-row">
                    <KBInput
                      className="kb-form-group kb-form-c18 kb-form-alone"
                      type="text"
                      title="小程序跳转文案"
                      field={mini_redirect_content}
                      isRequired
                    />
                  </div>
                  <div className="kb-row" style={{ textAlign: 'left' }}>
                    <KBImageCropperInput
                      title="小程序图片"
                      aspect={60 / 60}
                      className="kb-form-group kb-form-c18 kb-form-alone"
                      field={mini_qrcode_image_url}
                      type="activities"
                      imageSize={1024}
                      width={60}
                      height={60}
                      failureCallBack={this.UploadFailureCallBack}
                      accept=".png,.jpg"
                      isRequired
                    >
                      <div className="upload-portrait">
                        {mini_qrcode_image_url &&
                        mini_qrcode_image_url.value ? (
                          <img
                            src={toImageProxyDirect(
                              mini_qrcode_image_url.value,
                              60,
                              60
                            )}
                            style={{
                              width: '60px',
                              height: '60px',
                              display: 'inline-block',
                              marginLeft: 0,
                              borderRadius: 0,
                              objectFit: 'cover'
                            }}
                            alt=""
                          />
                        ) : (
                          ''
                        )}
                        <div
                          className="d-iblock"
                          style={{ verticalAlign: 'middle' }}
                        >
                          <button className="bordered-btn m-none" type="button">
                            {mini_qrcode_image_url.dirty
                              ? '图片已上传'
                              : '上传图片'}
                          </button>
                          <p
                            className="color-nine"
                            style={{ paddingTop: 20, fontSize: 12 }}
                          >
                            图片格式为png或jpg,文件大小不大于1M
                          </p>
                        </div>
                      </div>
                    </KBImageCropperInput>
                    {mini_qrcode_image_url.touched &&
                      mini_qrcode_image_url.error && (
                        <p className="lr-error">
                          {mini_qrcode_image_url.error}
                        </p>
                      )}
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="kb-row m-top fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

AreasForm.propTypes = {
  onAreaCreated: PropTypes.func,
  areaEdited: PropTypes.func,
  area: PropTypes.object
}

function mapStateToProps(state, props) {
  const { area = {}, type } = props
  const { loc_id } = props
  const location_id = loc_id || (area && area.location_id)
  console.log(222, props)
  let defaultImages = (area && area.images) || []
  const locationSettings =
    selectors.getLocationSetting(state, location_id) || {}
  const areaSettings = locationSettings.area_settings || {}
  const isScale =
    locationSettings.location_settings &&
    locationSettings.location_settings.entering_rate_calc_type === 'by_scale'
  let area_physical_rate = areaSettings.area_physical_rate
  let area_rentable_rate = areaSettings.area_rentable_rate

  let self_setuped = false

  const { star_point_settings } = area
  if (star_point_settings) {
    self_setuped = star_point_settings.self_setuped
  }

  return {
    location_id,
    defaultImages,
    isScale,
    area_physical_rate,
    area_rentable_rate,
    initialValues: {
      fake_order_num: 0,
      ...area,
      type,
      merchant_id: (area.merchant || {}).id,
      invoicing_application_setting_id: (
        area.invoicing_application_setting || {}
      ).id,
      redirect_mini: area.redirect_mini || false,
      self_setuped: self_setuped ? 'custom' : 'unset',
      de_rate: star_point_settings
        ? star_point_settings.de_rate * 100 + ''
        : '0',
      ex_rate: star_point_settings ? star_point_settings.ex_rate : ''
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createAreaAction: bindActionCreators(areaActions.create, dispatch),
    updateAreaAction: bindActionCreators(areaActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入分区名称'
    if (
      values.name &&
      values.name.length > 4 &&
      values.name.replace(/[^\x00-\xff]/g, '01').length > 7
    ) {
      errors.name = '分区名称过长，请控制在6个字符以内'
    }
  }

  if (valid.required(values.area_type)) {
    errors.area_type = '请选择区域类型'
  }

  if(valid.required(values.fake_order_num)) {
    errors.fake_order_num = '请输入订单基数'
  } else if (valid.integer(values.fake_order_num)) {
    errors.fake_order_num = '订单基数必须为整数'
  } else if (values.fake_order_num < 0) {
    errors.fake_order_num = '订单基数不能为负数'
  }

  if (
    values.area_type != 'floor' &&
    values.is_tower_location &&
    valid.required(values.floor_info_id)
  ) {
    errors.floor_info_id = '请选择所属楼层'
  }

  if (values.listing_price && valid.isPrice(values.listing_price)) {
    errors.listing_price = '请输入正确的价格'
  }

  if (
    values.non_entering_listing_price &&
    valid.isPrice(values.non_entering_listing_price)
  ) {
    errors.non_entering_listing_price = '请输入正确的价格'
  }

  if (
    !values.is_tower_location &&
    values.area_type != 'floor' &&
    valid.isPrice(values.size)
  ) {
    errors.size = '请输入正确的面积, 小数点保留2位'
  }

  if (
    !values.is_tower_location &&
    !values.type &&
    values.area_type != 'floor' &&
    valid.required(values.desk_serial_numbers)
  ) {
    errors.desk_serial_numbers = '请输入工位号'
  }

  // 第三方小程序字段
  if (values.redirect_mini === 'true') {
    if (valid.required(values.appid)) {
      errors.appid = '请输入微信注册appid'
    }
    if (valid.required(values.mini_id)) {
      errors.mini_id = '请输入小程序原始id'
    }
    if (valid.required(values.mini_path)) {
      errors.mini_path = '请输入小程序路径'
    }
    if (valid.required(values.mini_name)) {
      errors.mini_name = '请输入小程序名字'
    }
    if (valid.required(values.mini_redirect_title)) {
      errors.mini_redirect_title = '请输入小程序跳转标题'
    }
    if (valid.required(values.mini_redirect_content)) {
      errors.mini_redirect_content = '请输入小程序跳转文案'
    }
    if (valid.required(values.mini_qrcode_image_url)) {
      errors.mini_qrcode_image_url = '请上传小程序图片'
    }
  }

  // 选择独立的星积分时必填
  if (values.self_setuped === 'custom') {
    if (valid.required(values.ex_rate)) {
      errors.ex_rate = '请输入兑换比例'
    }
    if (valid.required(values.de_rate)) {
      errors.de_rate = '请输入抵扣比例'
    } else {
      const deRateNum = Number(values.de_rate)
      if (isNaN(deRateNum) || deRateNum < 0 || deRateNum > 100) {
        errors.de_rate = '兑换比例只能为0-100之间的数字'
      }
    }
  }

  return errors
}

const formConfig = {
  form: 'areasForm',
  fields: [
    'type',
    'area_member_type',
    'name',
    'non_entering_day_listing_price',
    'area_type',
    'orientation',
    'size',
    'floor',
    'device_ids',
    'capacity',
    'desk_serial_numbers',
    'listing_price',
    'lowest_price',
    'non_entering_listing_price',
    'physical_rate',
    'rentable_rate',
    'floor_ids',
    'images',
    'checkin_date',
    'features',
    'fake_order_num',
    'is_tower_location',
    'floor_info_id',
    'listing_price',
    'day_listing_price',
    'bridge_listing_price',
    'bridgego_visible',
    'merchant_id',
    'invoicing_application_setting_id',
    'xforceplus_tax_setting_id',
    // 第三方小程序字段
    'redirect_mini',
    'appid',
    'mini_id',
    'mini_path',
    'mini_qrcode_image_url',
    'mini_name',
    'mini_redirect_title',
    'mini_redirect_content',
    'self_setuped',
    'star_point_settings',
    'ex_rate',
    'de_rate'
  ],
  validate: validate,
  touchOnBlur: false
}

AreasForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(AreasForm)

export default AreasForm
