import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { routerActions } from 'react-router-redux'
import { locationActions, apiCallFailure } from 'app/actions'
import { Router, Route, Link } from 'react-router'
import { randomNumber } from 'utils/kbUtil'
import { KBQiniuImage, KBPopover } from 'components'
import { KBPopoverConfirm } from 'components/tools'

var LocationOfCity = React.createClass({
  toLocationsSetting() {
    const { routerActions, location } = this.props

    routerActions.push(`/admin/faq/locations/${location.id}/setting`)
  },

  changeLocationPublic(publicState) {
    const { updateLocationAction, location, apiCallFailureActions } = this.props
    if (publicState) {
      apiCallFailureActions({
        status: 'error',
        message:
          '分店启用后不能转成非启用状态，如果确实需要请联系系统管理员操作!'
      })
      return
    }
    KBPopoverConfirm({
      name: '启用分店',
      context:
        '你是否确定用户可以在客户端App或微信上看见此分店的基本信息和图片?',
      callback: () => {
        const loc_public = !publicState
        return api.updateLocation(location.id, { public: loc_public }).then(
          json => {
            KBPopover.close()
            updateLocationAction.success(json)
          },
          errors => {
            updateLocationAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  render() {
    let location = this.props.location
    const style = {
      display: 'inline-block',
      padding: '4px 10px',
      fontSize: '12px',
      color: '#FFFFFF',
      marginLeft: 10
    }

    return (
      <tr>
        <td>
          <span
            className="t-over d-iblock"
            style={{ maxWidth: 120, verticalAlign: 'middle', width: 80 }}
          >
            {location.name}
          </span>
          <span
            className={`c-pointer ${
              location.public ? 'receipt-finish' : 'receipt-unpaid'
            }`}
            onClick={this.changeLocationPublic.bind(null, location.public)}
            style={style}
          >
            {location.public ? '展示' : '隐藏'}
          </span>
        </td>
        <td>{location.physical_address}</td>
        <td>{location.size}</td>
        <td>
          <i
            className="iconfont icon-edit"
            onClick={() => {
              this.toLocationsSetting()
            }}
          />
        </td>
      </tr>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    updateLocationAction: bindActionCreators(locationActions.update, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationOfCity)
