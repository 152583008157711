import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import _ from 'lodash'
import { KBAvatar, KBDropdown } from 'components'
import { util } from 'utils/kbUtil'

var Select = require('react-select')
var KBSearchList = React.createClass({
  getInitialState() {
    return {
      dropShow: false,
      str: '',
      users: [],
      searchIndex: 0
    }
  },
  selectDownList(data) {
    const { selectCallBack, searchArgument } = this.props
    const {searchIndex} = this.state
    selectCallBack && selectCallBack(data, searchArgument ? searchArgument[searchIndex] : undefined)
    this.setState({ str: data.name })
    this.hideDrop()
  },
  showDrop() {
    this.setState({ dropShow: true })
  },

  hideDrop() {
    this.setState({ dropShow: false })
  },

  inputFocus() {
    this.showDrop()
  },

  inputBlur() {
    util.setTimeout('dropDownInput', this.hideDrop, 300)
  },

  clearInput() {
    this.setState({ str: '' })
  },

  mulitChangeUser(e) {
    let str = e.target.value
    const { searchIndex } = this.state
    const { searchArgument, selectCallBack } = this.props

    if (!str || str == ' ') {
      this.setState({ str: '', users: [] })
      selectCallBack && selectCallBack()
      return
    }

    searchArgument[searchIndex].ajax(str, data => {
      this.setState({ users: data })
    })

    this.setState({ str })
  },

  changeUser(e) {
    let str = e.target.value
    const { selectCallBack } = this.props

    if (!str || str == ' ') {
      this.setState({ str: '', users: [] })
      selectCallBack && selectCallBack()
      return
    }

    this.props.getUsers(str, data => {
      this.setState({ users: data })
    })

    this.setState({ str })
  },

  singleSearch() {
    const {
      field,
      hint,
      inputClass,
      placeholder,
      param,
      style,
      itemWidth,
      custom,
      errorStyle,
      hideAvatar
    } = this.props
    const { dropShow, str, users } = this.state
    return (
      <div
        className="add-staff-component"
        style={Object.assign({}, { width: '183px' }, style)}
      >
        <input
          type="text"
          className={classNames(
            inputClass,
            field && field.touched && field.error
              ? 'kb-input-error'
              : 'kb-input'
          )}
          placeholder={placeholder}
          value={str}
          onChange={this.changeUser}
          onFocus={this.inputFocus}
          onBlur={this.inputBlur}
        />
        {dropShow &&
          (this.props.children || (
            <ul className="add-staff-list">
              {users.map((json, index) => {
                json.name = custom ? _.get(json, custom, '') : json.name
                return (
                  <li
                    onClick={this.selectDownList.bind(null, json)}
                    key={index}
                  >
                    <a href="#">
                      {
                        !hideAvatar && <div className="add-staff-portrait">
                          <KBAvatar
                            user={json}
                            size={30}
                            style={{
                              width: '30px',
                              height: '30px',
                              lineHeight: '33px'
                            }}
                          />
                        </div>
                      }
                      <span
                        className={`add-staff-name team-search${
                          itemWidth ? `-${itemWidth}` : ''
                        }`}
                      >
                        {json[param] || json.name}
                      </span>
                    </a>
                  </li>
                )
              })}
            </ul>
          ))}
        {field && field.touched && field.error ? (
          <p className="lr-error" style={errorStyle}>
            {field.error}
          </p>
        ) : (
          <p style={{ color: '#c8c8c8', fontSize: 12, padding: '4px 0 0 0' }}>
            {hint}
          </p>
        )}
      </div>
    )
  },
  setSearchIndex(index) {
    this.setState({ searchIndex: index })
    this.refs['searchArgument'] && this.refs['searchArgument'].hide()
  },
  multiSearch() {
    const { param, style, searchArgument, inputStyle } = this.props
    const { dropShow, str, users, searchIndex } = this.state
    return (
      <KBDropdown ref="searchArgument" className="add-search-argument">
        <div>
          <span
            className="span-pr-s"
            onClick={() => this.refs['searchArgument'].show()}
          >
            {searchArgument[searchIndex].name}
          </span>
          <span
            className="task-deg"
            onClick={() => this.refs['searchArgument'].show()}
          />
          <span className="span-slash">|</span>
          <div className="add-inline-component">
            <input
              type="text"
              placeholder={searchArgument[searchIndex].placeholder}
              value={str}
              onChange={this.mulitChangeUser}
              onFocus={this.inputFocus}
              onBlur={this.inputBlur}
              style={inputStyle}
            />
          </div>
          {dropShow &&
            (this.props.children || (
              <ul className="add-staff-list">
                {users.map((json, index) => (
                  <li
                    onClick={this.selectDownList.bind(null, json)}
                    key={index}
                  >
                    <a href="#">
                      <div className="add-staff-portrait">
                        <KBAvatar
                          user={json}
                          size={30}
                          style={{
                            width: '30px',
                            height: '30px',
                            lineHeight: '33px'
                          }}
                        />
                      </div>
                      <span
                        className="add-staff-name team-search-max"
                        style={{ maxWidth: '230px' }}
                      >
                        {json[param] || json.name}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            ))}
        </div>
        <KBDropdown.KBDropdownContent style={{ marginTop: 9 }}>
          <div>
            <ul className="task-type">
              {searchArgument.map((state, index) => {
                return (
                  <li key={index} onClick={() => this.setSearchIndex(index)}>
                    <span>{state.name}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        </KBDropdown.KBDropdownContent>
      </KBDropdown>
    )
  },
  render() {
    const { searchArgument } = this.props

    if (!searchArgument || !searchArgument.length) {
      return this.singleSearch()
    }

    return this.multiSearch()
  }
})

KBSearchList.propTypes = {
  searchArgument: PropTypes.array
}

export default KBSearchList
