import React, { PropTypes } from 'react'
import events from 'events'
import { KBButton } from 'components'
import classNames from 'classnames'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

// KBPopover.create(<JSX/>, Options, noQueue)
// Options:
// 'content': null,
// 'visible': false,
// 'className': null,
// 'noOverlay': false,
// 'position': false,
// 'closeOnOutsideClick': false
var EventEmitter = events.EventEmitter,
  SHOW_EVENT = 'show_pop',
  CLOSE_EVENT = 'close_pop',
  _props = {},
  _initialState = {},
  Manager

Manager = Object.assign({}, EventEmitter.prototype, {
  id: 1,
  popups: {},
  queue: [],
  active: null,
  value: null,

  getId: function() {
    return 'id_' + this.id++
  },

  activePopup: function() {
    return this.popups[this.active]
  },

  close: function() {
    if (!this.active) {
      return false
    }

    var id = this.active
    this.active = null

    this.emit(CLOSE_EVENT)
    this.dispatch()

    this.value = null

    return id
  },

  dispatch: function() {
    if (this.active || this.queue.length < 1) {
      return false
    }

    var id = this.queue.shift()

    /** Set active */
    this.active = id

    this.emit(SHOW_EVENT)
  }
})

var KBPopover = React.createClass({
  displayName: 'KBPopover',

  getInitialState: function() {
    var state = {
      title: null,
      buttons: false,
      content: null,
      visible: false,
      className: null,
      noOverlay: false,
      closeCallBack: null,
      hideOverlay: false,
      position: false,
      closeOnOutsideClick: false
    }

    _initialState = state

    return state
  },

  getDefaultProps: function() {
    return {
      className: 'kb-popup'
    }
  },

  statics: {
    plugins: {},

    addShowListener: function(callback) {
      Manager.on(SHOW_EVENT, callback)
    },

    removeShowListener: function(callback) {
      Manager.removeListener(SHOW_EVENT, callback)
    },

    addCloseListener: function(callback) {
      Manager.on(CLOSE_EVENT, callback)
    },

    removeCloseListener: function(callback) {
      Manager.removeListener(CLOSE_EVENT, callback)
    },

    register: function(data) {
      var id = Manager.getId()

      data = Object.assign({}, _initialState, data)

      Manager.popups[id] = data

      return id
    },

    queue: function(id) {
      if (!Manager.popups.hasOwnProperty(id)) {
        return false
      }

      /** Add popup to queue */
      Manager.queue.push(id)

      /** Dispatch queue */
      Manager.dispatch()

      return id
    },

    create: function(data) {
      /** Register popup */
      var id = this.register(data)

      /** Queue popup */
      this.queue(id)

      return id
    },

    close: function() {
      let popup = Manager.activePopup()
      popup && popup.closeCallBack && popup.closeCallBack()
      Manager.close()
    },

    getValue: function() {
      return Manager.value
    },

    registerPlugin: function(name, callback) {
      this.plugins[name] = callback.bind(this)
    },

    show: function(content, options = {}, noQueue = false) {
      var data = Object.assign(
        {
          content: content
        },
        options
      )

      if (noQueue) {
        return this.register(data)
      }

      return this.create(data)
    },

    showForm: function(
      content,
      options = {
        boxStyle: {
          width: '1000px'
        }
      },
      noQueue = false
    ) {
      var data = Object.assign(
        {
          content: content
        },
        options
      )

      if (noQueue) {
        return this.register(data)
      }

      return this.create(data)
    }
  },
  showState() {
    let _this = this,
      popup
    popup = Manager.activePopup()

    _this.setState({
      content: popup.content,
      visible: true,
      className: popup.className,
      noOverlay: popup.noOverlay,
      hideOverlay: popup.hideOverlay,
      position: popup.position,
      closeCallBack: popup.closeCallback,
      closeOnOutsideClick: popup.closeOnOutsideClick,
      boxStyle: popup.boxStyle
    })
  },
  closeState() {
    var _this = this
    if (_this.mounted) {
      _this.setState(_this.getInitialState())
    }
  },
  componentDidMount: function() {
    this.mounted = true
    Manager.on(SHOW_EVENT, this.showState)
    Manager.on(CLOSE_EVENT, this.closeState)
  },
  componentWillUnmount() {
    this.mounted = false
    Manager.removeListener(SHOW_EVENT, this.showState)
    Manager.removeListener(CLOSE_EVENT, this.closeState)
  },
  componentDidUpdate: function() {
    var box = this.box

    if (!box) {
      return
    }

    if (!this.state.position) {
      box.style.opacity = 1
      box.style.top = null
      box.style.left = null
      box.style.margin = null

      return false
    }

    if (typeof this.state.position === 'function') {
      return this.state.position.call(null, box)
    }

    box.style.top = parseInt(this.state.position.y) + 'px'
    box.style.left = parseInt(this.state.position.x) + 'px'
    box.style.margin = 0
    box.style.opacity = 1
  },

  hasClass: function(element, className) {
    if (element.classList) {
      return !!className && element.classList.contains(className)
    }

    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1
  },

  className: function(className) {
    return this.props.className + '__' + className
  },

  onClose: function() {
    let popup = Manager.activePopup()
    popup && popup.closeCallBack && popup.closeCallBack()
    Manager.close()
  },

  containerClick: function(e) {
    if (
      this.state.closeOnOutsideClick &&
      this.hasClass(e.target, this.props.className)
    ) {
      this.onClose()
    }
  },

  render: function() {
    var className = this.props.className,
      box,
      overlayStyle = {},
      boxClass

    if (this.state.visible) {
      className += ' ' + this.props.className + '--visible'

      boxClass = this.className('box')

      if (this.state.className) {
        boxClass += ' ' + this.state.className
      }

      let boxStyle = { opacity: 0, width: 626 }

      if (this.state.boxStyle) {
        boxStyle = Object.assign({}, boxStyle, this.state.boxStyle)
      }

      box = (
        <article
          ref={ref => {
            this.box = ref
          }}
          style={boxStyle}
          className={boxClass}
        >
          {this.state.content}
        </article>
      )
    }

    if (this.state.hideOverlay) {
      overlayStyle.background = 'transparent'
    }

    return this.state.noOverlay ? (
      box
    ) : (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="example"
        transitionEnterTimeout={150}
        transitionLeave={false}
        onClick={this.containerClick}
        className={className}
        style={overlayStyle}
      >
        {box}
      </ReactCSSTransitionGroup>
    )
  }
})

// KBPopover.plugins.popover(<content/>, target, options)
// - target: the target DOM element to attach popover
// - options include:
//    position: 'top', 'bottom', 'bottom-left', 'bottom-right'
//    className: custom className for the popup, default is '.kb-popup__box--popover'
//    leftOffset: offset to move left
//    topOffset: offset to move right
//  Must specify the width of the popup class, default is 200px
KBPopover.registerPlugin('popover', function(content, target, options) {
  let bodyRect = document.body.getBoundingClientRect()
  let btnRect = target.getBoundingClientRect()
  let btnOffsetTop = btnRect.top - bodyRect.top
  let btnOffsetLeft = btnRect.left - bodyRect.left
  let btnWidth = btnRect.width
  let scroll = document.documentElement.scrollTop || document.body.scrollTop

  let myOptions = Object.assign({}, options)
  let topOffset = myOptions.topOffset || 0
  let leftOffset = myOptions.leftOffset || 0
  let pos = myOptions.position || 'bottom'
  let className = myOptions.className || 'kb-popup__box--popover'
  let boxStyle = Object.assign({}, { width: 'auto' }, myOptions.boxStyle)

  return this.create({
    content: content,
    className: className,
    noOverlay: false, // will not render overlay background, not clickable
    hideOverlay: true, // will render overlay background but transparent, clickable
    closeOnOutsideClick: true, // only work if noOverlay is true
    boxStyle: boxStyle,
    position: function(box) {
      //if (pos === 'top') {
      //  box.style.top = (btnOffsetTop - box.offsetHeight) - scroll + 'px';
      //  box.style.left = (btnOffsetLeft + (target.offsetWidth / 2) - (box.offsetWidth / 2)) + 'px';
      //} else if (pos === 'bottom') {
      //  box.style.top = (btnOffsetTop + btnRect.height) - scroll + 'px';
      //  box.style.left = (btnOffsetLeft + (target.offsetWidth / 2) - (box.offsetWidth / 2)) + 'px';
      //} else if (pos === 'bottom-right') {
      //  box.style.top = (btnOffsetTop + btnRect.height) - scroll + 'px';
      //  box.style.left = (btnOffsetLeft + btnWidth - box.offsetWidth) + 'px';
      //} else if (pos === 'bottom-left') {
      //  box.style.top = (btnOffsetTop + btnRect.height) - scroll + 'px';
      //  box.style.left = (btnOffsetLeft) + 'px';
      //}
      //box.style.top = box.style.top + topOffset;
      //box.style.left = box.style.left + leftOffset;
      box.style.margin = 0
      box.style.opacity = 1
    }
  })
})

KBPopover.registerPlugin('confirm', function(title, body, options) {
  let content = (
    <div>
      <header className="kb-form-header">
        <h2 className="header-title">{title}</h2>
        <i
          className="iconfont header-close icon-cancel"
          onClick={KBPopover.close}
        />
      </header>
      <div className="kb-form-container">
        <div className="kb-row">{body}</div>
        <div className="kb-row fixed-form-btn">
          <span className="cancel-btn" onClick={KBPopover.close}>
            取消
          </span>
          <KBButton
            className="certain-btn"
            onClick={() => {
              if (options.confirm) {
                options.confirm()
              } else {
                KBPopover.close()
              }
            }}
            style={{ marginRight: 0 }}
          >
            确定
          </KBButton>
        </div>
      </div>
    </div>
  )

  this.create({
    content: content,
    noOverlay: false,
    hideOverlay: false,
    closeOnOutsideClick: false
  })
})

KBPopover.registerPlugin('alert', function(title, body, options) {
  let content = (
    <div>
      <header className="kb-form-header">
        <h2 className="header-title">{title}</h2>
        <i
          className="iconfont header-close icon-cancel"
          onClick={KBPopover.close}
        />
      </header>
      <div className="kb-form-container">
        <div className="kb-row">{body}</div>
        <div className="kb-form-fr fixed-form-btn ">
          <KBButton
            className="certain-btn"
            onClick={options.callback || KBPopover.close}
          >
            确定
          </KBButton>
        </div>
      </div>
    </div>
  )

  this.create({
    content: content,
    noOverlay: false,
    hideOverlay: false,
    closeOnOutsideClick: false
  })
})

KBPopover.registerPlugin('tooltip', function(content, target, options) {
  let classes = classNames('tooltip', (options && options.position) || 'top')
  let tooltip = (
    <div>
      <div className="tooltip-arrow"></div>
      <div className="tooltip-inner">{content}</div>
    </div>
  )

  return KBPopover.plugins.popover(
    tooltip,
    target,
    Object.assign(
      {},
      {
        className: classes,
        position: 'top',
        noOverlay: true,
        hideOverlay: true,
        closeOnOutsideClick: false
      },
      options
    )
  )
})

export default KBPopover
