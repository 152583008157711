import React from 'react'
import { api } from 'app/services'
import config from 'app/config'
import { getBlobService } from 'utils/kbUtil'
import uuidv4 from 'uuid/v4';
import { notification } from 'antd';

var KBEditor = React.createClass({
  displayName: 'KBEditor',
  getInitialState() {
    return {
      editor: null
    }
  },

  componentWillReceiveProps(nextProps) {
    const { editor } = this.state

    if(!editor) return

    // 当前的htmlString
    const htmlString = editor.getHtml()

    const nextHtmlString = nextProps.field.value

    if(htmlString !== nextHtmlString) {
      // 文案变化
      editor.select([])
      editor.deleteFragment()
      const E = window.wangEditor
      E.SlateTransforms.setNodes(editor, { type: 'paragraph' }, { mode: 'highest' })
      editor.dangerouslyInsertHtml(nextHtmlString)
      editor.blur()
    }
  },

  async componentDidMount() {
    this.initEditor()
  },

  componentWillUnmount() {
    this.destroyEditor()
  },

  initEditor() {
    const {field, disabled} = this.props
    const E = window.wangEditor
    const box = this.refs[field.name]
    const _this = this

    const editor = E.createEditor({
      selector: box.querySelector('.editor-text-area'),
      html: field.value || '<p><br></p>',
      config: {
        placeholder: '请输入',
        MENU_CONF: {
          uploadImage: {
            // base64LimitSize: 100 * 1024, // 100kb 以下插入 base64
            // 自定义插入图片
            async customUpload(file, insertFn) {
              _this.uploadToBlob(file, insertFn)
            },
          }
        },
        onChange(result) {
          field.onChange(result.getHtml())
        }
      }
    })

    E.createToolbar({
      editor,
      selector: box.querySelector('.editor-toolbar'),
      config: {
        // 排除某些菜单项
        excludeKeys: ['group-video', 'todo', 'code', 'insertImage', 'insertTable', 'codeBlock', 'fullScreen'],
      }
    })

    this.setState({
      editor
    })

    if(disabled) {
      editor.disable()
    }
  },

  async uploadToBlob(file, insertImageFn) {
    if(!file) {
      return
    }

    if(!['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(file.type)) {
      return notification.error({
        message: '文件类型错误',
        description: '请上传png/jpeg/jpg/gif类型的图片'
      })
    }

    // 富文本默认为公有
    const { failureCallBack, isPublic = true } = this.props
    const fd = new FormData()
    fd.append('file', file)
    try {
      const data = await api[isPublic ? 'uploadToPublicBlob' : 'uploadToPrivacyBlob'](fd)
      insertImageFn(data.url)
    } catch (error) {
      if(failureCallBack) {
        failureCallBack(error)
      } else {
        alert('上传失败，请重试')
      }
    }
  },

  destroyEditor() {
    this.state.editor.destroy()
  },

  render() {
    const { field, isRequired, title } = this.props
    return (
      <fieldset className="fieldset" ref={field.name}>
        {
          title && (
            <legend className="clear-fix">
              <span>
                {isRequired && <span className="must-fillR">*</span>}
                {title}
              </span>
            </legend>
          )
        }
        <div style={{'border': '1px solid #DFE3E9'}}>
          <div className="editor-toolbar" style={{'border-bottom': '1px solid #DFE3E9'}}></div>
          <div className="editor-text-area" style={{height: '500px'}}></div>
        </div>
        {field.touched && field.error && (
          <p className="lr-error">{field.error}</p>
        )}
      </fieldset>
    )
  }
})

export default KBEditor
