import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBImageCropperInput
} from 'components'
import { announcementsActions, successState } from 'app/actions'
import { ANNOUNCEMENT_FORM } from 'app/constants/hint'
import { notification } from 'antd'

var AnnouncementForm = React.createClass({
  getInitialState() {
    return {}
  },

  componentDidMount() {},

  createFaqFiles(model) {
    const {
      createAnnouncementActions,
      updateAnnouncementActions,
      section,
      state,
      successActions
    } = this.props

    if (state) {
      return api.updateAnnouncements(section.id, model).then(json => {
        updateAnnouncementActions.success(json)
        successActions({
          title: '修改公告成功'
        })
        KBPopover.close()
        return true
      })
    }

    model.push_msg = true
    model.push_roles = ''
    return api.createAnnouncements(model).then(json => {
      createAnnouncementActions.success(json)
      successActions({
        title: '创建公告成功',
        message: '请前去“公告”查看详情'
      })
      KBPopover.close()
      return true
    })
  },

  failureCallBack(err) {
    if(!err) return
    notification.error({ message: '上传失败', description: err })
  },

  render() {
    const {
      fields: { title, image, link },
      error,
      submitting,
      handleSubmit,
      disableLinkEditable
    } = this.props
    const { state } = this.props

    return (
      <form onSubmit={handleSubmit(this.createFaqFiles)}>
        <KBFormError error={error} />
        <header className="kb-form-header">
          <h2 className="header-title">{state ? '修改公告' : '创建公告'}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={title}
              title="标题"
              fistFoucs={true}
              placeholder={ANNOUNCEMENT_FORM.name}
              label={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              disabled={disableLinkEditable}
              inputClass={disableLinkEditable ? 'kb-input-disabled' : ''}
              field={link}
              title="URL"
              hint={ANNOUNCEMENT_FORM.url}
            />
          </div>
          <div className="kb-row">
            <KBImageCropperInput
              title="上传图片"
              aspect={640 / 360}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={image}
              type="announcement"
              accept=".png,.jpg"
              failureCallBack={this.failureCallBack}
              isPublic
            >
              {image.value ? (
                <div className="upload-portrait">
                  <img
                    src={image.value}
                    style={{
                      width: '232px',
                      height: '130px',
                      display: 'inline-block',
                      marginLeft: 0,
                      borderRadius: 0
                    }}
                    alt=""
                  />
                  <button className="bordered-btn" type="button">
                    {image.dirty ? '图片已上传' : '上传图片'}
                  </button>
                </div>
              ) : (
                ''
              )}
            </KBImageCropperInput>
            {image.touched && image.error ? (
              <p className="lr-error">
                {image.error}
              </p>
            ) : (
              <p
                style={{
                  'margin-top': 10,
                  color: '#999999',
                  'font-size': 12,
                }}
              >
                提示：图片格式为png或jpg,尺寸大小不小于1280x720
              </p>
            )}
          </div>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton className="certain-btn" submitting={submitting}>
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createAnnouncementActions: bindActionCreators(
      announcementsActions.create,
      dispatch
    ),
    updateAnnouncementActions: bindActionCreators(
      announcementsActions.update,
      dispatch
    ),
    successActions: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.title)) {
    errors.title = '请输入公告标题'
  }

  if (valid.required(values.image)) {
    errors.image = '请选择图片'
  }

  if (valid.required(values.link) || valid.Link(values.link)) {
    errors.link = '请输入正确的URL地址'
  }

  return errors
}

const formConfig = {
  form: 'AnnouncementForm',
  fields: ['title', 'image', 'link', 'push_msg', 'push_roles'],
  validate: validate,
  touchOnBlur: false
}

AnnouncementForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementForm)

export default AnnouncementForm
