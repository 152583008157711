import React, { PropTypes } from 'react'
import { connect } from 'react-redux'
import { KBInput, KBFormError, KBPopover } from 'components'
import { GLOBAL_USER } from 'app/services/api_utils'
import { bindActionCreators } from 'redux'
import { apiCallFailure } from 'app/actions'
import request from 'superagent-bluebird-promise'
import { getServiceErrorMessage } from 'utils/kbUtil'

var KBExcelUpload = React.createClass({
  getInitialState() {
    return {
      uploadFileUrl: null,
      uploadStateError: false,
      uploadStateFinish: false,
      uploadStateStart: false,
      uploadStateLoading: false,
      errorMsg: ''
    }
  },

  getDefaultProps() {
    return {
      single: true
    }
  },

  componentDidMount() {
    this.setState({ uploadStateStart: true })
  },

  renderProgress() {
    const { type } = this.props
    //csv https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/cl/uploads/locations/1a714e868-ca7f-4174-90c0-78cf0f4c8510no0.png
    if (type === 'csv') {
      return (
        <div className="excel-upload-box">
          <div className="excel-upload">
            <img
              style={{ width: 60 }}
              src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/cl/uploads/locations/1a714e868-ca7f-4174-90c0-78cf0f4c8510no0.png"
            />
            <a href="javascript:void(0)">上传csv中</a>
          </div>
        </div>
      )
    }

    return (
      <div className="excel-upload-box">
        <div className="excel-upload">
          <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/excel.jpg" />
          <a href="javascript:void(0)">上传Excel中</a>
        </div>
      </div>
    )
  },

  renderUploaded() {
    const { type } = this.props
    return (
      <div className="excel-upload">
        <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/excel-succeed.jpg" />
        <a href="">{type === 'csv' ? '签到状态更新完成' : '上传Excel完成'}</a>
      </div>
    )
  },

  uploadFile() {
    const { file } = this.refs
    file.click()
  },

  changeFile(e) {
    const { type: uploadType } = this.props
    let file = e.target.files[0]
    let fileType = uploadType === 'csv' ? ['csv'] : ['xlsx', 'xls']
    let fillName = file ? file.name : '上传文件'
    let name = fillName.substr(0, fillName.lastIndexOf('.'))
    let type = fillName.substr(fillName.lastIndexOf('.') + 1)
    let index = fileType.findIndex(json => json == type)

    if (index == -1) {
      this.setState({
        errorMsg:
          uploadType !== 'csv'
            ? '上传文件格式不正确,必须为xlsx、xls'
            : '上传文件格式不正确,必须为csv'
      })
      return
    }

    this.setState({ uploadStateLoading: true })
    this.sendFile(file)
  },

  sendFile(file) {
    const { url, params, fileField, callback } = this.props
    var r = request
      .put(url)
      .attach(
        fileField ? fileField : 'file',
        file,
        file ? file.name : '上传文件'
      )
    if (params && params.location_id) {
      r.field('location_id', params.location_id)
    }

    r.set('Authorization', 'Bearer ' + GLOBAL_USER.jwt_token).end(
      e => {
        var xhr = r.xhr
        if (xhr.status == '422') {
          const errorMessage = getServiceErrorMessage(xhr)
          errorMessage &&
            this.props.apiCallFailureActions({
              status: 'error',
              message: errorMessage
            })
          this.uploadFileError(JSON.parse(xhr.response))
        } else if (xhr.status == '200' || xhr.status == '201') {
          this.setCurrentState({
            uploadStateFinish: true,
            uploadStateLoading: false
          })
        }
        this.setState({ uploadStateLoading: false }, () => {
          try {
            const res = JSON.parse(xhr.response)
            callback && callback(res)
          } catch (error) {
            callback && callback()
          }
        })
      },
      e => {
        this.setCurrentState({
          uploadStateFinish: true,
          uploadStateLoading: false
        })
      }
    )
  },

  uploadFileError(err) {
    if (err.status) {
      return
    }

    this.setCurrentState({
      errorMsg: err.errors,
      uploadStateError: true
    })
  },

  renderUploadField() {
    const { type } = this.props
    const { uploadStateError } = this.state

    if (!uploadStateError) {
      return (
        <form ref="submit">
          <div className="excel-upload" onClick={this.uploadFile}>
            {type === 'csv' ? (
              <img
                style={{ width: 60 }}
                src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/cl/uploads/locations/1a714e868-ca7f-4174-90c0-78cf0f4c8510no0.png"
              />
            ) : (
              <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/excel.jpg" />
            )}
            <a href="javascript:void(0)">
              {type === 'csv' ? '上传CSV文档' : '上传EXCEL文档'}
            </a>
          </div>
          <input
            name="file"
            type="file"
            accept={`${type === 'csv' ? '.csv' : '.xls,.xlsx'}`}
            onChange={this.changeFile}
            ref="file"
            style={{ display: 'none' }}
          />
        </form>
      )
    }

    return null
  },

  renderUploadError() {
    const { type } = this.props
    return (
      <div className="excel-upload">
        <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/excel-fail.jpg" />
        <a href="javascript:void(0)">
          {type === 'csv' ? '上传CSV文档' : '上传EXCEL文档'}
        </a>
      </div>
    )
  },

  defaultState() {
    return {
      uploadStateError: false,
      uploadStateFinish: false,
      uploadStateStart: false,
      errorMsg: ''
    }
  },

  setCurrentState(newState) {
    let defaultState = this.defaultState()

    this.setState(Object.assign({}, defaultState, newState))
  },

  resetSate() {
    this.setCurrentState({ uploadStateStart: true })
  },

  render() {
    const { type } = this.props
    const {
      errorMsg,
      uploadStateError,
      uploadStateStart,
      uploadStateFinish,
      uploadStateLoading
    } = this.state

    return (
      <div>
        {!uploadStateLoading && uploadStateStart
          ? this.renderUploadField()
          : ''}
        {uploadStateLoading ? this.renderProgress() : ''}
        {!uploadStateLoading && uploadStateFinish ? this.renderUploaded() : ''}
        {uploadStateError ? this.renderUploadError() : ''}
        {errorMsg ? (
          <div className="upload-text">
            <span className="lr-error">{errorMsg}</span>
          </div>
        ) : (
          ''
        )}
        <div className="t-center">
          {uploadStateError || uploadStateFinish ? (
            <button
              className="certain-btn"
              onClick={this.resetSate}
              style={{ marginTop: '10px' }}
            >
              {type!=='csv'?'重新添加':'继续上传'}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
})

KBExcelUpload.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired
}

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KBExcelUpload)
