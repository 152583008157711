import React from 'react'
import { api } from 'app/services'
import { notification } from 'antd'
import config from 'app/config'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
const uuidv4 = require('uuid/v4')

const KBDownloadPrivacy = React.createClass({
  getInitialState() {
    return {
      loading: false
    }
  },
  downloadFile() {
    const { url, user, preview } = this.props
    let filename = ''
    try {
      filename = url.split('/').at(-1)
    } catch (error) {
      filename = uuidv4()
    }

    this.setState({
      loading: true
    })

    const xhr = new XMLHttpRequest()
    xhr.open(
      'GET',
      `${config.urlScheme}://${config.url}/managements/v2/uploads/download_private_file?url=${url}`,
      true
    )
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('Authorization', `Bearer ${user.jwt_token}`)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let type = xhr.getResponseHeader('Content-Type')
          let blob = new Blob([xhr.response], { type: type })
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            /*
             * For IE
             * >=IE10
             */
            window.navigator.msSaveBlob(blob, filename)
          } else {
            /*
             * For Non-IE (chrome, firefox)
             */
            let URL = window.URL || window.webkitURL
            let objectUrl = URL.createObjectURL(blob)
            if(preview || !filename) {
              this.previewFile(objectUrl)
            } else {
              let a = document.createElement('a')
              if (typeof a.download === 'undefined') {
                window.location = objectUrl
              } else {
                a.href = objectUrl
                a.download = filename
                document.body.appendChild(a)
                a.click()
                a.remove()
              }
            }
          }
          this.setState({
            loading: false
          })
        } else {
          this.setState({
            loading: false
          })
          notification.error({
            message: '资源下载失败',
            description: '资源下载失败，请稍后再试'
          })
        }
      }
    }
    xhr.send()
  },
  previewFile(url) {
    const { preview } = this.props
    if(typeof preview === 'function') {
      preview(url)
    } else {
      const page = window.open()
      page.location.href = url
    }
  },

  render() {
    const { children } = this.props
    const { loading } = this.state
    return (
      <div onClick={this.downloadFile} style={{ display: 'contents' }}>
        {loading ? '请求中...' : children}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let user = selectors.getCurrentUser(state)
  return {
    user
  }
}

export default connect(mapStateToProps)(KBDownloadPrivacy)
