import * as ActionTypes from '../actions'
import { apiUtils } from '../services'
export default function user(state = {}, action) {
  let { type, response } = action

  if (
    type === ActionTypes.REGISTER.SUCCESS ||
    type === ActionTypes.LOGIN.SUCCESS ||
    type == 'GETCURRENT_USER_SUCCESS' ||
    type == 'UPDATECURRENT_USER_SUCCESS'
  ) {
    const { json } = action.response

    // if user is not denormalized, just assign
    if (!json) {
      return Object.assign({}, state, action.response)
    }

    // user is denormalized, add user detail
    const { entities, result } = json
    const userJson = entities.users[result]
    return Object.assign({}, state, userJson, entities)
  } else if (type === ActionTypes.USER_LOGGED_OUT) {
    return {}
  }
  return state
}
