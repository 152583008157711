import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import request from 'superagent-bluebird-promise'
import config from 'app/config'
import { getBlobService } from 'utils/kbUtil'
import { api } from 'app/services'
import { getPortPromise } from 'portfinder'
import { notification } from 'antd'

const uuidv4 = require('uuid/v4')

var isFunction = function(fn) {
  var getType = {}
  return fn && getType.toString.call(fn) === '[object Function]'
}

var KBQiniuFileUpload = React.createClass({
  // based on https://github.com/paramaggarwal/react-dropzone
  propTypes: {
    onDrop: React.PropTypes.func.isRequired,
    token: React.PropTypes.string.isRequired,
    // called before upload to set callback to files
    onUpload: React.PropTypes.func,
    size: React.PropTypes.number,
    style: React.PropTypes.object,
    supportClick: React.PropTypes.bool,
    accept: React.PropTypes.string,
    multiple: React.PropTypes.bool,
    // Qiniu
    uploadUrl: React.PropTypes.string,
    uploadKey: React.PropTypes.string,
    prefix: React.PropTypes.string
  },

  getDefaultProps: function() {
    const { azure_url, isAzureUpload } = config
    return {
      supportClick: true,
      multiple: true,
      uploadUrl: isAzureUpload ? azure_url : config.qiniu_upload_url
    }
  },

  getInitialState: function() {
    return {
      isDragActive: false
    }
  },

  onDragLeave: function(e) {
    this.setState({
      isDragActive: false
    })
  },

  onDragOver: function(e) {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'copy'

    this.setState({
      isDragActive: true
    })
  },

  onDrop: function(e) {
    e.preventDefault()

    this.setState({
      isDragActive: false
    })
    const { isAzureUpload } = config
    var files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const accepts = /image\/\*/.test(this.props.accept)
      ? this.props.accept.replace('image/*', '.jpg,.png,.jpeg')
      : this.props.accept
    const suffixArr = ((accepts || '').split(',') || []).map(x =>
      x.replace('.', '')
    )
    // console.log(
    //   '========================================================================='
    // )
    // console.log(files, this.props.accept, suffixArr, '=======')

    let canUpload = true
    // return

    var maxFiles = this.props.multiple ? files.length : 1
    for (var i = 0; i < maxFiles; i++) {
      if (files[i].name) {
        const suffix = files[i].name.split('.')[1]
        if (!suffixArr.includes(suffix) && accepts !== '*') canUpload = false
      }
    }

    if (!canUpload) {
      return notification.error({
        message: '上传失败',
        description: `请上传${suffixArr.join(',')}格式的文件`
      })
    }

    return this.toUploadAzure(e)
    if (isAzureUpload) {
      this.toUploadAzure(e)
      return
    }

    if (this.props.onUpload) {
      files = Array.prototype.slice.call(files, 0, maxFiles)
      this.props.onUpload(files, e)
    }

    for (var i = 0; i < maxFiles; i++) {
      files[i].preview = URL.createObjectURL(files[i])
      files[i].request = this.upload(files[i])
      files[i].uploadPromise =
        files[i] && files[i].request && files[i].request.promise()
    }

    if (this.props.onDrop) {
      files = Array.prototype.slice.call(files, 0, maxFiles)
      this.props.onDrop(files, e)
    }
  },

  async toUploadAzure(e, checkMD5 = false) {
    const { isPublic } = this.props

    var files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }

    if (!files.length) {
      alert('Please select a file!')
      return
    }

    // let serversUrl = `${uploadUrl}${config.media_container}/${prefix}${filename}`

    var maxFiles = this.props.multiple ? files.length : 1

    if (this.props.onUpload) {
      files = Array.prototype.slice.call(files, 0, maxFiles)
      this.props.onUpload(files, e)
    }

    for (var i = 0; i < maxFiles; i++) {
      const name = files[i].name
      const expand = name.substr(name.lastIndexOf('.'), name.length)
      const uuidName = uuidv4() + 'no' + i + expand

      // const filename = uuidName
      // const azureData = (
      //   await api.getAzureToken({
      //     filepath: `${config.media_container}/${prefix}${filename}`
      //   })
      // ).json
      // if (!azureData.token) {
      //   return
      // }

      // var blobService = getBlobService(uploadUrl, azureData.token)
      // if (!blobService) {
      //   return
      // }
      files[i].preview = URL.createObjectURL(files[i])

      files[i].uploadPromise = new Promise((resolve, reject) => {
        const fd = new FormData()
        fd.append('file', files[i])
        api[isPublic ? 'uploadToPublicBlob' : 'uploadToPrivacyBlob'](fd).then((data) => {
          resolve({
            url: data.url,
            name: uuidName
          })
        }).catch((error) => {
          reject(error)
        })
      })

      // files[i].uploadPromise = this.uploadFile({
      //   file: files[i],
      //   checkMD5,
      //   blobService,
      //   uuidName
      // })
    }

    if (this.props.onDrop) {
      files = Array.prototype.slice.call(files, 0, maxFiles)
      this.props.onDrop(files, e)
    }
  },

  uploadFile(file, checkMD5, blobService, uuidName) {
    const { prefix, updateProgress, isPublic } = this.props
    var blockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512
    var options = {
      storeBlobContentMD5: checkMD5,
      blockSize: blockSize
    }
    blobService.singleBlobPutThresholdInBytes = blockSize
    return new Promise((resolve, reject) => {
      var speedSummary = blobService.createBlockBlobFromBrowserFile(
        config.media_container,
        prefix + uuidName,
        file,
        options,
        function(error, result, response) {
          speedSummary.body = {
            ...speedSummary.body,
            'x:filename': uuidName,
            key: `${config.media_container}/${speedSummary.name}`
          }
          if (error) {
            reject()
          } else {
            resolve(speedSummary)
          }
        }
      )

      speedSummary.on('progress', () => {
        var process = speedSummary.getCompletePercent()
        updateProgress && updateProgress(process)
      })
    })
  },

  onClick: function() {
    if (this.props.supportClick) {
      this.open()
    }
  },

  open: function() {
    var fileInput = ReactDOM.findDOMNode(this.refs.fileInput)
    fileInput.value = null
    fileInput.click()
  },

  upload: function(file) {
    if (!file || file.size === 0) return null
    var key = file.preview.split('/').pop() + '.' + file.name.split('.').pop()
    if (this.props.prefix) {
      key = this.props.prefix + key
    }

    if (this.props.uploadKey) {
      key = this.props.uploadKey
    }

    // post to Qiniu directly
    var r = request
      .post(this.props.uploadUrl)
      .field('key', key)
      .field('token', this.props.token)
      .field('x:filename', file.name)
      .field('x:size', file.size)
      .attach('file', file, file.name)
      .set('Accept', 'application/json')
    if (isFunction(file.onprogress)) {
      r.on('progress', file.onprogress)
      URL && URL.revokeObjectURL(file.preview)
    }
    return r
  },

  render: function() {
    var className = this.props.className || 'dropzone'
    if (this.state.isDragActive) {
      className = classNames(className, 'active')
    }

    var style = this.props.style
    /* || {
        width: this.props.size || 100,
        height: this.props.size || 100,
        borderStyle: this.state.isDragActive ? 'solid' : 'dashed'
      };*/

    return (
      <div
        className={className}
        style={style}
        onClick={this.onClick}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
      >
        <input
          style={{
            display: 'none'
          }}
          id="files"
          type="file"
          multiple={this.props.multiple}
          ref="fileInput"
          onChange={this.onDrop}
          accept={this.props.accept}
        />
        {this.props.children}
      </div>
    )
  }
})

export default KBQiniuFileUpload
