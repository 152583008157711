import React, { PropTypes } from 'react'
import { findDOMNode } from 'react-dom'
import Row from './Row'
import * as util from 'utils/kbUtil'
import Drop from './Drop'

require('./KBDropSelect.scss')

const DEFAULT_OPTIONS = {
  key: 'id',
  value: 'name',
  defaultMultiContent: '全部',
  defaultSelectPrompt: '选择',
  defaultSingleContent: '待选择',
  pinyin: 'name_pinyin',
  singleDropDown: null,
  multiDropDown: null
}

/**
 * KBDropDownSearch 用于搜索
 * @type {[type]}
 */
var KBDropSelect = React.createClass({
  displayName: 'KBDropSelect',
  getInitialState(nextProps) {
    const { options, selectedData, multi } = nextProps || this.props
    return {
      active: false,
      str: '',
      loading: false,
      selectData:
        (selectedData && selectedData.length) || (!multi && selectedData)
          ? selectedData
          : null,
      defaultData: this.props.defaultData || [],
      options: Object.assign({}, DEFAULT_OPTIONS, options)
    }
  },

  getDefaultProps() {
    return {
      isInput: true,
      singleIsCancel: true,
      multi: false,
      showImg: true,
      async: false
    }
  },

  componentWillReceiveProps(nextProps) {
    if (
      this.props.selectedData &&
      nextProps.selectedData &&
      !nextProps.selectedData.length
    ) {
      return this.clearData()
    }

    if (!nextProps.multi && this.props.selectedData != nextProps.selectedData) {
      return this.clearData(nextProps)
    }

    if (
      this.props.selectedData &&
      !this.props.selectedData.length &&
      nextProps.selectedData &&
      nextProps.selectedData.length
    ) {
      this.setState({
        selectData: nextProps.selectedData
      })
    }

    if (this.props.options != nextProps.options) {
      this.setState({
        options: Object.assign({}, DEFAULT_OPTIONS, nextProps.options)
      })
    }
  },

  componentWillUnmount() {},

  componentDidMount() {
    const { defaultValue } = this.props

    if (!defaultValue) {
      return
    }

    this.setState({
      selectData: defaultValue
    })
  },

  clearData(nextProps) {
    this.setState(this.getInitialState(nextProps))
  },

  changeSelect(rowData, isFlag) {
    const { multi, singleIsCancel } = this.props

    if (multi && !isFlag) {
      let selectData = Object.assign([], this.state.selectData)
      selectData = [...selectData, rowData]
      this.setState({
        selectData
      })

      this.props.callback(selectData)
      return
    }

    if (multi && isFlag) {
      this.deleteSelect(rowData)
      return
    }

    if (isFlag && singleIsCancel) {
      this.setState({
        selectData: null
      })
      this.props.callback(null)
      return
    }

    if (isFlag) {
      return
    }

    this.setState({
      selectData: rowData
    })
    this.props.callback(rowData)
  },

  // changeUser (e) {
  //   let str = e.target.value
  //   const { selectCallBack, toAjax } = this.props
  //
  //   if (!str || str == ' ') {
  //     this.setState({ str: '', defaultData: [] })
  //     return
  //   }
  //
  //   this.setState({
  //     str,
  //     loading : true
  //   })
  //
  //   toAjax(str, (data) => {
  //     this.setState({ defaultData: data, loading: false })
  //   })
  // },

  /**
   * 当Multi状态下  删除选中的Rows
   */
  deleteSelect(rowData, index) {
    const { options } = this.state
    let selectData = Object.assign([], this.state.selectData)

    if (!index) {
      let temp = selectData.findIndex(
        json => json[options.key] == rowData[options.key]
      )
      index = temp !== -1 ? temp : index
    }

    selectData.splice(index, 1)

    this.setState({
      selectData
    })

    this.props.callback(selectData)
  },

  setSingleDropDown(ref) {
    this.setState({
      singleDropDown: ref
    })
  },

  setMultiDropDown(ref) {
    this.setState({
      multiDropDown: ref
    })
  },

  hide() {
    const { singleDropDown, multiDropDown } = this.state
    singleDropDown && singleDropDown.hide()
    multiDropDown && multiDropDown.hide()
  },

  render() {
    const { selectData } = this.state
    const { style, closeClick, dropDown_name } = this.props

    return (
      <div className="KB-select_box" style={style}>
        <section>
          <Drop
            {...this.props}
            options={this.state.options}
            selectData={selectData}
            deleteSelect={this.deleteSelect}
            changeSelect={this.changeSelect}
            setSingleDropDown={this.setSingleDropDown}
            setMultiDropDown={this.setMultiDropDown}
            dropDown_name={dropDown_name}
            closeClick={closeClick}
          />
        </section>
      </div>
    )
  }
})

KBDropSelect.PropTypes = {
  /**
   * 是否出现input
   */
  isInput: PropTypes.bool,
  /**
   * 是否多选
   */
  multi: PropTypes.bool,
  /**
   * 是否异步获取 如果true 则调用ajax callback 如果false则调用默认的 defaultData
   */
  async: PropTypes.bool,
  /**
   * [defaultData 是一个数组]
   */
  defaultData: PropTypes.array,
  /**
   * 自定义参数
   * key                  : id 关键ID
   * value                : name 显示的名称
   * pinyin               : name_pinyin
   * defaultMultiContent  : '全部'
   * defaultSingleContent : '待选择'
   */
  options: PropTypes.object,
  defaultValue: React.PropTypes.oneOfType([
    React.PropTypes.array,
    React.PropTypes.object
  ]),
  /**
   * 是否显示图片
   */
  showImg: PropTypes.bool,
  /**
   * toAjax 两个参数 第一个 str 第二个 callback(data)
   */
  toAjax: PropTypes.func,
  // 在单选的条件下 是否取消当前选中
  singleIsCancel: PropTypes.bool
}

export default KBDropSelect
