import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { fmoney } from 'utils/kbUtil'
import { invoicesActions } from 'app/actions'
import { AA_PAYMENT_INFORMATION } from 'app/constants/hint'
import {
  KBInput,
  KBTextarea,
  KBFormError,
  KBButton,
  KBPopover,
} from 'components'

var ManualPayForm = React.createClass({
  createManualPayForm(model) {
    const { invoice } = this.props

    return api.putManualPay(invoice.id, model).then(json => {
      this.props.getInvoicesActions.success(json)
      KBPopover.close()
    })
  },

  render() {
    const {
      fields: { amount, reference_number, notes },
      error,
      submitting,
      handleSubmit,
      invoice
    } = this.props
    const hint = AA_PAYMENT_INFORMATION

    let total_amount = invoice && parseFloat(invoice.total_amount).toFixed(2)
    total_amount = isNaN(total_amount) ? 0 : total_amount

    let paid_amount = invoice && parseFloat(invoice.paid_amount).toFixed(2)
    paid_amount = isNaN(paid_amount) ? 0 : paid_amount

    let current_amount = amount && parseFloat(amount.value).toFixed(2)
    current_amount = isNaN(current_amount) ? 0 : current_amount

    const remaining = parseFloat(
      total_amount - paid_amount - current_amount
    ).toFixed(2)

    return (
      <form onSubmit={handleSubmit(this.createManualPayForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">{invoice.sales_customer.name}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container">
          <div className="popup-half">
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-form-c48 kb-form-alone"
                isRequired={true}
                title="收账金额"
                hint={hint.collect_amount}
                inputClass="kb-input input-dollar"
                fistFoucs={true}
                field={amount}
              />
            </div>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-form-c48 kb-form-alone"
                isRequired={true}
                title="转账流水号"
                field={reference_number}
              />
            </div>
            <div className="kb-row">
              <KBTextarea
                field={notes}
                title="收款备注"
                isRequired={true}
                className="kb-form-group kb-form-c48 kb-form-alone"
              />
            </div>
          </div>

          <div className="popup-half">
            <div className="kb-title">
              <span className="color-six">明细</span>
            </div>
            <ul className="detailed-statement-list">
              <li>
                <span>应收金额:</span>
                <span>{fmoney(total_amount, 2)}元</span>
              </li>
              <li>
                <span>已收金额:</span>
                <span>{fmoney(paid_amount, 2)}元</span>
              </li>
              <li>
                <span>本次金额:</span>
                <span>{fmoney(current_amount, 2)}元</span>
              </li>
              <li>
                <span>剩余金额:</span>
                <span className="color-red">{fmoney(remaining, 2)}元</span>
              </li>
            </ul>
          </div>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

ManualPayForm.PropTypes = {
  invoice: PropTypes.object.isRequired
}

function mapStateToProps(state, ownProps) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    getInvoicesActions: bindActionCreators(invoicesActions.get, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.amount)) {
    errors.amount = '请输入付款额'
  }

  if (valid.required(values.reference_number)) {
    errors.reference_number = '请输入转账流水号'
  }

  if (valid.required(values.notes)) {
    errors.notes = '请填写收款备注'
  }

  return errors
}

const formConfig = {
  form: 'ManualPayForm',
  fields: ['amount', 'reference_number', 'notes'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ManualPayForm)
