import config from '../config'
import CITIES from './cities'

export const cities = CITIES

export const QINGNIU_STATIC_FILE = config.qiniu_base_url + 'static/'

export const MEBMER_GENDERS_FORM = [
  {
    value: 'male',
    title: '男'
  },
  {
    value: 'female',
    title: '女'
  }
]

export const MEMBER_GENDER_OBJ = {
  male: '男',
  female: '女'
}

export const DUTIES_TYPE_OBJ = {
  employer: '公司负责人',
  employee: '公司员工',
  finance: '财务负责人'
}

export const DUTIES_TYPE_FORM = [
  {
    value: 'employer',
    title: '公司负责人'
  },
  {
    value: 'employee',
    title: '公司员工'
  },
  {
    value: 'finance',
    title: '财务负责人'
  }
]

export const MEMBER_AGE = [
  {
    name: '全部',
    value: {
      age_more_than: '',
      age_less_than: ''
    }
  },
  {
    name: '18-22',
    value: {
      age_more_than: 18,
      age_less_than: 22
    }
  },
  {
    name: '23-26',
    value: {
      age_more_than: 23,
      age_less_than: 26
    }
  },
  {
    name: '27-35',
    value: {
      age_more_than: 27,
      age_less_than: 35
    }
  },
  {
    name: '35岁以上',
    value: {
      age_more_than: 36,
      age_less_than: 100
    }
  }
]

export const DOCUMENT_TYPE = [
  {
    id: 'idcard',
    name: '身份证'
  },
  {
    id: 'passport',
    name: '护照'
  }
]

export const SEARCH_TYPE = [
  {
    id: 'user',
    name: '会员'
  },
  {
    id: 'org',
    name: '公司'
  },
  {
    id: 'desk',
    name: '工位'
  }
]

export const SEARCH_TYPE_CONTENT = {
  user: '会员',
  desk: '工位',
  contract: '合同',
  org: '公司',
  invoice: '账单'
}

export const MEETING_SHIP_RESOURCE_TYPE = [
  {
    id: 'desk',
    name: '工位'
  },
  {
    id: 'booking',
    name: '会议室'
  },
  {
    id: 'printing',
    name: '打印机'
  }
]

export const MEETING_SHIP_RESOURCE_TYPE_OBJ = {
  desk: '工位',
  booking: '会议室',
  printing: '打印机'
}

export const CONTRACTAPPROVALSETTING = [
  {
    id: 'none',
    name: '不审批'
  },
  {
    id: 'group',
    name: '指定人员'
  }
]

export const USER_SELECT_INPUT = {
  placeholder: '选择员工',
  noResultsText: '没有找到'
}

export const DEPARTMENT_SELECT_INPUT = {
  placeholder: '选择部门',
  noResultsText: '没有找到'
}

export const MEMBERSHIPS_DURATION_UNITS = {
  months: '月',
  days: '天'
}

export const MEMBERSHIPS_DURATION_UNITS_ARRAY = [
  {
    id: 'months',
    name: '以月为单位购买',
    value: '以月为单位购买'
  },
  {
    id: 'days',
    name: '以天为单位购买',
    value: '以天为单位购买'
  }
]

export const MEMBERSHIP_RESOURCE_UNIT = {
  booking: '小时',
  desk: '个',
  printing: '张'
}

export const MEMBERSHIP_RESOURCE_TYPE_OBJ = {
  booking: '会议室',
  desk: '工位',
  printing: '打印'
}

export const MEMBERSHIPS_CYCLE_TYPE_ARRAY = [
  {
    id: 'one_time',
    value: '一次性购买',
    name: '一次性购买'
  }
  // {
  //   id : 'recurring',
  //   name : '定期支付',
  //   value : '定期支付'
  // }
]

export const MEMBERSHIPS_CYCLE_TYPE = {
  one_time: '一次性购买',
  recurring: '定期支付'
}

export const ORG_SUBSCRIPTION_STATES = {
  active: '已签',
  expiring: '快到期',
  cancelled: '已取消',
  ended: '已结束',
  approving: '审批中',
  rejected: '审批拒绝',
  waiting_approving: '待审批',
  already_approving: '审批中',
  draft: '待签字'
}
export const ORG_SUBSCRIPTIONS_STATES = {
  active: '已签',
  expiring: '快到期',
  cancelled: '已取消',
  ended: '已结束',
  approving: '审批中',
  rejected: '审批拒绝',
  draft: '待签字'
}

export const ORG_SUBSCRIPTION_STATES_CLASS = {
  active: 'receipt-finish',
  expiring: 'receipt-expiring',
  cancelled: 'receipt-completed',
  ended: 'receipt-overdue',
  approving: 'receipt-approving',
  rejected: 'receipt-approving',
  draft: 'receipt-draft',
  waiting_approving: 'receipt-approving',
  already_approving: 'receipt-approving'
}

export const ORG_SUBSCRIPTION_STATES_CLASS_NEW = {
  active: 'receipt-finish_new',
  expiring: 'receipt-expiring_new',
  cancelled: 'receipt-cancelled_new',
  ended: 'receipt-overdue_new',
  approving: 'receipt-approving_new',
  rejected: 'receipt-rejected_new',
  draft: 'receipt-draft_new',
  waiting_approving: 'receipt-waiting_approving_new',
  already_approving: 'receipt-already_approving_new'
}

export const INVOICES_CONTRACT_STATES = [
  {
    id: 'active',
    name: '已签'
  },
  {
    id: 'expiring',
    name: '快到期'
  },
  {
    id: 'cancelled',
    name: '已取消'
  },
  {
    id: 'ended',
    name: '已结束'
  },
  {
    id: 'approving',
    name: '审批中'
  },
  {
    id: 'rejected',
    name: '审批拒绝'
  },
  {
    id: 'draft',
    name: '待签字'
  }
]

export const INVOICES_SUBSCRIPTION_STATES = [
  {
    id: '',
    name: '全部'
  },
  {
    id: 'available',
    name: '当前合同'
  },
  {
    id: 'draft',
    name: '待签字'
  },
  {
    id: 'approving',
    name: '审批中'
  },
  {
    id: 'rejected',
    name: '审批拒绝'
  },
  {
    id: 'ended',
    name: '已结束'
  },
  {
    id: 'cancelled',
    name: '已取消'
  }
]

export const NOTE_HANDLE = {
  join_SalesSubscription: {
    class: 'icon-Performance-contract',
    color: '#ffcd7a'
  },
  join_OrganizationUser: {
    class: 'icon-entry',
    color: '#a7c2b1'
  },
  join_Organization: {
    class: 'icon-entry',
    color: '#a7c2b1'
  },
  left_SalesSubscription: {
    class: 'icon-leave',
    color: '#ef91a9'
  },
  left_OrganizationUser: {
    class: 'icon-quit',
    color: '#ec6b40'
  },
  left_Organization: {
    class: 'icon-quit',
    color: '#ec6b40'
  },
  remove_SalesSubscription: {
    class: 'icon-leave',
    color: '#ef91a9'
  },
  remove_OrganizationUser: {
    class: 'icon-quit',
    color: '#ec6b40'
  },
  remove_Organization: {
    class: 'icon-quit',
    color: '#ec6b40'
  },
  join_null: {
    class: 'icon-enter',
    color: '#ef4e4f'
  },
  left_null: {
    class: 'icon-leave',
    color: '#ef91a9'
  },
  remove_null: {
    class: 'icon-leave',
    color: '#ef91a9'
  },
  cancelled_null: {
    class: 'icon-audit-reject',
    color: '#9288e1'
  },
  cancelled_SalesSubscription: {
    class: 'icon-audit-reject',
    color: '#9288e1'
  },
  approve_SalesSubscription: {
    class: 'icon-audit-pass',
    color: '#73bb4b'
  },
  null_null: {
    class: 'icon-interview',
    color: '#d1658f'
  },
  rejected_SalesSubscription: {
    class: 'icon-quit',
    color: '#ec6b40'
  }
}

export const TERMINATE_REASON = [
  {
    name: '公司解散',
    id: 'dismissed'
  },
  {
    name: '临时过渡',
    id: 'transition'
  },
  {
    name: '价格问题',
    id: 'high_price'
  },
  {
    name: '主动劝退',
    id: 'discouraged'
  },
  {
    name: '转至其它场所',
    id: 'moved'
  },
  {
    name: '更换分区',
    id: 'move_area'
  },
  {
    name: '合同修改',
    id: 'modify'
  },
  {
    name: '输入错误',
    id: 'input_mistake'
  },
  {
    name: '其他原因',
    id: 'other_ended'
  }
]

export const LOCATION_SEND_MESSAGE_RANGE = [
  {
    name: '全部会员',
    id: 'all_user'
  },
  {
    name: '全部公司负责人',
    id: 'all_ceo'
  }
]

export const SEND_MESSAGE_RANGE_OBJ = {
  all_user: '全部会员',
  all_ceo: '全部公司负责人'
}

//分店设置 客户端是否可见
export const LOCATION_PUBLIC = [
  {
    id: 'true',
    name: '设置客户端可见'
  },
  {
    id: 'false',
    name: '设置客户端不可见'
  }
]

//分店设置 是否允许普通用户自行绑定卡
export const CAN_BINDING_CARD = [
  {
    id: 'true',
    name: '允许用户绑定门禁IC卡'
  },
  {
    id: 'false',
    name: '不允许用户绑定门禁IC卡'
  }
]

//分店设置 是否允许用户自行设置触摸密码
export const CAN_SET_TOUCH_PASSWORD = [
  {
    id: 'true',
    name: '允许用户设置触摸密码'
  },
  {
    id: 'false',
    name: '不允许用户设置触摸密码'
  }
]

//分店设置 是否显示蓝牙门禁
export const DISPLAY_BLUETOOTH_LOCK = [
  {
    id: 'true',
    name: '显示蓝牙门禁'
  },
  {
    id: 'false',
    name: '不显示蓝牙门禁'
  }
]

//分店设置 是否靠近门才能开门
export const NEED_CLOSE_TO_LOCK = [
  {
    id: 'true',
    name: '是'
  },
  {
    id: 'false',
    name: '否'
  }
]

export const RECURRING_CYCLES = [
  { name: '一个月', id: 1 },
  { name: '两个月', id: 2 },
  { name: '三个月', id: 3 },
  { name: '四个月', id: 4 },
  { name: '五个月', id: 5 },
  { name: '六个月', id: 6 },
  { name: '七个月', id: 7 },
  { name: '八个月', id: 8 },
  { name: '九个月', id: 9 },
  { name: '十个月', id: 10 },
  { name: '十一个月', id: 11 },
  { name: '十二个月', id: 12 }
]

export const RECURRING_CYCLES_DEPOSIT = [
  { name: '免会员费', id: 0 },
  { name: '一个月', id: 1 },
  { name: '两个月', id: 2 }
]

export const RECURRING_CYCLES_STATE = {
  1: '一个月',
  2: '两个月',
  3: '三个月',
  4: '四个月',
  6: '六个月',
  12: '十二个月'
}

export const SUBSCRIPTION_DATE_TYPE = {
  weekly: '每周',
  monthly: '每月',
  yearly: '每年'
}

export const CONTRACT_END_DATE_BTN = [
  {
    name: '一个月',
    id: '1'
  },
  {
    name: '三个月',
    id: '3'
  },
  {
    name: '六个月',
    id: '6'
  },
  {
    name: '一年',
    id: '12'
  },
  {
    name: '两年',
    id: '24'
  },
  {
    name: '三年',
    id: '36'
  },
  {
    name: '五年',
    id: '60'
  }
]

export const TASK_SECTIONS = {
  my: '我负责的报修',
  all: '所有报修任务',
  following: '我参与的报修',
  reports: '下属负责的报修'
}

export const ORG_ROLES_FORM = [
  {
    title: '公司负责人',
    value: 'employer'
  },
  {
    title: '财务负责人',
    value: 'finance'
  },
  {
    title: '公司一般成员',
    value: 'employee'
  }
]

export const ORG_ROLES_OBJ = {
  employer: '公司负责人',
  finance: '财务负责人',
  employee: '公司一般成员'
}

export const ORG_FUNDING_STAGES_FORM = [
  {
    id: 1,
    name: '尚未获投'
  },
  {
    id: 2,
    name: '种子轮'
  },
  {
    id: 3,
    name: '天使轮'
  },
  {
    id: 4,
    name: 'Pre-A轮'
  },
  {
    id: 5,
    name: 'A轮'
  },
  {
    id: 6,
    name: 'A+轮'
  },
  {
    id: 7,
    name: 'Pre-B轮'
  },
  {
    id: 8,
    name: 'B轮'
  },
  {
    id: 9,
    name: 'B+轮'
  },
  {
    id: 10,
    name: 'C轮'
  },
  {
    id: 11,
    name: 'D轮'
  },
  {
    id: 12,
    name: 'E轮'
  },
  {
    id: 13,
    name: 'F轮-上市前'
  },
  {
    id: 14,
    name: '已上市'
  },
  {
    id: 15,
    name: '新三板'
  },
  {
    id: 16,
    name: '战略投资'
  },
  {
    id: 17,
    name: '已被收购'
  },
  {
    id: 18,
    name: '不明确'
  }
]

export const ORG_INDUSTRIES_FORM = [
  {
    id: 1,
    name: '互联网'
  },
  {
    id: 2,
    name: '移动互联网'
  },
  {
    id: 3,
    name: '电商'
  }
]

/**
 * 会议室主题
 */
export const MEETING_THEME_TYPE = {
  image: '图片',
  video: '视频'
}

export const MEETING_THEME_CATEGORY = {
  one: '主题一',
  two: '主题二',
  three: '主题三',
  four: '主题四',
  five: '主题五'
}

export const MEETING_THEME_CATEGORY_ARRAY = [
  {
    name: '主题一',
    id: 'one'
  },
  {
    name: '主题二',
    id: 'two'
  },
  {
    name: '主题三',
    id: 'three'
  },
  {
    name: '主题四',
    id: 'four'
  },
  {
    name: '主题五',
    id: 'five'
  }
]

export const MEETING_THEME_UPLOAD_ARRAY = [
  {
    name: '图片',
    id: 'image'
  },
  {
    name: '视频',
    id: 'video'
  }
]

/**
 * 会议室管理
 * @type {Array}
 */
export const ORIENTATION_STATES = [
  {
    name: '东',
    id: 'east'
  },
  {
    name: '西',
    id: 'west'
  },
  {
    name: '南',
    id: 'south'
  },
  {
    name: '北',
    id: 'north'
  },
  {
    name: '东南',
    id: 'southeast'
  },
  {
    name: '西南',
    id: 'southwest'
  },
  {
    name: '东北',
    id: 'northeast'
  },
  {
    name: '西北',
    id: 'northwest'
  }
]

export const ORIENTATION_STATES_OBJ = {
  east: '东',
  west: '西',
  south: '南',
  north: '北',
  southeast: '东南',
  southwest: '西南',
  northeast: '东北',
  northwest: '西北'
}

export const MEETING_DEVICE = {
  1: 'icon-Whiteboard',
  2: 'icon-tv',
  3: 'icon-slide',
  4: 'icon-apple-tv',
  5: 'icon-projection',
  6: 'icon-tv',
  7: 'icon-icon_phone',
  8: 'icon-pc',
  9: 'icon-mac',
  10: 'icon-camera'
}

export const AREATYPES_STATES = [
  {
    name: '会议室',
    id: 'meeting_room'
  },
  {
    name: '休息区',
    id: 'break_room'
  },
  {
    name: '咖啡店',
    id: 'cafe'
  },
  {
    name: '健身房',
    id: 'gym'
  },
  {
    name: '活动区',
    id: 'activity_room'
  },
  {
    name: '演播室',
    id: 'broadcasting_studio'
  },
  {
    name: '摄影棚',
    id: 'photography_studio'
  },
  {
    name: '游戏室',
    id: 'gaming_room'
  },
  {
    name: '睡觉区',
    id: 'sleeping_room'
  },
  {
    name: '其他',
    id: 'other'
  }
]

export const AREATYPES_TIPSY = {
  meeting_room: '会议室',
  break_room: '休息区',
  cafe: '咖啡店',
  gym: '健身房',
  activity_room: '活动区',
  broadcasting_studio: '演播室',
  photography_studio: '摄影棚',
  gaming_room: '游戏室',
  sleeping_room: '睡觉区',
  other: '其他'
}

export const LOCK_MANAGE_TIPSY = {
  gate: '大门',
  private_office: '独立办公室',
  public_office: '开放办公区',
  flexible_office: '灵活办公区',
  activity_room: '多功能活动区',
  meeting_room: '会议室',
  meeting_room: '会议室',
  break_room: '休息区',
  cafe: '咖啡店',
  gym: '健身房',
  broadcasting_studio: '演播室',
  photography_studio: '摄影棚',
  gaming_room: '游戏室',
  sleeping_room: '睡觉区',
  floor: '虚拟区域',
  other: '其他'
}

export const AREATYPES_CLASS = {
  meeting_room: 'icon-meeting',
  break_room: 'icon-break_room',
  cafe: 'icon-cafe',
  gym: 'icon-gym',
  activity_room: 'icon-activity_room',
  broadcasting_studio: 'icon-broadcasting_studio',
  photography_studio: 'icon-photography_studio',
  gaming_room: 'icon-gaming_room',
  sleeping_room: 'icon-sleeping_room',
  other: 'icon-other'
}

export const RESERVATION_PRIVILEGES = [
  {
    title: '凯德星会员可预订 ',
    id: 'tenant',
    name: '凯德星会员可预订 ',
    value: 'tenant'
  },
  {
    title: '办公楼会员可预订',
    value: 'space_tenant',
    id: 'space_tenant',
    name: '办公楼会员可预订'
  },
  {
    title: '本项目办公楼会员可预订',
    value: 'location_tenant',
    id: 'location_tenant',
    name: '本项目办公楼会员可预订'
  },
  {
    title: '空间管理员可预订',
    value: 'space_manager',
    id: 'space_manager',
    name: '空间管理员可预订'
  },
  {
    title: '办公&co-working会员可预订',
    value: 'space_and_normal_tenant',
    id: 'space_and_normal_tenant',
    name: '办公&co-working会员可预订'
  }
]

export const LOCKER_RESERVATION_PRIVILEGES = [
  {
    title: '凯德星会员可预订',
    id: 'all_user',
    name: '凯德星会员可预订',
    value: 'all_user'
  },
  {
    title: '办公楼会员可预订',
    value: 'space_tenant',
    id: 'space_tenant',
    name: '办公楼会员可预订'
  },
  {
    title: '本项目办公楼会员可预订',
    value: 'location_group_tenant',
    id: 'location_group_tenant',
    name: '本项目办公楼会员可预订'
  }
]

export const RESERVATION_PROGRESS_STATUS = {
  upcoming: '未开始',
  proceeding: '进行中',
  ended: '已结束'
}

export const RESERVATION_PROGRESS_CLASS = {
  upcoming: 'progress-upcoming',
  proceeding: 'progress-proceeding',
  ended: 'progress-ended'
}

/**
 * 会议室管理OBJ
 * @type {Object}
 */

export const NON_MEETING_ROOMS = ['public_office', 'private_office']
export const OFFICE_AREA_TYPES = ['public_office', 'private_office']

export const OFFICE_AREA_STATE = {
  public_office: '公开办公区',
  private_office: '独立办公区',
  flexible_office: '灵活办公区',
  floor: '虚拟区域'
}

export const AREAS_TYPE = [
  {
    id: 'all',
    name: '全部'
  },
  {
    id: 'public_office',
    name: '开放工位'
  },
  {
    id: 'private_office',
    name: '独立办公室'
  }
]

export const AREAS_STATE = [
  {
    id: 'all',
    name: '全部'
  },
  {
    id: 'free',
    name: '未分配'
  },
  {
    id: 'assigned',
    name: '已分配'
  },
  {
    id: 'expiring',
    name: '快到期'
  }
]

export const DESK_LIST_STATES = [
  {
    id: 'free',
    name: '未分配'
  },
  {
    id: 'approving',
    name: '审批中'
  },
  {
    id: 'assigned',
    name: '已分配'
  },
  //   {
  //     id   : 'expiring',
  //     name : '快到期'
  //   },
  {
    id: 'reserved',
    name: '已出租'
  }
]

export const AREAS_LIST_STATE = [
  {
    id: 'all',
    name: '全部'
  },
  {
    id: 'free',
    name: '未分配'
  },
  {
    id: 'approving',
    name: '审批中'
  },
  {
    id: 'partial_free',
    name: '部分分配'
  },
  {
    id: 'assigned',
    name: '已分配'
  },
  {
    id: 'reserved',
    name: '已出租'
  },
  {
    id: 'expiring',
    name: '快到期'
  }
]

export const AREA_STATES_CLASS = {
  free: 'free',
  assigned: 'occupy',
  expiring: 'expiring',
  partial_free: 'partial_free',
  reserved: 'reserved',
  approving: 'approving'
}

export const AREAS_STATE_OBJ = {
  free: '空闲',
  partial_free: '部分工位空闲',
  reserved: '已出租',
  assigned: '已分配',
  expiring: '即将到期',
  approving: '审批中',
  disabled: '已停用'
}

export const DESK_STATES = {
  free: '未出租',
  assigned: '已分配',
  expiring: '快到期',
  reserved: '未分配',
  partial_free: '部分空闲',
  approving: '审批中'
}

export const DESK_STATES_CLASS = {
  free: 'empty',
  assigned: 'occupy',
  expiring: 'expiring',
  partial_free: 'partial_free',
  reserved: 'reserved',
  approving: 'approving'
}

export const NETWORK_TYPE = {
  two_g: '2G联网',
  ethernet_cable: '网线联网',
  wifi: 'WiFi联网'
}

export const OPPORTUNITY_STATES = {
  preliminary: '初步接洽',
  determined: '需求确定',
  plan: '方案报价',
  audit: '谈判审核',
  success: '赢单',
  shelved: '搁置',
  failed: '流失',
  voided: '无效',
  entered: '已入驻',
  archived: '已毕业'
}

export const OPPORTUNITY_DESIRES = {
  very_strong: '很强烈',
  ordinary: '一般',
  not_strong: '不强烈',
  not_clear: '不明确'
}

export const OPPORTUNITY_STATES_CLASS = {
  preliminary: 'status-initial',
  determined: 'status-certain',
  plan: 'status-scheme',
  audit: 'status-audit',
  success: 'status-win',
  failed: 'status-failed',
  shelved: 'status-shelved',
  voided: 'status-disable',
  entered: 'status-entered',
  archived: 'status-archived'
}

export const OPPORTUNITY_DESIRES_CLASS = {
  very_strong: 'desire-strong',
  ordinary: 'desire-ordinary',
  not_strong: 'desire-notstrong',
  not_clear: 'desire-notclear'
}

export const LEAD_STATES = {
  pending: '未处理',
  contacted: '已联系',
  close: '关闭'
}

export const MEETING_SHIP_AREA_TYPE = [
  {
    id: 'public_office',
    name: '开放办公区'
  },
  {
    id: 'private_office',
    name: '独立办公室'
  }
]

export const MEETING_SHIP_STATUS_OBJ = {
  draft: '草稿',
  formal: '正式的'
}

export const MEETING_SHIP_AREA_TYPE_OBJ = {
  public_office: '开放办公区',
  private_office: '独立办公室',
  'public_office,private_office': '开放办公区和独立办公室',
  floor: '虚拟区域'
}

export const MEETING_SHIP_RESOURCES_TYPE = [
  {
    id: 'printing',
    name: '打印'
  }
]

export const MEETING_SHIP_RESOURCES_TYPE_OBJ = {
  printing: '打印'
}

export const CRM_AREA_TYPE = [
  {
    id: 'public_office',
    name: '开放办公区'
  },
  {
    id: 'private_office',
    name: '独立办公室'
  },
  {
    id: 'flexible_office',
    name: '灵活办公区'
  },
  {
    id: 'scattered',
    name: '灵活工位'
  },
  {
    id: 'floor',
    name: '虚拟区域'
  }
]

export const CRM_AREA_TYPE_OBJ = {
  public_office: '开放办公区',
  private_office: '独立办公室',
  flexible_office: '灵活办公区',
  scattered: '灵活工位',
  floor: '虚拟区域'
}

export const REQUIREMENT_TYPE = [
  {
    id: 'desk',
    name: '办公区租赁'
  },
  {
    id: 'field',
    name: '场地租赁'
  },
  {
    id: 'other',
    name: '其它'
  }
]

export const REQUIREMENT_TYPE_OBJ = {
  desk: '办公区租赁',
  field: '场地租赁',
  other: '其它'
}

// 客户意向租赁面积可选值
export const CRM_INTENT_RENT_AREA_SIZE = [
  {
    id: '1-10',
    name: '1-10'
  },
  {
    id: '11-20',
    name: '11-20'
  },
  {
    id: '21-50',
    name: '21-50'
  },
  {
    id: '51-100',
    name: '51-100'
  },
  {
    id: '101-200',
    name: '101-200'
  },
  {
    id: '200以上',
    name: '200以上'
  }
]

export const CRM_INTENT_RENT_AREA_SIZE_OBJ = {
  '1-10': '1-10',
  '11-20': '11-20',
  '21-50': '21-50',
  '51-100': '51-100',
  '101-200': '101-200',
  '200以上': '200以上'
}

export const CRM_COMPANY_TYPE = [
  {
    id: 'office',
    name: '办事处'
  },
  {
    id: 'branch_office',
    name: '分公司'
  },
  {
    id: 'startup',
    name: '初创企业'
  },
  {
    id: 'personal',
    name: '个人职业'
  },
  {
    id: 'common',
    name: '普通公司'
  },
  {
    id: 'other',
    name: '其它'
  }
]

export const CRM_COMPANY_TYPE_OBJ = {
  office: '办事处',
  branch_office: '分公司',
  startup: '初创企业',
  personal: '个人职业',
  other: '其它',
  common: '普通公司'
}

export const CRM_DESIRE = [
  {
    id: 'very_strong',
    name: '很强烈'
  },
  {
    id: 'ordinary',
    name: '一般'
  },
  {
    id: 'not_strong',
    name: '不强烈'
  },
  {
    id: 'not_clear',
    name: '不明确'
  }
]

export const CRM_DESIRE_OBJ = {
  very_strong: '很强烈',
  ordinary: '一般',
  not_strong: '不强烈',
  not_clear: '不明确'
}

export const CRM_VISIT_TYPE = [
  {
    id: 'visited',
    name: '客户已参观'
  },
  {
    id: 'pending',
    name: '客户预约参观'
  },
  {
    id: 'no_visited',
    name: '客户未参观'
  }
]

export const CRM_VISIT_TYPE_OBJ = {
  visited: '客户已参观',
  pending: '客户预约参观',
  no_visited: '客户未参观'
}

export const CRM_OPPORTUNITY_STATES = [
  {
    id: 'preliminary',
    name: '初步接洽',
    class: 'status-initial'
  },
  {
    id: 'plan',
    name: '方案报价',
    class: 'status-scheme'
  },
  {
    id: 'success',
    name: '赢单',
    class: 'status-win'
  },
  {
    id: 'shelved',
    name: '搁置',
    class: 'status-shelved'
  },
  {
    id: 'failed',
    name: '流失',
    class: 'status-failed'
  }
]

export const CRM_OPPORTUNITY_STATE = [
  {
    id: 'preliminary',
    name: '初步接洽',
    class: 'status-initial'
  },
  {
    id: 'plan',
    name: '方案报价',
    class: 'status-scheme'
  },
  {
    id: 'success',
    name: '赢单',
    class: 'status-win'
  },
  {
    id: 'entered',
    name: '已入驻',
    class: 'status-entered'
  },
  {
    id: 'archived',
    name: '已毕业',
    class: 'status-archived'
  },
  {
    id: 'shelved',
    name: '搁置',
    class: 'status-shelved'
  },
  {
    id: 'failed',
    name: '流失',
    class: 'status-failed'
  }
]

export const CRM_OPPORTUNITY_DESIRES = [
  {
    id: 'very_strong',
    name: '很强烈',
    class: 'desire-strong'
  },
  {
    id: 'ordinary',
    name: '一般',
    class: 'desire-ordinary'
  },
  {
    id: 'not_strong',
    name: '不强烈',
    class: 'desire-notstrong'
  },
  {
    id: 'not_clear',
    name: '不明确',
    class: 'desire-notclear'
  }
]

export const CRM_FAILED_REASON = [
  {
    id: 'expensive',
    name: '价格'
  },
  {
    id: 'no_need',
    name: '暂时不需要'
  },
  {
    id: 'lighting',
    name: '采光'
  },
  {
    id: 'layout',
    name: '格局'
  },
  {
    id: 'narrow',
    name: '拥挤'
  },
  {
    id: 'old_fashion',
    name: '传统办公'
  },
  {
    id: 'environment',
    name: '办公环境'
  },
  {
    id: 'story_height',
    name: '层高'
  },
  {
    id: 'smell',
    name: '味道'
  },
  {
    id: 'chose_others',
    name: '选择其他联合办公'
  }
]

export const CRM_FAILED_REASON_OBJ = {
  expensive: '价格',
  no_need: '暂时不需要',
  lighting: '采光',
  layout: '格局',
  narrow: '拥挤',
  old_fashion: '传统办公',
  environment: '办公环境',
  story_height: '层高',
  smell: '味道',
  chose_others: '选择其他联合办公',
  geographic_location: '地理位置',
  too_far: '位置太远',
  traffic: '交通不便',
  price_factor: '价格因素',
  too_expensive: '太贵',
  registration: '注册无法满足',
  unsatisfied_demand: '无法满足公司需求',
  rent_method: '租赁方式不符合',
  underaction: '不够灵活',
  excessive_cycle: '租期过长',
  unmatched_space: '空间格局不符合',
  awkward: '设计不喜欢',
  stuffy_room: '房间不通透',
  improper_desk: '工位数不合适',
  decorate: '装修因素',
  dislike_style: '风格不喜欢',
  space_taint: '空间有异味',
  dislike_tall: '层高不喜欢',
  clock_other_space: '已租其它空间',
  only_adviser: '仅咨询',
  other_factor: '其它',
  need_hatch: '需求孵化政策',
  no_answer: '多次拨打未接',
  visit_or_study: '参观学习'
}

export const CRM_LEADS_STATE_ARRAY = [
  {
    id: 'not_contact',
    name: '未联系'
  },
  {
    id: 'contacted',
    name: '已联系'
  },
  {
    id: 'new_opportunity',
    name: '新机会'
  },
  {
    id: 'no_chance',
    name: '没有机会'
  }
]

export const CRM_LEADS_STATE = {
  not_contact: '未联系',
  contacted: '已联系',
  new_opportunity: '新机会',
  no_chance: '没有机会'
}

export const CRM_USER_TYPE_STATUS = [
  {
    title: '所有客户',
    status: 'all'
  },
  {
    title: '我负责的',
    status: 'me'
  },
  {
    title: '我参与的',
    status: 'descendant'
  }
]

export const CRM_USER_TYPE_STAT = {
  all: '所有客户',
  me: '我负责的',
  descendant: '我参与的'
}

export const CRM_EMPLOYEE_NUMBER = (start, end) => {
  let people_num = []
  for (let i = start; i <= end; i++) {
    people_num.push({
      id: i.toString(),
      name: i.toString()
    })
  }
  return people_num
}

export const CRM_TIME_NUMBER = (start, end, space = 1) => {
  let people_num = []
  for (let i = start; i <= end; i = i + space) {
    let value = i < 10 ? '0' + i : i.toString()
    people_num.push({
      id: value,
      name: value
    })
  }
  return people_num
}

export const PAYMENT_TYPE = [
  {
    id: 'both',
    name: '现金和虚拟货币都支持'
  },
  {
    id: 'money',
    name: '现金支付'
  },
  {
    id: 'point',
    name: '虚拟货币支付'
  }
]

export const PAYMENT_TYPE_OBJ = {
  both: '现金和积分都支持',
  money: '现金支付',
  point: '虚拟货币支付',
  balance: '余额支付'
}

export const PAYMENT_TYPE_ORDER = {
  credits: '会员支付',
  money: '现金支付',
  point: '虚拟货币支付'
}

export const CRM_SOURCE_TYPES = [
  {
    id: 'advertisement',
    name: '广告'
  },
  {
    id: 'workshop',
    name: '研讨会'
  },
  {
    id: 'sem',
    name: '百度'
  },
  {
    id: '客户介绍',
    name: '客户介绍'
  }
]

export const CRM_LEVEL_TYPES = [
  {
    id: 'ordinary',
    name: '普通的'
  },
  {
    id: 'important',
    name: '重要的'
  }
]

export const CRM_CUSTOMER_TYPES = [
  { id: 'new_customer', name: '新客户' },
  { id: 'old_customer', name: '老客户' }
]

export const CRM_GENDER_TYPES = [
  {
    value: 'male',
    title: '男'
  },
  {
    value: 'female',
    title: '女'
  }
]

export const IS_FINISH = [
  {
    value: true,
    title: '是'
  },
  {
    value: false,
    title: '否'
  }
]

/**
 * 是否有锁有墙
 * @type {Array}
 */
export const LOCK_ARRAY = [
  {
    title: '有',
    id: true,
    name: '有',
    value: true
  },
  {
    title: '无',
    value: false,
    id: false,
    name: '无'
  }
]

/// Tasks

export const TASK_STATES = {
  in_progress: '进行中',
  reopen: '重新打开',
  completed: '已完成',
  overdue: '已逾期',
  closed: '已关闭',
  pending: '审核中',
  submit_task: '已提交',
  received: '已接单'
}
export const TASK_STATES_CLASS = {
  received: 'receipt-new',
  in_progress: 'receipt-in_progress',
  reopen: 'receipt-reopen',
  completed: 'receipt-completed',
  overdue: 'receipt-overdue',
  closed: 'receipt-closed',
  pending: 'receipt-pending',
  submit_task: 'receipt-submit_task'
}

export const TASK_UPDATE_STATE = [
  {
    id: 'in_progress',
    name: '进行中'
  },
  {
    id: 'reopen',
    name: '重新打开'
  },
  {
    id: 'completed',
    name: '已完成'
  },
  {
    id: 'overdue',
    name: '已逾期'
  },
  {
    id: 'submit_task',
    name: '已提交'
  }
]

export const TASK_PRIORITIES = {
  normal: '普通',
  high: '紧急'
}

export const TASK_PRIORITIES_FORM = [
  {
    id: 'normal',
    name: '普通'
  },
  {
    id: 'high',
    name: '重要'
  }
]

export const TASK_SUBTYPES_FORM = [
  {
    id: 'maintenance',
    name: '维护'
  },
  {
    id: 'it',
    name: 'IT维修'
  },
  {
    id: 'office',
    name: '办公维护'
  },
  {
    id: 'engineering',
    name: '工程维修'
  },
  {
    id: 'requirement',
    name: '要求'
  },
  {
    id: 'suggestion',
    name: '建议'
  },
  {
    id: 'general',
    name: '一般'
  },
  {
    id: 'other',
    name: '其他'
  }
]
export const TASK_SOURCETYPE_FORM = [
  { id: 'space_member', name: '空间管理者' },
  { id: 'organization_user', name: '组织员工' }
]

export const SPACE_MEMBER_LOCATION_ROLES = [
  {
    id: 'location_operator',
    name: '单楼栋运营'
  },
  {
    id: 'observer',
    name: '观察人员'
  },
  {
    id: 'finance',
    name: '分店财务负责人'
  },
  {
    id: 'location_manager',
    name: '分店负责人'
  },
  {
    id: 'sales',
    name: '招商人员'
  },
  {
    id: 'repair',
    name: '分店报修负责人'
  },
  {
    id: 'contract',
    name: '合同审批人'
  },
  {
    id: 'abnormal_contract',
    name: '低价合同审批人'
  },
  {
    id: 'visitor',
    name: '访客管理人员'
  },
  {
    id: 'landlord',
    name: '项目外部管理人员'
  }
]

export const LOCATION_ROLES = {
  location_operator: '运营人员',
  location_manager: '店长',
  finance: '财务人员',
  observer: '观察人员',
  sales: '招商人员',
  repair: '分店报修负责人',
  visitor: '访客管理人员'
}

export const TASK_TYPE_FORM = [
  {
    id: 'repair',
    name: '修理'
  }
  //{
  //  id : 'crm',
  //  name : 'CRM'
  //}
]

export const TASK_SUBTYPES = {
  maintenance: '维护',
  it: 'IT维修',
  office: '办公维护',
  engineering: '工程维修',
  requirement: '要求',
  suggestion: '建议',
  general: '一般',
  other: '其他'
}

export const TASK_ASSIGN_TO = {
  all: '所有人的',
  my: '我负责的',
  reports: '我的下属',
  following: '我参与的'
}

export const TASK_API_ASSIGNED_TO = {
  all: { assigned_to: 'all' },
  my: { assigned_to: 'me' },
  reports: { assigned_to: 'descendants' },
  following: { followers: 'me' }
}

export const TASK_NOTETYPE_FORM = [
  {
    id: 'wechat',
    name: '发微信'
  },
  {
    id: 'phone',
    name: '打电话'
  },
  {
    id: 'visit',
    name: '客户上门'
  },
  {
    id: 'send_email',
    name: '发邮件'
  },
  {
    id: 'send_message',
    name: '发短信'
  }
]

export const TASK_NOTETYPE = {
  wechat: '发微信',
  phone: '打电话',
  visit: '客户上门',
  send_email: '发邮件',
  send_message: '发短信'
}

export const TASK_UPDATE_TYPES = {
  state: '更新了任务状态',
  priority: '更新了重要性',
  assigned_to: '更新了任务负责人',
  due_date: '更新了指定完成日期',
  reset_due_date: '取消了指定完成日期',
  subject: '更新了任务内容',
  add_follower: '添加了参与人',
  remove_follower: '删除了参与人',
  upload_photo: '上传了照片',
  update: '更新了状态',
  create_task: '创建了任务',
  subtype: '更新了任务类别'
}

export const TASK_UDPATE_ICONS = {
  state: 'icon-edit',
  priority: 'icon-edit',
  subject: 'icon-edit',
  due_date: 'icon-calendar',
  reset_due_date: 'icon-calendar',
  assigned_to: 'icon-people',
  add_follower: 'icon-people',
  remove_follower: 'icon-people',
  upload_photo: 'icon-photo',
  update: 'icon-pen'
}

export const INVOICES_STATE = {
  unpaid: '待支付',
  partially_paid: '部分支付',
  paid: '已支付',
  refunding: '待退款',
  overdue: '已逾期',
  refunded: '已退款',
  offline_refunded: '已线下退款',
  cancelled: '已取消',
  _deleted: '已删除',
  reverse_approving: '红冲审批中',
  partially_reversed:'部分红冲',
  reverse_approved: '已红冲'
}

export const INVOICE_TYPE = [
  {
    id: 'credit',
    name: '信用'
  },
  {
    id: 'money',
    name: '现金'
  },
  {
    id: 'point',
    name: '办公券'
  },
  {
    id: 'balance',
    name: '余额'
  }
]

export const INVOICES_TYPE = {
  deposit: '工位保证金',
  rent: '工位会员费',
  rent_and_deposit: '工位会员费和保证金',
  refund: '退款',
  custom: '手动添加',
  reservation: '会议室',
  membership: '会员',
  membership_resource: '资源',
  point_plan: '积分充值',
  print_reservation: '打印',
  product_reservation: '商品',
  activity: '活动',
  desk_reservation: '工位预订',
  parking_deposit: '车位保证金',
  parking_rent: '车位会员费',
  parking_rent_and_deposit: '车位会员费和保证金'
}

export const INVOICES_ORDER_TYPE = [
  {
    id: '',
    name: '全部'
  },
  {
    id: 'reservation',
    name: '会议室'
  },
  {
    id: 'membership',
    name: '会员'
  },
  {
    id: 'point_plan',
    name: '积分充值'
  },
  {
    id: 'print_reservation',
    name: '打印'
  },
  {
    id: 'product_reservation',
    name: '商品'
  },
  {
    id: 'membership_resource',
    name: '资源'
  },
  {
    id: 'activity',
    name: '活动'
  },
  {
    id: 'desk_reservation',
    name: '工位预订'
  }
]

export const INVOICES_UPDATE_TYPE = [
  {
    id: 'deposit',
    name: '工位保证金'
  },
  {
    id: 'rent',
    name: '工位租金'
  },
  {
    id:'no_sales_area_cycle_fee',
    name:'周期性费用'
  },
  // {
  //   id: 'parking_deposit',
  //   name: '车位保证金'
  // },
  // {
  //   id: 'parking_rent',
  //   name: '车位租金'
  // },
  {
    id: 'refund',
    name: '退款'
  },
  {
    id: 'custom',
    name: '手动添加'
  },
  {
    id: 'reservation',
    name: '会议室'
  },
  {
    id: 'desk_reservation',
    name: '工位预订'
  },
  {
    id: 'membership',
    name: '会员'
  },
  {
    id: 'membership_resource',
    name: '资源'
  }
]

export const INVOICES_STATE_CLASS = {
  unpaid: 'receipt-unpaid',
  partially_paid: 'receipt-going',
  refunding: 'receipt-refunding',
  paid: 'receipt-finish',
  overdue: 'receipt-debt',
  cancelled: 'receipt-debt',
  refunded: 'receipt-confirmed',
  offline_refunded: 'receipt-confirmed',
  reverse_approving: 'receipt-approving',
  _deleted: 'receipt-refunding',
  reversed:'receipt-paid',
  partially_reversed: 'receipt-approving',
}

export const INVOICES_SENDING_STATE = {
  unsent: '未发送账单',
  sent: '已发送账单'
}

export const INVOICES_SENDING_STATE_CLASS = {
  unsent: 'not-send',
  sent: 'send-yet'
}

export const INVOICES_STAGE = [
  {
    id: 'started',
    name: '已生成账单'
  },
  {
    id: 'later',
    name: '未来账单'
  }
]

export const INVOICES_STAGE_STATUS = {
  started: '已生成账单',
  later: '未来账单'
}

export const INVOICES_IS_REVIEWED = [
  {
    id: '',
    name: '全部'
  },
  {
    id: true,
    name: '已审核'
  },
  {
    id: false,
    name: '未审核'
  }
]

export const INVOICES_UPDATE_STATE = [
  {
    id: 'unpaid',
    name: '待支付'
  },
  {
    id: 'partially_paid',
    name: '部分支付'
  },
  {
    id: 'paid',
    name: '已支付'
  },
  {
    id: 'unchecked',
    name: '待核销'
  },
  {
    id: 'checked',
    name: '已核销'
  },
  {
    id: 'confirmed',
    name: '确认核销'
  },
  {
    id: 'check_failed',
    name: '核销失败'
  },
  {
    id: 'overdue',
    name: '已逾期'
  },
  {
    id: 'refunding',
    name: '待退款'
  },
  {
    id: 'refunded',
    name: '已退款'
  },
  {
    id: 'cancelled',
    name: '已取消'
  },
  {
    id: '_deleted',
    name: '已删除'
  },
  {
    id: 'reverse_approving',
    name: '红冲审批中'
  },
  {
    id: 'reversed',
    name: '已红冲'
  },
  {
    id: 'partially_reversed',
    name: '部分红冲'
  }
]

export const ORDER_STATES_ARRAY = [
  {
    id: 'unpaid',
    name: '待支付'
  },
  {
    id: 'paid',
    name: '已支付'
  },
  {
    id: 'cancelled',
    name: '已取消'
  }
]

export const ORDER_STATE_ARRAY = [
  {
    id: '',
    name: '全部'
  },
  {
    id: 'unpaid',
    name: '待支付'
  },
  {
    id: 'paid',
    name: '已支付'
  },
  {
    id: 'cancelled',
    name: '已取消'
  },
  {
    id: 'refunding',
    name: '待退款'
  },
  {
    id: 'refunded',
    name: '已退款'
  },
  {
    id: 'offline_refunded',
    name: '已线下退款'
  }
]

export const INVOICE_SALES_PAYMENTS = {
  cash: '现金支付',
  pos: 'pos支付',
  point: '办公券支付',
  union_pay: '银联',
  wechat_offline: '微信(线下)',
  alipay_offline: '支付宝(线下)',
  balance: '余额付款',
  credit: '会员资源支付',
  manually: '人工核销',
  other: '其他'
}

export const INVOICE_ORDER_SALES_PAYMENTS = {
  cash: '现金支付',
  money: '现金支付',
  pos: 'pos支付',
  point: '办公券支付',
  union_pay: '银联',
  wechat: '微信(线上)',
  alipay: '支付宝(线上)',
  wechat_offline: '微信(线下)',
  alipay_offline: '支付宝(线下)',
  // balance: '余额付款',
  // credit: '会员资源支付',
  manually: '人工核销',
  prepay_card: '★凯星卡',
  free_purchase: '奕桥体验券',
  third_point: '星积分支付',
  other: '其他'
}

// 订单
export const INVOICE_ORDER_CERTIFICATE_PAYMENT_TYPES = [
  {
    id: 'cash',
    name: '现金支付'
  },
  {
    id: 'point',
    name: '办公券支付'
  },
  {
    id: 'manually',
    name: '人工核销'
  },
  {
    id: 'pos',
    name: 'pos支付'
  },
  {
    id: 'union_pay',
    name: '银联支付'
  },
  {
    id: 'wechat',
    name: '微信(线上)'
  },
  {
    id: 'wechat_offline',
    name: '微信(线下)'
  },
  {
    id: 'alipay',
    name: '支付宝(线上)'
  },
  {
    id: 'alipay_offline',
    name: '支付宝(线下)'
  },
  {
    id: 'balance',
    name: '余额付款'
  },
  {
    id: 'credit',
    name: '会员资源支付'
  },
  {
    id: 'prepay_card',
    name: '★凯星卡'
  },
  {
    id: 'free_purchase',
    name: '奕桥体验券'
  },
  {
    id: 'third_point',
    name: '星积分支付'
  },
  {
    id: 'other',
    name: '其他'
  }
]

// 账单凭证付款方式
export const INVOICE_CERTIFICATE_PAYMENT_TYPES = [
  {
    id: 'cash',
    name: '现金支付'
  },
  {
    id: 'point',
    name: '办公券支付'
  },
  {
    id: 'manually',
    name: '人工核销'
  },
  {
    id: 'pos',
    name: 'pos支付'
  },
  {
    id: 'union_pay',
    name: '银联支付'
  },
  {
    id: 'wechat_offline',
    name: '微信(线下)'
  },
  {
    id: 'alipay_offline',
    name: '支付宝(线下)'
  },
  // {
  //   id: 'balance',
  //   name: '余额付款'
  // },
  // {
  //   id: 'credit',
  //   name: '会员资源支付'
  // },
  {
    id: 'other',
    name: '其他'
  }
]

export const ORG_RENT_STATE = {
  entering: '即将入住',
  leaved: '离开',
  expiring: '即将到租'
}

export const ORG_RENT_STATE_CLASS = {
  entering: 'rent-entering',
  leaved: 'rent-leaved',
  expiring: 'rent-expiring'
}

export const CRM_NODE_TYPES = [
  {
    id: 'visit',
    name: '顾客上门'
  },

  {
    id: 'phone',
    name: '电话通讯'
  },
  {
    id: 'send_email',
    name: '发送邮件'
  },
  {
    id: 'wechat',
    name: '微信沟通'
  },
  {
    id: 'qq',
    name: 'QQ沟通'
  },
  {
    id: 'other',
    name: '其他'
  }
]

export const NODE_STATES = {
  wechat: '微信',
  phone: '电话',
  visit: '客户上门',
  send_email: '邮件',
  send_message: '短信',
  send_qq: 'QQ',
  other: '其他'
}

export const THEME_COLORS = [
  '#DA6068',
  '#70CCB0',
  '#FB6E52',
  '#e47622',
  '#F6D06A',
  '#AC92ED',
  '#A0D468',
  '#4FC0E8',
  '#EC88C0',
  '#66C5D5'
]

export const MEETING_COLORS = [
  '#ffcb08',
  '#80a4f1',
  '#87d57b',
  '#34464b',
  '#bc8781',
  '#da503a',
  '#734821',
  '#03b6bd',
  '#feb912',
  '#e76054'
]

export const ENGLISH_ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]

/**
 * 客户来源
 * @type {Array}
 */
export const OPPORTUNITIES_SOURCE = [
  {
    id: 'website',
    name: '网络'
  },
  {
    id: 'agency',
    name: '传统中介'
  },
  {
    id: 'sem',
    name: '百度'
  },
  {
    id: 'recommend',
    name: '推荐'
  },
  {
    id: 'society',
    name: '社交网络'
  },
  {
    id: 'crowdsourcing',
    name: '众包分销'
  },
  {
    id: 'visit',
    name: '来访'
  },
  {
    id: 'online_promotion',
    name: '线上推广'
  },
  {
    id: 'offline_promotion',
    name: '线下推广'
  },
  {
    id: 'online_agency',
    name: '线上中介'
  },
  {
    id: 'other',
    name: '其他'
  }
]

/**
 * 客户来源
 * @type {Array}
 */
export const OPPORTUNITIES_SOURCE_OBJ = {
  agency: '传统中介',
  website: '网络',
  sem: '百度',
  recommend: '推荐',
  society: '社交网络',
  crowdsourcing: '众包分销',
  visit: '来访',
  online_promotion: '线上推广',
  offline_promotion: '线下推广',
  online_agency: '线上中介',
  other: '其他'
}

/**
 * 空间设置数字显示
 * @type {Array}
 */
export const FINANCE_NUMBER_FORMAT = [
  {
    id: 'ceiling',
    name: '向上取整'
  },
  {
    id: 'floor',
    name: '向下取整'
  },
  {
    id: 'round',
    name: '四舍五入'
  },
  {
    id: 'origin',
    name: '不变'
  }
]

// 账单发送周期
export const SEND_INVOICE_REPORT = [
  {
    id: 'daily',
    name: '每天'
  },
  {
    id: 'weekly',
    name: '每周'
  },
  {
    id: 'monthly',
    name: '每月'
  },
  {
    id: 'false',
    name: '不发送'
  }
]

// 账单核销
export const INVOICE_CONFIRM = [
  {
    id: true,
    name: '需要确认核销'
  },
  {
    id: false,
    name: '不需要确认核销'
  }
]

// 账单核销
export const INVOICE_AUTO_CONFIRM = [
  {
    id: true,
    name: '自动核销和确认核销'
  },
  {
    id: false,
    name: '不自动核销和确认核销'
  }
]

// 入住率计算方式
export const ENTER_RATE_CALC_TYPE = [
  {
    id: 'by_desk',
    name: '按工位计算'
  },
  {
    id: 'by_area',
    name: '按面积计算'
  },
  {
    id: 'by_scale',
    name: '按比率计算'
  }
]

// 工位入驻租赁方式
export const AREA_RENTING_TYPE = [
  {
    id: 'by_desk',
    name: '按工位出租'
  },
  {
    id: 'by_area',
    name: '按面积出租'
  }
]

export const AREA_CONTRACT_ENTERING_WAO = [
  {
    id: 'by_desk',
    name: '按工位出租'
  },
  {
    id: 'by_area',
    name: '按面积出租'
  },
  {
    id: 'both',
    name: '两种都支持'
  }
]

// 押金合并
export const MERGE_DEPOSIT = [
  {
    id: false,
    name: '不合并押金到会员费账单'
  },
  {
    id: true,
    name: '合并押金到会员费账单'
  }
]

/**
 * 报修来源
 * @type {Array}
 */
export const TASK_SOURCE = [
  {
    id: 'space_member',
    name: '内部提交'
  },
  {
    id: 'organization_user',
    name: '用户提交'
  }
]

/**
 * 报修来源OBJ
 * @type {Object}
 */
export const TASK_SOURCE_OBJ = {
  space_member: '内部提交',
  organization_user: '用户提交'
}

/**
 * 报修类型
 * @type {Array}
 */
export const TASK_SUBTYPE = [
  {
    id: 'it',
    name: 'IT维修'
  },
  {
    id: 'engineering',
    name: '工程维修'
  },
  {
    id: 'office',
    name: '办公维护'
  },
  {
    id: 'other',
    name: '其他'
  }
]

// Faq 状态
export const FAQ_FILES_STATE = [
  {
    id: 'active',
    name: '激活'
  },
  {
    id: 'hidden',
    name: '隐藏'
  },
  {
    id: 'draft',
    name: '草稿'
  }
]

export const FAQ_FILES_STATE_OBJ = {
  active: '激活',
  hidden: '隐藏',
  draft: '草稿'
}

export const CONFIRM = [
  {
    name: '是',
    id: true
  },
  {
    name: '否',
    id: false
  }
]

export const SERVICE_REQUEST_STATE_ARRAY = []

// 服务订单状态对象
export const SERVICE_REQUEST_STATES = [
  {
    id: 'requested',
    name: '已提交'
  },
  {
    id: 'request_accepted',
    name: '已接单'
  },
  {
    id: 'completed',
    name: '已完成'
  },
  {
    id: 'canceled',
    name: '已取消'
  },
  {
    id: 'rejected',
    name: '已拒绝'
  }
]

export const SERVICE_REQUEST_STATE_OBJ = {
  requested: '已提交',
  request_accepted: '已接单',
  completed: '已完成',
  canceled: '已取消',
  rejected: '已拒绝'
}

export const SERVICE_REQUEST_STATE_CLASS = {
  requested: 'service-requested',
  request_accepted: 'service-request_accepted',
  completed: 'service-completed',
  canceled: 'service-canceled',
  rejected: 'service-rejected'
}

// 模块可见性设置
export const APP_MODULES_VISIBLE_TYPE = {
  normal: '全部会员可见',
  office: '办公楼会员可见'
}

// APP模块类型
export const APPMODULES_TYPE_OBJ = {
  h5: 'h5',
  native: '原生'
}

// APP模块类别
export const APPMODULES_CATEGORY_OBJ = {
  member: '会员应用',
  organization: '公司应用'
}

// APP模块类型Array
export const APPMODULES_TYPE = [
  {
    id: 'h5',
    name: 'html5'
  },
  {
    id: 'native',
    name: '原生'
  }
]

// APP模块类别 Array
export const APPMODULES_CATEGORY = [
  {
    id: 'member',
    name: '会员应用'
  },
  {
    id: 'organization',
    name: '公司应用'
  }
]

// APP模块可见性
export const APP_MODULES_VISIBLE_ARRAY = [
  {
    id: 'normal',
    name: '所有人可见'
  },
  {
    id: 'finance',
    name: '财务负责人可见'
  },
  {
    id: 'principal',
    name: '公司负责人可见'
  }
]

//门禁权限progress
export const LOCKS_PERMISSIONS_PROGRESS = {
  upcoming: '未开始',
  current: '拥有',
  ended: '结束'
}

//门禁权限状态class
export const LOCKS_PERMISSIONS_PROGRESS_CLASS = {
  current: 'receipt-new',
  upcoming: 'receipt-expiring',
  cancelled: 'receipt-completed',
  ended: 'receipt-overdue'
}

// 门禁开门方式
export const LOCK_ACTION_TYPE = {
  qrcode: '主动扫码',
  password: '密码',
  card: '刷卡',
  remote: '远程开门',
  auto: '自动开门',
  shake: '摇一摇蓝牙开门',
  manual: '手动开门',
  face: '刷脸',
  passive_qrcode: '被动扫码',
  bracelet: '手环开门'
}

// 统计方式（location，user）默认按照负责人统计
export const STATISTIC_MODE = [
  {
    id: 'location',
    name: '分店'
  },
  {
    id: 'user',
    name: '人员'
  }
]

export const SHOP_TYPE = [
  {
    id: 'online',
    name: '线上商城'
  },
  {
    id: 'offline',
    name: '线下商城'
  }
]

export const SHOP_STATUS = [
  {
    id: 'on_sale',
    name: '上架'
  },
  {
    id: 'off_sale',
    name: '下架'
  }
]

export const SHOP_OBJ_TYPE = {
  online: '线上商城',
  offline: '线下商城'
}
export const SHOP_OBJ_TYPES = {
  online: '线上',
  offline: '线下'
}
export const SPACE_ROLES = {
  admin: '分店管理 会员管理 空间设置',
  hr: '员工管理',
  crm: '客户管理',
  crm_admin: '客户管理-管理员',
  repair: '任务管理',
  finance: '财务管理',
  faq: '内容管理',
  app: '小程序显示管理',
  statistic: '数据统计',
  shop_manager: '商城管理',
  shop: '商城管理'
}

//报表链接状态
export const REPORT_LINK_STATE = {
  active: '已生成',
  failed: '生成失败',
  pending: '正在生成',
  uploading: '正在上传'
}

// 会员制资源类型
export const MEMBERSHIP_RESOURCE_TYPE = [
  {
    id: 'desk',
    name: '工位'
  },
  {
    id: 'booking',
    name: '会议室'
  },
  {
    id: 'printing',
    name: '打印'
  },
  {
    id: 'point',
    name: '虚拟货币'
  }
]

// CRM 库存
export const CRM_INVENTORY_EXPIRING_DATE = {
  oneMonth: 'one-month',
  twoMonth: 'two-month',
  threeMonth: 'three-month',
  moreMonth: 'more-month'
}

export const CRM_INVENTORY_EXPIRING_DATE_STRING = {
  oneMonth: '一个月',
  twoMonth: '两个月',
  threeMonth: '三个月',
  moreMonth: '三个月以上'
}

/**
 * 统计报表- 周期
 * @type {Array}
 */
export const STATISTIC_CYCLE = [
  {
    id: 'daily',
    name: '每天'
  },
  {
    id: 'weekly',
    name: '每周'
  },
  {
    id: 'monthly',
    name: '每月'
  },
  {
    id: 'yearly',
    name: '每年'
  }
]

/**
 * 统计报表- 周期 天 周 月
 * @type {Array}
 */
export const STATISTIC_CYCLE_DWM = [
  {
    id: 'daily',
    name: '每天'
  },
  {
    id: 'weekly',
    name: '每周'
  },
  {
    id: 'monthly',
    name: '每月'
  }
]

// 积分行为
export const POINT_EVENT = {
  unlock: '用户使用智能门禁开门',
  post_image: '用户发布带有图片的帖子',
  post_text: '用户发布帖子',
  vote_to_post: '用户为帖子点赞',
  reserve_meeting_room: '用户预定会议室',
  offer_feadback: '用户进行意见反馈',
  setup_avatar: '用户更新头像',
  setup_nickname: '用户更新昵称',
  setup_description: '用户更新介绍',
  setup_birthday: '用户更新生日',
  setup_gender: '用户更新性别'
}

/**
 * 获取会议室预定时间段
 * 默认9点到晚上的22点
 * @method MEETING_HM
 * @param  {[array]} [返回时间数组 {id : string, name : string}]
 */
export const MEETING_TIME_HM = ((start, end) => {
  var time = []
  var count = end - start
  var currentStart = start
  for (let i = 1; i <= count; i++) {
    let st = fullZero(currentStart)
    time.push({
      id: currentStart + ',' + '1',
      name: st + ':' + '00'
    })

    time.push({
      id: currentStart + ',' + '2',
      name: st + ':' + '30'
    })

    ++currentStart
  }

  time.push({
    id: end + ',' + '1',
    name: end + ':' + '00'
  })

  function fullZero(number) {
    if (number <= 9) {
      return '0' + number
    }

    return number
  }

  return time
})(9, 19)

export function AUTO_SWITCH_TIME() {
  const time = []
  for (let hour = 0; hour <= 23; hour++) {
    let st = fullZero(hour)
    for (let min = 0; min <= 50; min += 10) {
      time.push({
        id: min,
        name: st + ':' + min
      })
    }
  }

  function fullZero(number) {
    if (number <= 9) {
      return '0' + number
    }

    return number
  }
  return time
}

/**
 * 获取全天时间段
 * @method MEETING_HM
 * @param {start|Number}
 * @param {end|Number}
 * Tip: 参数小数位为5时代表半小时
 * @return  {[array]} [返回时间数组 {id : string, name : string}]
 */
export function DAY_TIME_HM(start = 0, end = 24) {
  let time = []
  const startIsInteger = Number.isInteger(start)
  const endIsInteger = Number.isInteger(end)
  let currentStart = startIsInteger ? start : Math.ceil(start)
  let currentEnd = endIsInteger ? end : Math.floor(end)
  const count = currentEnd - currentStart

  if (!startIsInteger) {
    time.push({
      id: currentStart - 1 + ',' + '2',
      name: fullZero(currentStart - 1) + ':' + '30'
    })
  }

  for (let i = 1; i <= count; i++) {
    let st = fullZero(currentStart)
    time.push({
      id: currentStart + ',' + '1',
      name: st + ':' + '00'
    })

    time.push({
      id: currentStart + ',' + '2',
      name: st + ':' + '30'
    })

    ++currentStart
  }

  time.push({
    id: currentEnd + ',' + '1',
    name: currentEnd === 24 ? 23 + ':' + '59' : currentEnd + ':' + '00'
  })

  if (!endIsInteger) {
    time.push({
      id: currentEnd + ',' + '2',
      name: fullZero(currentEnd) + ':' + '30'
    })
  }

  function fullZero(number) {
    if (number <= 9) {
      return '0' + number
    }

    return number
  }

  return time
}

// 全天时段（00 - 24）
export const DAY_TIME_HOUR = (count => {
  var time = []
  for (let i = 0; i <= count; i++) {
    let st = fullZero(i)
    time.push({
      id: i,
      name: st + ':' + '00'
    })
  }

  function fullZero(number) {
    if (number <= 9) {
      return '0' + number
    }

    return number
  }

  return time
})(23)

export const SUBSCRIPTION_STATE = [
  {
    id: 'enabled',
    name: '启用中'
  },
  {
    id: 'disabled',
    name: '禁用'
  },
  {
    id: 'draft',
    name: '草稿'
  }
]

export const SUBSCRIPTION_STATE_OBJ = {
  enabled: '启用中',
  disabled: '禁用',
  draft: '草稿'
}

export const SUBSCRIPTION_STATE_CLASSNAME = {
  enabled: 'status-btn status-btn_active',
  disabled: 'status-btn',
  draft: 'status-scratch-file'
}

// 公司员工技能
export const ORG_MEMBER_SKILLS = [
  { id: '数据分析', name: '数据分析' },
  { id: '项目管理', name: '项目管理' },
  { id: '产品运营', name: '产品运营' },
  { id: '产品设计', name: '产品设计' },
  { id: 'UI/UX', name: 'UI/UX' },
  { id: '移动开发', name: '移动开发' },
  { id: '网站开发', name: '网站开发' },
  { id: '后台开发', name: '后台开发' },
  { id: 'IT支持', name: 'IT支持' },
  { id: '市场营销', name: '市场营销' },
  { id: '创意策划', name: '创意策划' },
  { id: '行政管理', name: '行政管理' },
  { id: '财务会计', name: '财务会计' },
  { id: '人力资源', name: '人力资源' },
  { id: '广告', name: '广告' },
  { id: '金融', name: '金融' },
  { id: '法律', name: '法律' },
  { id: '采购贸易', name: '采购贸易' },
  { id: '商务谈判', name: '商务谈判' },
  { id: '播音主持', name: '播音主持' },
  { id: '新媒体运营', name: '新媒体运营' },
  { id: '摄影摄像', name: '摄影摄像' },
  { id: '心理咨询', name: '心理咨询' }
]

// 积分明细的客户类型 公司 / 个人
export const POINT_PLAN_CUSTOMER_TYPE = [
  {
    id: 'org',
    name: '公司'
  },
  {
    id: 'user',
    name: '个人'
  }
]

// 自定义动态字段类型
export const DYNAMIC_ATTRIBUTES_TYPE = [
  {
    id: 'string',
    name: '文本'
  },
  {
    id: 'date',
    name: '日期'
  },
  {
    id: 'datetime',
    name: '日期时间'
  },
  {
    id: 'integer',
    name: '整数'
  },
  {
    id: 'float',
    name: '实数'
  },
  {
    id: 'boolean',
    name: '布尔'
  }
]

// 新建公司时负责人的证件类型
export const CONTACTS_IDCARD_TYPE = [
  {
    id: 'idcard',
    name: '身份证'
  },
  {
    id: 'passport',
    name: '护照'
  }
]

export const VISIT_REASON = [
  {
    id: '',
    name: '全部'
  },
  {
    id: '参观',
    name: '参观'
  },
  {
    id: '会议',
    name: '会议'
  },
  {
    id: '面试',
    name: '面试'
  },
  {
    id: '活动',
    name: '活动'
  },
  {
    id: '其他',
    name: '其他'
  }
]

export const VISIT_REASON_OBJ = {
  参观: '参观',
  会议: '会议',
  面试: '面试',
  活动: '活动',
  其他: '其他'
}

// 发票状态
export const INVOICE_APPLICATION_STATE = [
  {
    id: 'pending',
    name: '申请开票'
  },
  {
    id: 'process',
    name: '开票中'
  },
  {
    id: 'issued',
    name: '开票完成'
  },
  {
    id: 'aborted',
    name: '已作废'
  }
]

export const INVOICE_APPLICATION_STATE_OBJ = {
  pending: '申请开票',
  process: '开票中',
  issued: '开票完成',
  aborted: '已作废'
}

export const POINT_RECHARGE_TABS = [
  //   {
  //     id: 'recharge',
  //     name: '充值'
  //   },
  {
    id: 'giving',
    name: '赠送'
  },
  {
    id: 'revoke',
    name: '扣除'
  }
]

export const ACTIVITY_STATUS_OBJ = {
  active: '已发布',
  cancelled: '已取消',
  draft: '草稿'
}

export const ACTIVITY_STATUS_TAG_OBJ = {
  upcoming: '即将开始',
  in_progress: '进行中',
  future: '已发布',
  ended: '已结束'
}

export const RENTING_PHASES_RECURRING_DATE_COMPUTE_DAY = [
  {
    id: 'fixed',
    name: '交租阶段开始日的前一个月的几号'
  },
  {
    id: 'advance',
    name: '交租阶段开始日的前几天'
  }
]

export const NEWS_STATUS = [
  {
    id: 'published',
    name: '发布'
  },
  {
    id: 'deleted',
    name: '移除'
  }
]

export const NEWS_STATUS_OBJ = {
  published: '发布',
  deleted: '草稿',
  draft: '草稿'
}

// 打印机状态
export const PRINTER_STATUS = [
  {
    id: 'free',
    name: '未使用'
  },
  {
    id: 'active',
    name: '工作中'
  },
  {
    id: 'broken',
    name: '已损坏'
  }
]

export const PRINTER_STATUS_OBJ = {
  free: '未使用',
  active: '工作中',
  broken: '已损坏'
}

export const POINT_TYPES = [
  {
    title: `尊享`,
    value: 'fwpsw'
  },
  {
    title: `高级`,
    value: 'fwpsin'
  },
  {
    title: `灵活`,
    value: 'fldesk'
  },
  {
    title: `活动`,
    value: 'fwpevt'
  }
]

export const LINE_ITEM_TYPES = [
  {
    id: 'late_fee',
    name: '滞纳金'
  },
  {
    id: 'house_fee',
    name: '物业费'
  },
  {
    id: 'renting_fee',
    name: '会员费'
  },
  {
    id: 'deposit_fee',
    name: '押金'
  },
  {
    id: 'water_fee',
    name: '水费'
  },
  {
    id: 'electric_fee',
    name: '电费'
  },
  {
    id: 'network_fee',
    name: '网络费'
  },
  {
    id: 'express_fee',
    name: '快递费'
  },
  {
    id: 'damaged_fee',
    name: '物品损坏费'
  },
  {
    id: 'meeting_room_fee',
    name: '会议室'
  },
  {
    id: 'register_fee',
    name: '注册'
  },
  {
    id: 'exit_fee',
    name: '退场'
  },
  {
    id: 'park_fee',
    name: '停车'
  },
  {
    id: 'deposit_fee_revoke',
    name: '保证金没收'
  },
  {
    id: 'gz_monthly_rent',
    name: '租金（广州）'
  },
  {
    id: 'gz_non_monthly_rent',
    name: '租金（非月度期间）（广州）'
  },
  {
    id: 'gz_monthly_service_fee',
    name: '服务费（广州）'
  },
  {
    id: 'gz_non_monthly_service_fee',
    name: '服务费（非月度期间）（广州）'
  },
  {
    id: 'gz_water_fee',
    name: '纯净水（广州）'
  },
  {
    id: 'gz_monthly_rent_fac_fee',
    name: '实验室设备租赁（广州）'
  },
  {
    id: 'gz_non_monthly_rent_fac_fee',
    name: '实验室设备租赁（非月度期间）（广州）'
  },
  {
    id: 'other',
    name: '其它'
  }
]

export const LINE_ITEM_TYPES_OBJ = {
  late_fee: '滞纳金',
  house_fee: '物业费',
  renting_fee: '会员费',
  deposit_fee: '押金',
  water_fee: '水费',
  electric_fee: '电费',
  network_fee: '网络费',
  express_fee: '快递费',
  damaged_fee: '物品损坏费',
  other: '其它',
  self_defined: '自定义'
}

export const SUPPOER_LOCALES = [
  {
    name: 'English',
    value: 'en-US'
  },
  {
    name: '简体中文',
    value: 'zh-CN'
  }
]

export const localeIndex = 1

export const DEFAULT_VISITOR_TYPE = {
  _visitor: '来访会员',
  _tour: '参观社区'
}

export const VISIT_AUDIT_STATES = [
  {
    id: 'pending_approved',
    name: '未审核'
  },
  {
    id: 'approved',
    name: '已通过'
  },
  {
    id: 'disapproved',
    name: '已拒绝'
  }
]

export const VISIT_AUDIT_STATES_OBJ = {
  pending_approved: '未审核',
  approved: '已通过',
  disapproved: '已拒绝'
}

export const VISIT_STATUS_ARRAY = [
  {
    id: 'accepted',
    name: '已同意'
  },
  {
    id: 'rejected',
    name: '已拒绝'
  }
]

export const VISIT_STATUS_OBJ = {
  accepted: '已同意',
  rejected: '已拒绝',
  all: '全部',
  ended: '已结束',
  waiting_for_acceptance: '待同意',
  cancelled: '已取消'
}

export const VISIT_STATUS_COLOR = {
  accepted: '#0AC862',
  rejected: '#FF0000',
  ended: '#666'
}

export const POST_CATEGORY_OBJ = {
  normal: '普通帖',
  demand: '需求帖'
}

export const USER_TYPE = [
  {
    name: '入驻会员',
    value: 'entered'
  },
  {
    name: '会员制会员',
    value: 'membership'
  },
  {
    name: '非会员',
    value: 'visitor'
  }
]

export const USER_TYPE_OBJ = {
  entered: '入驻会员',
  membership: '会员制会员',
  visitor: '非会员'
}

export const LOCATION_TYPES = [
  {
    id: 'union_office',
    name: '联合办公'
  },
  {
    id: 'shopping_mall',
    name: '商场'
  },
  {
    id: 'self_office',
    name: '自用写字楼'
  },
  {
    id: 'sales_office',
    name: '销售写字楼'
  },
  {
    id: 'sales_apartment',
    name: '销售公寓'
  },
  {
    id: 'serviced_apartment',
    name: '服务公寓'
  },
  {
    id: 'hotel',
    name: '酒店'
  },
  {
    id: 'garage',
    name: '车库'
  }
]

export const PARKING_SPACE_TYPE = [
  {
    id: 'flat_plate',
    name: '平面车位'
  },
  {
    id: 'cube_plate',
    name: '立体车位'
  }
]

export const PARKING_SPACE_TYPE_OBJ = {
  flat_plate: '平面车位',
  cube_plate: '立体车位'
}

export const PARKING_STATES = [
  {
    id: 'free',
    name: '可出租'
  },
  // {
  // 	id   : 'reserved',
  // 	name : '已预订'
  // },
  {
    id: 'approving',
    name: '审批中'
  },
  {
    id: 'assigned',
    name: '已出租'
  },
  // {
  // 	id   : 'expiring',
  // 	name : '快到期'
  // },
  {
    id: 'disabled',
    name: '已锁定'
  }
]

export const PARKING_STATES_OBJ = {
  free: '可出租',
  approving: '审批中',
  assigned: '已出租',
  expiring: '快到期',
  reserved: '已预订',
  disabled: '已锁定'
}

export const PARKING_CONTRACT_RENTER_TYPES = [
  {
    id: 'simple',
    name: '写字楼租户'
  },
  {
    id: 'outer_user',
    name: '个人'
  },
  {
    id: 'inner_user',
    name: '凯德员工'
  }
]

export const PARKING_CONTRACT_RENTER_TYPES_OBJ = {
  normal: '写字楼租户',
  simple: '写字楼租户',
  outer_user: '个人',
  inner_user: '凯德员工'
}

export const PARKING_RENT_TYPE = [
  {
    id: '月租',
    name: '月租'
  },
  {
    id: '时租',
    name: '时租'
  }
]

export const PARKING_RENT_TYPE_OBJ = {
  月租: '月租',
  时租: '时租'
}

export const CAR_PLATES_SAVED = [
  {
    id: true,
    name: '已收录'
  },
  {
    id: false,
    name: '未收录'
  }
]

export const CAR_PLATES_SAVED_OBJ = {
  true: '已收录',
  false: '未收录'
}

export const INCOME_CONFIRM_STATE = [
  {
    id: true,
    name: '已入账'
  },
  {
    id: false,
    name: '未入账'
  }
]

export const INCOME_CONFIRM_STATE_OBJ = {
  true: '已入账',
  false: '未入账'
}

export const SUBSCRIPTION_TYPES = [
  {
    id: 'normal',
    name: '工位合同'
  },
  {
    id:'no_sales_area',
    name:'无面积租赁合同'
  }
  // {
  // 	id: 'parking',
  // 	name: '车位合同'
  // }
]

export const SUBSCRIPTION_TYPES_OBJ = {
  normal: '工位合同',
  parking: '车位合同',
  no_sales_area:'无面积租赁合同'
}

export const ORG_ENTER_TYPES_OBJ = {
  shortterm: '联办公司',
  longterm: '写字楼公司'
}

export const PAYMENT_SETTING = {
  wechat: '微信支付',
  alipay: '支付宝支付',
  point: '优惠券支付',
  invoice: '账单支付',
  third_point: '星积分支付',
  prepay_card: '★凯星卡'
}
export const PAYMENT_TYPE_NEW_OBJ = {
  reservation: '场地支付',
  // desk_reservation: '工位支付',
  // membership: '会员支付',
  // membership_resource: '会员资源支付',
  // point_plan: '积分支付',
  // print_reservation: '打印支付',
  // product_reservation: '商城支付',
  // activity: '活动支付',
  cabinet_reservation: '储物柜支付'
}

export const CUSTOM_TIME_ARRAY = [
  { name: '00:00', value: '00:00' },
  { name: '01:00', value: '01:00' },
  { name: '02:00', value: '02:00' },
  { name: '03:00', value: '03:00' },
  { name: '04:00', value: '04:00' },
  { name: '05:00', value: '05:00' },
  { name: '06:00', value: '06:00' },
  { name: '07:00', value: '07:00' },
  { name: '08:00', value: '08:00' },
  { name: '09:00', value: '09:00' },
  { name: '10:00', value: '10:00' },
  { name: '11:00', value: '11:00' },
  { name: '12:00', value: '12:00' },
  { name: '13:00', value: '13:00' },
  { name: '14:00', value: '14:00' },
  { name: '15:00', value: '15:00' },
  { name: '16:00', value: '16:00' },
  { name: '17:00', value: '17:00' },
  { name: '18:00', value: '18:00' },
  { name: '19:00', value: '19:00' },
  { name: '20:00', value: '20:00' },
  { name: '21:00', value: '21:00' },
  { name: '22:00', value: '22:00' },
  { name: '23:00', value: '23:00' },
  { name: '23:59', value: '23:59' }
]

export const ANNOUNCEMENTS_TYPE = {
  announcement: '广告',
  station_announcement: '公告',
}

export const ACTIVITY_JOIN_ROLE = [
  {
    title:'所有人',
    value:'all_user'
  },
  {
    title:'办公楼会员专享',
    value:'tenant'
  },
  {
    title:'奕桥会员专享',
    value:'bridge'
  }
]
export const ACTIVITY_TICKET_MTC = [
  {
    title:'星积分',
    value:'point'
  },
  {
    title:'免费',
    value:'free'
  }
]
