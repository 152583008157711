import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'
import Row from './Row'
import _ from 'lodash'

/**
 * 原内容区域
 * @description
 * 这里控制内容其余的显隐和选择
 */
var Source = React.createClass({
  displayName: 'Source',
  getInitialState() {
    const { data } = this.props
    const { mountData } = this.context
    const newData = mountData(data, false)

    return {
      data: newData,
      searchValue: '',
      headerChecked: false
    }
  },

  // 更新替代
  componentWillReceiveProps(nextProps) {
    const { data } = nextProps
    const { multi, filterNoChildrensData } = this.context
    const { searchValue } = this.state
    const oldData = this.props.data
    if (!multi && data.length != oldData.length) {
      this.setState({
        data: searchValue ? this.filterDataOfValue(data, searchValue) : data,
        headerChecked: false
      })
    }

    if (multi && !_.isEqual(data, oldData)) {
      var newData = filterNoChildrensData(data)
      this.setState({
        data: searchValue
          ? this.filterMultiDataOfValue(newData, searchValue)
          : newData,
        headerChecked: false
      })
    }
  },
  contextTypes: {
    setEditState: PropTypes.func,
    multi: PropTypes.bool,
    isChildrenLoad: PropTypes.bool,
    isHead: PropTypes.bool,
    isSearch: PropTypes.bool,
    mountData: PropTypes.func,
    filterNoChildrensData: PropTypes.func
  },
  childContextTypes: {
    setRowDataOfIndex: PropTypes.func,
    setMultiParentRowData: PropTypes.func,
    setMultiChildRowData: PropTypes.func,
    filterNoChildrensData: PropTypes.func,
    type: PropTypes.string
  },
  getChildContext() {
    return {
      setRowDataOfIndex: this.setRowDataOfIndex,
      setMultiParentRowData: this.setMultiParentRowData,
      setMultiChildRowData: this.setMultiChildRowData,
      filterNoChildrensData: this.filterNoChildrensData,
      type: this.props.type
    }
  },
  // 更新父组件的数据（多数据情况）并且为子级
  setMultiChildRowData(rowData, index) {
    const newData = this.state.data
    newData[index] = rowData

    const isChecked = newData.find(json => {
      if (json.Tchecked) {
        return true
      }
      return json.childrens && json.childrens.find(json => json.Tchecked)
    })

    this.updateRowData(isChecked, newData)
  },
  // 更新父组件的数据（多数据情况）并且为父级
  setMultiParentRowData(rowData, index) {
    const newData = this.state.data

    newData[index] = rowData
    const isChecked = newData.find(json => json.Tchecked)
    this.updateRowData(isChecked, newData)
  },
  // 像子组件提供选择按钮之后的操作（更新数据）
  setRowDataOfIndex(rowData, index) {
    const newData = this.state.data
    newData[index] = rowData

    var filterData = newData.filter(json => json.Tchecked)
    this.updateRowData(filterData.length, newData)
  },
  // 更新数据，并通知上级改变当前编辑状态
  updateRowData(isChecked, newData) {
    const { setEditState } = this.context
    const { type } = this.props
    if (isChecked) {
      setEditState(type, true)
    } else {
      setEditState(type, false)
    }

    this.setState({
      data: newData
    })
  },
  // 上面的全选框，全选或者全部取消
  checkedAll(e) {
    const checked = e.target.checked
    const { setEditState, mountData } = this.context
    const { type } = this.props
    const { data } = this.state
    var newData = mountData(data, checked)

    if (checked && newData.length > 0) {
      setEditState(type, true)
    } else if (!checked && newData.length > 0) {
      setEditState(type, false)
    }
    this.setState({
      data: newData,
      headerChecked: checked
    })
  },
  // 向父级返回数据
  getData() {
    const { searchValue, data } = this.state
    const { multi } = this.context

    if (!searchValue) {
      return this.props.data
    }

    var newData = _.cloneDeep(this.props.data)

    if (!multi) {
      return this.props.data.map(json => {
        if (
          !this.isFilterNameOfValue(json.name, searchValue) &&
          json.Tchecked
        ) {
          json.Tchecked = false
          return json
        }
        return json
      })
    }

    newData = this.mergeData(newData, data, searchValue, true)

    return newData
  },
  // 手动merge数据，并且做过滤处理
  mergeData(data, currentData, searchValue, isCheckFilter = false) {
    return data.map((json, index) => {
      var findSomeData = currentData.find(t => t.id == json.id) || json
      if (findSomeData) {
        json.Tchecked = findSomeData.Tchecked
        json.childrens = json.childrens.map(t => {
          var d = findSomeData.childrens.find(z => z.id == t.id) || t
          if (
            isCheckFilter &&
            !this.isFilterNameOfValue(d.name, searchValue) &&
            d.Tchecked
          ) {
            d.Tchecked = false
          }
          return d
        })
      }
      return json
    })
  },
  // 按照用户的输入，过滤单个数据
  filterDataOfValue(data, value) {
    return data.filter(json => this.isFilterNameOfValue(json.name, value))
  },
  // 按照用户的输入，过滤多选数据
  filterMultiDataOfValue(data, value) {
    var newData = _.cloneDeep(data)

    newData = newData.map(json => {
      json.childrens =
        json.childrens && this.filterDataOfValue(json.childrens, value)
      return json
    })

    newData = newData.filter(json => {
      // 判断父级名称是否满足过滤条件
      const isFilter = this.isFilterNameOfValue(json.name, value)
      if ((json.childrens && json.childrens.length) || isFilter) {
        return true
      }

      return false
    })
    return newData
  },
  // 根据value过滤name
  isFilterNameOfValue(name, value) {
    return name.indexOf(value) != -1
  },
  // 按照用户输入过滤数据，判断多选还是单选的情况
  searchData(e) {
    const { multi } = this.context
    var value = e.target.value

    if (multi) {
      return this.searchMultiData(value)
    }
    const { data } = this.props
    const newData = this.filterDataOfValue(data, value)
    this.setState({
      searchValue: value,
      data: newData
    })
  },
  // 搜索多选数据的情况
  searchMultiData(value) {
    const { data } = this.props
    const newData = this.filterMultiDataOfValue(data, value)
    this.setState({
      searchValue: value,
      data: newData
    })
  },
  // 清空数据操作
  clearSearch() {
    const { data } = this.props
    const { multi, filterNoChildrensData } = this.context
    const currentData = this.state.data
    var newData = data

    if (multi) {
      newData = filterNoChildrensData(data)
      newData = this.mergeData(newData, currentData)
    }

    this.setState({
      searchValue: '',
      data: newData
    })
  },
  // 搜索框渲染
  renderSearch() {
    const { searchValue } = this.state
    const { isSearch } = this.context
    if (!isSearch) {
      return ''
    }
    return (
      <div className="add-staff-component">
        <input type="text" onChange={this.searchData} value={searchValue} />
        <span className="clear-search-btn" onClick={this.clearSearch}>
          x
        </span>
      </div>
    )
  },
  // 获取选中的数据
  getCheckItem(data) {
    return data.filter(json => json.Tchecked)
  },
  // 获取多选时的多选数据和它的总数
  getCheckMultiData(data) {
    var items = []
    var dataCount = 0
    data.forEach(json => {
      dataCount = dataCount + ((json.childrens && json.childrens.length) || 0)
      json.childrens &&
        json.childrens.forEach(row => {
          if (row.Tchecked) {
            items.push(row)
          }
        })
    })
    return {
      dataCount,
      items
    }
  },
  // 获取当前数据中总数据和选中的数据的个数
  getItemCount() {
    const { multi } = this.context
    const { data } = this.state
    var count = 0
    var current = 0

    if (multi) {
      const { dataCount, items } = this.getCheckMultiData(data)
      count = dataCount
      current = items.length
    } else {
      count = data.length
      current = this.getCheckItem(data).length
    }
    return {
      count,
      current
    }
  },
  // 显示当前Source选择情况 1/2
  renderCount() {
    const { type } = this.props
    const { count, current } = this.getItemCount()
    if (type === 'source' && !count) {
      return <span></span>
    }

    if (!current) {
      return <span className="show-count">({count})</span>
    }
    return (
      <span className="show-count">
        ({current}/{count})
      </span>
    )
  },
  renderHeader() {
    const { headerChecked } = this.state
    const { isHead } = this.context
    const { title } = this.props

    if (!isHead) {
      return ''
    }

    return (
      <div className="kb-transfer-check_all">
        {/*<input type='checkbox' checked={ headerChecked } onChange={ this.checkedAll } />*/}
        <span className="title">{title ? title : 'Source'}</span>
        {this.renderCount()}
      </div>
    )
  },
  render() {
    const { data, searchValue } = this.state
    const { className, isEdit, title } = this.props
    const dataSource = this.props.data
    const { isGroupAdd } = this.props

    return (
      <div className="kb-transfer-container">
        {this.renderHeader()}
        <div className={className}>
          {this.renderSearch()}
          <div className="transfer-content">
            {data.map((row, index) => {
              return (
                <Row
                  rowData={row}
                  index={index}
                  boxClass="first-tier"
                  labelClass="parent-label"
                  setRowDataOfIndex={this.setRowDataOfIndex}
                  isGroupAdd={isGroupAdd}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  }
})

Source.propTypes = {
  // 当前source组件数据
  data: PropTypes.array,
  // 当前source 的type 用于标识
  type: PropTypes.string,
  // 表示是否处于编辑状态
  isEdit: PropTypes.bool
}

export default Source
