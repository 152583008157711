import { normalize } from 'normalizr'

var request = require('superagent-bluebird-promise')
const __DEV__ = process.env.NODE_ENV == 'development'
const EMPTY_USER = {
  id: null,
  name: null,
  avatar: null,
  email: null,
  phone_num: null,
  jwt_token: null
}

export var GLOBAL_USER = EMPTY_USER

// save user to storage.null user means to delete saved user.
export function setCurrentUser(user, remember) {
  GLOBAL_USER = user

  if (!(user && user.jwt_token)) {
    localStorage && localStorage.removeItem('current_user')
    sessionStorage && sessionStorage.removeItem('current_user')
    return
  }

  if (!(remember && remember.remember)) {
    sessionStorage &&
      sessionStorage.setItem('current_user', JSON.stringify(user))
    return
  }

  localStorage && localStorage.setItem('current_user', JSON.stringify(user))
}

// save user to storage.null user means to delete saved user.
export function removeCurrentUser() {
  localStorage && localStorage.removeItem('current_user')
  sessionStorage && sessionStorage.removeItem('current_user')
}

// temp useed in native app code.
export function saveUserInfo(user) {
  GLOBAL_USER = user
}

export function getCurrentUser() {
  return GLOBAL_USER
}

function _success(response, schema) {
  // Do we need this for superagent?
  // https://github.com/visionmedia/superagent/issues/551
  if (response.noContent) {
    return { status: true, response }
  }
  var jsonBody = response.body
  if (__DEV__) {
  }

  let json = schema ? normalize(jsonBody, schema) : jsonBody
  return { json, response }
}

export function _error(error, schema) {
  if (__DEV__) {
    console.log(`[ERROR]: ${JSON.stringify(error)}`)
  }

  let getType = Object.prototype.toString
  let errorData = null
  if (error.originalError) {
    errorData = eval('(' + error.originalError.rawResponse + ')')
  }
  let statusCode = error.originalError && error.originalError.statusCode
  let errorObj = { code: error.status, message: error.message, ...error.body }
  let json = errorData && (schema ? normalize(errorData, schema) : errorData)
  let typeString = getType.call(json)
  errorObj._error = errorObj._error || {}
  errorObj._error.message = error.body && error.body.message

  if (
    statusCode === 200 &&
    json &&
    (typeString === '[object Array]' || typeString === '[object Object]')
  ) {
    return { json }
  }
  return Promise.reject(errorObj)
}

export function _apiGET(rootURL, pathURL, params, schema) {
  let url = `${rootURL}${pathURL}`
  if (__DEV__) {
    console.log(`[GET]:${url}, ${JSON.stringify(params)}`)
  }
  return request
    .get(url)
    .query(params)
    .set(_tokenHeader())
    .then(
      response => _success(response, schema),
      error => _error(error, schema)
    )
}

/*
 * Helper functions to call POST API
 */
export function _apiPOST(rootURL, pathURL, params = {}, schema) {
  let url = `${rootURL}${pathURL}`
  if (__DEV__) {
    console.log(`[POST]: ${url} ${JSON.stringify(params)}`)
  }
  return request
    .post(url)
    .set(_tokenHeader())
    .send(params)
    .then(
      response => _success(response, schema),
      error => _error(error, schema)
    )
}

/*
 * Helper functions to call PUT API
 */
export function _apiPUT(rootURL, pathURL, params = {}, schema) {
  let url = `${rootURL}${pathURL}`
  if (__DEV__) {
    console.log(`[PUT]: ${url} ${JSON.stringify(params)}`)
  }

  return request
    .put(url)
    .set(_tokenHeader())
    .send(params)
    .then(
      response => _success(response, schema),
      error => _error(error, schema)
    )
}

/*
 * Helper functions to call PUT API
 */
export function _apiDELETE(rootURL, pathURL, params = {}, schema) {
  let url = `${rootURL}${pathURL}`

  if (__DEV__) {
    console.log(`[DELETE]:${url} ${JSON.stringify(params)}`)
  }

  return request
    .del(url)
    .set(_tokenHeader())
    .send(params)
    .then(
      response => _success(response, schema),
      error => _error(error, schema)
    )
}

//高德地图API 解析地址
export const _apiGetTrapezeOfGaode = (city = '北京', address = '北京市') => {
  return request
    .get(
      `https://restapi.amap.com/v3/geocode/geo?${
        city ? 'city=' + city : ''
      }&address=${address}&output=JSON&key=0974ffa7440cd3b5fb8b0c1ac782a957`
    )
    .then(
      response => _success(response),
      error => _error(error)
    )
}

export function _apiUploadFile(rootURL, pathURL, params = {}, schema) {
  return new Promise((resolve, reject) => {
    let url = `${rootURL}${pathURL}`;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    const headerObj = _tokenHeader()
    delete headerObj['Content-Type']
    for (const key in headerObj) {
      xhr.setRequestHeader(key, headerObj[key])
    }
    xhr.send(params);
    xhr.onreadystatechange = () => {
      if (xhr.readyState===4) {
        if(xhr.status==200) {
          resolve(JSON.parse(xhr.response))
        } else {
          reject(xhr)
        }
      }
    }
  })
}

function _tokenHeader() {
  let tokenHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  if (GLOBAL_USER && GLOBAL_USER.jwt_token) {
    tokenHeader['Authorization'] = 'Bearer ' + GLOBAL_USER.jwt_token
  }
  return tokenHeader
}
