import React, { PropTypes } from 'react'
import { findDOMNode } from 'react-dom'
import { KBAvatar, KBTipsy } from 'components'

/**
 * 搜索框
 * 显示单行 Row
 */
var Row = React.createClass({
  render() {
    const {
      rowData,
      changeSelect,
      showImg,
      options,
      multi,
      isFlag
    } = this.props

    return (
      <li
        className="clear-fix"
        onClick={changeSelect.bind(null, rowData, isFlag)}
      >
        {showImg ? (
          <div className="option-img f-left">
            <KBAvatar
              user={rowData}
              size={30}
              style={{ width: '30px', height: '30px', lineHeight: '33px' }}
            />
          </div>
        ) : (
          ''
        )}
        <KBTipsy content={rowData[options ? options.value : 'name']}>
          <span
            className="option-text t-over f-left"
            style={{ maxWidth: 110, display: 'inline-block', marginTop: 6 }}
          >
            {rowData[options ? options.value : 'name']}
          </span>
        </KBTipsy>
        {isFlag ? (
          <i className="iconfont icon-right f-right" style={{ marginTop: 8 }} />
        ) : (
          ''
        )}
      </li>
    )
  }
})

Row.PropTypes = {
  // rowData 数据
  rowData: PropTypes.object.isRequired,
  /**
   * 点击单行回调
   */
  changeSelect: PropTypes.func,
  /**
   * 自定义的操作
   * 这里主要用的他的 option.value 显示的名称
   */
  options: PropTypes.object,
  /**
   * 是否显示图片
   */
  showImg: PropTypes.bool,
  // 是否多选
  multi: PropTypes.bool,
  // 是否被选中
  isFlag: PropTypes.bool
}

export default Row
