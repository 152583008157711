import React, { Component, PropTypes } from 'react'
import ReactDOM from 'react-dom'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { KBInput, KBFormError, KBButton, CountDownButton } from 'components'
import classNames from 'classnames'
import { currentUserActions, successState } from 'app/actions'

var Register = React.createClass({
  register(model) {
    const { loginAction, routerActions, redirect, successAction } = this.props

    return api.registerUser(model).then(({ json }) => {
      // dispatch success globally
      successAction({ message: '注册成功!' })
      let loggedInUser = json.entities.users[json.result]
      apiUtils.setCurrentUser(loggedInUser)
      loginAction.success(loggedInUser)
    })
  },
  sendSmsCode() {
    let phone = ReactDOM.findDOMNode(this.refs.phone).value
    setTimeout(
      () =>
        api
          .sendRegistrationSmsCode({ phone_num: phone })
          .then(json => {
            this.refs.countDownButton._stopTimer()
            if (process.env.NODE_ENV == 'development') {
              alert(json.json.sms_code)
            }
          })
          .catch(err => {
            this.refs.countDownButton._stopTimer()
          }),
      3000
    )
    return true
  },
  render() {
    const {
      fields: { email, password, name, phone_num, register_sms_code },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <div className="lr-out-box">
        <div className="comment-box1">
          <div className="kb-box box1-header">
            <span className="lr-text">注册</span>
          </div>
          <form onSubmit={handleSubmit(this.register)}>
            <KBFormError error={this.props.error} />
            <KBInput field={name} placeholder="姓名" />
            <KBInput field={email} placeholder="Email地址" />
            <KBInput type="password" field={password} placeholder="输入密码" />
            <div
              className={classNames(
                'kb-box',
                phone_num.touched && phone_num.error
                  ? 'kb-input-error'
                  : 'kb-input'
              )}
            >
              <input
                type="text"
                ref="phone"
                placeholder="手机号码"
                {...phone_num}
              />
              {phone_num.touched && phone_num.error && (
                <p className="lr-error">{phone_num.error}</p>
              )}
            </div>
            <div
              className={classNames(
                'kb-box',
                register_sms_code.touched && register_sms_code.error
                  ? 'kb-input-error'
                  : 'kb-input'
              )}
            >
              <div className="f-left" style={{ width: '60%' }}>
                <input
                  className="verification-code"
                  type="text"
                  ref="code"
                  placeholder="验证码"
                  style={{ borderRight: 'none' }}
                  {...register_sms_code}
                />
                {register_sms_code.touched && register_sms_code.error && (
                  <p className="lr-error">{register_sms_code.error}</p>
                )}
              </div>
              <CountDownButton
                className="send-code f-left"
                ref="countDownButton"
                onClick={this.sendSmsCode}
              >
                发送验证码
              </CountDownButton>
            </div>
            <div className="kb-box lr-certain">
              <KBButton type="submit" submitting={submitting}>
                {'注册'}
              </KBButton>
            </div>
            <p className="common-font-1" style={{ textAlign: 'center' }}>
              注册表明您同意我们的&nbsp;[&nbsp;用户条款&nbsp;]&nbsp;
            </p>
            <div className="kb-box register-exist-account">
              <Link to="/login">已有账号?</Link>
            </div>
          </form>
        </div>
      </div>
    )
  }
})

// Map state to props. State comes from Redux State Store
// Redirect handles URL location change.
function mapStateToProps(state, ownProps) {
  return {}
}

// Map action creators dispatch to props
// So we can call this.props.actions.<actionName>(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    loginAction: bindActionCreators(currentUserActions.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.name)) {
    errors.name = '请输入您的姓名'
  }

  if (valid.required(values.email)) {
    errors.email = '请输入您的注册mail'
  }

  if (valid.email(values.email)) {
    errors.email = '请输入正确的Email地址'
  }

  if (valid.minLength(6)(values.password) || valid.required(values.password)) {
    errors.password = '密码必须在6-20字符之间'
  }

  if (valid.required(values.phone_num) || valid.mobile(values.phone_num)) {
    errors.phone_num = '请输入正确的手机号码'
  }

  if (valid.required(values.register_sms_code)) {
    errors.register_sms_code = '请输入验证码'
  }
  return errors
}

const formConfig = {
  form: 'Register',
  fields: ['name', 'email', 'password', 'phone_num', 'register_sms_code'],
  touchOnBlur: false,
  validate: validate
}

// connect maps to components
export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(Register)
