import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { api, apiUtils } from 'app/services'
import { customerRecordCommentsActions } from 'app/actions'
import classNames from 'classnames'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { KBInput, KBButton, KBAvatar } from 'components'
import * as valid from 'utils/validate'
import { connect } from 'react-redux'
import CrmRecordListContent from './CrmRecordListContent'
import moment from 'moment'
import _ from 'lodash'

var CrmRecordCommentList = React.createClass({
  getInitialState() {
    return {
      loading: false,
      comments: this.props.record && this.props.record.comments,
      text: '',
      disposeState: false,
      isShowBtn: false
    }
  },

  componentDidMount() {
    const { comments } = this.state
    this.mounted = true
    let array = _.slice(comments, 0, comments.length)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.record.id !== this.props.record.id) {
      this._loadData(nextProps.record.id, nextProps.opportunity_id)
    }
  },

  _loadData(record_id, opp_id) {
    let { getRecordCommentsAction } = this.props
    this.setState({ loading: true })

    api
      .getCrmComments(opp_id, record_id)
      .then(({ json, response }) => {
        getRecordCommentsAction.success(json)
        let comments =
          json.entities.crmComments && Object.values(json.entities.crmComments)
        if (this.mounted) {
          this.setState({ comments: comments })
        }
      })
      .finally(() => {
        if (this.mounted) {
          this.setState({ loading: false })
        }
      })
  },

  createCrmRecordComment(e) {
    e.stopPropagation()
    var keynum
    keynum = window.event ? e.keyCode : e.which
    if (keynum && keynum != 13) {
      return
    }

    const content = this.refs.content.value
    if (!content) {
      this.setState({ text: '' })
      return
    }

    let { opportunity_id, record } = this.props
    return api.createCrmComment(opportunity_id, record.id, { content }).then(
      json => {
        this.props.createCrmRecordCommentAction.success(json)
        // add json to comments
        let comment = Object.values(json.json.entities.crmComments)
        let comments = this.state.comments
          ? [...comment, ...this.state.comments]
          : [...comment]
        this.setState({ comments: comments })
        this.setState({ text: '' })
      },
      errors => this.props.createCrmRecordCommentAction.failure(errors)
    )
  },

  textChange(e) {
    this.setState({ text: e.target.value })
  },

  putComments() {
    this.setState({
      disposeState: !this.state.disposeState
    })
  },

  changeSendBtn(bool) {
    this.setState({
      isShowBtn: bool
    })
  },

  render() {
    let { comments, text, disposeState, isShowBtn } = this.state
    let disposeComments = disposeState ? comments : _.slice(comments, 0, 2)
    const { user, create_by } = this.props
    return (
      <div>
        <div className="nav-comment-children">
          {disposeComments &&
            disposeComments.map(comment => {
              return (
                <CrmRecordListContent comment={comment} create_by={create_by} />
              )
            })}
        </div>
        {comments && comments.length > 2 ? (
          <div
            onClick={this.putComments}
            style={{ paddingLeft: 50, marginBottom: 20 }}
          >
            <span className="color-link c-pointer" style={{ fontSize: 16 }}>
              {disposeState ? '收起评论' : '更多评论'}
            </span>
          </div>
        ) : (
          ''
        )}
        <div className="comment-reply-box clear-fix">
          <KBAvatar
            AvatarClassName="user-reply-img"
            user={user}
            size={36}
            style={{ display: 'inline-block' }}
          />
          <input
            className="input"
            name="recodeComment"
            ref="content"
            onChange={this.textChange}
            onKeyDown={this.createCrmRecordComment}
            value={text}
            onFocus={this.changeSendBtn.bind(null, true)}
            onBlur={this.changeSendBtn.bind(null, false)}
            placeholder="添加回复"
          />
          {true ? (
            <span
              className="certain-btn-ms"
              onClick={this.createCrmRecordComment}
              style={{ marginLeft: 5, padding: '6px 5px' }}
            >
              发送
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
})

CrmRecordCommentList.PropTypes = {
  opportunity_id: PropTypes.number.isRequired,
  record: PropTypes.object.isRequired
}

function mapStateToProps(state, props) {
  const { entities, user } = state

  return {
    user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getRecordCommentsAction: bindActionCreators(
      customerRecordCommentsActions.get,
      dispatch
    ),
    createCrmRecordCommentAction: bindActionCreators(
      customerRecordCommentsActions.create,
      dispatch
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CrmRecordCommentList)
