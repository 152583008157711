import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { invoicesActions, successState, taxesAction } from 'app/actions'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput,
  KBTextarea,
  KBDateInput,
  KBSelect
} from 'components'
import moment from 'moment'
import { routerActions } from 'react-router-redux'
import { toPercent, formatYearDayCHS } from 'utils/kbUtil'
import { getTaxes } from 'app/selectors'
import { LINE_ITEM_TYPES } from 'app/constants'
import _ from 'lodash'

var InvoiceUpdateForm = React.createClass({
  getInitialState() {
    return {
      billType: []
    }
  },
  componentDidMount() {
    const { getTaxesAction } = this.props
    api.getSelfDefinedLineItemTypes().then(
      ({ json }) => {
        let NEW_LINE_ITEM_TYPES = JSON.parse(JSON.stringify(LINE_ITEM_TYPES))
        NEW_LINE_ITEM_TYPES.splice(NEW_LINE_ITEM_TYPES.length - 2, 0, ...json)
        this.setState({
          billType: NEW_LINE_ITEM_TYPES
        })
      },
      error => {}
    )
    api.getSpaceTaxes().then(
      json => {
        getTaxesAction.success(json)
      },
      error => {
        getTaxesAction.failure(error)
      }
    )
  },

  updateInvoice(model) {
    const {
      successAction,
      updateInvoicesActions,
      invoice_id,
      update_type
    } = this.props
    const { billType } = this.state

    for (let i = 0; i < model.line_items.length; i++) {
      for (let j = 0; j < billType.length; j++) {
        if (
          model.line_items[i].line_item_type == billType[j].id &&
          billType[j].charge_code
        ) {
          model.line_items[i].line_item_type = 'self_defined'
          model.line_items[i].self_defined_line_item_type_id = billType[j].id
        }
      }
    }

    if (update_type === 'delay_invoice') {
      delete model.line_items
      return api.putInvoice(invoice_id, model).then(json => {
        this.props.updateInvoicesActions.success(json)
        successAction({
          title: '账单延期成功',
          message: `已延期至${formatYearDayCHS(model.due_date)}到期`
        })
        KBPopover.close()
        return true
      })
    }

    delete model.due_date
    return api.updateInvoicesLineItems(invoice_id, model).then(json => {
      this.props.updateInvoicesActions.success(json)
      successAction({
        message: '账单修改成功'
      })
      KBPopover.close()
      return true
    })
  },
  changeBillType(e) {
    const { routerActions } = this.props
    if (e == 'custom') {
      routerActions.replace('/admin/spaces_setting/invoice')
      KBPopover.close()
    }
  },

  render() {
    const {
      fields: { due_date, line_items },
      error,
      submitting,
      handleSubmit,
      update_type,
      taxes
    } = this.props
    const { billType } = this.state

    return (
      <form onSubmit={handleSubmit(this.updateInvoice)}>
        <header className="kb-form-header">
          <h2 className="header-title">{`修改账单${
            update_type == 'delay_invoice' ? '到期日' : ''
          }`}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div
          className="kb-form-container"
          style={{
            overflowY: `${
              update_type == 'delay_invoice' ? '-webkit-paged-y' : 'auto'
            }`
          }}
        >
          <div className="kb-row">
            <KBDateInput
              type="date"
              title="到期日期"
              isRequired={true}
              className="kb-form-group kb-form-210 kb-form-alone"
              field={due_date}
            />
          </div>
          {update_type == 'delay_invoice' ? null : (
            <fieldset className="fieldset">
              <div className="form-content">
                <header className="kb-nav-header">
                  <div className="region-title">
                    <i className="iconfont icon-adjust" />
                    <span>账款项</span>
                  </div>
                  <div className="kb-nav-line pact-line">
                    <div style={{ width: '480px' }}></div>
                  </div>
                </header>
                {line_items && line_items.length > 0 ? (
                  <ul className="region-input-box">
                    {line_items &&
                      line_items.map((line, index) => {
                        return (
                          <li key={index}>
                            <div className="kb-row">
                              <div className="kb-row kb-form-66">
                                <div className="kb-form-group kb-div-c39">
                                  <label>单价</label>
                                  <div>
                                    <input type="text" {...line.unit_price} />
                                    {line.unit_price.touched &&
                                      line.unit_price.error && (
                                        <p className="lr-error">
                                          {' '}
                                          {line.unit_price.error}{' '}
                                        </p>
                                      )}
                                  </div>
                                </div>
                                <div className="kb-form-group kb-div-c39">
                                  <label className="label-rt">数量</label>
                                  <div>
                                    <input type="number" {...line.units} />
                                    {line.units.touched && line.units.error && (
                                      <p className="lr-error">
                                        {' '}
                                        {line.units.error}{' '}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="kb-row">
                                <KBSelect
                                  onChange={this.changeBillType}
                                  defaultValue="-1"
                                  field={line.line_item_type}
                                  arrayData={billType}
                                  title="类型"
                                  className="kb-form-group kb-form-c18 kb-form-alone"
                                />
                              </div>
                              <div className="kb-row">
                                <KBSelect
                                  defaultValue="-1"
                                  field={line.sales_tax_id}
                                  arrayData={taxes}
                                  title="税率"
                                  className="kb-form-group kb-form-c18 kb-form-alone"
                                />
                              </div>
                              <div className="kb-row">
                                <KBTextarea
                                  disabled={true}
                                  field={line.description}
                                  title="说明"
                                  className="kb-form-group kb-form-c18 kb-form-alone"
                                />
                              </div>
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                ) : null}
              </div>
            </fieldset>
          )}
          <div className="f-right" style={{ textAlign: 'right' }}>
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn m-left-sm"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

InvoiceUpdateForm.propTypes = {
  invoice_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
}

function mapStateToProps(state, props) {
  let lineItems = props.invoice.line_items || []
  let due_date =
    props.invoice.due_date || moment(new Date()).format('YYYY-MM-DD')
  let taxes = Object.assign([], getTaxes(state))
  taxes = _.cloneDeep(taxes).map(tax => {
    let name = tax.name
    tax.name = `${name} (税率:${toPercent(tax.rate, 2)})`
    return tax
  })

  return {
    taxes,
    initialValues: { due_date, line_items: lineItems }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch),
    successAction: bindActionCreators(successState, dispatch),
    getTaxesAction: bindActionCreators(taxesAction.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validateLine = line => {
  const errors = {}
  if (valid.isNumber(line.unit_price)) {
    errors.unit_price = '请输入正确的价格, 小数点保留2位'
  }

  if (valid.required(line.units) || line.units == 0) {
    errors.units = '请输入数量并且不等于0'
  }
  return errors
}

const validate = values => {
  const errors = {}

  if (valid.required(values.due_date)) {
    errors.due_date = '请选择到期日期'
  }

  errors.line_items = values.line_items.map(validateLine)

  return errors
}

const formConfig = {
  form: 'InvoiceUpdateForm',
  fields: [
    'due_date',
    'line_items[].id',
    'line_items[].units',
    'line_items[].unit_price',
    'line_items[].sales_tax_id',
    'line_items[].line_item_type',
    'line_items[].description'
  ],
  validate: validate,
  touchOnBlur: false
}

InvoiceUpdateForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoiceUpdateForm)

export default InvoiceUpdateForm
