import React, { Component, PropTypes } from 'react'
import { KBLetterAvatar } from 'components'

var KBAvatar = React.createClass({
  render() {
    var {
      user,
      size,
      className,
      imgStyle,
      imgSize,
      AvatarClassName
    } = this.props

    if (user && (user.avatar || user.logo)) {
      let obj = {}
      if (imgSize) {
        obj.width = imgSize.width
        obj.height = imgSize.height
      } else {
        obj.width = size
        obj.height = size
      }

      return (
        <img
          className={className}
          src={user.avatar || user.logo}
          style={{ ...obj, borderRadius: imgSize ? 0 : 100, ...imgStyle }}
        />
      )
    } else {
      return (
        <KBLetterAvatar
          class={AvatarClassName}
          name={user && (user.name || user.area_name)}
          pinyin={user && user.name_pinyin}
          size={size}
          style={this.props.style}
        />
      )
    }
  }
})

KBAvatar.PropTypes = {
  user: PropTypes.object.isRequired,
  size: PropTypes.number
}

KBAvatar.defaultProps = {
  size: 40
}
export default KBAvatar
