import React, { Component, PropTypes } from 'react'
import { KBDropdown } from 'components'
import moment from 'moment'
import DayPicker, { DateUtils } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import _ from 'lodash'
import { contrastDate } from 'utils/kbUtil'

var KBRangeDate = React.createClass({
  getInitialState() {
    const { from, to } = this.props
    return {
      from: (from && moment(from).toDate()) || null,
      to: (to && moment(to).toDate()) || null
    }
  },

  _setDateRange() {
    const { from, to } = this.state
    this._refresh('fromTo')
    this.props.callback({ from, to })
    if (!from && !to) {
      this.setState({
        from: '',
        to: ''
      })
    }
  },

  handleDayClick(e, day) {
    const { from, to } = this.state
    const range = DateUtils.addDayToRange(day, { from, to })
    this._selectRange(range)
  },

  _selectRange(range) {
    this.setState({
      ...range
    })
  },

  getDefaultProps() {
    return {
      format: 'MMMDo',
      title: '选择日期范围'
    }
  },

  _selectToday() {
    let today = new Date()
    this._selectRange({ from: today, to: today })
  },

  _selectWeek() {
    let today = new Date()
    let startOfWeek = moment(today)
      .startOf('week')
      .toDate()
    let endOfWeek = moment(today)
      .endOf('week')
      .toDate()
    this._selectRange({ from: startOfWeek, to: endOfWeek })
  },

  _selectMonth() {
    let today = new Date()
    let startOfMonth = moment(today)
      .startOf('month')
      .toDate()
    let endOfMonth = moment(today)
      .endOf('month')
      .toDate()
    this._selectRange({ from: startOfMonth, to: endOfMonth })
  },

  _selectQuarter() {
    let today = new Date()
    let startOfQuarter = moment(today)
      .startOf('quarter')
      .toDate()
    let endOfQuarter = moment(today)
      .endOf('quarter')
      .toDate()
    this._selectRange({ from: startOfQuarter, to: endOfQuarter })
  },

  _selectNextMonth() {
    let today = new Date()
    let startOfNextMonth = moment(today)
      .add(1, 'month')
      .startOf('month')
      .toDate()
    let endOfNextMonth = moment(today)
      .add(1, 'month')
      .endOf('month')
      .toDate()

    this._selectRange({ from: startOfNextMonth, to: endOfNextMonth })
  },

  _selectNextQuarter() {
    let today = new Date()
    let startOfNextQuarter = moment(today)
      .add(1, 'quarter')
      .startOf('quarter')
      .toDate()
    let endOfNextQuarter = moment(today)
      .add(1, 'quarter')
      .endOf('quarter')
      .toDate()

    this._selectRange({ from: startOfNextQuarter, to: endOfNextQuarter })
  },

  _resetDateRange() {
    this.setState({
      from: '',
      to: ''
    })
  },

  _refresh(key) {
    let dropDown = key + 'Dropdown'
    const { page } = this.props
    this.refs[dropDown] && this.refs[dropDown].hide()
  },

  componentWillReceiveProps(nextProps) {
    const { from, to } = nextProps
    const oldFrom = this.props.from
    const oldTo = this.props.to
    if (
      contrastDate(from, oldFrom, 'integer') != 0 ||
      contrastDate(to, oldTo, 'integer') != 0
    ) {
      this.setState({
        from: (from && moment(from).toDate()) || null,
        to: (to && moment(to).toDate()) || null
      })
    }
  },

  hide() {
    this.refs['fromToDropdown'].hide()
  },

  fromToTextRender(from, to) {
    const { format, defaultDate, title } = this.props
    return from || to
      ? `${title}:${from ? moment(from).format(format) : ''}${to ? '-' + moment(to).format(format) : ''
      }`
      : (defaultDate && title + ':' + moment(defaultDate).format(format)) ||
      title
  },

  render() {
    const {
      leftNone,
      style,
      iconStyle,
      spanStyle,
      closeClick,
      dropDown_name
    } = this.props
    const { from, to } = this.state

    return (
      <KBDropdown
        ref="fromToDropdown"
        style={leftNone ? { ...style } : { marginLeft: 20 }}
      >
        <KBDropdown.KBDropdownTrigger>
          <div
            className="task-status-title"
            onClick={() => {
              closeClick && closeClick(dropDown_name)
            }}
          >
            <i
              className="iconfont icon-calendar"
              style={{ paddingRight: '3px', ...iconStyle }}
            />
            <span style={spanStyle}>{this.fromToTextRender(from, to)}</span>
            <span className="task-deg" />
          </div>
        </KBDropdown.KBDropdownTrigger>
        <KBDropdown.KBDropdownContent>
          <div style={{ width: 530 }}>
            <DayPicker
              dir="zh-cn"
              locale="zh-cn"
              ref="daypicker"
              localeUtils={MomentLocaleUtils}
              numberOfMonths={2}
              initialMonth={from || new Date()}
              selectedDays={day => DateUtils.isDayInRange(day, { from, to })}
              onDayClick={this.handleDayClick}
            />
            <div
              className="date-btn"
              style={{
                padding: 20,
                borderTop: '1px solid #e6e6e6',
                background: '#fff'
              }}
            >
              <div className="f-left">
                <a className="add-btn" onClick={this._selectWeek}>
                  本周
                </a>
                <a className="add-btn" onClick={this._selectMonth}>
                  本月
                </a>
                <a className="add-btn" onClick={this._selectQuarter}>
                  本季度
                </a>
                <a className="add-btn" onClick={this._selectNextMonth}>
                  下月
                </a>
                <a className="add-btn" onClick={this._selectNextQuarter}>
                  下季度
                </a>
                {this.props.children}
              </div>
              <div className="f-right">
                <span className="cancel-btn" onClick={this._resetDateRange}>
                  重置
                </span>
                <button
                  className="certain-btn"
                  type="button"
                  onClick={this._setDateRange}
                >
                  确定
                </button>
              </div>
            </div>
          </div>
        </KBDropdown.KBDropdownContent>
      </KBDropdown>
    )
  }
})

KBRangeDate.propTypes = {
  callback: PropTypes.func.isRequired,
  format: PropTypes.string,
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
}

export default KBRangeDate
