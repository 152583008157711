import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import { util, momentFromNow } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import CrmInventorySearchForm from './CrmInventorySearchForm'
import moment from 'moment'
import {
  DESK_STATES,
  AREA_STATES_CLASS,
  CRM_INVENTORY_EXPIRING_DATE,
  CRM_INVENTORY_EXPIRING_DATE_STRING,
  OPPORTUNITY_DESIRES,
  OPPORTUNITY_DESIRES_CLASS
} from 'app/constants'
import { KBNoData } from 'components'

var CrmInventory = React.createClass({
  displayName: 'CrmInventory',
  getInitialState() {
    return {
      private_usage: null,
      showFloor: false,
      public_usage: null,
      flexible_usage: null,
      showType: 'private'
    }
  },

  componentDidMount() {},

  getInventory(data) {
    let { private_usage, public_usage, flexible_usage } = data
    let showType = private_usage
      ? 'private'
      : public_usage
      ? 'public'
      : flexible_usage
      ? 'flexible'
      : 'none'
    this.setState({
      private_usage,
      public_usage,
      flexible_usage,
      showType
    })
  },

  privateUsageAreaJsx() {
    const { private_usage, showFloor } = this.state
    let jsx =
      private_usage.locations.length > 0 ? (
        private_usage.locations.map((json, index) => {
          return (
            <div className="m-bottom">
              <div
                className="border-bottom flex-vertical-center"
                style={{ padding: 10, justifyContent: 'space-between' }}
              >
                <span style={{ fontSize: 16 }}>{json.location_name}</span>
              </div>
              <table
                className="content-table"
                key={index}
                style={{ borderCollapse: 'inherit' }}
              >
                <thead>
                  <tr>
                    <th>分店名称</th>
                    <th>分区名称</th>
                    <th>分区状态</th>
                    <th>面积(㎡)</th>
                    <th>人数</th>
                    <th>价格</th>
                    <th>到期状态</th>
                    <th>到期日</th>
                    <th>意向客户</th>
                    <th>订购意愿</th>
                    <th>销售者</th>
                  </tr>
                </thead>
                <tbody>
                  {json.areas.length > 0 &&
                    json.areas.map(area => {
                      const {
                        name,
                        state,
                        size,
                        capacity,
                        listing_price,
                        expire_date,
                        office_rent_intention
                      } = area
                      let expireDate = expire_date
                        ? parseInt(
                            moment(expire_date).diff(moment(), 'days') / 30
                          )
                        : null
                      if (expireDate == 1) {
                        expireDate = 'oneMonth'
                      } else if (expireDate == 2) {
                        expireDate = 'twoMonth'
                      } else if (expireDate == 3) {
                        expireDate = 'threeMonth'
                      } else if (expireDate > 3) {
                        expireDate = 'moreMonth'
                      } else {
                        expireDate = null
                      }
                      return (
                        <tr className="inventory-tr">
                          <td>{json.location_name}</td>
                          <td>
                            <Link
                              className="a-hover"
                              to={`/admin/locations/${json.location_id}/areas/desks/list?area_id=${area.id}`}
                            >
                              {name}
                            </Link>
                          </td>
                          <td>
                            <span
                              className={`receipt-status station-${AREA_STATES_CLASS[state]}`}
                            >
                              {DESK_STATES[state]}
                            </span>
                          </td>
                          <td>{size || '无'}</td>
                          <td>{capacity || 0}人</td>
                          <td>{listing_price}/人/月</td>
                          <td>
                            {expireDate ? (
                              <span
                                className={`receipt-status ${CRM_INVENTORY_EXPIRING_DATE[expireDate]}`}
                              >
                                {CRM_INVENTORY_EXPIRING_DATE_STRING[expireDate]}
                              </span>
                            ) : (
                              '暂无'
                            )}
                          </td>
                          <td>
                            {expire_date
                              ? moment(expire_date).format('YYYY-MM-DD')
                              : ''}
                          </td>
                          <td>
                            {office_rent_intention
                              ? office_rent_intention.user_name
                              : ''}
                          </td>
                          <td>
                            {office_rent_intention ? (
                              <span
                                className={`receipt-status ${
                                  OPPORTUNITY_DESIRES_CLASS[
                                    office_rent_intention.desire
                                  ]
                                }`}
                              >
                                {
                                  OPPORTUNITY_DESIRES[
                                    office_rent_intention.desire
                                  ]
                                }
                              </span>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>
                            {office_rent_intention
                              ? office_rent_intention.creator_name
                              : ''}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          )
        })
      ) : (
        <KBNoData tipMessage="暂无数据" />
      )
    return jsx
  },

  flexibleUsageLocationJsx() {
    const { flexible_usage } = this.state
    let jsx =
      flexible_usage.locations.length > 0 &&
      flexible_usage.locations.map((json, index) => {
        return (
          <tr>
            <td>{json.location_name}</td>
            <td>{json.total}个</td>
            <td>{json.remain}个</td>
            <td>{json.expiring_count ? json.expiring_count : 0}个</td>
          </tr>
        )
      })
    return jsx
  },

  publicUsageLocationJsx() {
    const { public_usage } = this.state
    let jsx =
      public_usage.locations.length > 0 &&
      public_usage.locations.map((json, index) => {
        return (
          <tr>
            <td>{json.location_name}</td>
            <td>{json.total}个</td>
            <td>{json.remain}个</td>
            <td>{json.expiring_count ? json.expiring_count : 0}个</td>
          </tr>
        )
      })
    return jsx
  },

  setShowType(type) {
    this.setState({
      showType: type
    })
  },

  render() {
    const { locations } = this.props
    let { private_usage, public_usage, flexible_usage, showType } = this.state
    return (
      <div className="kb-content-container clear-fix">
        <section>
          <header className="nav-header border-bottom">
            <div className="nav-section-header-title">
              <span>库存信息</span>
            </div>
          </header>
          <div className="nav-section-content-container">
            <CrmInventorySearchForm
              locations={locations}
              callback={this.getInventory}
            />
            <div className="kb-title m-bottom">
              <span>搜索结果</span>
            </div>
            {(public_usage && public_usage.total) ||
            (flexible_usage && flexible_usage.total) ||
            (private_usage && private_usage.total) ? (
              <div>
                <ul className="nav-section-tab">
                  {private_usage && private_usage.total ? (
                    <li
                      onClick={this.setShowType.bind(null, 'private')}
                      className={`kb-tabs-menu-item ${
                        showType == 'private' ? 'nav-section-tab-active' : ''
                      }`}
                    >
                      <a>封闭工位</a>
                    </li>
                  ) : (
                    ''
                  )}
                  {public_usage && public_usage.total ? (
                    <li
                      onClick={this.setShowType.bind(null, 'public')}
                      className={`kb-tabs-menu-item ${
                        showType == 'public' ? 'nav-section-tab-active' : ''
                      }`}
                    >
                      <a>开放工位</a>
                    </li>
                  ) : (
                    ''
                  )}
                  {flexible_usage && flexible_usage.total ? (
                    <li
                      onClick={this.setShowType.bind(null, 'flexible')}
                      className={`kb-tabs-menu-item ${
                        showType == 'flexible' ? 'nav-section-tab-active' : ''
                      }`}
                    >
                      <a>灵活工位</a>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
                {public_usage && showType == 'public' ? (
                  <div className="m-bottom">
                    <div
                      className="border-bottom"
                      style={{ paddingBottom: 10 }}
                    >
                      <span className="m-right-sm" style={{ fontSize: 18 }}>
                        开放工位
                      </span>
                      <span style={{ fontSize: 14 }}>
                        {public_usage.remain}个
                      </span>
                    </div>
                    <table className="content-table">
                      <thead>
                        <tr>
                          <th>分店名称</th>
                          <th>总数</th>
                          <th>剩余</th>
                          <th>到期</th>
                        </tr>
                      </thead>
                      {this.publicUsageLocationJsx()}
                    </table>
                  </div>
                ) : (
                  ''
                )}
                {flexible_usage && showType == 'flexible' ? (
                  <div className="m-bottom">
                    <div
                      className="border-bottom"
                      style={{ paddingBottom: 10 }}
                    >
                      <span className="m-right-sm" style={{ fontSize: 18 }}>
                        灵活工位
                      </span>
                      <span style={{ fontSize: 14 }}>
                        {flexible_usage.remain}个
                      </span>
                    </div>
                    <table className="content-table">
                      <thead>
                        <tr>
                          <th>分店名称</th>
                          <th>总数</th>
                          <th>剩余</th>
                          <th>到期</th>
                        </tr>
                      </thead>
                      {this.flexibleUsageLocationJsx()}
                    </table>
                  </div>
                ) : (
                  ''
                )}
                {private_usage && showType == 'private' ? (
                  <div>
                    <div
                      className="border-bottom"
                      style={{ paddingBottom: 10 }}
                    >
                      <span className="m-right-sm" style={{ fontSize: 18 }}>
                        独立办公室
                      </span>
                      <span style={{ fontSize: 14 }}>
                        {private_usage.remain}个
                      </span>
                    </div>
                    <div style={{ paddingLeft: 30 }}>
                      {this.privateUsageAreaJsx()}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <KBNoData tipMessage="暂无数据" />
            )}
          </div>
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const locations = selectors.getLocations(state)

  return {
    locations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrmInventory)
