import React, { Component, PropTypes } from 'react'
import {
  INVOICE_ORDER_CERTIFICATE_PAYMENT_TYPES,
  INVOICE_ORDER_SALES_PAYMENTS
} from 'app/constants'
import {
  KBDropdown,
  KBPagination,
  KBRangeDate,
  KBTableWithoutData,
  KBLoadingContainer,
  KBAvatar,
  KBTipsy,
  KBPopover
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { Router, Route, Link } from 'react-router'
import { api, apiUtils } from 'app/services'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { successState } from 'app/actions'
import {
  getSerializeLocationObj,
  getCurrentUser,
  getSpaceVirtualCurrencyName,
  getObjectMembers
} from 'app/selectors'
import { bindActionCreators } from 'redux'
import {
  fmoney,
  paginate,
  formatMinuteSecondEN,
  kbCloseDropDown,
  queryFilter,
  getFilterAndQuery
} from 'utils/kbUtil'
import { getInitialDate } from 'utils/kbData'
import { getUserInfoUrl } from 'utils/kbUrl'
import OnlyTimeReportForm from './OnlyTimeReportForm'

const INVOICE_PAYMENTS_PAY_MODE = [
  {
    id: 'wechat',
    name: '微信(线上)'
  },
  {
    id: 'alipay',
    name: '支付宝(线上)'
  }
]

const DROP_DOWNS = [
  'statusDropdown',
  'applyDateDropDown',
  'location_idDropdown'
]

var InvoicePayments = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      filters,
      invoice_payments: [],
      pagination: null,
      loading: false
    }
  },

  componentDidMount() {
    this.mounted = true

    this.getApplicationList()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  getApplicationList(newPage) {
    const { filters } = this.state
    const { routerActions, query, location_id } = this.props
    const per_page = 10
    let pageObj = {
      per_page,
      page: newPage || this.props.page
    }

    let params = Object.assign({}, filters, location_id ? { location_id } : {})
    if (!params.payment_mode) {
      params.payment_mode = 'wechat,alipay'
    }

    let queryFiltersStr = queryFilter({ ...query, ...params, ...pageObj })

    this.setState({ loading: true })
    api
      .getInvoicesPayments({ ...params, ...pageObj })
      .then(
        json => {
          let pagination = paginate(json.response)
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin${
              this.props.location_id
                ? `/locations/${this.props.location_id}`
                : ''
            }/invoices/invoice_payments${queryFiltersStr}`
          )
          if (this.mounted) {
            this.setState({
              invoice_payments: json.json,
              pagination
            })
          }
        },
        errors => {
          console.log(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.getApplicationList(nextProps.page)
    }
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters })
    this.refs.statusDropdown.hide()
    this.refs.location_idDropdown && this.refs.location_idDropdown.hide()

    setTimeout(this.getApplicationList)
  },

  _clearFilters() {
    const { routerActions } = this.props
    this.setState({ filters: {} })
    setTimeout(this.getApplicationList)
    routerActions.replace(
      `/admin${
        this.props.location_id ? `/locations/${this.props.location_id}` : ''
      }/invoices/invoice_payments`
    )
  },

  changeUpdateRange(rage) {
    let applyDateRange = {}
    applyDateRange.start_date = rage.from
      ? getInitialDate(rage.from).initStartDateString
      : null
    applyDateRange.end_date = rage.to
      ? getInitialDate(rage.to).initEndDateString
      : null
    applyDateRange.start_date &&
      applyDateRange.end_date &&
      this._setFilter(applyDateRange)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  _renderFilters() {
    const { locations, location_id } = this.props
    const { filters } = this.state
    const { payment_mode, start_date, end_date } = filters

    return (
      <div className="nav-section-action-bar">
        <div className="select-progress">
          {location_id && Object.keys(locations).length > 0 ? (
            <div
              className="task-status-title"
              style={{ display: 'inline-block' }}
            >
              <i className="iconfont icon-position" />
              <span>{locations[location_id].name || ''}</span>
            </div>
          ) : (
            <KBDropdown ref="location_idDropdown">
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(
                    null,
                    'location_idDropdown'
                  )}
                >
                  <i className="iconfont icon-position" />
                  <span>
                    {this.state.filters.location_id &&
                    Object.keys(locations).length > 0
                      ? locations[this.state.filters.location_id].name
                      : '选择分店'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ location_id: '' })}>
                      全部
                    </li>
                    {locations &&
                      Object.keys(locations).map((location_id, index) => {
                        let location = locations[location_id]
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              this._setFilter({ location_id: location.id })
                            }
                          >
                            <span>{location.name}</span>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          )}
          <KBDropdown ref="statusDropdown">
            <KBDropdown.KBDropdownTrigger>
              <div className="task-status-title" style={{ marginLeft: 20 }}>
                <i className="iconfont icon-finance" />
                <span>
                  {payment_mode
                    ? INVOICE_ORDER_SALES_PAYMENTS[payment_mode] ||
                      (payment_mode === 'wechat,alipay' ? '全部' : '')
                    : '支付方式'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li
                    onClick={() =>
                      this._setFilter({ payment_mode: 'wechat,alipay' })
                    }
                  >
                    <span>全部</span>
                  </li>
                  {INVOICE_PAYMENTS_PAY_MODE.map((state, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          this._setFilter({ payment_mode: state.id })
                        }
                      >
                        <span>{state.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
          <KBRangeDate
            from={start_date || ''}
            to={end_date || ''}
            title="选择支付日期"
            callback={this.changeUpdateRange}
            closeClick={this.closeDropDowns}
            dropDown_name="applyDateDropDown"
            ref={ref => {
              this.applyDateDropDown = ref
            }}
            leftNone={false}
          />
        </div>
      </div>
    )
  },

  paymentsReport() {
    const { location_id } = this.props
    KBPopover.show(<OnlyTimeReportForm loc_id={location_id} type="payment" />)
  },

  render() {
    const { invoice_payments, pagination, loading } = this.state
    const { virtual_currency_name, usersObj, location_id } = this.props

    return (
      <div className="kb-content-container clear-fix">
        <div>
          <header className="nav-header">
            <div className=" clear-fix">
              <div className="nav-section-header-title f-left">
                <span>支付信息</span>
              </div>
              <button
                className="c-btn-secondary f-right"
                onClick={this.paymentsReport}
              >
                导出支付信息
              </button>
            </div>
          </header>
          <div className="nav-section-content-container">
            <div>
              {this._renderFilters()}
              {Object.keys(this.state.filters).length > 0 ? (
                <div className="clear-criteria" onClick={this._clearFilters}>
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              ) : null}
            </div>
            <KBLoadingContainer loading={loading}>
              <table className="content-table">
                <thead>
                  <tr>
                    <th>账单编号</th>
                    <th>账单内容</th>
                    <th>收款账户</th>
                    <th>支付金额</th>
                    <th>支付方式</th>
                    <th>支付时间</th>
                    <th>支付人</th>
                    <th>备注</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={invoice_payments.length > 0}
                  tableHeadNum="7"
                  tipMessage="暂无支付信息"
                >
                  {invoice_payments &&
                    invoice_payments.map((payment, index) => (
                      <tr key={index}>
                        <td>
                          {payment.sales_invoice ? (
                            <Link
                              to={`/admin${
                                location_id ? '/locations/' + location_id : ''
                              }/invoices/invoices_list/${
                                payment.sales_invoice.id
                              }`}
                              className="color-link"
                            >
                              {payment.sales_invoice.serial_number}
                            </Link>
                          ) : (
                            ''
                          )}
                        </td>
                        <td>
                          {(payment.sales_invoice &&
                            payment.sales_invoice.title) ||
                            ''}
                        </td>
                        <td>
                          <KBTipsy content={payment.account_id}>
                            <span
                              style={{
                                display: 'inline-block',
                                width: 100,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                cursor: 'pointer'
                              }}
                            >
                              {payment.account_id || ''}
                            </span>
                          </KBTipsy>
                        </td>
                        <td className="color-red">
                          {payment.payment_mode === 'point'
                            ? `${payment.amount || 0} ${virtual_currency_name}`
                            : `¥ ${fmoney(payment.amount || 0)}`}
                        </td>
                        <td>
                          {(payment.payment_mode &&
                            INVOICE_ORDER_SALES_PAYMENTS[
                              payment.payment_mode
                            ]) ||
                            ''}
                        </td>
                        <td>
                          {payment.payment_time
                            ? formatMinuteSecondEN(payment.payment_time)
                            : ''}
                        </td>
                        <td>
                          {payment.created_by ? (
                            <div className="vf-center">
                              <KBAvatar
                                user={{
                                  id: payment.created_by,
                                  name:
                                    (usersObj[payment.created_by] &&
                                      usersObj[payment.created_by].name) ||
                                    ''
                                }}
                                imgStyle={{ marginRight: 5 }}
                                style={{
                                  display: 'inline-block',
                                  marginRight: '5px'
                                }}
                                size={30}
                              />
                              <Link
                                to={getUserInfoUrl(payment.created_by)}
                                className="color-link"
                              >
                                {(usersObj[payment.created_by] &&
                                  usersObj[payment.created_by].name) ||
                                  ''}
                              </Link>
                            </div>
                          ) : null}
                        </td>
                        <td>{payment.notes || ''}</td>
                      </tr>
                    ))}
                </KBTableWithoutData>
              </table>
            </KBLoadingContainer>
            <KBPagination
              pagination={pagination}
              template={`/admin${
                this.props.location_id
                  ? `/locations/${this.props.location_id}`
                  : ''
              }/invoices/invoice_payments?page=#PAGE#`}
            />
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location, params } = props
  let location_id = params.id
  let user = getCurrentUser(state)
  let page = parseInt(location.query && location.query.page) || 1
  let locations = getSerializeLocationObj(state)
  var FILTER_STATE = ['payment_mode', 'start_date', 'end_date', 'location_id']

  var param = getFilterAndQuery(location.query, FILTER_STATE)
  if (param.filters.payment_mode == 'null') {
    param.filters.payment_mode = null
  }

  let virtual_currency_name = getSpaceVirtualCurrencyName(state)
  let usersObj = getObjectMembers(state)

  return {
    user,
    page,
    location_id,
    locations,
    virtual_currency_name,
    usersObj,
    ...param
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePayments)
