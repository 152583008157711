import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { api, apiUtils } from 'app/services'
import { apiCallFailure, lockPermissionsActions } from 'app/actions'
import {
  KBDateInput,
  KBPopover,
  KBFormError,
  KBButton,
  KBTransfer,
  KBSelect
} from 'components'
import { DAY_TIME_HM, LOCK_MANAGE_TIPSY } from 'app/constants'
import BatchUpdateAreasForm from './BatchUpdateAreasForm'
import moment from 'moment'
const pinyin = require('tiny-pinyin')

const AREA_TYPE = {
  office: '分区',
  room: '场地'
}

var BatchUpdateAreas = React.createClass({
  displayName: 'BatchUpdateAreas',
  getInitialState() {
    return {
      areaSource: [],
      areaTarget: []
    }
  },
  createBatchAreaForm() {
    const { apiCallFailureActions } = this.props
    let areas = this.refs.areaTransfer.getData().target
    if (areas.length <= 0) {
      apiCallFailureActions({
        status: 'error',
        message: '请选择场地'
      })
      return
    }
    this.showBatchUpdateAreas(areas)
  },

  showBatchUpdateAreas(areas) {
    const { loc_id, areaType } = this.props
    let batchUpdateAreas = []
    let area_ids = []
    areas &&
      areas.map(a => {
        batchUpdateAreas = batchUpdateAreas.concat(a.childrens)
      })
    batchUpdateAreas.map(area => {
      area_ids.push(area.id)
    })
    KBPopover.close()
    KBPopover.show(
      <BatchUpdateAreasForm
        areaType={areaType}
        loc_id={loc_id}
        area_ids={area_ids}
      />
    )
  },

  componentDidMount() {
    const { areas } = this.props

    // 对 area 按照 area_type 进行分组
    let tempIds = {}
    areas &&
      areas.map(json => {
        let tempIdKeys = Object.keys(tempIds)
        let s_id = json.area_type || 'gate'
        if (tempIdKeys.includes(String(s_id))) {
          tempIds[s_id].push(json)
        } else {
          tempIds[s_id] = []
          tempIds[s_id].push(json)
        }
      })

    let arrayedLocks = Object.values(tempIds)
    let mapedLocks = []

    arrayedLocks &&
      arrayedLocks.map(data => {
        let id = mapedLocks.length || 0
        let nameIndex = (data[0] && data[0].area_type) || 'gate'
        let name = LOCK_MANAGE_TIPSY[nameIndex]
        let childrens = data
        mapedLocks.push({ id, name, childrens })
      })

    this.setState({
      areaSource: mapedLocks
    })
  },

  getAreas(id) {
    const { areaSource } = this.state
    let children = areaSource[id].childrens

    children &&
      children.map(lock => {
        lock.pinyin = pinyin.convertToPinyin(lock.name).split('')[0]
      })
    children &&
      children.sort((a, b) => {
        return a.pinyin.localeCompare(b.pinyin)
      })

    return children
  },

  render() {
    const {
      error,
      submitting,
      handleSubmit,
      state,
      areas,
      selectAreas,
      lock,
      areaType
    } = this.props
    let { areaSource, areaTarget } = this.state
    var halfClass = {
      className: 'kb-form-group kb-div-c48'
    }
    return (
      <form onSubmit={handleSubmit(this.createBatchAreaForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">批量修改{AREA_TYPE[areaType]}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className="kb-title border-none">
            <i
              className="iconfont icon-department"
              style={{ fontSize: 18, marginRight: 5, color: '#666' }}
            />
            <span>{AREA_TYPE[areaType]}选择</span>
          </div>
          <div className="kb-row">
            <KBTransfer
              ref="areaTransfer"
              isSearch={false}
              isHead={true}
              isMiddle={false}
              isChildrenLoad={true}
              getExpandChilds={this.getAreas}
              multi={true}
              sourceTitle={AREA_TYPE[areaType]}
              targetTitle="已添加"
              source={areaSource}
              target={areaTarget}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { entities } = state
  const { loc_id, lock } = props
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.create,
      dispatch
    ),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'BatchUpdateAreas',
  fields: [],
  validate: validate,
  touchOnBlur: false
}

BatchUpdateAreas = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(BatchUpdateAreas)

export default BatchUpdateAreas
