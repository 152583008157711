import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import {
  deskActions,
  usersActions,
  subscriptionActions,
  informActions,
  orgUserActions,
  orgActions
} from 'app/actions'
import { KBButton, KBPopover, KBUserInput } from 'components'
import { getOrg, getOrgMembers } from 'app/selectors'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'

var MemberDeskForm = React.createClass({
  getInitialState() {
    return {
      contracts: [],
      errorMsg: ''
    }
  },

  componentDidMount() {
    const {
      desk: { contract_id },
      getSubscriptionActions,
      loc_id,
      getOrganizationsAction
    } = this.props
    if (contract_id == -1) {
      return
    }

    api
      .getOrganizations({
        location_id: loc_id,
        per_page: 1000,
        organization_type: 'normal'
      })
      .then(
        json => {
          getOrganizationsAction.success(json, { location_id: loc_id })
        },
        errors => {
          getOrganizationsAction.failure(errors)
        }
      )

    api.getSubscription(contract_id).then(
      json => {
        let subscription = getEntitiesJsonArray(json, 'subscriptions') || []
        subscription.name = `合同 (编号 《${subscription.serial}》, ${subscription.units}人 剩余${subscription.remain_units}人)`

        this.setState({
          contracts: [subscription]
        })

        getSubscriptionActions.success(json)
      },
      error => {
        getSubscriptionActions.failure(error)
      }
    )
  },

  updateOrganizationMemberForm(model) {
    const {
      updateUserActions,
      informUpdateActions,
      changeDeskOfList,
      callback,
      orgUserActions
    } = this.props
    const { user_id, organization_id, contract_id, desk_id } = model
    return api
      .assignDeskUser(user_id, organization_id, {
        contract_id,
        desk_id
      })
      .then(json => {
        const {
          json: {
            entities: { users }
          }
        } = json
        const values = Object.values(users)[0]
        let entry = {
          [user_id]: {
            ...values,
            ...values.user,
            id: values.user.id
          }
        }

        entry[user_id].desks = entry[user_id].desks.map(json => {
          json.user = values.user
          return json
        })

        json.json.entities.users = entry

        updateUserActions.success(json)
        orgUserActions.success({
          json: {
            json: {
              entities: {
                orgUsers: entry
              }
            }
          }
        })
        informUpdateActions.success(null, { key: 'inform', value: 'areas' })
        changeDeskOfList(values.desks.find(d => d.id === desk_id) || {})
        callback()
        this.closeWindow()
        return true
      })
  },

  closeWindow() {
    KBPopover.close()
  },

  render() {
    const {
      fields: { contract_id, organization_id, user_id },
      error,
      submitting,
      handleSubmit,
      orgs,
      members
    } = this.props
    const { contracts, errorMsg } = this.state
    return (
      <form onSubmit={handleSubmit(this.updateOrganizationMemberForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">会员入驻</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={this.closeWindow}
          />
        </header>
        <div className="kb-form-container-m">
          <div className="kb-row">
            <KBUserInput
              placeholder="请选择一个公司"
              className="kb-form-group kb-div-c18 kb-form-alone"
              disabled={true}
              selectedUsers={orgs}
              title="选择公司"
              users={orgs}
              multi={false}
              field={organization_id}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              placeholder="请选择一个合同"
              className="kb-form-group kb-div-c18 kb-form-alone"
              disabled={true}
              selectedUsers={contracts}
              title="选择合同"
              users={contracts}
              multi={false}
              field={contract_id}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              placeholder="请选择一个员工"
              className="kb-form-group kb-div-c18 kb-form-alone"
              title="选择员工"
              noResultsText="暂无可选员工"
              users={members}
              multi={false}
              field={user_id}
            />
          </div>
          <div className="kb-row">
            {errorMsg ? <p className="lr-error">{errorMsg}</p> : ''}
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={this.closeWindow}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              完成
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

MemberDeskForm.propTypes = {
  desk: PropTypes.object.isRequired
}

function mapStateToProps(state, props) {
  const { loc_id, desk } = props
  const organization_id = desk.organization_id
  const contract_id = desk.contract_id
  let org = getOrg(state, desk.organization_id)
  let members = getOrgMembers(state, null, organization_id)
  members = members.filter(m => !m.desks || !m.desks.length)

  return {
    desk,
    orgs: org ? [org] : [],
    members,
    loc_id,
    initialValues: {
      desk_id: desk.id,
      organization_id,
      contract_id
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateUserActions: bindActionCreators(usersActions.update, dispatch),
    orgUserActions: bindActionCreators(orgUserActions.update, dispatch),
    getDesksActions: bindActionCreators(deskActions.get, dispatch),
    deleteDeskActions: bindActionCreators(deskActions.delete, dispatch),
    getSubscriptionActions: bindActionCreators(
      subscriptionActions.all,
      dispatch
    ),
    informUpdateActions: bindActionCreators(informActions.update, dispatch),
    getOrganizationsAction: bindActionCreators(orgActions.get, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.contract_id)) {
    errors.contract_id = '请选择合同编号'
  }

  if (valid.required(values.desk_id)) {
    errors.desk_id = '请选择工位号'
  }

  if (valid.required(values.user_id)) {
    errors.user_id = '请选择公司员工'
  }

  if (valid.required(values.organization_id)) {
    errors.organization_id = '请选择公司'
  }

  return errors
}

const formConfig = {
  form: 'MemberDeskForm',
  fields: ['contract_id', 'desk_id', 'organization_id', 'user_id'],
  validate: validate,
  touchOnBlur: false,
  initialValues: {
    gender: 'female'
  }
}

MemberDeskForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(MemberDeskForm)

export default MemberDeskForm
