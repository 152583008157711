import React, { createClass, PropTypes } from 'react'

const KBDropdownContent = createClass({
  displayName: 'KBDropdownContent',

  propTypes: {
    children: PropTypes.node,
    className: PropTypes.string
  },

  getDefaultProps() {
    return {
      className: ''
    }
  },

  render() {
    const { children, className } = this.props
    const props = {
      ...this.props,
      className: `kb_dropdown__content ${className}`
    }

    return <div {...props}>{children}</div>
  }
})

export default KBDropdownContent
