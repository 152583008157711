import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import { isListValue, getListFirstValue } from 'utils/kbUtil'

const menu = [
  {
    name: '优惠券详情',
    value: 'info'
  },
  {
    name: '领券明细',
    value: 'orders'
  },
]

var ActivitiesContainer = React.createClass({
  displayName: 'ActivitiesContainer',
  componentDidMount() {},
  render() {
    const { section, locationId, couponId, location } = this.props

    return (
      <div className="kb-out-box clear-fix" style={{ paddingTop: 0 }}>
        <section className="kb-content" style={{paddingTop: 0}}>
          <nav className="nav-container f-left">
            <div className="nav-container-content">
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">优惠券</h2>
              </header>
              <div className="nav-body">
                <ul className="nav-container-list">
                  {menu.map((data, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          location.pathname.endsWith(data.value)
                            ? 'nav-container-list-hover'
                            : ''
                        }
                      >
                        {locationId ? (
                          <Link
                            to={`/admin/locations/${locationId}/coupon/${couponId}/${getListFirstValue(
                              data.value
                            )}`}
                          >
                            {' '}
                            {data.name}{' '}
                          </Link>
                        ) : (
                          <Link
                            to={`/admin/coupon/${couponId}/${getListFirstValue(
                              data.value
                            )}`}
                          >
                            {' '}
                            {data.name}{' '}
                          </Link>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </nav>
          {this.props.children}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const {  params } = props
  const { couponId, id: locationId } = params

  let sections = props.location.pathname.split('/')
  let section = ''
  if (locationId) {
    section = sections.length > 5 ? sections[6] : ''
  } else {
    section = sections.length > 5 ? sections[6] : ''
  }
  return {
    locationId,
    couponId,
    section
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesContainer)
