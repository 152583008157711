import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import ReactDOM from 'react-dom'
import { api, apiUtils } from 'app/services'
import { leadsActions, apiCallFailure, successState } from 'app/actions'
import { connect } from 'react-redux'
import {
  KBLoadingContainer,
  KBPopover,
  KBPagination,
  KBDropdown
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { paginate, queryFilter, getFilterAndQuery } from 'utils/kbUtil'
import moment from 'moment'
import * as selectors from 'app/selectors'
import { CRM_LEADS_STATE, CRM_LEADS_STATE_ARRAY } from 'app/constants'
import { canAccessSpaceRoles } from 'app/reducers/role'
import LeadForm from './LeadForm'

var CrmOpportunityList = React.createClass({
  displayName: 'CrmOpportunityList',

  getInitialState() {
    const { filters } = this.props
    return {
      loading: false,
      filters,
      page: this.props.page || 1,
      queryFiltersStr: ''
    }
  },

  componentDidMount() {
    const { page } = this.props
    this.mounted = true
    this._loadData(page)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  // 加载数据
  _loadData(page, isfilters) {
    const { getLeadsActions, routerActions, query } = this.props
    let params = Object.assign({}, isfilters)
    let queryFiltersStr = queryFilter({ ...query, ...params, page: page })
    this.setState({
      loading: true
    })
    api
      .getLeads({ page: page, ...params })
      .then(
        json => {
          getLeadsActions.success(json, {
            entityName: 'leads'
          })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(`/admin/crm/leads${queryFiltersStr}`)
          let pagination = paginate(json.response)
          this.setState({
            pagination
          })
        },
        errors => {
          getLeadsActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (nextProps.page !== this.props.page) {
      this._loadData(nextProps.page, { ...this.state.filters, ...filters })
    }
  },

  updateLeadForm(lead) {
    KBPopover.show(
      <LeadForm
        initialValues={{
          title: lead.title,
          company: lead.company,
          phone: lead.phone,
          mobile: lead.mobile,
          state: lead.state
        }}
        lead_id={lead.id}
      />
    )
  },

  deleteLeadForm(id) {
    const { deleteLeadActions } = this.props

    KBPopoverConfirm({
      name: '删除线索',
      context: '是否确定要删除线索',
      callback: () => {
        return api.deleteLead(id).then(
          json => {
            deleteLeadActions.success(json, { id, key: 'leads' })
            KBPopover.close()
          },
          error => {
            deleteLeadActions.failure(error)
          }
        )
      }
    })
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters })

    // force refresh
    this._refresh(newFilters)
  },

  _refresh(newFilters) {
    this.refs['stateDropdown'] && this.refs['stateDropdown'].hide()
    this.refs['location_idDropdown'] && this.refs['location_idDropdown'].hide()
    setTimeout(this._loadData(1, newFilters))
  },

  _clearFilters() {
    this.setState({ filters: {} })
    this._refresh(null, {})
  },

  _renderFilters() {
    const { locations } = this.props
    return (
      <div className="nav-section-action-bar">
        <KBDropdown ref="location_idDropdown" style={{ marginRight: 20 }}>
          <KBDropdown.KBDropdownTrigger>
            <div className="task-status-title">
              <i className="iconfont icon-position" />
              <span>
                {this.state.filters.location_id &&
                Object.keys(locations).length > 0
                  ? locations[this.state.filters.location_id].name
                  : '选择分店'}
              </span>
              <span className="task-deg" />
            </div>
          </KBDropdown.KBDropdownTrigger>
          <KBDropdown.KBDropdownContent>
            <div>
              <ul className="task-type">
                {locations &&
                  Object.keys(locations).map((location_id, index) => {
                    let location = locations[location_id]
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          this._setFilter({ location_ids: location.id })
                        }
                      >
                        <span>{location.name}</span>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </KBDropdown.KBDropdownContent>
        </KBDropdown>
        <KBDropdown ref="stateDropdown">
          <KBDropdown.KBDropdownTrigger>
            <div className="task-status-title">
              <i className="iconfont icon-finance" />
              <span>
                {this.state.filters.state
                  ? CRM_LEADS_STATE[this.state.filters.state]
                  : '选择状态'}
              </span>
              <span className="task-deg" />
            </div>
          </KBDropdown.KBDropdownTrigger>
          <KBDropdown.KBDropdownContent>
            <div>
              <ul className="task-type">
                {CRM_LEADS_STATE_ARRAY.map((state, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => this._setFilter({ state: state.id })}
                    >
                      <span>{state.name}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
          </KBDropdown.KBDropdownContent>
        </KBDropdown>
      </div>
    )
  },

  render() {
    const { leads } = this.props
    const { pagination, loading } = this.state

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header border-bottom">
          <div className="nav-section-header-title">
            <span>线索列表</span>
          </div>
        </header>
        <div className="nav-section-content-container">
          <KBLoadingContainer loading={loading}>
            <div className="m-updown">
              {this._renderFilters()}
              {Object.keys(this.state.filters).length > 0 ? (
                <div
                  className="clear-criteria"
                  style={{ marginTop: 5 }}
                  onClick={this._clearFilters}
                >
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              ) : null}
            </div>
            <table className="content-table edit-table">
              <thead>
                <tr>
                  <th>姓名</th>
                  <th>状态</th>
                  <th>公司</th>
                  <th>电话</th>
                  <th>参观分店</th>
                  <th>参观人数</th>
                  <th>日期</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {leads.length > 0 &&
                  leads.map((lead, index) => {
                    return (
                      <tr key={index}>
                        <td>{lead.title}</td>
                        <td>{CRM_LEADS_STATE[lead.state]}</td>
                        <td>{lead.company}</td>
                        <td>{lead.mobile}</td>
                        <td>
                          {lead.locations[0] ? lead.locations[0].name : '无'}
                        </td>
                        <td>{lead.employee_number || '无'}</td>
                        <td>{moment(lead.created_at).format('YYYY-MM-DD')}</td>
                        <td>
                          <i
                            className="iconfont icon-edit m-right-sm"
                            onClick={this.updateLeadForm.bind(null, lead)}
                          />
                          <i
                            className="iconfont icon-delete"
                            onClick={this.deleteLeadForm.bind(null, lead.id)}
                          />
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            <KBPagination
              pagination={pagination}
              template={`/admin/crm/leads?page=#PAGE#`}
            />
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let leads = selectors.getLeads(state)
  let locations = selectors.getSerializeLocationObj(state)
  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page
  const FILTER_STATE = ['state', 'location_ids']
  var params = getFilterAndQuery(location.query, FILTER_STATE)
  return {
    leads,
    page,
    locations,
    ...params
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLeadsActions: bindActionCreators(leadsActions.replace, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    deleteLeadActions: bindActionCreators(leadsActions.delete, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrmOpportunityList)
