import DeskNumberComponent from './DeskNumber'
import LogoCropperFormComponent from './LogoCropperForm'
import PopoverShowPictureComponent from './PopoverShowPicture'
import PopoverShowVideoComponent from './PopoverShowVideo'
import FromToDropdownComponent from './FromToDropdown'
import KBDayPickerComponent from './KBDayPicker'
import KBSelectComponent from './KBSelect'
import KBASNumberInputComponent from './KBASNumberInput'

export const DeskNumber = DeskNumberComponent
export const LogoCropperForm = LogoCropperFormComponent
export const PopoverShowPicture = PopoverShowPictureComponent
export const PopoverShowVideo = PopoverShowVideoComponent
export const FromToDropdown = FromToDropdownComponent
export const KBDayPicker = KBDayPickerComponent
export const KBSelect = KBSelectComponent
export const KBASNumberInput = KBASNumberInputComponent
