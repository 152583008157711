import { Highcharts } from 'utils/kbData'

const InitialHighCharts = {
  renderChart(type = 'Chart', config) {
    var elementName = this.state.elementName || 'highcharts'
    if (!config) {
      throw new Error('Config must be specified for the Chart component')
    }
    let chartConfig = config.chart
    this.chart = new Highcharts[type](
      {
        ...config,
        chart: {
          ...chartConfig,
          renderTo: this.refs[elementName]
        }
      },
      this.props.callback
    )
    if (this.chart.title && !this.chart.title.textStr) {
      this.chart.title.textStr = this.chart.title.text || 'chart'
    }
  },
  getHighChartColor() {
    return Highcharts.getOptions().colors
  },
  getHighcharts() {
    return Highcharts
  },
  componentWillUnmount() {
    this.isChart() && this.chart.destroy()
  },
  updateSeriesDate(data = [], index = 0) {
    if (!this.isChart()) {
      return
    }

    this.chart.series[index].setData(data, true)
  },

  print() {
    this.chart.print()
  },

  exportingExcel() {
    this.chart.downloadCSV()
  },

  setCategories(array, type = 'xAxis', index = 0) {
    if (!this.isChart()) {
      return
    }

    this.chart[type][index].setCategories(array)
  },

  isChart() {
    var chart = this.chart

    if (chart && Object.keys(chart).length) {
      return true
    }

    return false
  },

  clearSerierDate() {
    var len = (this.chart.series && this.chart.series.length) || 0

    if (len) {
      for (let i = 0; i < len; i++) {
        this.chart.series[0].remove()
      }
    }
  },

  setSerierDate(data) {
    if (!this.isChart()) {
      return
    }

    var len = (this.chart.series && this.chart.series.length) || 0

    if (!data.length) {
      return
    }

    if (len) {
      for (let i = 0; i < len; i++) {
        this.chart.series[0].remove()
      }
    }

    for (let i = 0; i < data.length; i++) {
      this.chart.addSeries(data[i])
    }
  }
}

export default InitialHighCharts
