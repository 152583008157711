export const allStatus = [
  { label: '已过期', value: 'expired' },
  { label: '已上架', value: 'up' },
  { label: '已下架', value: 'down' },
  { label: '草稿', value: 'draft' }
]

export const useStatus = [
  { label: '未使用', value: 'active' },
  { label: '已使用', value: 'used' },
  { label: '已过期', value: 'expired' },
]

export const allTypes = [
  { title: '会议室', value: 'meeting_room' },
  { title: '工位', value: 'desk' }
]

export const displayTypes = [
  { title: '是', value: true },
  { title: '否', value: false }
]

export const priceOptions = [{ title: '免费', value: 0 }]

export const userTypes = [{ title: '所有人', value: 'all_user' }]
