import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { successState, invoicesActions } from 'app/actions'
import { routerActions } from 'react-router-redux'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { KBInput, KBPopover, KBButton, KBMorePhotoUpload } from 'components'
import intl from 'react-intl-universal'
import { callbackify } from 'util'

var InvoicesDeleteForm = React.createClass({
  rejectSubmit(model) {
    const {
      id,
      deleteInvoicesActions,
      routerActions,
      updateInvoicesActions
    } = this.props
    return api.deleteInvoice(id, model).then(
      json => {
        updateInvoicesActions.success(json)
        KBPopover.close()
      },
      error => {
        updateInvoicesActions.failure(error)
        KBPopover.close()
      }
    )
  },

  render() {
    const {
      fields: { description, files },
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.rejectSubmit)}>
        <header className="kb-form-header">
          <div className="header-title">删除账单</div>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        {/* 财务审批人-- */}
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              title={intl.get(
                'meeting.meeting_application_reject_form.label.reason'
              )}
              title="原因"
              isRequired={true}
              className="kb-form-c18 kb-form-group kb-form-alone"
              field={description}
            />
          </div>
          {/* 附件-- */}
          <div className="kb-row">
            <KBMorePhotoUpload
              title="上传附件"
              hint="允许多张上传,格式为png或jpg"
              photoShow={true}
              className="kb-form-group kb-form-210 kb-form-alone"
              field={files}
              text="点击上传附件"
              prefix="images/organization/"
              accept=".png,.jpg"
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    successActions: bindActionCreators(successState, dispatch),
    deleteInvoicesActions: bindActionCreators(invoicesActions.delete, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.description)) {
    errors.description = '删除原因不能为空'
  }

  return errors
}

const formConfig = {
  form: 'InvoicesDeleteForm',
  fields: ['description', 'files'],
  validate: validate,
  touchOnBlur: false
}

InvoicesDeleteForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoicesDeleteForm)

export default InvoicesDeleteForm
