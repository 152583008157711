import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as actions from 'app/actions'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBDateInput,
  KBTextarea,
  KBSelect,
  KBTipsy,
  KBButton,
  KBPopover,
  KBInput,
  KBUserInput,
  KBDropDownListInput
} from 'components'
import * as selectors from 'app/selectors'
import {
  CRM_OPPORTUNITY_STATES,
  CRM_SOURCE_TYPES,
  CRM_LEVEL_TYPES,
  CRM_CUSTOMER_TYPES,
  CRM_GENDER_TYPES,
  CRM_AREA_TYPE,
  CRM_COMPANY_TYPE,
  CRM_DESIRE,
  CRM_DESIRE_OBJ,
  CRM_EMPLOYEE_NUMBER,
  REQUIREMENT_TYPE,
  CRM_INTENT_RENT_AREA_SIZE,
  CRM_NODE_TYPES,
  CRM_VISIT_TYPE,
  CRM_TIME_NUMBER
} from 'app/constants'
import { ADD_CLIENT } from 'app/constants/hint'
import {
  checkObject,
  formatYearDayEN,
  listIsNullCallBack,
  formatHoursMinutesCHS
} from 'utils/kbUtil'
import _ from 'lodash'
import CrmNewRecordForm from './CrmNewRecordForm'
import moment from 'moment'
import { DateUtils } from 'react-day-picker'

const STEPS = [
  '1 填写客户信息',
  '2 填写公司信息',
  '3 填写客户意向',
  '4 选择客户状态',
  '5 选择销售团队'
]
const START_INDEX = 1
const END_INDEX = 5

const CUSTOMER_TYPE = [
  {
    id: 'customer',
    name: '直接客户'
  },
  {
    id: 'agent',
    name: '代理人'
  }
]

var CrmNewOpportunityForm = React.createClass({
  getInitialState() {
    return {
      current_index: START_INDEX,
      customer_type: 'customer',
      rentIntentType: this.props.fields.requirement_type.initialValue || [],
      deskType: this.props.fields.area_types.initialValue || [],
      selectedRentType: 'by_area',
      salesRecordForm: null,
      receiver: null,
      oppsWithSamePhoneNum: [],
      selectedLocations: null
    }
  },

  componentDidMount() {
    const {
      fields: {
        employee_number,
        rentable_area,
        employee_months,
        rent_type,
        interest_locations_visit_date,
        visit_time_hour,
        visit_time_minute,
        state
      },
      employeeNumbersArry,
      employee_monthsArray
    } = this.props

    employee_number.onChange(employeeNumbersArry[0].id)
    rentable_area.onChange(CRM_INTENT_RENT_AREA_SIZE[0].id)
    employee_months.onChange(employee_monthsArray[0].id)
    rent_type.onChange('by_area')
    interest_locations_visit_date.onChange(new Date())
    visit_time_hour.onChange('08')
    visit_time_minute.onChange('00')
    state.onChange('preliminary')
    this._loadIndustries()
  },

  _loadIndustries() {
    const { allIndustries, industries } = this.props

    listIsNullCallBack(industries, () => {
      api.getIndustries().then(
        json => {
          allIndustries.success(json)
        },
        errors => {
          allIndustries.failure(errors)
        }
      )
    })
  },

  creatOpportunity(model) {
    const {
      createOpportunity,
      apiCallFailureAction,
      callback,
      successAction,
      routerAction
    } = this.props
    const { selectedRentType, salesRecordForm } = this.state
    if (model.industry_id == -1) {
      delete model.industry_id
    }

    if (selectedRentType == 'by_area') {
      delete model.employee_number
    } else if (selectedRentType == 'by_desk') {
      delete model.rentable_area
    }

    model.content = model.content + 'SEPARATOR' + model.content_compete
    delete model.content_compete
    delete model.interest_locations_location_id
    delete model.interest_locations_notes
    delete model.interest_locations_receiver_id
    delete model.interest_locations_visit_date

    model.for_merge = false

    return api.createOpportunity(model).then(
      json => {
        const result_id = json.json.result
        createOpportunity.success(json, { key: 'opportunities' })
        successAction({ message: '客户添加成功！' })
        routerAction.push(`/admin/crm/opportunities/${result_id}`)
        KBPopover.close()
        return true
      },
      error => {
        createOpportunity.failure(error)
      }
    )
  },

  preStep() {
    var current_index = this.state.current_index - 1

    if (current_index < START_INDEX) {
      current_index = START_INDEX
    }

    this.setState({
      current_index
    })
  },

  validateSingleField(field, message) {
    const { apiCallFailureAction } = this.props
    if (!field.value) {
      apiCallFailureAction({
        status: 'error',
        message
      })
      return false
    }
    return true
  },

  validdateContactsInCustomerInfo() {
    const {
      fields: { mobile, wechat, agent_mobile, email },
      apiCallFailureAction
    } = this.props

    if (!mobile.value && !wechat.value && !agent_mobile.value) {
      apiCallFailureAction({
        status: 'error',
        message: '电话，微信，代理人电话至少填写一项'
      })
      return false
    }
    if (
      (mobile.value && valid.commonPhoneNumber(mobile.value)) ||
      (agent_mobile.value && valid.commonPhoneNumber(agent_mobile.value))
    ) {
      apiCallFailureAction({
        status: 'error',
        message: '请填写正确的电话'
      })
      return false
    }

    if (email.value && valid.email(email.value)) {
      apiCallFailureAction({
        status: 'error',
        message: '请填写正确的邮箱'
      })
      return false
    }

    return true
  },

  validateCustomerInfo() {
    const {
      fields: { name, contact_name }
    } = this.props

    return (
      this.validateSingleField(name, '请输入客户名称') &&
      this.validateSingleField(
        contact_name,
        `请填写${this.state.customer_type == 'agent' ? '代理人' : '联系人'}`
      ) &&
      this.validdateContactsInCustomerInfo()
    )
  },

  validatecustomerIntention() {
    const {
      fields: { source }
    } = this.props

    return this.validateSingleField(source, '请选择客户来源')
  },

  validatecustomerStatus() {
    const {
      fields: { state, content }
    } = this.props

    return (
      this.validateSingleField(state, '请选择客户状态') &&
      this.validateSingleField(content, '请填写销售记录')
    )
  },

  validateFileds(index) {
    switch (index) {
      case 1:
        return this.validateCustomerInfo()
        break
      case 3:
        return this.validatecustomerIntention()
        break
      case 4:
        return this.validatecustomerStatus()
        break

      default:
        return true
    }
  },

  nextStep() {
    const {
      fields: { mobile }
    } = this.props
    const { oppsWithSamePhoneNum } = this.state

    let filedsIsValid = this.validateFileds(this.state.current_index)
    if (!filedsIsValid) {
      return
    }

    if (
      this.state.current_index == 1 &&
      mobile.value &&
      !oppsWithSamePhoneNum.length
    ) {
      return this.checkCustomerPhoneNum()
    }

    var current_index = this.state.current_index + 1

    if (current_index > END_INDEX) {
      current_index = END_INDEX
    }

    this.setState({
      current_index
    })
  },

  checkCustomerPhoneNum() {
    const {
      fields: { mobile }
    } = this.props
    let params = { phone: mobile.value, per_page: 1000 }

    api.getOpportunitiesByPhoneNum(params).then(json => {
      let oppsWithSamePhoneNum = (json.response && json.response.body) || []
      if (!oppsWithSamePhoneNum.length) {
        this.setState({
          current_index: 2
        })
        return
      }
      this.setState({
        oppsWithSamePhoneNum
      })
    })
  },

  changeCustomerType(type) {
    const {
      fields: { mobile, wechat, agent_mobile }
    } = this.props
    if (type == 'customer') {
      agent_mobile.onChange('')
    }
    if (type == 'agent') {
      mobile.onChange('')
      wechat.onChange('')
    }
    this.setState({
      customer_type: type
    })
  },

  customerInfoJsx() {
    const {
      fields: { name, contact_name, mobile, wechat, email, agent_mobile }
    } = this.props
    const { customer_type, oppsWithSamePhoneNum } = this.state
    const oneClass = { className: 'kb-form-group kb-form-c18 kb-form-alone' }
    const halfClass = { className: 'kb-form-group kb-div-c39' }

    if (oppsWithSamePhoneNum.length) {
      return (
        <div className="crm-opps-with-same-phone-number">
          <div className="tip-msg">
            <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/mirro@2x.png" />
            <span>您填写的信息与下列客户信息有重复</span>
          </div>
          <div className="opps-container">
            {oppsWithSamePhoneNum.map(opp => {
              let leaderArr =
                opp.sales_team.find(team => {
                  return team.is_leader
                }) || {}
              let leader = leaderArr.sales_user || {}
              return (
                <div className="opp-block">
                  <div>
                    <span>客户名称</span>
                    <Link
                      className="a-link color-link"
                      to={`/admin/crm/opportunities/${opp.id}`}
                    >
                      {opp.title || ''}
                    </Link>
                  </div>
                  <div>
                    <span>手机号码</span>
                    <span>{(opp.contact && opp.contact.mobile) || ''}</span>
                  </div>
                  <div>
                    <span>创建时间</span>
                    <span>
                      {(opp.created_at && formatYearDayEN(opp.created_at)) ||
                        ''}
                    </span>
                  </div>
                  <div>
                    <span>负责人</span>
                    <span>{leader.name || ''}</span>
                  </div>
                </div>
              )
            })}
            <div className="opp-block">
              <div className="continue-add-new">
                <span
                  className="add-icon-con"
                  onClick={() => {
                    this.nextStep()
                    this.setState({ oppsWithSamePhoneNum: [] })
                  }}
                >
                  <i className="iconfont icon-add" />
                </span>
              </div>
              <div className="continue-add-msg">继续添加为新客户</div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="kb-form-container" style={{ paddingBottom: 95 }}>
        <div className="kb-row kb-form-66">
          <KBInput
            isRequired={true}
            title="客户名称"
            {...halfClass}
            field={name}
          />
        </div>
        <div className="kb-row kb-form-66">
          <div {...halfClass}>
            <label>客户类型</label>
            <div>
              <div className="kb-form-group r1_radios">
                {CUSTOMER_TYPE.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="kb-form-group r1_radio"
                      onClick={this.changeCustomerType.bind(null, data.id)}
                    >
                      <div
                        className={`radio-btn ${
                          customer_type == data.id ? 'radio-btn-active' : ''
                        }`}
                      ></div>
                      <label className="label">{data.name}</label>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        {customer_type == 'customer' ? (
          <div className="kb-row kb-form-66">
            <KBInput
              title="联系人"
              {...halfClass}
              field={contact_name}
              isRequired={true}
            />
            <KBInput
              title="电话"
              {...halfClass}
              titleClass="label-rt"
              field={mobile}
            />
          </div>
        ) : null}
        {customer_type == 'customer' ? (
          <div className="kb-row kb-form-66">
            <KBInput title="微信" {...halfClass} field={wechat} />
            <KBInput
              title="邮箱"
              {...halfClass}
              titleClass="label-rt"
              field={email}
            />
          </div>
        ) : null}
        {customer_type == 'agent' ? (
          <div className="kb-row kb-form-66">
            <KBInput
              title="代理人"
              {...halfClass}
              field={contact_name}
              isRequired={true}
            />
            <KBInput
              title="代理人电话"
              {...halfClass}
              field={agent_mobile}
              titleClass="label-rt"
            />
          </div>
        ) : null}
      </div>
    )
  },

  organizationInfoJsx() {
    const {
      fields: { company, industry_id, company_type },
      industries
    } = this.props
    const oneClass = { className: 'kb-form-group kb-form-c18 kb-form-alone' }
    const halfClass = { className: 'kb-form-group kb-div-c39' }

    return (
      <div className="kb-form-container" style={{ paddingBottom: 95 }}>
        <div className="kb-row">
          <KBInput title="公司名称" {...oneClass} field={company} />
        </div>
        <div className="kb-row kb-form-66">
          <KBSelect
            title="行业"
            defaultValue="-1"
            {...halfClass}
            field={industry_id}
            arrayData={industries}
          />
          <KBSelect
            title="公司类型"
            {...halfClass}
            field={company_type}
            arrayData={CRM_COMPANY_TYPE}
            titleClass="label-rt"
          />
        </div>
      </div>
    )
  },

  selectRentIntentType(type) {
    const {
      fields: { requirement_type }
    } = this.props
    const { rentIntentType } = this.state
    let newTypes = rentIntentType
    if (rentIntentType.includes(type)) {
      newTypes.splice(
        newTypes.findIndex(ele => {
          return ele == type
        }),
        1
      )
    } else {
      newTypes.push(type)
    }
    requirement_type.onChange(newTypes.toString())
    this.setState({
      rentIntentType: newTypes
    })
  },

  selectDeskType(type) {
    const {
      fields: { area_types }
    } = this.props
    const { deskType } = this.state
    let newTypes = deskType
    if (deskType.includes(type)) {
      newTypes.splice(
        newTypes.findIndex(ele => {
          return ele == type
        }),
        1
      )
    } else {
      newTypes.push(type)
    }
    area_types.onChange(newTypes.toString())
    this.setState({
      deskType: newTypes
    })
  },

  changeRentType(type) {
    const {
      fields: { rent_type }
    } = this.props

    rent_type.onChange(type)
    this.setState({
      selectedRentType: type
    })
  },

  changeCustomerDesire(type) {
    const {
      fields: { desire }
    } = this.props
    desire.onChange(type)
  },

  changeCustomerSource(source_type) {
    const {
      fields: { source }
    } = this.props
    source.onChange(source_type)
  },

  onUserSelected(value) {
    let selectedLocations = value
    this.setState({
      selectedLocations
    })
  },

  customerIntentionJsx() {
    const {
      fields: {
        employee_number,
        rentable_area,
        employee_months,
        area_capacity,
        requirement_text,
        location_ids,
        desire,
        source,
        source_text
      },
      employeeNumbersArry,
      employee_monthsArray,
      userSpaceId,
      sources,
      oppLocations
    } = this.props
    const {
      rentIntentType,
      deskType,
      selectedRentType,
      selectedLocations
    } = this.state
    const rentType = [
      {
        id: 'by_area',
        name: '按面积租'
      },
      {
        id: 'by_desk',
        name: '按工位租'
      }
    ]
    const oneClass = { className: 'kb-form-group kb-form-c18 kb-form-alone' }
    const halfClass = { className: 'kb-form-group kb-div-c39' }

    return (
      <div className="kb-form-container" style={{ paddingBottom: 95 }}>
        <div className="kb-form-group kb-form-c18 kb-form-alone">
          <label>客户意向</label>
          <div className="">
            <div className="f-left tabs">
              <div className="f-left" style={{ width: '100%' }}>
                <label className="f-left intentions">
                  <input
                    className="chk_1"
                    type="checkbox"
                    checked={
                      rentIntentType.includes(REQUIREMENT_TYPE[0].id)
                        ? true
                        : false
                    }
                    style={{ display: 'none' }}
                    onClick={this.selectRentIntentType.bind(
                      null,
                      REQUIREMENT_TYPE[0].id
                    )}
                  />
                  <span
                    className="f-left color-nine"
                    style={{ marginTop: 1 }}
                  />
                  <span className="f-left title">
                    {REQUIREMENT_TYPE[0].name}
                  </span>
                </label>
                <div
                  className={
                    rentIntentType.includes(REQUIREMENT_TYPE[0].id)
                      ? 'f-left content'
                      : ''
                  }
                >
                  {rentIntentType.includes(REQUIREMENT_TYPE[0].id) ? (
                    <div className="kb-row">
                      <label>办公区类型</label>
                      {CRM_AREA_TYPE.slice(0, 2).map(type => {
                        return (
                          <label className="desk-types">
                            <input
                              className="f-left chk_1"
                              type="checkbox"
                              checked={
                                deskType.includes(type.id) ? true : false
                              }
                              style={{ display: 'none' }}
                              onClick={this.selectDeskType.bind(null, type.id)}
                            />
                            <span
                              className="f-left color-nine"
                              style={{ marginTop: 1 }}
                            />
                            <span className="title">{type.name}</span>
                          </label>
                        )
                      })}
                    </div>
                  ) : null}
                  {rentIntentType.includes(REQUIREMENT_TYPE[0].id) ? (
                    <div className="kb-row">
                      <label style={{ display: 'inline-block', width: 56 }} />
                      {rentType &&
                        rentType.map(data => {
                          return (
                            <span
                              onClick={this.changeRentType.bind(null, data.id)}
                              style={{ marginRight: 20 }}
                            >
                              <span
                                className={`radio ${
                                  selectedRentType == data.id
                                    ? 'radio-active'
                                    : ''
                                }`}
                              ></span>
                              <span
                                style={{ color: '#666', cursor: 'pointer' }}
                              >
                                {data.name}
                              </span>
                            </span>
                          )
                        })}
                    </div>
                  ) : null}
                  {rentIntentType.includes(REQUIREMENT_TYPE[0].id) ? (
                    <div className="kb-row-s">
                      {selectedRentType == rentType[1].id ? (
                        <KBSelect
                          defaultValue="-1"
                          title="入驻人数"
                          field={employee_number}
                          arrayData={employeeNumbersArry}
                        />
                      ) : null}
                      {selectedRentType == rentType[0].id ? (
                        <KBSelect
                          defaultValue="-1"
                          title="租赁面积"
                          field={rentable_area}
                          arrayData={CRM_INTENT_RENT_AREA_SIZE}
                        />
                      ) : null}
                      <KBSelect
                        defaultValue="-1"
                        title="入驻月数"
                        field={employee_months}
                        arrayData={employee_monthsArray}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="f-left" style={{ width: '100%' }}>
                <label className="f-left intentions">
                  <input
                    className="chk_1"
                    type="checkbox"
                    checked={
                      rentIntentType.includes(REQUIREMENT_TYPE[1].id)
                        ? true
                        : false
                    }
                    style={{ display: 'none' }}
                    onClick={this.selectRentIntentType.bind(
                      null,
                      REQUIREMENT_TYPE[1].id
                    )}
                  />
                  <span
                    className="f-left color-nine"
                    style={{ marginTop: 1 }}
                  />
                  <span className="f-left title">
                    {REQUIREMENT_TYPE[1].name}
                  </span>
                </label>
                <div
                  className={
                    rentIntentType.includes(REQUIREMENT_TYPE[1].id)
                      ? 'f-left content'
                      : ''
                  }
                >
                  {rentIntentType.includes(REQUIREMENT_TYPE[1].id) ? (
                    <div className="kb-row-m">
                      <KBSelect
                        defaultValue="-1"
                        title="容纳人数"
                        field={area_capacity}
                        arrayData={employeeNumbersArry}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="f-left" style={{ width: '100%' }}>
                <label className="f-left intentions">
                  <input
                    className="chk_1"
                    type="checkbox"
                    checked={
                      rentIntentType.includes(REQUIREMENT_TYPE[2].id)
                        ? true
                        : false
                    }
                    style={{ display: 'none' }}
                    onClick={this.selectRentIntentType.bind(
                      null,
                      REQUIREMENT_TYPE[2].id
                    )}
                  />
                  <span
                    className="f-left color-nine"
                    style={{ marginTop: 1 }}
                  />
                  <span className="f-left title">
                    {REQUIREMENT_TYPE[2].name}
                  </span>
                </label>
                <div
                  className={
                    rentIntentType.includes(REQUIREMENT_TYPE[2].id)
                      ? 'f-left content'
                      : ''
                  }
                >
                  {rentIntentType.includes(REQUIREMENT_TYPE[2].id) ? (
                    <div className="kb-row-e">
                      <KBTextarea
                        className="kb-form-group kb-form-c18 kb-form-alone"
                        field={requirement_text}
                        title="具体信息"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='kb-row'>
            <KBUserInput title='意向分店'  {...oneClass} placeholder='选择感兴趣的分店' field={location_ids} multi={true} users={ this.props.locations } selectedUsers={ selectedLocations || oppLocations } callback={ this.onUserSelected } />
          </div> */}
        <div className="kb-row">
          <div {...oneClass}>
            <label>入驻意愿</label>
            <div>
              <div className="kb-form-group r1_radios">
                {CRM_DESIRE.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="kb-form-group r1_radio"
                      onClick={this.changeCustomerDesire.bind(null, data.id)}
                    >
                      <div
                        className={`radio-btn ${
                          desire.value == data.id ? 'radio-btn-active' : ''
                        }`}
                      ></div>
                      <label className="label">{data.name}</label>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="kb-row">
          <div {...oneClass}>
            <label>
              客户来源<span className="must-fill">*</span>
            </label>
            <div>
              <div className="kb-form-group r1_radios">
                {sources.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="kb-form-group r1_radio"
                      onClick={this.changeCustomerSource.bind(null, data.id)}
                    >
                      <div
                        className={`radio-btn ${
                          source.value == data.id ? 'radio-btn-active' : ''
                        }`}
                      ></div>
                      <label className="label">{data.name}</label>
                    </div>
                  )
                })}
              </div>
              {source.touched && source.error ? (
                <p className="lr-error">{source.error}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="kb-row">
          <KBInput
            isRequired={userSpaceId == 6 ? true : false}
            title="具体来源"
            {...oneClass}
            field={source_text}
          />
        </div>
      </div>
    )
  },

  chooseState(id, index) {
    const {
      fields: { state }
    } = this.props

    state.onChange(id)
    this.setState({
      selectIndex: index
    })
  },

  chooseNoteType(id) {
    const {
      fields: { note_type }
    } = this.props
    note_type.onChange(id)
  },

  chooseVisitType(id) {
    const {
      fields: { visit }
    } = this.props
    visit.onChange(id)
  },

  addVisitorGuider(user) {
    const {
      fields: { interest_locations_receiver_id }
    } = this.props
    let id = user.id || null

    interest_locations_receiver_id.onChange(id)
    this.setState({
      receiver: user
    })
  },

  cleanVisitGuider() {
    const {
      fields: { interest_locations_receiver_id }
    } = this.props

    interest_locations_receiver_id.onChange('')
    this.setState({
      receiver: null
    })
  },

  addVisitorRecord(type) {
    const {
      fields: {
        interest_locations,
        interest_locations_location_id,
        interest_locations_visit_date,
        interest_locations_receiver_id,
        interest_locations_notes,
        visit_time_hour,
        visit_time_minute
      },
      apiCallFailureAction
    } = this.props

    const LOC_ERR_MSG = {
      visited: '请选择已参观分店',
      reserved: '请选择预约参观分店'
    }
    const RECEIVER_ERR_MSG = {
      visited: '请选择带领参观人',
      reserved: '请选择负责人'
    }
    const TIME_ERR_MSG = {
      visited: '您已在当前时间参观过该分店，请重新选择分店或时间',
      reserved: '您已在当前时间预约过该分店，请重新选择分店或时间'
    }

    if (!interest_locations_location_id.value) {
      return apiCallFailureAction({
        status: 'error',
        message: LOC_ERR_MSG[type]
      })
    }
    if (!interest_locations_receiver_id.value) {
      return apiCallFailureAction({
        status: 'error',
        message: RECEIVER_ERR_MSG[type]
      })
    }

    let visit_date =
      moment(interest_locations_visit_date.value).format('YYYY-MM-DD') +
      ' ' +
      visit_time_hour.value +
      ':' +
      visit_time_minute.value

    let locationRepeated = false
    interest_locations.length &&
      interest_locations.map(record => {
        if (
          interest_locations_location_id.value == record.location_id.value &&
          visit_date == record.visit_date.value &&
          type == record.visit_state.value
        ) {
          locationRepeated = true
        }
      })
    if (locationRepeated) {
      return apiCallFailureAction({
        status: 'error',
        message: TIME_ERR_MSG[type]
      })
    }

    interest_locations.addField({
      location_id: interest_locations_location_id.value,
      visit_date,
      visit_state: type,
      receiver_id: interest_locations_receiver_id.value,
      notes: interest_locations_notes.value
    })

    setTimeout(() => {
      interest_locations_receiver_id.onChange('')
      interest_locations_notes.onChange('')
      this.setState({
        receiver: null
      })
    })
  },

  customerStatusJsx() {
    const {
      fields: {
        state,
        content,
        visit,
        visit_date,
        interest_locations,
        interest_locations_location_id,
        interest_locations_visit_date,
        interest_locations_receiver_id,
        interest_locations_notes,
        note_type,
        content_compete,
        next_follow_at,
        visit_time_hour,
        visit_time_minute
      },
      locations,
      users,
      locationsObj,
      usersObj,
      hourNumberArray,
      minuteNumberArray
    } = this.props
    const { selectIndex, receiver } = this.state

    const visit_ele_class = {
      className: 'kb-form-group kb-form-alone visit-input-ele'
    }
    const visit_half_class = {
      className: 'kb-form-group kb-form-66 visit-input-ele'
    }

    const CRM_OPPORTUNITY_STATES_CREATING = [
      {
        id: 'preliminary',
        name: '初步接洽',
        class: 'status-initial'
      },
      {
        id: 'plan',
        name: '方案报价',
        class: 'status-scheme'
      },
      {
        id: 'shelved',
        name: '搁置',
        class: 'status-shelved'
      }
    ]

    let currentStateIndex = CRM_OPPORTUNITY_STATES_CREATING.findIndex(
      json => json.id == state.value
    )

    let noteTypeLeft = 30
    let indexLeft = 100 * (selectIndex || currentStateIndex)
    noteTypeLeft = noteTypeLeft + indexLeft

    return (
      <div className="kb-form-container" style={{ paddingBottom: 95 }}>
        {/* <CrmNewRecordForm customer={ {state: state.value } } updateOppStateField={ this.updateOppStateField } saveSalesRecordToOpp={ this.saveSalesRecordToOpp } /> */}
        <form>
          <div className="kb-row">
            <div className={`kb-form-group kb-form-c18 kb-form-alone`}>
              <label style={{ color: '#4E4E4E' }}>
                选择客户状态<span className="must-fill">*</span>
              </label>
              <div>
                <div className="kb-form-group r1_radios">
                  {CRM_OPPORTUNITY_STATES_CREATING.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="kb-form-group r1_radio"
                        onClick={this.chooseState.bind(null, data.id, index)}
                      >
                        <div
                          className={`radio-btn ${
                            state.value == data.id ? 'radio-btn-active' : ''
                          }`}
                        ></div>
                        <label className="label">{data.name}</label>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          {state.value ? (
            <div className="flow-communicate">
              <div
                className="communicate-arrow"
                style={{
                  display: `${noteTypeLeft >= 30 ? 'inline-block' : 'none'}`,
                  left: noteTypeLeft + 'px'
                }}
              >
                <em />
                <span />
              </div>
              <div className="kb-row">
                <KBTextarea
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  title="销售记录"
                  placeholder="请填写销售记录"
                  field={content}
                />
              </div>
              <ul className="communicate-way">
                <label>沟通方式</label>
                {
                  <div className="communicate-way-container">
                    {CRM_NODE_TYPES.map((node_state, index) => {
                      return (
                        <li
                          key={index}
                          className={
                            node_state.id == note_type.value
                              ? 'communicate-active'
                              : ''
                          }
                          onClick={this.chooseNoteType.bind(
                            null,
                            node_state.id
                          )}
                        >
                          <span
                            className={`radio ${
                              node_state.id == note_type.value
                                ? 'radio-active'
                                : ''
                            }`}
                          ></span>
                          <a>{node_state.name}</a>
                        </li>
                      )
                    })}
                  </div>
                }
              </ul>

              <div className="visit-choice">
                <label>参观状态</label>
                {
                  <div className="visit-container">
                    {/* 已参观 */}
                    <div className="choices" style={{ width: '100%' }}>
                      <li
                        className={
                          CRM_VISIT_TYPE[0].id == visit.value
                            ? 'communicate-active'
                            : ''
                        }
                        onClick={this.chooseVisitType.bind(
                          null,
                          CRM_VISIT_TYPE[0].id
                        )}
                      >
                        <span
                          className={`radio ${
                            CRM_VISIT_TYPE[0].id == visit.value
                              ? 'radio-active'
                              : ''
                          }`}
                        ></span>
                        <a>{CRM_VISIT_TYPE[0].name}</a>
                      </li>
                      {CRM_VISIT_TYPE[0].id == visit.value ? (
                        <div className="visit-choice-container">
                          <div className="kb-row">
                            <KBUserInput
                              placeholder={'选择分店'}
                              {...visit_ele_class}
                              field={interest_locations_location_id}
                              multi={false}
                              users={locations}
                            />

                            <div {...visit_half_class} style={{ width: '60%' }}>
                              <KBDateInput
                                placeholder="选择参观时间"
                                field={interest_locations_visit_date}
                                className="kb-form-group kb-form-alone f-left"
                                style={{ marginRight: 20, width: 200 }}
                              />
                              <KBSelect
                                className="kb-form-group kb-form-alone f-left"
                                style={{
                                  float: 'left',
                                  width: 60,
                                  backgroundColor: '#fff'
                                }}
                                field={visit_time_hour}
                                arrayData={hourNumberArray}
                              />
                              <span style={{ float: 'left', padding: 9 }}>
                                :
                              </span>
                              <KBSelect
                                className="kb-form-group kb-form-alone f-left"
                                style={{
                                  float: 'left',
                                  width: 60,
                                  backgroundColor: '#fff'
                                }}
                                field={visit_time_minute}
                                arrayData={minuteNumberArray}
                              />
                            </div>
                          </div>

                          <div className="kb-row">
                            <div {...visit_ele_class}>
                              <div className="kb_dropdown">
                                {receiver ? (
                                  <div className="selected-guider">
                                    <span>
                                      <i
                                        className="iconfont icon-account_circle"
                                        style={{ marginRight: '7px' }}
                                      />
                                      {receiver.name}
                                    </span>
                                    <span
                                      onClick={this.cleanVisitGuider}
                                      style={{
                                        float: 'right',
                                        cursor: 'pointer',
                                        fontSize: '0.9rem',
                                        color: '#999'
                                      }}
                                    >
                                      <i className="iconfont icon-quit" />
                                      重选
                                    </span>
                                  </div>
                                ) : (
                                  <KBDropDownListInput
                                    data={users}
                                    placeholder={'选择带领参观人'}
                                    selectCallBack={this.addVisitorGuider}
                                    styleWidth={'100%'}
                                    filterIdArray={[].map(json => json.id)}
                                    query={['name', 'name_pinyin']}
                                  />
                                )}
                              </div>
                            </div>

                            <KBInput
                              placeholder="备注"
                              field={interest_locations_notes}
                              className="kb-form-group kb-form-alone visit-input-ele-input"
                            />
                            <a
                              className="bordered-btn"
                              style={{ display: 'inline-block', float: 'left' }}
                              onClick={this.addVisitorRecord.bind(
                                null,
                                'visited'
                              )}
                            >{`添加${
                              interest_locations && interest_locations.length
                                ? '下一个'
                                : ''
                            }`}</a>
                          </div>

                          {interest_locations && interest_locations.length ? (
                            <div className="kb-row" style={{ marginBottom: 0 }}>
                              {interest_locations.map((record, index) => {
                                let visit_loc_id =
                                  (record.location_id &&
                                    record.location_id.value) ||
                                  ''
                                let visit_receiver_id =
                                  (record.receiver_id &&
                                    record.receiver_id.value) ||
                                  ''
                                let loc_name = locationsObj[visit_loc_id].name
                                if (record.visit_state.value != 'visited') {
                                  return null
                                }
                                return (
                                  <div className="visit-record">
                                    <div className="t-over">
                                      <KBTipsy content={loc_name}>
                                        {visit_loc_id ? (
                                          <Link
                                            className="a-link"
                                            to={`/admin/locations/${visit_loc_id}`}
                                          >
                                            {loc_name}
                                          </Link>
                                        ) : null}
                                      </KBTipsy>
                                    </div>
                                    <span className="visit-receiver">
                                      带领人：
                                      <span style={{ color: '#333333' }}>
                                        {(usersObj[visit_receiver_id] &&
                                          usersObj[visit_receiver_id].name) ||
                                          '-'}
                                      </span>
                                    </span>
                                    <span className="visit-receiver">
                                      时间：
                                      <span style={{ color: '#333333' }}>
                                        {(record.visit_date.value &&
                                          formatHoursMinutesCHS(
                                            record.visit_date.value
                                          )) ||
                                          '-'}
                                      </span>
                                    </span>
                                    <i
                                      className="iconfont icon-close"
                                      onClick={() => {
                                        interest_locations.removeField(index)
                                      }}
                                    />
                                  </div>
                                )
                              })}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                    {/* 预约参观 */}
                    <div className="choices" style={{ width: '100%' }}>
                      <li
                        className={
                          CRM_VISIT_TYPE[1].id == visit.value
                            ? 'communicate-active'
                            : ''
                        }
                        onClick={this.chooseVisitType.bind(
                          null,
                          CRM_VISIT_TYPE[1].id
                        )}
                      >
                        <span
                          className={`radio ${
                            CRM_VISIT_TYPE[1].id == visit.value
                              ? 'radio-active'
                              : ''
                          }`}
                        ></span>
                        <a>{CRM_VISIT_TYPE[1].name}</a>
                      </li>
                      {CRM_VISIT_TYPE[1].id == visit.value ? (
                        <div className="visit-choice-container">
                          <div className="kb-row">
                            <KBUserInput
                              placeholder={'选择分店'}
                              {...visit_ele_class}
                              field={interest_locations_location_id}
                              multi={false}
                              users={locations}
                            />

                            <div {...visit_half_class} style={{ width: '60%' }}>
                              <KBDateInput
                                placeholder="选择预约时间"
                                field={interest_locations_visit_date}
                                className="kb-form-group kb-form-alone f-left"
                                style={{ marginRight: 20, width: 200 }}
                              />
                              <KBSelect
                                className="kb-form-group kb-form-alone f-left"
                                style={{
                                  float: 'left',
                                  width: 60,
                                  backgroundColor: '#fff'
                                }}
                                field={visit_time_hour}
                                arrayData={hourNumberArray}
                              />
                              <span style={{ float: 'left', padding: 9 }}>
                                :
                              </span>
                              <KBSelect
                                className="kb-form-group kb-form-alone f-left"
                                style={{
                                  float: 'left',
                                  width: 60,
                                  backgroundColor: '#fff'
                                }}
                                field={visit_time_minute}
                                arrayData={minuteNumberArray}
                              />
                            </div>
                          </div>

                          <div className="kb-row">
                            <div {...visit_ele_class}>
                              <div className="kb_dropdown">
                                {receiver ? (
                                  <div className="selected-guider">
                                    <span>
                                      <i
                                        className="iconfont icon-account_circle"
                                        style={{ marginRight: '7px' }}
                                      />
                                      {receiver.name}
                                    </span>
                                    <span
                                      onClick={this.cleanVisitGuider}
                                      style={{
                                        float: 'right',
                                        cursor: 'pointer',
                                        fontSize: '0.9rem',
                                        color: '#999'
                                      }}
                                    >
                                      <i className="iconfont icon-quit" />
                                      重选
                                    </span>
                                  </div>
                                ) : (
                                  <KBDropDownListInput
                                    data={users}
                                    placeholder={'选择负责人'}
                                    selectCallBack={this.addVisitorGuider}
                                    styleWidth={'100%'}
                                    filterIdArray={[].map(json => json.id)}
                                    query={['name', 'name_pinyin']}
                                  />
                                )}
                              </div>
                            </div>
                            <KBInput
                              placeholder="备注"
                              field={interest_locations_notes}
                              className="kb-form-group kb-form-alone visit-input-ele-input"
                            />
                            <span
                              className="bordered-btn"
                              style={{ display: 'inline-block', float: 'left' }}
                              onClick={this.addVisitorRecord.bind(
                                null,
                                'reserved'
                              )}
                            >{`添加${
                              interest_locations && interest_locations.length
                                ? '下一个'
                                : ''
                            }`}</span>
                          </div>

                          {interest_locations && interest_locations.length ? (
                            <div className="kb-row" style={{ marginBottom: 0 }}>
                              {interest_locations.map((record, index) => {
                                let visit_loc_id =
                                  (record.location_id &&
                                    record.location_id.value) ||
                                  ''
                                let visit_receiver_id =
                                  (record.receiver_id &&
                                    record.receiver_id.value) ||
                                  ''
                                let loc_name = locationsObj[visit_loc_id].name
                                if (record.visit_state.value != 'reserved') {
                                  return null
                                }
                                return (
                                  <div className="visit-record">
                                    <div className="t-over">
                                      <KBTipsy content={loc_name}>
                                        {visit_loc_id ? (
                                          <Link
                                            className="a-link"
                                            to={`/admin/locations/${visit_loc_id}`}
                                          >
                                            {loc_name}
                                          </Link>
                                        ) : null}
                                      </KBTipsy>
                                    </div>
                                    <span className="visit-receiver">
                                      负责人：
                                      <span style={{ color: '#333333' }}>
                                        {(usersObj[visit_receiver_id] &&
                                          usersObj[visit_receiver_id].name) ||
                                          '-'}
                                      </span>
                                    </span>
                                    <span className="visit-receiver">
                                      时间：
                                      <span style={{ color: '#333333' }}>
                                        {(record.visit_date.value &&
                                          formatHoursMinutesCHS(
                                            record.visit_date.value
                                          )) ||
                                          '-'}
                                      </span>
                                    </span>
                                    <i
                                      className="iconfont icon-close"
                                      onClick={() => {
                                        interest_locations.removeField(index)
                                        record.id &&
                                          this.deleteVisitRecord(
                                            record.id.value
                                          )
                                      }}
                                    />
                                  </div>
                                )
                              })}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                    {/* 未参观 */}
                    <div className="choices" style={{ width: '100%' }}>
                      <li
                        className={
                          CRM_VISIT_TYPE[2].id == visit.value
                            ? 'communicate-active'
                            : ''
                        }
                        onClick={this.chooseVisitType.bind(
                          null,
                          CRM_VISIT_TYPE[2].id
                        )}
                      >
                        <span
                          className={`radio ${
                            CRM_VISIT_TYPE[2].id == visit.value
                              ? 'radio-active'
                              : ''
                          }`}
                        ></span>
                        <a>{CRM_VISIT_TYPE[2].name}</a>
                      </li>
                    </div>
                  </div>
                }
              </div>

              <KBInput title="可对比竞品" field={content_compete} />

              <div className="clear-fix">
                <div className="kb-row">
                  <KBDateInput
                    field={next_follow_at}
                    title="下次跟进"
                    className="kb-form-c18 kb-form-group kb-form-alone"
                    disabledDays={DateUtils.isPastDay}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </div>
    )
  },

  customerSalesTeamJsx() {
    const {
      fields: { sales_leader, salesmen, notes },
      oppMasters,
      oppUsers
    } = this.props
    const oneClass = { className: 'kb-form-group kb-form-c18 kb-form-alone' }
    const halfClass = { className: 'kb-form-group kb-div-c39' }
    const hint = ADD_CLIENT

    return (
      <div className="kb-form-container" style={{ paddingBottom: 95 }}>
        <div className="kb-row kb-form-66">
          <KBUserInput
            isRequired={true}
            hint={hint.principal}
            title="执行人"
            {...halfClass}
            field={sales_leader}
            multi={false}
            users={this.props.users}
            selectedUsers={oppMasters}
          />
        </div>
        <div className="kb-row">
          <KBUserInput
            title="团队人员"
            hint={hint.team_member}
            {...oneClass}
            field={salesmen}
            multi={true}
            users={this.props.users}
            selectedUsers={oppUsers}
          />
        </div>
        <div className="kb-row">
          <KBTextarea
            hint={hint.remark}
            {...oneClass}
            field={notes}
            title="备注"
          />
        </div>
      </div>
    )
  },

  render() {
    const { current_index } = this.state
    const { handleSubmit, submitting } = this.props

    return (
      <div
        className="kb-content-container clear-fix"
        style={{ minHeight: 500 }}
      >
        <section>
          <header className="nav-header border-bottom">
            <div className="nav-section-header-title">
              <span>{STEPS[current_index - 1]}</span>
            </div>
          </header>
          <div className="crm-new-opp-progress-container">
            <div
              className="crm-new-opp-progress-bar"
              style={{ width: `${(current_index * 100) / END_INDEX}%` }}
            ></div>
          </div>

          {current_index == 1 ? this.customerInfoJsx() : null}
          {current_index == 2 ? this.organizationInfoJsx() : null}
          {current_index == 3 ? this.customerIntentionJsx() : null}
          {current_index == 4 ? this.customerStatusJsx() : null}
          {current_index == 5 ? this.customerSalesTeamJsx() : null}

          <div
            className="kb-row clear-fix"
            style={{
              padding: '0 30px 30px 30px',
              width: '100%',
              position: 'absolute',
              bottom: 0
            }}
          >
            <div className="f-left">
              {current_index != START_INDEX ? (
                <button
                  className="certain-btn"
                  type="button"
                  onClick={this.preStep}
                >
                  上一步
                </button>
              ) : (
                ''
              )}
            </div>
            <div className="kb-form-fr">
              {current_index == END_INDEX ? (
                <KBButton
                  className="certain-btn m-none"
                  submitting={submitting}
                  type="submit"
                  onClick={handleSubmit(this.creatOpportunity)}
                >
                  确认添加客户
                </KBButton>
              ) : current_index == 1 &&
                this.state.oppsWithSamePhoneNum.length ? null : (
                <button
                  className="certain-btn m-none"
                  type="button"
                  onClick={this.nextStep}
                >
                  下一步
                </button>
              )}
            </div>
          </div>
        </section>
      </div>
    )
  }
})

function _getUsers(state, props) {
  let user = selectors.getCurrentUser(state)
  if (props.state != 'update') {
    return {
      oppUsers: [user],
      oppMasters: [user]
    }
  }

  let master =
    _.find(props.opp.sales_team, team => {
      return team.is_leader
    }).sales_user || []

  let users = props.opp.sales_team.map(team => {
    if (team.is_leader != true) {
      return team.sales_user
    }
  })

  return {
    oppLocations: props.opp.locations,
    oppMasters: [master],
    oppUsers: users
  }
}

function mapStateToProps(state, props) {
  const { user, entities } = state
  let users = selectors.getSpaceUsers(state)
  const userSpaceId = user.space
  const industriesObj = checkObject(entities, 'industries')
  const industries = (industriesObj && Object.values(industriesObj)) || []
  let sources = selectors.getCrmSources(state)
  let employeeNumbersArry = CRM_EMPLOYEE_NUMBER(1, 50)
  let employee_monthsArray = CRM_EMPLOYEE_NUMBER(1, 24)
  let obj = _getUsers(state, props)
  let locations = selectors.getSerializeLocation(state)

  const locationsObj = selectors.getSerializeLocationObj(state)
  const usersObj = selectors.getObjectMembers(state)

  let hourNumberArray = CRM_TIME_NUMBER(8, 21, 1)
  let minuteNumberArray = CRM_TIME_NUMBER(0, 59, 15)

  return {
    users,
    industries,
    sources,
    employeeNumbersArry,
    employee_monthsArray,
    userSpaceId,
    locations,
    locationsObj,
    usersObj,
    hourNumberArray,
    minuteNumberArray,
    ...obj
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(actions.successState, dispatch),
    createOpportunity: bindActionCreators(
      actions.opportunitiesActions.create,
      dispatch
    ),
    apiCallFailureAction: bindActionCreators(actions.apiCallFailure, dispatch),
    createCrmRecordAction: bindActionCreators(
      actions.customerRecordsActions.create,
      dispatch
    ),
    allIndustries: bindActionCreators(actions.industriesActions.all, dispatch),
    routerAction: bindActionCreators(routerActions, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.sales_leader)) {
    errors.sales_leader = '请选择执行人'
  }

  return errors
}

const formConfig = {
  form: 'CrmNewOpportunityForm',
  fields: [
    'name',
    'company',
    'source',
    'content',
    'wechat',
    'employee_months',
    'user_space_id',
    'industry_id',
    'source_text',
    'company_type',
    'area_types',
    'contact_name',
    'mobile',
    'agent_mobile',
    'email',
    'address',
    'desire',
    'gender',
    'only_contact',
    'number',
    'level',
    'source',
    'title',
    'state',
    'expected_date',
    'customer_type',
    'location_ids',
    'employee_number',
    'area_capacity',
    'total_number',
    'sales_leader',
    'salesmen',
    'notes',
    'requirement_type',
    'requirement_text',
    'rentable_area',
    'visit',
    'visit_date',
    'interest_locations[].location_id',
    'interest_locations[].visit_date',
    'interest_locations[].visit_state',
    'interest_locations[].receiver_id',
    'interest_locations[].notes',
    'interest_locations_location_id',
    'interest_locations_visit_date',
    'interest_locations_receiver_id',
    'interest_locations_notes',
    'rent_type',
    'note_type',
    'content_compete',
    'next_follow_at',
    'visit_time_hour',
    'visit_time_minute'
  ],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(CrmNewOpportunityForm)
