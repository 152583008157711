import { get } from '../reducers/reducer_utils'
import * as Constants from '../constants'
import _ from 'lodash'

export const getUserById = (state, user_id = null) => {
  if (user_id && user_id == state.user.id) return state.user
  return user_id
    ? state.entities.users && state.entities.users[user_id]
    : state.user
}

export const getMemberById = (state, member_id = null) => {
  if (member_id && member_id == state.user.id) return state.user
  return member_id ? state.entities.members[member_id] : state.user
}

// get current logged in user
export const getCurrentUser = state => state && state.user

// if current user is logged in
export const isAuthenticated = state => getCurrentUser(state) || false

// get current space or given space id
// note that this only gets the current space description
export const getSpace = (state, space_id = null) => {
  if (!space_id) {
    let user = getCurrentUser(state)
    if (user) {
      space_id = user.space
    }
    return space_id && state.entities.spaces && state.entities.spaces[space_id]
  }
}

// 所有分店（不一定可以有）
export const getLocations = (state, space_id = null) => {
  let space = getSpace(state, space_id)
  if (!space) {
    return null
  }
  var locations =
    (state.entities.locations && Object.values(state.entities.locations)) || []
  return (
    locations.filter(location => location.space_id == (space_id || space.id)) ||
    []
  )
}

export const getLocationsAsDictionary = (state, space_id = null) => {
  return get(state, ['entities', 'locations'])
}

export const getLocation = (state, location_id) => {
  return !location_id
    ? state.entities.locations
    : state.entities.locations && state.entities.locations[location_id]
}

// 用户可以access的分店
export const getLocationsOfUser = (state, user_id = null) => {
  let user = getUserById(state, user_id)
  if (user) {
    return user.locations && Object.values(user.locations)
  } else {
    return null
  }
}

// 用户可以access的缺省分店
export const getDefaultLocation = (state, user_id = null) => {
  let locations = getLocationsOfUser(state, user_id)
  return locations && locations.length > 0 && locations[0]
}

// 用户的权限
export const getRoles = (state, user_id = null) => {
  let user = getMemberById(state, user_id)
  if (user && user.roles) {
    return user.roles && Object.values(user.roles)
  } else {
    return null
  }
}

export const findRole = (state, key) => {
  const roles = getRoles(state) || []
  if (roles || !key || !roles.length) {
    return false
  }

  let role = roles.find(role => role.role == key)

  return role ? role : false
}

//用户在分店的权限
export const getRolesOfLocation = (state, location_id, user_id = null) => {
  let roles = getRoles(state, user_id)
  if (roles) {
    return roles.filter(role => role.location_id == location_id)
  } else {
    return null
  }
}

export const getSubScriptions = (state, id) => {
  let subscriptions = get(state, ['entities', 'subscriptions'])

  if (id) {
    return subscriptions && subscriptions[id]
  }

  return (subscriptions && Object.values(subscriptions)) || []
}

export const getSubScriptionsByOrg = (state, org_id) => {
  let subscriptions = getSubScriptions(state)
  if (!subscriptions.length) {
    return []
  }

  return subscriptions.filter(
    subscription => subscription.organization_id == org_id
  )
}

export const getArrayUsers = state => {
  const users = getEntities(state, 'users')

  return (users && Object.values(users)) || []
}

export const getObjectMembers = state => {
  const users = getEntities(state, 'members')

  return users || {}
}

//用户在分店的详情
export const getCurrentLocation = (state, location_id) => {
  let location = get(state, ['entities', 'locations', location_id])
  return location
}

//用户在空间的权限
export const getRolesOfSpace = (state, user_id = null) => {
  let roles = getRoles(state, user_id)
  if (!roles) return null
  return roles.filter(role => role.location_id === null)
}

//是否分店管理员
export const isLocationAdmin = (state, location_id, user_id = null) => {
  let roles = getRolesOfLocation(state, location_id, user_id)
  if (roles) {
    let manmageRole = roles.filter(role => role.role === 'location_manager')
    return manmageRole[0] ? true : false
  } else {
    return false
  }
}
//是否空间管理员
export const isSpaceAdmin = (state, user_id = null) => {
  let roles = getRoles(state, user_id)
  if (roles) {
    let admin = roles.find(
      role => role.location_id === null && role.role === 'admin'
    )
    return admin ? true : false
  } else {
    return false
  }
}

//是否为项目管理员
export const isGroupAdmin = (state, location_id, user_id = null) => {
  let roles = getRolesOfLocation(state, location_id, user_id)
  if (roles) {
    let manmageRole = roles.filter(role => role.role === 'group_manager')
    return manmageRole[0] ? true : false
  } else {
    return false
  }
}
//用户上级
export const getParentOfUser = (state, user_id = null) => {
  let user = getMemberById(state, user_id)
  if (user.parent_id) {
    return state.entities.members[user.parent_id]
  } else {
    return null
  }
}
//全部空间管理公司用户
export const getSpaceUsers = (state, space_id = null) => {
  let space = getSpace(state, space_id)
  if (!space) {
    return null
  }

  return objectToArray(state.entities.members)
}

//查询所有的用户通过Ids
export const getUsersByUserIds = (state, ids) => {
  return ids && ids.map(id => state.entities.members[id])
}

//全部部门列表(数组)
export const getDepartments = (state, space_id = null) => {
  let space = getSpace(state, space_id)
  if (!space) {
    return null
  }
  return objectToArray(state.entities.departments)
}

//全部部门列表(Object)
export const getDepartmentsObj = (state, space_id = null) => {
  let space = getSpace(state, space_id)
  if (!space) {
    return null
  }
  return state.entities.departments
}
export const getDepartment = (state, department_id) => {
  return (
    department_id &&
    state.entities.departments &&
    state.entities.departments[department_id]
  )
}
//下级部门
export const getChildDepartments = (state, department_id) => {
  let children_ids = state.entities.departments[department_id].children
  let departments = state.entities.departments
  return children_ids.map(id => departments[id])
}
//上级部门
export const getParentDepartment = (state, department_id) => {
  let department = state.entities.departments[department_id]
  if (!department || !department.parent_id) return null
  return state.entities.departments[department.parent_id]
}
//用户所在的所有部门
export const getDepartmentsOfUser = (state, user_id) => {
  let user = getMemberById(state, user_id)
  if (!user) return null
  let departments = state.entities.departments || []
  return user.department_ids && user.department_ids.map(id => departments[id])
}
//部门成员
export const getUsersOfDepartment = (state, department_id) => {
  let users = getSpaceUsers(state)
  return (
    users &&
    users.filter(
      user =>
        user.department_ids && user.department_ids.indexOf(department_id) > -1
    )
  )
}
//分店管理公司
export const getOperatorsOfLocation = (state, location_id) => {
  let teams = get(state, ['entities', 'locations', location_id, 'team'])
  return (
    teams &&
    teams.filter(team =>
      team.roles_list.find(
        json =>
          json.location_id == location_id && json.role == 'location_operator'
      )
    )
  )
}

//获取公司角色
export const getTeamRoles = user => {
  return user.roles
}
//分店角色权限比较
export const canAccessLocationRoles = (
  user,
  location_id,
  required_roles = []
) => {
  let roles = getTeamRoles(user)
  return _.intersection(required_roles, roles).length > 0
}
//项目角色权限比较
export const canAccessGroupRoles = (user, location_id, required_roles = []) => {
  let roles = getTeamRoles(user)
  return _.intersection(required_roles, roles).length > 0
}
//分店观察员(只具有'observer'，不具有分店其他任何权限)
export const canAccessLocationObserver = (user, location_id) => {
  let isManager = canAccessLocationRoles(user, location_id, [
    'location_manager',
    'finance',
    'location_operator'
  ])
  let isObserver = location_id
    ? canAccessLocationRoles(user, location_id, ['observer'])
    : false
  return !isManager && isObserver
}

//项目观察员
export const canAccessGroupObserver = (user, location_id) => {
  let isManager = canAccessGroupRoles(user, location_id, [
    'group_manager',
    'finance',
    'group_operator'
  ])
  let isObserver = location_id
    ? canAccessGroupRoles(user, location_id, ['observer'])
    : false
  return !isManager && isObserver
}

//分店财务(只具有'finance',不具有分店其他任何权限)
export const canAccessLocationOnlyFinance = (user, location_id) => {
  let isManager = canAccessLocationRoles(user, location_id, [
    'location_manager',
    'observer',
    'location_operator'
  ])
  let isFinance = location_id
    ? canAccessLocationRoles(user, location_id, ['finance'])
    : false
  return !isManager && isFinance
}

//分店角色
export const getTeamRolesOfLocation = (state, location_id, roleStr = '') => {
  let teams = get(state, ['entities', 'locations', location_id, 'team'])
  let teamRoles = []
  teams &&
    teams.map(team => {
      let isObserver = true
      if (roleStr == 'observer') {
        isObserver = canAccessLocationObserver(team, location_id)
      }
      team.roles.map(roleList => {
        if (roleList == roleStr && isObserver) {
          teamRoles.push(team)
        }
      })
    })
  return teamRoles
}

//项目角色
export const getTeamRolesOfGroup = (state, location_id, roleStr = '') => {
  let teams = get(state, ['entities', 'groups', location_id, 'team'])
  let teamRoles = []
  teams &&
    teams.map(team => {
      let isObserver = true
      if (roleStr == 'observer') {
        isObserver = canAccessGroupObserver(team, location_id)
      }
      team.roles.map(roleList => {
        if (roleList == roleStr && isObserver) {
          teamRoles.push(team)
        }
      })
    })
  return teamRoles
}

//分店负责人
export const getManagerOfLocation = (state, location_id) => {
  let teams = get(state, ['entities', 'locations', location_id, 'team'])
  return teams && teams.find(team => team.is_manager == true)
}
// 分店所有管理人员
export const getUsersOfLocation = (state, location_id) => {
  let teams = get(state, ['entities', 'locations', location_id, 'team'])
  return teams
}

/////////// 空间会员 ///////////////////////////////////////////////////

//空间所有会员:
export const getMembersOfSpace = (state, space_id = null) => {
  let space = getSpace(state, space_id)
  if (!space) {
    return null
  }
  let member_ids = get(state, ['spaces', space.id, 'members'])
  return member_ids && member_ids.map(id => state.entities.users[id])
}
//空间分店会员:
export const getMembersOfLocation = (state, location_id) => {
  let member_ids = get(state, ['locations', location_id, 'member_ids'])
  return member_ids && member_ids.map(id => state.entities.users[id])
}
//空间公司
export const getOrgsOfLocation = (state, location_id) => {
  let organizations = get(state, ['entities', 'organizations'])
  let organizationsList = Object.assign([], objectToArray(organizations))

  organizationsList.sort().reverse()
  if (!location_id) {
    return organizationsList
  }

  return organizationsList.filter(org => {
    let loc_ids = []
    org.locations && org.locations.map(loc => loc_ids.push(loc.id))
    if (loc_ids.includes(Number(location_id))) {
      return true
    }
    return false
  })
}

//报表列表
export const getReports = state => {
  let reports = get(state, ['entities', 'reportLists'])
  let reportsList = Object.assign([], objectToArray(reports))

  return reportsList.sort().reverse()
}

/**
 * 根据Key值获取loading状态
 * @method getLoadingStatusOfKey
 * @param  {[obj]}              state [store]
 * @param  {[string]}              key   [key]
 */
export const getLoadingStatusOfKey = (state, key) => {
  if (!key) {
    return
  }

  let status = get(state, ['loading', key])

  return status
}

//空间公司
export const getOrg = (state, org_id) => {
  return get(state, ['entities', 'organizations', org_id])
}
//公司的会员（所有人）
export const getMembersOfOrg = (state, org_id) => {
  let users = getArrayUsers(state)
  let orgUsers = users.filter(user => {
    return (
      user &&
      user.organizations &&
      user.organizations.find &&
      user.organizations.find(o => {
        return o.id == org_id || (o.organization && o.organization.id == org_id)
      })
    )
  })

  return orgUsers
}

export const getOrgMembers = (state, user_id, org_id) => {
  let users = get(state, ['entities', 'users'])

  if (user_id) {
    return users && users[user_id]
  }

  let list = objectToArray(users) || []

  if (org_id) {
    return list.filter(
      j =>
        j.organizations &&
        j.organizations.find &&
        j.organizations.find(
          o => o.id == org_id || (o.organization && o.organization.id == org_id)
        )
    )
  }

  return list
}

export const getOrgReplaceMembers = (state, user_id, org_id) => {
  let users = get(state, ['entities', 'orgUsers'])

  if (user_id) {
    return users && users[user_id]
  }

  let list = objectToArray(users) || []

  if (org_id) {
    return list.filter(
      j =>
        j.organizations &&
        j.organizations.find &&
        j.organizations.find(
          o => o.id == org_id || (o.organization && o.organization.id == org_id)
        )
    )
  }

  return list
}

//公司负责人列表
export const getLeadersOfOrg = (state, org_id) => {
  let org = getOrg(state, org_id)
  return org && org.users && org.users.filter(user => user.role === 'employer')
}
export const getContractOfOrg = (state, org_id) => {
  let org = getOrg(state, org_id)
  return org && org.sales_subscriptions
}

//机会列表
export const getOpportunities = (state, space_id) => {
  let opp_ids = get(state, ['crm', 'opportunities'])
  return (
    opp_ids &&
    Object.keys(opp_ids).map(id =>
      get(state, ['entities', 'opportunities', id])
    )
  )
}

export const getObjOpportunities = (state, space_id) => {
  return get(state, ['entities', 'opportunities'])
}

//机会列表
export const getContacts = (state, space_id) => {
  let contact_ids = get(state, ['crm', 'contacts'])
  return (
    contact_ids &&
    contact_ids.map(id => get(state, ['entities', 'contacts', id]))
  )
}
//线索列表
export const getLeads = state => {
  let leads = get(state, ['entities', 'leads'])
  return objectToArray(leads)
}
// 客户意向办公区列表
export const getIntentAreas = state => {
  let areas = get(state, ['entities', 'intentAreas'])
  return objectToArray(areas)
}

// 指定分店下的分区列表(简单信息)
export const getSimpleAreas = state => {
  let areas = get(state, ['entities', 'simpleAreas'])
  return objectToArray(areas)
}

//
export const getObjCustomers = state => {
  return get(state, ['entities', 'customers'])
}

//销售机会公司
export const getOpportunityTeam = (state, opportunity_id) => {
  let opportunity = state.entities.opportunities[opportunity_id]
  if (!opportunity) return null
  return opportunity.sales_team
}
//销售负责人
export const getOpportunityLeader = (state, opportunity_id) => {
  let sales_team = getOpportunityTeam(state, opportunity_id)
  return sales_team.filter(team => team.is_leader)
}

//销售记录
export const getRecords = state => {
  let records = get(state, ['entities', 'crmRecords'])

  return (records && Object.values(records).reverse()) || []
}

/////// Tasks /////
// get all tasks
export const getTasks = (state, space_id = null) => {
  let tasks = get(state, ['entities', 'tasks'])

  return (tasks && Object.keys(tasks).length > 0 && Object.values(tasks)) || []
}

//任务负责公司
export const getTaskTeam = (state, task_id) => {
  let task = state.entities.tasks[task_id]
  if (!task) return null
  return task.followers
}
//任务负责人
export const getTaskOwner = (state, task_id) => {
  let task = state.entities.tasks[task_id]
  if (!task) return null
  return task.assigned_to
}

// 分店分区
export const getArea = (state, area_id) => {
  return getEntity(state, 'areas', area_id)
}

/**
 * 返回Kay value 形式area
 * @param state
 */
export const getObjArea = state => {
  return get(state, ['entities', 'areas'])
}

export const getAreasOfLocation = (state, location_id) => {
  let areas = objectToArray(state.entities.areas)
  areas &&
    areas.map(area => {
      area.desks =
        area.desks &&
        area.desks.map(json => {
          json.area_id = area.id
          return json
        })
      return area
    })
  areas = areas && areas.filter(area => area.location_id == location_id)
  return areas
}
//分店Desks
export const getDesksOfLocation = (state, location_id) => {
  let desk_ids = get(state, ['locations', location_id, 'desk_ids'])
  return desk_ids && desk_ids.map(id => state.entities.desks[id])
}

export const getAreaOfId = (state, area_id) => {
  return get(state, ['entities', 'areas', area_id]) || {}
}

//分区Desk
export const getDesksOfArea = (state, area_id) => {
  let desk_ids = get(state, ['entities', 'areas', area_id, 'desks'])
  let desks = []

  desk_ids &&
    desk_ids.forEach(id => {
      let desk = state.entities.desks[id]

      if (desk) {
        desks.push(desk)
      }
    })

  return desks
}

/**
 * 只获取以入驻的工位
 * @param  {[obj]} state   [store]
 * @param  {[string]} area_id [分区id]
 * @return {[Array]}         [工位]
 */
export const getDesksOfAreaId = (state, area_id) => {
  let desks = get(state, ['entities', 'desks']) || []
  desks = Object.assign([], Object.values(desks))

  if (!area_id) {
    return desks
  }

  desks = desks.filter(desk => {
    return desk.area && desk.area.id == area_id
  })

  return desks
}

export const getEntitiesDesksOfArea = (state, area_id) => {
  let desks = get(state, ['entities', 'areas', area_id, 'desks'])
  return Object.keys(desks).length > 0 && Object.values(desks)
}

export const getInvoiceOfId = (state, invoiceId) => {
  let invoices = get(state, ['entities', 'invoices'])
  if (Object.prototype.toString.call(invoices) === '[object Object]') {
    return invoices[invoiceId]
  }
  return invoices && invoices.find(invoice => invoice.id == invoiceId)
}

// 分店会议室
export const getMeetingRoomsOfLocation = (state, location_id) => {
  let meetings = get(state, ['entities', 'meetingRoots'])

  let meetingList =
    (meetings && Object.keys(meetings).length > 0 && Object.values(meetings)) ||
    []

  return (
    meetingList &&
    meetingList.filter(meeting => {
      if (!location_id) {
        return true
      }

      return meeting.location_id == location_id
    })
  )
}

//查询平板列表
export const getMeetingDevicesOfLocation = (state, location_id) => {
  let meetingDevices = get(state, ['entities', 'meetingDevices'])

  let devicesList =
    (meetingDevices &&
      Object.keys(meetingDevices).length > 0 &&
      Object.values(meetingDevices)) ||
    []
  return (
    devicesList &&
    devicesList.filter(device => {
      if (!location_id) {
        return true
      }

      return device.location_id == location_id
    })
  )
}

//会议室平板信息
export const getMeetingDeviceMessage = (state, device_id) => {
  let meetingDevices = get(state, ['entities', 'meetingDevices'])
  let devicesMessage =
    (meetingDevices &&
      Object.keys(meetingDevices).length > 0 &&
      Object.values(meetingDevices)) ||
    []

  if (!device_id) {
    return null
  }

  return devicesMessage.find(device => {
    return device.device_id == device_id
  })
}

// 获取会议室 根据会议室ID
export const getMeetingRoomsOfMeetingId = (state, meeting_id) => {
  let meetings = get(state, ['entities', 'meetingRoots'])
  let meeting = meetings && meetings[meeting_id]

  if (!meeting) {
    return {}
  }

  return meeting
}

/**
 * 获取预定列表
 * @type {[type]}
 */
export const getReservations = state => {
  let reservations = get(state, ['entities', 'reservations'])
  return objectToArray(reservations)
}

//分店开放工位区
export const getOpenOfficesOfLocation = (state, location_id) => {
  let areas = getAreasOfLocation(state, location_id)
  if (!areas) return null
  return areas.filter(area => area.area_type === 'public_office')
}
//分店独立办公区
export const getCloseOfficesOfLocation = (state, location_id) => {
  let areas = getAreasOfLocation(state, location_id)
  if (!areas) return null
  return areas.filter(area => area.area_type === 'private_office')
}
//分店灵活办公区
export const getFlexibleOfficesOfLocation = (state, location_id) => {
  let areas = getAreasOfLocation(state, location_id)
  if (!areas) return null
  return areas.filter(area => area.area_type === 'flexible_office')
}
//所有办公区域
export const getOfficeAreasOfLocation = (state, location_id) => {
  let areas = getAreasOfLocation(state, location_id)
  if (!areas) return null
  return areas.filter(area =>
    _.includes(['private_office', 'public_office'], area.area_type)
  )
}
// 会员Desk
export const getDeskOfMember = (state, member_id) => {
  let user = state.entities.users[member_id]
  if (!user || !user.desk) return null
  return user.desk
}

//获取空间员工信息
export const getSpaceMemberInfo = (state, member_id) => {
  let user = state.entities.members[member_id]
  return user
}

/**
 * 获取CRM来源列表
 * @param  {[obj]} state [state]
 * @return {[array]}       [CRM来源列表]
 */
export const getCrmSources = state => {
  let sources = get(state, ['entities', 'crmSources']) || []
  sources = Object.assign([], Object.values(sources))
  return sources
}

// 获取自定义动态字段
export const getDynamicAttributes = state => {
  let dynamicAttributes = get(state, ['entities', 'dynamicAttributes'])
  let dynamicAttributesList = Object.assign(
    [],
    objectToArray(dynamicAttributes)
  )

  return dynamicAttributesList.sort().reverse()
}

// 根据Location_id 获取分店楼层信息
export const getFloorInfos = (state, id) => {
  let floorInfos = get(state, ['entities', 'floorInfos']) || []
  floorInfos = Object.assign([], Object.values(floorInfos))

  floorInfos = floorInfos.filter(json => json.location_id == id)
  floorInfos = floorInfos.map(json => {
    json.name = json.layer_description
    return json
  })

  return floorInfos
}

// 通过location—id 大楼id 获取楼层
export const getFloorInfosOfLocIdAndBuildId = (state, loc_id, build_id) => {
  let floorInfos = getFloorInfos(state, loc_id)
  return floorInfos.filter(
    floor => floor.building_info && floor.building_info.id == build_id
  )
}

/**
 * 获取CRM来源列表 Obj 状态
 * @param  {[obj]} state [state]
 * @return {[obj]}       [CRM来源列表]
 */
export const getObjCrmSources = state => {
  let sources = get(state, ['entities', 'crmSources']) || []
  return sources
}

// 会员Desk
export const getDeskOfDeskId = (state, desk_id) => {
  if (!desk_id) {
    return null
  }
  return state.entities.desks && state.entities.desks[desk_id]
}

//工位上的会员
export const getMemberOfDesk = (state, desk_id) => {
  let users = Object.values(state.entities.users)
  return users.filter(user => user.desk && user.desk.id == desk_id)
}
//分区的所有会员
export const getMemberOfArea = (state, area_id) => {
  let area = state.entities.areas[area_id]
  if (!area || !area.desks) return null
  let desks = Object.values(area.desks)
  let desk_ids = desks.map(desk => desk.id)
  let users = Object.values(state.entities.users)
  return users.filter(user => user.desk && desk_ids.indexOf(user.desk.id) > -1)
}

/**
 * 获取序列化的Locations
 * @return {[array]} [返回locations]
 */
export const getSerializeLocation = state => {
  let locations = get(state, ['entities', 'serializeLocations']) || []
  return objectToArray(locations)
}

export const getSerializeGroupsLocation = (state, group_id) => {
  let locations = get(state, ['entities', 'serializeLocations']) || []
  locations = _.filter(locations, o => {
    return (
      _.findIndex(o.location_groups, group => {
        return group.id == group_id
      }) !== -1
    )
  })
  return objectToArray(locations)
}

// export const getSerializeLocation = (state) => {
//   let locations = get(state, ['entities', 'locations']) || []
//   return objectToArray(locations)
// }
export const getSerializeLocationObj = (state, location_id) => {
  let locations = get(state, ['entities', 'serializeLocations']) || {}
  if (location_id) {
    return locations[location_id]
  }
  return locations
}
// export const getSerializeLocationObj = (state, location_id) => {
//   let locations = get(state, ['entities', 'locations']) || {}
//   if (location_id) {
//     return locations[location_id]
//   }
//   return locations
// }

/**
 * object to array
 * @return {[array]} [返回转换后的数组 否则为[]空数组]
 */
export const objectToArray = object => {
  return (
    (object && Object.keys(object).length > 0 && Object.values(object)) || []
  )
}

/**
 * 获取设备信息
 * @type {[type]}
 */
export const getDevices = state => {
  let devices = get(state, ['entities', 'devices']) || []
  return Object.assign([], Object.values(devices))
}

// 返回Entities数上的节点
export const getEntities = (state, entitiesName) => {
  return get(state, ['entities', entitiesName])
}

// 返回指定的Entity
export const getEntity = (state, entitiesName, entityId) => {
  return get(state, ['entities', entitiesName, entityId])
}

/**
 * FAQ 分类列表
 */
export const getSections = (state, location_id) => {
  let sections = get(state, ['entities', 'sections'])
  let sectionList = Object.assign([], objectToArray(sections))

  sectionList.map(section => {
    section.name = section.title
    return section
  })

  if (!location_id) {
    return sectionList
  }

  return []
}

/**
 * FAQ 文件列表
 */
export const getFaqFiles = state => {
  let faqFiles = get(state, ['entities', 'faqFiles'])
  let faqFileList = Object.assign([], objectToArray(faqFiles))

  return faqFileList
}

// 获取某个分类分支下的所有的文章列表
export const getTopicsBySection = (state, section_id) => {
  let topics = get(state, ['entities', 'topics'])
  let topicsList = Object.assign([], objectToArray(topics))
  if (!section_id) {
    return topicsList
  }

  topicsList = topicsList.filter(topics => {
    return topics.section.id == section_id
  })

  return topicsList
}

export const getSpacesSalesSetting = state => {
  const space = getSpace(state)
  let settings = get(state, ['entities', 'spacesSalesSetting'])
  let settingList = Object.assign([], objectToArray(settings))

  return settingList.find(json => json.space_id == space.id)
}

// 根据文章ID 和 分类ID 获取文章具体信息
export const getTopicsObjBySectionAndId = (state, section_id, topics_id) => {
  let topics = get(state, ['entities', 'topics'])
  let topicsList = Object.assign([], objectToArray(topics))

  if (!section_id || !topics_id) {
    return null
  }

  return topicsList.find(topics => {
    return topics.section.id == section_id && topics_id == topics.id
  })
}

// 获取门禁列表
export const getLocks = state => {
  let locks = get(state, ['entities', 'locks'])
  return Object.assign([], objectToArray(locks))
}

//获取门禁详情
export const getLockInfo = (state, lock_id) => {
  let locks = get(state, ['entities', 'locks'])
  let locksArray = Object.assign([], objectToArray(locks))
  if (!lock_id) {
    return null
  }
  return locksArray.find(lock => {
    return lock.id == lock_id
  })
}

//获取门禁权限(门禁详情)
export const getLocksPermissions = state => {
  let locksPermissions = get(state, ['entities', 'lockPermissions'])
  let locksPermissionsList = Object.assign([], objectToArray(locksPermissions))
  return locksPermissionsList
}

//获取门禁权限
export const getLocksPermissionsUser = (state, member_id) => {
  let locksPermissions = get(state, ['entities', 'lockPermissions'])
  let locksPermissionsList = Object.assign([], objectToArray(locksPermissions))
  if (!member_id) {
    return null
  }
  return locksPermissionsList.filter(permissions => {
    return permissions.user_id == member_id
  })
}

/**
 * 获取服务商列表
 * @return {array} [返回服务商列表]
 */
export const getServiceProviders = state => {
  let serviceProviders = get(state, ['entities', 'serviceProviders'])
  return Object.assign([], objectToArray(serviceProviders))
}

export const getServiceProvidersOfId = (state, id) => {
  let serviceProviders = get(state, ['entities', 'serviceProviders'])
  const serviceProvider = (serviceProviders && serviceProviders[id]) || null
  return serviceProvider
}

/**
 * 获取根据id获取服务请求 如果没有id获取全部的服务请求
 */
export const getServiceRequestOfProvidersId = (state, id) => {
  let serviceRequests = get(state, ['entities', 'serviceRequests'])
  let serviceRequestsList = Object.assign([], objectToArray(serviceRequests))

  if (!id) {
    return serviceRequestsList
  }

  return serviceRequestsList.filter(request => {
    return request.service_provider_id == id
  })
}

/**
 * 根据服务订单ID 获取服务订单对象
 * @method getServiceRequestOfRequestId
 * @param  {[obj]}                     state [state]
 * @param  {[int]}                     id    [服务端订单ID]
 * @return {[obj]}                           [服务订单对象]
 */
export const getServiceRequestOfRequestId = (state, id) => {
  let serviceRequests = get(state, ['entities', 'serviceRequests'])

  if (!id) {
    return serviceRequests
  }

  return serviceRequests && serviceRequests[id]
}

export const getServiceOfId = (state, id) => {
  let services = get(state, ['entities', 'services'])
  let serviceList = Object.assign([], objectToArray(services))

  if (!id) {
    return serviceList
  }

  return services && services[id]
}

export const getServiceOfProviderId = (state, id) => {
  let services = getServiceOfId(state)
  if (!id) {
    return services
  }

  return services.filter(service => {
    return service.service_provider_id == id
  })
}

/**
 * 通过服务类型ID 获取服务
 * @method getServiceCategoriesOfId
 * @param  {[obj]}                 state [state]
 * @param  {[int]}                 id    [服务类型id]
 * @return {[array || obj]}        [如果id 为null 则返回全部的服务类型 如果id存在则返回该服务类型对象]
 */
export const getServiceCategories = state => {
  let serviceCategories = get(state, ['entities', 'serviceCategories'])
  let serviceCategoriesList = Object.assign(
    [],
    objectToArray(serviceCategories)
  )

  return serviceCategoriesList
}

/**
 * 获取所有的待办事项
 * @method getSpaceLeaveOrEnterOptions
 */
export const getSpaceLeaveOrEnterOptions = state => {
  let leaveOrEnterOptions = get(state, ['entities', 'leaveOrEnterOptions'])
  let leaveOrEnterOptionList = Object.assign(
    [],
    objectToArray(leaveOrEnterOptions)
  )

  return leaveOrEnterOptionList
}

export const getSubCategoriesOfId = (state, id) => {
  let subCategories = get(state, ['entities', 'subCategories'])
  let subCategoriesList = Object.assign([], objectToArray(subCategories))

  if (!id) {
    return subCategoriesList
  }

  return subCategories[id]
}

// Get all cities
export const getCities = state => {
  return state.entities.cities
}

// Get all provinces
export const getProvinces = state => {
  return state.entities.provinces
}

/**
 * 根据公告ID 获取公告  如果没有ID 默认返回公告数组
 * @method getAnnouncementsOfId
 * @param  {[obj]}             state [state]
 * @param  {[int]}             id    [公告id]
 */
export const getAnnouncementsOfId = (state, id) => {
  let announcements = get(state, ['entities', 'announcements'])
  let announcementsList = Object.assign([], objectToArray(announcements))

  if (!id) {
    return announcementsList
  }

  return announcements[id]
}

/**
 * 获取空间配置
 * @method getSpaceSetting
 * @param  {[object]}        state [state]
 * @return {[object]}              [空间配置对象]
 */
export const getSpaceSetting = state => {
  let space = get(state, ['spaceSetting'])
  return space
}

export const getLocationSetting = (state, location_id) => {
  let setting = get(state, ['locationSetting', location_id])
  return setting
}
export const getGroupSetting = state => {
  let setting = get(state, ['spaceSetting'])
  return setting
}

// 获取空间权限配置
export const getRoleSetting = state => {
  let space = get(state, ['spaceRole'])
  return space
}

export const getSpaceVirtualCurrencyName = state => {
  let settings = getSpaceSetting(state)
  let virtual_currency_name =
    // (settings &&
    //   settings.virtual_currency_settings &&
    //   settings.virtual_currency_settings.virtual_currency_name) ||
    '办公券'
  return virtual_currency_name
}

/**
 * 获取会议室预定设置
 * @method getSpaceMeetingRoomSetting
 */
export const getSpaceMeetingRoomSetting = state => {
  let meetingSetting = get(state, ['entities', 'meetingSetting'])
  return meetingSetting && Object.values(meetingSetting)[0]
}

/**
 * 获取BeeCloud配置
 */
export const getBeeCloudSetting = state => {
  let settings = getSpaceSetting(state)
  let beecloud = settings && settings.beecloud_setting
  return beecloud
}

// 获取税率列表
export const getTaxes = state => {
  let taxes = get(state, ['entities', 'taxes'])
  return Object.assign([], objectToArray(taxes))
}

// 获取自定义账单类型列表
export const getBillType = state => {
  let self_defined_line_item_types = get(state, [
    'entities',
    'self_defined_line_item_types'
  ])
  return Object.assign([], objectToArray(self_defined_line_item_types))
}

/**
 * 返回数组形式的  城市列表
 * @return array [返回数组]
 */
export const getCitiesToArray = state => {
  let cities = get(state, ['entities', 'cities'])
  return Object.assign([], objectToArray(cities))
}

// 获取合同模板列表-或者根据id获取合同模板
export const getSubscriptionTempletes = (state, id) => {
  let subscriptions = get(state, ['entities', 'subscriptionTempletes'])
  if (id) {
    return subscriptions && subscriptions[id]
  }

  return Object.assign([], objectToArray(subscriptions))
}

// 根据合同模板的状态获取合同列表
export const getSubscriptionTempletesOfState = (state, status) => {
  let subscriptions = getSubscriptionTempletes(state)
  return subscriptions.filter(json => json.status == status)
}

// 获取合同模板有效字段
export const getSubscriptionPlaceholder = state => {
  let validPlaceholders = get(state, ['entities', 'subscriptionPlaceholder'])
  return Object.assign([], objectToArray(validPlaceholders))
}

export const getAuditLogs = state => {
  let auditLogs = get(state, ['entities', 'auditLogs'])
  return Object.assign([], objectToArray(auditLogs))
}

/**
 * 返回APP应用
 * @return array [返回数组]
 */
export const getAppModules = state => {
  let appModules = get(state, ['entities', 'appModules'])
  return Object.assign([], objectToArray(appModules))
}

/**
 * 返回平面图列表
 * @method getFloorPlans
 */
export const getFloorPlans = (state, loc_id) => {
  let floorplans = get(state, ['entities', 'floorplans'])
  let floorPlansList = Object.assign([], objectToArray(floorplans))
  if (!loc_id) {
    return floorPlansList
  }
  floorPlansList = floorPlansList.filter(json => json.location_id == loc_id)
  return floorPlansList
}

// 获取大楼信息
export const getBuildingInfo = (state, loc_id) => {
  let buildingInfo = get(state, ['entities', 'buildingInfo'])
  let buildingInfoList = Object.assign([], objectToArray(buildingInfo))
  if (!loc_id) {
    return buildingInfoList
  }
  buildingInfoList = buildingInfoList.filter(json => json.location_id == loc_id)
  return buildingInfoList
}

/**
 * 获取公司清单
 * @method getOrgOptions
 * @param  {[type]}      state [description]
 * @return {[type]}            [description]
 */
export const getOrgOptionsOfOrgId = (state, org_id) => {
  let leaveOrEnterOptions = get(state, ['entities', 'leaveOrEnterOptions'])
  let options = Object.assign([], objectToArray(leaveOrEnterOptions))

  if (!org_id) {
    return options
  }

  return options.filter(json => {
    return json.organization_id == org_id
  })
}

export const getTradingAreas = state => {
  return state.trading_areas
}
export const getTradingAreasOfCity = state => { }
export const getIndustries = state => {
  return state.entities.industries
}
export const getRaisings = state => {
  let raisings = get(state, ['entities', 'raisings'])

  return (
    (raisings && Object.keys(raisings).length > 0 && Object.values(raisings)) ||
    []
  )
}

export const getOrganizationNotes = state => {
  let notes = get(state, ['entities', 'organizationNotes'])
  return (notes && Object.keys(notes).length > 0 && Object.values(notes)) || []
}

export const getUserGropAndSettingValue = (setting, key) => {
  let obj = {
    user_group: '',
    setting_value: ''
  }
  if (!setting || !setting[key]) {
    return obj
  }

  obj.user_group = setting[key].user_group || ''
  obj.setting_value = setting[key] || ''

  return obj
}

// get Redux Form
export const getReduxFormValues = (state, name) => {
  let formObj = get(state, ['form', name])

  if (!formObj) {
    return null
  }

  return formObj.values ? formObj.values : null
}

// 会员等级列表
export const getMemberships = (state, options) => {
  let memberships = get(state, ['entities', 'memberships'])

  if (options) {
    if (options.parent_id) {
      return (
        memberships &&
        memberships[options.parent_id].histories.find(
          json => options.id == json.id
        )
      )
    }
    return memberships && memberships[options.id]
  }

  return objectToArray(memberships)
}

// 会员制资源列表
export const getMembershipResources = (state, id) => {
  let membershipResources = get(state, ['entities', 'membershipResources'])

  if (id) {
    return membershipResources && membershipResources[id]
  }

  return objectToArray(membershipResources)
}

// 会员制资源列表
export const getPointPlans = (state, id) => {
  let pointPlans = get(state, ['entities', 'pointPlans'])

  if (id) {
    return pointPlans[id]
  }

  return objectToArray(pointPlans)
}

// 商城
export const getSalesProducts = state => {
  let salesProducts = get(state, ['entities', 'salesProducts'])

  return objectToArray(salesProducts)
}

export const getShops = state => {
  let shops = get(state, ['entities', 'shops'])

  return objectToArray(shops)
}

export const getProductCategories = (state, shop_id) => {
  let productCategories = get(state, ['entities', 'productCategories'])
  let productCategoryList = objectToArray(productCategories)
  if (shop_id) {
    return productCategoryList.filter(p => p.shop_id == shop_id)
  }

  return productCategoryList
}

export const getProductInventories = state => {
  let productInventories = get(state, ['entities', 'productInventories'])

  return objectToArray(productInventories)
}

// 活动列表
export const getActivities = state => {
  let activities = get(state, ['entities', 'activities']) || {}

  return objectToArray(activities)
}

// 活动详情
export const getActivitiesInfo = (state, act_id) => {
  let activities = get(state, ['entities', 'activities']) || {}
  let activityInfo = activities[act_id] || {}
  return activityInfo
}

// 活动报名列表
export const getActivitiesOrders = state => {
  let activitiesOrders = get(state, ['entities', 'activitiesOrders']) || {}

  return objectToArray(activitiesOrders)
}

// 活动评论列表
export const getActivitiesComments = state => {
  let activitiesComments = get(state, ['entities', 'activitiesComments']) || {}

  return objectToArray(activitiesComments)
}

// 资讯列表
export const getNews = state => {
  let news = get(state, ['entities', 'news']) || {}

  return objectToArray(news)
}

// 资讯详情
export const getNewsInfo = (state, news_id) => {
  let news = get(state, ['entities', 'news']) || {}
  let newsInfo = news[news_id] || {}
  return newsInfo
}

// 获取打印机列表
export const getPrinters = (state, loc_id) => {
  let printers = get(state, ['entities', 'printers'])
  let printersList = Object.assign([], objectToArray(printers))
  if (!loc_id) {
    return printersList
  }
  printersList = printersList.filter(
    json => json.location && json.location.id == loc_id
  )
  return printersList
}

// 获取分店组列表
export const getLocationGroups = state => {
  let locationGroups = get(state, ['entities', 'locationGroups'])
  let locationGroupsArray = Object.assign([], objectToArray(locationGroups))
  return locationGroupsArray
}

// 获取门禁组列表
export const getLockGroups = state => {
  let lockGroups = get(state, ['entities', 'lockGroups'])
  let lockGroupsArray = Object.assign([], objectToArray(lockGroups))
  return lockGroupsArray
}

//获取门禁详情
export const getLockGroupInfo = (state, lock_group_id) => {
  let lockGroups = get(state, ['entities', 'lockGroups'])
  let lockGroupsArray = Object.assign([], objectToArray(lockGroups))
  if (!lock_group_id) {
    return null
  }
  return lockGroupsArray.find(lock_group => {
    return lock_group.id == lock_group_id
  })
}

// 获取发票库列表
export const getInvoicingPools = state => {
  let invoicingPools = get(state, ['entities', 'invoicingPools'])
  let invoicingPoolsArray = Object.assign([], objectToArray(invoicingPools))
  return invoicingPoolsArray
}

// 所有会员
export const getAllUsers = (state, user_id = null) => {
  var users =
    (state.entities.users && Object.values(state.entities.users)) || []

  if (user_id) {
    return users.filter(user => user.id == user_id) || []
  }
  return users
}

// 获取写字楼楼层列表
export const getLocationFloorsFromState = state => {
  let locationFloors = get(state, ['entities', 'locationFloors'])
  let locationFloorsArray = Object.assign([], objectToArray(locationFloors))
  return locationFloorsArray
}

//用户在项目的详情
export const getCurrentGroup = (state, group_id) => {
  let group = get(state, ['entities', 'groups', group_id])
  return group
}

// 获取分店currency设置
export const getLocationCurrency = (state, loc_id) => {
  const codeObj = {
    CNY: '¥',
    USD: '$',
    ILS: '₪',
    SGD: '$'
  }
  let money = get(state, ['locationSetting', loc_id, 'settings', 'money'])
  let isoCode = money && money.iso_code

  return codeObj[isoCode] || '¥'
}

// 获取储物柜
export const getLockers = state => {
  let lockers = get(state, ['entities', 'lockers'])
  let lockersArray = Object.assign([], objectToArray(lockers))
  return lockersArray.reverse()
}

// 获取储物柜规格
export const getLockerCategories = state => {
  let lockerCategories = get(state, ['entities', 'lockerCategories'])
  let lockerCategoriesArray = Object.assign([], objectToArray(lockerCategories))
  return lockerCategoriesArray
}

// 获取储物柜预定列表
export const getLockerReservations = state => {
  let lockerReservations = get(state, ['entities', 'lockerReservations'])
  let lockerReservationsArray = Object.assign(
    [],
    objectToArray(lockerReservations)
  )
  return lockerReservationsArray.reverse()
}
