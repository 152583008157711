import React, { PropTypes } from 'react'

var KBStarRate = React.createClass({
  displayName: 'KBStarRate',

  getStarNumArray(num = 5) {
    let result = []
    for (let i = 1; i < num + 1; i++) {
      result.push(i)
    }
    return result
  },

  render() {
    const {
      starNumber,
      isField,
      field,
      value,
      onStarClick,
      className,
      style,
      starStyle
    } = this.props
    const currentValue = isField ? field.value : value

    return (
      <div className={`activity-stars ${className}`} style={style}>
        {this.getStarNumArray(starNumber).map(num => {
          return (
            <span
              className={currentValue >= num ? 'star_active' : ''}
              onClick={e => {
                onStarClick && onStarClick(num)
                e.stopPropagation()
              }}
            >
              <i
                className={`iconfont icon-star2 ${
                  onStarClick ? 'c-pointer' : ''
                }`}
                style={starStyle}
              />
            </span>
          )
        })}
      </div>
    )
  }
})

KBStarRate.propTypes = {
  starNumber: PropTypes.number,
  isField: PropTypes.bool,
  field: PropTypes.object,
  value: PropTypes.number.isRequired,
  onStarClick: PropTypes.function,
  style: PropTypes.object,
  starStyle: PropTypes.object
}

export default KBStarRate
