import {
  TASK_STATES,
  TASK_PRIORITIES,
  TASK_SUBTYPES,
  TASK_UPDATE_TYPES
} from '../constants'
import moment from 'moment'

export const getUUID = () => {
  var d = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
    c
  ) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

function getTaskUpdateValue(note, task) {
  let val = ''
  let update = note.generate_operation_notes
  if (!update) {
    return ''
  }
  let newValue = update.new_value
  let operation = update.operation
  let users = task.users
  switch (operation) {
    case 'state':
      val = TASK_STATES[newValue]
      break
    case 'priority':
      val = TASK_PRIORITIES[newValue]
      break
    case 'subtype':
      val = TASK_SUBTYPES[newValue]
      break
    case 'subject':
      val = newValue
      break
    case 'due_date':
      val = newValue === '' ? '' : moment(newValue).format('MMMDo')
      break
    case 'assigned_to':
    case 'add_follower':
    case 'remove_follower':
      let user = users && users.find(user => user.id.toString() === newValue)
      val = user && user.name
      break
    case 'create_task':
      break
    default:
      break
  }
  return val
}

// getTaskNoteUpdate parses task note and returns {updateType, newValue}
export function getTaskNoteUpdate(note, task) {
  let updateType =
    note &&
    note.generate_operation_notes &&
    note.generate_operation_notes.operation

  if (!updateType) {
    updateType = note.media.length > 0 ? 'upload_photo' : 'update'
    return { updateType, newValue: '' }
  } else {
    let newValue = getTaskUpdateValue(note, task) || ''
    // Handle reset due date specifically
    if (updateType === 'due_date' && newValue === '') {
      updateType = 'reset_due_date'
    }
    return { updateType, newValue }
  }
}

function isParentDepartment(department, parentDepartment) {
  if (!department.parent_id) {
    return parentDepartment
  }
}

// returns top departments whose parent_id is not defined or null
export function getTopDepartments(departments) {
  return departments && departments.filter(dept => !dept.parent_id)
}

// returns all children
export function getChildDepartments(parentDepartment, departments) {
  return (
    departments &&
    departments.filter(dept => parentDepartment.id === dept.parent_id)
  )
}
