import React, { Component, PropTypes } from 'react'
import { KBLetterAvatar, KBQiniuImage } from 'components'
import { util } from 'utils/kbUtil'

var KBLogo = React.createClass({
  render() {
    var { obj, size, className, style, numberOfChars } = this.props

    var isLogo = obj.logo
    var tempObj = Object.assign({}, obj, {
      logo: obj.logo
    })
    var processName = util.clearSpacing(tempObj.name)
    if (isLogo) {
      return (
        <KBQiniuImage
          type="src"
          className={className}
          src={tempObj.logo}
          width={size}
          height={size}
          style={style}
        />
      )
    } else {
      return (
        <KBLetterAvatar
          name={processName}
          pinyin={tempObj.name_pinyin || tempObj.name}
          size={size}
          style={style}
          numberOfChars={numberOfChars}
        />
      )
    }
  }
})

KBLogo.PropTypes = {
  obj: PropTypes.object.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.string
}

KBLogo.defaultProps = {
  size: 40
}
export default KBLogo
