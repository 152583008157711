import React, { PropTypes } from 'react'
import { KBPopover, KBTipsy } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { Link } from 'react-router'
import { api } from 'app/services'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { canAccessLocation } from 'app/reducers/role'
import {
  deskActions,
  usersActions,
  orgUserActions,
  locationActions,
  areaActions,
  apiCallFailure,
  successState
} from 'app/actions'
import * as selectors from 'app/selectors'
import MemberDeskForm from './MemberDeskForm'
import { formatYearDayCHS } from 'utils/kbUtil'
import DeskDetail from './DeskDetail'
import * as Constants from 'app/constants'

var DeskList = React.createClass({
  getInitialState() {
    return {
      selectedDeskIds: []
    }
  },

  deleteDesk(desk) {
    const { id } = desk
    const { deleteDesksAction, successAction } = this.props

    KBPopoverConfirm({
      name: '删除工位',
      context: '您确定要删除工位？',
      callback: () => {
        return api.deleteDesk(id).then(
          json => {
            deleteDesksAction.success(json, { key: 'desks', id })
            KBPopover.close()
            successAction({
              message: '成功删除'
            })
            this.refreshStatistic(desk.area_id)
          },
          errors => {
            deleteDesksAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  memberDeskForm(desk) {
    const { loc_id, changeDeskOfList } = this.props
    KBPopover.show(
      <MemberDeskForm
        loc_id={loc_id}
        desk={desk}
        changeDeskOfList={changeDeskOfList}
        callback={this.refreshStatistic}
      />
    )
  },

  clickDeskBox(desk) {
    if (desk.state !== 'free') {
      return
    }
    const { selectedDeskIds } = this.state
    const newSelectedDeskIds = Object.assign([], selectedDeskIds)
    if (selectedDeskIds.includes(desk.id)) {
      newSelectedDeskIds.splice(
        newSelectedDeskIds.findIndex(id => id === desk.id),
        1
      )
    } else {
      newSelectedDeskIds.push(desk.id)
    }
    this.setState({
      selectedDeskIds: newSelectedDeskIds
    })
  },

  deleteAllSelectedDesks() {
    const { loc_id, successAction, deleteDesksAction } = this.props
    const { selectedDeskIds } = this.state

    let ids = Object.assign([], selectedDeskIds)
    KBPopoverConfirm({
      name: '批量删除工位',
      context: `您确定要删除选中的全部 ${selectedDeskIds.length} 个工位？`,
      callback: () => {
        return api.batchDeleteDesks({ location_id: loc_id, ids }).then(
          json => {
            deleteDesksAction.success(json, { key: 'desks', ids })
            KBPopover.close()
            successAction({
              message: '成功删除'
            })
            this.refreshStatistic()
          },
          errors => {
            deleteDesksAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  selectAllFreeDesks() {
    const { desks } = this.props
    const deskIds = []
    desks &&
      desks.map(desk => {
        if (desk.state === 'free') {
          deskIds.push(desk.id)
        }
      })
    this.setState({
      selectedDeskIds: deskIds
    })
  },

  deskStyle(number) {
    let num_style = {
      width: '100%',
      whiteSpace: 'nowrap',
      fontSize: '15px',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
    return num_style
  },

  clearMemberDesk(desk) {
    const {
      updateUserActions,
      changeDeskOfList,
      orgUserActions,
      loc_id,
      successAction,
      loadData,
      area_id,
      page,
      filters,
    } = this.props
    const {
      assignee_id,
      organization_id,
      reservation_type,
      id,
      desk_reservation_id
    } = desk
    const that = this

    if (reservation_type === 'DeskReservationsDesk') {
      return KBPopover.plugins.confirm('取消预订1', '是否取消当前工位预订？', {
        confirm: () => {
          api
            .freeReservedDesk({
              location_id: loc_id,
              desk_reservation_id: desk_reservation_id,
              desk_ids: String(id)
            })
            .then(
              json => {
                delete desk.assignee_id
                changeDeskOfList({
                  ...desk,
                  state: 'free'
                })
                this.refreshStatistic()
                KBPopover.close()
                loadData(loc_id, area_id, page, filters)

                successAction({
                  message: '成功取消工位预定'
                })
              },
              errors => {
                const { apiCallFailureAction } = this.props
                const errorMsg = errors.message || '服务器出错了，请稍后再试'
                apiCallFailureAction({
                  status: 'error',
                  message: errorMsg
                })
                KBPopover.close()
              }
            )
        }
      })
    }

    return KBPopover.plugins.confirm('取消入驻', '是否取消当前员工工位？', {
      confirm: () => {
        api.putFreeDeskOrgMember(organization_id, assignee_id).then(
          json => {
            updateUserDesk(json)
            KBPopover.close()
            successAction({
              message: '取消入驻成功'
            })
          },
          errors => {
            updateUserActions.failure(errors)
            KBPopover.close()
          }
        )
      }
    })

    function updateUserDesk(json) {
      const {
        json: {
          entities: { users }
        }
      } = json
      const values = Object.values(users)[0]
      let entry = {
        [assignee_id]: {
          ...values,
          ...values.user,
          id: values.user.id
        }
      }

      entry[assignee_id].desks = entry[assignee_id].desks.map(json => {
        json.user = values.user
        return json
      })

      json.json.entities.users = entry
      json.json.entities.orgUsers = entry
      updateUserActions.success(json)
      orgUserActions.success(json)
      delete desk.assignee_id
      changeDeskOfList({
        ...desk,
        state: 'reserved'
      })
      that.refreshStatistic()
    }
  },

  refreshStatistic() {
    const { getLocationInfo, loc_id } = this.props
    api.getLocationInfo(loc_id).then(
      json => {
        getLocationInfo.success(json)
        this.refreshAreaStatistic()
      },
      errors => {
        getLocationInfo.failure(errors)
      }
    )
  },

  refreshAreaStatistic() {
    const { loc_id, getAreasAction } = this.props
    api
      .getAreas({
        location_id: loc_id,
        includes: 'subscriptions,organizations',
        area_type: 'office',
        per_page: 1000
      })
      .then(
        json => {
          getAreasAction.success(json, { location_id: loc_id })
          return true
        },
        errors => {
          getAreasAction.failure(errors)
        }
      )
  },

  clearSelected() {
    this.setState({
      selectedDeskIds: []
    })
  },

  render() {
    let { desks, areas, isManager, loc_id } = this.props
    const { selectedDeskIds } = this.state

    return (
      <div>
        {desks.length > 0 ? (
          <div className="m-none" style={{ paddingTop: 15, width: '100%' }}>
            <KBTipsy content="全选将只会选中所有“未出租”状态的工位">
              <button
                className="bordered-btn m-none m-right-sm"
                onClick={this.selectAllFreeDesks}
              >
                全选
              </button>
            </KBTipsy>
            {isManager && selectedDeskIds.length > 0 ? (
              <button
                className="c-btn-delete m-none m-right-sm"
                onClick={this.deleteAllSelectedDesks}
              >
                删除选中
              </button>
            ) : null}
            {selectedDeskIds.length > 0 ? (
              <span>
                已选中&nbsp;
                <span className="color-link">{selectedDeskIds.length}</span>
                &nbsp;个工位
              </span>
            ) : null}
            {selectedDeskIds.length > 0 ? (
              <span
                className="m-left-sm hover-delete"
                onClick={this.clearSelected}
                style={{ color: '#999' }}
              >
                <i className="iconfont icon-Uploadfailed" />
                取消选中
              </span>
            ) : null}
          </div>
        ) : null}
        <div className="station-all" style={{ border: 'none' }}>
          {areas &&
            desks &&
            desks.map(desk => {
              let area = areas[desk.area]
              let state = desk.state || 'free'
              const { id } = desk
              const sub = desk.contract
              return (
                <div className="kb_dropdown">
                  {desk.assignee_id ? (
                    <DeskDetail
                      key={id}
                      desk={desk}
                      {...this.props}
                      desks={desks}
                      state={state}
                      deskStyle={this.deskStyle}
                      sub={sub || {}}
                      clearMemberDesk={this.clearMemberDesk}
                    />
                  ) : (
                      <div
                        className={`station-box station-${Constants.DESK_STATES_CLASS[state]
                          } ${selectedDeskIds.includes(id)
                            ? 'station-box-selected'
                            : ''
                          }`}
                        onClick={this.clickDeskBox.bind(null, desk)}
                      >
                        {selectedDeskIds.includes(id) ? (
                          <div className="selected-mark">
                            <i className="iconfont icon-right m-none" />
                          </div>
                        ) : null}
                        <span style={this.deskStyle(desk.serial_number)}>
                          {desk.serial_number}
                        </span>
                        <div className="station-message">
                          <span
                            className="station-status"
                            style={{ display: 'block' }}
                          >
                            {Constants.DESK_STATES[state]}
                          </span>
                        </div>
                        <div
                          className="station-popup-box"
                          style={{ bottom: '84px' }}
                        >
                          <div className="station-popup">
                            <div
                              style={{
                                backgroundColor: '#000',
                                filter: 'alpha(opacity=0)',
                                opacity: 0,
                                height: 5
                              }}
                            ></div>
                            <div className="popup-container">
                              <div className="parking-detail">
                                <div>
                                  <div className="parking-title">
                                    <span>
                                      工位号：{desk ? desk.serial_number : ''}
                                    </span>
                                  </div>
                                </div>
                                <div />
                              </div>
                              {desk.organization_id || sub ? (
                                <div className="parking-state-info">
                                  {desk.organization_id ? (
                                    <div className="info-line">
                                      <i className="iconfont icon-company" />
                                      <span>公司：</span>
                                      <Link
                                        to={`/admin/locations/${loc_id}/organizations/${desk.organization_id}`}
                                        className="color-link"
                                      >
                                        {(desk.organization &&
                                          desk.organization.name) ||
                                          ''}
                                      </Link>
                                    </div>
                                  ) : null}
                                  {sub ? (
                                    <div className="info-line">
                                      <i className="iconfont icon-contract" />
                                      <span>合同编号：</span>
                                      <Link
                                        to={`/admin/locations/${loc_id}/organizations/${desk.organization_id}/subscriptions/${sub.id}`}
                                        className="color-link"
                                      >
                                        {sub.serial || ''}
                                      </Link>
                                    </div>
                                  ) : null}
                                  {sub ? (
                                    <div className="info-line">
                                      <i className="iconfont icon-timer" />
                                      <span>合同结束日期：</span>
                                      <span>
                                        {formatYearDayCHS(sub.end_date)}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}

                              {isManager ? (
                                <div className="parking-operation">
                                  {state === 'free' ? (
                                    <button
                                      className="certain-delete-btn"
                                      onClick={this.deleteDesk.bind(null, desk)}
                                    >
                                      删除
                                    </button>
                                  ) : null}
                                  {(state === 'reserved' ||
                                    state === 'approving') &&
                                    desk.contract_id ? (
                                      <button
                                        className="certain-btn"
                                        onClick={this.memberDeskForm.bind(
                                          null,
                                          desk
                                        )}
                                      >
                                        入驻
                                      </button>
                                    ) : null}
                                  {state === 'reserved' &&
                                    desk.reservation_type ===
                                    'DeskReservationsDesk' ? (
                                      <button
                                        className="certain-btn"
                                        onClick={this.clearMemberDesk.bind(
                                          null,
                                          desk
                                        )}
                                      >
                                        取消预订
                                      </button>
                                    ) : null}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              )
            })}
        </div>
      </div>
    )
  }
})

DeskList.PropTypes = {
  desks: PropTypes.array.isRequired,
  areas: PropTypes.object.isRequired
}

function mapStateToProps(state, props) {
  const { user } = state
  const { loc_id } = props
  const areas = selectors.getEntities(state, 'areas')
  let isManager = canAccessLocation(user, loc_id)

  return {
    areas,
    isManager,
    loc_id
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getLocationInfo: bindActionCreators(locationActions.update, dispatch),
    updateUserActions: bindActionCreators(usersActions.update, dispatch),
    deleteDesksAction: bindActionCreators(deskActions.delete, dispatch),
    updateDesksAction: bindActionCreators(deskActions.update, dispatch),
    orgUserActions: bindActionCreators(orgUserActions.update, dispatch),
    getAreasAction: bindActionCreators(areaActions.all, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeskList)
