import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { activitiesAction, successState } from 'app/actions'
import {
  toImageProxyDirect,
  formatHmN4TwoDate,
  formatYMDN4TwoDate
} from 'utils/kbUtil'
import { ACTIVITY_STATUS_OBJ, ACTIVITY_STATUS_TAG_OBJ } from 'app/constants'
import {
  KBLoadingContainer,
  KBStatisticNumber,
  KBAvatar,
  KBTipsy,
  KBPopover
} from 'components'
import * as selectors from 'app/selectors'
import moment from 'moment'
import MobilePreview from './MobilePreview'
import AnnouncementForm from '../announcements/AnnouncementForm'
import { getUserInfoUrl } from 'utils/kbUrl'
import { accurateMath } from 'utils/math'

let ActivitiesInfo = React.createClass({
  displayName: 'ActivitiesInfo',

  getInitialState() {
    return {
      loading: true,
      signInCode: '',
      showSignInCode: false,
      activityDetailCode: '',
      showActivityDetailCode: false
    }
  },
  componentDidMount() {
    const { activity_id, getActivitiesAction } = this.props
    api
      .getActivitiesInfo(activity_id)
      .then(
        json => {
          getActivitiesAction.success(json)
        },
        error => {
          getActivitiesAction.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  toActivitiesList() {
    const { routerActions, loc_id } = this.props
    if (loc_id) {
      routerActions.push(`/admin/locations/${loc_id}/activities`)
    } else {
      routerActions.push(`/admin/memberships/memberManage/activities`)
    }
  },

  toEditActivity() {
    const { routerActions, loc_id, activity } = this.props
    const { id } = activity
    if (loc_id) {
      routerActions.push(
        `/admin/locations/${loc_id}/activities/edit?act_id=${activity.id}`
      )
    } else {
      routerActions.push(
        `/admin/memberships/memberManage/activities/edit?act_id=${activity.id}`
      )
    }
  },

  toPublish(model) {
    const { updateActivitiesAction, successActions } = this.props
    const { id } = model

    KBPopover.plugins.confirm('发布', '确定发布该活动吗？', {
      confirm: () => {
        api
          .updateActivities(id, { status: 'active' })
          .then(
            json => {
              successActions({ message: '发布成功！' })
              updateActivitiesAction.success(json)
            },
            error => {
              updateActivitiesAction.failure(error)
            }
          )
          .finally(() => {
            KBPopover.close()
          })
      }
    })
  },

  deleteActivity(model) {
    const {
      deleteActivitiesAction,
      routerActions,
      successActions,
      loc_id
    } = this.props
    const { id } = model
    KBPopover.plugins.confirm('删除活动', '确定删除该活动吗？', {
      confirm: () => {
        api.deleteActivity(id).then(
          json => {
            deleteActivitiesAction.success(json)
            successActions({ message: `成功删除活动:${model.title}` })
            KBPopover.close()
            if (loc_id) {
              routerActions.push(`/admin/locations/${loc_id}/activities`)
            } else {
              routerActions.push(`/admin/memberships/memberManage/activities`)
            }
          },
          error => {
            deleteActivitiesAction.failure(error)
          }
        )
      }
    })
  },

  toSingPeople() {
    const { routerActions, loc_id, activity } = this.props
    if (loc_id) {
      routerActions.push(
        `/admin/locations/${loc_id}/activities/${activity.id}/orders`
      )
    } else {
      routerActions.push(
        `/admin/memberships/memberManage/activities/${activity.id}/orders`
      )
    }
  },

  escape(str) {
    return str.replace(/<\/script/g, '<\\/script').replace(/<!--/g, '<\\!--')
  },

  previewOnMobile() {
    const { activity } = this.props
    KBPopover.show(<MobilePreview qrcode_url={activity.preview_qrcode} />)
  },

  createAnnouncement() {
    const { activity } = this.props
    let link = activity.detail_url || ''
    let title = activity.title
    let image = activity.cover
    KBPopover.show(
      <AnnouncementForm
        initialValues={{ link, title, image }}
        disableLinkEditable={true}
      />
    )
  },

  unDowmActivity() {
    const {
      activity: { on_sales, id },
      updateActivitiesAction,
      successActions
    } = this.props
    const title = on_sales ? '下架' : '上架'
    const content = on_sales ? '确定下架该活动吗？' : '确定上架该活动吗？'
    const msg = on_sales ? '下架成功！' : '上架成功！'
    KBPopover.plugins.confirm(title, content, {
      confirm: () => {
        api
          .updateActivities(id, { on_sales: !on_sales })
          .then(
            json => {
              successActions({ message: msg })
              updateActivitiesAction.success(json)
            },
            error => {
              updateActivitiesAction.failure(error)
            }
          )
          .finally(() => {
            KBPopover.close()
          })
      }
    })
  },

  getQrCode(type) {
    const { activity } = this.props
    const { showSignInCode, showActivityDetailCode } = this.state
    if (
      (type === 'signInCode' && !showSignInCode) ||
      (type === 'activityDetailCode' && !showActivityDetailCode)
    ) {
      if (type === 'signInCode') {
        this.setState({
          signInCode: activity.bridgego_signin_qrcode,
          showSignInCode: true,
          showActivityDetailCode: false
        })
      } else {
        this.setState({
          activityDetailCode: activity.bridgego_qrcode,
          showActivityDetailCode: true,
          showSignInCode: false
        })
      }
      // api
      //   .getActivityQrCode(activity.id, {
      //     qr_type: type === 'signInCode' ? 'signin_qr' : 'ac_detail_qr',
      //     source: 'bridgego',
      //     with: 1280
      //   })
      //   .then(res => {
      //     const {
      //       json: { url }
      //     } = res
      //     if (type === 'signInCode') {
      //       this.setState({
      //         signInCode: url,
      //         showSignInCode: true,
      //         showActivityDetailCode: false
      //       })
      //     } else {
      //       this.setState({
      //         activityDetailCode: url,
      //         showActivityDetailCode: true,
      //         showSignInCode: false
      //       })
      //     }
      //   })
    } else {
      this.setState({ showSignInCode: false, showActivityDetailCode: false })
    }
  },

  downloadCodeImg(type) {
    const { signInCode, activityDetailCode } = this.state
    let filename //图片名称
    let filetype //图片类型
    let path = type === '1' ? signInCode : activityDetailCode
    if (path.indexOf('/') > 0) {
      let file = path.substring(path.lastIndexOf('/') + 1, path.length)
      let fileArr = file.toLowerCase().split('.')
      filename = fileArr[0]
      filetype = fileArr[1]
    }
    let canvas = document.createElement('canvas')
    let img = document.createElement('img')
    img.onload = function(e) {
      canvas.width = img.width
      canvas.height = img.height
      let context = canvas.getContext('2d')
      context.drawImage(img, 0, 0, img.width, img.height)
      canvas.toBlob(blob => {
        let a = document.createElement('a')
        //a.href : blob:http://127.0.0.1:5500/fdd7df7a-c953-4a0f-a4ec-8bb9d09056d8
        a.href = window.URL.createObjectURL(blob)
        a.download = filename
        a.click()
      }, `image/${filetype}`)
    }
    img.setAttribute('crossOrigin', 'Anonymous')
    img.src = type === '1' ? signInCode : activityDetailCode
  },

  render() {
    const { activity } = this.props
    const {
      cover,
      title,
      start_time,
      end_time,
      tags,
      creator,
      location,
      details,
      activity_guests,
      contacts_phone,
      area,
      status,
      address,
      status_tag,
      on_sales
    } = activity
    const {
      loading,
      signInCode,
      activityDetailCode,
      showSignInCode,
      showActivityDetailCode
    } = this.state
    let now = new Date()
    let changeTime = moment(start_time).format('YYYY/MM/DD')
    let startTime = new Date(changeTime).valueOf()

    return (
      <div className="nav-section-container f-right">
        <KBLoadingContainer loading={loading}>
          <header className="nav-section-header clear-fix border-bottom">
            <div className="nav-section-header-title f-left">
              <span
                className="color-nine c-pointer a-hover"
                onClick={this.toActivitiesList}
              >
                活动
              </span>
              <i
                className="iconfont icon-navigate_next color-nine"
                style={{ marginLeft: 15, marginRight: 15 }}
              />
              <span>{title}</span>
            </div>
            <div className="f-right">
              <span
                className="bordered-btn-end f-right"
                onClick={this.deleteActivity.bind(this, activity)}
              >
                删除
              </span>
              <span
                className="bordered-btn f-right"
                onClick={this.toEditActivity}
              >
                编辑
              </span>
              <div
                className="bordered-btn f-right"
                onClick={this.previewOnMobile}
              >
                手机预览
              </div>
              {status && status === 'active' ? (
                <div
                  className="bordered-btn f-right"
                  onClick={this.createAnnouncement}
                >
                  创建公告
                </div>
              ) : null}
              <dev
                className="bordered-btn f-right"
                onClick={this.unDowmActivity}
              >
                {on_sales ? '下架' : '上架'}
              </dev>
              {status && status === 'draft' ? (
                <span
                  className="bordered-btn f-right"
                  onClick={this.toPublish.bind(this, activity)}
                >
                  发布
                </span>
              ) : (
                ''
              )}
            </div>
          </header>
          {
            activity.can_reserve && <div className="activity-chart">
              <div className="chart-box">
                <KBStatisticNumber
                  number={activity.total_tickets || 0}
                  name="活动人数"
                  unit="人"
                />
              </div>
              <div className="chart-box a-hover" onClick={this.toSingPeople}>
                <a>
                  <KBStatisticNumber
                    number={activity.total_sign_up || 0}
                    name="报名人数"
                    unit="人"
                  />
                </a>
              </div>
            </div>
          }
          {activity && Object.keys(activity).length > 0 ? (
            <div style={{ padding: 30 }}>
              <div className="activity-main">
                <div className="act_main_img">
                  <img
                    src={toImageProxyDirect(cover, 375, 250)}
                    alt=""
                    style={{ marginBottom: 20 }}
                  />
                </div>
                <div className="activity-content">
                  <div style={{ position: 'relative' }}>
                    {
                      activity.can_reserve && <button
                        className="bordered-btn"
                        onClick={() => this.getQrCode('signInCode')}
                      >
                        用户签到码
                      </button>
                    }
                    <button
                      className="bordered-btn"
                      onClick={() => this.getQrCode('activityDetailCode')}
                    >
                      活动详情码
                    </button>
                    {showSignInCode && (
                      <div
                        style={{
                          width: '180px',
                          // height:'140px',
                          background:
                            'linear-gradient(360deg, rgb(69, 164, 247) 0%, rgb(200, 231, 255) 100%) ',
                          position: 'absolute',
                          borderRadius: 4,
                          top: 40,
                          right: 210,
                          zIndex: 1000,
                          padding: 10,
                          textAlign: 'center'
                        }}
                      >
                        <img
                          src={signInCode}
                          style={{ margin: '10px', width: 140, height: 140 }}
                          alt=""
                        />
                        <button
                          className="certain-btn"
                          onClick={() => this.downloadCodeImg('1')}
                        >
                          下载签到码
                        </button>
                      </div>
                    )}
                    {showActivityDetailCode && (
                      <div
                        style={{
                          width: '180px',
                          // height:'140px',
                          background:
                            'linear-gradient(360deg, rgb(69, 164, 247) 0%, rgb(200, 231, 255) 100%) ',
                          position: 'absolute',
                          borderRadius: 4,
                          top: 40,
                          right: 95,
                          zIndex: 1000,
                          padding: 10,
                          textAlign: 'center'
                        }}
                      >
                        <img
                          src={activityDetailCode}
                          style={{ margin: '10px', width: 140, height: 140 }}
                          alt=""
                        />
                        <button
                          className="certain-btn"
                          onClick={() => this.downloadCodeImg('2')}
                        >
                          下载小程序码
                        </button>
                      </div>
                    )}
                  </div>
                  {/*<div className="activity-title" style={ {color: '#404040', cursor: 'default'} }>{ title }</div>*/}
                  <span
                    className={`activity-status activity-status_${
                      status === 'active' ? status_tag : status
                    }`}
                    style={{ display: 'inline-block', marginBottom: '15px' }}
                  >
                    {status === 'active'
                      ? ACTIVITY_STATUS_TAG_OBJ[status_tag]
                      : ACTIVITY_STATUS_OBJ[status]}
                  </span>
                  {tags && tags.length > 0 ? (
                    <div className="activity-space_between">
                      <i
                        className="iconfont icon-tag"
                        style={{ marginRight: 10 }}
                      />
                      {tags.map((tag, index) => {
                        return <span className="act-info-tag">{tag}</span>
                      })}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="activity-space_between">
                    <i
                      className="iconfont icon-time"
                      style={{ marginRight: 10 }}
                    />
                    {formatYMDN4TwoDate(start_time, end_time)}
                    <span style={{ marginLeft: 10 }}>
                      {formatHmN4TwoDate(start_time, end_time)}
                    </span>
                  </div>
                  <div
                    className="activity-space_between"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <i
                      className="iconfont icon-account_circle"
                      style={{ marginRight: 10 }}
                    />
                    <span style={{ marginRight: 10 }}>创建人:</span>
                    <KBAvatar
                      style={{ display: 'inline-block', marginRight: '5px' }}
                      imgStyle={{ marginRight: 10 }}
                      user={creator}
                      size={20}
                    />
                    <Link
                      className="color-link"
                      to={getUserInfoUrl(creator.id)}
                    >
                      {creator.name}
                    </Link>
                    <i
                      className="iconfont icon-icon_phone"
                      style={{ marginRight: 10, marginLeft: 20 }}
                    />
                    <span>{contacts_phone || '无'}</span>
                  </div>
                  <div className="activity-space_between">
                    <i
                      className="iconfont icon-position"
                      style={{ marginRight: 10 }}
                    />
                    {address ? (
                      <span>{address}</span>
                    ) : (
                      <span>
                        {(location && location.name) || '无'}{' '}
                        {area && area.name}
                      </span>
                    )}
                  </div>
                  {
                    activity.can_reserve && <div className="activity-tickets-list">
                      <div>
                        <i
                          className="iconfont icon-money"
                          style={{ marginRight: 10, marginBottom: 3 }}
                        />
                      </div>
                      <div style={{ display: 'inline-block' }}>
                        <div className="activity-ticket">
                          <span>
                            {activity.min_price == 0
                              ? '免费'
                              : `${accurateMath.mul(activity.min_price, 100)} 积分`}
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              {activity_guests && activity_guests.length > 0 ? (
                <div
                  className="activity-guests clear-fix"
                  style={{ marginTop: 30 }}
                >
                  <div className="kb-title">
                    <span>嘉宾</span>
                  </div>
                  <div>
                    {activity_guests.map(guest => {
                      return (
                        <div
                          className="guest-card guest-box"
                          style={{ marginLeft: 0, marginBottom: 0 }}
                        >
                          <div>
                            <KBAvatar
                              style={{
                                display: 'inline-block',
                                marginRight: '5px'
                              }}
                              imgStyle={{ marginRight: 10 }}
                              user={guest}
                              size={50}
                            />
                          </div>
                          <div className="guest-message">
                            <span>{guest.name}</span>
                            <span>{guest.title}</span>
                            {/* <span>{ guest.brief }</span> */}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}
              <div
                className="activity-introduce clear-fix"
                style={{ marginTop: 30 }}
              >
                <div className="kb-title">
                  <span>活动介绍</span>
                </div>
                <div className="m-top-sm">
                  <div
                    className="news-content"
                    dangerouslySetInnerHTML={{
                      __html: details ? this.escape(details) : '暂无活动介绍'
                    }}
                  />
                  {/* <FroalaEditorView model={details}/> */}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let loc_id = props.params.id
  let activity_id = props.params.activity_id
  let activity = selectors.getActivitiesInfo(state, activity_id)
  return {
    loc_id,
    activity_id,
    activity
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getActivitiesAction: bindActionCreators(activitiesAction.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    updateActivitiesAction: bindActionCreators(
      activitiesAction.update,
      dispatch
    ),
    deleteActivitiesAction: bindActionCreators(
      activitiesAction.delete,
      dispatch
    )
  }
}

ActivitiesInfo = connect(mapStateToProps, mapDispatchToProps)(ActivitiesInfo)

export default ActivitiesInfo
