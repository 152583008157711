import React from 'react'
import { checkObject, checkName, encryptionPhone } from 'utils/kbUtil'
import { api, apiUtils } from 'app/services'
import { usersActions } from 'app/actions'
import { Link } from 'react-router'
import { KBPopover, KBAvatar, KBTableWithoutData, KBTipsy } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { connect } from 'react-redux'
import { getSerializeLocationObj, getLocation } from 'app/selectors'
import { MEMBER_GENDER_OBJ, ORG_ROLES_OBJ } from 'app/constants'
import { bindActionCreators } from 'redux'
import { checkNull } from 'utils/kbUtil'
import { canAccessLocation, canAdminLocationWeChat } from 'app/reducers/role'
import OrganizationMemberForm from 'containers/organization/OrganizationMemberForm'
import _ from 'lodash'
import { getUserInfoUrl, getUrlOfOrgDetailPage } from 'utils/kbUrl'
import { PopoverShowPicture } from 'components/views'

var MembersList = React.createClass({
  displayName: 'MembersList',

  getInitialState() {
    return {
      users: this.props.users
    }
  },

  componentWillReceiveProps(nextProps) {
    const users = nextProps.users
    if (!_.isEqual(users, this.props.users)) {
      this.setState({
        users
      })
    }
  },

  editMember(member) {
    const { loc_id, refreshMember } = this.props
    // const org = member.organizations && member.organizations[0] && member.organizations[0]
    let m = Object.assign({}, member, {
      // role: member.roles && member.roles.find(o => o.group_id == org.id).role || 'employee',
      birth_year: checkNull(member.birth_year),
      birth_month: checkNull(member.birth_month),
      birth_day: checkNull(member.birth_day)
    })
    console.log('编辑会员信息');
    KBPopover.show(
      <OrganizationMemberForm
        updateCallBack={()=>refreshMember&&refreshMember()}
        member={m}
        type="update"
        loc_id={loc_id}
        initialValues={m}
        defaultSkills={
          m.skill_list &&
          m.skill_list.map(json => {
            return { id: json, name: json }
          })
        }
      />
    )
  },

  dismissMember(member, org_id) {
    KBPopoverConfirm({
      name: '会员离职',
      context: `您确定要离职会员${member.name || member.id}吗?`,
      callback: () => {
        return this.memberDismiss(member, org_id)
      }
    })
  },

  deleteMember(member, org_id) {
    KBPopoverConfirm({
      name: '删除会员',
      context: `您确定要删除会员${member.name || member.id}吗?`,
      callback: () => {
        return this.memberDismiss(member, org_id, true)
      }
    })
  },

  memberDismiss(member, org_id, delete_d = false) {
    const { refreshMember } = this.props
    return api
      .deleteOrgnizationMember(member.id, org_id, { delete: delete_d })
      .then(
        json => {
          this.props.deleteUserActions.success(json, {
            key: 'users',
            id: member.id
          })
          refreshMember&&refreshMember()
          let users = this.state.users
          this.setState({
            users: users && users.filter(m => m.id !== member.id)
          })

          KBPopover.close()
        },
        errors => {
          this.props.deleteUserActions.failure(errors)
          KBPopover.close()
        }
      )
  },

  clickPhoto(url) {
    KBPopover.show(<PopoverShowPicture photoUrl={url} />)
  },

  render() {
    const { loc_id, isManager, org_id, getMemberUrl } = this.props
    const { users } = this.state
    return (
      <table className="content-table">
        <thead>
          <tr>
            <th>姓名</th>
            <th>face++ 照片</th>
            <th style={{ width: 40 }}>性别</th>
            <th>昵称</th>
            <th>公司</th>
            <th>职务</th>
            <th>邮箱</th>
            <th>电话</th>
            {isManager ? <th className="t-left">操作</th> : null}
          </tr>
        </thead>
        <KBTableWithoutData
          hasData={users.length > 0}
          tableHeadNum="8"
          tipMessage="暂无会员信息"
        >
          {users &&
            users.map((user, index) => {
              const organizations = user.organizations
              return (
                <tr key={index}>
                  <td>
                    <div className="vf-center">
                      <KBAvatar
                        user={user}
                        imgStyle={{ marginRight: 10 }}
                        style={{ display: 'inline-block', marginRight: '10px' }}
                        size={35}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div className="vf-center">
                          <Link
                            to={`${
                              getMemberUrl
                                ? getMemberUrl(user.id)
                                : getUserInfoUrl(user.id)
                            }?type=members`}
                            className="color-link t-over"
                            style={{ display: 'inline-block', maxWidth: 100 }}
                          >
                            {user.name}
                          </Link>
                          {user.space_member ? (
                            <KBTipsy content="空间员工">
                              <i
                                className="iconfont icon-people"
                                style={{
                                  marginLeft: 10,
                                  fontSize: '15px',
                                  color: '#999'
                                }}
                              />
                            </KBTipsy>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {user.face_photo_uploaded ?
                    // (
                    //   <img
                    //     className="face-img-in-table"
                    //     onClick={this.clickPhoto.bind(
                    //       null,
                    //       user.face_photo.url
                    //     )}
                    //     src={user.face_photo.url}
                    //     alt="Face++ Img"
                    //   />
                    // )
                    '已上传'
                    : (
                      '未上传'
                    )}
                  </td>
                  <td>{MEMBER_GENDER_OBJ[user.gender] || ''}</td>
                  <td>
                    <div className="vf-center">
                      <Link
                        to={
                          getMemberUrl
                            ? getMemberUrl(user.id)
                            : getUserInfoUrl(user.id)
                        }
                        className="color-link t-over"
                        style={{ maxWidth: 100, display: 'inline-block' }}
                      >
                        {user.nickname}
                      </Link>
                    </div>
                  </td>
                  <td>
                    {(user.organizations &&
                      user.organizations.map(org => {
                        return (
                          <div>
                            <Link
                              className="color-link"
                              to={getUrlOfOrgDetailPage(loc_id, org.id)}
                            >
                              {org.name}
                            </Link>
                          </div>
                        )
                      })) || <span>{user.org_name}</span>}
                  </td>
                  <td>{user.title || ''}</td>
                  <td>{user.email}</td>
                  <td>
                    <span
                      className="t-over"
                      style={{ display: 'inline-block', maxWidth: 140 }}
                    >
                      {user.phone_num}
                    </span>
                  </td>
                  {isManager ? (
                    <td
                      className="t-left"
                      style={{ whiteSpace: 'nowrap', width: 100 }}
                    >
                      <KBTipsy content="编辑">
                        <i
                          className="iconfont icon-edit"
                          alt="编辑员工"
                          onClick={() => this.editMember(user)}
                          style={{ marginRight: '15px ' }}
                        />
                      </KBTipsy>
                      {/* {
                      loc_id && (organizations.length === 1 || org_id) ? (
                        <KBTipsy content='离职'>
                          <i className="iconfont icon-crm_status_5" alt="离职员工" onClick={(e) => {this.dismissMember(user, org_id || (organizations && organizations[0].id));e.stopPropagation();}} style={{marginRight: '15px '}}/>
                        </KBTipsy>
                      ): null
                    } */}
                      {loc_id && (organizations.length === 1 || org_id) ? (
                        <KBTipsy content="删除">
                          <i
                            className="iconfont icon-delete"
                            alt="删除员工"
                            onClick={e => {
                              this.deleteMember(
                                user,
                                org_id || (organizations && organizations[0].id)
                              )
                              e.stopPropagation()
                            }}
                          />
                        </KBTipsy>
                      ) : null}
                    </td>
                  ) : null}
                </tr>
              )
            })}
        </KBTableWithoutData>
      </table>
    )
  }
})

MembersList.PropTypes = {
  users: React.PropTypes.array,
  loc_id: React.PropTypes.number.isRequired,
  org_id: React.PropTypes.number.isRequired
}

function mapStateToProps(state, props) {
  var users = props.users || []

  const { user } = state
  const { loc_id } = props
  let isManager = canAccessLocation(user, loc_id) || canAdminLocationWeChat(user, loc_id)
  const locationsObj = getSerializeLocationObj(state) || {}

  return {
    users,
    isManager,
    locationsObj
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    deleteUserActions: bindActionCreators(usersActions.delete, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersList)
