import React from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import {
  paginate,
  queryFilter,
  getFilterAndQuery,
  getServiceErrorMessage,
} from 'utils/kbUtil'
import { apiCallFailure, successState } from 'app/actions'
import {
  KBPagination,
  KBLoadingContainer,
  KBTable
} from 'components'
import { canAccessLocation } from 'app/reducers/role'
import moment from 'moment'
import TableSearch from 'components/v2/TableSearch'
import { Typography, Tooltip, Tag } from 'antd'
import {allStatus, allTypes} from './constants';
import _ from 'lodash';


var CouponList = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      filters,
      loading: false,
      data: [],
      pagination: null,
      locations: [],
      organizations: [],
      tableColumns: [
        { title: '类型', key: 'type', render: (record) => {
          const {coupon_type} = record
          return allTypes.find(item => item.value === coupon_type).title
        } },
        {
          title: '名称',
          key: 'name',
          render: (record) => {
            return <a className='color-link' onClick={() => this.toInfo(record.id)}>{record.name}</a>
      }
        },
        { title: '折扣', key: 'coupon_discount' },
        { title: '适用范围',  key: 'areas', render: (record) => {
          const text = ((_.get(record.areas, 'length') ? record.areas : record.locations) || []).map(i => i.name).join('、')
          return <Tooltip title={text}>
            <Typography.Paragraph ellipsis style={{width: 300}}>
            {text}
            </Typography.Paragraph>
          </Tooltip>

        } },
        { title: '领取有效期', key: 'ddd', render: (record) => {
          return `${moment(record.start_at).format('YYYY年MM月DD日')}-${moment(record.end_at).format('YYYY年MM月DD日')}`
        }},
        { title: '领券数/总券数', key: 'eee', align: 'center', render: (record) => {
          return `${record.user_coupon_templates_count}/${record.total_number}`
        }},
        { title: '用券数/领券数', key: 'fff', align: 'center', render: (record) => {
          return `${record.user_coupon_templates_used_count}/${record.user_coupon_templates_count}`
        }},
        {
          title: '状态',
          dataIndex: 'approved_at',
          key: 'approved_at',
          width: 110,
          render: record => {
              const { status } = record
              const statusTag = {
                expired: <Tag>已过期</Tag>,
                down: <Tag>已下架</Tag>,
                up: <Tag color="green">已上架</Tag>,
                draft: <Tag color="orange">草稿</Tag>
              }
              return statusTag[status]
          }
        }
      ],
    }
  },

  componentDidMount() {
    this.mounted = true
    this._loadData(1)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page !== nextProps.page) {
      this._loadData(nextProps.page, { ...this.state.filters, ...filters })
    }
  },

  _loadData(page, filters) {
    const { locationId, apiCallFailureAction, query, routerActions } = this.props
    const per_page = 10
    let pageObj = { per_page, page }
    let params = Object.assign({}, pageObj, filters, {
      location_id: locationId || undefined,
    })
    let queryFiltersStr = queryFilter({ ...query, ...pageObj, ...filters })
    this.setState({ loading: true })
    api
      .getCouponTemplateList(params)
      .then(
        json => {
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          if (locationId) {
            routerActions.replace(
              `/admin/locations/${locationId}/coupon${queryFiltersStr}`
            )
          } else {
            routerActions.replace(`/admin/coupon${queryFiltersStr}`)
          }
          let pagination = paginate(json.response)
          let data = (json.json || [])


          this.setState({
            pagination,
            data
          })
        },
        errors => {
          apiCallFailureAction({
            status: 'error',
            message: getServiceErrorMessage(errors)
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters })
    this._refresh()
  },

  _refresh() {
    setTimeout(this.showFiltered)
  },

  _clearFilters() {
    this.setState({ filters: {} })
    this._refresh()
  },

  showFiltered() {
    const { filters } = this.state

    let params = Object.assign({}, filters)

    this._loadData(filters.page || 1, { ...params })
    this.setState({
      ...params
    })
  },

  _renderFilters() {
    const { filters, locations, organizations } = this.state
    const { locationId } = this.props
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters &&
      selectFilters.filter(filter => filter !== '' && filter != undefined)

    return (
      <div style={{ paddingTop: 25 }}>
        <TableSearch
          label="选择类型"
          value={filters.coupon_type}
          options={allTypes.map(i => ({label: i.title, value: i.value}))}
          onChange={val => this._setFilter({ coupon_type: val || undefined })}
        />
        <TableSearch
          label="选择状态"
          value={filters.status}
          options={allStatus}
          onChange={val => this._setFilter({ status: val || undefined })}
        />
        {selectFilters.length > 0 ? (
          <div
            className="clear-criteria"
            style={{ marginTop: 0 }}
            onClick={this._clearFilters}
          >
            <i className="iconfont icon-close" />
            <span>清除所有筛选条件</span>
          </div>
        ) : null}
      </div>
    )
  },

  toCouponForm() {
    const { routerActions, locationId } = this.props
    if (locationId) {
      routerActions.push(`/admin/locations/${locationId}/coupon/couponForm`)
    } else {
      routerActions.push(`/admin/coupon/couponForm`)
    }
  },

  toInfo(id) {
    const { routerActions, locationId } = this.props
    if (locationId) {
      routerActions.push(`/admin/locations/${locationId}/coupon/${id}/info`)
    } else {
      routerActions.push(`/admin/coupon/${id}/info`)
    }
  },

  render() {
    const { loading, pagination, data } = this.state
    const { locationId } = this.props

    return (
      <section className="kb-content-container clear-fix">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>优惠券列表</span>
          </h2>
          <div className="f-right">
            <button className="bordered-btn" onClick={this.toCouponForm}>
              创建优惠券
            </button>
          </div>
        </header>
        <div className="nav-section-content-container">
          {this._renderFilters()}
          <KBLoadingContainer loading={loading}>
            <KBTable columns={this.state.tableColumns} dataSource={data} />
            <KBPagination
              pagination={pagination}
              template={
                locationId
                  ? `/admin/locations/${locationId}/coupon?page=#PAGE#`
                  : `/admin/coupon?page=#PAGE#`
              }
            />
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const locationId = params.id

  let page = parseInt(location.query && location.query.page, 10)
  page = isNaN(page) ? 1 : page

  var FILTER_STATE = ['location_id', 'organization_id', 'status']
  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)

  return {
    locationId,
    page,
    ...paramsAll
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponList)
