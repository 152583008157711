import React from 'react'
import { Router, Route, Link } from 'react-router'

var AreaLink = React.createClass({
  displayName: 'AreaLink',

  render() {
    const { loc_id, area } = this.props

    return (
      <Link to={`/admin/locations/${loc_id}/desks?area_id=${area.id}`}>
        {' '}
        {area.name}{' '}
      </Link>
    )
  }
})

AreaLink.PropTypes = {
  loc_id: React.PropTypes.number.isRequired,
  area: React.PropTypes.object.isRequired
}

export default AreaLink
