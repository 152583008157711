import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'

var KBTextarea = React.createClass({
  value() {
    return this.input.value
  },
  onChange(e) {
    const { onChange, field } = this.props
    const value = e.target.value
    field.onChange(value)
    onChange && onChange(value)
  },
  render() {
    const {
      field,
      placeholder,
      disabled,
      className,
      title,
      titleClass,
      txtStyle,
      divStyle,
      childDivStyle,
      isRequired,
      errorStyle,
      hint,
      isRequiredR,
      maxLength
    } = this.props
    return (
      <div className={className} style={divStyle}>
        {title && (
          <label className={titleClass}>
            {isRequiredR ? <span className="must-fillR">*</span> : ''}
            {title}
            {isRequired ? <span className="must-fill">*</span> : ''}
          </label>
        )}
        <div style={childDivStyle}>
          <textarea
            ref={ref => {
              this.input = ref
            }}
            className={classNames(
              this.props.className,
              field.touched && field.error ? 'kb-input-error' : 'kb-input'
            )}
            placeholder={placeholder}
            onChange={this.onChange}
            style={txtStyle}
            value={field.value || ''}
            disabled={disabled}
            maxLength={maxLength}
          />
          {field.touched && field.error ? (
            <p className="lr-error f-left" style={errorStyle}>
              {field.error}
            </p>
          ) : (
            <p
              style={{
                color: '#c8c8c8',
                fontSize: 12,
                padding: '2px 0 0 11px'
              }}
            >
              {hint}
            </p>
          )}
        </div>
      </div>
    )
  }
})

KBTextarea.propTypes = {
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string
}

KBTextarea.defaultProps = {
  type: 'text'
}

export default KBTextarea
