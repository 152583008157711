import React, { Component, PropTypes } from 'react'
import {
  ORG_SUBSCRIPTION_STATES,
  SUBSCRIPTION_TYPES,
  SUBSCRIPTION_TYPES_OBJ,
  INVOICES_CONTRACT_STATES
} from 'app/constants'
import {
  KBDropdown,
  KBPagination,
  KBRangeDate,
  KBPopover,
  KBTableWithoutData,
  KBLoadingContainer
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import classNames from 'classnames'
import { api, apiUtils } from 'app/services'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { subscriptionsAction, successState } from 'app/actions'
import {
  getSubScriptions,
  getSerializeLocationObj,
  getCurrentUser
} from 'app/selectors'
import { bindActionCreators } from 'redux'
import {
  getEntitiesJsonArray,
  paginate,
  getFilterAndQuery,
  queryFilter,
  kbCloseDropDown
} from 'utils/kbUtil'
import { canAdminSpace } from 'app/reducers/role'
import InvoicesContractBlock from './InvoicesContractBlock'
import { getInitialDate } from 'utils/kbData'
import { KBDayPicker } from 'components/views'
import moment from 'moment'
import OnlyTimeReportForm from './OnlyTimeReportForm'
import _ from 'lodash'

const DROP_DOWNS = [
  'statusDropdown',
  'location_idDropdown',
  'dateDropDown',
  'filterDate',
  'subscriptionTypeDropdown'
]

var InvoicesContract = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      filters,
      subscriptions: [],
      pagination: null,
      isContractSetting: false,
      loading: false,
      page: this.props.page || 1
    }
  },

  componentDidMount() {
    this.mounted = true
    this.getSubscriptions()

    // api.getSpaceSetting().then(({ json }) => {
    //   this.setState({
    //     isContractSetting : this.isContractApprovalSetting(json.settings)
    //   })
    // })
  },

  componentWillUnmount() {
    this.mounted = false
  },

  /**
   * 是否拥有审批合同的权利
   * @param  {[type]}  settings [当前空间的setting]
   * @return {Boolean}          [是否通过]
   */
  isContractApprovalSetting(settings) {
    const { user } = this.props
    const setting = settings && settings.contract_role_setting
    if (canAdminSpace(user)) {
      return true
    }

    if (!setting || !settings) {
      return false
    }

    if (setting == 'none') {
      return false
    }

    if (!setting.user_group) {
      return false
    }

    let users = setting.user_group.split(',') || []

    if (users.find(user_id => user_id == user.id)) {
      return true
    }
  },

  // 解析在数据加载时的 from to
  parseLoadFromTo(params, from, to) {
    delete params.from
    delete params.to

    params.start_date = from ? getInitialDate(from).initStartDateString : null
    params.end_date = to ? getInitialDate(to).initEndDateString : null

    return params
  },

  getSubscriptions(newPage) {
    const { getSubscriptionsAction, routerActions } = this.props
    const { filters } = this.state
    const { from, to } = filters
    const preFilters = this.props.filters
    if (from && to) {
      filters.from = from && moment(from).format('YYYY-MM-DD')
      filters.to = to && moment(to).format('YYYY-MM-DD')
    } else {
      delete filters.from
      delete filters.to
    }
    const loc_id = this.getLocationId()
    const per_page = 10
    const finalFilters = filters || preFilters
    let params = Object.assign(
      {},
      { per_page, page: newPage || this.props.page },
      loc_id ? { location_id: loc_id } : {},
      finalFilters
    )

    // 判断日期
    params = this.parseLoadFromTo(params, from, to)

    let queryFiltersStr = queryFilter({
      ...finalFilters,
      page: newPage || this.props.page
    })
    this.setState({ loading: true })
    api
      .getSubscriptions({ ...params })
      .then(
        json => {
          let pagination = paginate(json.response)
          getSubscriptionsAction.success(json, {
            per_page: per_page,
            ...pagination
          })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin${
              this.props.location_id
                ? `/locations/${this.props.location_id}`
                : ''
            }/invoices${queryFiltersStr}`
          )
          let subscriptions = getEntitiesJsonArray(json, 'subscriptions')
          this.setState({
            subscriptions,
            pagination
          })
        },
        errors => {
          getSubscriptionsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.getSubscriptions(nextProps.page)
    }
  },

  getLocationId() {
    const { filters } = this.state
    const { location_id } = this.props

    let loc_id = filters.location_id || location_id

    if (loc_id) {
      return loc_id
    }

    return false
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    Object.keys(obj).map(key => {
      if (!obj[key]) {
        delete newFilters[key]
      }
    })
    this.setState({ filters: newFilters, page: 1 })
    this.location_idDropdown && this.location_idDropdown.hide()
    this.statusDropdown && this.statusDropdown.hide()
    this.filterDate && this.filterDate.hide()
    this.subscriptionTypeDropdown && this.subscriptionTypeDropdown.hide()

    setTimeout(this.getSubscriptions.bind(null, 1))
  },

  _clearFilters() {
    const { routerActions } = this.props
    this.setState({ filters: {}, page: 1 })
    setTimeout(this.getSubscriptions.bind(null, 1))
    routerActions.replace(
      `/admin${
        this.props.location_id ? `/locations/${this.props.location_id}` : ''
      }/invoices`
    )
  },

  changeDate(range) {
    this._setFilter(range)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },
  checkArray(data) {
    if (_.get(data, 'length', 0) > 0) {
      return true
    }
    return false
  },
  _renderFilters() {
    const { locations, location_id, isOnlyAdmin } = this.props
    const { filters } = this.state
    const { from, to, signed_at } = this.state.filters
    const loc_id = this.getLocationId()

    return (
      <div className="nav-section-action-bar">
        <div className="select-progress">
          <KBDropdown
            ref={ref => {
              this.statusDropdown = ref
            }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'statusDropdown')}
              >
                <i className="iconfont icon-finance" />
                <span>
                  {this.state.filters.status
                    ? ORG_SUBSCRIPTION_STATES[this.state.filters.status]
                    : '选择进度'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li onClick={() => this._setFilter({ status: '' })}>全部</li>
                  {INVOICES_CONTRACT_STATES.map((state, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => this._setFilter({ status: state.id })}
                      >
                        <span>{state.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        </div>
        <div className="select-progress">
          <KBDropdown
            ref={ref => {
              this.subscriptionTypeDropdown = ref
            }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(
                  null,
                  'subscriptionTypeDropdown'
                )}
              >
                <i className="iconfont icon-finance" />
                <span>
                  {this.state.filters.subscription_type
                    ? SUBSCRIPTION_TYPES_OBJ[
                        this.state.filters.subscription_type
                      ]
                    : '合同类型'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  {SUBSCRIPTION_TYPES.map((type, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          this._setFilter({ subscription_type: type.id })
                        }
                      >
                        <span>{type.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        </div>
        {location_id && locations && Object.keys(locations).length > 0 ? (
          <div
            className="task-status-title"
            style={{ display: 'inline-block' }}
          >
            <i className="iconfont icon-position" />
            <span>{(locations && locations[location_id].name) || ''}</span>
          </div>
        ) : (
          <KBDropdown
            ref={ref => {
              this.location_idDropdown = ref
            }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'location_idDropdown')}
              >
                <i className="iconfont icon-position" />
                <span>
                  {loc_id && this.checkArray(locations)
                    ? locations[loc_id].name
                    : '选择分店'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li onClick={() => this._setFilter({ location_id: '' })}>
                    全部
                  </li>
                  {locations &&
                    Object.keys(locations).map((location_id, index) => {
                      let location = locations[location_id]
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ location_id: location.id })
                          }
                        >
                          <span>{location.name}</span>
                        </li>
                      )
                    })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        )}

        <KBDropdown
          ref={ref => {
            this.filterDate = ref
          }}
          onClick={this.closeDropDowns.bind(null, 'filterDate')}
        >
          <KBDropdown.KBDropdownTrigger>
            <div className="task-status-title" style={{ marginLeft: 20 }}>
              <i
                className="iconfont icon-calendar"
                style={{ marginRight: '3px' }}
              />
              <span>
                选择签约日期
                {signed_at ? ': ' + moment(signed_at).format('YYYY-MM-DD') : ''}
              </span>
              <span className="task-deg" />
            </div>
          </KBDropdown.KBDropdownTrigger>
          <KBDropdown.KBDropdownContent>
            <KBDayPicker
              onChange={date =>
                this._setFilter({
                  signed_at: moment(date).format('YYYY-MM-DD')
                })
              }
              selectedDay={moment(signed_at).toDate()}
            />
          </KBDropdown.KBDropdownContent>
        </KBDropdown>

        <KBRangeDate
          title="选择合同起止日范围"
          from={from || ''}
          to={to || ''}
          callback={this.changeDate}
          ref={ref => {
            this.dateDropDown = ref
          }}
          dropDown_name="dateDropDown"
          closeClick={this.closeDropDowns}
        />
      </div>
    )
  },

  subscriptionsReport() {
    const { location_id } = this.props
    KBPopover.show(<OnlyTimeReportForm loc_id={location_id} type="contract" />)
  },

  deleteSubCallback(sub) {
    let { subscriptions } = this.state
    _.remove(subscriptions, function(s) {
      return s.id === sub.id
    })
    this.setState({
      subscriptions
    })
  },

  render() {
    const { isOnlyAdmin } = this.props
    const { subscriptions, pagination, isContractSetting, loading } = this.state
    const newSubscriptions = this.props.subscriptions
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')

    var subscriptionArray = subscriptions
      ? subscriptions.filter(subscription => {
          if (!this.state.filters.status) {
            return true
          }

          return subscription.status == this.state.filters.status
        })
      : []

    subscriptionArray = subscriptionArray.map(sub => {
      let subscription = newSubscriptions.find(
        subscription => subscription.id == sub.id
      )
      if (subscription) {
        return subscription
      } else {
        return sub
      }
    })

    return (
      <div className="kb-content-container clear-fix">
        <div>
          <header className="nav-header">
            <div className=" clear-fix">
              <div className="nav-section-header-title f-left">
                <span>合同审批</span>
              </div>
              {!isOnlyAdmin&&<button
                className="c-btn-secondary f-right"
                onClick={this.subscriptionsReport}
              >
                导出合同信息
              </button>}
            </div>
          </header>
          <div className="nav-section-content-container">
            <div className="m-updown">
              {this._renderFilters()}
              {selectFilters.length > 0 ? (
                <div className="clear-criteria" onClick={this._clearFilters}>
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              ) : null}
            </div>
            <KBLoadingContainer loading={loading}>
              <table className="content-table edit-table">
                <thead>
                  <tr style={{ whiteSpace: 'nowrap' }}>
                    <th>公司名称</th>
                    <th>编号</th>
                    <th>合同类型</th>
                    <th>状态</th>
                    <th>起止日期</th>
                    <th>可入驻</th>
                    <th>已入驻</th>
                    <th>会员费总额</th>
                    <th>保证金</th>
                    <th>税额</th>
                    <th>不含税额</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={subscriptionArray.length > 0}
                  tableHeadNum="8"
                  tipMessage="暂无合同"
                >
                  {subscriptionArray &&
                    subscriptionArray.map((m, index) => (
                      <InvoicesContractBlock
                        isContractSetting={isContractSetting}
                        subscription={m}
                        key={index}
                        loc_id={this.props.location_id}
                        deleteCallback={this.deleteSubCallback}
                      />
                    ))}
                </KBTableWithoutData>
              </table>
              <KBPagination
                pagination={pagination}
                template={`/admin${
                  this.props.location_id
                    ? `/locations/${this.props.location_id}`
                    : ''
                }/invoices?page=#PAGE#`}
              />
            </KBLoadingContainer>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location, params } = props
  let location_id = params.id
  let user = getCurrentUser(state)
  let page = parseInt(location.query && location.query.page) || 1
  let subscriptions = getSubScriptions(state)
  let locations = getSerializeLocationObj(state)
  const { space_roles } = user
  let isOnlyAdmin = false
  if(space_roles&&space_roles.length===1&&space_roles[0].role==='admin'){
    isOnlyAdmin= true
  }

  // let locations = getLocations(state)
  var FILTER_STATE = [
    'from',
    'to',
    'status',
    'location_id',
    'subscription_type'
  ]

  var param = getFilterAndQuery(location.query, FILTER_STATE)
  return {
    user,
    subscriptions,
    locations,
    location_id,
    page,
    isOnlyAdmin,
    ...param
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSubscriptionsAction: bindActionCreators(
      subscriptionsAction.get,
      dispatch
    ),
    updateSubscriptionsAction: bindActionCreators(
      subscriptionsAction.update,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    deleteSubscriptionsAction: bindActionCreators(
      subscriptionsAction.delete,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesContract)
