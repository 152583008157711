import React, { Component, PropTypes } from 'react'
import { KBPopover } from 'components'

var PopoverShowVideo = React.createClass({
  displayName: 'PopoverShowVideo',
  render() {
    const { videoUrl } = this.props

    return (
      <div>
        <header className="kb-form-header">
          <h2 className="header-title">视频预览</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="show-picture">
          <video
            alt="视频展示"
            src={videoUrl}
            controls="controls"
            autoPlay="autoPlay"
            style={{ height: '100%', width: '100%' }}
          />
        </div>
      </div>
    )
  }
})

PopoverShowVideo.propTypes = {
  videoUrl: React.PropTypes.string.isRequired
}

export default PopoverShowVideo
