import _ from 'lodash'
import { get, unsetObject, updateArray } from './reducer_utils'

function error(state = {}, action) {
  let status =
    get(action, ['errors', 'code']) ||
    get(action, ['error', 'code']) ||
    get(action, ['error', 'status']) ||
    get(action, ['status'])
  let msg = get(action, ['errors', 'message'])
  const error = action.errors || action.error
  if (action.type === 'CLEAR_ERROR_STATE') {
    return {}
  }

  let message = ''
  let errors = action.errors
  let isErrors = (errors && errors.status == 'failed') || false

  if ((!status || status < 400) && !msg && !isErrors) {
    return state
  }

  if (status >= 500) {
    return mergeErrorState(state, {message: '服务器出错，请稍后重试'}, status)
  }

  switch (status) {
    case 401:
      message = '用户登录信息过期，请重新登录'
      break
    case 403:
      let errorMsg = error403(error)
      message = errorMsg ? errorMsg : '用户登录信息过期，请重新登录'
      break
    case 'error':
      message = getErrorMsg(error)
      break
    case 404:
      message = '页面不存在'
      break
    case 422:
      message = getErrorMsg(error)
      break
    default:
      message = '服务器开小差了，请稍后重试'
  }

  return mergeErrorState(state, {
    message,
    status: status,
    title: error && error.title
  })
}

function mergeErrorState(state, data = {}) {
  return _.merge(
    {},
    state,
    { message: data.message, status: data.status },
    data && data.title ? { title: data && data.title } : {}
  )
}

function getErrorMsg(error) {
  return (error._error && error._error.message) || error.message
}

function error403(error) {
  if (error._error && getErrorMsg(error) == 'Forbidden') {
    return '当前用户没有权限操作此项'
  }

  let errorMsg = getErrorMsg(error)
  if (errorMsg) {
    return errorMsg
  }

  return null
}

export default error
