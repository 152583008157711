import _ from 'lodash'

function spaceSetting(state = {}, action) {
  const data = action.response
  let newState = _.cloneDeep(state)
  if (action.type === 'GETSPACESSETTING_SUCCESS') {
    newState = data.json
    return newState
  }

  if (action.type === 'UPDATESPACESSETTING_SUCCESS' && action.key) {
    let key = action.key
    newState[key] = Object.assign({}, newState[key], data.json[key])
    return newState
  }

  return state
}

export default spaceSetting
