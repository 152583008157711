import React, { Component, PropTypes } from 'react'
import { api } from 'app/services'
import {
  KBPopoverTop,
  KBToReportCenter
} from 'components'

const ExportButton = React.createClass({
  getInitialState() {
    return {}
  },

  exportData() {
    const { couponId } = this.props
    api.exportCouponTemplate({ coupon_template_id: couponId }).then(
      res => {
        return KBPopoverTop.show(
          <KBToReportCenter
            title="Excel导出"
            message="Excel已成功导出，可以前往报表中心下载"
          />
        )
      },
      err => {
        console.log(err)
      }
    )
  },

  render() {
    return (
      <span className="bordered-btn f-right" onClick={this.exportData}>
        导出领券明细
      </span>
    )
  }
})

export default ExportButton
