import { unsetObject, updateArray } from './reducer_utils'
import _ from 'lodash'

// Saves location orgs in entities.locations.[loc_id].org_ids
var locations = (state = {}, action) => {
  const { type, key, value } = action

  if (!key) {
    return state
  }

  if (type === 'CREATELOADING_SUCCESS' && value + '') {
    let st = Object.assign({}, state, { [key]: value })
    return st
  }

  if (type === 'DELETELOADING_SUCCESS') {
    return unsetObject(state, `${action.key}`)
  }

  if (type === 'UPDATELOADING_SUCCESS' && value + '') {
    let st = Object.assign({}, state, { [key]: value })
    return st
  }

  return state
}

export default locations
