import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, getValues } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { ADD_AREA } from 'app/constants/hint'
import { KBButton } from 'components'
import ReactDOM from 'react-dom'
import _ from 'lodash'

var DeskNumber = React.createClass({
  getInitialState() {
    return {
      deskNumbers: [],
      loading: false,
      prefix: ''
    }
  },

  // returns parsed input as array
  // either: [1, 2, 3, 4] or [5] or []
  _parseDeskInput(inputText) {
    const { deskNumbers } = this.state
    if (inputText.indexOf('-') > 0) {
      let pairs = inputText.split('-')
      let start = parseInt(pairs[0])
      let end = parseInt(pairs[1])

      if (!isNaN(start) && !isNaN(end) && end > start) {
        let result = []
        for (let i = start; i <= end; i++) {
          result.push(i)
        }
        return result
      }
      return null
    } else {
      let result = parseInt(inputText)
      return isNaN(result) ? null : [result]
    }
  },

  addDesks() {
    const { deskNumberInput } = this.refs
    const { number } = this.props
    let deskNumberInputValue = ReactDOM.findDOMNode(deskNumberInput).value
    let deskInputs = deskNumberInputValue.split(',')
    let inputNumberArrays = []

    deskInputs.map((deskInput, index) => {
      let result = this._parseDeskInput(deskInput)

      if (result) {
        inputNumberArrays = [...inputNumberArrays, ...result]
      }
    })
    let count = isNaN(parseInt(number)) ? 10 : parseInt(number)

    if (inputNumberArrays.length > count) {
      inputNumberArrays = inputNumberArrays.filter((number, index) => {
        if (index <= count - 1) {
          return true
        }
      })
    }
    this.addDeskState(inputNumberArrays, 'manual')
  },

  addDeskState(array, type) {
    const { prefix, deskNumbers } = this.state
    const { number } = this.props

    var numObj = array.map(json => {
      return {
        serial_number: json,
        prefix
      }
    })
    let selectDesks = [...numObj]
    let sortedDeskNumbers = _.uniqBy(selectDesks, 'serial_number').sort(
      (a, b) => a.serial_number - b.serial_number
    )
    let totalDeskNumbers =
      deskNumbers.length < number
        ? deskNumbers.concat(sortedDeskNumbers)
        : sortedDeskNumbers
    totalDeskNumbers = [...new Set(totalDeskNumbers)]
    totalDeskNumbers =
      totalDeskNumbers.length > number
        ? totalDeskNumbers.slice(0, number)
        : totalDeskNumbers
    totalDeskNumbers.sort((a, b) => {
      return a.serial_number - b.serial_number
    })
    let lastDeskNumber = totalDeskNumbers[totalDeskNumbers.length - 1]
    let maxNumber = lastDeskNumber && lastDeskNumber.serial_number
    let maxDigit = (maxNumber && maxNumber.toString().length) || 0
    totalDeskNumbers.map(num => {
      let numDigit =
        (num.serial_number && num.serial_number.toString().length) || 0
      if (maxDigit - numDigit > 0) {
        for (let i = 0; i < maxDigit - numDigit; i++) {
          num.serial_number = '0' + `${num.serial_number}`
        }
      }
      return num
    })
    this.setState({
      deskNumbers: totalDeskNumbers
    })

    this.changeField(
      totalDeskNumbers.map(desk => desk.prefix + desk.serial_number).join(',')
    )
    // reset form
    this.refs.deskNumberInput.value = ''
    this.refs.deskNumberInput.focus()
  },

  removeDesk(removeIndex) {
    let removedSortedDeskNumbers = this.state.deskNumbers.filter(
      (i, index) => index !== removeIndex
    )
    const { prefix } = this.state

    this.setState({
      deskNumbers: removedSortedDeskNumbers
    })

    this.changeField(
      removedSortedDeskNumbers
        .map(desk => desk.prefix + desk.serial_number)
        .join(',')
    )
  },

  getAvailableDesks() {
    const { loc_id, number } = this.props
    this.setState({ loading: true })
    api.getAvailableDeskNumbers(loc_id, { count: number }).then(({ json }) => {
      this.addDeskState(json || [], 'auto')
      this.setState({ loading: false })
    })
  },

  changeField(value) {
    const { field } = this.props
    field && field.onChange(value)
  },

  changePrefix(e) {
    var prefix = e.target.value

    if (!prefix || prefix == ' ') {
      this.setState({
        prefix: ''
      })
      return null
    }

    this.setState({
      prefix
    })
  },

  deleteAllDeskNums() {
    this.changeField('')
    this.setState({
      deskNumbers: []
    })
  },

  render() {
    const { deskNumbers, loading, prefix, deskPrefixNumbers } = this.state
    const { error } = this.props
    const hint = ADD_AREA
    return (
      <div className="kb-row">
        <div className="kb-row">
          <div className="kb-form-group kb-form-c18 kb-form-alone">
            <label>工位前缀</label>
            <div>
              <input
                type="text"
                className="kb-input"
                name="deskPrefix"
                onChange={this.changePrefix}
                value={prefix}
              />
            </div>
          </div>
        </div>
        <div className="kb-row kb-form-66">
          <div className="kb-div-c39 kb-form-group">
            <label>工位号</label>
            <div>
              <input
                type="text"
                className="kb-input"
                name="capacity"
                ref="deskNumberInput"
              />
              <p style={{ color: '#c8c8c8', fontSize: 12, textAlign: 'left' }}>
                {hint.station_num}
              </p>
            </div>
          </div>
          <div className="kb-form-group kb-div-c18">
            <label>&nbsp;</label>
            <div className="kb-two-button">
              <KBButton
                className="bordered-btn"
                type="button"
                onClick={this.addDesks}
              >
                生成
              </KBButton>
              {/*<a href="javascript:void(0)" className='color-link' style={{ lineHeight: '35px' }} onClick={ this.getAvailableDesks } >{ loading ? 'Loading' : '自动添加工位号' }</a>*/}
            </div>
          </div>
        </div>
        {deskNumbers && deskNumbers.length > 0 ? (
          <div>
            <div
              className="clear-criteria"
              style={{ marginTop: 5 }}
              onClick={this.deleteAllDeskNums}
            >
              <i className="iconfont icon-close" />
              <span>删除全部工位号</span>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="kb-row">
          <div className="kb-form-group kb-form-c18 kb-form-alone">
            <label />
            <div className="desks-block">
              {deskNumbers &&
                deskNumbers.map((deskNumber, index) => (
                  <div key={index}>
                    <span>{deskNumber.prefix + deskNumber.serial_number}</span>
                    <i
                      className="iconfont icon-close"
                      onClick={() => this.removeDesk(index)}
                    />
                  </div>
                ))}
              {error && <p className="lr-error">{error}</p>}
            </div>
          </div>
        </div>
      </div>
    )
  }
})

DeskNumber.propTypes = {
  field: PropTypes.object.isRequired
}
export default DeskNumber
