import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { KBInput, KBFormError, KBPopover, KBQiniuFileUpload } from 'components'
import classNames from 'classnames'
import config from 'app/config'

var KBMoreFilesUpload = React.createClass({
  getInitialState() {
    const { defaultFiles, prefix } = this.props
    return {
      files: [],
      uploading: false,
      prefix: prefix ? prefix : config.uploadDir + 'subscription/',
      uploadProgress: null,
      uploaded: false,
      uploadFileUrl: null,
      fileList: defaultFiles || []
    }
  },

  getDefaultProps() {
    return {
      baseUrl: config.qiniu_base_url,
      photoShow: true
    }
  },

  changeField(value) {
    const { onChange }= this.props
    if(onChange){
      onChange(value)
    }else{
      this.props.field.onChange(value)
    }
  },

  onUpload(files) {
    // set onprogress function before uploading
    this.setState({
      uploading: true,
      uploaded: false,
      uploadProgress: 0,
      uploadFileUrl: null
    })
    var _this = this
    files.map(function(f) {
      f.onprogress = function(e) {
        _this.setState({ uploadProgress: e.percent })
      }
    })
  },

  updateProgress(percent) {
    this.setState({ uploadProgress: percent })
  },

  onDrop(files) {
    const { fileList } = this.state
    const { type } = this.props
    var temp = Object.assign([], fileList)
    this.setState({
      files
    })
    // files is a FileList(https://developer.mozilla.org/en/docs/Web/API/FileList) Object
    // and with each file, we attached two functions to handle upload progress and result
    // file.request => return super-agent uploading file request
    // file.uploadPromise => return a Promise to handle uploading status(what you can do when upload failed)
    // `react-qiniu` using bluebird, check bluebird API https://github.com/petkaantonov/bluebird/blob/master/API.md
    // see more example in example/app.js
    var _this = this
    files.map(f => {
      f.uploadPromise.then(response => {
        // let url = isPublic ? response.url : (this.state.showUrl + response.body.key)
        // let name = isPublic ? response.name : response.body['x:filename']
        let url = response.url
        let name = response.name
        temp.push({
          type,
          name: name.substring(0, name.lastIndexOf('.')),
          file_url: url
        })

        _this.setState({
          uploading: false,
          uploaded: true,
          uploadFileUrl: url,
          fileList: temp
        })

        _this.changeField(temp)
      })
    })
  },

  setUrlAndToken({ domain, token, upload_url }) {
    this.setState({
      uploadToken: token,
      showUrl: domain,
      baseUrl: upload_url
    })
  },

  componentDidMount() {
    const { isAzureUpload, azure_url } = config

    if (isAzureUpload) {
      this.setState({
        showUrl: azure_url
      })
      return
    }

    api.getUploadToken().then(({ json }) => {
      this.setUrlAndToken(json)
    })
  },

  _reset() {
    this.setState({ uploading: false, uploaded: false, uploadFileUrl: null })
  },

  renderProgress() {
    return this.state.uploading ? (
      <div
        className={classNames(
          this.props.buttonClass ? this.props.buttonClass : 'post-btn-ing'
        )}
      >
        <div
          className="post-btn-progress"
          style={{ width: this.state.uploadProgress + '%' }}
        ></div>
        <div className="post-btn-content">
          <span>{this.props.uploadingText || '正在上传'}</span>
        </div>
      </div>
    ) : null
  },

  renderUploaded() {
    if (this.state.uploaded) {
      return (
        <div className="post-btn-success">
          <div className="post-btn-progress" />
          <div className="post-btn-content">
            <span>{this.props.uploadedText || '上传完毕'}</span>
          </div>
          <a onClick={this._reset}>×</a>
        </div>
      )
    }
    return null
  },

  renderUploadField() {
    const { multiple, field, accept, isPublic } = this.props
    const { prefix } = this.state
    return (this.state.uploadToken || config.isAzureUpload) &&
      !this.state.uploading ? (
      <KBQiniuFileUpload
        uploadUrl={this.state.baseUrl}
        onDrop={this.onDrop}
        multiple={multiple}
        token={this.state.uploadToken}
        prefix={prefix}
        updateProgress={this.updateProgress}
        onUpload={this.onUpload}
        accept={accept}
        isPublic={isPublic}
      >
        <div
          className={classNames(
            this.props.buttonClass ? this.props.buttonClass : 'post-btn-default'
          )}
        >
          <div className="post-btn-progress"></div>
          <div className="post-btn-content">
            <span>
              {field.pristine
                ? this.props.text
                : this.props.uploadedText || '上传完毕'}
            </span>
          </div>
        </div>
      </KBQiniuFileUpload>
    ) : null
  },

  removeFile(index) {
    const { fileList } = this.state

    var tempFile = Object.assign([], fileList)
    tempFile.splice(index, 1)

    this.setState(
      Object.assign({}, this.state, {
        fileList: tempFile
      })
    )

    this.changeField(tempFile)
  },

  render() {
    const {
      className,
      titleClass,
      title,
      field,
      hint,
      isRequired,
      imgStyle,
      sort
    } = this.props

    return (
      <div className={className}>
        {title && (
          <label className={titleClass}>
            {title}
            {isRequired ? <span className="must-fill">*</span> : ''}
          </label>
        )}
        {this.renderUploadField()}
        {this.renderProgress()}
        {field.touched && field.error ? (
          <p className="lr-error">{field.error}</p>
        ) : (
          <p
            style={{ color: '#c8c8c8', fontSize: 12, padding: '2px 0 0 11px' }}
          >
            {hint}
          </p>
        )}
      </div>
    )
  }
})

KBMoreFilesUpload.propTypes = {
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  prefix: PropTypes.string,
  type: PropTypes.string,
  multiple: PropTypes.bool,
  text: PropTypes.string.isRequired,
  uploadingText: PropTypes.string,
  uploadedText: PropTypes.string,
  accept: PropTypes.string
}

export default KBMoreFilesUpload
