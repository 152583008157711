import React from 'react'
import {
  KBDropdown,
  KBPagination,
  KBRangeDate,
  KBPopover,
  KBTableWithoutData,
  KBLoadingContainer,
  KBConfirmModal
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { api } from 'app/services'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { successState, invoicingPoolsAction } from 'app/actions'
import {
  getSerializeLocationObj,
  getCurrentUser,
  getInvoicingPools
} from 'app/selectors'
import { bindActionCreators } from 'redux'
import {
  paginate,
  kbCloseDropDown,
  queryFilter,
  getFilterAndQuery,
  formatYearDaySOL
} from 'utils/kbUtil'
import { getInitialDate } from 'utils/kbData'
import InvoicingPoolForm from './InvoicingPoolForm'
import { canAccessLocation } from 'app/reducers/role'

const DROP_DOWNS = ['applyDateDropDown', 'location_idDropdown']

var InvoicingPoolList = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      filters,
      pagination: null,
      loading: false
    }
  },

  componentDidMount() {
    this.mounted = true

    this.getInvocingPoolList()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  getInvocingPoolList(newPage) {
    const { filters } = this.state
    const {
      routerActions,
      query,
      location_id,
      getInvoicingPoolsAction
    } = this.props
    const per_page = 10
    let pageObj = {
      per_page,
      page: newPage || this.props.page
    }

    let params = Object.assign({}, filters, location_id ? { location_id } : {})
    let queryFiltersStr = queryFilter({ ...query, ...params, ...pageObj })

    this.setState({ loading: true })
    api
      .getInvoicingPools({ ...params, ...pageObj })
      .then(
        json => {
          getInvoicingPoolsAction.success(json)
          let pagination = paginate(json.response)
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin${
              this.props.location_id
                ? `/locations/${this.props.location_id}`
                : ''
            }/invoices/invoicing_pool${queryFiltersStr}`
          )
          if (this.mounted) {
            this.setState({
              pagination
            })
          }
        },
        errors => {
          getInvoicingPoolsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.getInvocingPoolList(nextProps.page)
    }
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters })
    this.refs.location_idDropdown && this.refs.location_idDropdown.hide()

    setTimeout(this.getInvocingPoolList)
  },

  _clearFilters() {
    const { routerActions } = this.props
    this.setState({ filters: {} })
    setTimeout(this.getInvocingPoolList)
    routerActions.replace(
      `/admin${
        this.props.location_id ? `/locations/${this.props.location_id}` : ''
      }/invoices/invoicing_pool`
    )
  },

  changeUpdateRange(rage) {
    let applyDateRange = {}
    applyDateRange.purchase_date_start = rage.from
      ? getInitialDate(rage.from).initStartDateString
      : null
    applyDateRange.purchase_date_end = rage.to
      ? getInitialDate(rage.to).initEndDateString
      : null
    applyDateRange.purchase_date_start &&
      applyDateRange.purchase_date_end &&
      this._setFilter(applyDateRange)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  _renderFilters() {
    const { locations, location_id } = this.props
    const { filters } = this.state
    const { purchase_date_start, purchase_date_end } = filters

    return (
      <div className="nav-section-action-bar">
        <div className="select-progress">
          {location_id && Object.keys(locations).length > 0 ? (
            <div
              className="task-status-title"
              style={{ display: 'inline-block' }}
            >
              <i className="iconfont icon-position" />
              <span>{locations[location_id].name || ''}</span>
            </div>
          ) : (
            <KBDropdown ref="location_idDropdown">
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(
                    null,
                    'location_idDropdown'
                  )}
                >
                  <i className="iconfont icon-position" />
                  <span>
                    {this.state.filters.location_id &&
                    Object.keys(locations).length > 0
                      ? locations[this.state.filters.location_id].name
                      : '选择分店'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ location_id: '' })}>
                      全部
                    </li>
                    {locations &&
                      Object.keys(locations).map((location_id, index) => {
                        let location = locations[location_id]
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              this._setFilter({ location_id: location.id })
                            }
                          >
                            <span>{location.name}</span>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          )}
          <KBRangeDate
            from={purchase_date_start || ''}
            to={purchase_date_end || ''}
            title="选择购票日期"
            callback={this.changeUpdateRange}
            closeClick={this.closeDropDowns}
            dropDown_name="applyDateDropDown"
            ref={ref => {
              this.applyDateDropDown = ref
            }}
            leftNone={false}
          />
        </div>
      </div>
    )
  },

  addInvoicingPool() {
    const { location_id } = this.props

    KBPopover.show(<InvoicingPoolForm location_id={location_id} />)
  },

  editInvoicingPool(pool) {
    const { location_id } = this.props
    KBPopover.show(
      <InvoicingPoolForm
        isUpdate={true}
        current_pool={pool}
        location_id={location_id}
      />
    )
  },

  deleteInvoicingPool(pool) {
    const { location_id, deleteInvoicingPoolsAction } = this.props

    KBPopover.show(<KBConfirmModal
      title='删除发票库'
      context={`是否确认删除发票库：${pool.name}？`}
      callback={async ()=>{
        return api.deleteInvoicingPool(pool.id, { location_id }).then(
          json => {
            deleteInvoicingPoolsAction.success(json, {
              id: pool.id,
              key: 'invoicingPools'
            })
          },
          errors => {
            deleteInvoicingPoolsAction.failure(errors)
          }
        )
      }}/>)
    // KBPopoverConfirm({
    //   name: '删除发票库',
    //   context: `是否确认删除发票库：${pool.name}？`,
    //   callback: () => {
    //     return api.deleteInvoicingPool(pool.id, { location_id }).then(
    //       json => {
    //         deleteInvoicingPoolsAction.success(json, {
    //           id: pool.id,
    //           key: 'invoicingPools'
    //         })
    //         KBPopover.close()
    //       },
    //       errors => {
    //         deleteInvoicingPoolsAction.failure(errors)
    //       }
    //     )
    //   }
    // })
  },

  render() {
    const { pagination, loading } = this.state
    const { invoicingPools, isManager } = this.props

    return (
      <div className="kb-content-container clear-fix">
        <div>
          <header className="nav-header">
            <div className=" clear-fix">
              <div className="nav-section-header-title f-left">
                <span>发票库</span>
              </div>
              {isManager ? (
                <div className="f-right">
                  <button
                    className="bordered-btn"
                    onClick={this.addInvoicingPool}
                  >
                    添加发票库
                  </button>
                </div>
              ) : null}
            </div>
          </header>
          <div className="nav-section-content-container">
            <div>
              {this._renderFilters()}
              {Object.keys(this.state.filters).length > 0 ? (
                <div className="clear-criteria" onClick={this._clearFilters}>
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              ) : null}
            </div>
            <KBLoadingContainer loading={loading}>
              <table className="content-table">
                <thead>
                  <tr>
                    <th>名称</th>
                    <th>说明</th>
                    <th>发票初始号码</th>
                    <th>购票日期</th>
                    <th>购票数量</th>
                    <th>剩余数量</th>
                    <th className="t-right">操作</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={invoicingPools.length > 0}
                  tableHeadNum="7"
                  tipMessage="暂无发票库"
                >
                  {invoicingPools &&
                    invoicingPools.map((pool, index) => (
                      <tr key={index}>
                        <td>{pool.name || '-'}</td>
                        <td>{pool.description || '-'}</td>
                        <td>{pool.first_serial || '-'}</td>
                        <td>{formatYearDaySOL(pool.purchase_date)}</td>
                        <td>{pool.total_amount || '-'}</td>
                        <td>{pool.remain_amount || '-'}</td>
                        {isManager ? (
                          <td className="t-right">
                            <i
                              className="iconfont icon-edit"
                              onClick={this.editInvoicingPool.bind(null, pool)}
                            />
                            <i
                              className="iconfont icon-delete m-left-sm"
                              onClick={this.deleteInvoicingPool.bind(
                                null,
                                pool
                              )}
                            />
                          </td>
                        ) : (
                          <td />
                        )}
                      </tr>
                    ))}
                </KBTableWithoutData>
              </table>
            </KBLoadingContainer>
            <KBPagination
              pagination={pagination}
              template={`/admin${
                this.props.location_id
                  ? `/locations/${this.props.location_id}`
                  : ''
              }/invoices/invoicing_pool?page=#PAGE#`}
            />
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location, params } = props
  let location_id = params.id
  let user = getCurrentUser(state)
  const isManager = canAccessLocation(user, location_id)
  let page = parseInt(location.query && location.query.page, 10) || 1
  let locations = getSerializeLocationObj(state)
  let invoicingPools = getInvoicingPools(state)
  var FILTER_STATE = ['purchase_date_start', 'purchase_date_end', 'location_id']

  var param = getFilterAndQuery(location.query, FILTER_STATE)

  return {
    user,
    page,
    location_id,
    locations,
    invoicingPools,
    isManager,
    ...param
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    getInvoicingPoolsAction: bindActionCreators(
      invoicingPoolsAction.replace,
      dispatch
    ),
    deleteInvoicingPoolsAction: bindActionCreators(
      invoicingPoolsAction.delete,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicingPoolList)
