import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { routerActions } from 'react-router-redux'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { CRM_OPPORTUNITY_STATES } from 'app/constants'
import {
  KBPopover,
  KBPopoverTop,
  KBFormError,
  KBButton,
  KBDateInput,
  KBToReportCenter,
  KBUserInput
} from 'components'
import moment from 'moment'
import { getInitialDate } from 'utils/kbData'

var CrmOppReportForm = React.createClass({
  getInitialState() {
    return {}
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="客户信息导出"
        message="客户信息已成功导出，可以前往报表中心下载"
      />
    )
  },

  reportTasks(model) {
    const { start_at, end_at, state } = model
    let params = Object.assign(
      {},
      {
        start_at: getInitialDate(start_at).initStartDateString,
        end_at: getInitialDate(end_at).initEndDateString
      },
      state ? { state } : {}
    )

    return api.createReports(params).then(() => {
      this.toReportCenter && this.toReportCenter()
    })
  },

  changeStartYMDCallback(data) {
    const {
      fields: { start_at }
    } = this.props
    this.setYMD(start_at, data)
  },

  changeEndYMDCallback(data) {
    const {
      fields: { end_at }
    } = this.props
    this.setYMD(end_at, data)
  },

  setYMD(field, value) {
    let date = moment(value)
    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    field.onChange(
      moment(field.value || value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    )
  },

  render() {
    const {
      fields: { startYMD, endYMD, state },
      submitting,
      handleSubmit,
      filters
    } = this.props
    const defaultStates = (filters.state && filters.state.split(',')) || []
    const selectedState = CRM_OPPORTUNITY_STATES.filter(s =>
      defaultStates.includes(s.id)
    )

    return (
      <form onSubmit={handleSubmit(this.reportTasks)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">客户信息导出</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container-m">
          <div className="kb-row kb-form-66">
            <KBDateInput
              type="date"
              title="开始日期"
              isRequired={true}
              className="kb-form-group kb-div-48"
              field={startYMD}
              onChange={this.changeStartYMDCallback}
              hint="此日期为客户的创建日期"
            />
            <KBDateInput
              type="date"
              title="结束日期"
              titleClass="label-rt"
              className="kb-form-group kb-div-48"
              field={endYMD}
              onChange={this.changeEndYMDCallback}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="状态"
              placeholder={'请选择客户状态'}
              className="kb-form-group kb-form-210 kb-form-alone"
              field={state}
              hint={'选择要导出客户的状态，不选则默认导出所有状态的客户'}
              selectedUsers={selectedState}
              multi={true}
              users={CRM_OPPORTUNITY_STATES}
            />
          </div>
          <div className="kb-row f-right">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { filters } = props
  const initStartString = getInitialDate(filters.from || new Date())
    .initStartDateString
  const initEndString = getInitialDate(filters.to || new Date())
    .initEndDateString

  return {
    initialValues: {
      start_at: initStartString,
      end_at: initEndString,
      startYMD: initStartString,
      endYMD: initEndString,
      state: filters.state || ''
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (
    values.start_at &&
    values.end_at &&
    moment(values.end_at).diff(moment(values.start_at)) <= 0
  ) {
    errors.endYMD = '结束日期必须大于开始日期'
  }

  return errors
}

const formConfig = {
  form: 'CrmOppReportForm',
  fields: ['start_at', 'end_at', 'startYMD', 'endYMD', 'state'],
  validate: validate,
  touchOnBlur: false
}

CrmOppReportForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(CrmOppReportForm)

export default CrmOppReportForm
