import React, { Component, PropTypes } from 'react'
import config from 'app/config'
import { toImageProxyDirect } from 'utils/kbUtil'

var KBQiniuImage = React.createClass({
  render() {
    const { src, width, height, className, type } = this.props
    let url = toImageProxyDirect(config.qiniu_base_url, width, height)
    if (type) {
      url = toImageProxyDirect(src, width, height)
    }
    return (
      <img
        className={className}
        src={url}
        style={{ width: width, height: height, ...this.props.style }}
      />
    )
  }
})

KBQiniuImage.PropTypes = {
  src: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object
}

KBQiniuImage.defaultProps = {
  width: 200,
  height: 200
}

export default KBQiniuImage
