import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

var InvoicesBox = React.createClass({
  displayName: 'InvoicesBox',
  render() {
    return this.props.children
  }
})

function mapStateToProps(state, props) {
  // const { params,location } = props
  // let sections = location.pathname.split('/')
  // let section = sections.length > 2 ? sections[3] : ''

  return {
    //    section : section || ''
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesBox)
