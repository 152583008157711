import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBInput,
  KBPopover,
  KBTextarea,
  KBImageCropperInput,
  KBButton
} from 'components'
import { toImageProxyDirect } from 'utils/kbUtil'
import { notification } from 'antd'

var AvtivitiesGuestsForm = React.createClass({
  addActivitiesGuests(model) {
    const { callBack } = this.props
    callBack && callBack(model)
    KBPopover.close()
  },

  failureCallBack(err) {
    if(!err) return
    notification.error({ message: '上传失败', description: err, top: 100 })
  },

  render() {
    const {
      fields: { avatar, name, title, brief },
      error,
      submitting,
      handleSubmit,
      locations
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.addActivitiesGuests)}>
        <div className="kb-form-header">
          <h2 className="header-title">添加嘉宾</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </div>
        <div className="kb-form-container">
          <div className="kb-row" style={{ textAlign: 'left' }}>
            <KBImageCropperInput
              title="上传头像"
              aspect={false}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={avatar}
              type="activities"
              accept=".png,.jpg"
              failureCallBack={this.failureCallBack}
            >
              <div className="upload-portrait">
                {avatar && avatar.value ? (
                  <img
                    src={toImageProxyDirect(avatar.value, 150, 150)}
                    style={{
                      width: '150px',
                      height: '150px',
                      display: 'inline-block',
                      marginLeft: 0,
                      borderRadius: 0
                    }}
                  />
                ) : (
                  ''
                )}
                <div
                  className="d-iblock"
                  style={{ verticalAlign: 'middle', paddingLeft: 20 }}
                >
                  <button className="bordered-btn m-none" type="button">
                    {avatar.dirty ? '头像已上传' : '上传头像'}
                  </button>
                  <p
                    className="color-nine"
                    style={{ paddingTop: 20, fontSize: 12 }}
                  >
                    图片格式为png或jpg,文件大小不大于1M
                  </p>
                </div>
              </div>
            </KBImageCropperInput>
          </div>
          <div className="kb-row kb-form-66">
            <KBInput
              className="kb-form-group kb-div-c39"
              isRequired={true}
              field={name}
              title="姓名"
            />
            <KBInput
              className="kb-form-group kb-div-c39"
              isRequiredR={true}
              field={title}
              title="职位"
              titleClass="label-rt"
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={brief}
              placeholder="输入简介"
              title="简介"
            />
          </div>
          <div className="kb-row">
            <div className="kb-form-fr">
              <span
                className="cancel-btn"
                onClick={KBPopover.close}
                style={{ marginRight: '20px' }}
              >
                取消
              </span>
              <KBButton
                className="certain-btn kb-from-cmr"
                type="submit"
                submitting={submitting}
              >
                确定
              </KBButton>
            </div>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}
  if (valid.required(values.name)) {
    errors.name = '请输入嘉宾名字'
  }
  if (values.name && values.name.length >= 5) {
    errors.name = '名字长度不能超过四个字'
  }

  if (values.title && values.title.length >= 5) {
    errors.title = '职位长度不能超过四个字'
  }

  if (valid.required(values.title)) {
    errors.title = '请输入嘉宾职位'
  }
  return errors
}

const formConfig = {
  form: 'MemberLocationRolesForm',
  fields: ['avatar', 'name', 'title', 'brief'],
  touchOnBlur: false,
  validate: validate
}

AvtivitiesGuestsForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(AvtivitiesGuestsForm)

export default AvtivitiesGuestsForm
