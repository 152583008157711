import React, { Component, PropTypes } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import * as Constants from 'app/constants'
import { api } from 'app/services'
import { canAccessLocation } from 'app/reducers/role'
import { getUserInfoUrl } from 'utils/kbUrl'
import { formatYearDayCHS } from 'utils/kbUtil'

var DeskDetail = React.createClass({
  getInitialState() {
    return {
      remarkStatus: false,
      note: this.props.desk.note
    }
  },

  createDeskForm() {
    const { desk } = this.props
    const { note } = this.state

    const { updateDesksAction } = this.props
    return api.updateDesk(desk.id, { note }).then(
      json => {
        updateDesksAction.success(json)
      },
      errors => {
        updateDesksAction.failure(errors)
      }
    )
  },

  remarkStatusTrue() {
    this.setState({ remarkStatus: true })
  },

  remarkStatusFalse() {
    this.setState({ remarkStatus: false })
  },

  updateNote(e) {
    let value = e.target.value
    this.setState({
      note: value
    })
  },

  focusNote() {
    this.remarkStatusTrue()
  },

  blurFocus() {
    setTimeout(() => {
      this.remarkStatusFalse()
    }, 300)
  },

  deskInfo(desk) {
    const {
      loc_id,
      clearMemberDesk,
      sub,
      isManager,
      isPureReservation
    } = this.props
    const user = desk.user || {}
    return (
      <div className="station-popup-box" style={{ bottom: '84px' }}>
        <div className="station-popup">
          <div
            style={{
              backgroundColor: '#000',
              filter: 'alpha(opacity=0)',
              opacity: 0,
              height: 5
            }}
          ></div>
          <div className="popup-container">
            <div className="parking-detail">
              <div>
                <div className="parking-title">
                  <span>工位号：{desk ? desk.serial_number : ''}</span>
                </div>
              </div>
              <div />
            </div>
            <div className="parking-state-info">
              <div className="info-line">
                <i className="iconfont icon-account_circle" />
                <Link
                  to={getUserInfoUrl(desk.assignee_id)}
                  className="color-link"
                >
                  {user.name || ''}
                </Link>
              </div>
              {desk.organization_id ? (
                <div className="info-line">
                  <i className="iconfont icon-company" />
                  <span>公司：</span>
                  <Link
                    to={`/admin/locations/${loc_id}/organizations/${desk.organization_id}`}
                    className="color-link"
                  >
                    {(desk.organization && desk.organization.name) || ''}
                  </Link>
                </div>
              ) : null}
              {sub.serial ? (
                <div className="info-line">
                  <i className="iconfont icon-contract" />
                  <span>合同编号：</span>
                  <Link
                    to={`/admin/locations/${loc_id}/organizations/${desk.organization_id}/subscriptions/${sub.id}`}
                    className="color-link"
                  >
                    {sub.serial}
                  </Link>
                </div>
              ) : null}
              {sub.end_date ? (
                <div className="info-line">
                  <i className="iconfont icon-timer" />
                  <span>合同结束日期：</span>
                  <span>{formatYearDayCHS(sub.end_date)}</span>
                </div>
              ) : null}
            </div>

            {isManager ? (
              <div className="parking-operation">
                <button
                  className="certain-btn"
                  onClick={clearMemberDesk.bind(null, desk)}
                >
                  取消{isPureReservation ? '预订' : '入驻'}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  },

  render() {
    const { desk, state, deskStyle, isPureReservation } = this.props

    return (
      <div className="desk-box">
        <div
          className={`station-box station-${Constants.DESK_STATES_CLASS[state]}`}
        >
          <span style={deskStyle(desk.serial_number)}>
            {desk.serial_number}
          </span>
          <div className="station-message">
            <span className="station-status">
              {desk.user && desk.user.name}
            </span>
            <span className="station-user">
              {isPureReservation ? '已预订' : Constants.DESK_STATES[state]}
            </span>
          </div>
        </div>
        {this.deskInfo(desk)}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { loc_id, desk } = props
  let isManager = canAccessLocation(user, loc_id)
  const isPureReservation = desk.reservation_type === 'DeskReservationsDesk'

  return {
    isManager,
    loc_id,
    isPureReservation
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeskDetail)
