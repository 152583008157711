import React from 'react'

var KBNoData = React.createClass({
  displayName: 'KBNoData',

  render() {
    const { className, tipMessage, outterStyle, imgWidth } = this.props

    return (
      <div
        className={className}
        style={{ textAlign: 'center', marginTop: '40px', ...outterStyle }}
      >
        <img
          src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/que.png"
          alt="No Data"
          style={{ width: imgWidth || 180 }}
        />
        <div style={{ color: '#c3d0e9', marginTop: 15, fontSize: 14 }}>
          {tipMessage || '暂无数据'}
        </div>
      </div>
    )
  }
})

KBNoData.propTypes = {}

export default KBNoData
