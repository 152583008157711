import React from 'react';
import {parse} from 'qs';
import { api, apiUtils } from 'app/services'
import { LoginRedirect } from 'components/mixins'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { apiCallFailure, currentUserActions } from 'app/actions'
import { reduxForm } from 'redux-form'

const Authorized = React.createClass({
  mixins: [LoginRedirect],
  login(requestId) {
    const { loginAction, apiCallFailureActions, routerActions } = this.props

    return api
      .loginUser({
        request_id: requestId
      })
      .then(data => {
        console.log(123123, data)
        let loggedInUser = data.json.entities.users[data.json.result]
        const user = Object.assign({}, loggedInUser, data.json.entities)
        apiUtils.setCurrentUser(user, { remember: false })
        loginAction.success(data)
        return this.checkAccess(loggedInUser)
      })
      .catch((err) => {
        apiCallFailureActions({
          status: 'error',
          message: err.message || '授权错误，请重试'
        })
        routerActions.push('/unauthorized')
      })
  },

  componentDidMount() {
    const {requestId, apiCallFailureActions} = this.props
    if(requestId) {
      // 执行登录
      this.login(requestId)
    } else {
      apiCallFailureActions({
        status: 'error',
        message: 'requestId缺失，授权错误，请重试'
      })
      routerActions.push('/unauthorized')
    }
  },

  render() {
    return (
      // 授权成功
      <div className="lr-out-box" style={{
        textAlign: 'center'
      }}>
        <p style={{
          marginTop: '20px'
        }}>获取用户信息中...</p>
      </div>
    )
  }
})

function mapStateToProps(state, ownProps) {
  return {
    requestId: ownProps.location.query.requestId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    loginAction: bindActionCreators(currentUserActions.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
  }
}

const formConfig = {
  form: 'authorized',
  fields: [],
  touchOnBlur: false,
}

export default reduxForm(formConfig, mapStateToProps, mapDispatchToProps)(Authorized)
