import React, { Component } from 'react'
import { DatePicker } from 'antd'

// 基于antd
export default React.createClass({
  getInitialState() {
    return {}
  },
  onChange(val) {
    const { onChange, field } = this.props
    if (onChange) {
      onChange(val)
    } else {
      field.onChange(val)
    }
  },
  render() {
    const { label, type, isRequired, field, hint, ...restProps } = this.props
    return (
      <div>
        <label
          style={{
            fontSize: 11,
            margin: ' 9px 0 3px',
            color: '#7F8FA4',
            position: 'relative',
            whiteSpace: 'nowrap',
            display: 'block'
          }}
        >
          {label}
          {isRequired && <span className="must-fill">*</span>}
        </label>

        {type === 'RangePicker' && (
          <DatePicker.RangePicker
            value={field.value}
            onChange={this.onChange}
            {...restProps}
          />
        )}

        {/* 提示文字 */}
        {hint && (
          <p
            style={{
              color: '#c8c8c8',
              fontSize: 12,
              padding: '2px 0 0 11px'
            }}
          >
            {hint}
          </p>
        )}

        {/* 错误文字 */}
        {field.error && field.touched && (
          <p className="lr-error">{field.error}</p>
        )}
      </div>
    )
  }
})
