import React, { Component, PropTypes } from 'react'
import { KBPopover, KBPopoverTop } from 'components'

var PopoverShowPicture = React.createClass({
  displayName: 'PopoverShowPicture',

  closeKBPopover() {
    const { isPopoverTop } = this.props
    if (isPopoverTop) {
      KBPopoverTop.close()
      return
    }
    KBPopover.close()
  },

  render() {
    const { photoUrl } = this.props
    return (
      <div>
        <header className="kb-form-header">
          <h2 className="header-title">图片预览</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={this.closeKBPopover}
          />
        </header>
        <div className="show-picture">
          <img alt="照片展示" src={photoUrl} />
        </div>
      </div>
    )
  }
})

PopoverShowPicture.propTypes = {
  photoUrl: React.PropTypes.string.isRequired
}

export default PopoverShowPicture
