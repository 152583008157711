import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import {
  KBInput,
  KBPopover,
  KBTextarea,
  KBImageCropperInput,
  KBButton,
  KBMorePhotoUpload
} from 'components'
import { notification } from 'antd'
import { api } from 'app/services'
import { successState, apiCallFailure } from 'app/actions'

var ListingForm = React.createClass({
  approve(model) {
    const { couponInfo, callBack } = this.props
    return api.approveCouponTemplate(couponInfo.id, model).then(res => {
      this.toInfo()
      callBack && callBack()
    })
  },

  failureCallBack(err) {
    if(!err) return
    notification.error({ message: '上传失败', description: err, top: 100 })
  },

  toInfo() {
    KBPopover.close()
    const { routerActions, locationId, couponInfo } = this.props
    if (locationId) {
      routerActions.push(`/admin/locations/${locationId}/coupon/${couponInfo.id}/info`)
    } else {
      routerActions.push(`/admin/coupon/${couponInfo.id}/info`)
    }
  },

  render() {
    const {
      fields: { approved_file },
      error,
      submitting,
      handleSubmit,
      locations,
      couponInfo = {}
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.approve)}>
        <div className="kb-form-header">
          <h2 className="header-title">上架</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={this.toInfo}
          />
        </div>
        <div className="kb-form-container">
        <h5 style={{color: '#5ccd89'}}>优惠券已提交成功，请点击上传附件完成上架</h5>
        <div className="kb-row" style={{ textAlign: 'left' }}>
            <KBMorePhotoUpload
              photoShow={false}
              sort={false}
              className="kb-form-group kb-form-alone"
              field={approved_file}
              text="点击上传"
              accept=".pdf,.jpg,.jpeg,.png"
              title={`请上传审批附件，并确认上架“${couponInfo.name}”`}
              isRequired
              multiple={false}
              uploadOneTime
            />
          </div>
          <div className="kb-row">
            <div className="kb-form-fr">
              <span
                className="cancel-btn"
                onClick={this.toInfo}
                style={{ marginRight: '20px' }}
              >
                取消
              </span>
              <KBButton
                className="certain-btn kb-from-cmr"
                type="submit"
                submitting={submitting}
              >
                上架
              </KBButton>
            </div>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {
    initialValues: {
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.approved_file)) {
    errors.approved_file = '请上传审批附件'
  }

  console.log(123, values);

  return errors
}

const formConfig = {
  form: 'MemberLocationRolesForm',
  fields: ['approved_file'],
  touchOnBlur: false,
  validate: validate
}

ListingForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ListingForm)

export default ListingForm
