import React, { Component, PropTypes } from 'react'

class VerifyState extends Component {
  render() {
    return (
      <div className="lr-out-box">
        <div className="comment-box1">
          <div className="kb-box t-center">
            <span className="green-font">当前状态</span>
          </div>
          <div className="kb-box t-center unlock-previous-step">
            您的注册信息正在审核中...我们将尽快完成。
          </div>
        </div>
      </div>
    )
  }
}

export default VerifyState
