import React from 'react'
import { Router, Route, Link } from 'react-router'

var UserLink = React.createClass({
  displayName: 'UserLink',

  render() {
    const { user, className, style } = this.props

    return (
      <Link
        className={className}
        style={style}
        to={`/admin/employee/${user.id}`}
      >
        {' '}
        {this.props.children || user.name}{' '}
      </Link>
    )
  }
})

UserLink.PropTypes = {
  user: React.PropTypes.object.isRequired
}

export default UserLink
