import React, { Component, PropTypes } from 'react'

var KBSwitch = React.createClass({
  getInitialState() {
    return {
    }
  },

  changeSlide() {
    const { value, onChange } = this.props
    if(onChange) {
      onChange(!value)
    }
  },

  render() {
    const { value, title, titleWidth } = this.props

    return (
      <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
        <div style={{width: titleWidth }}>{title}</div>
        <div className="slide-btn" onClick={this.changeSlide}>
          <span
            className={`slide-bg ${value ? 'slide-left' : 'slide-right'}`}
          >
            <span className="slide-move" />
          </span>
        </div>
      </div>
    )
  }
})

KBSwitch.propTypes = {
  field: PropTypes.object.isRequired,
}

export default KBSwitch
