import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { successState, apiCallFailure } from 'app/actions'
import { routerActions } from 'react-router-redux'
import {
  KBForm,
  KBInput,
  KBButton,
  KBRadio,
  KBTextarea,
  KBPopover,
  KBImageCropperInput
} from 'components'
import Select from 'components/v2/Select'
import LocationSelect from 'components/v2/LocationSelect'
import MeetingRoomSelect from 'components/v2/MeetingRoomSelect'
import DatePicker from 'components/v2/DatePicker'
import { api } from 'app/services'
import moment from 'moment'
import ListingPopover from './ListingPopover'
import { allTypes, priceOptions, userTypes, displayTypes } from './constants'
import { getCurrentLocation } from 'utils/kbUtil'
import _ from 'lodash'

var EditValueAddedServices = React.createClass({
  getInitialState() {
    return {
      fileList: []
    }
  },

  componentDidMount() {
    const { fid } = this.props.location.query
    if (fid) {
      // 获取详情数据
      const { fields, locationId } = this.props
      api.getCouponTemplateInfo(fid).then(({ json }) => {
        ;[
          'coupon_type',
          'display',
          'name',
          'price',
          'total_number',
          'user_type',
          'coupon_limit',
          'rules',
          'status'
        ].forEach(key => {
          fields[key].onChange(json[key])
        })
        fields.coupon_discount.onChange(json.coupon_discount + '')
        fields.receiveTime.onChange([
          moment(json.start_at),
          moment(json.end_at)
        ])
        fields.receiveTime.onChange([
          moment(json.start_at),
          moment(json.end_at)
        ])
        fields.useTime.onChange([
          moment(json.expire_start_at),
          moment(json.expire_end_at)
        ])

        fields.location_ids.onChange((json.locations || []).map(i => i.id))
        fields.area_ids.onChange((json.areas || []).map(i => i.id))
      })
    }
  },

  submitData(model) {
    const {
      params: { id },
      apiCallFailureAction,
      successAction,
      locationId
    } = this.props

    const { fid } = this.props.location.query
    const params = {
      ...model,
      start_at: model.receiveTime[0].format('YYYY-MM-DD'),
      end_at: model.receiveTime[1].format('YYYY-MM-DD'),
      expire_start_at: model.useTime[0].format('YYYY-MM-DD'),
      expire_end_at: model.useTime[1].format('YYYY-MM-DD'),
      location_ids: locationId ? [locationId] : model.location_ids,
      area_ids: (model.coupon_type === 'meeting_room' && locationId) ? model.area_ids : [],
      is_system: locationId ? false : true
    }

    delete params.receiveTime
    delete params.useTime

    if (!fid) {
      // 新建
      return api
        .createCouponTemplate(params)
        .then(res => {
          successAction({ message: '创建成功' })
          // this.goBack()
          this.showConfirmPopover(res.json)
        })
        .catch(error => {
          apiCallFailureAction({
            status: 'error',
            message: error.message
          })
        })
    } else {
      // 编辑
      return api
        .updateCouponTemplate(fid, params)
        .then(res => {
          successAction({ message: '修改成功' })
          this.showConfirmPopover(res.json)
          // this.goBack()
        })
        .catch(error => {
          apiCallFailureAction({
            status: 'error',
            message: error.message
          })
        })
    }
  },

  goBack() {
    this.props.routerActions.goBack()
  },

  showConfirmPopover(couponInfo) {
    const { locationId } = this.props
    KBPopover.show(
      <ListingPopover locationId={locationId} couponInfo={couponInfo} />
    )
  },

  onTypeChange(ev) {
    const { fields, locationId } = this.props
    ev.persist()
    const value = ev.target.value
    fields.coupon_type.onChange(value)
    fields.area_ids.onChange('')
  },

  render() {
    const {
      fields: {
        coupon_type,
        display,
        name,
        coupon_discount,
        location_ids,
        area_ids,
        price,
        total_number,
        receiveTime,
        useTime,
        user_type,
        coupon_limit,
        rules,
        status,
        cover
      },
      locationId,
      submitting,
      handleSubmit
    } = this.props
    const { fileList } = this.state
    const { fid } = this.props.location.query
    const currentLocation = getCurrentLocation()

    return (
      <div className="kb-content">
        <div className="kb-content-container">
          <KBForm
            type="reduxForm"
            fields={this.props.fields}
            onSubmit={handleSubmit(this.submitData)}
          >
            <header className="kb-form-header">
              <h2 className="header-title">{fid ? '编辑' : '创建'}优惠券</h2>
            </header>
            <div className="kb-form-container">
              <div className="kb-row">
                <div className="kb-form-66">
                  <KBRadio
                    className="kb-form-group kb-div-c48"
                    inClassName="kb-form-group kb-div-66"
                    groupStyle={{ width: '100%' }}
                    divClassName="kb-form-group kb-div-33"
                    rightLabel="label-rt"
                    title="类型"
                    arrayType={allTypes}
                    name="coupon_type"
                    isRequired
                    field={coupon_type}
                    onChange={this.onTypeChange}
                  />
                </div>
              </div>

              <div className="kb-row">
                <div className="kb-form-66">
                  <KBRadio
                    className="kb-form-group kb-div-c48"
                    inClassName="kb-form-group kb-div-66"
                    groupStyle={{ width: '100%' }}
                    divClassName="kb-form-group kb-div-33"
                    rightLabel="label-rt"
                    title="是否展示在领券中心"
                    arrayType={displayTypes}
                    name="display"
                    isRequired
                    field={display}
                  />
                </div>
              </div>

              <div className="kb-row ">
                <KBInput
                  className="kb-form-group kb-form-alone"
                  title="名称"
                  label
                  isRequired
                  placeholder="范例：618会议室9折优惠券"
                  field={name}
                  name="name"
                  maxLength="32"
                />
              </div>

              <div
                className="kb-row"
                style={{ width: 242, position: 'relative' }}
              >
                <KBInput
                  className="kb-form-group kb-form-alone"
                  title="折扣"
                  label
                  isRequired
                  field={coupon_discount}
                  name="coupon_discount"
                  placeholder="折扣值区间：1~9.9，最多一位小数"
                />
                {/* <span style={{position: 'absolute', right: -16,
                  bottom: 12,
                }}>折</span> */}
              </div>

              {/* 选分店 */}
              <div className="kb-row">
                {coupon_type.value === 'meeting_room' && locationId && (
                  <MeetingRoomSelect
                    label="适用范围"
                    isRequired
                    field={area_ids}
                    name="area_ids"
                    mode="multiple"
                    locationId={locationId}
                  />
                )}
                {!locationId && (
                  <LocationSelect
                    label="适用范围"
                    isRequired
                    field={location_ids}
                    name="location_ids"
                    mode="multiple"
                  />
                )}
                {coupon_type.value === 'desk' && locationId && (
                  <KBInput
                    className="kb-form-group kb-form-alone"
                    title="适用范围"
                    label
                    isRequired
                    disabled
                    field={{
                      value: _.get(currentLocation, 'name'),
                      onChange: () => {}
                    }}
                  />
                )}
              </div>
              <div className="kb-row">
                <div className="kb-form-66">
                  <KBRadio
                    className="kb-form-group kb-div-c48"
                    inClassName="kb-form-group kb-div-66"
                    groupStyle={{ width: '100%' }}
                    divClassName="kb-form-group kb-div-33"
                    rightLabel="label-rt"
                    title="价格"
                    arrayType={priceOptions}
                    name="price"
                    isRequired
                    field={price}
                  />
                </div>
              </div>

              <div className="kb-row" style={{ width: 242 }}>
                <KBInput
                  className="kb-form-group kb-form-alone"
                  title="数量"
                  label
                  isRequired
                  field={total_number}
                  name="total_number"
                />
              </div>

              <div className="kb-row kb-form-c48">
                <DatePicker
                  type="RangePicker"
                  label="领取有效期"
                  isRequired
                  field={receiveTime}
                  name="receiveTime"
                />
              </div>

              <div className="kb-row kb-form-c48">
                <DatePicker
                  type="RangePicker"
                  label="使用有效期"
                  isRequired
                  field={useTime}
                  name="useTime"
                />
              </div>

              <div className="kb-row">
                <div className="kb-form-66">
                  <KBRadio
                    className="kb-form-group kb-div-c48"
                    inClassName="kb-form-group kb-div-66"
                    groupStyle={{ width: '100%' }}
                    divClassName="kb-form-group kb-div-33"
                    rightLabel="label-rt"
                    title="领取对象"
                    arrayType={userTypes}
                    name="user_type"
                    isRequired
                    field={user_type}
                  />
                </div>
              </div>

              <div className="kb-row " style={{ width: 242 }}>
                <KBInput
                  className="kb-form-group kb-form-alone"
                  title="单人限领次数"
                  label
                  isRequired
                  field={coupon_limit}
                  name="coupon_limit"
                  disabled
                />
              </div>

              <div className="kb-row ">
                <KBTextarea
                  className="kb-form-group kb-form-alone"
                  title="使用规则"
                  label
                  field={rules}
                  name="rules"
                  maxLength="255"
                  txtStyle={{ height: 150 }}
                />
              </div>

              <div className="kb-row" style={{ textAlign: 'left', width: 500 }}>
                <KBImageCropperInput
                  title="头图"
                  // 长宽比例
                  aspect={335 / 168}
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={cover}
                  // 文件尺寸限制
                  imageSize={1024 * 5}
                  // 预览时的图片大小
                  width={335}
                  height={168}
                  failureCallBack={this.failureCallBack}
                  accept="image/jpeg, image/png, image/jpg"
                  isPublic
                >
                  <div className="upload-portrait">
                    {/* 已上传时的内容展示 */}
                    {cover && cover.value ? (
                      <img
                        // 通过修改url来截取图片
                        src={cover.value}
                        style={{
                          width: '335px',
                          height: '168px',
                          display: 'inline-block',
                          marginLeft: 0,
                          borderRadius: 0
                        }}
                        alt=""
                      />
                    ) : (
                      ''
                    )}
                    <div
                      className="d-iblock"
                      style={{ verticalAlign: 'middle' }}
                    >
                      <button className="bordered-btn m-none" type="button">
                        {cover.dirty ? '图片已上传' : '上传图片'}
                      </button>
                      <p
                        className="color-nine"
                        style={{ paddingTop: 20, fontSize: 12 }}
                      >
                        请上传png, jpg, jpeg格式的图片，尺寸要求：335px *
                        168px，长宽比=2:1
                      </p>
                    </div>
                  </div>
                </KBImageCropperInput>
                <div style={{ marginTop: 10 }}>
                  {cover.touched && cover.error && (
                    <p className="lr-error">{cover.error}</p>
                  )}
                </div>
              </div>

              <div className="kb-form-fr" style={{ marginTop: '80px' }}>
                <span
                  className="cancel-btn"
                  style={{ marginRight: 15 }}
                  onClick={this.goBack}
                >
                  取消
                </span>
                <KBButton
                  className="certain-btn"
                  type="submit"
                  submitting={submitting}
                  onClick={() => status.onChange('draft')}
                >
                  提交
                </KBButton>
              </div>
            </div>
          </KBForm>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const locationId = params.id

  return {
    locationId,
    initialValues: {
      coupon_type: 'meeting_room',
      display: true,
      price: 0,
      user_type: 'all_user',
      coupon_limit: 1
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = (values, props) => {
  const errors = {}
  const { coupon_discount, total_number, receiveTime, useTime } = values
  console.log(values)
  if (valid.required(values.coupon_type)) {
    errors.coupon_type = '请选择类型'
  }

  if (valid.required(values.name)) {
    errors.name = '请输入名称'
  }

  if (
    valid.required(coupon_discount) ||
    isNaN(coupon_discount) ||
    Number(coupon_discount) < 1 ||
    Number(coupon_discount) > 9.9 ||
    (coupon_discount.includes('.') && coupon_discount.split('.')[1].length > 1)
  ) {
    errors.coupon_discount = '折扣值区间：1~9.9，最多一位小数'
  }

  if (!props.locationId && valid.required(values.location_ids)) {
    errors.location_ids = '请选择适用范围'
  }

  if(props.locationId && values.coupon_type === 'meeting_room' && valid.required(values.area_ids)) {
    errors.area_ids = '请选择适用范围'
  }

  if (
    valid.required(values.total_number) ||
    Number(total_number) < 1 ||
    !Number.isInteger(Number(total_number))
  ) {
    errors.total_number = '数量请输入正整数'
  }

  if (receiveTime && receiveTime.length) {
    if (
      moment()
        .startOf('day')
        .isAfter(moment(receiveTime[0]))
    ) {
      errors.receiveTime = '领取有效期不能为过去时间'
    }
  } else {
    errors.receiveTime = '请选择领取有效期'
  }

  if (useTime && useTime.length) {
    if (receiveTime && receiveTime.length) {
      if (moment(useTime[0]).isBefore(moment(receiveTime[0]))) {
        errors.useTime = '使用有效期的开始日期不能早于领取有效期的开始日期'
      }
      if (moment(useTime[1]).isBefore(moment(receiveTime[1]))) {
        errors.useTime = '使用有效期的结束日期不能早于领取有效期的结束日期'
      }
    }
  } else {
    errors.useTime = '请选择使用有效期'
  }

  return errors
}

const formConfig = {
  form: 'EditValueAddedServices',
  fields: [
    'coupon_type',
    'display',
    'name',
    'coupon_discount',
    'location_ids',
    'area_ids',
    'price',
    'total_number',
    'receiveTime',
    'useTime',
    'user_type',
    'coupon_limit',
    'rules',
    'status',
    'cover'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(EditValueAddedServices)
