import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBMorePhotoUpload,
  KBButton,
  KBPopover,
  KBUserInput
} from 'components'
import { faqFilesActions } from 'app/actions'
import * as selectors from 'app/selectors'
import * as constants from 'app/constants'
import config from 'app/config'

var FaqUploadFileForm = React.createClass({
  getInitialState() {
    return {}
  },

  createFaqFiles(model) {
    const { createFaqFilesActions } = this.props
    return api.createFaqFile(model).then(json => {
      createFaqFilesActions.success(json)
      KBPopover.close()
      return true
    })
  },

  render() {
    const {
      fields: { title, position, url, state, location_id },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { sections } = this.props

    return (
      <form onSubmit={handleSubmit(this.createFaqFiles)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">上传文件</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={title}
              title="标题"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={position}
              title="位置"
              type="number"
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              placeholder="请选择状态"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={state}
              title="状态"
              users={constants.FAQ_FILES_STATE}
            />
          </div>
          <div className="kb-row">
            <div className="kb-form-group kb-form-c18 kb-form-alone">
              <label>
                上传合同
                <span className="must-fill">*</span>
              </label>
              <div>
                <KBMorePhotoUpload
                  className="kb-form-group kb-form-c48  kb-form-alone"
                  text={url.value ? '文件已上传' : '上传文件'}
                  uploadingText="正在上传"
                  multiple={false}
                  prefix={config.uploadDir + 'faqs/files/'}
                  photoShow={false}
                  buttonClass={'bordered-btn m-none'}
                  uploadedText="上传文件"
                  field={url}
                  accept=".doc,.docx,.pdf"
                />
                {url.touched && url.error && (
                  <p className="lr-error"> {url.error} </p>
                )}
              </div>
            </div>
          </div>
          <div className="kb-row">
            <KBButton className="certain-btn" submitting={submitting}>
              确定
            </KBButton>
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state) {
  let sections = selectors.getSections(state)
  // let locations = selectors.getLocations(state)
  return {
    sections
    // locations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createFaqFilesActions: bindActionCreators(faqFilesActions.create, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.title)) {
    errors.title = '请输入文件标题'
  }

  if (valid.required(values.position)) {
    errors.position = '请选择文件位置'
  }

  if (valid.required(values.url)) {
    errors.url = '请输入文件url'
  }

  if (valid.required(values.state)) {
    errors.state = '状态不能为空'
  }

  return errors
}

const formConfig = {
  form: 'FaqUploadFileForm',
  fields: ['title', 'position', 'url', 'state', 'location_id'],
  validate: validate,
  touchOnBlur: false
}

FaqUploadFileForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(FaqUploadFileForm)

export default FaqUploadFileForm
