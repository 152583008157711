import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import AssistBox from './../shuck/AssistBox'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { KBInput, KBFormError, KBButton } from 'components'

var SendUnlockEmail = React.createClass({
  unlocks(model, dispatch) {
    return api.unlocks(model).then(json => {
      this.props.routerActions.push('/login')
    })
  },
  render() {
    const {
      fields: { email },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <div className="lr-out-box">
        <div className="comment-box1">
          <div className="kb-box box1-header">
            <span className="lr-text">账户解锁</span>
          </div>
          <div className="kb-box send-link">
            <p className="common-font-1">请输入你的注册email,</p>
            <p className="common-font-1">我们将发送解锁链接到该邮箱</p>
          </div>
          <form onSubmit={handleSubmit(this.unlocks)}>
            <KBFormError err={this.props.error} />
            <KBInput field={email} placeholder="输入您的注册Email地址" />
            <div className="kb-box">
              <KBButton type="submit" disabled={submitting}>
                {submitting ? '发送中' : '发送解锁邮件'}
              </KBButton>
            </div>
          </form>
        </div>
      </div>
    )
  }
})

const validate = values => {
  const errors = {}
  if (valid.required(values.email)) {
    errors.email = '请输入Email地址'
  } else if (valid.email(values.email)) {
    errors.email = '请输入正确的Email地址'
  }
  return errors
}

const formConfig = {
  form: 'sendUnlockEmail',
  fields: ['email'],
  touchOnBlur: false,
  validate: validate
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default reduxForm(
  formConfig,
  undefined,
  mapDispatchToProps
)(SendUnlockEmail)
