import React, { Component, PropTypes } from 'react'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBPopover,
  KBQiniuFileUpload,
  KBTipsy
} from 'components'
import classNames from 'classnames'
import config from 'app/config'
import { toImageProxyDirect } from 'utils/kbUtil'

var KBMoreFileUpload = React.createClass({
  getInitialState() {
    const { defaultImages } = this.props
    return {
      files: [],
      uploading: false,
      uploadProgress: null,
      uploadFileUrl: null,
      fileList: defaultImages || []
    }
  },

  getDefaultProps() {
    return {
      baseUrl: config.qiniu_base_url
    }
  },

  onUpload: function(files) {
    const { fileType } = this.props
    // set onprogress function before uploading
    this.setState({ uploaded: false, uploadFileUrl: null })
    var _this = this
    files.map(function(f) {
      f.onprogress = function(e) {
        //console.log(e.percent);
        _this.setState({ uploading: true, uploadProgress: e.percent })
      }
    })
  },

  onDrop: function(files) {
    const { fileType, field, messageCallback } = this.props
    const { fileList } = this.state
    let sizeArray = []
    this.setState({
      files: files
    })
    // files is a FileList(https://developer.mozilla.org/en/docs/Web/API/FileList) Object
    // and with each file, we attached two functions to handle upload progress and result
    // file.request => return super-agent uploading file request
    // file.uploadPromise => return a Promise to handle uploading status(what you can do when upload failed)
    // `react-qiniu` using bluebird, check bluebird API https://github.com/petkaantonov/bluebird/blob/master/API.md
    // see more example in example/app.js
    //console.log('Received files: ', files);
    var _this = this
    files.map(f => {
      f.uploadPromise.then(response => {
        console.log(response);
        //console.log('[FILE UPLOADED]: ', JSON.stringify(response.body))
        // let url = isPublic ? response.url : (this.state.showUrl + response.body.key)
        let url = response.url
        let fileMessage = {}

        field.addField({
          category: fileType,
          url: url
        })

        fileMessage.size = f.size
        fileMessage.type = f.type
        sizeArray.push(fileMessage)

        _this.setState({ uploading: false })
        messageCallback && messageCallback(sizeArray)
      })
    })
  },

  setUrlAndToken({ domain, token, upload_url }) {
    const { isAzureUpload, azure_url } = config
    this.setState({
      uploadToken: token,
      showUrl: isAzureUpload ? azure_url : domain,
      baseUrl: isAzureUpload ? azure_url : upload_url
    })
  },

  componentDidMount() {
    // load locations
    api.getUploadToken().then(({ json }) => {
      this.setUrlAndToken(json)
    })
  },

  renderProgress() {
    return this.state.uploading ? (
      <div
        className={classNames(
          this.props.buttonClass ? this.props.buttonClass : 'post-btn-ing'
        )}
      >
        <div
          className="post-btn-progress"
          width={this.state.uploadProgress + '%'}
        ></div>
        <div className="post-btn-content">
          <span>{this.props.uploadingText || '正在上传'}</span>
        </div>
      </div>
    ) : null
  },

  renderUploadField() {
    const { prefix, multiple, field, text, accept, isPublic } = this.props
    const { uploading } = this.state
    return this.state.uploadToken && !uploading ? (
      <KBQiniuFileUpload
        uploadUrl={this.state.baseUrl}
        onDrop={this.onDrop}
        multiple={multiple}
        token={this.state.uploadToken}
        prefix={prefix ? prefix : config.uploadDir + 'locations/'}
        onUpload={this.onUpload}
        accept={accept}
        isPublic={isPublic}
      >
        <div
          className={classNames(
            this.props.buttonClass ? this.props.buttonClass : 'post-btn-default'
          )}
        >
          <div className="post-btn-progress"></div>
          <div className="post-btn-content">
            <span>{text}</span>
          </div>
        </div>
      </KBQiniuFileUpload>
    ) : null
  },

  render() {
    const {
      className,
      titleClass,
      title,
      field,
      hint,
      isRequired,
      fileType
    } = this.props
    const { fileList } = this.state
    return (
      <div className={className}>
        {title && (
          <label className={titleClass}>
            {title}
            {isRequired ? <span className="must-fill">*</span> : ''}
          </label>
        )}
        {this.renderUploadField()}
        {this.renderProgress()}
        <br />
        <ul className="kb-more-file" style={{ width: '95%' }}>
          {field &&
            field.length > 0 &&
            field.map((file, index) => {
              return (
                <li key={index} style={{ width: '100%' }}>
                  <a
                    href={file.url.value}
                    className="t-over color-link"
                    style={{ maxWidthL: '95%', display: 'block' }}
                    target="__blank"
                  >
                    {file.url.value}
                  </a>
                  <div className="delete" style={{ top: 0 }}>
                    <i
                      className="iconfont icon-close"
                      onClick={() => field.removeField(index)}
                    />
                  </div>
                </li>
              )
            })}
        </ul>
        {/*{ field.touched && field.error ? <p className="lr-error">{ field.error }</p> : <p style={{color:'#c8c8c8',fontSize:12,padding:'2px 0 0 11px'}}>{ hint }</p>}*/}
      </div>
    )
  }
})

KBMoreFileUpload.propTypes = {
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  prefix: PropTypes.string,
  multiple: PropTypes.bool,
  text: PropTypes.string.isRequired,
  uploadingText: PropTypes.string,
  uploadedText: PropTypes.string,
  accept: PropTypes.string
}

export default KBMoreFileUpload
