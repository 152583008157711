import React, { PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import { invoicesActions } from 'app/actions'
import { KBFormError, KBButton, KBPopover, KBDateInput } from 'components'
import moment from 'moment'
import { arrayToObject } from 'utils/kbUtil'

var InvoiceConfirmIncomeForm = React.createClass({
  incomeConfirm(model) {
    const { location_id, ids, line_item_ids, callback } = this.props

    model.location_id = location_id
    model.ids = ids.join(',')
    model.monthly_line_item_ids = line_item_ids.join(',')

    return api.batchConfirmIncome(model).then(
      json => {
        const { invoices } = json.response.body
        this.props.updateInvoicesActions.success({
          entities: { invoices: arrayToObject(invoices) }
        })
        callback && callback(invoices || [])
        KBPopover.close()
      },
      error => {
        this.props.updateInvoicesActions.success(error)
        KBPopover.close()
      }
    )
  },

  render() {
    const {
      fields: { income_confirmed_at },
      submitting,
      handleSubmit,
      isBatch
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.incomeConfirm)}>
        <header className="kb-form-header">
          <h2 className="header-title">{isBatch ? '批量' : ''}确认收入</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container-m">
          <div className="kb-row">
            <KBDateInput
              type="date"
              title="确认日期"
              className="kb-form-group kb-form-210 kb-form-alone"
              field={income_confirmed_at}
            />
          </div>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

InvoiceConfirmIncomeForm.PropTypes = {
  invoice: PropTypes.object.isRequired
}

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      income_confirmed_at: moment().format('YYYY-MM-DD')
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'InvoiceConfirmIncomeForm',
  fields: ['income_confirmed_at'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoiceConfirmIncomeForm)
