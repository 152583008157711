import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { apiCallFailure } from 'app/actions'
import { getFieldErrorMsg } from 'utils/kbUtil'

var KBForm = React.createClass({
  componentWillReceiveProps(nextProps) {
    const { fields, apiCallFailureActions } = nextProps
    const { type } = this.props

    if (type !== 'reduxForm') {
      return
    }

    const newError = getFieldErrorMsg(fields)
    const oldError = getFieldErrorMsg(this.props.fields)
    if (newError && newError !== oldError) {
      return apiCallFailureActions({
        status: 'error',
        message: newError
      })
    }
  },
  render() {
    const { onSubmit, onScroll, ref } = this.props

    return (
      <form onSubmit={onSubmit} onScroll={onScroll} ref={ref}>
        {this.props.children}
      </form>
    )
  }
})

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KBForm)
