import React, { Component, PropTypes } from 'react'
import { KBLoading } from 'components'

var KBLoadingContainer = React.createClass({
  render() {
    const { style, innerStyle } = this.props
    return (
      <div
        style={
          this.props.className
            ? { ...style }
            : { width: '100%', height: '100%', position: 'relative', ...style }
        }
        className={this.props.className}
      >
        {this.props.loading ? (
          this.props.type === 'cube' ? (
            <div className="kb-folding-cube" style={innerStyle || {}}>
              <div className="kb-cube1 kb-cube"></div>
              <div className="kb-cube2 kb-cube"></div>
              <div className="kb-cube4 kb-cube"></div>
              <div className="kb-cube3 kb-cube"></div>
            </div>
          ) : (
            <div className="kb-spinner" style={innerStyle || {}}>
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          )
        ) : (
          this.props.children
        )}
      </div>
    )
  }
})

KBLoadingContainer.PropTypes = {
  loading: PropTypes.bool.isRequired
}

export default KBLoadingContainer
