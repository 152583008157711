import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { locationActions, successState, apiCallFailure } from 'app/actions'
import LocationBaseForm from '../spaces/LocationBaseForm'
import LocationPublicForm from '../spaces/LocationPublicForm'
import { KBMorePhotoUpload } from 'components'
import { LogoCropperForm } from 'components/views'
import * as selectors from 'app/selectors'

/**
 * 分店基础设置
 */
var LocationSetting = React.createClass({
  updateLocation(model) {
    const { updateLocationAction, location_id, successAction } = this.props
    return api.updateLocation(location_id, model).then(json => {
      successAction({ message: '保存成功!' })
      updateLocationAction.success(json)
    })
  },

  render() {
    const { cities, provinces, location } = this.props

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span>分店设置</span>
          </div>
        </header>
        <div className="nav-section-content-container" style={{ padding: 0 }}>
          <div className="kb-form-container">
            <fieldset className="fieldset">
              <div className="kb-title m-bottom-sm">
                <span className="color-six">设置公开程度</span>
              </div>
              <LocationPublicForm location={location} />
            </fieldset>
            <fieldset className="fieldset">
              <div className="kb-title m-bottom-sm">
                <span className="color-six">基本信息</span>
              </div>
              <LocationBaseForm
                updateLocation={this.updateLocation}
                location={location}
                cities={cities}
                provinces={provinces}
              />
            </fieldset>
          </div>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const { location_id } = params
  const cities = selectors.getCities(state)
  const provinces = selectors.getProvinces(state)
  let currentLocation = selectors.getSerializeLocationObj(state, location_id)
  return {
    cities,
    provinces,
    location: currentLocation,
    location_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLocationAction: bindActionCreators(locationActions.update, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSetting)
