import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { KBPopoverTop, KBPopover } from 'components'

var MobilePreview = React.createClass({
  render() {
    const { qrcode_url, isTop } = this.props

    return (
      <div>
        <header className="kb-form-header">
          <h2 className="header-title">手机扫一扫预览</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={isTop ? KBPopoverTop.close : KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div style={{ textAlign: 'center' }}>
            {qrcode_url ? (
              <img
                src={`data:img/png;base64,${qrcode_url}`}
                style={{ width: 350 }}
                alt=""
              />
            ) : (
              <span>当前内容暂不支持预览</span>
            )}
          </div>
          <div className="kb-row fixed-form-btn">
            <button
              className="certain-btn"
              onClick={isTop ? KBPopoverTop.close : KBPopover.close}
            >
              完成
            </button>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MobilePreview)
