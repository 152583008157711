import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import classNames from 'classnames'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import {
  customerRecordsActions,
  apiCallFailure,
  opportunitiesActions,
  intentAreaActions
} from 'app/actions'
import {
  OPPORTUNITY_STATES,
  CRM_NODE_TYPES,
  CRM_OPPORTUNITY_STATES,
  CRM_FAILED_REASON,
  CRM_FAILED_REASON_OBJ,
  CRM_VISIT_TYPE,
  CRM_INTENT_RENT_AREA_SIZE,
  OPPORTUNITY_DESIRES,
  CRM_TIME_NUMBER
} from 'app/constants'
import {
  KBFormError,
  KBButton,
  KBTextarea,
  KBTipsy,
  KBPopover,
  KBInput,
  KBDateInput,
  KBSelect,
  KBDropDownListInput,
  KBUserInput
} from 'components'
import moment from 'moment'
import DayPicker, { DateUtils } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import * as selectors from 'app/selectors'
import RecommendOfficeForm from './RecommendOfficeForm'
import _ from 'lodash'
import { canAccessSpaceRoles } from 'app/reducers/role'
import { formatHoursMinutesCHS } from 'utils/kbUtil'

var CrmNewRecordForm = React.createClass({
  displayName: 'CrmNewRecordForm',
  getInitialState() {
    const { intentAreas, customer } = this.props
    let officeRecommendState = intentAreas.length
      ? 'recommended'
      : 'not_recommended'
    return {
      selectIndex: null,
      recordTime: null,
      selectSuccessLocation: '',
      visitState: (customer && customer.visit) || 'no_visited',
      deletedVisitRecords: [],
      officeRecommendState,
      visitedFromBook: null,
      receiver: null
    }
  },
  createCrmRecord(model) {
    const {
      fields: { next_follow_at, visit },
      visit_records,
      hideRecordForm
    } = this.props
    const { deletedVisitRecords } = this.state
    let {
      customer,
      createCrmRecordAction,
      apiCallFailureAction,
      resetForm,
      routerActions,
      saveSalesRecordToOpp
    } = this.props
    let {
      opportunity_state,
      location_id,
      content_record,
      content_compete,
      failed_reason
    } = model
    model.content = content_record + 'SEPARATOR' + content_compete
    const oldState = customer.state

    if (!(customer && customer.id)) {
      return saveSalesRecordToOpp && saveSalesRecordToOpp(model)
    }

    if (!content_record) {
      return apiCallFailureAction({
        status: 'error',
        title: '记录为空！',
        message: '请添加销售记录'
      })
    }

    if (oldState == 'success' && opportunity_state != 'success') {
      return apiCallFailureAction({
        status: 'error',
        title: '状态错误！',
        message: '在赢单状态下，不能修改为其他状态'
      })
    }

    if (opportunity_state == 'success' && !location_id) {
      return apiCallFailureAction({
        status: 'error',
        message: '请选择赢单分店'
      })
    }
    if (
      (!failed_reason && opportunity_state == 'failed') ||
      failed_reason == -1
    ) {
      return apiCallFailureAction({
        status: 'error',
        message: '请选择流失原因'
      })
    }
    // 从 model 中拿出新添加的参观记录
    let newAddedRecords = []
    model.interest_locations.length &&
      model.interest_locations.map(rec => {
        if (!rec.id) {
          newAddedRecords.push(rec)
        }
      })
    // 标记删除的参观记录
    deletedVisitRecords.length &&
      deletedVisitRecords.map(del_r_id => {
        visit_records.length &&
          visit_records.map(new_r => {
            if (new_r.id == del_r_id) {
              new_r.deleted_at = new Date()
            }
            return new_r
          })
      })
    // 合并
    model.interest_locations = visit_records.concat(newAddedRecords)
    this.updateOpp(model)
    delete model.visit
    delete model.visit_date
    delete model.interest_locations_location_id
    delete model.interest_locations_notes
    delete model.interest_locations_receiver_id
    delete model.interest_locations_visit_date
    delete model.interest_locations

    const { recordTime } = this.state
    return api
      .createCrmRecord(customer.id, { ...model })
      .then(
        json => {
          createCrmRecordAction.success(json)
          resetForm()
          next_follow_at.onChange(recordTime)

          if (opportunity_state == 'success' && !customer.subscription) {
            this.getOpportunityInfo(customer.id)
          }

          this.setState({
            visitState: model.visit
          })

          return true
        },
        error => {
          createCrmRecordAction.failure(error)
        }
      )
      .finally(() => {
        hideRecordForm && hideRecordForm()
      })
  },

  getOpportunityInfo(opp_id) {
    const { getOpportunityInfo, u_id } = this.props
    api.getOpportunityInfo(opp_id).then(
      json => {
        getOpportunityInfo.success(json, { user_id: u_id })
        // 赢单后创建合同（如有其它处需要调用此方法 @method getOpportunityInfo ，此处应加上判断条件）
        let customer = json.response && json.response.body
        this.chooseCreateSubscription(customer)
        return true
      },
      errors => {
        getOpportunityInfo.failure(errors)
      }
    )
  },

  chooseCreateSubscription(customer) {
    const { createSubscription } = this.props
    let location_id = customer.win_location && customer.win_location.id
    let customer_id = customer.id
    let industry_id =
      customer.customer && customer.customer.industry
        ? customer.customer.industry.id
        : ''
    let company_name = (customer.contact && customer.contact.company) || ''
    KBPopover.show(
      <div>
        <header className="kb-form-header">
          <h2 className="header-title">是否创建合同</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row flex-center">
            <KBButton
              className="certain-btn"
              onClick={() => {
                KBPopover.close()
                createSubscription && createSubscription()
              }}
            >
              创建合同
            </KBButton>
          </div>
          <div className="kb-row flex-center">
            <KBButton className="certain-btn" onClick={KBPopover.close}>
              不创建合同
            </KBButton>
          </div>
        </div>
      </div>
    )
  },

  updateOpp(model) {
    const { updateOpportunity, customer } = this.props
    let form = {
      visit: model.visit
    }
    form.interest_locations = model.interest_locations

    let salesMen = []
    customer &&
      customer.sales_team &&
      customer.sales_team.map(man => {
        if (man.is_leader) {
          form.sales_leader = man.sales_user.id
        } else {
          salesMen.push(man.sales_user.id)
        }
      })
    form.salesmen = (salesMen.length && salesMen.join(',')) || ''
    api
      .updateOpportunity(customer.id, form)
      .then(
        json => {
          updateOpportunity.success(json, {
            id: customer.id,
            key: 'opportunities'
          })
        },
        err => {
          updateOpportunity.failure(err)
        }
      )
      .finally(() => {
        this.setState({
          visitedFromBook: null,
          visitState: model.visit
        })
      })
  },

  componentDidMount() {
    const {
      getIntentOfficesAction,
      createCrmRecordAction,
      fields: {
        next_follow_at,
        interest_locations_visit_date,
        interest_locations_receiver_id,
        visit_date,
        visit,
        location_id,
        visit_time_hour,
        visit_time_minute
      },
      opportunity_state,
      customer
    } = this.props

    let sales_leader =
      (customer &&
        customer.sales_team &&
        customer.sales_team.filter(s => {
          return s.is_leader
        })) ||
      []
    let leader =
      (sales_leader[0] &&
        sales_leader[0].sales_user &&
        sales_leader[0].sales_user) ||
      null

    if (leader) {
      interest_locations_receiver_id.onChange(leader.id)
      this.setState({
        receiver: leader
      })
    }

    visit_date.onChange((customer && customer.visit_date) || new Date())
    visit.onChange((customer && customer.visit) || 'no_visited')
    location_id.onChange(
      (customer && customer.win_location && customer.win_location.id) || ''
    )
    interest_locations_visit_date.onChange(new Date())

    visit_time_hour.onChange('08')
    visit_time_minute.onChange('00')

    if (opportunity_state == 'failed') {
      return
    }

    if (customer && customer.id) {
      api.getIntentOffices(customer.id).then(
        json => {
          getIntentOfficesAction.success(json, {
            entityName: 'intentAreas'
          })
        },
        errors => {
          getIntentOfficesAction.failure(errors)
        }
      )
    }

    // api.getSpaceSetting().then(({ json }) => {
    //   let date = moment().add(json.settings.crm_message_setting, 'd').toDate()
    //   this.setState({ recordTime: date })
    //   next_follow_at.onChange(date)
    // }, errors => {
    //   createCrmRecordAction.failure(errors)
    // })
  },

  chooseState(id, index) {
    const {
      fields: { opportunity_state, next_follow_at },
      updateOppStateField
    } = this.props
    opportunity_state.onChange(id)
    updateOppStateField && updateOppStateField(id)

    let isFailed = id == 'failed'
    if (isFailed) {
      next_follow_at.onChange(null)
    }

    this.setState({
      selectIndex: index,
      recordTime: isFailed ? null : this.state.recordTime
    })
  },

  hideDropDown(name) {
    this.refs[name].hide()
  },

  chooseNoteType(id) {
    const {
      fields: { note_type }
    } = this.props
    note_type.onChange(id)
  },

  chooseVisitType(id) {
    const {
      fields: { visit }
    } = this.props
    visit.onChange(id)

    this.setState({
      visitState: id
    })
  },

  chooseRecommendType(id) {
    this.setState({
      officeRecommendState: id
    })
  },

  bookFromVisited(id) {
    const {
      fields: { visit }
    } = this.props
    visit.onChange(id)

    this.setState({
      visitedFromBook: id
    })
  },

  udpateNoDate() {
    const {
      fields: { next_follow_at }
    } = this.props
    this.setState({
      recordTime: null
    })
    next_follow_at.onChange(null)
    this.hideDropDown('fromToDropdown')
  },

  handleDayClick(e, day) {
    const {
      fields: { next_follow_at }
    } = this.props
    this.setState({
      recordTime: day
    })
    next_follow_at.onChange(day)
  },

  setDayPicker() {
    this.hideDropDown('fromToDropdown')
  },

  selectFailedReason(reason) {
    const {
      fields: { failed_reason }
    } = this.props
    this.refs['failedReasonDropdown'].hide()
    failed_reason.onChange(reason)
  },

  renderBtnTitle() {
    const {
      fields: { opportunity_state },
      customer
    } = this.props
    const oldState = customer.state

    if (opportunity_state.value == 'success' && oldState != 'success') {
      return '确认赢单'
    }
    return '发布'
  },

  addVisitorGuider(user) {
    const {
      fields: { interest_locations_receiver_id }
    } = this.props
    let id = user.id || null

    interest_locations_receiver_id.onChange(id)
    this.setState({
      receiver: user
    })
  },

  cleanVisitGuider() {
    const {
      fields: { interest_locations_receiver_id }
    } = this.props

    interest_locations_receiver_id.onChange('')
    this.setState({
      receiver: null
    })
  },

  addVisitorRecord(type) {
    const {
      fields: {
        interest_locations,
        interest_locations_location_id,
        interest_locations_visit_date,
        interest_locations_receiver_id,
        interest_locations_notes,
        visit_time_hour,
        visit_time_minute
      },
      apiCallFailureAction
    } = this.props

    const LOC_ERR_MSG = {
      visited: '请选择已参观分店',
      reserved: '请选择预约参观分店'
    }
    const RECEIVER_ERR_MSG = {
      visited: '请选择带领参观人',
      reserved: '请选择负责人'
    }
    const TIME_ERR_MSG = {
      visited: '您已在当前时间参观过该分店，请重新选择分店或时间',
      reserved: '您已在当前时间预约过该分店，请重新选择分店或时间'
    }

    if (!interest_locations_location_id.value) {
      return apiCallFailureAction({
        status: 'error',
        message: LOC_ERR_MSG[type]
      })
    }
    if (!interest_locations_receiver_id.value) {
      return apiCallFailureAction({
        status: 'error',
        message: RECEIVER_ERR_MSG[type]
      })
    }

    let visit_date =
      moment(interest_locations_visit_date.value).format('YYYY-MM-DD') +
      ' ' +
      visit_time_hour.value +
      ':' +
      visit_time_minute.value +
      ':00'

    let locationRepeated = false
    interest_locations.length &&
      interest_locations.map(record => {
        if (
          interest_locations_location_id.value == record.location_id.value &&
          visit_date == record.visit_date.value &&
          type == record.visit_state.value
        ) {
          locationRepeated = true
        }
      })
    if (locationRepeated) {
      return apiCallFailureAction({
        status: 'error',
        message: TIME_ERR_MSG[type]
      })
    }

    interest_locations.addField({
      location_id: interest_locations_location_id.value,
      visit_date,
      visit_state: type,
      receiver_id: interest_locations_receiver_id.value,
      notes: interest_locations_notes.value
    })

    setTimeout(() => {
      interest_locations_receiver_id.onChange('')
      interest_locations_notes.onChange('')
      this.setState({
        receiver: null
      })
    })
  },

  deleteVisitRecord(record_id) {
    const { deletedVisitRecords } = this.state
    let newDeleted = deletedVisitRecords || []
    newDeleted.push(record_id)
    this.setState({
      deletedVisitRecords: newDeleted
    })
  },

  addRecommendOffice() {
    const { customer, locations, intentAreas } = this.props
    KBPopover.show(
      <RecommendOfficeForm
        customer={customer}
        locations={locations}
        intentAreas={intentAreas}
      />
    )
  },

  removeIntentOffice(area) {
    const { customer, deleteIntentOfficeAction } = this.props
    let id = customer && customer.id

    const intentOfficeid = area.id
    api.deleteIntentOffice(id, intentOfficeid).then(
      json => {
        deleteIntentOfficeAction.success(json, {
          id: intentOfficeid,
          key: 'intentAreas'
        })
      },
      errors => {
        deleteIntentOfficeAction.failure(errors)
      }
    )
  },

  render() {
    const {
      selectIndex,
      recordTime,
      selectSuccessLocation,
      visitState,
      visitedFromBook,
      receiver,
      officeRecommendState
    } = this.state
    const {
      fields: {
        content,
        content_record,
        content_compete,
        note_type,
        opportunity_state,
        failed_reason,
        location_id,
        next_follow_at,
        visit,
        visit_date,
        commend_areas,
        intention_areas,
        rentable_area,
        interest_locations,
        interest_locations_location_id,
        interest_locations_visit_date,
        interest_locations_receiver_id,
        interest_locations_notes,
        visit_time_hour,
        visit_time_minute
      },
      error,
      submitting,
      handleSubmit,
      locations,
      customer,
      users,
      locationsObj,
      usersObj,
      intentAreas,
      winLocation,
      hourNumberArray,
      minuteNumberArray
    } = this.props

    let currentStateIndex = CRM_OPPORTUNITY_STATES.findIndex(
      json => json.id == opportunity_state.value
    )

    let noteTypeLeft = 30
    let indexLeft = 100 * (selectIndex || currentStateIndex)
    // if ((selectIndex || currentStateIndex) > 2) {
    //   indexLeft = indexLeft - 50
    // }

    const visit_ele_class = {
      className: 'kb-form-group kb-form-alone visit-input-ele'
    }

    const visit_half_class = {
      className: 'kb-form-group kb-form-66 visit-input-ele'
    }

    const CRM_RECOMEND_OFFICE = [
      {
        id: 'recommended',
        name: '已向客户推荐'
      },
      {
        id: 'not_recommended',
        name: '未向客户推荐'
      }
    ]

    noteTypeLeft = noteTypeLeft + indexLeft
    return (
      <form onSubmit={handleSubmit(this.createCrmRecord)}>
        <KBFormError err={this.props.error} />
        <div className="kb-row">
          <div
            className={`kb-form-group kb-form-${
              customer && customer.id ? '210' : 'c18'
            } kb-form-alone`}
          >
            <label style={{ color: '#4E4E4E' }}>
              选择客户状态
              {customer && customer.id ? (
                ''
              ) : (
                <span className="must-fill">*</span>
              )}
            </label>
            <div>
              <div className="kb-form-group r1_radios">
                {CRM_OPPORTUNITY_STATES.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="kb-form-group r1_radio"
                      onClick={this.chooseState.bind(null, data.id, index)}
                    >
                      <div
                        className={`radio-btn ${
                          opportunity_state.value == data.id
                            ? 'radio-btn-active'
                            : ''
                        }`}
                      ></div>
                      <label className="label">{data.name}</label>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        {opportunity_state.value ? (
          <div className="flow-communicate">
            <div
              className="communicate-arrow"
              style={{
                display: `${noteTypeLeft >= 30 ? 'inline-block' : 'none'}`,
                left: noteTypeLeft + 'px'
              }}
            >
              <em />
              <span />
            </div>
            <div className="kb-row">
              <KBTextarea
                className="kb-form-group kb-form-c18 kb-form-alone"
                title="销售记录"
                placeholder="请填写销售记录"
                field={content_record}
              />
            </div>
            <ul className="communicate-way">
              <label>沟通方式</label>
              {
                <div className="communicate-way-container">
                  {CRM_NODE_TYPES.map((state, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          state.id == note_type.value
                            ? 'communicate-active'
                            : ''
                        }
                        onClick={this.chooseNoteType.bind(null, state.id)}
                      >
                        <span
                          className={`radio ${
                            state.id == note_type.value ? 'radio-active' : ''
                          }`}
                        ></span>
                        <a>{state.name}</a>
                      </li>
                    )
                  })}
                </div>
              }
            </ul>

            <div className="visit-choice">
              <label>参观状态</label>
              {
                <div className="visit-container">
                  {/* 已参观 */}
                  <div className="choices" style={{ width: '100%' }}>
                    <li
                      className={
                        CRM_VISIT_TYPE[0].id == visitState
                          ? 'communicate-active'
                          : ''
                      }
                      onClick={this.chooseVisitType.bind(
                        null,
                        CRM_VISIT_TYPE[0].id
                      )}
                    >
                      <span
                        className={`radio ${
                          CRM_VISIT_TYPE[0].id == visitState
                            ? 'radio-active'
                            : ''
                        }`}
                      ></span>
                      <a>{CRM_VISIT_TYPE[0].name}</a>
                    </li>
                    {CRM_VISIT_TYPE[0].id == visitState ? (
                      <div className="visit-choice-container">
                        <div className="kb-row">
                          <KBUserInput
                            placeholder={'选择分店'}
                            {...visit_ele_class}
                            field={interest_locations_location_id}
                            multi={false}
                            users={locations}
                          />

                          <div {...visit_half_class} style={{ width: '60%' }}>
                            <KBDateInput
                              placeholder="选择参观时间"
                              field={interest_locations_visit_date}
                              className="kb-form-group kb-form-alone f-left"
                              style={{ marginRight: 20, width: 200 }}
                              disabledDays={DateUtils.isFutureDay}
                            />
                            <KBSelect
                              className="kb-form-group kb-form-alone f-left"
                              style={{
                                float: 'left',
                                width: 60,
                                backgroundColor: '#fff'
                              }}
                              field={visit_time_hour}
                              arrayData={hourNumberArray}
                            />
                            <span style={{ float: 'left', padding: 9 }}>:</span>
                            <KBSelect
                              className="kb-form-group kb-form-alone f-left"
                              style={{
                                float: 'left',
                                width: 60,
                                backgroundColor: '#fff'
                              }}
                              field={visit_time_minute}
                              arrayData={minuteNumberArray}
                            />
                          </div>
                        </div>

                        <div className="kb-row">
                          <div {...visit_ele_class}>
                            <div className="kb_dropdown">
                              {receiver ? (
                                <div className="selected-guider">
                                  <span>
                                    <i
                                      className="iconfont icon-account_circle"
                                      style={{ marginRight: '7px' }}
                                    />
                                    {receiver.name}
                                  </span>
                                  <span
                                    onClick={this.cleanVisitGuider}
                                    style={{
                                      float: 'right',
                                      cursor: 'pointer',
                                      fontSize: '0.9rem',
                                      color: '#999'
                                    }}
                                  >
                                    <i className="iconfont icon-quit" />
                                    重选
                                  </span>
                                </div>
                              ) : (
                                <KBDropDownListInput
                                  data={users}
                                  placeholder={'选择带领参观人'}
                                  selectCallBack={this.addVisitorGuider}
                                  styleWidth={'100%'}
                                  filterIdArray={[].map(json => json.id)}
                                  query={['name', 'name_pinyin']}
                                />
                              )}
                            </div>
                          </div>
                          <KBInput
                            placeholder="备注"
                            field={interest_locations_notes}
                            className="kb-form-group kb-form-alone visit-input-ele-input"
                          />
                          <a
                            className="bordered-btn"
                            style={{ display: 'inline-block', float: 'left' }}
                            onClick={this.addVisitorRecord.bind(
                              null,
                              'visited'
                            )}
                          >{`添加${
                            interest_locations && interest_locations.length
                              ? '下一个'
                              : ''
                          }`}</a>
                        </div>

                        {interest_locations && interest_locations.length ? (
                          <div className="kb-row" style={{ marginBottom: 0 }}>
                            {interest_locations.map((record, index) => {
                              let visit_loc_id =
                                (record.location_id &&
                                  record.location_id.value) ||
                                ''
                              let visit_receiver_id =
                                (record.receiver_id &&
                                  record.receiver_id.value) ||
                                ''
                              let loc_name = locationsObj[visit_loc_id].name
                              if (record.visit_state.value != 'visited') {
                                return null
                              }
                              return (
                                <div className="visit-record">
                                  <div className="t-over">
                                    <KBTipsy content={loc_name}>
                                      {visit_loc_id ? (
                                        this.props.isSpaceCRMOnly ? (
                                          <span>{loc_name}</span>
                                        ) : (
                                          <Link
                                            className="a-link"
                                            to={`/admin/locations/${visit_loc_id}`}
                                          >
                                            {loc_name}
                                          </Link>
                                        )
                                      ) : null}
                                    </KBTipsy>
                                  </div>
                                  <span className="visit-receiver">
                                    带领人：
                                    <span style={{ color: '#333333' }}>
                                      {(usersObj[visit_receiver_id] &&
                                        usersObj[visit_receiver_id].name) ||
                                        '-'}
                                    </span>
                                  </span>
                                  <span className="visit-receiver">
                                    时间：
                                    <span style={{ color: '#333333' }}>
                                      {(record.visit_date.value &&
                                        formatHoursMinutesCHS(
                                          record.visit_date.value
                                        )) ||
                                        '-'}
                                    </span>
                                  </span>
                                  <i
                                    className="iconfont icon-close"
                                    onClick={() => {
                                      interest_locations.removeField(index)
                                      this.deleteVisitRecord(record.id.value)
                                    }}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {/* 预约参观 */}
                  <div className="choices" style={{ width: '100%' }}>
                    <li
                      className={
                        CRM_VISIT_TYPE[1].id == visitState
                          ? 'communicate-active'
                          : ''
                      }
                      onClick={this.chooseVisitType.bind(
                        null,
                        CRM_VISIT_TYPE[1].id
                      )}
                    >
                      <span
                        className={`radio ${
                          CRM_VISIT_TYPE[1].id == visitState
                            ? 'radio-active'
                            : ''
                        }`}
                      ></span>
                      <a>{CRM_VISIT_TYPE[1].name}</a>
                    </li>
                    {CRM_VISIT_TYPE[1].id == visitState ? (
                      <div className="visit-choice-container">
                        <div className="kb-row">
                          <KBUserInput
                            placeholder={'选择分店'}
                            {...visit_ele_class}
                            field={interest_locations_location_id}
                            multi={false}
                            users={locations}
                          />

                          <div {...visit_half_class} style={{ width: '60%' }}>
                            <KBDateInput
                              placeholder="选择预约时间"
                              field={interest_locations_visit_date}
                              className="kb-form-group kb-form-alone f-left"
                              style={{ marginRight: 20, width: 200 }}
                              disabledDays={DateUtils.isPastDay}
                            />
                            <KBSelect
                              className="kb-form-group kb-form-alone f-left"
                              style={{
                                float: 'left',
                                width: 60,
                                backgroundColor: '#fff'
                              }}
                              field={visit_time_hour}
                              arrayData={hourNumberArray}
                            />
                            <span style={{ float: 'left', padding: 9 }}>:</span>
                            <KBSelect
                              className="kb-form-group kb-form-alone f-left"
                              style={{
                                float: 'left',
                                width: 60,
                                backgroundColor: '#fff'
                              }}
                              field={visit_time_minute}
                              arrayData={minuteNumberArray}
                            />
                          </div>
                        </div>

                        <div className="kb-row">
                          <div {...visit_ele_class}>
                            <div className="kb_dropdown">
                              {receiver ? (
                                <div className="selected-guider">
                                  <span>
                                    <i
                                      className="iconfont icon-account_circle"
                                      style={{ marginRight: '7px' }}
                                    />
                                    {receiver.name}
                                  </span>
                                  <span
                                    onClick={this.cleanVisitGuider}
                                    style={{
                                      float: 'right',
                                      cursor: 'pointer',
                                      fontSize: '0.9rem',
                                      color: '#999'
                                    }}
                                  >
                                    <i className="iconfont icon-quit" />
                                    重选
                                  </span>
                                </div>
                              ) : (
                                <KBDropDownListInput
                                  data={users}
                                  placeholder={'选择负责人'}
                                  selectCallBack={this.addVisitorGuider}
                                  styleWidth={'100%'}
                                  filterIdArray={[].map(json => json.id)}
                                  query={['name', 'name_pinyin']}
                                />
                              )}
                            </div>
                          </div>
                          <KBInput
                            placeholder="备注"
                            field={interest_locations_notes}
                            className="kb-form-group kb-form-alone visit-input-ele-input"
                          />
                          <a
                            className="bordered-btn"
                            style={{ display: 'inline-block', float: 'left' }}
                            onClick={this.addVisitorRecord.bind(
                              null,
                              'reserved'
                            )}
                          >{`添加${
                            interest_locations && interest_locations.length
                              ? '下一个'
                              : ''
                          }`}</a>
                        </div>

                        {interest_locations && interest_locations.length ? (
                          <div className="kb-row" style={{ marginBottom: 0 }}>
                            {interest_locations.map((record, index) => {
                              let visit_loc_id =
                                (record.location_id &&
                                  record.location_id.value) ||
                                ''
                              let visit_receiver_id =
                                (record.receiver_id &&
                                  record.receiver_id.value) ||
                                ''
                              let loc_name = locationsObj[visit_loc_id].name
                              if (record.visit_state.value != 'reserved') {
                                return null
                              }
                              return (
                                <div className="visit-record">
                                  <div className="t-over">
                                    <KBTipsy content={loc_name}>
                                      {visit_loc_id ? (
                                        this.props.isSpaceCRMOnly ? (
                                          <span>{loc_name}</span>
                                        ) : (
                                          <Link
                                            className="a-link"
                                            to={`/admin/locations/${visit_loc_id}`}
                                          >
                                            {loc_name}
                                          </Link>
                                        )
                                      ) : null}
                                    </KBTipsy>
                                  </div>
                                  <span className="visit-receiver">
                                    负责人：
                                    <span style={{ color: '#333333' }}>
                                      {(usersObj[visit_receiver_id] &&
                                        usersObj[visit_receiver_id].name) ||
                                        '-'}
                                    </span>
                                  </span>
                                  <span className="visit-receiver">
                                    时间：
                                    <span style={{ color: '#333333' }}>
                                      {(record.visit_date.value &&
                                        formatHoursMinutesCHS(
                                          record.visit_date.value
                                        )) ||
                                        '-'}
                                    </span>
                                  </span>
                                  <i
                                    className="iconfont icon-close"
                                    onClick={() => {
                                      interest_locations.removeField(index)
                                      this.deleteVisitRecord(record.id.value)
                                    }}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {/* 未参观 */}
                  <div className="choices" style={{ width: '100%' }}>
                    <li
                      className={
                        CRM_VISIT_TYPE[2].id == visitState
                          ? 'communicate-active'
                          : ''
                      }
                      onClick={this.chooseVisitType.bind(
                        null,
                        CRM_VISIT_TYPE[2].id
                      )}
                    >
                      <span
                        className={`radio ${
                          CRM_VISIT_TYPE[2].id == visitState
                            ? 'radio-active'
                            : ''
                        }`}
                      ></span>
                      <a>{CRM_VISIT_TYPE[2].name}</a>
                    </li>
                  </div>
                </div>
              }
            </div>
            {customer && customer.id ? (
              <div className="visit-choice">
                <label>推荐房间</label>
                {
                  <div className="visit-container">
                    {/* 已向客户推荐 */}
                    <div className="choices" style={{ width: '100%' }}>
                      <li
                        className={
                          CRM_RECOMEND_OFFICE[0].id == officeRecommendState
                            ? 'communicate-active'
                            : ''
                        }
                        onClick={this.chooseRecommendType.bind(
                          null,
                          CRM_RECOMEND_OFFICE[0].id
                        )}
                      >
                        <span
                          className={`radio ${
                            CRM_RECOMEND_OFFICE[0].id == officeRecommendState
                              ? 'radio-active'
                              : ''
                          }`}
                        ></span>
                        <a>{CRM_RECOMEND_OFFICE[0].name}</a>
                      </li>
                      {CRM_RECOMEND_OFFICE[0].id == officeRecommendState ? (
                        <span
                          className="bordered-btn f-right"
                          onClick={this.addRecommendOffice}
                        >
                          添加推荐房间
                        </span>
                      ) : null}
                      {CRM_RECOMEND_OFFICE[0].id == officeRecommendState ? (
                        <div className="visit-choice-container">
                          {intentAreas && intentAreas.length ? (
                            <div className="kb-row" style={{ marginBottom: 0 }}>
                              {intentAreas.map((area, index) => {
                                let visit_loc_id =
                                  (area.location && area.location.id) || ''
                                let desire = area.desire || ''
                                let area_name = area.area && area.area.name
                                return (
                                  <div className="visit-record">
                                    {visit_loc_id ? (
                                      this.props.isSpaceCRMOnly ? (
                                        `${area_name}${
                                          visit_loc_id
                                            ? `(${area.location.name})`
                                            : ''
                                        }`
                                      ) : (
                                        <div>
                                          <Link
                                            className="a-link"
                                            to={`/admin/locations/${visit_loc_id}/areas/desks/list?area_id=${area.area.id}`}
                                          >
                                            {`${area_name}`}
                                          </Link>
                                          <span>{`${
                                            visit_loc_id
                                              ? `(${area.location.name})`
                                              : ''
                                          }`}</span>
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        style={{ fontSize: 15 }}
                                      >{`${area_name}`}</div>
                                    )}
                                    <span className="visit-receiver">
                                      兴趣：
                                      <span style={{ color: '#333333' }}>
                                        {OPPORTUNITY_DESIRES[desire] || '暂无'}
                                      </span>
                                    </span>
                                    <i
                                      className="iconfont icon-close"
                                      onClick={this.removeIntentOffice.bind(
                                        null,
                                        area
                                      )}
                                    />
                                  </div>
                                )
                              })}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                    {/* 未向客户推荐 */}
                    <div className="choices" style={{ width: '100%' }}>
                      <li
                        className={
                          CRM_RECOMEND_OFFICE[1].id == officeRecommendState
                            ? 'communicate-active'
                            : ''
                        }
                        onClick={this.chooseRecommendType.bind(
                          null,
                          CRM_RECOMEND_OFFICE[1].id
                        )}
                      >
                        <span
                          className={`radio ${
                            CRM_RECOMEND_OFFICE[1].id == officeRecommendState
                              ? 'radio-active'
                              : ''
                          }`}
                        ></span>
                        <a>{CRM_RECOMEND_OFFICE[1].name}</a>
                      </li>
                    </div>
                  </div>
                }
              </div>
            ) : null}

            {/* {
              opportunity_state.value == 'preliminary' ?
              <KBInput title="推荐房间" field={commend_areas}/>
              : null
            }
            {
              opportunity_state.value == 'preliminary' ?
              <KBInput title="意向房间" field={intention_areas}/>
              : null
            }
            {
              opportunity_state.value == 'preliminary' ?
              <div className="clear-fix">
                <div className='kb-row'>
                  <KBSelect className='kb-form-c18 kb-form-group kb-form-alone' title='意向面积' field={ rentable_area } arrayData={ CRM_INTENT_RENT_AREA_SIZE } />
                </div>
              </div>
              : null
            } */}
            {opportunity_state.value == 'success' ? (
              ''
            ) : (
              <KBInput title="可对比竞品" field={content_compete} />
            )}
            <div className="clear-fix">
              {opportunity_state.value == 'failed' ||
              opportunity_state.value == 'success' ? (
                ''
              ) : (
                <div className="kb-row">
                  <KBDateInput
                    field={next_follow_at}
                    title="下次跟进"
                    className="kb-form-c18 kb-form-group kb-form-alone"
                    disabledDays={DateUtils.isPastDay}
                  />
                </div>
              )}
              {opportunity_state.value == 'failed' ? (
                <div className="kb-row" style={{ position: 'relative' }}>
                  <span className="select_content_before">*</span>
                  <KBSelect
                    defaultValue="-1"
                    arrayData={CRM_FAILED_REASON}
                    field={failed_reason}
                    title="流失原因"
                    className="kb-form-c18 kb-form-group kb-form-alone"
                  />
                </div>
              ) : (
                ''
              )}
              {opportunity_state.value == 'success' ? (
                <div className="kb-row" style={{ position: 'relative' }}>
                  <span className="select_content_before">*</span>
                  <KBUserInput
                    title="赢单分店"
                    placeholder={'选择赢单分店'}
                    className="kb-form-c18 kb-form-group kb-form-alone"
                    field={location_id}
                    disabled={winLocation}
                    selectedUsers={[winLocation]}
                    multi={false}
                    users={locations}
                  />
                </div>
              ) : null}
            </div>
            {customer && customer.id ? (
              <KBButton
                className="certain-btn f-right"
                type="submit"
                submitting={submitting}
              >
                {this.renderBtnTitle()}
              </KBButton>
            ) : (
              <button className="certain-btn f-right" type="submit">
                保存销售记录
              </button>
            )}
          </div>
        ) : null}
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { customer } = props
  const { interest_locations } = customer
  const visit_records = []
  const interested_locs = []
  interest_locations &&
    interest_locations.length &&
    interest_locations.map(loc => {
      if (loc.visit_date) {
        visit_records.push(loc)
      } else {
        interested_locs.push(loc)
      }
    })
  const locations = selectors.getSerializeLocation(state)
  const locationsObj = selectors.getSerializeLocationObj(state)
  const { user, entities } = state
  let u_id = user.id
  const users = selectors.getSpaceUsers(state)
  const usersObj = selectors.getObjectMembers(state)
  const intentAreas = selectors.getIntentAreas(state) || []
  const winLocation = (customer && customer.win_location) || null
  let hourNumberArray = CRM_TIME_NUMBER(8, 21, 1)
  let minuteNumberArray = CRM_TIME_NUMBER(0, 59, 15)

  let isSpaceCRMOnly =
    canAccessSpaceRoles(user, ['crm_admin', 'crm']) &&
    !canAccessSpaceRoles(user, ['admin'])

  return {
    u_id,
    users,
    usersObj,
    customer,
    locations,
    locationsObj,
    intentAreas,
    winLocation,
    visit_records,
    hourNumberArray,
    minuteNumberArray,
    isSpaceCRMOnly,
    opportunity_state: customer && customer.state,
    initialValues: {
      location_id:
        customer && customer.win_location && customer.win_location.id,
      opportunity_state: customer && customer.state,
      failed_reason: customer && customer.failed_reason,
      interest_locations: visit_records,
      visit: customer && customer.visit
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getOpportunityInfo: bindActionCreators(
      opportunitiesActions.replace,
      dispatch
    ),
    updateOpportunity: bindActionCreators(
      opportunitiesActions.update,
      dispatch
    ),
    createCrmRecordAction: bindActionCreators(
      customerRecordsActions.create,
      dispatch
    ),
    getIntentOfficesAction: bindActionCreators(
      intentAreaActions.replace,
      dispatch
    ),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    deleteIntentOfficeAction: bindActionCreators(
      intentAreaActions.delete,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}
  return errors
}

const formConfig = {
  form: 'CrmNewRecordForm',
  fields: [
    'content',
    'content_record',
    'content_compete',
    'note_type',
    'location_id',
    'opportunity_state',
    'next_follow_at',
    'failed_reason',
    'visit',
    'visit_date',
    'commend_areas',
    'intention_areas',
    'rentable_area',
    'interest_locations[].id',
    'interest_locations[].location_id',
    'interest_locations[].visit_date',
    'interest_locations[].visit_state',
    'interest_locations[].receiver_id',
    'interest_locations[].notes',
    'interest_locations[].deleted_at',
    'interest_locations_location_id',
    'interest_locations_visit_date',
    'interest_locations_receiver_id',
    'interest_locations_notes',
    'interest_locations_deleted_at',
    'visit_time_hour',
    'visit_time_minute'
  ],
  validate: validate,
  touchOnBlur: false
}

CrmNewRecordForm.propTypes = {
  customer: React.PropTypes.object.isRequired
}

CrmNewRecordForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(CrmNewRecordForm)

export default CrmNewRecordForm
