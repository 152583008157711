/*
  注意：
    1.注册的用户必须经过邮箱验证才能使用，否则服务器会返回403.一般创建空间返回403有两个原因，一个
  就是邮箱没验证，还有就是审核没通过。现在为了开发，都是自动审核通过，所以就只有邮箱验证这一个原
  因.
*/
import { Schema, arrayOf, normalize } from 'normalizr'
import config from '../config'
import { _apiGET, _apiPOST, _apiPUT, _apiDELETE, _apiUploadFile } from './api_utils'
import * as Schemas from '../constants/Schemas'

const API_MGMT_ROOT = `${config.urlScheme}://${config.url}/managements/v2`

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/gaearon/normalizr

// 基础数据查询==========================
/*
  城市和区列表
*/
export const getCities = params =>
  _apiGET(API_MGMT_ROOT, '/cities', params, Schemas.citySchemaArray)

/*
 城市详情
 */
export const getCityInfo = id => _apiGET(API_MGMT_ROOT, `/cities/${id}`, null)

/*
省份列表
 */
export const getProvinces = () =>
  _apiGET(
    API_MGMT_ROOT,
    '/cities/provinces_list',
    null,
    Schemas.provinceSchemaArray
  )

/*
  设备列表
*/
export const getDevices = params =>
  _apiGET(API_MGMT_ROOT, '/devices', params, Schemas.devicesSchemaArray)
/*
  行业列表
*/
export const getIndustries = () =>
  _apiGET(API_MGMT_ROOT, '/industries', null, Schemas.industriesSchemaArray)

/*
GET /managements/v2/locations/location_stores  分店商城列表
*/
export const getLocationStores = () =>
  _apiGET(API_MGMT_ROOT, '/locations/location_stores ', null)

/**
 * 融资情况
 */
export const getRaisings = () =>
  _apiGET(API_MGMT_ROOT, '/fund_raisings', null, Schemas.raisingsSchemaArray)

// 用户注册和登录========================
export const sendRegistrationSmsCode = params =>
  _apiPOST(API_MGMT_ROOT, '/registrations/send_sms_code', params)
export const sendLoginSmsCode = params =>
  _apiPOST(API_MGMT_ROOT, '/sessions/send_sms_code', params)
export const registerUser = params =>
  _apiPOST(API_MGMT_ROOT, '/registrations', params, Schemas.userSchema)
export const loginUser = params =>
  _apiPOST(API_MGMT_ROOT, '/sessions', params, Schemas.userSchema)
export const logoutUser = params => {
  return _apiPOST(API_MGMT_ROOT, '/sessions/logout', params, Schemas.userSchema)
  // window.location.href = `${API_MGMT_ROOT}/sessions/logout`
}
export const logout401User = params => {
  // _apiPOST(API_MGMT_ROOT, '/sessions/logout', params, Schemas.userSchema)
  window.location.href = `${config.urlScheme}://${config.url}/saml/logout`
}
export const sendPassWordEmail = params =>
  _apiPOST(API_MGMT_ROOT, '/passwords', params)
export const resetPassword = params =>
  _apiPUT(API_MGMT_ROOT, '/passwords/reset', params)
export const unlocks = params => _apiPOST(API_MGMT_ROOT, '/unlocks', params)
export const unlock = params =>
  _apiPUT(API_MGMT_ROOT, '/unlocks/unlock', params)
export const confirm = params =>
  _apiPUT(API_MGMT_ROOT, '/confirmations/confirm', params)
export const confirmations = params =>
  _apiPOST(API_MGMT_ROOT, '/confirmations', params)
export const authEmployeeVerify = params =>
  _apiPUT(API_MGMT_ROOT, '/confirmations/auth_employee_verify', params)
// Get upload file token
export const getUploadToken = params =>
  _apiGET(API_MGMT_ROOT, '/uploads/token', params)

// 获取Azure上传所需的Token
export const getAzureToken = params =>
  _apiPOST(API_MGMT_ROOT, '/uploads/azure_token', params)

// 上传到公有blob
export const uploadToPublicBlob = params =>
  _apiUploadFile(API_MGMT_ROOT, '/uploads/upload_public_file', params)

// 上传到私有blob
export const uploadToPrivacyBlob = params =>
  _apiUploadFile(API_MGMT_ROOT, '/uploads/upload_file', params)

// 下载私有blob资源
export const downloadPrivacyBlob = params =>
  _apiGET(API_MGMT_ROOT, '/uploads/download_private_file', params)

// 登录用户=============================
/*
  当前登录用户信息
  请求举例：
    curl -X GET --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' 'http://101.200.141.121/managements/v1/user'
  成功返回结果：
    {
      "id": 20,
      "name": "wanghao",
      "email": "22340991@qq.com",
      "phone_num": "13552799004",
      "jwt_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k",
      "state": "active",
      "space_creator": true,
      "avatar": null,
      "can_create_space": false,
      "email_confirmed": true,
      "init_password": false,
      "space": {
        "id": 2,
        "name": "王豪测试空间2",
        "state": "active",
        "subdomain": "wanghao2"
      }
    }
*/

export const currentUpdateUserInfo = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/members/${id}`, params, Schemas.userSchema)

// 获取用户
export const getUsers = params =>
  _apiGET(API_MGMT_ROOT, '/users', params, Schemas.userSchemaArray)
export const getUsersNoSchema = params =>
  _apiGET(API_MGMT_ROOT, '/users', params, null)

// 获取用户详情
export const getUserInfo = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/users/${id}`, params, Schemas.userSchema)

// 获取用户详情
export const getOrgMember = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/users/${id}`, params, Schemas.userSchema)

// 修改
export const updatePassword = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/users/${id}/update_password`,
    params,
    Schemas.spaceMemberSchema
  )

// 返回用户全部相关信息
export const currentUserAllInfo = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/members/${id}`,
    { includes: ['locations'] },
    Schemas.spaceMemberSchema
  )

/*
  当前登录用户信息
  请求举例：
    curl -X GET --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' 'http://101.200.141.121/managements/v1/user'
  成功返回结果：
    {
      "id": 20,
      "name": "wanghao",
      "email": "22340991@qq.com",
      "phone_num": "13552799004",
      "jwt_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k",
      "state": "active",
      "space_creator": true,
      "avatar": null,
      "can_create_space": false,
      "email_confirmed": true,
      "init_password": false,
      "space": {
        "id": 2,
        "name": "王豪测试空间2",
        "state": "active",
        "subdomain": "wanghao2"
      }
    }
*/
export const updateCurrentUserInfo = params =>
  _apiPUT(API_MGMT_ROOT, '/user', params, Schemas.userSchema)
// 空间管理=============================
/*
  空间管理员创建空间
  请求举例：
    curl -X POST --header 'Content-Type: application/x-www-form-urlencoded' --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' -d 'name=%E7%8E%8B%E8%B1%AA%E6%B5%8B%E8%AF%95%E7%A9%BA%E9%97%B42&subdomain=wanghao2&full_name=%E7%8E%8B%E8%B1%AA%E6%B5%8B%E8%AF%95%E7%A9%BA%E9%97%B42&logo=test.png&description=%E7%8E%8B%E8%B1%AA%E6%B5%8B%E8%AF%95%E7%A9%BA%E9%97%B42&telephone=13552799004' 'http://101.200.141.121/managements/v1/spaces'
  成功返回结果：
    {
      "id": 2,
      "name": "王豪测试空间2",
      "full_name": "王豪测试空间2",
      "subdomain": "wanghao2",
      "logo": "test.png",
      "description": "王豪测试空间2",
      "telephone": "13552799004",
      "state": "active"
    }
*/
export const createSpace = params =>
  _apiPOST(API_MGMT_ROOT, '/spaces', params, Schemas.spaceSchema)

/*
  获取空间详情
  请求举例：
    curl -X GET --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' 'http://101.200.141.121/managements/v1/space'
  成功返回结果：
    {
      "id": 2,
      "name": "王豪测试空间2",
      "full_name": "王豪测试空间2",
      "subdomain": "wanghao2",
      "logo": "test.png",
      "description": "王豪测试空间2",
      "telephone": "13552799004",
      "state": "active"
    }
*/
export const getSpace = params =>
  _apiGET(API_MGMT_ROOT, '/space', params, Schemas.spaceSchema)

export const setSpace = params =>
  _apiPUT(API_MGMT_ROOT, '/space', params, Schemas.spaceSchema)

/*
  空间管理员创建部门
  请求举例：
    {
      name: '部门修改测试1',
    }
  成功返回结果：
    {
      "id": 2,
      "name": "部门修改测试1",
      "space_id": 2,
      "parent": {
        "id": 1,
        "name": "行政部",
        "space_id": 2
      }
    }
  请求命令：
*/
export const createDepartment = params =>
  _apiPOST(API_MGMT_ROOT, '/departments', params, Schemas.departmentSchema)
/*
空间管理员修改部门
  请求举例：
    {
      id: '2',
      name: '部门修改测试1',
      parent_id: '1'
    }
  成功返回结果：
    {
      "id": 2,
      "name": "部门修改测试1",
      "space_id": 2,
      "parent": {
        "id": 1,
        "name": "行政部",
        "space_id": 2
      }
    }
  请求命令：
    curl -X POST --header 'Content-Type: application/x-www-form-urlencoded' --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' -d 'name=%E6%B5%8B%E8%AF%95%E9%83%A8%E9%97%A8' 'http://101.200.141.121/managements/v1/departments'
*/
export const updateDepartment = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/departments/${id}`, params, Schemas.departmentSchema)
/*
  查看部门信息及其层级结构（直接父节点和直接子节点）
  请求举例：
    {
      id: '1',
    }
  成功返回结果：
    {
      "id": 2,
      "name": "部门修改测试1",
      "space_id": 2,
      "parent": {
        "id": 1,
        "name": "行政部",
        "space_id": 2
      }
    }
  请求命令：
    curl -X GET --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' 'http://101.200.141.121/managements/v1/departments/1'
*/
export const getDepartmentInfo = id =>
  _apiGET(API_MGMT_ROOT, `/departments/${id}`, null, Schemas.departmentSchema)
/*
  查看所有部门信息及其层级结构
  请求举例：
  成功返回结果：
    [{
      "id": 2,
      "name": "部门修改测试1",
      "space_id": 2,
      "parent": {
        "id": 1,
        "name": "行政部",
        "space_id": 2
      }
    }]
  请求命令：
    curl -X GET --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' 'http://101.200.141.121/managements/v1/departments/1'
*/
export const getDepartments = () =>
  _apiGET(API_MGMT_ROOT, '/departments', null, Schemas.departmentSchemaArray)
/*
  删除部门（部门下面没有子部门或员工才可以删除）
  请求举例：
    {
      id: '2',
    }
  成功返回结果：
    no content
  请求命令：
    curl -X DELETE --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' 'http://101.200.141.121/managements/v1/departments/2'
*/
export const deleteDepartment = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/departments/${id}`,
    null,
    Schemas.departmentSchema
  )
/*
  列出空间或部门的所有员工（不传department_id，列出空间的所有员工；传递department_id，列出该部门的所有员工）
    1.不传department_id，列出空间的所有员工
    2.传递department_id，列出该部门的所有员工
    3.传递name属性，可根据name属性搜索过滤
  请求举例：
    {
      department_id: '1'
    }
  成功返回结果：
    [
      {
        "id": 21,
        "name": "测试员工1",
        "gender": "male",
        "email": "ceshiyouiang@qq.com",
        "phone_num": "17090147298",
        "state": "init",
        "space_title": "测试职务",
        "departments": [
          {
            "id": 1,
            "name": "行政部"
          }
        ]
      }
    ]
  请求命令：
    curl -X GET --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' 'http://101.200.141.121/managements/v1/members?department_id=1'
*/
export const getMembers = params =>
  _apiGET(API_MGMT_ROOT, `/members`, params, Schemas.spaceMemberSchemaArray)

/*
  空间管理员添加新员工
  请求举例：
    {
      name: '测试员工2',
      gender: 'male',
      phone_num: '17090147299',
      email: 'ceshiyouiang2@qq.com',
      department_ids: '1',
      space_title: '测试职务',
      parent_id: '1'
    }
  成功返回结果：
    {
      "id": 22,
      "name": "测试员工2",
      "gender": "male",
      "email": "ceshiyouiang2@qq.com",
      "phone_num": "17090147299",
      "state": "init",
      "space_title": "测试职务",
      "departments": [
        {
          "id": 1,
          "name": "行政部"
        }
      ]
    }
  请求命令：
    curl -X POST --header 'Content-Type: application/x-www-form-urlencoded' --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' -d 'name=%E6%B5%8B%E8%AF%95%E5%91%98%E5%B7%A52&gender=male&phone_num=17090147299&email=ceshiyouiang2%40qq.com&department_ids=1&space_title=%E6%B5%8B%E8%AF%95%E8%81%8C%E5%8A%A1&parent_id=1' 'http://101.200.141.121/managements/v1/members'
*/
export const createMember = params =>
  _apiPOST(API_MGMT_ROOT, '/members', params, Schemas.spaceMemberSchema)
/*
  修改员工信息
  请求举例：
    id:21
    {
      name:'UpdateByUpdateMembers'
    }
  成功返回结果：
    {
      "id":21,
      "name":"UpdateByUpdateMembers",
      "gender":"male",
      "email":"ceshiyouiang@qq.com",
      "phone_num":"17090147298",
      "state":"init",
      "space_title":"测试职务",
      "departments":[
        {"id":1,"name":"行政部"}
      ]
    }
  请求命令：
    curl -X PUT --header 'Content-Type: application/x-www-form-urlencoded' --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' -d 'name=UpdateByUpdateMembers' 'http://101.200.141.121/managements/v1/members/1'
*/
export const updateMember = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/members/${id}`, params, Schemas.spaceMemberSchema)

/**
 * 获取空间员工信息
 */
export const getSpaceMemberInfo = id =>
  _apiGET(API_MGMT_ROOT, `/members/${id}`, null, Schemas.spaceMemberSchema)

/*
  删除员工（只有未激活的并且没有下属的员工才可以删除）
  请求举例：
    id:21
  成功返回结果：
    204
    no content
  请求命令：
    curl -X DELETE --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' 'http://101.200.141.121/managements/v1/members/21'
*/
export const deleteMember = id =>
  _apiDELETE(API_MGMT_ROOT, `/members/${id}`, {})

// 分店管理=============================
/*
  获取分店列表
  请求举例：
  成功返回结果：
    [

    ]
  请求命令：
    curl -X GET --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' 'http://101.200.141.121/managements/v1/locations'
*/
export const getLocations = params =>
  _apiGET(API_MGMT_ROOT, '/locations', params, Schemas.locationSchemaArray)
/**
 * 员工离职
 * @method getDismiss
 * @param  {[int]}   id [员工ID]
 */
export const getDismiss = id =>
  _apiPUT(
    API_MGMT_ROOT,
    `/members/${id}/dismiss`,
    null,
    Schemas.spaceMemberSchema
  )

/**
 * 获取只有 id 和 name 的分店信息
 * [{
    "id": 2,
    "name": "三元桥测试分店"
  }]
 * @type {[type]}
 */
export const getSerializeLocations = params =>
  _apiGET(
    API_MGMT_ROOT,
    '/locations',
    { display_all: true, per_page: 50000, ...params },
    Schemas.serializeLocationsSchemaArray
  )
/*
  创建分店
  成功返回结果:
    {
      "id": 1,
      "name": "测试分店",
      "space_id": 2,
      "description": "测试分店",
      "serial_number": "1",
      "physical_address": "三元桥",
      "size": 100,
      "telephone": null,
      "image": null,
      "sales_plan": {
        "id": 1,
        "title": "测试分店",
        "description": null,
        "unit_price": "0.0",
        "unit_cycle": "monthly",
        "space_id": 2,
        "location_id": 1,
        "created_at": "2016-05-17T11:30:50.333+08:00",
        "updated_at": "2016-05-17T11:30:50.333+08:00",
        "deleted_at": null,
        "listing_price": "0.0"
      },
      "city": {
        "id": 1,
        "name": "朝阳"
      },
      "district": {
        "id": 1,
        "name": "朝阳",
        "city_id": 1
      },
      "manager": {
        "id": 20,
        "name": "wanghao"
      },
      "operators": [
        {
          "id": 20,
          "name": "wanghao"
        }
      ]
    }
*/
export const createLocation = params =>
  _apiPOST(API_MGMT_ROOT, `/locations`, params, Schemas.locationSchema)
/*
  编辑分店
  成功返回结果：
    同创建分店
*/
export const updateLocation = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/locations/${id}`, params, Schemas.locationSchema)

/*
  修改某个分店信息
  成功返回结果：
    同创建分店
*/
export const putLocationInfo = id =>
  _apiPUT(API_MGMT_ROOT, `/locations/${id}`, null, Schemas.locationSchema)

/*
 获取某个分店信息
 成功返回结果：
 同创建分店
 */
export const getLocationInfo = (id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/locations/${id}`,
    {
      includes: 'invoices,organization_info,tasks,statistics'
    },
    Schemas.locationSchema
  )

/*
  删除分店
  成功返回结果：
    同创建分店
*/
export const deleteLocation = id =>
  _apiDELETE(API_MGMT_ROOT, `/locations/${id}`)

/*
  获取某分店分区列表
  成功返回结果：
*/
export const getAreas = params =>
  _apiGET(API_MGMT_ROOT, `/areas`, params, Schemas.areaSchemaArray)

export const getAreasWithoutSchema = params =>
  _apiGET(API_MGMT_ROOT, `/areas`, params)

/*
  获取某分店分区列表 (不需要 token, 返回数据只含有简单信息)
  成功返回结果：
*/
export const getAreasWithoutLimit = (location_id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/locations/${location_id}/areas/simple_areas`,
    params,
    Schemas.simpleAreaSchemaArray
  )

export const getEnteringRate = (ids, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/locations/entering_rate?location_ids=${ids}`,
    params,
    Schemas.simpleAreaSchemaArray
  )

/*
 获取某分店分区及工位使用情况
 */
export const getLocationAreasUsage = location_id =>
  _apiGET(API_MGMT_ROOT, `/areas/usage`, { location_id })

/*
  创建分区
  成功返回结果：
    {
      "id": 3,
      "name": "测试分区",
      "location_id": 2,
      "area_type": "public_office",
      "orientation": "east",
      "lock": true,
      "wall": true,
      "capacity": null,
      "desks_count": null,
      "real_capacity": null,
      "devices": []
    }
*/
export const createArea = (location_id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/areas`,
    { location_id, ...params },
    Schemas.areaSchema
  )

//分区详情
export const getAreaInfo = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/areas/${id}`, params, Schemas.areaSchema)

/*
  修改分区
  成功返回结果：
    同创建分区
*/
export const updateArea = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/areas/${id}`, params, Schemas.areaSchema)

/*
  批量修改分区
  成功返回结果：
    同分区列表
*/
export const batchUpdateAreas = params =>
  _apiPUT(API_MGMT_ROOT, `/areas/batch_update`, params, Schemas.areaSchemaArray)

/*
  删除分区
  成功返回结果：
  204
  no content
*/
export const deleteArea = id => _apiDELETE(API_MGMT_ROOT, `/areas/${id}`)

// 获取工位预定记录
export const getDeskReservations = params =>
  _apiGET(API_MGMT_ROOT, `/desks/reservations`, params, null)

/*
  返回分店的工位列表，也可以按区域查询
    location_id 是必传的
    area_id 可选（若不传，则返回该分店下的所有工位列表，若传，则显示该区域下的所有工位列表）
  例如：
    getDesks(2,{
      area_id: '1'
    })
  成功返回结果：
  请求举例：
    curl -X GET --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' 'http://101.200.141.121/managements/v1/locations/2/desks?area_id=1'
*/
export const getDesks = params =>
  _apiGET(API_MGMT_ROOT, `/desks`, params, Schemas.deskSchemaArray)

export const getAvailableDesks = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/desks/available_desks`,
    params,
    Schemas.deskSchemaArray
  )

/*
  创建单个工位。只能在创建区域时才能批量创建工位
  成功返回结果：
    {
      "id": 1,
      "serial_number": "1",
      "list_price": 100,
      "location": {
        "id": 2,
        "name": "测试分店"
      },
      "area": {
        "id": 1,
        "name": "测试分区修改",
        "area_type": "public_office"
      }
    }
  请求举例：
    curl -X POST --header 'Content-Type: application/x-www-form-urlencoded' --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' -d 'list_price=100' 'http://101.200.141.121/managements/v1/areas/1/desks'
*/
export const createDesk = (area_id, params) =>
  _apiPOST(API_MGMT_ROOT, `/areas/${area_id}/desks`, params, Schemas.deskSchema)

/**
 * 批量添加工位到分区
 * @param  {[string|id]} area_id [分区ID]
 * @param  {[Object]} params  [desk_serial_numbers]
 */
export const addDesks = (area_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/areas/${area_id}/add_desks`,
    params,
    Schemas.areaSchema
  )

/*
  修改
  成功返回结果：
    {
      "id": 1,
      "serial_number": "1",
      "list_price": 200,
      "location": {
        "id": 2,
        "name": "测试分店"
      },
      "area": {
        "id": 1,
        "name": "测试分区修改",
        "area_type": "public_office"
      }
    }
  请求举例：
    curl -X PUT --header 'Content-Type: application/x-www-form-urlencoded' --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' -d 'list_price=200' 'http://101.200.141.121/managements/v1/desks/1'
*/
export const updateDesk = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/desks/${id}`, params, Schemas.deskSchema)
//获取工位详情
export const getDeskDetail = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/desks/${id}`, params, Schemas.deskSchema)
/*
  删除工位
  成功返回结果：
    204
    no content
*/
export const deleteDesk = id =>
  _apiDELETE(API_MGMT_ROOT, `/desks/${id}`, null, Schemas.deskSchema)

/*
  转移工位（把工位转移到另一个区域）
  成功返回结果：
    204
    no content
  请求命令：
    curl -X PUT --header 'Content-Type: application/x-www-form-urlencoded' --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' -d 'dest_area_id=4' 'http://101.200.141.121/managements/v1/desks/2/transfer_to_area'
*/
export const transferDeskToArea = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/desks/${id}/transfer_to_area`, params)
// 公司管理=============================

/*
  获取公司列表
  成功返回结果：
    [
      {
        "id": 2,
        "name": "测试公司2",
        "full_name": "测试公司2",
        "logo": null,
        "fund_raising": "angel_round",
        "description": null,
        "contacts_name": "wanghao",
        "contacts_phone": "17090147298",
        "contacts_id_number": "23123123342342342",
        "contacts_email": "22340991@qq.com",
        "industry": {
          "id": 1,
          "name": "互联网"
        }
      },
      {
        "id": 1,
        "name": "测试公司1",
        "full_name": "测试公司1",
        "logo": null,
        "fund_raising": "angel_round",
        "description": null,
        "contacts_name": "wanghao",
        "contacts_phone": "17090147298",
        "contacts_id_number": "2312312312312312312313123123",
        "contacts_email": "22340991@qq.com",
        "industry": {
          "id": 1,
          "name": "互联网"
        }
      }
    ]
  请求命令：
    curl -X GET --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' 'http://101.200.141.121/managements/v1/locations/2/organizations'
*/
export const getOrganizations = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/organizations`,
    params,
    Schemas.organizationSchemaArray
  )

export const getOrganizationsWithoutSchemas = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/organizations`,
    params,
  )
/*
  创建公司
  成功返回结果：
    {
      "id": 1,
      "name": "测试公司1",
      "full_name": "测试公司1",
      "logo": null,
      "fund_raising": "angel_round",
      "description": null,
      "contacts_name": "wanghao",
      "contacts_phone": "17090147298",
      "contacts_id_number": "2312312312312312312313123123",
      "contacts_email": "22340991@qq.com",
      "industry": {
        "id": 1,
        "name": "互联网"
      }
    }
  请求命令：
    curl -X POST --header 'Content-Type: application/x-www-form-urlencoded' --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' -d 'name=%E6%B5%8B%E8%AF%95%E5%85%AC%E5%8F%B81&full_name=%E6%B5%8B%E8%AF%95%E5%85%AC%E5%8F%B81&industry_id=1&fund_raising=angel_round&contacts_name=wanghao&contacts_phone=17090147298&contacts_id_number=2312312312312312312313123123&contacts_email=22340991%40qq.com' 'http://101.200.141.121/managements/v1/locations/2/organizations'
*/
export const createOrganization = (location_id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/organizations`,
    { location_id, ...params, organization_type: 'normal' },
    Schemas.organizationSchema
  )

// 创建简单公司（用户车位合同创建）
export const createSimpleOrganization = (location_id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/organizations/simple_create`,
    { location_id, ...params },
    Schemas.organizationSchema
  )

/**
 * 公司毕业/离开 （确认无手续需要办理，直接退租）
 * @method archiveOrganization
 * @param  {[int]}            location_id [分店ID]
 * @param  {[int]}            id          [公司ID]
 */
export const archiveOrganization = (location_id, id, params) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/organizations/${id}/archive`,
    { ...params, location_id },
    Schemas.organizationSchema
  )

/*
  修改公司
  成功返回结果：
    同创建公司
  请求命令：
*/
export const updateOrganization = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/organizations/${id}`,
    params,
    Schemas.organizationSchema
  )
/*
  获取公司详情
  成功返回结果：
    同创建公司
  请求命令：
    curl -X GET --header 'Accept: application/json' --header 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjAsInZlcnNpb24iOjF9.fkG7HnXR7qzktuc3mr2qiKy4PHu1PxaJzAz3K0gL8_k' 'http://101.200.141.121/managements/v1/locations/2/organizations/1'
*/
export const getOrganizationInfo = (id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/organizations/${id}`,
    params,
    Schemas.organizationSchema
  )
/*
  删除公司
  成功返回结果：
    204
    no content
  请求命令：
*/
export const deleteOrgnization = (id, location_id) =>
  _apiDELETE(API_MGMT_ROOT, `/organizations/${id}`, { location_id })

/*
  停用公司
*/
export const suspendOrganization = (id, location_id) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/organizations/${id}/suspend`,
    { location_id },
    Schemas.organizationSchema
  )

/*
  启用公司
*/
export const unsuspendOrganization = (id, location_id) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/organizations/${id}/unsuspend`,
    { location_id },
    Schemas.organizationSchema
  )

//https://devapi.kuban.io/managements/v1/locations/2/organizations/1/medium_list
//获取公司附件列表
export const getAnnexList = (org_id, params) =>
  _apiGET(API_MGMT_ROOT, `/organizations/${org_id}/medium_list`, params)

//https://devapi.kuban.io/managements/v1/subscriptions/medium_filename_list
// 获取公司附件类型
// export const getAnnexTypeList = (params) => _apiGET(API_MGMT_ROOT, `/subscriptions/medium_filename_list`, params)

// https://devapi.kuban.io/managements/v1/locations/2/organizations/23661/upload_medium
// 上传公司附件
export const postAnnex = (loc_id, org_id, params) =>
  _apiPOST(API_MGMT_ROOT, `/organizations/${org_id}/upload_medium`, params)

// 更新公司附件
export const putAnnex = (loc_id, org_id, params) =>
  _apiPUT(API_MGMT_ROOT, `/organizations/${org_id}/update_medium`, params)

//https://devapi.kuban.io/managements/v1/locations/2/organizations/1/destroy_medium
// 删除公司附件
export const deleteAnnex = (loc_id, org_id, params) =>
  _apiDELETE(API_MGMT_ROOT, `/organizations/${org_id}/destroy_medium`, params)

/**
 * 取消工位分配
 * @param  {[int]} loc_id [location]
 * @param  {[int]} org_id [organizations]
 * @param  {[int]} id     [member_id]
 */
export const putFreeDeskOrgMember = (org_id, id) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/organizations/${org_id}/organization_members/${id}/free_desk`,
    null,
    Schemas.userSchema
  )

export const createOrgMember = params =>
  _apiPOST(API_MGMT_ROOT, `/users`, params, Schemas.userSchema)
/*
  修改员工信息
  成功返回结果：
  {
    "id": 99,
    "name": "艾男男",
    "phone_num": "13542288561",
    "wechat": null,
    "email": "ceshi9067@163.com",
    "gender": null,
    "nickname": null,
    "birthday": null,
    "birthplace": null,
    "interest": null,
    "experience": null,
    "title": "测试职务",
    "contract": "fadfadfasdfadsf",
    "station": "1000",
    "serial_number": "2",
    "role": "employer"
  }
  请求命令：
*/

export const updateOrgMember = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/users/${id}`, params, Schemas.userSchema)

//GET /managements/v2/users/ad_info
//通过AD名查询AD账户信息
export const VerifyDcName = params =>
  _apiGET(API_MGMT_ROOT, `/users/ad_info`, params)

// 入驻工位
export const assignDeskUser = (id, org_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/organizations/${org_id}/organization_members/${id}/assign_desk`,
    params,
    Schemas.userSchema
  )

/*
 公司员工挂失电话
 */
export const updateOrganizationMemberPhone = id =>
  _apiPUT(API_MGMT_ROOT, `/users/${id}/lost_phone`, null, Schemas.userSchema)

/*
删除公司员工
  成功返回结果：
    204
    no content
  请求命令：
*/
export const deleteOrgnizationMember = (id, organization_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/organizations/${organization_id}/organization_members/${id}/dismiss`,
    params,
    Schemas.userSchema
  )

export const updateOrgnizationMemberRole = (id, organization_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/organizations/${organization_id}/organization_members/${id}`,
    params,
    Schemas.userSchema
  )

export const enrollOrgnizationMemberRole = (id, organization_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/organizations/${organization_id}/organization_members/${id}/enroll`,
    params,
    Schemas.userSchema
  )

// 公司动态 =============================

export const deleteOrganizationNote = (location_id, organization_id, id) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/locations/${location_id}/organizations/${organization_id}/organization_notes/${id}`,
    null,
    Schemas.organizationNoteSchema
  )

export const createOrganizationNote = (location_id, organization_id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/locations/${location_id}/organizations/${organization_id}/organization_notes`,
    params,
    Schemas.organizationNoteSchema
  )
export const getOrganizationNotes = (location_id, organization_id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/organizations/${organization_id}/organization_notes`,
    params,
    Schemas.organizationNoteSchemaArray
  )

// CRM 管理 =============================

const CRM_MODULE_ROOT = `${API_MGMT_ROOT}/crm`

// 客户管理=============================

/*
  客户列表
*/
export const searchCustomers = params =>
  _apiGET(
    CRM_MODULE_ROOT,
    `/customers/search`,
    params,
    Schemas.customerSchemaArray
  )

// 联系人管理=============================

/*
  联系人列表
*/
export const getContacts = () =>
  _apiGET(CRM_MODULE_ROOT, `/contacts`, null, Schemas.contactsSchemaArray)
/*
  创建联系人
*/
export const createContacts = params =>
  _apiPOST(CRM_MODULE_ROOT, `/contacts`, params, Schemas.contactsSchema)
/*
  修改联系人
*/
export const updateContacts = (id, params) =>
  _apiPUT(CRM_MODULE_ROOT, `/contacts/${id}`, params, Schemas.contactsSchema)
/*
  获取联系人详情
*/
export const getContactsInfo = id =>
  _apiGET(CRM_MODULE_ROOT, `/contacts/${id}`, null, Schemas.contactsSchema)
/*
  删除联系人
*/
export const deleteContacts = id =>
  _apiDELETE(CRM_MODULE_ROOT, `/contacts/${id}`, null, Schemas.contactsSchema)

// 线索管理=============================
/*
  线索列表
*/
export const getLeads = params =>
  _apiGET(CRM_MODULE_ROOT, `/leads`, params, Schemas.leadSchemaArray)
/*
  查看用户线索列表
*/
export const getLeadsByUser = params =>
  _apiGET(CRM_MODULE_ROOT, `/leads`, params, Schemas.leadSchemaArray)
/*
  新建线索
*/
export const createLead = params =>
  _apiPOST(CRM_MODULE_ROOT, `/leads`, params, Schemas.leadSchema)
/*
  线索详情
*/
export const getLead = id =>
  _apiGET(CRM_MODULE_ROOT, `/leads/${id}`, null, Schemas.leadSchema)
/*
  编辑线索
*/
export const updateLead = (id, params) =>
  _apiPUT(CRM_MODULE_ROOT, `/leads/${id}`, params, Schemas.leadSchema)
/*
  删除线索
*/
export const deleteLead = id =>
  _apiDELETE(CRM_MODULE_ROOT, `/leads/${id}`, null)

// 机会管理=============================
/*
  机会列表
*/
export const getOpportunities = params =>
  _apiGET(
    CRM_MODULE_ROOT,
    `/opportunities`,
    params,
    Schemas.opportunitySchemaArray
  )
/*
用户机会列表
*/
export const getOpportunitiesByUser = params =>
  _apiGET(
    CRM_MODULE_ROOT,
    `/opportunities/get_opportunities_by_user`,
    params,
    Schemas.opportunitySchemaArray
  )
/*
同一手机号机会列表
*/
export const getOpportunitiesByPhoneNum = params =>
  _apiGET(
    CRM_MODULE_ROOT,
    `/customers/get_customers_by_phone`,
    params,
    Schemas.opportunitySchemaArray
  )
/*
  新建机会
*/
export const createOpportunity = params =>
  _apiPOST(CRM_MODULE_ROOT, `/opportunities`, params, Schemas.opportunitySchema)
/*
  获取机会详情
*/
export const getOpportunityInfo = id =>
  _apiGET(
    CRM_MODULE_ROOT,
    `/opportunities/${id}`,
    null,
    Schemas.opportunitySchema
  )
/*
  修改机会
*/
export const updateOpportunity = (id, params) =>
  _apiPUT(
    CRM_MODULE_ROOT,
    `/opportunities/${id}`,
    params,
    Schemas.opportunitySchema
  )
/*
  删除机会
*/
export const deleteOpportunity = id =>
  _apiDELETE(
    CRM_MODULE_ROOT,
    `/opportunities/${id}`,
    null,
    Schemas.opportunitySchema
  )

// 销售记录模块=============================
/*
  获取所有的销售记录
*/
export const getAllCrmRecords = params =>
  _apiGET(CRM_MODULE_ROOT, `/crm_records`, params, Schemas.crmRecordSchemaArray)

/**
 * 获取按照条件搜索销售记录
 * @type {[type]}
 */
export const getSearchCrmRecords = params =>
  _apiGET(
    CRM_MODULE_ROOT,
    `/crm_records/record_search`,
    params,
    Schemas.crmRecordSchemaArray
  )

/*
  获取销售机会的销售记录
*/
export const getCrmRecordsByOpportunity = (opportunity_id, params) =>
  _apiGET(
    CRM_MODULE_ROOT,
    `/opportunities/${opportunity_id}/crm_records/get_records_by_opportunity`,
    params,
    Schemas.crmRecordSchemaArray
  )
/*
  新建销售记录
*/
export const createCrmRecord = (opportunity_id, params) =>
  _apiPOST(
    CRM_MODULE_ROOT,
    `/opportunities/${opportunity_id}/crm_records`,
    params,
    Schemas.crmRecordSchema
  )
/*
  修改销售记录
*/
export const updateCrmRecord = (id, opportunity_id, params) =>
  _apiPUT(
    CRM_MODULE_ROOT,
    `/opportunities/${opportunity_id}/crm_records/${id}`,
    params,
    Schemas.crmRecordSchema
  )
/*
  删除销售记录
*/
export const deleteCrmRecord = (id, opportunity_id) =>
  _apiDELETE(
    CRM_MODULE_ROOT,
    `/opportunities/${opportunity_id}/crm_records/${id}`,
    null
  )

// 销售记录讨论模块=============================
/*
  获取销售记录讨论列表
*/
export const getCrmComments = (opportunity_id, crm_record_id) =>
  _apiGET(
    CRM_MODULE_ROOT,
    `/opportunities/${opportunity_id}/crm_records/${crm_record_id}/crm_comments`,
    null,
    Schemas.crmCommentSchemaArray
  )
/*
  添加讨论
*/
export const createCrmComment = (opportunity_id, crm_record_id, params) =>
  _apiPOST(
    CRM_MODULE_ROOT,
    `/opportunities/${opportunity_id}/crm_records/${crm_record_id}/crm_comments`,
    params,
    Schemas.crmCommentSchema
  )
/*
  回复讨论
*/
export const replyCrmComment = (id, opportunity_id, crm_record_id, params) =>
  _apiPOST(
    CRM_MODULE_ROOT,
    `/opportunities/${opportunity_id}/crm_records/${crm_record_id}/crm_comments/${id}/reply`,
    params,
    Schemas.crmCommentSchema
  )
/*
  编辑讨论内容
*/
export const updateCrmComment = (id, opportunity_id, crm_record_id, params) =>
  _apiPUT(
    CRM_MODULE_ROOT,
    `/opportunities/${opportunity_id}/crm_records/${crm_record_id}/crm_comments/${id}`,
    params,
    Schemas.crmCommentSchema
  )
/*
  删除讨论内容
*/
export const deleteCrmComment = (id, opportunity_id, crm_record_id) =>
  _apiDELETE(
    CRM_MODULE_ROOT,
    `/opportunities/${opportunity_id}/crm_records/${crm_record_id}/crm_comments/${id}`,
    null
  )

// 其他=============================
/*
  查看下属的CRM首页
*/
export const getSubordinateDashboard = params =>
  _apiPUT(CRM_MODULE_ROOT, `/homes/subordinate_dashboard`, params)
/*
  CRM首页
*/
export const getHome = params => _apiGET(CRM_MODULE_ROOT, `/homes`, params)
/*
  CRM客户首页数据
*/
export const getCustomerIndex = params =>
  _apiGET(CRM_MODULE_ROOT, `/homes/customer_index`, params)

//销售库存
export const getCrmInventory = params =>
  _apiGET(API_MGMT_ROOT, `/space/inventory`, params)

// 客户的意向办公区=======================
/*
  客户的意向办公区列表
*/
export const getIntentOffices = opp_id =>
  _apiGET(
    CRM_MODULE_ROOT,
    `/opportunities/${opp_id}/office_rent_intentions`,
    null,
    Schemas.intentAreasSchemaArray
  )
/*
  创建 意向办公区
*/
export const createIntentOffice = (opp_id, params) =>
  _apiPOST(
    CRM_MODULE_ROOT,
    `/opportunities/${opp_id}/office_rent_intentions`,
    params,
    Schemas.intentAreasSchema
  )
/*
  删除 意向办公区
*/
export const deleteIntentOffice = (opp_id, area_id) =>
  _apiDELETE(
    CRM_MODULE_ROOT,
    `/opportunities/${opp_id}/office_rent_intentions/${area_id}`,
    null,
    Schemas.intentAreasSchema
  )
/*
  修改 意向办公区
*/
export const updateIntentOffice = (opp_id, area_id, params) =>
  _apiPUT(
    CRM_MODULE_ROOT,
    `/opportunities/${opp_id}/office_rent_intentions/${area_id}`,
    params,
    Schemas.intentAreasSchema
  )

// 任务管理=============================
/*
  任务列表
*/
export const getTasks = params =>
  _apiGET(API_MGMT_ROOT, `/tasks`, params, Schemas.taskSchemaArray)

/*
 用户任务列表
 */
export const getUserTasks = params =>
  _apiGET(API_MGMT_ROOT, `/tasks/get_my_tasks`, params, Schemas.taskSchemaArray)

/*
 分店任务列表
 */
export const getLocationTasks = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/tasks/get_location_tasks`,
    params,
    Schemas.taskSchemaArray
  )

/*
 下属任务列表
 */
export const getDescendantTasks = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/tasks/get_descendant_tasks`,
    params,
    Schemas.taskSchemaArray
  )

/*
  新建任务
*/
export const createTasks = params =>
  _apiPOST(API_MGMT_ROOT, `/tasks`, params, Schemas.taskSchema)
/*
  任务详情
*/
export const getTask = id =>
  _apiGET(API_MGMT_ROOT, `/tasks/${id}`, null, Schemas.taskSchema)
/*
  更新任务
*/
export const updateTask = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/tasks/${id}`, params, Schemas.taskSchema)
/*
  删除任务
*/
export const deleteTask = id => _apiDELETE(API_MGMT_ROOT, `/tasks/${id}`, null)

/*
  添加参与者
*/
export const addFollower = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/tasks/${id}/add_follower`,
    params,
    Schemas.taskSchema
  )

/*
 添加描述
 */
export const addTaskComment = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/tasks/${id}/add_comment`, params, Schemas.taskSchema)

/*
  删除参与者
*/
export const deleteFollower = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/tasks/${id}/remove_follower`,
    params,
    Schemas.taskSchema
  )

/**
 * 合同
 */
export const getSubscriptions = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/subscriptions`,
    params,
    Schemas.subscriptionSchemaArray
  )

export const getSubscription = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/subscriptions/${id}`,
    null,
    Schemas.subscriptionSchema
  )

export const getFormalSubSerialNumber = params =>
  _apiGET(API_MGMT_ROOT, `/subscriptions/serial`, params, null)

/**
 * 展开交租阶段
 * @type {[type]}
 */
export const getExpandRentingPhase = params =>
  _apiPOST(API_MGMT_ROOT, `/subscriptions/expand_renting_phase`, params)

// 展开缴租阶段(换房)
export const getChangeRoomExpandRentingPhase = (id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/subscriptions/${id}/change_room_expand_renting_phase`,
    params
  )

/**
 * 创建合同
 */
export const createSubscriptions = (id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/subscriptions`,
    { location_id: id, ...params },
    Schemas.subscriptionSchema
  )

// 修改合同
export const putSubscriptions = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/subscriptions/${id}`,
    params,
    Schemas.subscriptionSchema
  )

// 修改合同(换房)
export const putSubscriptionsChangeRoom = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/subscriptions/${id}/change_room`,
    params,
    Schemas.subscriptionSchema
  )

// 合同详情
export const getSubscriptionDetail = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/subscriptions/${id}`,
    null,
    Schemas.subscriptionSchema
  )

// 修改车位合同的车牌号
export const putParkingContractCarPlates = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/subscriptions/${id}/change_car_plates`,
    params,
    Schemas.subscriptionSchema
  )

// 车位合同 录入车牌到车库
export const putContractsSaveCarPlates = params =>
  _apiPUT(
    API_MGMT_ROOT,
    `/subscriptions/save_car_plates`,
    params,
    Schemas.subscriptionSchemaArray
  )

/**
 * 删除正在审批状态的合同
 * @type {[type]}
 */
export const deleteSubscription = (id, params) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/subscriptions/${id}`,
    null,
    Schemas.subscriptionSchema
  )

/**
 * 终止合同
 * @param  {[number]} id     [合同id]
 * @param  {[obj]} params [expire_date， terminate_reason, terminate_note]
 * @return {[promise]}
 */
export const terminateSubScriptiopns = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/subscriptions/${id}/terminate`,
    params,
    Schemas.subscriptionSchema
  )

/**
 * 终止公司所有正式合同（公司退租时用）
 */
export const terminateAllSubScriptions = params =>
  _apiPUT(
    API_MGMT_ROOT,
    `/subscriptions/terminate_all`,
    params,
    Schemas.subscriptionSchemaArray
  )

/**
 * 公司退款项
 * @method exitRefundingOrg
 */
export const exitRefundingSubScriptions = params =>
  _apiGET(API_MGMT_ROOT, `/subscriptions/exit_refunding`, params, null)

/**
 * 获取终止合同退款项
 * @method terminationRefunding
 * @param  {[type]}             id     [description]
 */
export const terminationRefunding = (id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/subscriptions/${id}/termination_refunding`,
    params,
    null
  )

/**
 * 获取合同退款项的退款类型
 * @method getSubscriptionLineItemRefundType
 * @param  {[type]}             id     [refund_type]
 */
export const getSubscriptionLineItemRefundType = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/subscriptions/line_item_refund_type`,
    params,
    Schemas.subscriptionLineItemRefundTypeSchemaArray
  )

/**
 * 审批合同
 * @param  {[int]} id [合同ID]
 */
export const updateSubScriptionApprove = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/subscriptions/${id}/approve`,
    params,
    Schemas.subscriptionSchema
  )

/**
 * 合同分配门禁权限
 * @param  {[int]} id [合同ID]
 */
export const putSubscriptionLockPermissions = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/subscriptions/${id}/update_lock_permissions`,
    params,
    Schemas.subscriptionSchema
  )

/**
 * 账单支付信息
 */
export const getInvoicesPayments = params =>
  _apiGET(API_MGMT_ROOT, '/invoices/sales_payments', params, null)

/**
 * 账单按条件查询账单
 */
export const getInvoices = params => _apiGET(API_MGMT_ROOT, '/invoices', params)

/**
 * 添加账单
 * @method postInvoices
 */
export const postInvoices = params =>
  _apiPOST(API_MGMT_ROOT, `/invoices`, params, Schemas.invoicesSchema)

/**
 * 账单详情
 */
export const getInvoice = id =>
  _apiGET(API_MGMT_ROOT, '/invoices/' + id, null, Schemas.invoicesSchema)

// 删除账单
export const deleteInvoice = (id, params) =>
  _apiDELETE(API_MGMT_ROOT, `/invoices/${id}`, params, Schemas.invoicesSchema)

/**
 * 批量审核账单
 * @method putBatchReview
 */
export const putBatchReview = params =>
  _apiPUT(API_MGMT_ROOT, `/invoices/batch_review`, params)

/**
 * 批量核销账单
 * @method putBatchCheck
 */
export const putBatchCheck = params =>
  _apiPUT(API_MGMT_ROOT, `/invoices/batch_check_paid`, params)

/**
 * 批量发送账单邮件
 * @method putBatchSendInvoicesEmail
 */
export const putBatchSendInvoicesEmail = params =>
  _apiPUT(API_MGMT_ROOT, `/invoices/batch_send_remind`, params)

/**
 * 审核账单
 * @method updateInvoice
 * @param  {[int]}      id [账单ID]
 */
export const reviewInvoice = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/invoices/${id}/review`,
    params,
    Schemas.invoicesSchema
  )

/**
 * 修改账单
 */
export const putInvoice = (id, params) =>
  _apiPUT(API_MGMT_ROOT, '/invoices/' + id, params, Schemas.invoicesSchema)

/**
 * 财务账单发票相关
 */
// 获取发票列表
export const getInvoiceApplicationList = params =>
  _apiGET(API_MGMT_ROOT, '/invoicing_applications', params)

// 申请开发票
export const postInvoiceApplication = params =>
  _apiPOST(API_MGMT_ROOT, '/invoicing_applications', params)

// 批量处理开票
export const processInvoiceApplicationBatch = params =>
  _apiPUT(API_MGMT_ROOT, '/invoicing_applications/to_process_batch', params)

// 批量完成开票
export const finishInvoiceApplicationBatch = params =>
  _apiPUT(API_MGMT_ROOT, '/invoicing_applications/to_issue_batch', params)

// 获取发票申请详情
export const getInvoiceApplication = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/invoicing_applications/${id}`, params)

// 修改发票申请
export const updateInvoiceApplication = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/invoicing_applications/${id}`, params)

// 处理发票申请
export const processInvoiceApplication = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/invoicing_applications/${id}/to_process`, params)

// 完成发票申请
export const finishInvoiceApplication = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/invoicing_applications/${id}/to_issue`, params)

// 发票作废(批处理)
export const abortInvoiceApplication = params =>
  _apiDELETE(API_MGMT_ROOT, `/invoicing_applications/abort_invoicing`, params)

/**
 * 发票库相关
 */
// 获取发票库列表
export const getInvoicingPools = params =>
  _apiGET(
    API_MGMT_ROOT,
    '/invoicing_pools',
    params,
    Schemas.invoicingPoolSchemaArray
  )

// 修改发票库列表
export const putInvoicingPool = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/invoicing_pools/${id}`,
    params,
    Schemas.invoicingPoolSchema
  )

// 创建发票库列表
export const createInvoicingPool = params =>
  _apiPOST(
    API_MGMT_ROOT,
    '/invoicing_pools',
    params,
    Schemas.invoicingPoolSchema
  )

// 删除发票库列表
export const deleteInvoicingPool = (id, params) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/invoicing_pools/${id}`,
    params,
    Schemas.invoicingPoolSchema
  )

/**
 * 发送邮件 PUT /managements/v1/invoices/{id}/send_remind
 */
export const putSendRemind = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/invoices/${id}/send_remind`,
    params,
    Schemas.invoicesSchema
  )

/**
 * 人工收款
 */
export const putManualPay = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/invoices/${id}/manual_pay`,
    params,
    Schemas.invoicesSchema
  )

/**
 * 删除账单付款凭证
 */
export const deletePayment = (id, params) =>
  _apiDELETE(API_MGMT_ROOT, `/invoices/${id}/reset_payment`, params)

/**
 * 修改账单付款凭证
 */
export const putResetVouchers = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/invoices/${id}/reset_vouchers`, params)

// 账单核销
export const putCheckPaid = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/invoices/${id}/check_paid`,
    params,
    Schemas.invoicesSchema
  )

// 账单确认核销
export const putConfirmPaid = id =>
  _apiPUT(
    API_MGMT_ROOT,
    `/invoices/${id}/confirm_paid`,
    null,
    Schemas.invoicesSchema
  )

/**
 * 公司或员工搜索  这个接口不建议加Schemas
 */
export const getSearchOrganization = params =>
  _apiGET(API_MGMT_ROOT, '/organizations/search', params)

/**
 * 分店搜索(会员 公司 合同 账单 工位 账单)
 */
export const getSearchAllType = params =>
  _apiGET(API_MGMT_ROOT, '/search', params)

/**
 * Save device token
 */
export const postDeviceToken = params =>
  _apiPOST(API_MGMT_ROOT, '/device_tokens', params)

/**
 * 导入部门信息 URL
 */
export const departmentsImportUrl = API_MGMT_ROOT + '/departments/import'

/**
 * 导入部门信息 URL
 */
export const membersImportUrl = API_MGMT_ROOT + '/members/import_members'

/**
 * 导入分区信息 URL
 * @param loc_id
 */
export const areasImportUrl = loc_id => API_MGMT_ROOT + `/areas/import_areas`

/**
 * 导入车位信息 URL
 * @param loc_id
 */
export const parkingImportUrl = loc_id =>
  API_MGMT_ROOT + `/areas/import_parkings`

/**
 * 导入公司信息 URL
 * @param loc_id
 */
export const orgImportUrl = loc_id =>
  API_MGMT_ROOT + `/organizations/import_organizations`

/**
 * 导入合同信息 URL
 * @param loc_id
 */
export const subscriptionsImportUrl = loc_id =>
  API_MGMT_ROOT + `/subscriptions/import_subscriptions`

/**
 * 同步导入员工信息 URL
 * @param loc_id
 */
export const orgMembersImportUrl = loc_id =>
  API_MGMT_ROOT + `/organizations/import_members`

/**
 * 异步导入员工信息 URL
 * @param loc_id
 */
export const orgMembersAsyncImportUrl = loc_id =>
  API_MGMT_ROOT + `/organizations/import_members_async`

/**
 * 异步导入加购权益兑换码 URL
 * @param loc_id
 */
 export const promocodeAsyncImportUrl = () =>
 API_MGMT_ROOT + `/discount_goods/parse_promo_codes`


/**
 * 活动过签到 URL
 * @param activity_id
 */
export const signInActivity = activity_id =>
API_MGMT_ROOT + `/activities/activities/${activity_id}/batch_signin`

/**
 * 导入分店工位信息 URL
 * @param loc_id
 */
export const locationAreasImportUrl = loc_id =>
  API_MGMT_ROOT + `/locations/${loc_id}`

/**
 * 获取CRM 首页信息
 */
export const getCrmHomes = params =>
  _apiGET(API_MGMT_ROOT, '/crm/homes', params)

/**
 * CRM 搜索
 */
export const getSearchCustomers = params =>
  _apiGET(API_MGMT_ROOT, '/crm/customers/search', params)

/**
 * 空间设置
 */
// export const updateSpaceSetting = (params) => _apiPUT(API_MGMT_ROOT,'/space/update_space_settings', params, Schemas.spacesSettingSchema)

/**
 * 获取通用设置
 */
export const getSpaceSetting = params =>
  _apiGET(API_MGMT_ROOT, '/settings', params)

// 修改通用设置
export const putSpaceSetting = params =>
  _apiPUT(API_MGMT_ROOT, '/settings', params)

// 查询所有权限
export const getSpaceRoles = params =>
  _apiGET(API_MGMT_ROOT, `/space_roles`, params)

// 更新space roles
export const postSpaceRoles = params =>
  _apiPOST(API_MGMT_ROOT, `/space_roles`, params)

export const putSpaceDefaultPayment = params =>
  -_apiPUT(API_MGMT_ROOT, '/settings' , params)

// // 获取公司入驻途径设置
// export const getOrganizationEnterSetting = () => _apiGET(API_MGMT_ROOT,'/space/organization_enter_setting', null, Schemas.orgEnterSettingSchema)

// // 修改公司入驻途径设置
// export const updateOrganizationEnterSetting = (params) => _apiPUT(API_MGMT_ROOT,'/space/update_organization_enter_setting', params, Schemas.orgEnterSettingSchema)

// 直接入驻公司
export const enterOrgDirectly = (org_id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/organizations/${org_id}/approve_enter`,
    params,
    Schemas.organizationSchema
  )

// 查看账单确认核销设置
// export const getInvoiceConfirmSetting = () => _apiGET(API_MGMT_ROOT, `/space/invoice_confirm_setting`, null, null)

// 修改账单确认核销设置
// export const updateInvoiceConfirmSetting = (params) => _apiPUT(API_MGMT_ROOT, `/space/update_invoice_confirm_setting`, params)

// 查看打印价格设置

// 修改打印价格设置

// 查看账单自动核销和确认核销设置

// 修改账单自动核销和确认核销设置

// 税率列表
export const getSpaceTaxes = () =>
  _apiGET(API_MGMT_ROOT, '/taxes', null, Schemas.taxesSchemaArray)

// 修改税率
export const updateSpaceTaxes = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/taxes/${id}`, params, Schemas.taxesSchema)

// 创建税率
export const postSpaceTaxes = params =>
  _apiPOST(API_MGMT_ROOT, `/taxes`, params, Schemas.taxesSchema)

// 删除税率
export const deleteSpaceTaxes = id =>
  _apiDELETE(API_MGMT_ROOT, `/taxes/${id}`, null, Schemas.taxesSchema)

/**
 * 获取当前分店可分配工位
 * @param id
 */
export const getAvailableDeskNumbers = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/locations/${id}/available_desk_numbers`, params)

/**
 * 账单调整
 * @param  {[int]} id     [账单ID]
 * @param  {[object]} params [body]
 */
export const updateInvoicesAdjust = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/invoices/${id}/adjust`,
    params,
    Schemas.invoicesSchema
  )

/**
 * 账款项修改
 * @param  {[int]} id     [账单ID]
 * @param  {[object]} params [body]
 */
export const updateInvoicesLineItems = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/invoices/${id}/update_line_items`,
    params,
    Schemas.invoicesSchema
  )

/**
 * CRM来源列表获取
 */
export const getCrmSources = () =>
  _apiGET(
    API_MGMT_ROOT,
    `/crm/crm_sources`,
    null,
    Schemas.crmSourcesSchemaArray
  )

/**
 * 添加CRM来源
 * @param  {[name : string, source_type : integer]} params [来源名称，来源类型]
 */
export const createCrmSources = params =>
  _apiPOST(API_MGMT_ROOT, `/crm/crm_sources`, params, Schemas.crmSourcesSchema)

/**
 * 修改CRM来源
 * @param  {[integer]} id     [crm来源id]
 * @param  {[name : string, source_type : integer]} params [来源名称，来源类型]
 */
export const updateCrmSource = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/crm/crm_sources/${id}`,
    params,
    Schemas.crmSourcesSchema
  )

/**
 * 删除CRM来源
 * @param  {[integer]} id [crm来源id]
 */
export const deleteCrmSource = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/crm/crm_sources/${id}`,
    null,
    Schemas.crmSourcesSchema
  )

/**
 * 签为正式合同
 * @param  {[integer]} id     [合同ID]
 * @param  {[string,string]} params [file,serial]
 */
export const updateSignSubscription = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/subscriptions/${id}/sign`,
    params,
    Schemas.subscriptionSchema
  )

/**
 * 创建会议室
 * @type {[type]}
 */
export const createMeetingRooms = params =>
  _apiPOST(API_MGMT_ROOT, `/meeting_rooms`, params, Schemas.meetingRootsSchema)

/**
 * 修改会议室
 * @method updateMeetingRooms
 */
export const updateMeetingRooms = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/meeting_rooms/${id}`,
    params,
    Schemas.meetingRootsSchema
  )

/**
 * 会议室列表
 * @param  {[integer]} id     [分店ID]
 */
export const getMeetingRooms = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/meeting_rooms`,
    params,
    Schemas.meetingRootsSchemaArray
  )

/**
 * 删除会议室
 * @method deleteMeetingRooms
 */
export const deleteMeetingRooms = (id, params) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/meeting_rooms/${id}`,
    params,
    Schemas.meetingRootsSchema
  )

/**
 * 会议室预定设置
 * @method updateMeetingRoomSetting
 */
export const updateMeetingRoomSetting = params =>
  _apiPUT(
    API_MGMT_ROOT,
    `/space/update_meeting_room_setting`,
    params,
    Schemas.meetingSettingSchema
  )

// 分店会议室预定设置
export const updateLocationMeetingRoomSetting = params =>
  _apiPUT(
    API_MGMT_ROOT,
    `/space/update_location_meeting_room_setting`,
    params,
    Schemas.meetingLocationSettingSchema
  )

/**
 * 预定会议室
 * @param {[integer]} area_id [会议室id]
 * @param {[date-time]} start_at [开始时间]
 * @param {[date-time]} end_at [结束时间]
 */
export const createReservations = params =>
  _apiPOST(API_MGMT_ROOT, `/reservations`, params, Schemas.reservationsSchema)

/**
 * 会议室超时情况
 * @method getOverTimeInfo
 */
export const getOverTimeInfo = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/reservations/${id}/overtime_info`, params)

/**
 * 结束会议室
 * @method putTerminate
 */
export const putTerminate = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/reservations/${id}/terminate`,
    params,
    Schemas.reservationsSchema
  )

/**
 * 获取预定会议室列表
 * @param {[integer]} area_id [会议室id]
 * @param {[string]} state [预定状态]
 */
export const getReservations = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/reservations`,
    params,
    Schemas.reservationsSchemaArray
  )

/**
 * 会议室详情
 * @param {[integer]} area_id [会议室id]
 */
export const getMeetingRoomInfo = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/meeting_rooms/${id}`,
    null,
    Schemas.meetingRootsSchema
  )

/**
 * 取消预订会议室
 * @type {[type]}
 */
export const deleteReservations = (id, params) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/reservations/${id}`,
    null,
    Schemas.reservationsSchema
  )

// 更改会议时间
export const putMeetingChangeTime = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/reservations/${id}/change_time`,
    params,
    Schemas.reservationsSchema
  )

/**
 * 创建平板信息
 */
export const createMeetingDevice = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/meeting_screens`,
    params,
    Schemas.meetingDevicesSchema
  )

/**
 * 查询平板列表
 */
export const getMeetingDevices = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/meeting_screens`,
    params,
    Schemas.meetingDevicesSchemaArray
  )

/**
 * 查询平板信息
 */
export const getMeetingDeviceMessage = (device_id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/meeting_screens/${device_id}`,
    params,
    Schemas.meetingDevicesSchema
  )

/**
 * 修改平板信息
 */
export const updateMeetingDeviceMessage = (device_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/meeting_screens/${device_id}`,
    params,
    Schemas.meetingDevicesSchema
  )

/**
 * 删除平板
 */
export const deleteMeetingDevices = (id, params) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/meeting_screens/${id}`,
    params,
    Schemas.meetingDevicesSchemaArray
  )

/**
 * FAQ 分类列表
 */
export const getSections = params =>
  _apiGET(API_MGMT_ROOT, `/sections`, params, Schemas.sectionsSchemaArray)

/**
 * 修改分类
 * @type {[type]}
 */
export const updateSections = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/sections/${id}`, params, Schemas.sectionsSchema)

/**
 * 删除分类
 * @type {[type]}
 */
export const deleteSections = id =>
  _apiDELETE(API_MGMT_ROOT, `/sections/${id}`, null, Schemas.sectionsSchema)

/**
 * 创建分类
 * @type {[type]}
 */
export const createSections = params =>
  _apiPOST(API_MGMT_ROOT, `/sections`, params, Schemas.sectionsSchema)

/**
 * 创建文章
 * @type {[type]}
 */
export const createTopics = (id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/sections/${id}/topics`,
    params,
    Schemas.topicsSchema
  )

/**
 * 获取文章详情
 * @type {[type]}
 */
export const getTopicsInfo = (id, topics_id) =>
  _apiGET(
    API_MGMT_ROOT,
    `/sections/${id}/topics/${topics_id}`,
    null,
    Schemas.topicsSchema
  )

/**
 * 删除文章
 * @type {[type]}
 */
export const deleteTopics = (section_id, topics_id) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/sections/${section_id}/topics/${topics_id}`,
    null,
    Schemas.topicsSchema
  )

/**
 * 修改文章
 * @type {[type]}
 */
export const updateTopics = (section_id, topics_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/sections/${section_id}/topics/${topics_id}`,
    params,
    Schemas.topicsSchema
  )

/**
 * 文章列表
 * @type {[type]}
 */
export const getTopics = (id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/sections/${id}/topics`,
    params,
    Schemas.topicsSchemaArray
  )

/**
 * 文件列表
 * @type {[type]}
 */
export const getFaqFiles = params =>
  _apiGET(API_MGMT_ROOT, `/faq_files`, params, Schemas.faqFilesSchemaArray)

/**
 * 添加FAQ文件 到文件列表
 * @type {[type]}
 */
export const createFaqFile = params =>
  _apiPOST(API_MGMT_ROOT, `/faq_files`, params, Schemas.faqFilesSchema)

/**
 * 门禁管理
 */
export const createLock = params =>
  _apiPOST(API_MGMT_ROOT, `/locks`, params, Schemas.locksSchema)

export const getLocks = params =>
  _apiGET(API_MGMT_ROOT, `/locks`, params, Schemas.locksSchemaArray)

export const updateLock = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/locks/${id}`, params, Schemas.locksSchema)

export const getLockEvents = params =>
  _apiGET(API_MGMT_ROOT, `/lock_events`, params)

export const deleteLock = id =>
  _apiDELETE(API_MGMT_ROOT, `/locks/${id}`, null, Schemas.locksSchema)

export const getLockPermissions = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/lock_permissions`,
    params,
    Schemas.lockPermissionsSchemaArray
  )

export const createLockPermissions = (id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/lock_permissions/batch_create`,
    params,
    Schemas.lockPermissionsSchemaArray
  )

export const deleteLockPermissions = (id, params) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/lock_permissions/batch_destroy`,
    params,
    Schemas.lockPermissionsSchemaArray
  )

export const updateLockPermissions = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/lock_permissions/batch_update`,
    params,
    Schemas.lockPermissionsSchemaArray
  )

export const getLockGenerateQrcode = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/locks/${id}/qrcode`, params)

export const updateMemberIcCard = (location_id, user_id) =>
  _apiPUT(API_MGMT_ROOT, `/locks/delete_user_ic_card`, {
    user_id: user_id,
    location_id: location_id
  })

// 开门热点统计
export const getLockHotspot = params =>
  _apiGET(API_MGMT_ROOT, `/lock_events/hotspot`, params)

// 联网开锁
export const createUnlockNetworking = (location_id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/locations/${location_id}/locks/unlock_by_networking`,
    params
  )

/**
 * 服务商管理
 */
export const getServiceProviders = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/service_providers`,
    params,
    Schemas.serviceProvidersSchemaArray
  )

export const createServiceProviders = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/service_providers`,
    params,
    Schemas.serviceProvidersSchema
  )

export const updateServiceProviders = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/service_providers/${id}`,
    params,
    Schemas.serviceProvidersSchema
  )

export const deleteServiceProviders = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/service_providers/${id}`,
    null,
    Schemas.serviceProvidersSchema
  )

export const getServiceProviderDetails = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/service_providers/${id}`,
    null,
    Schemas.serviceProvidersSchema
  )

/**
 * 设置启动服务商的排序位置
 * @method putServiceProvidersSetPositions
 */
export const putServiceProvidersSetPositions = params =>
  _apiPUT(
    API_MGMT_ROOT,
    `/service_providers/set_positions`,
    params,
    Schemas.serviceProvidersSchemaArray
  )

export const getProvidersServices = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/service_providers/${id}/services`,
    null,
    Schemas.servicesSchemaArray
  )

// 服务分类
export const getServiceCategories = () =>
  _apiGET(
    API_MGMT_ROOT,
    `/service_categories`,
    null,
    Schemas.serviceCategoriesSchemaArray
  )

// 服务请求订单
export const getServiceRequests = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/service_requests`,
    params,
    Schemas.serviceRequestsSchemaArray
  )

// 修改订单跟进时间
export const getModifyRemindAt = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/service_requests/${id}/modify_remind_at`,
    params,
    Schemas.serviceRequestsSchema
  )

// 切换服务商置顶状态
export const putPrioritize = id =>
  _apiPUT(
    API_MGMT_ROOT,
    `/service_providers/${id}/prioritize`,
    null,
    Schemas.serviceProvidersSchema
  )

// 切换服务商is_enabled状态
export const putServiceProviderEnabled = id =>
  _apiPUT(
    API_MGMT_ROOT,
    `/service_providers/${id}/switch`,
    null,
    Schemas.serviceProvidersSchema
  )

// 切换服务 is_enabled状态
export const putServiceEnabled = (id, service_id) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/service_providers/${id}/services/${service_id}/switch`,
    null,
    Schemas.servicesSchema
  )

// 查看订单评论
export const getServiceRequestComments = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/service_requests/${id}/service_request_comments`,
    null,
    Schemas.serviceRequestCommentsSchema
  )

// 查看订单详情
export const getServiceRequestDetail = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/service_requests/${id}`,
    null,
    Schemas.serviceRequestsSchema
  )

// 管理员对服务申请发表评论
export const createServiceRequestComments = (id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/service_requests/${id}/service_request_comments`,
    params,
    Schemas.serviceRequestsSchema
  )

// 改变订单状态API
export const putChangePrioritizeStatus = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/service_requests/${id}/change_status`,
    params,
    Schemas.serviceRequestsSchema
  )

// 设置beeCloudSetting
export const beeCloudSetting = params =>
  _apiPUT(API_MGMT_ROOT, `/space/beecloud_setting`, params, null)

/**
 * [为服务商创建服务]
 * @method createService
 * @param  {[int]}      id     [服务商ID]
 * @param  {[object]}   params [参数]
 */
export const createService = (id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/service_providers/${id}/services`,
    params,
    Schemas.servicesSchema
  )

/**
 * [修改服务商的服务]
 * @method updateService
 * @param  {[int]}      id         [服务商ID]
 * @param  {[int]}      service_id [服务ID]
 * @param  {[object]}      params     [参数]
 */
export const updateService = (id, service_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/service_providers/${id}/services/${service_id}`,
    params,
    Schemas.servicesSchema
  )

/**
 * 删除服务商服务
 * @method deleteService
 * @param  {[int]}      id         [服务商id]
 * @param  {[int]}      service_id [服务id]
 */
export const deleteService = (id, service_id) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/service_providers/${id}/services/${service_id}`,
    null,
    Schemas.servicesSchema
  )

/**
 * 修改BeeCloud状态
 * @method updateBeeCloudSettingState
 */
export const updateBeeCloudSettingState = params =>
  _apiPUT(API_MGMT_ROOT, `/space/update_beecloud_setting_state`, params, null)

/**
 * 获取公告列表
 * @method getAnnouncements
 */
export const getAnnouncements = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/announcements`,
    params,
    Schemas.announcementsSchemaArray
  )

/**
 * 创建公告
 * @method createAnnouncements
 */
export const createAnnouncements = params =>
  _apiPOST(API_MGMT_ROOT, `/announcements`, params, Schemas.announcementsSchema)

/**
 * 修改公告
 * @method updateAnnouncements
 * @param  {[type]}            id     [公告id]
 */
export const updateAnnouncements = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/announcements/${id}`,
    params,
    Schemas.announcementsSchema
  )

/**
 * 公告详情
 * @method getAnnouncementsDetail
 * @param  {[int]}               id [公告ID]
 */
export const getAnnouncementsDetail = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/announcements/${id}`,
    null,
    Schemas.announcementsSchema
  )

/**
 * 删除公告
 * @method deleteAnnouncements
 * @param  {[int]}            id [公告ID]
 */
export const deleteAnnouncements = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/announcements/${id}`,
    null,
    Schemas.announcementsSchema
  )

// PUT /managements/v2/announcements/sort 公告排序
export const sortAnnouncements = (params) => _apiPUT( API_MGMT_ROOT, '/announcements/sort', params )
/**
 * 创建代办事项
 * @method createLeveOrEnterOptions
 */
export const createLeveOrEnterOptions = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/leave_or_enter_options`,
    params,
    Schemas.leaveOrEnterOptionsSchema
  )

/**
 * 修改待办事项
 * @method updateLeveOrEnterOptions
 * @param  {[int]}                 id     [待办事项ID]
 */
export const updateLeveOrEnterOptions = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/leave_or_enter_options/${id}`,
    params,
    Schemas.leaveOrEnterOptionsSchema
  )

/**
 * 获取公司待办事项清单
 * @method getOrganizationOptions
 * @param  {[int]}               id     [分店ID]
 * @param  {[int]}               org_id [公司ID]
 */
export const getOrganizationOptions = (id, org_id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/organizations/${org_id}/organization_options`,
    params,
    Schemas.leaveOrEnterOptionsSchemaArray
  )

/**
 * 切换待办事项勾选状态
 * @method updateOrganizationOptions
 * @param  {[Int]}                  id        [分店ID]
 * @param  {[Int]}                  org_id    [公司ID]
 * @param  {[Int]}                  option_id [待办事项ID]
 */
export const updateOrganizationOptions = (org_id, option_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/organizations/${org_id}/organization_options/${option_id}/check`,
    params,
    Schemas.leaveOrEnterOptionsSchema
  )

/**
 * 删除待办事项
 * @method deleteLeaveOrEnterOptions
 * @param  {[int]}                  id [待办事项ID]
 */
export const deleteLeaveOrEnterOptions = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/leave_or_enter_options/${id}`,
    null,
    Schemas.leaveOrEnterOptionsSchema
  )

/**
 * 入驻 / 退租 待办事项
 * @method getLeveOrEnterOptions
 * @param  {[object]}              params [{type : '待办事项类型(archive/enter)'}]
 */
export const getLeveOrEnterOptions = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/leave_or_enter_options`,
    params,
    Schemas.leaveOrEnterOptionsSchemaArray
  )

// ----- APP 应用模块
export const getAppModules = params =>
  _apiGET(API_MGMT_ROOT, `/app_modules`, params, Schemas.appModulesSchemaArray)

/**
 * 创建自定义模块
 * @method createAppModule
 */
export const createAppModule = params =>
  _apiPOST(API_MGMT_ROOT, `/app_modules`, params, Schemas.appModulesSchema)

/**
 * 修改自定义模块
 * @method updateAppModule
 * @param  {[int]}        id     [模块ID]
 */
export const updateAppModule = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/app_modules/${id}`, params, Schemas.appModulesSchema)

/**
 * 模块详情
 * @method getAppModulesDetail
 */
export const getAppModulesDetail = id =>
  _apiGET(API_MGMT_ROOT, `/app_modules/${id}`, null, Schemas.appModulesSchema)

/**
 * 删除模块
 * @method deleteAppModule
 */
export const deleteAppModule = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/app_modules/${id}`,
    null,
    Schemas.appModulesSchema
  )

// --------  统计报表
/**
 * Crm客户来源统计
 * @method getSourceStatistic
 * @param  {[string, stringDate, stringDate]}           params [location_ids, from, to]
 * @return {[type]}                  [description]
 */
export const getSourceStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/crms/source_statistic`, params, null)

/**
 * Crm 客户个数统计
 * @method getCustomerStatistic
 */
export const getCustomerStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/crms/customer_statistic`, params, null)

/**
 * Crm 客户负责人统计数据
 * @method getCustomerWithLeaderStatistic
 */
export const getCustomerWithLeaderStatistic = () =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/crms/customer_with_leader_statistic`,
    null,
    null
  )

/**
 * Crm 分店项目比较
 * @method getCustomerWithLocationStatistic
 * @return {[type]}                         [description]
 */
export const getCustomerWithLocationStatistic = () =>
  _apiGET(API_MGMT_ROOT, `/statistic/crms/customer_with_location_statistic`)

/**
 * Crm 流失率
 * @method getCustomerFailedStateStatistic
 */
export const getCustomerFailedStateStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/crms/customer_failed_state_statistic`,
    params
  )

/**
 * 客户跟进率统计
 * @method getFollowUpStatistic
 */
export const getFollowUpStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/crms/follow_up_statistic`, params)

/**
 * Crm 各状态客户的分布
 * @method getCustomerStateStatistic
 * @param  {[type]}                  params [description]
 */
export const getCustomerStateStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/crms/customer_state_statistic`)

/**
 * Crm 客户流失原因的分布
 * @method getCustomerStateStatistic
 * @param  {[type]}                  params [description]
 */
export const getCustomerFailedReasonStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/crms/customer_failed_reason_statistic`)

/**
 * Crm 客户的漏斗图
 * @method getCustomerStateHistoryStatistic
 * @param  {[type]}                         params [description]
 * @return {[type]}                                [description]
 */
export const getCustomerStateHistoryStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/crms/customer_state_history_statistic`,
    params
  )

/**
 * 获取 报修数量统计
 * @method getPersonRepairTaskStatistic
 * @param  {[id, string, string]}  params [location_id、start_date、end_date]
 */
export const getRepairTaskStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/repairs/repair_task_count_statistic`,
    params
  )

/**
 * 获取报修每日新增及其活跃任务统计
 * @method getIncrementActiveStatistic
 * @param  {[int, string, string]} params [location_id, start_date, end_date]
 */
export const getIncrementActiveStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/repairs/increment_and_active_statistic`,
    params
  )

/**
 * 报修任务分类统计
 * @method getSubtypeTaskStatistic
 * @param  {[int, string, string]}                params [location_id, start_date, end_date]
 */
export const getSubtypeTaskStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/repairs/subtype_task_statistic`, params)

/**
 * 报修人物按照重要性统计
 * @method getPriorityTaskStatistic
 * @param  {[int, string, string]}                params [location_id, start_date, end_date]
 */
export const getPriorityTaskStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/repairs/priority_task_statistic`, params)

/**
 * 总预定数和预订时间长度按天变化
 * @method getReserveCountTrendStatistic
 */
export const getReserveCountTrendStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/metting_rooms/reserve_count_trend_statistic`,
    params
  )

/**
 * 会议室使用率统计
 * @method getUsageRateStatistic
 */
export const getUsageRateStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/metting_rooms/usage_rate_statistic`,
    params
  )

/**
 * 按照小时预订时间段分布
 * @method geReserveCountByHourStatistic
 */
export const geReserveCountByHourStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/metting_rooms/reserve_count_by_hour_statistic`,
    params
  )

/**
 * 预定会议时长分布饼图
 * @method getReserveTimeLengthStatistic
 */
export const getReserveTimeLengthStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/metting_rooms/reserve_time_length_statistic`,
    params
  )

/**
 * 分店每个会议室的使用率列表
 * @method getEachUsageRateStatistic
 */
export const getEachUsageRateStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/metting_rooms/each_usage_rate_statistic`,
    params
  )

/**
 * 入驻统计数据
 * @method getEnterStatistic
 */
export const getEnterStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/desks/enter_statistic`, params)

/**
 * 工位收入数据统计
 * @method getReceiveStatistic
 */
export const getReceiveStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/desks/receive_statistic`, params)

/**
 * 公司总数及会员总数统计
 * @method getOrgAndMemberCountStatistic
 */
export const getOrgAndMemberCountStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/organizations/organization_and_member_count_statistic`,
    params
  )

/**
 * 合同周期统计
 * @method getSubscriptionCycleStatistic
 */
export const getSubscriptionCycleStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/organizations/subscription_cycle_statistic`,
    params
  )

/**
 * 公司人数数据统计
 * @method getOrgMemberStatistic
 */
export const getOrgMemberStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/organizations/organization_member_statistic`,
    params
  )

/**
 * Crm 赢单率 流单率统计
 * @method getCustomerWinOrFailedStateStatistic
 */
export const getCustomerWinOrFailedStateStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/crms/customer_win_or_failed_state_statistic`,
    params
  )

/**
 * Crm 客户赢单输单来源统计
 * @method getCustomerWinOrFailedSourceStatistic
 */
export const getCustomerWinOrFailedSourceStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/crms/win_or_failed_source_statistic`,
    params
  )

/**
 * 开门记录统计
 */
export const getLockRecordStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/locks/lock_event_statistic`, params)

/**
 * 每天独立开门人数统计
 */
export const getIndependentLockRecordStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/locks/uniq_lock_event_statistic`, params)

/**
 * 空间保证金统计
 */
export const getInvoicesDepositStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/invoices/deposit_statistics`, params, null)

/**
 * 空间月度应收保证金统计
 */
export const getInvoicesMonthlyReceivableDepositStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/invoices/monthly_receivable_deposit_statistics`,
    params,
    null
  )

/**
 * 保证金Line_item报表导出
 */
export const exportDepositLineItems = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/deposit_line_items_report`, params, null)

//报表中心
/**
 * 报表列表
 */
export const getReports = params =>
  _apiGET(API_MGMT_ROOT, `/reports`, params, Schemas.reportListSchemaArray)

/**
 * 导出客户信息报表
 */
export const createReports = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/opportunity_report`, params)

/**
 * 导出保修信息
 */
export const tasksReports = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/tasks_report`, params)

// 导出账单
// 最多导出3个月账单，时间跨度不能过大, 涉及大量数据导出问题
export const salesInvoiceReport = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/sales_invoice_report`, params)

// 导出租赁情况账单
// 最多导出3个月账单，时间跨度不能过大, 涉及大量数据导出问题
export const salesAreaInvoiceReport = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/sales_area_invoice_report`, params)

// 导出会员
export const memberReport = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/organization_members_report`, params)

// 导出分区
export const areaReport = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/desks_report`, params)

// 导出门禁权限列表
export const lockPermissionReport = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/lock_permissions_report`, params)

// 导出开门记录
export const lockEventsReport = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/lock_events_report`, params)

// 导出租赁情况合同
export const salesSubscriptionsReport = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/sales_subscriptions_report`, params)

/**
 * 导出公司
 */
export const postReportsOrganization = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/organization_report`, params)

/**
 * 会议室使用情况报表
 */
export const postMeetingRoomReport = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/meeting_room_report`, params)

/**
 * 导出商城订单信息
 */
export const exportProductOrders = params =>
  _apiPOST(API_MGMT_ROOT, '/reports/shop_invoice_report', params)

/**
 * 导出账单支付信息
 */
export const exportInvoicePayments = params =>
  _apiPOST(API_MGMT_ROOT, '/reports/sales_payments_report', params)

// 房间租赁情况导出
export const getOfficeRentingReport = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/office_renting_report`, params, null)

// 发票情况导出
export const getInvoicingApplicationsReport = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/reports/invoicing_applications_report`,
    params,
    null
  )

/**
 * 广播消息
 */
export const createBroadcastMessages = params =>
  _apiPOST(API_MGMT_ROOT, `/broadcast_messages`, params)

/**
 * 获取广播消息列表
 */
export const getBroadcastMessages = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/broadcast_messages`,
    params,
    Schemas.messageListSchemaArray
  )

/**
 * 创建平面图
 * @method createFloorPlan
 * @param  {[type]}        id     [location_id]
 */
export const createFloorPlan = (id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/locations/${id}/floorplans`,
    params,
    Schemas.floorPlansSchema
  )

export const getFloorPlanInfo = (id, loc_id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/locations/${loc_id}/floorplans/${id}`,
    params,
    Schemas.floorPlansSchema
  )

export const putFloorPlans = (id, loc_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/locations/${loc_id}/floorplans/${id}`,
    params,
    Schemas.floorPlansSchema
  )

export const deleteFloorPlans = (id, loc_id, params) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/locations/${loc_id}/floorplans/${id}`,
    params,
    Schemas.floorPlansSchema
  )

/**
 * 获取平面图列表
 * @method getFloorPlans
 * @param  {[number]}      id [loc_id]
 */
export const getFloorPlans = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/locations/${id}/floorplans`,
    null,
    Schemas.floorPlansSchemaArray
  )

/**
 * 合同模板部分
 */

// 获取合同模板列表
export const getSubScriptionTempletes = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/subscription_templetes`,
    params,
    Schemas.subscriptionTempletesSchemaArray
  )

// 创建合同模板列表
export const postSubscriptionTempletes = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/subscription_templetes`,
    params,
    Schemas.subscriptionTempletesSchema
  )

// 删除合同模板
export const deleteSubscriptionTempletes = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/subscription_templetes/${id}`,
    null,
    Schemas.subscriptionTempletesSchema
  )

// 获取合同模板详情
export const getSubscriptionTempleteInfo = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/subscription_templetes/${id}`,
    null,
    Schemas.subscriptionTempletesSchema
  )

// 修改模板
export const putSubscriptionTemplete = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/subscription_templetes/${id}`,
    params,
    Schemas.subscriptionTempletesSchema
  )

// 有效的模板占位符
export const getValidPlaceholder = () =>
  _apiGET(API_MGMT_ROOT, `/subscription_templetes/valid_placeholder`, null)

// 创建空间特殊占位符
export const createSubscriptionSpecialPlaceholder = params =>
  _apiPOST(API_MGMT_ROOT, `/subscription_templetes/special_placeholder`, params)

// 删除空间特殊占位符
export const deleteSubscriptionSpecialPlaceholder = params =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/subscription_templetes/delete_special_placeholder`,
    params
  )

// 根据模板导出合同文件
export const exportTemplete = (id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/subscription_templetes/${id}/export_templete`,
    params
  )

// 预览末班生成的合同文件
export const previewTemplete = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/subscription_templetes/${id}/export`, params)

//验证合同模板变量是否填写正确
export const postValidateDocxMailMerge = template_url =>
  _apiPOST(
    API_MGMT_ROOT,
    `/subscription_templetes/validate_docx_mailmerge`,
    { template_url: template_url },
    null
  )

// 操作记录列表
export const getAuditLogs = params =>
  _apiGET(API_MGMT_ROOT, `/audit_logs`, params, Schemas.auditLogsSchemaArray)

// 分店楼层信息
export const getFloorInfos = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/locations/${id}/floor_infos`,
    null,
    Schemas.FloorInfosSchemaArray
  )

// 创建楼层
export const postFloorInfos = (id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/locations/${id}/floor_infos`,
    params,
    Schemas.FloorInfosSchema
  )

// 删除楼层
export const deleteFloorInfos = (id, loc_id) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/locations/${loc_id}/floor_infos/${id}`,
    null,
    Schemas.FloorInfosSchema
  )

// 楼层详情
export const getFloorDetail = (id, loc_id) =>
  _apiGET(
    API_MGMT_ROOT,
    `/locations/${loc_id}/floor_infos/${id}`,
    null,
    Schemas.FloorInfosSchema
  )

// 修改楼层
export const putFloorInfos = (id, loc_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/locations/${loc_id}/floor_infos/${id}`,
    params,
    Schemas.FloorInfosSchema
  )

// 分店大楼列表
export const getBuildingInfos = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/locations/${id}/building_infos`,
    null,
    Schemas.BuildingInfosSchemaArray
  )

// 创建分店大楼
export const postBuildingInfos = (id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/locations/${id}/building_infos`,
    params,
    Schemas.BuildingInfosSchema
  )

// 删除分店大楼
export const deleteBuildingInfos = (id, loc_id) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/locations/${loc_id}/building_infos/${id}`,
    null,
    Schemas.BuildingInfosSchema
  )

// 分店大楼详情
export const getBuildingDetail = (id, loc_id) =>
  _apiGET(
    API_MGMT_ROOT,
    `/locations/${loc_id}/building_infos/${id}`,
    null,
    Schemas.BuildingInfosSchema
  )

// 修改分店大楼信息
export const putBuildingInfos = (id, loc_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/locations/${loc_id}/building_infos/${id}`,
    params,
    Schemas.BuildingInfosSchema
  )

/*
    Visit 访客相关 api
 */

// 获取访客设置(全部字段和值)
export const getVisitorSettings = () =>
  _apiGET(API_MGMT_ROOT, `/visitors/settings`, null)

// 修改访客设置
export const updateVisitorSettings = params =>
  _apiPUT(API_MGMT_ROOT, `/visitors/settings`, params)

// Visitor history
export const getVisitorHistory = params =>
  _apiGET(API_MGMT_ROOT, `/visitors/stats/history`, params)

// Visitor average
export const getVisitorAverage = params =>
  _apiGET(API_MGMT_ROOT, `/visitors/stats/average`, params)

// 访问列表
export const getVisitors = params =>
  _apiGET(API_MGMT_ROOT, `/visitors/visitors`, params)

// 访客详情
export const getVisitorById = id =>
  _apiGET(API_MGMT_ROOT, `/visitors/visitors/${id}`, null)

// 创建访客
export const createVisitor = params =>
  _apiPOST(API_MGMT_ROOT, `/visitors/visitors`, params)

// 修改访客
export const updateVisitor = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/visitors/visitors/${id}`, params)

// 删除访客
export const deleteVisitor = id =>
  _apiDELETE(API_MGMT_ROOT, `/visitors/visitors/${id}`, null)

// 访客签到
export const visitorSignIn = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/visitors/visitors/${id}/signin`, params)

// 访客签退
export const visitorSignOut = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/visitors/visitors/${id}/signout`, params)

// 访客审核通过
export const visitorApprove = (id, params) =>
  _apiPOST(API_MGMT_ROOT, `/visitors/visitors/${id}/approve`, params)

// 访客审核拒绝
export const visitorDisapprove = (id, params) =>
  _apiPOST(API_MGMT_ROOT, `/visitors/visitors/${id}/disapprove`, params)

//访客审核批量通过
export const visitorBatchApprove = params =>
  _apiPOST(API_MGMT_ROOT, `/visitors/visitors/batch_approve`, params)

//访客审核批量拒绝
export const visitorBatchDispprove = params =>
  _apiPOST(API_MGMT_ROOT, `/visitors/visitors/batch_disapprove`, params)

// most_visited Visitors
export const getMostVisitedVisitors = params =>
  _apiGET(API_MGMT_ROOT, `/visitors/visitors/most_visited`, params)

//POST /managements/v2/visitors/visitor_blacklists
// 访客加入黑命单
export const postVisitorBlacklists = params =>
  _apiPOST(API_MGMT_ROOT, `/visitors/visitor_blacklists`, params)

//DELETE /managements/v2/visitors/visitor_blacklists/{id}
// 访客加入黑命单
export const delVisitorBlacklists = id =>
  _apiDELETE(API_MGMT_ROOT, `/visitors/visitor_blacklists/${id}`, null)

//GET /managements/v2/visitors/visitor_blacklistsList visitor blacklists
export const getVisitorBlacklists = params =>
  _apiGET(API_MGMT_ROOT, `/visitors/visitor_blacklists/`, params)

// 会员制dashboard
export const getMembershipDashboard = () =>
  _apiGET(API_MGMT_ROOT, `/membership/dashboard`, null, null)

// 会员等级列表
export const getMemberships = params =>
  _apiGET(API_MGMT_ROOT, `/memberships`, params, Schemas.membershipsSchemaArray)

// 创建会员等级
export const createMemberships = params =>
  _apiPOST(API_MGMT_ROOT, `/memberships`, params, Schemas.membershipsSchema)

// 修改会员等级
export const putMemberships = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/memberships/${id}`,
    params,
    Schemas.membershipsSchema
  )

// 修改是否可见
export const updateVisbilityMemberships = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/memberships/${id}/update_visibility`,
    params,
    Schemas.membershipsSchema
  )

// 把历史版本或draft变成当前版本
export const updateBecomeCurrentMemberships = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/memberships/${id}/become_current`,
    params,
    Schemas.membershipsSchema
  )

// 会员等级详情
export const getMembershipsInfo = id =>
  _apiGET(API_MGMT_ROOT, `/memberships/${id}`, null, Schemas.membershipsSchema)

// 删除会员等级
export const deleteMemberships = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/memberships/${id}`,
    null,
    Schemas.membershipsSchema
  )

// 会员等级排序
export const putSortMemberships = params =>
  _apiPUT(API_MGMT_ROOT, `/memberships/sort`, params)

// 会员等级购买列表
export const getMembershipsOrders = (id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/memberships/${id}/orders`,
    params,
    Schemas.membershipsOrdersSchemaArray
  )

// 会员制资源列表
export const getMembershipResources = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/membership_resources`,
    params,
    Schemas.membershipResourcesSchemaArray
  )

// 创建会员制资源
export const postMembershipResources = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/membership_resources`,
    params,
    Schemas.membershipResourcesSchema
  )

// 修改会员制资源
export const putMembershipResources = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/membership_resources/${id}`,
    params,
    Schemas.membershipResourcesSchema
  )

// 获取会员制资源详情
export const getMemberShipResourceInfo = (id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/membership_resources/${id}`,
    params,
    Schemas.membershipResourcesSchema
  )

// 删除会员制资源详情
export const deleteMemberShipsResource = (id, params) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/membership_resources/${id}`,
    params,
    Schemas.membershipResourcesSchema
  )

// 会员资源购买列表
export const getMemberShipResourcesOrders = (id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/membership_resources/${id}/orders`,
    params,
    Schemas.membershipResourcesOrdersSchemaArray
  )

// 查询用户资源余额
export const getMembershipResourcesCredits = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/membership_resources/user_credits`,
    { user_id: id },
    null
  )

// 查询用户会员等级
export const getMembershipUserMembership = id =>
  _apiGET(API_MGMT_ROOT, `/memberships/user_membership`, {
    sales_customer_id: id
  })

// 手动给用户添加资源
export const postMembershipResourcesGrant = (id, params) =>
  _apiPOST(API_MGMT_ROOT, `/membership_resources/${id}/grant`, params, null)

// 直接给用户添加积分
export const postMembersPointPlansGrant = params =>
  _apiPOST(API_MGMT_ROOT, `/point_plans/grant`, params, null)

// 获取积分列表
export const getPointPlans = params =>
  _apiGET(API_MGMT_ROOT, `/point_plans`, params, Schemas.pointPlansSchemaArray)

// 创建积分计划
export const postPointPlans = params =>
  _apiPOST(API_MGMT_ROOT, `/point_plans`, params, Schemas.pointPlansSchema)

// 修改积分计划
export const putPointPlans = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/point_plans/${id}`, params, Schemas.pointPlansSchema)

// 积分计划详情
export const getPointPlansInfo = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/point_plans/${id}`, params, Schemas.pointPlansSchema)

// 删除积分计划
export const deletePointPlans = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/point_plans/${id}`,
    null,
    Schemas.pointPlansSchema
  )

// 积分购买列表
export const getPointPlansOrders = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/point_plans/orders`,
    params,
    Schemas.pointPlansOrderSchemaArray
  )

// 积分变动情况列表
export const getPointPlansLedgers = params =>
  _apiGET(API_MGMT_ROOT, `/point_plans/ledgers`, params, null)

// 主动赠送积分
export const postGivingPoint = params =>
  _apiPOST(API_MGMT_ROOT, `/point_plans/giving_point`, params, null)

// 收回赠送积分
export const postRevokePoint = params =>
  _apiPOST(API_MGMT_ROOT, `/point_plans/revoke_point`, params, null)

// 获取微信设置
export const getWechatSettings = params =>
  _apiGET(API_MGMT_ROOT, `/wechat_setting`, params, null)

// 创建微信支付相关设置
export const createWechatSettings = params =>
  _apiPOST(API_MGMT_ROOT, `/wechat_setting`, params, null)

// 修改微信相关设置
export const putWechatSettings = params =>
  _apiPUT(API_MGMT_ROOT, `/wechat_setting`, params, null)

// --- 商城
export const getShops = params =>
  _apiGET(API_MGMT_ROOT, `/shops`, params, Schemas.shopSchemaArray)

export const postShops = params =>
  _apiPOST(API_MGMT_ROOT, `/shops`, params, Schemas.shopSchema)

export const putShops = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/shops/${id}`, params, Schemas.shopSchema)

export const getShopDetail = id =>
  _apiGET(API_MGMT_ROOT, `/shops/${id}`, null, Schemas.shopSchema)

export const deleteShop = id =>
  _apiDELETE(API_MGMT_ROOT, `/shops/${id}`, null, Schemas.shopSchema)

// 商品分类
export const getProductCategories = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/product_categories`,
    params,
    Schemas.productCategorieSchemaArray
  )

export const postProductCategories = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/product_categories`,
    params,
    Schemas.productCategorieSchema
  )

export const putProductCategories = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/product_categories/${id}`,
    params,
    Schemas.productCategorieSchema
  )

export const deleteProductCategories = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/product_categories/${id}`,
    null,
    Schemas.productCategorieSchema
  )

// 商品
export const getSalesProducts = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/sales_products`,
    params,
    Schemas.salesProductSchemaArray
  )

export const postSalesProducts = params =>
  _apiPOST(API_MGMT_ROOT, `/sales_products`, params, Schemas.salesProductSchema)

export const putSalesProducts = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/sales_products/${id}`,
    params,
    Schemas.salesProductSchema
  )

export const getSalesProductDetail = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/sales_products/${id}`,
    null,
    Schemas.salesProductSchema
  )

export const deleteSaleProducts = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/sales_products/${id}`,
    null,
    Schemas.salesProductSchema
  )

// 商城库存
export const getProductInventories = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/product_inventories`,
    params,
    Schemas.productInventorieSchemaArray
  )

export const postProductInventories = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/product_inventories`,
    params,
    Schemas.productInventorieSchema
  )

export const putProductInventories = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/product_inventories/${id}`,
    params,
    Schemas.productInventorieSchema
  )

export const addProductInventories = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/product_inventories/${id}/increase`,
    params,
    Schemas.productInventorieSchema
  )

export const getProductInventoryDetail = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/product_inventories/${id}`,
    null,
    Schemas.productInventorieSchema
  )

// 商城统计列表
export const getSalesStatistics = params =>
  _apiGET(API_MGMT_ROOT, `/shops/sales_statistics`, params)

/*
  自定义动态字段
*/
export const getDynamicAttributes = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/dynamic_attributes`,
    params,
    Schemas.dynamicAttributesSchemaArray
  )

export const createDynamicAttributes = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/dynamic_attributes`,
    params,
    Schemas.dynamicAttributesSchema
  )

export const deleteDynamicAttributes = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/dynamic_attributes/${id}`,
    null,
    Schemas.dynamicAttributesSchema
  )

// 合同审批人
export const getContractApprovers = id =>
  _apiGET(
    API_MGMT_ROOT,
    `/space/location_contract_approvers_setting?location_id=${id}`
  )

export const putContractApprovers = params =>
  _apiPUT(
    API_MGMT_ROOT,
    `/space/update_location_contract_approvers_setting`,
    params
  )

/**
 * 活动模块
 */

// 活动列表
export const getActivities = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/activities/activities`,
    params,
    Schemas.activitiesSchemaArray
  )

// 创建活动
export const createActivities = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/activities/activities`,
    params,
    Schemas.activitiesSchema
  )

// 编辑活动
export const updateActivities = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/activities/activities/${id}`,
    params,
    Schemas.activitiesSchema
  )

// 删除活动
export const deleteActivity = (id, params) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/activities/activities/${id}`,
    params,
    Schemas.activitiesSchema
  )

// 活动详情
export const getActivitiesInfo = async (id) =>
  _apiGET(
    API_MGMT_ROOT,
    `/activities/activities/${id}`,
    null,
    Schemas.activitiesSchema
  )

// 活动报名列表
export const getActivitiesOrders = (id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/activities/activities/${id}/orders`,
    params,
    Schemas.activitiesOrdersSchemaArray
  )

// 活动评论列表
export const getActivitiesComments = (id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/activities/activities/${id}/comments`,
    params,
    Schemas.activitiesCommentsSchemaArray
  )

// 活动评论审核
export const approveActivityComment = (id, comment_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/activities/activities/${id}/comments/${comment_id}/approve`,
    params,
    Schemas.activitiesCommentsSchema
  )

// 删除活动评论
export const deleteActivityComment = (id, comment_id, params) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/activities/activities/${id}/comments/${comment_id}`,
    params,
    Schemas.activitiesCommentsSchema
  )

// 活动热门标签
export const getActivitiesHotTags = params =>
  _apiGET(API_MGMT_ROOT, `/activities/activities/hot_tags`, params, null)

/**
 * 新闻资讯模块
 */

// 资讯列表
export const getNews = params =>
  _apiGET(API_MGMT_ROOT, `/news`, params, Schemas.newsSchemaArray)

// 创建资讯
export const createNews = params =>
  _apiPOST(API_MGMT_ROOT, `/news`, params, Schemas.newsSchema)

// 编辑资讯
export const updateNews = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/news/${id}`, params, Schemas.newsSchema)

// 资讯详情
export const getNewsInfo = id =>
  _apiGET(API_MGMT_ROOT, `/news/${id}`, null, Schemas.newsSchema)

// 删除资讯
export const deleteNews = id =>
  _apiDELETE(API_MGMT_ROOT, `/news/${id}`, null, Schemas.newsSchema)

/**
 * 分店打印机管理
 */
// 打印机列表
export const getPrinters = params =>
  _apiGET(API_MGMT_ROOT, `/printers`, params, Schemas.printersSchemaArray)

// 创建打印机
export const createPrinter = params =>
  _apiPOST(API_MGMT_ROOT, `/printers`, params, Schemas.printersSchema)

// 编辑打印机
export const updatePrinter = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/printers/${id}`, params, Schemas.printersSchema)

// 删除打印机
export const deletePrinter = id =>
  _apiDELETE(API_MGMT_ROOT, `/printers/${id}`, null, Schemas.printersSchema)

/**
 * 自定义模板相关接口
 */
// 模板列表
export const getTemplates = params =>
  _apiGET(API_MGMT_ROOT, `/templates`, params, null)

// 创建模板
export const createTemplate = params =>
  _apiPOST(API_MGMT_ROOT, `/templates`, params, null)

// 编辑模板
export const updateTemplate = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/templates/${id}`, params, null)

// 删除模板
export const deleteTemplate = id =>
  _apiDELETE(API_MGMT_ROOT, `/templates/${id}`, null, null)

// 展示模板
export const showTemplate = id =>
  _apiGET(API_MGMT_ROOT, `/templates/${id}`, null, null)

// 预设模板类型列表
export const getTemplateKeys = params =>
  _apiGET(API_MGMT_ROOT, `/templates/template_keys`, params, null)

// 模板保留字列表
export const getTemplateKeywordsOfKey = params =>
  _apiGET(API_MGMT_ROOT, `/templates/template_keywords`, params, null)

export const getLocationSettings = (id, inherit) =>
  _apiGET(
    API_MGMT_ROOT,
    `/settings`,
    { target_type: 'Location', target_id: id, inherit },
    null
  )

// export const getLocationGroupSettings = (id, inherit) => _apiGET(API_MGMT_ROOT, `/settings`, { target_type: 'LocationGroup', target_id: id, inherit }, null)

// 根据地址查询经纬度
export const getReverseGeocode = params =>
  _apiPOST(API_MGMT_ROOT, `/reverse_geocode`, params, null)

/**
 * Pad 平板相关 api
 */

// 通过设备 code 创建一个平板设备
export const createPadWithCode = params =>
  _apiPOST(API_MGMT_ROOT, `/pads/validate_code`, params, null)

// 平板列表
export const getPads = params => _apiGET(API_MGMT_ROOT, `/pads`, params, null)

// 修改平板信息
export const updatePad = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/pads/${id}`, params, null)

// 平板详情
export const getPadDetail = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/pads/${id}`, params, null)

// 删除平板
export const deletePad = id =>
  _apiDELETE(API_MGMT_ROOT, `/pads/${id}`, null, null)

/**
 * 社群帖子 相关 api
 */

// 帖子列表
export const getPosts = params => _apiGET(API_MGMT_ROOT, `/posts`, params, null)

// 帖子详情
export const getPostDetail = id =>
  _apiGET(API_MGMT_ROOT, `/posts/${id}`, null, null)

// 删除帖子
export const deletePost = id =>
  _apiDELETE(API_MGMT_ROOT, `/posts/${id}`, null, null)

// 帖子评论列表
export const getPostComments = (post_id, params) =>
  _apiGET(API_MGMT_ROOT, `/posts/${post_id}/post_comments`, params, null)

// 帖子评论详情
export const getPostCommentDetail = (post_id, id) =>
  _apiGET(API_MGMT_ROOT, `/posts/${post_id}/post_comments/${id}`, null, null)

// 删除帖子评论
export const deletePostComment = (post_id, id) =>
  _apiDELETE(API_MGMT_ROOT, `/posts/${post_id}/post_comments/${id}`, null, null)

/**
 * 消息通知 相关 api
 */

// 分店动态列表
export const getSystemNotifications = params =>
  _apiGET(API_MGMT_ROOT, `/system_notifications`, params, null)

/**
 * 取消工位预订 api
 */

export const freeReservedDesk = params =>
  _apiPOST(API_MGMT_ROOT, `/desks/cancel_reservation`, params, null)

/**
 * 发票相关 api
 */

// 创建发票抬头\
export const postInvoiceTitle = params =>
  _apiPOST(API_MGMT_ROOT, `/invoice_titles`, params, null)

// 更新发票抬头
export const putInvoiceTitle = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/invoice_titles/${id}`, params, null)

// 可用的发票抬头
export const getInvoiceTitles = params =>
  _apiGET(API_MGMT_ROOT, `/invoice_titles`, params, null)

// 删除发票抬头
export const deleteInvoiceTitle = (id, params) =>
  _apiDELETE(API_MGMT_ROOT, `/invoice_titles/${id}`, params, null)

/**
 * 分店组相关 api
 */

// Get all location_group
export const getLocationGroups = () =>
  _apiGET(
    API_MGMT_ROOT,
    `/location_groups`,
    null,
    Schemas.locationGroupsSchemaArray
  )

// Create location_group
export const postLocationGroups = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/location_groups`,
    params,
    Schemas.locationGroupsSchema
  )

// Update location_group
export const putLocationGroup = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/location_groups/${id}`,
    params,
    Schemas.locationGroupsSchema
  )

// Delete location_group
export const deleteLocationGroup = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/location_groups/${id}`,
    null,
    Schemas.locationGroupsSchema
  )

/**
 * 门禁组相关 api
 */

// Get all lock_group
export const getLockGroups = params =>
  _apiGET(API_MGMT_ROOT, `/lock_groups`, params, Schemas.lockGroupsSchemaArray)

// Create lock_group
export const postLockGroups = params =>
  _apiPOST(API_MGMT_ROOT, `/lock_groups`, params, Schemas.lockGroupsSchema)

// Update lock_group
export const putLockGroup = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/lock_groups/${id}`, params, Schemas.lockGroupsSchema)

// Get lock_group
export const getLockGroup = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/lock_groups/${id}`, params, Schemas.lockGroupsSchema)

// Delete lock_group
export const deleteLockGroup = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/lock_groups/${id}`,
    null,
    Schemas.lockGroupsSchema
  )

/**
 *  账单相关统计数据
 */

// 月度账单统计
export const getMonthlyInvoicesStatistics = params =>
  _apiGET(API_MGMT_ROOT, `/invoices/line_item_statistics`, params, null)

export const postMonthlyInvoicesStatistics = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/reports/monthly_summary_line_item_report`,
    params,
    null
  )

// 账单核销统计
export const getConfirmedInvoicesStatistics = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/reports/monthly_split_line_item_report`,
    params,
    null
  )
// 账单核销实收款统计报表
export const getPaidInvoicesStatistics = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/monthly_split_payment_report`, params, null)
// 账单核销统计表格显示
export const getConfirmedInvoiceLineItems = params =>
  _apiGET(API_MGMT_ROOT, `/invoices/monthly_line_items`, params, null)

/**
 * 批量删除工位
 */
export const batchDeleteDesks = params =>
  _apiDELETE(API_MGMT_ROOT, `/desks/batch_destroy`, params)

/**
 * 批量确认收入
 */
export const batchConfirmIncome = params =>
  _apiPUT(API_MGMT_ROOT, `/invoices/confirm_income`, params)

/**
 * 获取 tags
 */
export const getSettingTags = params =>
  _apiGET(API_MGMT_ROOT, `/settings/tags`, params, null)

/**
 * 批量删除分区
 */
export const batchDeleteAreas = params =>
  _apiDELETE(API_MGMT_ROOT, `/areas/batch_destroy`, params)

/**
 * 分店首页统计数据
 */

// 访客数量
export const getHomeOrgEnterAndArchiveStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/home/organization_archived_count`,
    params,
    null
  )

// 访客数量
export const getHomeVisitorStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/home/visitors_count`, params, null)

// 会议室使用比例
export const getHomeMeetingRoomUsageStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/home/meeting_room_usage`, params, null)

// 账单到期数量
export const getHomeInvoiceOverdueCountStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/invoices/amount_by_monthly_detail`,
    params,
    null
  )

// 合同到期数量
export const getHomeSubExpiringCountStatistic = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/home/subscription_expiring_count`,
    params,
    null
  )

/**
 * 数据统计部分新增接口
 */

// 活跃用户
export const getActiveUserCountStatistic = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/home/active_user_count`, params, null)

// 到期账单统计数据
export const getInvoiceStatisticByDueDate = params =>
  _apiGET(API_MGMT_ROOT, `/statistic/invoices/amount_by_due_date`, params, null)

// 账单应收实收统计数据
export const getInvoiceStatisticByMonthlyDetail = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/statistic/invoices/amount_by_monthly_detail`,
    params,
    null
  )

/**
 * 楼层相关接口
 */

// Get all floors
export const getLocationFloors = (loc_id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/locations/${loc_id}/floor_infos`,
    params,
    Schemas.locationFloorsSchemaArray
  )

// Create floors
export const postLocationFloor = (loc_id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/locations/${loc_id}/floor_infos`,
    params,
    Schemas.locationFloorsSchema
  )

// Update floors
export const putLocationFloor = (loc_id, id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/locations/${loc_id}/floor_infos/${id}`,
    params,
    Schemas.locationFloorsSchema
  )

// Get floors
export const getLocationFloorDetail = (loc_id, id, params) =>
  _apiGET(
    API_MGMT_ROOT,
    `/locations/${loc_id}/floor_infos/${id}`,
    params,
    Schemas.locationFloorsSchema
  )

// Delete floors
export const deleteLocationFloor = (loc_id, id) =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/locations/${loc_id}/floor_infos/${id}`,
    null,
    Schemas.locationFloorsSchema
  )

/**
 * 创建写字楼合同
 */
export const createTowerLocationSubscription = params =>
  _apiPOST(API_MGMT_ROOT, `/subscriptions/longterm_create`, params, null)

/**
 * 门禁刷脸相关报表接口
 */

// 离职人员报表（通过最后开门时间统计）
export const getLastOpenDoorReport = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/last_open_door_report`, params, null)

// face++ 照片统计
export const getFacePhotoCountReport = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/face_photo_count_report`, params, null)

// 进出记录汇总（face++）
export const getUserInoutReport = params =>
  _apiPOST(API_MGMT_ROOT, `/reports/user_inout_report`, params, null)

// 批量离职公司员工
export const batchDismissOrgMembers = params =>
  _apiPUT(API_MGMT_ROOT, `/organization_members/batch_dismiss`, params, null)

//获取某一个项目详情
export const getGroupsInfo = group_id =>
  _apiGET(
    API_MGMT_ROOT,
    `/location_groups/${group_id}`,
    null,
    Schemas.groupsSchema
  )

//编辑某一个项目
export const putGroupsInfo = (group_id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/location_groups/${group_id}`,
    params,
    Schemas.groupsSchema
  )

//删除某一个项目
export const deleteGroups = group_id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/location_groups/${group_id}`,
    null,
    Schemas.groupsSchema
  )

/**
 * 储物柜相关 api
 */

// List all smart locker cabinets in given location
export const getLockers = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/smart_locker/cabinets`,
    params,
    Schemas.lockersGroupsSchemaArray
  )

// 智能储物柜格子列表
export const getLockerCabinets = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/smart_locker/cabinets/${id}/lockers`, params)

// 修改智能储物柜格子
export const putLockerCabinet = (cab_id, id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/smart_locker/cabinets/${cab_id}/lockers/${id}`,
    params
  )

// Complete reservation reset a locker to free
export const postLockerReservationComplete = id =>
  _apiPOST(API_MGMT_ROOT, `/smart_locker/reservations/${id}/complete`, null)

// Reserve a locker
export const postLockerReservation = params =>
  _apiPOST(API_MGMT_ROOT, `/smart_locker/reservations`, params)

// 智能储物柜预定列表
export const getLockerReservations = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/smart_locker/reservations`,
    params,
    Schemas.lockerReservationsSchemaArray
  )

// 清柜信息用
export const getCleanUpRecord = params =>
  _apiGET(API_MGMT_ROOT, `/smart_locker/reservations`, params)

// 储物柜规格列表
export const getLockerCategories = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/smart_locker/locker_categories`,
    params,
    Schemas.lockerCategoriesSchemaArray
  )

// 创建储物柜规格
export const postLockerCategories = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/smart_locker/locker_categories`,
    params,
    Schemas.lockerCategoriesSchema
  )

// PUT /managements/v2/smart_locker/cabinets/{cabinet_id}/lockers/{id}/free Set locker status to Free
export const putLockerFree = (cabinet_id, id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/smart_locker/cabinets/${cabinet_id}/lockers/${id}/free`,
    params,
    null
  )

// 修改储物柜规格
export const putLockerCategories = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/smart_locker/locker_categories/${id}`,
    params,
    Schemas.lockerCategoriesSchema
  )

// 删除储物柜规格
export const deleteLockerCategories = id =>
  _apiDELETE(
    API_MGMT_ROOT,
    `/smart_locker/locker_categories/${id}`,
    null,
    Schemas.lockerCategoriesSchema
  )

// PUT /managements/v2/smart_locker/cabinets/{cabinet_id}/lockers/{id}/cleanup   储物柜停止使用
export const cleanupLockers = (cabinet_id, id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/smart_locker/cabinets/${cabinet_id}/lockers/${id}/cleanup`,
    params,
    Schemas.lockerCategoriesSchema
  )

// GET /managements/v2/sensors 传感器列表
export const getSensors = params =>
  _apiGET(API_MGMT_ROOT, `/sensors`, params, null)

// PUT /managements/v2/sensors/{id}/switch 开启/关闭设备
export const switchSensors = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/sensors/${id}/switch`, params, null)

// PUT /managements/v2/sensors 更新传感器详情
export const editSensor = (id, params) =>
  _apiPUT(API_MGMT_ROOT, `/sensors/${id}`, params, null)

// GET /managements/v2/users/{id}/elevator_records 获取用户派梯信息
export const getElevatorRecord = (id, params) =>
  _apiGET(API_MGMT_ROOT, `/users/${id}/elevator_records`, params, null)

// GET /managements/v2/self_defined_line_item_types自定义账单类型列表
export const getSelfDefinedLineItemTypes = params =>
  _apiGET(
    API_MGMT_ROOT,
    `/self_defined_line_item_types`,
    params,
    null,
    Schemas.customSchemaArray
  )

// POST /managements/v2/self_defined_line_item_types 创建自定义账单类型
export const createSelfDefinedLineItemTypes = params =>
  _apiPOST(
    API_MGMT_ROOT,
    `/self_defined_line_item_types`,
    params,
    Schemas.customSchema
  )

// PUT /managements/v2/self_defined_line_item_types/{id}修改自定义账单类型
export const updateSelfDefinedLineItemTypes = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/self_defined_line_item_types/${id}`,
    params,
    Schemas.customSchema
  )

// POST /managements/v2/subscriptions/{id}/generate_phase_invoice 手动生成phase账单*
export const createGeneratePhaseInvoice = (id, params) =>
  _apiPOST(
    API_MGMT_ROOT,
    `/subscriptions/${id}/generate_phase_invoice`,
    params,
    Schemas.customSchema
  )

// PUT /managements/v2/invoices/{id}/write_back_approved 删除账单审批通过*
export const deleteWriteBackApproved = (id,params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/invoices/${id}/write_back_approved`,
    params,
    Schemas.customSchema
  )

// PUT /managements/v2/invoices/{id}/write_back_rejected 删除账单审批拒绝*
export const deleteWriteBackRejected = (id, params) =>
  _apiPUT(
    API_MGMT_ROOT,
    `/invoices/${id}/write_back_rejected`,
    params,
    Schemas.customSchema
  )

//GET /managements/v2/ctags
export const getCtags = (params) => _apiGET(API_MGMT_ROOT,'/ctags',params)

// GET /managements/v2/activities/activities/bridgego_simple 奕桥活动选择列表
export const getBridgegoList = (params) => _apiGET(API_MGMT_ROOT,'/activities/activities/bridgego_simple',params)

// GET /managements/v2/meeting_room/{id}/mini_qrcode
export const getWeChatCode = (id,params) => _apiGET(API_MGMT_ROOT,`/meeting_rooms/${id}/mini_qrcode`, params)

//PUT /managements/v2/invoices/{id}/transfer_deposit 保证金转移
export const transferDeposit = (id, params)=> _apiPUT(API_MGMT_ROOT,`/invoices/${id}/transfer_deposit`, params, Schemas.invoicesSchema)

//POST /managements/v2/invoices/{id}/entry_record 手工入账
export const enterOrder = (id, params) => _apiPOST(API_MGMT_ROOT,`/invoices/${id}/entry_record`, params)

//GET /managements/v2/invoices/debet_statistics 欠款报告
export const debetStatistics = (params) => _apiGET(API_MGMT_ROOT, '/invoices/debet_statistics', params)

//GET /managements/v2/invoices/deposit_statistics 保证金报告
export const depositStatistics = (params) => _apiGET(API_MGMT_ROOT, '/invoices/deposit_statistics', params)

//POST /managements/v2/invoices/import_entry_records
export const importEntryRecords = () => `${API_MGMT_ROOT}/invoices/import_entry_records`

//POST /managements/v2/invoices/{id}/export_remind_invoice_attachment
export const printPage = (id) => _apiPOST(API_MGMT_ROOT,`/invoices/${id}/export_remind_invoice_attachment`)

//GET /managements/v2/merchants
export const getMerchants = async (params) => _apiGET(API_MGMT_ROOT,'/merchants', params)

// Put /managements/v2/merchants/{id}
export const updateMerchants = (params)=> _apiPOST(API_MGMT_ROOT,`/merchants/setup`, params)

// GET /managements/v2/location_providers
export const getGroupProviders = (params)=> _apiGET(API_MGMT_ROOT,`/location_providers`, params)

// POST /managements/v2/location_providers/setup
export const undateGroupProviders = (params) => _apiPOST(API_MGMT_ROOT,'/location_providers/setup', params)

// POST /managements/v2/purchase_discounts
export const createCoupon = (params) => _apiPOST(API_MGMT_ROOT, '/purchase_discounts', params)

// GET /managements/v2/purchase_discounts
export const getCoupon = (params) => _apiGET(API_MGMT_ROOT, '/purchase_discounts', params)

// PUT /managements/v2/purchase_discounts/{id}
export const updateCoupon = (id,params) => _apiPUT(API_MGMT_ROOT, `/purchase_discounts/${id}`, params)

// GET /managements/v2/purchase_discounts/{id}
export const getCouponDeatil = (id) => _apiGET(API_MGMT_ROOT, `/purchase_discounts/${id}`)

// POST /managements/v2/reports/admin_report
export const exportMembers = () => _apiPOST(API_MGMT_ROOT, '/reports/admin_report')

// GET /managements/v2/invoicing_application_settings
export const getInvoiceApplicationSetting = (params) => _apiGET(API_MGMT_ROOT, '/invoicing_application_settings', params)

// POST /managements/v2/invoicing_application_settings/setup
export const updateInvoiceApplicationSetting = (params) => _apiPOST(API_MGMT_ROOT, '/invoicing_application_settings/setup', params)

// POST /managements/v2/reports/activity_order_report
export const reportActivitySignUp = (params) => _apiPOST(API_MGMT_ROOT, '/reports/activity_order_report', params)

// GET /managements/v2/activities/activities/{id}/mini_qrcode
export const getActivityQrCode = (id, params) => _apiGET(API_MGMT_ROOT, `/activities/activities/${id}/mini_qrcode`, params)

// POST /managements/v2/location_groups/{id}/setup_product_tag
export const setBridgePlusProduct = (id, params) => _apiPOST(API_MGMT_ROOT, `/location_groups/${id}/setup_product_tag`, params)

// DELETE /managements/v2/location_groups/{id}/delete_product_tag
export const delBridgePlusProduct = (id, params) => _apiDELETE(API_MGMT_ROOT, `/location_groups/${id}/delete_product_tag`, params)

// PUT /managements/v2/invoices/{id}/check_refund
export const checkRefund = (id, params) => _apiPUT(API_MGMT_ROOT, `/invoices/${id}/check_refund`, params, Schemas.invoicesSchema)

// GET /managements/v2/xforceplus_tax_settings
export const getTaxSettings = (params) => _apiGET(API_MGMT_ROOT, `/xforceplus_tax_settings`, params)

// POST /managements/v2/invoices/{id}/offline_invoicing
// 第三个参数可以触发update的方法
export const setOfflineInvoice = (id, params) => _apiPOST(API_MGMT_ROOT, `/invoices/${id}/offline_invoicing`, params, Schemas.invoicesSchema)

// 创建权益
export const createReplacement = (params) => _apiPOST(API_MGMT_ROOT, `/discount_goods`, params)

// 创建权益时获取会议室+工位
export const getReplacementArea = (params) => _apiGET(API_MGMT_ROOT, `/discount_goods/areas`, params)

// 修改权益
export const updateReplacement = (id, params) => _apiPUT(API_MGMT_ROOT, `/discount_goods/${id}`, params)

// 权益列表
export const getReplacements = params => _apiGET(API_MGMT_ROOT, `/discount_goods`, params)

// 权益详情
export const getReplacementInfo = (id, params) => _apiGET(API_MGMT_ROOT, `/discount_goods/${id}`, params)

// 上下架权益
export const setReplacementSales = (id, params) => _apiPUT(API_MGMT_ROOT, `/discount_goods/${id}/toggle_on_sales`, params)

// 发布权益
export const setReplacementStatus = (id, params) => _apiPUT(API_MGMT_ROOT, `/discount_goods/${id}/toggle_status`, params)

// 删除权益
export const delReplacement = (id, params) => _apiDELETE(API_MGMT_ROOT, `/discount_goods/${id}`, params)

// 权益订单
export const getReplacementOrder = (id, params) => _apiGET(API_MGMT_ROOT, `/discount_goods/${id}/orders`, params)

// 增值服务列表
export const getValueAddedServicesList = (params) => _apiGET(API_MGMT_ROOT, `/value_added_services`, params)

// 删除增值服务
export const deleteValueAddedService = (id) => _apiDELETE(API_MGMT_ROOT, `/value_added_services/${id}`)

// 修改增值服务
export const editValueAddedService = (id, params) => _apiPUT(API_MGMT_ROOT, `/value_added_services/${id}`, params)

// 创建服务
export const addValueAddedService = (params) => _apiPOST(API_MGMT_ROOT, `/value_added_services`, params)

// 增值服务详情
export const getValueAddedServiceDetail = (id, params) => _apiGET(API_MGMT_ROOT, `/value_added_services/${id}`, params)

// 增值服务排序
export const editValueAddedServiceOrder = (params) => _apiPUT(API_MGMT_ROOT, `/value_added_services/sort`, params)

// 增值服务申请列表
export const getValueAddedServiceApplies = (params) => _apiGET(API_MGMT_ROOT, `/value_added_service_applies`, params)

// 增值服务申请列表
export const getValueAddedServiceTagList = (params) => _apiGET(API_MGMT_ROOT, `/value_added_service_applies/tag_lists`, params)

// 导出增值服务申请列表
export const exportValueAddedServiceApplies = (params) => _apiPOST(API_MGMT_ROOT, `/reports/value_added_service_report`, params)

// 会员福利列表
export const getMemberWelfareList = (params) => _apiGET(API_MGMT_ROOT, `/member_benefits`, params)

// 创建会员福利
export const addMemberWelfare = (params) => _apiPOST(API_MGMT_ROOT, `/member_benefits`, params)

// 修改会员福利
export const editMemberWelfare = (id, params) => _apiPUT(API_MGMT_ROOT, `/member_benefits/${id}`, params)

// 删除会员福利
export const deleteMemberWelfare = (id) => _apiDELETE(API_MGMT_ROOT, `/member_benefits/${id}`)

// 会员福利详情
export const getMemberWelfareDetail = (id) => _apiGET(API_MGMT_ROOT, `/member_benefits/${id}`)

// 导出福利数据
export const exportMemberWelfare = (params) => _apiPOST(API_MGMT_ROOT, `/reports/member_benefits_report`, params)

// 通用版获取小程序二维码
export const getCommonQrCode = (params) => _apiGET(API_MGMT_ROOT, `/wechat_setting/mini_qrcode`, params)

// 自助认证列表
export const getAutoAuthList = (params) => _apiGET(API_MGMT_ROOT, `/auth_employees`, params)

// 自助认证通过
export const autoAuthPass = (id, params) => _apiPOST(API_MGMT_ROOT, `/auth_employees/${id}/agree`, params)

// 自助认证拒绝
export const autoAuthReject = (id, params) => _apiPOST(API_MGMT_ROOT, `/auth_employees/${id}/rejected`, params)

// 自助认证导出
export const exportAutoAut = (params) => _apiPOST(API_MGMT_ROOT, `/reports/auth_employee_report`, params)




/**
 * 优惠券列表
 */
export const getCouponTemplateList = (params) => _apiGET(API_MGMT_ROOT, `/coupon_templates`, params)

/**
 * 创建优惠券
 */
export const createCouponTemplate = (params) => _apiPOST(API_MGMT_ROOT, `/coupon_templates`, params)

/**
 * 编辑优惠券
 */
export const updateCouponTemplate = (id, params) => _apiPUT(API_MGMT_ROOT, `/coupon_templates/${id}`, params)

/**
 * 优惠券详情
 */
export const getCouponTemplateInfo = (id, params) => _apiGET(API_MGMT_ROOT, `/coupon_templates/${id}`, params)

/**
 * 上架优惠券
 */
export const approveCouponTemplate = (id, params) => _apiPOST(API_MGMT_ROOT, `/coupon_templates/${id}/approve`, params)

/**
 * 下架优惠券
 */
export const downCouponTemplate = (id, params) => _apiPOST(API_MGMT_ROOT, `/coupon_templates/${id}/down`, params)

/**
 * 优惠券-领券明细
 */
export const getCouponTemplateOrder = (id, params) => _apiGET(API_MGMT_ROOT, `/coupon_templates/${id}/user_coupon_templates`, params)


/**
 * 下架优惠券
 */
export const exportCouponTemplate = (params) => _apiPOST(API_MGMT_ROOT, `/reports/user_coupon_templates_report`, params)

