import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import { api } from 'app/services'
import { opportunitiesActions, apiCallFailure } from 'app/actions'
import { KBStatisticNumber, KBNoData } from 'components'
import { connect } from 'react-redux'
import { OPPORTUNITY_STATES, OPPORTUNITY_STATES_CLASS } from 'app/constants'
import CrmRecordList from './CrmRecordList'
import { util } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import moment from 'moment'

var CrmBox = React.createClass({
  displayName: 'CrmBox',
  getInitialState() {
    return {
      customers_count: 0,
      customer_percent: 0,
      lost_opportunities: 0,
      lost_opportunities_percent: 0,
      won_opportunities: 0,
      won_opportunities_percent: 0,
      my_follow_ups: [],
      descendant_follow_ups: [],
      follow_ups: [],
      show_follow: true,
      customers: [],
      customersShow: true,
      selectCustomer: null,
      inputText: '',
      days: '',
      cycleState: true,
      filters: {}
    }
  },
  componentDidMount() {
    const { cycleState } = this.state
    this.mounted = true
    this._loadData(cycleState)
  },

  _loadData(cycleState) {
    let params = {
      cycle: cycleState ? 'monthly' : 'weekly'
    }
    api.getCrmHomes(params).then(
      ({ json }) => {
        if (this.mounted) {
          this.setState({
            customers_count: json.statistics.customers_count,
            customer_percent: json.statistics.customer_percent,
            lost_opportunities: json.statistics.lost_opportunities || 0,
            lost_opportunities_percent:
              json.statistics.lost_opportunities_percent || 0,
            won_opportunities: json.statistics.won_opportunities || 0,
            won_opportunities_percent:
              json.statistics.won_opportunities_percent || 0,
            my_follow_ups: json.my_follow_ups,
            descendant_follow_ups: json.descendant_follow_ups,
            follow_ups: json.my_follow_ups
          })
        }
      },
      errors => {
        this.props.apiCallFailure(errors)
      }
    )
  },

  changeCustomer(e) {
    var value = e.target.value

    if (value === ' ') {
      return
    }

    this.setState({
      inputText: value
    })

    if (!value) {
      this.setState({
        customers: [
          {
            id: 'new',
            title: '新建客户'
          }
        ],
        customersShow: false
      })

      return
    }

    util.setTimeout(
      'queryCustomer',
      () => {
        api.getSearchCustomers({ query: value, per_page: 500 }).then(
          ({ json }) => {
            this.setState({
              customers: [{ id: 'new', title: '新建客户' }, ...json],
              customersShow: true
            })
          },
          errors => {
            this.props.apiCallFailure(errors)
          }
        )
      },
      200
    )
  },

  createCustomerCallBack(opportunity) {
    const { routerActions } = this.props

    if (!opportunity) {
      return
    }

    routerActions.push(`/admin/crm/opportunities/${opportunity.id}`)
  },

  selectCustomer(customer) {
    const { routerActions } = this.props

    if (customer.id == 'new') {
      const { user } = this.props
      const only_contact = 'false'
      // KBPopover.show(<OpportunityForm callback={ this.createCustomerCallBack } initialValues={{ only_contact }} u_id = { user.id }  />)
      routerActions.push(`/admin/crm/opportunities/new`)
    } else {
      routerActions.push(`/admin/crm/opportunities/${customer.id}`)
    }
  },

  componentWillUnmount() {
    this.mounted = false
    util.clearTimeout('crmIndexInput')
  },

  inputBlur() {
    util.setTimeout(
      'crmIndexInput',
      () => {
        this.setState({
          customers: [],
          customersShow: false
        })
      },
      300
    )
  },

  selectShowFollow() {
    const { show_follow, my_follow_ups, descendant_follow_ups } = this.state
    this.setState({ show_follow: !this.state.show_follow })
    if (show_follow) {
      this.setState({
        follow_ups: descendant_follow_ups
      })
    } else {
      this.setState({
        follow_ups: my_follow_ups
      })
    }
  },
  setFilter(key, value) {
    let newFilters = Object.assign({}, this.state.filters, { [key]: value })
    this.setState({ filters: newFilters })
    this.selectOpportunityState(key, newFilters)
  },
  selectOpportunityState(key, filters) {
    const { routerActions } = this.props
    const { cycleState } = this.state
    const nowDate = moment(new Date()).format('YYYY-MM-DD')
    const weekDate = moment()
      .weekday(0)
      .format('YYYY-MM-DD')
    const monthDate = moment()
      .startOf('month')
      .format('YYYY-MM-DD')
    const startDate = cycleState ? monthDate : weekDate
    routerActions.push(
      '/admin/crm/opportunities?state=' +
        filters.opportunityState +
        '&from=' +
        startDate +
        '&to=' +
        nowDate
    )
  },

  changeCycleMonth() {
    let changeCycle = true
    this.setState({
      cycleState: changeCycle
    })
    this._loadData(changeCycle)
  },

  changeCycleWeek() {
    let changeCycle = false
    this.setState({
      cycleState: changeCycle
    })
    this._loadData(changeCycle)
  },

  render() {
    const {
      cycleState,
      customers_count,
      lost_opportunities,
      won_opportunities,
      customer_percent,
      won_opportunities_percent,
      lost_opportunities_percent,
      show_follow,
      follow_ups,
      customers,
      customersShow,
      inputText,
      days
    } = this.state

    return (
      <div className="kb-content-container clear-fix">
        <section>
          <header className="nav-header t-center">
            <div
              className="chart-box t-left a-hover"
              style={{ marginRight: '75px' }}
              onClick={() => this.setFilter('opportunityState', '')}
            >
              <a>
                <KBStatisticNumber
                  number={customers_count}
                  name={`本${cycleState ? '月' : '周'}新增客户`}
                  unit="个"
                />
              </a>
            </div>
            <div
              className="chart-box t-left a-hover"
              style={{ marginRight: '75px' }}
              onClick={() => this.setFilter('opportunityState', 'success')}
            >
              <a>
                <KBStatisticNumber
                  number={won_opportunities}
                  name={`本${cycleState ? '月' : '周'}新赢单数`}
                  unit="个"
                />
              </a>
            </div>
            <div
              className="chart-box t-left a-hover"
              onClick={() => this.setFilter('opportunityState', 'failed')}
            >
              <a>
                <KBStatisticNumber
                  number={lost_opportunities}
                  name={`本${cycleState ? '月' : '周'}新流失数`}
                  unit="个"
                />
              </a>
            </div>
            <div className="edit-way f-right">
              <span
                className={cycleState ? 'active' : ''}
                onClick={this.changeCycleMonth}
              >
                本月
              </span>
              <span
                className={cycleState ? '' : 'active'}
                onClick={this.changeCycleWeek}
              >
                本周
              </span>
            </div>
          </header>
          <div className="nav-section-content-container">
            <div
              className="nav-content-1-md f-left m-top"
              style={{ width: 770 }}
            >
              <div className="common-search customer-search">
                <input
                  type="text"
                  placeholder="输入客户名字或手机号"
                  onChange={this.changeCustomer}
                  value={inputText}
                  onBlur={this.inputBlur}
                />

                {customers && customers.length && customersShow ? (
                  <ul className="search-customer">
                    {customers.map((customer, index) => {
                      return (
                        <li
                          key={index}
                          onClick={this.selectCustomer.bind(null, customer)}
                        >
                          <a>
                            <div className="customer-message">
                              {customer.id == 'new' ? (
                                <div className="customer-img">
                                  <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/add-btn-hover-sm.jpg" />
                                </div>
                              ) : (
                                ''
                              )}
                              <span
                                className="customer-name"
                                style={
                                  customer.id == 'new'
                                    ? { fontWeight: 'normal' }
                                    : {}
                                }
                              >
                                {customer.title}
                              </span>
                              <div style={{ flex: 1 }}>
                                <span
                                  className={
                                    OPPORTUNITY_STATES_CLASS[customer.state]
                                  }
                                  style={{
                                    padding: '3px 8px',
                                    fontSize: 12,
                                    color: '#fff'
                                  }}
                                >
                                  {OPPORTUNITY_STATES[customer.state]}
                                </span>
                              </div>
                              {customer && customer.contact ? (
                                <span
                                  style={{
                                    display: 'inline-block',
                                    marginRight: 15
                                  }}
                                >
                                  <i className="iconfont icon-icon_phone" />
                                  {customer &&
                                    customer.contact &&
                                    customer.contact.mobile &&
                                    customer.contact.mobile.replace(
                                      /(\d{3})\d{4}(\d{4})/,
                                      '$1****$2'
                                    )}
                                </span>
                              ) : null}
                              <span
                                className="color-nine"
                                style={{ display: 'block', width: 145 }}
                              >
                                {customer.updated_at
                                  ? `跟进时间 ${moment(
                                      customer.updated_at
                                    ).format('YYYY-MM-DD')}`
                                  : ''}
                              </span>
                            </div>
                            {customer.id == 'new' ? (
                              ''
                            ) : (
                              <div className="customer-principal">
                                <div className="d-iblock" style={{ flex: 1 }}>
                                  <span>负责人</span>
                                  <span
                                    className="principal"
                                    style={{ marginLeft: 10 }}
                                  >
                                    {customer.sales_team.find(
                                      team => !!team.is_leader
                                    ) &&
                                      customer.sales_team.find(
                                        team => !!team.is_leader
                                      ).sales_user &&
                                      customer.sales_team.find(
                                        team => !!team.is_leader
                                      ).sales_user.name}
                                  </span>
                                </div>
                                <span style={{ display: 'block', width: 145 }}>
                                  {customer.created_at
                                    ? `创建时间 ${moment(
                                        customer.created_at
                                      ).format('YYYY-MM-DD')}`
                                    : ''}
                                </span>
                              </div>
                            )}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                ) : null}
              </div>
              <div className="kb-title m-bottom-sm">
                <span className="color-six">销售记录</span>
              </div>
              <div className="nav-comment-container">
                <CrmRecordList isAll={true} opportunity={{ id: 1 }} />
              </div>
            </div>
            <nav className="nav-content-2  f-right m-top">
              <div className="nav-container-content border-none">
                <header className="nav-inner-header clear-fix">
                  <h2 className="header-title f-left">待跟进事项</h2>
                  <span
                    className="add-btn f-right"
                    onClick={this.selectShowFollow}
                    style={{ marginTop: 4 }}
                  >
                    {show_follow ? '查看下属' : '只看我的'}
                  </span>
                </header>
                <ul className="desk-3-ul">
                  {follow_ups && follow_ups.length > 0 ? (
                    follow_ups.map((json, index) => {
                      let next_follow_at = moment.unix(json.next_follow_at)
                      let now_date = moment(new Date())
                      let flag_date = next_follow_at.diff(now_date, 'days')
                      let days = this.state.days
                      if (flag_date == 0) {
                        days = '今天'
                      } else if (flag_date == -1) {
                        days = '昨天'
                      } else {
                        days = next_follow_at.fromNow()
                      }
                      return (
                        <li key={index}>
                          <Link
                            to={`/admin/crm/opportunities/${json.id}`}
                            style={{ padding: '10px 15px' }}
                          >
                            <div className="clear-fix">
                              <span
                                className="t-over f-left"
                                style={{
                                  maxWidth: 100,
                                  display: 'inline-block',
                                  marginTop: 3
                                }}
                              >
                                {json.title}
                              </span>
                              {flag_date < 0 ? (
                                <span
                                  className="f-right receipt-overdue"
                                  style={{
                                    fontSize: 12,
                                    color: '#fff',
                                    padding: '3px 6px',
                                    marginLeft: 10
                                  }}
                                >
                                  逾期
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            {json.next_follow_at ? (
                              <div
                                className="task-status"
                                style={{ marginTop: 5 }}
                              >
                                <span>{days}</span>
                                <span>跟进</span>
                              </div>
                            ) : (
                              ''
                            )}
                          </Link>
                        </li>
                      )
                    })
                  ) : (
                    <div className="box-content">
                      <KBNoData tipMessage="暂无跟进事项" />
                    </div>
                  )}
                </ul>
              </div>
            </nav>
          </div>
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const user = selectors.getCurrentUser(state)

  return {
    user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getOpportunity: bindActionCreators(opportunitiesActions.all, dispatch),
    apiCallFailure: bindActionCreators(apiCallFailure, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrmBox)
