import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { subscriptionsAction, successState } from 'app/actions'
import {
  KBInput,
  KBRadio,
  KBTextarea,
  KBSelect,
  KBFormError,
  KBButton,
  KBPopover,
  KBA,
  KBImageCropperInput
} from 'components'

var ContractNoPassForm = React.createClass({
  contractNoPassForm(model) {
    const { updateSubscriptionsAction, successAction, id } = this.props

    return api.updateSubScriptionApprove(id, model, { pass: false }).then(
      json => {
        successAction({ message: '合同审批不通过操作成功' })
        updateSubscriptionsAction.success(json)
        KBPopover.close()
      },
      errors => {
        updateSubscriptionsAction.failure(errors)
      }
    )
  },

  render() {
    const {
      fields: { reason },
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.contractNoPassForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">合同审批</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <span className="color-nine">是否不通过该合同</span>
          </div>
          <div className="kb-row">
            <KBTextarea
              field={reason}
              title="原因"
              className="kb-form-group kb-form-c18 kb-form-alone"
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, ownProps) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    updateSubscriptionsAction: bindActionCreators(
      subscriptionsAction.update,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const formConfig = {
  form: 'ContractNoPassForm',
  fields: ['reason'],
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ContractNoPassForm)
