import React, { Component, PropTypes } from 'react'
import YearMonthForm from '../utils/YearMonthForm'
import DayPicker, { DateUtils } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import moment from 'moment'
const defaultFormat = 'YYYY-MM-DD'
const currentYear = new Date().getFullYear()
const toMonth = new Date(currentYear + 10, 11, 31, 23, 59)
const fromMonth = new Date(1960, 0, 1, 0, 0)

var KBDayPicker = React.createClass({
  getInitialState() {
    const { selectedDay } = this.props
    return {
      selectedDay: selectedDay || new Date()
    }
  },
  handleDayClick(e, day) {
    this.setState({
      selectedDay: day
    })
    this.props.onChange && this.props.onChange(day)
  },

  changeMonth(initialMonth) {
    this.setState({ value: initialMonth })
    this.props.onChange && this.props.onChange(initialMonth)
  },

  componentWillReceiveProps(nextProps) {
    if (!moment(nextProps.selectedDay).isSame(this.props.selectedDay)) {
      this.setState({
        selectedDay: nextProps.selectedDay
      })
    }
  },

  render() {
    const { selectedDay } = this.state
    const { defaultYear } = this.props

    return (
      <DayPicker
        dir="zh-cn"
        locale="zh-cn"
        ref="daypicker"
        localeUtils={MomentLocaleUtils}
        initialMonth={selectedDay}
        selectedDays={day => DateUtils.isSameDay(selectedDay, day)}
        onDayClick={this.handleDayClick}
        disabledDays={this.props.disabledDays}
        fromMonth={fromMonth}
        toMonth={toMonth}
        captionElement={
          <YearMonthForm
            onChange={this.changeMonth}
            selectedDay={selectedDay}
            defaultYear={defaultYear || '2016'}
            currentYear={moment(selectedDay)
              .toDate()
              .getFullYear()}
          />
        }
      />
    )
  }
})

KBDayPicker.propTypes = {
  selectedDay: PropTypes.object,
  onChange: PropTypes.func,
  disabledDays: PropTypes.func
}

export default KBDayPicker
