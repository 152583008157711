import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { ORG_ROLES_OBJ } from 'app/constants'
import { api, apiUtils } from 'app/services'
import { apiCallFailure, usersActions, invoicesActions } from 'app/actions'
import { KBAvatar, KBCheckBox, KBInput } from 'components'
import _ from 'lodash'

var InvoicesRecipientList = React.createClass({
  getInitialState() {
    const { recipient } = this.props
    return {
      emailStatus: true,
      emailValue: '',
      ...recipient
    }
  },

  componentWillReceiveProps(nextProps) {
    const { recipient } = nextProps
    const oldRecipient = this.props.recipient
    if (!_.isEqual(recipient, oldRecipient)) {
      this.setState({
        ...recipient
      })
    }
  },

  updateRecipientEmail() {
    const { loc_id, org_id, invoice_id, updateUserActions } = this.props
    const { emailValue, id } = this.state

    api.updateOrgMember(id, { email: emailValue }).then(json => {
      updateUserActions.success(json)
      this.setState({
        email: emailValue
      })
    })
  },

  changeRecipientEmail(checked) {
    const { callback, recipient } = this.props
    const { email } = this.state
    let recipientObj = Object.assign({}, recipient)
    recipientObj.email = email

    callback && callback(checked, recipientObj)
  },

  changeEmailStatus() {
    this.setState({
      emailStatus: false
    })
  },

  changeEmailStatusFail() {
    this.setState({
      emailStatus: true,
      emailValue: ''
    })
  },

  updateEmailInput(e) {
    let value = e.target.value
    this.setState({
      emailValue: value
    })
  },

  changeEmailValue(e) {
    const { changeBillEmailValue } = this.props
    const { name } = this.state
    let value = e.target.value
    this.setState({
      email: value
    })
    changeBillEmailValue && changeBillEmailValue({ value, name })
  },

  render() {
    const { emailStatus, emailValue, email, name, role } = this.state

    return (
      <li>
        <KBCheckBox
          callback={this.changeRecipientEmail}
          checked={email && email.length > 0 ? true : false}
          style={{ marginRight: 5, display: 'inline-block' }}
        />
        <span className="recipient-name">{name}</span>
        <span>{ORG_ROLES_OBJ[role]}</span>
        {email && email.length > 0 ? (
          <div className="d-iblock">
            <input
              className="recipient-comment"
              value={email || ''}
              onChange={this.changeEmailValue}
            />
          </div>
        ) : emailStatus ? (
          <a className="certain-btn-ms" onClick={this.changeEmailStatus}>
            添加账单接收人邮箱
          </a>
        ) : (
          <div className="d-iblock" style={{ verticalAlign: 'middle' }}>
            <input
              className="recipient-comment"
              value={emailValue || ''}
              onChange={this.updateEmailInput}
            />
            <a
              className="certain-btn-ms"
              onClick={this.changeEmailStatusFail}
              style={{ marginTop: 3, marginLeft: 15 }}
            >
              放弃添加
            </a>
            <a
              className="certain-btn-ms"
              onClick={this.updateRecipientEmail}
              style={{ marginTop: 3, marginLeft: 15 }}
            >
              确定添加
            </a>
          </div>
        )}
      </li>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    updateUserActions: bindActionCreators(usersActions.update, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesRecipientList)
