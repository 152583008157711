import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import AssistBox from './../shuck/AssistBox'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { deskActions } from 'app/actions'
import { KBInput, KBPopover, KBFormError, KBButton } from 'components'

var DeskForm = React.createClass({
  displayName: 'DeskForm',
  getInitialState() {
    return {}
  },
  createOrganizationMemberForm(model) {
    var area_id = this.props.area_id
    return api.createDesk(area_id, model).then(json => {
      this.props.createDeskAction.success(json)
      KBPopover.close()
    })
  },
  render() {
    const {
      fields: { serial_number, list_price },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.createOrganizationMemberForm)}>
        <KBFormError err={this.props.error} />
        <KBInput
          title="编号"
          field={serial_number}
          placeholder="编号"
          fistFoucs={true}
        />
        <KBInput title="价格" field={list_price} placeholder="价格" />
        <KBButton type="submit" submitting={submitting}>
          确定
        </KBButton>
        <span onClick={KBPopover.close}>取消</span>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    createDeskAction: bindActionCreators(deskActions.create, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.list_price)) {
    errors.list_price = '请输入价格'
  }

  return errors
}

const formConfig = {
  form: 'DeskForm',
  fields: ['serial_number', 'list_price'],
  validate: validate
}

DeskForm = reduxForm(formConfig, mapStateToProps, mapDispatchToProps)(DeskForm)

export default DeskForm
