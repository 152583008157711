import React, { Component } from 'react'

export default class KBSelectTab extends Component {
  onChange(value) {
    const {onChange} = this.props
    onChange && onChange(value)
  }

  render() {
    const {options = [], value = '', style} = this.props
    const _options = [{label: '全部', value: ''}, ...options]
    return (
      <div className="common-tab" style={style}>
        {
          _options.map((item) => {
            return (
              <div
                className={`tab-list ${item.value === value ? 'tab-list_active' : ''}`}
                style={{ padding: '6px 13px' }}
                onClick={() => this.onChange(item.value)}
              >
                <span className="radio" />
                <span className="title">{item.label}</span>
              </div>
            )
          })
        }
      </div>
    )
  }
}
