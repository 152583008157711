import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { KBPopoverTop, KBPopover, KBImageCropper } from 'components'
import { notification } from 'antd'
import { api, apiUtils } from 'app/services'
import { getUUID } from 'app/utils/CommonUtils'
import request from 'superagent-bluebird-promise'
import config from 'app/config'
import { util, dataURLtoFile, getBlobService } from 'utils/kbUtil'
const uuidv4 = require('uuid/v4')

var KBImageCropperInput = React.createClass({
  // based on https://github.com/paramaggarwal/react-dropzone
  propTypes: {
    field: React.PropTypes.object,
    size: React.PropTypes.number,
    style: React.PropTypes.object,
    supportClick: React.PropTypes.bool,
    accept: React.PropTypes.string,
  },

  getDefaultProps: function() {
    return {
      supportClick: true,
      prefix: config.uploadDir + 'avatar/'
    }
  },

  getInitialState: function() {
    const showUrl = config.qiniu_base_url
    const baseUrl = config.qiniu_upload_url

    return {
      isDragActive: false,
      file: null,
      uploading: false,
      showUrl: showUrl,
      baseUrl: baseUrl + 'putb64/-1/key/'
    }
  },

  onDragLeave: function(e) {
    this.setState({
      isDragActive: false
    })
  },

  onDragOver: function(e) {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'copy'

    this.setState({
      isDragActive: true
    })
  },

  changeField(value) {
    this.props.field && this.props.field.onChange(value)
    this.props.callback && this.props.callback(value)
  },

  onDrop: function(e) {
    e.preventDefault()

    this.setState({
      isDragActive: false
    })

    var files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }

    var maxFiles = 1

    for (var i = 0; i < maxFiles; i++) {
      files[i].dataURL = URL.createObjectURL(files[i])
    }

    files = Array.prototype.slice.call(files, 0, maxFiles)
    if (files.length > 0) {
      let dataURL = files[0].dataURL
      let name = files[0].name
      let expand = name.substr(name.lastIndexOf('.')).toLocaleLowerCase()

      if (
        expand !== '.jpg' &&
        expand !== '.png' &&
        expand !== '.jpeg'
      ) {
        if(this.props.failureCallBack ) {
          this.props.failureCallBack('请选择后缀为.jpg,.png,.jpeg的文件')
        } else {
          alert(('请选择后缀为.jpg,.png,.jpeg的文件'))
        }
        return
      }

      if (this.props.imageSize) {
        const newSize = this.props.imageSize * 1024
        if (newSize < files[0].size) {
          this.props.failureCallBack(
            `请选择小于${this.props.imageSize / 1024}M的图片`
          )
          return
        }
      }

      this.setState({ expand })
      // open cropper
      KBPopoverTop.show(
        <KBImageCropper
          expand={expand}
          dataURL={dataURL}
          aspect={this.props.aspect}
          previewWidth={this.props.width}
          previewHeight={this.props.height}
          previewBorderRadius={this.props.previewBorderRadius}
          onCrop={this.handleCrop}
          onCancelCrop={this.closeCropper}
        />,
        {
          closeOnOutsideClick: false
        }
      )
    }
  },

  async uploadToBlob(file) {
    const { failureCallBack, loadingCallBack, isPublic } = this.props
    const fd = new FormData()
    fd.append('file', file)
    try {
      const data = await api[isPublic ? 'uploadToPublicBlob' : 'uploadToPrivacyBlob'](fd)
      loadingCallBack && loadingCallBack(false)
      this.setState({ uploading: false })
      this.changeField(data.url)
    } catch (error) {
      if(failureCallBack) {
        failureCallBack(error)
      } else {
        alert('上传失败，请重试')
      }
    }
  },

  async toUploadAzure(dataURL, urlOBject, checkMD5 = false) {
    if (!dataURL) {
      return
    }
    const { prefix, isPublic, failureCallBack, imageSize } = this.props
    const uploadUrl = config.azure_url
    var file = dataURLtoFile(dataURL, uuidv4() + '.png')

    if (failureCallBack && (file.size > imageSize * 1024)) {
      KBPopover.close()
      failureCallBack(`请选择小于${imageSize / 1024}M的图片`)
      return
    }

    return this.uploadToBlob(file)

    // 私有blob
    const filename = file.name
    const azureData = (
      await api.getAzureToken({
        filepath: `${config.media_container}/${prefix}${filename}`
      })
    ).json
    if (!azureData.token) {
      return
    }

    var blobService = getBlobService(uploadUrl, azureData.token)
    if (!blobService) {
      return
    }

    const { loadingCallBack } = this.props

    let imageDataURLPair = dataURL.split(',')
    if (imageDataURLPair.length < 2) {
      return
    }

    this.setState({ uploading: true })
    this.props.loadingCallBack && this.props.loadingCallBack(true)

    var blockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512
    var options = {
      storeBlobContentMD5: checkMD5,
      blockSize: blockSize
    }

    blobService.singleBlobPutThresholdInBytes = blockSize
    const serversUrl = `${uploadUrl}${config.media_container}/${prefix}${filename}`
    var speedSummary = blobService.createBlockBlobFromBrowserFile(
      config.media_container,
      prefix + file.name,
      file,
      options,
      (error, result, response) => {
        speedSummary.body = {
          ...speedSummary.body,
          'x:filename': file.name,
          key: `${config.media_container}/${speedSummary.name}`
        }
        if (error) {
          alert(error)
        } else {
          loadingCallBack && loadingCallBack(false)
          this.setState({ uploading: false })
          this.changeField(serversUrl)
          URL && URL.revokeObjectURL(urlOBject)
        }
      }
    )

    // speedSummary.on('progress', () => {
    //     var process = speedSummary.getCompletePercent();
    //     updateProgress && updateProgress(process)
    // });

    this.props.failureCallBack && this.props.failureCallBack('')
    KBPopoverTop.close()
  },

  closeCropper: function() {
    KBPopoverTop.close()
  },

  handleCrop: function(dataURL, urlOBject) {
    const { isAzureUpload } = config

    KBPopoverTop.close()
    if (isAzureUpload) {
      this.toUploadAzure(dataURL, urlOBject)
      return
    }
    if (!dataURL) {
      return
    }

    const { type, loadingCallBack } = this.props
    const { expand, showUrl } = this.state

    let imageDataURLPair = dataURL.split(',')
    if (imageDataURLPair.length < 2) {
      return
    }
    let imageDataURL = imageDataURLPair[1]

    let encodedData = window.btoa(
      `uploads/${type || 'company'}/${getUUID()}${expand || '.jpg'}`
    )
    let size = Math.ceil(imageDataURL.length / 3) * 4

    if (this.props.failureCallBack && size / 1024 > 2000) {
      KBPopover.close()
      this.props.failureCallBack('选择图片不能大于4M')
      return
    }

    this.putb64({
      dataURL: imageDataURL,
      key: encodedData,
      token: this.state.uploadToken,
      callback: data => {
        loadingCallBack && loadingCallBack(false)
        this.setState({ uploading: false })
        let qiniuUrl = showUrl + JSON.parse(data).key
        this.changeField(qiniuUrl)
        URL && URL.revokeObjectURL(urlOBject)
      }
    })

    this.props.failureCallBack && this.props.failureCallBack('')
    KBPopoverTop.close()
  },

  putb64(data) {
    var pic = data.dataURL
    this.setState({ uploading: true })
    this.props.loadingCallBack && this.props.loadingCallBack(true)
    var url = this.state.baseUrl + data.key
    var r = request
      .post(url)
      .send(pic)
      .set('Content-Type', 'application/octet-stream')
      .set('Authorization', 'UpToken ' + data.token)
      .end(
        e => {
          data.callback(r.xhr.responseText)
        },
        e => {}
      )
  },

  setUrlAndToken({ domain, token, upload_url }) {
    this.setState({
      uploadToken: token,
      showUrl: domain,
      baseUrl: upload_url + 'putb64/-1/key/'
    })
  },

  onClick: function() {
    const { bucket, isPublic } = this.props

    let params = {}
    if (bucket) {
      params.bucket = bucket
    }

    if(!isPublic) {
      util.setTimeout(
        'uploadToken',
        () => {
          api.getUploadToken(params).then(
            ({ json }) => {
              this.setUrlAndToken(json)
            },
            errors => {}
          )
        },
        300
      )
    }

    if (this.props.supportClick) {
      this.open()
    }
  },

  open: function() {
    var fileInput = ReactDOM.findDOMNode(this.refs.fileInput)
    fileInput.value = null
    fileInput.click()
  },

  render: function() {
    const {
      className,
      titleClass,
      title,
      inClassName,
      isRequired,
      mask,
      maskStyle
    } = this.props
    const { uploading } = this.state
    var inClass = inClassName || 'dropzone'
    if (this.state.isDragActive) {
      inClass = classNames(inClass, 'active')
    }

    var style = this.props.style
    // || {
    //    width: this.props.size || 100,
    //    height: this.props.size || 100,
    //    borderStyle: this.state.isDragActive ? 'solid' : 'dashed'
    //  };

    return (
      <div className={className}>
        {title && (
          <label className={titleClass}>
            {title}
            {isRequired ? <span className="must-fill">*</span> : ''}
          </label>
        )}
        <div
          className={inClass}
          style={style}
          onClick={this.onClick}
          onDragLeave={this.onDragLeave}
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
        >
          {mask ? (
            <div
              className={`mask ${uploading ? 'd-block' : ''}`}
              style={maskStyle}
            >
              {uploading ? (
                <p style={{ fontSize: 12 }}>上传中...</p>
              ) : (
                <i className="iconfont icon-add" />
              )}
            </div>
          ) : (
            ''
          )}
          <input
            style={{ display: 'none' }}
            type="file"
            multiple={false}
            ref="fileInput"
            onChange={this.onDrop}
            accept={this.props.accept}
          />
          {this.props.children || (
            <div className="post-btn-default">
              <div className="post-btn-content">
                <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/pact-icon.png" />
                <span>{uploading ? '上传中...' : '点击上传'}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
})

export default KBImageCropperInput
