import React from 'react';
import { KBPopover, KBButton } from 'components';

const KBConfirm = React.createClass({
  getInitialState(){
    return {
      loading:false,
    }
  },
  submit(){
    // callback 为一个异步函数 可以在前面加 async
    const { callback } = this.props
    this.setState({loading:true},()=>{
      callback().then(()=>{
        this.setState({loading:false})
        KBPopover.close()
      })
    })
  },
  render(){
    const { title, context, cancelText, okText} = this.props;
    return <div>
      <header className="kb-form-header">
          <h2 className="header-title">{title}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
      </header>
      <div className="kb-form-container">
        <div>{context}</div>
        <div className="kb-row fixed-form-btn">
          <span className="cancel-btn" onClick={KBPopover.close}>
            {cancelText ? cancelText : '取消'}
          </span>
          <KBButton
            className="certain-btn m-left-sm"
            type="submit"
            submitting={this.state.loading}
            onClick={this.submit}
          >
            {okText ? okText : '确定'}
          </KBButton>
        </div>
      </div>
    </div>
  }
});

export default KBConfirm;
