import React, { Component, PropTypes } from 'react'
import { KBLoadingContainer, KBAvatar, KBDropdown } from 'components'
import Select from './Select'

var Content = React.createClass({
  multiRowJsx() {
    const { selectData, options } = this.props
    // if (!selectData) {
    //   return ''
    // }

    // return (
    //   <ul className='KB-selected_title clear-fix'>
    //     {
    //       selectData.map((data, index) => {
    //         return (
    //           <li key={ index } style={ { float: 'left' } }>
    //             <KBAvatar user={ data } size={ 30 } style={ { width: '30px', height: '30px', lineHeight: '33px' } } />
    //             <span onClick={ (e) => { e.stopPropagation(); this.props.deleteSelect(data, index) } }/>
    //           </li>
    //         )
    //       })
    //     }
    //   </ul>
    // )
    return (
      <span>
        {options.defaultSelectPrompt}
        {selectData && selectData.length > 0 ? selectData.length + '个' : ''}
      </span>
    )
  },

  multiDropDown(ref) {
    const { setMultiDropDown } = this.props
    setMultiDropDown && setMultiDropDown(ref)
  },

  singleDropDown(ref) {
    const { setSingleDropDown } = this.props
    setSingleDropDown && setSingleDropDown(ref)
  },

  multiJsx() {
    const { selectData, options, closeClick, dropDown_name } = this.props

    return (
      <div className="clear-fix">
        <KBDropdown ref={this.multiDropDown}>
          <KBDropdown.KBDropdownTrigger>
            <div
              className="task-status-title"
              onClick={() => {
                closeClick && closeClick(dropDown_name)
              }}
            >
              <span>{this.multiRowJsx()}</span>
              <span className="task-deg" />
            </div>
          </KBDropdown.KBDropdownTrigger>
          <KBDropdown.KBDropdownContent>
            <Select {...this.props} />
          </KBDropdown.KBDropdownContent>
        </KBDropdown>
      </div>
    )
  },

  singleJsx() {
    const { selectData, options, closeClick, dropDown_name } = this.props
    return (
      <div>
        <KBDropdown ref={this.singleDropDown}>
          <KBDropdown.KBDropdownTrigger>
            <div
              className="task-status-title"
              onClick={() => {
                closeClick && closeClick(dropDown_name)
              }}
            >
              <span>
                {(selectData && selectData[options.value]) ||
                  options.defaultSingleContent}
              </span>
              <span className="task-deg" />
            </div>
          </KBDropdown.KBDropdownTrigger>
          <KBDropdown.KBDropdownContent>
            <Select {...this.props} />
          </KBDropdown.KBDropdownContent>
        </KBDropdown>
      </div>
    )
  },
  render() {
    const { multi } = this.props

    if (multi) {
      return this.multiJsx()
    }

    return this.singleJsx()
  }
})

export default Content
