import KBInputComponent from './utils/KBInput'
import KBSwitchComponent from './utils/KBSwitch'
import KBFormErrorComponent from './utils/KBFormError'
import KBButtonComponent from './utils/KBButton'
import KBTextareaComponent from './utils/KBTextarea'
import KBPopoverComponent from './utils/KBPopover'
import KBPopoverTopComponent from './utils/KBPopoverTop'
import KBSelectComponent from './utils/KBSelect'
import KBRadioComponent from './utils/KBRadio'
import KBFormComponent from './utils/KBForm'
import KBQiniuFileUploadComponent from './utils/KBQiniuFileUpload'
import KBMorePhotoUploadComponent from './utils/KBMorePhotoUpload'
import KBMoreFileUploadComponent from './utils/KBMoreFileUpload'
import KBMoreFilesUploadComponent from './utils/KBMoreFilesUpload'
import KBMoreImageCropperInputComponent from './utils/KBMoreImageCropperInput'
import KBSuccessComponent from './utils/KBSuccess'
import KBDateInputComponent from './utils/KBDateInput'
import KBUserInputComponent from './utils/KBUserInput'
import KBDropDownSpringComponent from './utils/KBDropDownSpring'
import KBDepartmentInputComponent from './utils/KBDepartmentInput'
import KBAsyncInputListComponent from './utils/KBAsyncInputList'
import KBTabsComponent from './utils/KBTabs'
import KBLoadingContainerComponent from './utils/KBLoadingContainer'
import KBCheckBoxComponent from './utils/KBCheckBox'
import KBAComponent from './utils/KBA'
import KBErrorComponent from './utils/KBError'
import KBPaginationComponent from './utils/KBPagination'
import KBLetterAvatarComponent from './utils/KBLetterAvatar'
import KBAvatarComponent from './utils/KBAvatar'
import KBDropDownListInputComponent from './utils/KBDropDownListInput'
import KBSearchListComponent from './utils/KBSearchList'
import KBSearchSelectComponent from './utils/KBSearchSelect'
import KBImageCropperComponent from './utils/KBImageCropper'
import KBImageCropperInputComponent from './utils/KBImageCropperInput'
import KBStatisticNumberComponent from './utils/KBStatisticNumber'
import KBExcelUploadComponent from './utils/KBExcelUpload'
import KBLogoCropperComponent from './utils/KBLogoCropper'
import KBUserSelectComponent from './utils/KBUserSelect'
import KBRangeDateComponent from './utils/KBRangeDate'
import KBLogoComponent from './utils/KBLogo'
import KBASNumberInputComponent from './utils/KBASNumberInput'
import KBQiniuImageComponent from './utils/KBQiniuImage'
import KBTableWithoutDataComponent from './utils/KBTableWithoutData'
import KBRadioListComponent from './utils/KBRadioList'
import KBFileUploadComponent from './utils/KBFileUpload'

import KBToReportCenterComponent from './views/KBToReportCenter'
import CountDownButtonComponent from './views/CountDownButton'
import KBNoDataComponent from './views/KBNoData'
import KBStarRateComponent from './views/KBStarRate'
import KBPaymentSetting from './views/PaymentSetting'

import MembersListComponent from './users/MembersList'
import UserLinkComponent from './users/UserLink'

import AreaLinkComponent from './areas/AreaLink'

import KBTipsyComponent from './plugins/kbtipsy/KBTipsy'
import KBTransferComponent from './plugins/kbtransfer'
import KBDropdownComponent from './plugins/kbdropdown/index'

import KBConfirm from './utils/KBConfirm'
import KBTableCom from './utils/KBTable'
import KBTimeRangeSelectCom from './utils/KBTimeRangeSelect'


import KBSelectTabCom from './utils/KBSelectTab'

export const KBSelectTab = KBSelectTabCom
export const KBInput = KBInputComponent
export const KBSwitch = KBSwitchComponent
export const KBForm = KBFormComponent
export const KBFormError = KBFormErrorComponent
export const KBButton = KBButtonComponent
export const KBTextarea = KBTextareaComponent
export const KBPopover = KBPopoverComponent
export const KBPopoverTop = KBPopoverTopComponent
export const KBSelect = KBSelectComponent
export const KBRadio = KBRadioComponent
export const KBQiniuFileUpload = KBQiniuFileUploadComponent
export const KBMorePhotoUpload = KBMorePhotoUploadComponent
export const KBMoreImageCropperInput = KBMoreImageCropperInputComponent
export const KBMoreFileUpload = KBMoreFileUploadComponent
export const KBMoreFilesUpload = KBMoreFilesUploadComponent
export const KBSuccess = KBSuccessComponent
export const KBDateInput = KBDateInputComponent
export const KBUserInput = KBUserInputComponent
export const KBDropDownSpring = KBDropDownSpringComponent
export const KBDepartmentInput = KBDepartmentInputComponent
export const KBAsyncInputList = KBAsyncInputListComponent
export const KBTabs = KBTabsComponent
export const KBLoadingContainer = KBLoadingContainerComponent
export const KBCheckBox = KBCheckBoxComponent
export const KBA = KBAComponent
export const KBError = KBErrorComponent
export const KBPagination = KBPaginationComponent
export const KBLetterAvatar = KBLetterAvatarComponent
export const KBAvatar = KBAvatarComponent
export const KBDropDownListInput = KBDropDownListInputComponent
export const KBSearchList = KBSearchListComponent
export const KBSearchSelect = KBSearchSelectComponent
export const KBImageCropper = KBImageCropperComponent
export const KBImageCropperInput = KBImageCropperInputComponent
export const KBStatisticNumber = KBStatisticNumberComponent
export const KBExcelUpload = KBExcelUploadComponent
export const KBLogoCropper = KBLogoCropperComponent
export const KBUserSelect = KBUserSelectComponent
export const KBRangeDate = KBRangeDateComponent
export const KBLogo = KBLogoComponent
export const KBASNumberInput = KBASNumberInputComponent
export const KBQiniuImage = KBQiniuImageComponent
export const KBTableWithoutData = KBTableWithoutDataComponent
export const KBRadioList = KBRadioListComponent
export const KBToReportCenter = KBToReportCenterComponent
export const KBFileUpload = KBFileUploadComponent

export const MembersList = MembersListComponent
export const UserLink = UserLinkComponent
export const KBNoData = KBNoDataComponent
export const KBStarRate = KBStarRateComponent

export const AreaLink = AreaLinkComponent

export const KBTipsy = KBTipsyComponent
export const KBTransfer = KBTransferComponent
export const KBDropdown = KBDropdownComponent
export const CountDownButton = CountDownButtonComponent
export const PaymentSetting = KBPaymentSetting

export const KBConfirmModal = KBConfirm
export const KBTable = KBTableCom
export const KBTimeRangeSelect = KBTimeRangeSelectCom

