import React from 'react'
import { Link } from 'react-router'

export default React.createClass({
  render() {
    return (
      // 授权失败会跳转到这里来
      <div
        className="lr-out-box"
        style={{
          textAlign: 'center'
        }}
      >
        <h1>授权失败</h1>
        <Link
          to="/"
          style={{
            fontSize: '16px'
          }}
        >
          重新授权
        </Link>
      </div>
    )
  }
})
