import React from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import * as actions from 'app/actions'
import {
  KBAvatar,
  KBPopover,
  KBTipsy,
  KBTableWithoutData,
  KBLoadingContainer
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import * as constant from 'app/constants'
import ApplicationForm from './ApplicationForm'
import { canAccessLocation } from 'app/reducers/role'

var Applications = React.createClass({
  displayName: 'Applications',

  getInitialState() {
    return {
      enabled: null,
      loading: false
    }
  },

  componentDidMount() {
    this._loadData()
  },

  _loadData() {
    const { getAppModulesActions, loc_id } = this.props
    const params = Object.assign(
      {},
      { per_page: 1000 },
      loc_id ? { location_id: loc_id } : {}
    )

    this.setState({ loading: true })
    api
      .getAppModules(params)
      .then(
        json => {
          getAppModulesActions.success(json)
        },
        errors => {
          getAppModulesActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },
  //location_group_id
  updateAppModuleEnabled(id, enabled) {
    const { updateAppModulesActions, successAction, loc_id } = this.props
    const params = Object.assign(
      {},
      { enabled },
      loc_id ? { location_id: loc_id } : {}
    )
    api.updateAppModule(id, params).then(json => {
      updateAppModulesActions.success(json)
      successAction({ message: enabled == false ? '模块已隐藏' : '模块已启用' })
      return true
    })
  },

  changeAppModuleEnabled(appModule) {
    this.setState({
      enabled: !appModule.enabled
    })
    this.updateAppModuleEnabled(appModule.id, !appModule.enabled)
  },

  deleteAppModule(appModule) {
    const { id } = appModule
    const { deleteAppModulesActions } = this.props
    KBPopoverConfirm({
      name: '删除自定义模块',
      context: `确定要删除该模块'${appModule.title}'?`,
      callback: () => {
        return api.deleteAppModule(id).then(
          json => {
            deleteAppModulesActions.success(json, { id, key: 'appModules' })
            KBPopover.close()
          },
          errors => {
            deleteAppModulesActions.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  updateAppModule(appModule) {
    const { loc_id } = this.props
    KBPopover.show(
      <ApplicationForm
        loc_id={loc_id}
        appModule={appModule}
        state="update"
        initialValues={appModule}
      />,
      {
        closeOnOutsideClick: false
      }
    )
  },

  createAppModule() {
    const { loc_id } = this.props
    KBPopover.show(
      <ApplicationForm
        loc_id={loc_id}
        initialValues={{
          category: 'member',
          app_type: 'h5',
          enabled: false
        }}
      />,
      {
        closeOnOutsideClick: false
      }
    )
  },

  renderAppModule(appModule, index) {
    return (
      <tr key={index}>
        <td>
          <div className="d-iblock" style={{ verticalAlign: 'middle' }}>
            <KBAvatar
              user={appModule}
              size={60}
              className="d-iblock"
              imgStyle={{ borderRadius: 0 }}
            />
          </div>
        </td>
        <td>
          {appModule.description ? (
            <KBTipsy content={appModule.description}>
              <span style={{ fontSize: 18 }}>{appModule.title}</span>
            </KBTipsy>
          ) : (
            <span style={{ fontSize: 18 }}>{appModule.title}</span>
          )}
        </td>
        {/* <td className="linkman">
          {appModule.entry_url ? (
            <KBTipsy content={appModule.entry_url}>
              <span className="app-module-url">{appModule.entry_url}</span>
            </KBTipsy>
          ) : (
            ''
          )}
        </td>
        <td>{constant.APPMODULES_TYPE_OBJ[appModule.app_type]}</td>
        <td>{constant.APP_MODULES_VISIBLE_TYPE[appModule.visible_type]}</td> */}
        <td>
          <div
            className="slide-btn"
            onClick={this.changeAppModuleEnabled.bind(null, appModule)}
          >
            <span
              className={`slide-bg ${
                appModule.enabled ? 'slide-left' : 'slide-right'
              }`}
            >
              <span className="slide-move" />
            </span>
          </div>
        </td>
        {/* <td>
          {appModule.system ? (
            ''
          ) : (
            <i
              className="iconfont icon-edit"
              onClick={this.updateAppModule.bind(null, appModule)}
              style={{ marginRight: 10 }}
            />
          )}
          {appModule.system ? (
            ''
          ) : (
            <i
              className="iconfont icon-delete"
              onClick={this.deleteAppModule.bind(null, appModule)}
            />
          )}
        </td> */}
      </tr>
    )
  },

  render() {
    const { appModules, loc_id, isManager } = this.props
    const { loading } = this.state

    // const membersAppModules = appModules.filter(
    //   json => json.category == 'member' && json.enabled == true
    // )
    // const organizationAppModules = appModules.filter(
    //   json => json.category == 'organization' && json.enabled == true
    // )
    // const disabledAppModules = appModules.filter(json => json.enabled == false)

    return (
      <section
        className={
          loc_id ? 'kb-content-container' : 'nav-section-container f-right'
        }
      >
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>应用模块</span>
            </div>
            {/* <div className="f-right">
              {loc_id && !isManager ? null : (
                <button className="bordered-btn" onClick={this.createAppModule}>
                  <i className="iconfont icon-add" />
                  添加自定义模块
                </button>
              )}
            </div> */}
          </div>
        </header>
        <div className="nav-section-content-container">
          <KBLoadingContainer loading={loading}>
            {/* <p style={{ marginTop: 15, marginBottom: 15 }}>
              增加或删除App内的应用模块以让会员获得相应功能。应用模块分为酷办“原生”模块和h5模块。酷办“原生”模块链接地址以"kuban://"开头。您也可以增加任何H5应用作为自定义模块。模块权限分为所有会员可见或只有公司负责人，财务负责人可见。未启用的模块或删除的模块将从App内工具栏目中删除。
            </p> */}
            {/* <div className="kb-title m-top">
              <span style={{ fontSize: 18 }}>会员应用</span>
            </div> */}
            <table className="content-table edit-table">
              <thead>
                <tr>
                  <th>Logo</th>
                  <th>标题</th>
                  {/* <th>链接</th>
                  <th>类型</th>
                  <th>可见性</th> */}
                  <th>启用</th>
                  {/* <th /> */}
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={appModules.length > 0}
                tableHeadNum="6"
                tipMessage="暂无会员应用"
              >
                {appModules &&
                  appModules.map((appModule, index) => {
                    // if (
                    //   appModule.title == '资讯' ||
                    //   appModule.title == '企业服务' ||
                    //   appModule.title == '我的团队' ||
                    //   appModule.title == '员工管理' ||
                    //   appModule.title == '空间服务' ||
                    //   appModule.title == '智能空调' ||
                    //   appModule.title == '智能照明' ||
                    //   appModule.title == '活动'
                    // ) {
                    //   return null
                    // }
                    return this.renderAppModule(appModule, index)
                  })}
              </KBTableWithoutData>
            </table>
            {/* <div className="kb-title m-top">
              <span style={{ fontSize: 18 }}>公司应用</span>
            </div>
            <table className="content-table edit-table">
              <thead>
                <tr>
                  <th>Logo</th>
                  <th>标题</th>
                  <th>链接</th>
                  <th>类型</th>
                  <th>可见性</th>
                  <th>启用</th>
                  <th />
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={organizationAppModules.length > 0}
                tableHeadNum="6"
                tipMessage="暂无公司应用"
              >
                {organizationAppModules &&
                  organizationAppModules.map((appModule, index) => {
                    if (
                      appModule.title == '资讯' ||
                      appModule.title == '企业服务' ||
                      appModule.title == '我的团队' ||
                      appModule.title == '员工管理' ||
                      appModule.title == '空间服务' ||
                      appModule.title == '智能空调' ||
                      appModule.title == '智能照明' ||
                      appModule.title == '活动'
                    ) {
                      return null
                    }
                    return this.renderAppModule(appModule, index)
                  })}
              </KBTableWithoutData>
            </table>
            <div className="kb-title m-top">
              <span style={{ fontSize: 18 }}>未启用</span>
            </div>
            <table className="content-table edit-table">
              <thead>
                <tr>
                  <th>Logo</th>
                  <th>标题</th>
                  <th>链接</th>
                  <th>类型</th>
                  <th>可见性</th>
                  <th>启用</th>
                  <th />
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={disabledAppModules.length > 0}
                tableHeadNum="6"
                tipMessage="暂无未启用应用"
              >
                {disabledAppModules &&
                  disabledAppModules.map((appModule, index) => {
                    if (
                      appModule.title == '资讯' ||
                      appModule.title == '企业服务' ||
                      appModule.title == '我的团队' ||
                      appModule.title == '员工管理' ||
                      appModule.title == '空间服务' ||
                      appModule.title == '智能空调' ||
                      appModule.title == '智能照明' ||
                      appModule.title == '活动'
                    ) {
                      return null
                    }
                    return this.renderAppModule(appModule, index)
                  })}
              </KBTableWithoutData>
            </table> */}
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const loc_id = params.id
  let appModules = selectors.getAppModules(state)
  const { user } = state
  const isManager = canAccessLocation(user, loc_id)
  appModules = appModules.map(json => {
    json.logo = json.icon
    json.name = json.title
    return json
  })

  // appModules.sort((a, b) => a.position - b.position)

  return {
    loc_id,
    isManager,
    appModules
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deleteAppModulesActions: bindActionCreators(
      actions.appModulesActions.delete,
      dispatch
    ),
    updateAppModulesActions: bindActionCreators(
      actions.appModulesActions.update,
      dispatch
    ),
    successAction: bindActionCreators(actions.successState, dispatch),
    getAppModulesActions: bindActionCreators(
      actions.appModulesActions.replace,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Applications)
