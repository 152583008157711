import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { api, apiUtils } from 'app/services'
import { faqFilesActions } from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBPopover
} from 'components'
import { connect } from 'react-redux'
import { OPPORTUNITY_STATES } from 'app/constants'
import * as selectors from 'app/selectors'
import moment from 'moment'
import { paginate } from 'utils/kbUtil'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import FaqUploadFileForm from './FaqUploadFileForm'
import * as constant from 'app/constants'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy'

var FaqDownload = React.createClass({
  displayName: 'FaqDownload',
  getInitialState() {
    return {
      faqFiles: [],
      pagination: null
    }
  },

  componentDidMount() {
    this._loadData(1)
  },

  _loadData(page) {
    const { getFaqFilesActions } = this.props
    const per_page = 30

    api.getFaqFiles({ page, per_page }).then(
      json => {
        let pagination = paginate(json.response)
        let faqFiles = getEntitiesJsonArray(json, 'faqFiles')
        getFaqFilesActions.success(json)

        this.setState({
          faqFiles,
          pagination
        })
      },
      errors => {
        getFaqFilesActions.failure(errors)
      }
    )
  },

  componentWillReceiveProps(nextProps) {
    if (
      this.props.page != nextProps.page ||
      (this.props.faqFiles.length &&
        nextProps.faqFiles.length - this.props.faqFiles.length == 1)
    ) {
      this._loadData(nextProps.page)
    }
  },

  createFile() {
    KBPopover.show(<FaqUploadFileForm />)
  },

  render() {
    const { faqFiles, pagination } = this.state

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span>常用下载</span>
          </div>
          <button className="bordered-btn f-right" onClick={this.createFile}>
            <i className="iconfont icon-add" />
            上传文档
          </button>
        </header>
        <div className="nav-section-content-container">
          <table className="content-table">
            <thead>
              <tr>
                <th>标题</th>
                <th>文档状态</th>
                <th>编辑时间</th>
                <th>下载</th>
              </tr>
            </thead>
            <tbody>
              {faqFiles.map((file, index) => {
                return (
                  <tr key={index}>
                    <td>{file.title}</td>
                    <td>{constant.FAQ_FILES_STATE_OBJ[file.state]}</td>
                    <td>{moment(file.updated_at).format('YYYY-MM-DD')}</td>
                    <td>
                      <KBDownloadPrivacy url={file.url}>
                        <a
                          className="add-btn a-hover"
                        >
                          下载
                        </a>
                      </KBDownloadPrivacy>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <KBPagination
            pagination={pagination}
            template={`/admin/faq/download?page=#PAGE#`}
          />
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const faqFiles = selectors.getFaqFiles(state)
  const { params, location } = props
  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page
  return {
    faqFiles,
    page
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getFaqFilesActions: bindActionCreators(faqFilesActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqDownload)
