import React, { PropTypes } from 'react'
import classNames from 'classnames'
import { KBPopoverTop, KBButton, KBInput } from 'components'
import _ from 'lodash'

const KBDropDownSpring = React.createClass({
  getInitialState() {
    const { field, value } = this.props
    const source = this.props.source || []
    return {
      inputValue: field.value || value || '',
      isHover: false,
      popoverValue: '',
      filedValues: [],
      isFocus: false,
      source: source,
      backupSource: source
    }
  },
  componentWillReceiveProps(nextProps) {
    if (this.props.source.length !== nextProps.source.length) {
      this.setState({
        source: nextProps.source
      })
    }
  },
  changeFields(row, e) {
    const { onChange, showValueField, multi, field } = this.props
    const { filedValues } = this.state
    let newFiledValues = _.cloneDeep(filedValues)
    if (!multi) {
      this.setState({
        inputValue: row[showValueField],
        isHover: false
      })

      if (onChange) {
        onChange(row)
        return
      }

      field && field.onChange(row.id)
    }

    if (multi) {
      if (newFiledValues.find(j => j.id === row.id)) {
        this.refs['kbDropDownSpring'].focus()
        return
      }
      newFiledValues.unshift(row)
      this.setState({
        filedValues: newFiledValues
      })

      this.changeMultiValues(newFiledValues)
      this.refs['kbDropDownSpring'].focus()
    }
  },
  changeMultiValues(filedValues) {
    const { onChange, field } = this.props
    if (onChange) {
      onChange(filedValues)
      return
    }

    field && field.onChange(filedValues.map(j => j.id).join(','))
  },
  rowRender() {
    const { heightScroll, isNew, showValueField, rowHeight } = this.props
    const { isHover, source } = this.state
    if (!isHover) {
      return
    }

    return (
      <div class="kb-selectt">
        {source.length ? (
          <div
            className="kb-select-box"
            style={{
              height: source.length
                ? source.length <= 4
                  ? rowHeight * source.length
                  : heightScroll
                : 0
            }}
          >
            {source.map(row => {
              return (
                <div
                  className="kb-select-row"
                  onClick={this.changeFields.bind(null, row)}
                >
                  {row[showValueField]}
                </div>
              )
            })}
          </div>
        ) : null}
        {isNew ? this.addRowRender() : null}
      </div>
    )
  },
  changePopoverValue(e) {
    const value = e.target.value
    this.setState({
      popoverValue: value
    })
  },
  newSelectRows() {
    const { newHint, showPopoverHint } = this.props
    KBPopoverTop.show(
      <div on>
        <header className="kb-form-header">
          <h2 className="header-title">{newHint}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopoverTop.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row kb-selectt-new">
            <div className="kb-form-group kb-form-alone">
              <input
                placeholder={showPopoverHint}
                onChange={this.changePopoverValue}
              />
            </div>
          </div>
          <div className="kb-row fixed-form-btn">
            <KBButton
              className="c-btn-secondary"
              onClick={() => {
                KBPopoverTop.close()
              }}
            >
              取消
            </KBButton>
            <KBButton
              className="certain-btn"
              onClick={this.addSelectRow}
              style={{ marginLeft: 20 }}
            >
              添加
            </KBButton>
          </div>
        </div>
      </div>
    )
  },
  async addSelectRow() {
    const { newDataCallBack } = this.props
    const { popoverValue, source, backupSource } = this.state
    if (!popoverValue) {
      KBPopoverTop.close()
      return
    }
    let newData = {
      id: popoverValue,
      value: popoverValue
    }
    if (newDataCallBack) {
      newData = await newDataCallBack(popoverValue)
    }
    let newSource = _.cloneDeep(source)
    let newBackupSource = _.cloneDeep(backupSource)
    newSource.unshift(newData)
    newBackupSource.unshift(newData)
    this.setState({
      source: newSource,
      backupSource: newBackupSource,
      popoverValue: ''
    })
    KBPopoverTop.close()
  },
  addRowRender() {
    const { heightScroll, newHint, rowHeight } = this.props
    const { source } = this.state

    return (
      <div
        className="kb-select-add"
        onClick={this.newSelectRows}
        style={{
          top:
            (source.length
              ? source.length <= 4
                ? rowHeight * source.length
                : heightScroll
              : 0) + 34
        }}
      >
        <i className="iconfont icon-add" alt={newHint} />
        <span>{newHint}</span>
      </div>
    )
  },
  mouseFocus() {
    this.setState({
      isHover: true,
      isFocus: true
    })
  },
  mouseBlur() {
    if (this.switchTNT) {
      return
    }
    this.setState({
      isFocus: false,
      isHover: false
    })
  },
  switchHover() {
    const { isHover } = this.state
    this.setState({
      isHover: !isHover
    })
  },
  mouseOut() {
    this.switchTNT = true
  },
  mouseOver() {
    this.switchTNT = false
  },
  cancelFiledValue(f) {
    const { filedValues } = this.state
    let newFiledValues = _.cloneDeep(filedValues)
    newFiledValues = newFiledValues.filter(n => n.id !== f.id)
    this.setState({
      filedValues: newFiledValues
    })
    this.changeMultiValues(newFiledValues)
  },
  renderMultiValues() {
    const { showValueField } = this.props
    const { filedValues, isFocus } = this.state
    return (
      <div
        className="kb-select-multi-box"
        style={isFocus ? { left: '20%' } : {}}
      >
        {filedValues.map(f => {
          return (
            <div className="kb-select-multi-row">
              <span
                className="kb-select-multi-r1"
                onClick={this.cancelFiledValue.bind(this, f)}
              >
                ×
              </span>
              <span className="kb-select-multi-r2">{f[showValueField]}</span>
            </div>
          )
        })}
      </div>
    )
  },
  changeInputValue(e) {
    const { showValueField } = this.props
    const value = e.target.value
    const { backupSource } = this.state
    let newSource = _.cloneDeep(backupSource)
    newSource = newSource.filter(n => n[showValueField].indexOf(value) !== -1)
    this.setState({
      source: newSource,
      inputValue: value
    })
  },
  render() {
    const {
      field,
      placeholder,
      multi,
      type,
      title,
      className,
      titleClass,
      inputClass,
      label,
      isRequired,
      isRequiredR,
      hint,
      disabled,
      inputStyle,
      errorStyle,
      fistFoucs,
      style,
      inputContainerStyle
    } = this.props
    const { inputValue } = this.state

    return (
      <div className={className} style={style}>
        {title && (
          <label className={titleClass}>
            {isRequiredR ? <span className="must-fillR">*</span> : ''}
            {title}
            {isRequired ? <span className="must-fill">*</span> : ''}
          </label>
        )}
        <div
          style={inputContainerStyle}
          className="kb-dropdown-box"
          onMouseEnter={this.mouseOut}
          onMouseLeave={this.mouseOver}
        >
          <input
            className={classNames(
              inputClass,
              field.touched && field.error ? 'kb-input-error' : 'kb-input'
            )}
            style={inputStyle}
            disabled={disabled ? 'disabled' : ''}
            type={type}
            placeholder={placeholder}
            value={inputValue}
            autoFocus={fistFoucs ? 'autofocus' : false}
            onFocus={this.mouseFocus}
            onBlur={this.mouseBlur}
            ref={'kbDropDownSpring'}
            onChange={this.changeInputValue}
          />
          {multi ? this.renderMultiValues() : null}
          <span className="Select-arrow-zone" onClick={this.switchHover}>
            <span className="Select-arrow"></span>
          </span>
          {this.rowRender()}
          {field.touched && field.error ? (
            <p className="lr-error" style={errorStyle}>
              {field.error}
            </p>
          ) : (
            <p style={{ color: '#c8c8c8', fontSize: 12, padding: '4px 0 0 0' }}>
              {hint}
            </p>
          )}
        </div>
      </div>
    )
  }
})

KBDropDownSpring.propTypes = {
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  newDataCallBack: PropTypes.func,
  source: PropTypes.array
}

KBDropDownSpring.defaultProps = {
  placeholder: '请选择',
  multi: false,
  heightScroll: 156,
  isNew: true,
  rowHeight: 36,
  showValueField: 'value',
  newHint: '添加',
  showPopoverHint: '名称'
}

export default KBDropDownSpring
