//添加新分店
export const ADD_LOCATION = {
  name: '输入分店名,如三元桥店',
  number: '内部的分店编号',
  tel: '输入外部联系电话号码',
  area: '分店总体面积平方米数',
  intro: '分店的简单介绍,外部可见',
  principal: '分店的总体负责人,如分店长或分店经理',
  staff: '分店的其他运营人员,可多选'
}

//添加新公司
export const ADD_ORGANIZATION = {
  full_name: '公司注册名称,与合同一致',
  short_name: '如"Capitaland Office"',
  intro: '公司的简单介绍,外部可见',
  remark: '添加备注信息,内部可见'
}

//添加新会员
export const ADD_ORGANIZATION_MEMBER = {
  principal: '只有公司负责人和财务负责人可以查看和支付公司账单',
  skills: '多个技能采用逗号分隔'
}

//添加新合同
export const ADD_SUBSCRIPTION = {
  valid_date: '修改内容在该指定日期以后生效',
  sign_date: '签订合同的日期',
  start_date: '合同开始生效日期',
  end_date: '合同终止日期',
  station_price: '单个工位的月会员费',
  month_price: '输入整月的总体金额',
  rent_day: '会员费周期开始日前一个月的XX日',
  sign: '只有正式签署的合同才会产生账单,待签字合同也可以安排会员入驻。',
  remark: '备注信息只有内部员工可见'
}

//添加新分区
export const ADD_AREA = {
  area: '分区是空间中不同位置的区域,如独立的办公区或同一位置的开放工位区',
  name: '可根据位置或者编号进行命名,如南1区,ROOM101',
  station_num: '工位号只能是数字或区间,如8,15-20'
}

//新建报修
export const ADD_TASK = {
  title: '可以输入报修的具体情况',
  team: '只有公司成员及其上级可查看保修内容,所有公司成员可以收到更新通知',
  principal: '当前负责人可以指派新的负责人',
  participator: '参与者可多选'
}

//修改分店
export const FIX_LOCATION = {
  manage_team: '为分店的运营公司及负责人',
  staff: '为分店的运营人员,可多选',
  finance: '为分店的财务人员,可多选',
  paid_checker: '可对分店财务账单进行“核销”操作的人员,可多选',
  confirmed_checker: '可对分店财务账单进行“确认核销”操作的人员,可多选',
  observer: '为分店的观察人员,可多选',
  sales: '为分店的招商人员,可多选',
  repair: '为分店的物业人员,可多选',
  contract: '为合同的审批人员,可多选'
}

//添加新员工
export const ADD_STAFF = {
  superior: '为该员工直属上级,有权查看该员工情况',
  permission_settings:
    '仅选择当前空间设置开放的权限，权限为全体人员则默认有效，所以不提供选择'
}

//添加新客户
export const ADD_CLIENT = {
  name: '可录入公司全称、简称、客户名字,如顶峰科技、马女士',
  market_stage: '可根据客户销售情况,选择对应的销售阶段',
  market_record: '可录入与客户沟通的情况,用户的需求',
  market_team:
    '只有团队成员及其上级可查看销售内容,所有团队成员可以收到更新通知',
  principal: '当前负责人可以指派新的负责人',
  team_member: '参与者可多选',
  remark: '备注信息内部可见'
}

//安全设置
export const SAFE_SETTING = {
  manager: '空间管理员可以修改后台所有信息，可多选',
  staff: '指定员工可多选'
}

//合同审批人配置
export const APPROVE_CONTRACT = {
  approve: '当合同输入之后，必须经过审批人之一同意方才生效',
  approver: '审批人可多选'
}

//添加服务
export const ADD_SERVICE = {
  list_price: '输入具体价格，年服务费或其他报价方式',
  member_price: '输入优惠价格',
  conditions: '输入优惠条件'
}

//添加付款信息
export const AA_PAYMENT_INFORMATION = {
  collect_amount: '输入负数代表退款金额'
}

export const APPLICATION_FORM = {
  name: '输入模块名称，不超过5个字',
  url: '输入H5网址(以http://或https://开头）'
  // 酷办App原生地址（以kuban://开头）'
}

export const ANNOUNCEMENT_FORM = {
  name: '输入公告标题',
  url: '输入文章网址(以http://或https://开头）'
}

export const SPACE_FORM = {
  short_name: '简短的空间名,如Capitaland Office空间',
  full_name: '注册公司名称，如上海Capitaland Office科技有限公司',
  contact_phone: '对外客服联系电话',
  one_liner: '一句话对用户介绍您的空间，不超过100字',
  devices_list: '多个设备已","分隔'
}

export const LOCK_SETTING_FORM = {
  bind_card: '用户是否可以使用App客户端绑定IC卡',
  set_lock_password: '用户是否可以使用App客户端设置独有门禁密码',
  display_bluetooth: 'App是否显示蓝牙门禁'
}

// 税率设置
export const TAXES_FORM = {
  rate: '输入百分数数字部分，如3.5%输入3.5'
}

export const SPACE_CURRENCY = {
  rate: '钱与虚拟币的兑换比例(1币=x元)'
}

export const POINT_EVENT = {
  time: '事件发生次数, 规定次数后，每天时间发生到第多少次用户将会获得虚拟货币',
  point: '事件发生后获得的虚拟货币数'
}

// 活动
export const ACTIVITIES_CREATE_FORM = {
  tag: '请填写活动标签',
  start_at: '例:  09:00',
  end_at: '例:  12:00',
  ticket: '如免费请输入票价0'
}

//修改项目
export const FIX_GROUP = {
  manage_team: '为项目的运营公司及负责人',
  staff: '为项目的运营人员,可多选'
}

export const EXPORT_HINT = {
  concat_admin: '如需导出更多数据，请致电Helpdesk 400-810-8887'
}
