import { Schema, arrayOf, normalize } from 'normalizr'

export const userSchema = new Schema('users')
export const userSchemaArray = arrayOf(userSchema)

export const spaceMemberSchema = new Schema('members')
export const spaceMemberSchemaArray = arrayOf(spaceMemberSchema)

export const spaceSchema = new Schema('spaces')

//groups
export const groupsSchema = new Schema('groups')
export const groupsSchemaArray = arrayOf(groupsSchema)

export const locationSchema = new Schema('locations')
export const locationSchemaArray = arrayOf(locationSchema)

export const serializeLocationsSchema = new Schema('serializeLocations')
export const serializeLocationsSchemaArray = arrayOf(serializeLocationsSchema)

export const departmentSchema = new Schema('departments')
export const departmentSchemaArray = arrayOf(departmentSchema)
export const customerSchema = new Schema('customers')
export const customerSchemaArray = arrayOf(customerSchema)

export const contactsSchema = new Schema('contacts')
export const contactsSchemaArray = arrayOf(contactsSchema)

export const leadSchema = new Schema('leads')
export const leadSchemaArray = arrayOf(leadSchema)

// opportunity
export const opportunitySchema = new Schema('opportunities')
export const opportunitySchemaArray = arrayOf(opportunitySchema)

export const intentAreasSchema = new Schema('intentAreas')
export const intentAreasSchemaArray = arrayOf(intentAreasSchema)

// crmRecord
export const crmRecordSchema = new Schema('crmRecords')

export const crmRecordSchemaArray = arrayOf(crmRecordSchema)

// crmComment
export const crmCommentSchema = new Schema('crmComments')
export const crmCommentSchemaArray = arrayOf(crmCommentSchema)

// FAQ分类列表
export const sectionsSchema = new Schema('sections')
export const sectionsSchemaArray = arrayOf(sectionsSchema)

// 文章列表
export const topicsSchema = new Schema('topics')
export const topicsSchemaArray = arrayOf(topicsSchema)

// 文件列表
export const faqFilesSchema = new Schema('faqFiles')
export const faqFilesSchemaArray = arrayOf(faqFilesSchema)

// 分店房屋楼层列表
export const FloorInfosSchema = new Schema('floorInfos')
export const FloorInfosSchemaArray = arrayOf(FloorInfosSchema)

// 分店大楼列表
export const BuildingInfosSchema = new Schema('buildingInfo')
export const BuildingInfosSchemaArray = arrayOf(BuildingInfosSchema)

/**
 * 会议室
 */
export const meetingRootsSchema = new Schema('meetingRoots')
export const meetingRootsSchemaArray = arrayOf(meetingRootsSchema)

/**
 * 平面图 Schema
 * @type {Schema}
 */
export const floorPlansSchema = new Schema('floorplans')
export const floorPlansSchemaArray = arrayOf(floorPlansSchema)

/**
 * 会议室设置
 */
export const meetingSettingSchema = new Schema('meetingSetting')

// 分店会议室设置
export const meetingLocationSettingSchema = new Schema('meetingLocationSetting')

/**
 * 预定会议室
 * @type {Schema}
 */
export const reservationsSchema = new Schema('reservations')
export const reservationsSchemaArray = arrayOf(reservationsSchema)

/**
 * 会议室
 */
export const meetingDevicesSchema = new Schema('meetingDevices')
export const meetingDevicesSchemaArray = arrayOf(meetingDevicesSchema)

// 公告 Schemas
export const announcementsSchema = new Schema('announcements')
export const announcementsSchemaArray = arrayOf(announcementsSchema)

// tasks
export const taskSchema = new Schema('tasks')
export const taskSchemaArray = arrayOf(taskSchema)

/**
 * crm 来源
 * @param {[type]} crmSources [客户来源]
 */
export const crmSourcesSchema = new Schema('crmSources')
export const crmSourcesSchemaArray = arrayOf(crmSourcesSchema)

/**
 * 设备
 * @type {Schema}
 */
export const devicesSchema = new Schema('devices')
export const devicesSchemaArray = arrayOf(devicesSchema)

// tasks
export const invoicesSchema = new Schema('invoices')
export const invoicesSchemaArray = arrayOf(invoicesSchema)

export const citySchema = new Schema('cities')
export const citySchemaArray = arrayOf(citySchema)

export const provinceSchema = new Schema('provinces')
export const provinceSchemaArray = arrayOf(provinceSchema)

export const salesPlanSchema = new Schema('sales_plans')

// base
export const industriesSchema = new Schema('industries')
export const industriesSchemaArray = arrayOf(industriesSchema)

// subscriptions
export const subscriptionSchema = new Schema('subscriptions')
export const subscriptionSchemaArray = arrayOf(subscriptionSchema)

// subscriptionLineItemRefundType
export const subscriptionLineItemRefundTypeSchema = new Schema(
  'subscriptionLineItemRefundType'
)
export const subscriptionLineItemRefundTypeSchemaArray = arrayOf(
  subscriptionLineItemRefundTypeSchema
)

export const organizationSchema = new Schema('organizations')
export const organizationSchemaArray = arrayOf(organizationSchema)

// organization members
export const orgMembersSchema = new Schema('orgMembers')
export const orgMembersSchemaArray = arrayOf(orgMembersSchema)

export const organizationNoteSchema = new Schema('organizationNotes')
export const organizationNoteSchemaArray = arrayOf(organizationNoteSchema)

export const areaSchema = new Schema('areas')
export const areaSchemaArray = arrayOf(areaSchema)

export const simpleAreaSchema = new Schema('simpleAreas')
export const simpleAreaSchemaArray = arrayOf(simpleAreaSchema)

export const deskSchema = new Schema('desks')
export const deskSchemaArray = arrayOf(deskSchema)

export const raisingsSchema = new Schema('raisings')
export const raisingsSchemaArray = arrayOf(raisingsSchema)

// 公司入驻是否通过待办事项 设置
export const orgEnterSettingSchema = new Schema('orgEnterSetting')

export const lockEventsSchema = new Schema('lockEvents')
export const lockEventsSchemaArray = arrayOf(lockEventsSchema)

export const lockPermissionsSchema = new Schema('lockPermissions')
export const lockPermissionsSchemaArray = arrayOf(lockPermissionsSchema)

export const serviceRequestCommentsSchema = new Schema('serviceRequestComments')
export const serviceRequestCommentsSchemaArray = arrayOf(
  serviceRequestCommentsSchema
)

// 合同模板
export const subscriptionTempletesSchema = new Schema('subscriptionTempletes')
export const subscriptionTempletesSchemaArray = arrayOf(
  subscriptionTempletesSchema
)

// 操作记录列表
export const auditLogsSchema = new Schema('auditLogs')
export const auditLogsSchemaArray = arrayOf(auditLogsSchema)

/**
 * 门禁
 */
export const locksSchema = new Schema('locks')
export const locksSchemaArray = arrayOf(locksSchema)

/**
 * 服务商
 */
export const serviceProvidersSchema = new Schema('serviceProviders')
export const serviceProvidersSchemaArray = arrayOf(serviceProvidersSchema)

/**
 * 服务
 */
export const servicesSchema = new Schema('services')
export const servicesSchemaArray = arrayOf(servicesSchema)

/**
 * 服务请求订单
 */
export const serviceRequestsSchema = new Schema('serviceRequests')
export const serviceRequestsSchemaArray = arrayOf(serviceRequestsSchema)

/**
 * [serviceCategoriesSchema 服务分类]
 * @type {Schema}
 */
export const serviceCategoriesSchema = new Schema('serviceCategories')
export const serviceCategoriesSchemaArray = arrayOf(serviceCategoriesSchema)

/**
 * 服务分类子分类
 */
export const subCategoriesSchema = new Schema('subCategories')
export const subCategoriesSchemaArray = arrayOf(subCategoriesSchema)

/**
 * 退租和入驻待办事项
 */
export const leaveOrEnterOptionsSchema = new Schema('leaveOrEnterOptions')
export const leaveOrEnterOptionsSchemaArray = arrayOf(leaveOrEnterOptionsSchema)

/**
 * App 应用模块
 */
export const appModulesSchema = new Schema('appModules')
export const appModulesSchemaArray = arrayOf(appModulesSchema)

/**
 * 消息列表
 */
export const messageListSchema = new Schema('messageLists')
export const messageListSchemaArray = arrayOf(messageListSchema)

// 报表中心
export const reportListSchema = new Schema('reportLists')
export const reportListSchemaArray = arrayOf(reportListSchema)

// 会员等级
export const membershipsSchema = new Schema('memberships')
export const membershipsSchemaArray = arrayOf(membershipsSchema)

// 会员等级购买列表
export const membershipsOrdersSchema = new Schema('membershipsOrders')
export const membershipsOrdersSchemaArray = arrayOf(membershipsOrdersSchema)

// 会员制资源列表
export const membershipResourcesSchema = new Schema('membershipResources')
export const membershipResourcesSchemaArray = arrayOf(membershipResourcesSchema)

// 会议室资源购买列表
export const membershipResourcesOrdersSchema = new Schema(
  'membershipResourcesOrders'
)
export const membershipResourcesOrdersSchemaArray = arrayOf(
  membershipResourcesOrdersSchema
)

// 积分计划列表
export const pointPlansSchema = new Schema('pointPlans')
export const pointPlansSchemaArray = arrayOf(pointPlansSchema)

// 积分购买列表
export const pointPlansOrderSchema = new Schema('pointPlansOrder')
export const pointPlansOrderSchemaArray = arrayOf(pointPlansOrderSchema)

// 商品
export const salesProductSchema = new Schema('salesProducts')
export const salesProductSchemaArray = arrayOf(salesProductSchema)

// 商城
export const shopSchema = new Schema('shops')
export const shopSchemaArray = arrayOf(shopSchema)

// 商品分类
export const productCategorieSchema = new Schema('productCategories')
export const productCategorieSchemaArray = arrayOf(productCategorieSchema)

// 商品库存
export const productInventorieSchema = new Schema('productInventories')
export const productInventorieSchemaArray = arrayOf(productInventorieSchema)

// 税率
export const taxesSchema = new Schema('taxes')
export const taxesSchemaArray = arrayOf(taxesSchema)

// 自定义账单类型
export const customSchema = new Schema('self_defined_line_item_types')
export const customSchemaArray = arrayOf(customSchema)

// 自定义动态字段
export const dynamicAttributesSchema = new Schema('dynamicAttributes')
export const dynamicAttributesSchemaArray = arrayOf(dynamicAttributesSchema)

// 活动模块
export const activitiesSchema = new Schema('activities')
export const activitiesSchemaArray = arrayOf(activitiesSchema)

export const activitiesOrdersSchema = new Schema('activitiesOrders')
export const activitiesOrdersSchemaArray = arrayOf(activitiesOrdersSchema)

export const activitiesCommentsSchema = new Schema('activitiesComments')
export const activitiesCommentsSchemaArray = arrayOf(activitiesCommentsSchema)

// 新闻资讯模块
export const newsSchema = new Schema('news')
export const newsSchemaArray = arrayOf(newsSchema)

// 分店打印机管理
export const printersSchema = new Schema('printers')
export const printersSchemaArray = arrayOf(printersSchema)

// 分店组
export const locationGroupsSchema = new Schema('locationGroups')
export const locationGroupsSchemaArray = arrayOf(locationGroupsSchema)

// 门禁组
export const lockGroupsSchema = new Schema('lockGroups')
export const lockGroupsSchemaArray = arrayOf(lockGroupsSchema)

// 发票库
export const invoicingPoolSchema = new Schema('invoicingPools')
export const invoicingPoolSchemaArray = arrayOf(invoicingPoolSchema)

// 写字楼楼层
export const locationFloorsSchema = new Schema('locationFloors')
export const locationFloorsSchemaArray = arrayOf(locationFloorsSchema)

serviceCategoriesSchema.define({
  sub_categories: subCategoriesSchemaArray
})

BuildingInfosSchema.define({
  // floor_infos : FloorInfosSchemaArray
})

leaveOrEnterOptionsSchema.define({
  organization: organizationSchema
})

departmentSchema.define({
  children: departmentSchemaArray,
  space: spaceSchema
})

userSchema.define({
  departments: departmentSchemaArray,
  space: spaceSchema,
  desk: deskSchema
})

locationSchema.define({
  manager: userSchema,
  operators: userSchemaArray,
  sales_plan: salesPlanSchema,
  city: citySchema
})

crmRecordSchema.define({
  customer: customerSchema,
  opportunity: opportunitySchema,
  created_by: userSchema
})

// 储物柜规格
export const lockerCategoriesSchema = new Schema('lockerCategories')
export const lockerCategoriesSchemaArray = arrayOf(lockerCategoriesSchema)

// 储物柜
export const lockersGroupsSchema = new Schema('lockers')
export const lockersGroupsSchemaArray = arrayOf(lockersGroupsSchema)

// 储物柜预定列表
export const lockerReservationsSchema = new Schema('lockerReservations')
export const lockerReservationsSchemaArray = arrayOf(lockerReservationsSchema)
