import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBPopover,
  KBPopoverTop,
  KBQiniuFileUpload,
  KBTipsy
} from 'components'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle
} from 'react-sortable-hoc'
import classNames from 'classnames'
import { PopoverShowPicture } from 'components/views'
import config from 'app/config'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy'

const ServiceDragHandle = SortableHandle(() => <div className="sort-handle" />)

const SortableItem = SortableElement(
  ({ item, imgStyle, removeFile, indexValue, img_width, img_height }) => {
    return (
      <li className="sort-image">
        <img src={item} alt="loading" style={imgStyle} />
        <div className="delete">
          <i
            className="iconfont icon-close"
            onClick={e => {
              removeFile(indexValue)
              e.stopPropagation()
            }}
          />
        </div>
        <ServiceDragHandle />
      </li>
    )
  }
)

const SortableList = SortableContainer(
  ({ items, imgStyle, removeFile, img_width, img_height }) => {
    return (
      <ul className="kb-more-file">
        {items.map((file, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              indexValue={index}
              item={file}
              imgStyle={imgStyle}
              removeFile={removeFile}
              img_width={img_width}
              img_height={img_height}
            />
          )
        })}
      </ul>
    )
  }
)

var InvoiceCertPhotoUpload = React.createClass({
  getInitialState() {
    const { defaultImages, prefix } = this.props
    return {
      files: [],
      uploading: false,
      prefix: prefix ? prefix : config.uploadDir + 'invoice/',
      uploadProgress: null,
      uploaded: false,
      uploadFileUrl: null,
      fileList: defaultImages || []
    }
  },

  getDefaultProps() {
    return {
      baseUrl: config.qiniu_base_url,
      photoShow: true,
      img_width: 100,
      img_height: 100
    }
  },

  changeField(value) {
    this.props.field.onChange(value)
  },

  onUpload: function(files) {
    // set onprogress function before uploading
    this.setState({
      uploading: true,
      uploaded: false,
      uploadProgress: 0,
      uploadFileUrl: null
    })
    var _this = this
    files.map(function(f) {
      f.onprogress = function(e) {
        _this.setState({ uploadProgress: e.percent })
      }
    })
  },

  onDrop: function(files) {
    const { messageCallback, getURLCallback, isPublic } = this.props
    const { fileList } = this.state
    var temp = Object.assign([], fileList)
    this.setState({
      files: files
    })
    // files is a FileList(https://developer.mozilla.org/en/docs/Web/API/FileList) Object
    // and with each file, we attached two functions to handle upload progress and result
    // file.request => return super-agent uploading file request
    // file.uploadPromise => return a Promise to handle uploading status(what you can do when upload failed)
    // `react-qiniu` using bluebird, check bluebird API https://github.com/petkaantonov/bluebird/blob/master/API.md
    // see more example in example/app.js
    var _this = this
    files.map(f => {
      f.uploadPromise.then(response => {
        // let url = isPublic ? response.url : (this.state.showUrl + response.body.key)
        let url = response.url

        temp.push(url)

        _this.setState({
          uploading: false,
          uploaded: true,
          uploadFileUrl: url,
          fileList: temp
        })

        _this.changeField(temp.join(','))

        getURLCallback && getURLCallback(url)
      })
    })
    messageCallback && messageCallback(files)
  },

  setUrlAndToken({ domain, token, upload_url }) {
    this.setState({
      uploadToken: token,
      showUrl: domain,
      baseUrl: upload_url
    })
  },

  componentDidMount() {
    const { isAzureUpload, azure_url } = config

    if (isAzureUpload) {
      this.setState({
        showUrl: azure_url
      })
      return
    }
    // load locations
    api.getUploadToken().then(({ json }) => {
      this.setUrlAndToken(json)
    })
  },

  _reset() {
    this.setState({ uploading: false, uploaded: false, uploadFileUrl: null })
  },

  updateProgress(percent) {
    this.setState({ uploadProgress: percent })
  },

  renderProgress() {
    return this.state.uploading ? (
      <div
        className={classNames(
          this.props.buttonClass ? this.props.buttonClass : 'post-btn-ing'
        )}
      >
        <div
          className="post-btn-progress"
          style={{ width: this.state.uploadProgress + '%' }}
        ></div>
        <div className="post-btn-content">
          <span>{this.props.uploadingText || '正在上传'}</span>
        </div>
      </div>
    ) : null
  },

  renderUploaded() {
    if (this.state.uploaded) {
      return (
        <div className="post-btn-success">
          <div className="post-btn-progress" />
          <div className="post-btn-content">
            <span>{this.props.uploadedText || '上传完毕'}</span>
          </div>
          <a onClick={this._reset}>×</a>
        </div>
      )
    }
    return null
  },

  renderUploadField() {
    const { multiple, field, hint, isPublic } = this.props
    const { fileList, prefix } = this.state
    const canUpload = field.pristine || multiple

    return (this.state.uploadToken || config.isAzureUpload) &&
      !this.state.uploading ? (
      <KBQiniuFileUpload
        supportClick={canUpload ? true : false}
        uploadUrl={this.state.baseUrl}
        prefix={prefix}
        onDrop={this.onDrop}
        updateProgress={this.updateProgress}
        multiple={multiple}
        token={this.state.uploadToken}
        onUpload={this.onUpload}
        accept=".png,.jpeg,.jpg"
        isPublic={isPublic}
      >
        <div
          className={classNames(
            this.props.buttonClass ? this.props.buttonClass : 'upload-container'
          )}
        >
          {canUpload ? (
            <div className="img-add">
              <i className="iconfont icon-add" />
              <span className="text">
                {!field.pristine && multiple ? '点击继续上传' : '点击上传'}
              </span>
            </div>
          ) : (
            <div className="img-upload-success">
              <i className="iconfont icon-Uploadsuccess" />
              <span className="text">上传成功</span>
            </div>
          )}
          <div className="post-btn-progress"></div>
        </div>
        {field.touched && field.error ? (
          <p className="lr-error">{field.error}</p>
        ) : (
          <p style={{ color: '#c8c8c8', fontSize: 12, padding: '5px 0' }}>
            {field.pristine ? (
              hint
            ) : fileList && fileList.length > 0 ? (
              <div>
                {fileList.map((file, index) => {
                  let fileNameArr = file.split('/')
                  let fileName =
                    fileNameArr.length > 0
                      ? fileNameArr[fileNameArr.length - 1]
                      : ''
                  return (
                    <div>
                      文件名：
                      <KBDownloadPrivacy url={file}>
                        <a
                          style={{ color: '#2ea1f8' }}
                        >
                          {fileName}
                        </a>
                      </KBDownloadPrivacy>
                      <KBTipsy content="删除">
                        <i
                          className="iconfont icon-delete"
                          style={{ cursor: 'pointer', marginLeft: 5 }}
                          onClick={e => {
                            e.stopPropagation()
                            this.removeFile(multiple ? index : 'reupload')
                          }}
                        />
                      </KBTipsy>
                    </div>
                  )
                })}
              </div>
            ) : null}
          </p>
        )}
      </KBQiniuFileUpload>
    ) : null
  },

  removeFile(index) {
    const { fileList } = this.state

    if (index == 'reupload') {
      this.setState({
        fileList: []
      })
      this.changeField('')
      return
    }

    var tempFile = Object.assign([], fileList)
    tempFile.splice(index, 1)

    this.setState(
      Object.assign({}, this.state, {
        fileList: tempFile
      })
    )

    this.changeField(tempFile.join(','))
  },

  onSortEnd({ oldIndex, newIndex }) {
    var fileList = this.state.fileList
    const newFilterList = arrayMove(fileList, oldIndex, newIndex)
    this.setState({
      fileList: newFilterList
    })
    this.changeField(newFilterList.join(','))
  },

  enlargeImages(url) {
    KBPopoverTop.show(<PopoverShowPicture photoUrl={url} isPopoverTop={true} />)
  },

  render() {
    const {
      className,
      titleClass,
      title,
      photoShow,
      field,
      hint,
      isRequired,
      imgStyle,
      sort,
      outerStyle,
      enlarge,
      img_width,
      img_height,
      preView
    } = this.props
    const { fileList } = this.state

    return (
      <div className={className} style={outerStyle}>
        {title && (
          <label className={titleClass}>
            {title}
            {isRequired ? <span className="must-fill">*</span> : ''}
          </label>
        )}
        {this.renderUploadField()}
        {this.renderProgress()}
        {sort ? (
          <SortableList
            items={fileList}
            onSortEnd={this.onSortEnd}
            imgStyle={imgStyle}
            axis="xy"
            img_width={img_width}
            img_height={img_height}
            useDragHandle={true}
            removeFile={this.removeFile}
          />
        ) : (
          photoShow && (
            <ul className="">
              {preView
                ? fileList.map((file, index) => {
                    return (
                      <li
                        className="img-show"
                        key={index}
                        onClick={
                          enlarge ? this.enlargeImages.bind(null, file) : ''
                        }
                        style={enlarge ? { cursor: 'pointer' } : {}}
                      >
                        <img src={file} alt="loading" style={imgStyle} />
                        <div className="delete">
                          <i
                            className="iconfont icon-close"
                            onClick={this.removeFile.bind(null, index)}
                          />
                        </div>
                      </li>
                    )
                  })
                : null}
            </ul>
          )
        )}
      </div>
    )
  }
})

InvoiceCertPhotoUpload.propTypes = {
  field: PropTypes.object.isRequired,
  photoShow: PropTypes.bool.isRequired,
  className: PropTypes.string,
  prefix: PropTypes.string,
  multiple: PropTypes.bool,
  text: PropTypes.string.isRequired,
  uploadingText: PropTypes.string,
  uploadedText: PropTypes.string
}

export default InvoiceCertPhotoUpload
