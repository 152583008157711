import { KBPopover } from 'components'
let isStop = false
export default (data = {}) => {
  const { name, context, callback } = data
  KBPopover.plugins.confirm(name, context, {
    confirm: () => {
      // if (isStop == true) {
      //   return
      // }
      isStop = true
      let resolve = callback()
      resolve &&
        resolve.then(json => {
          isStop = false
        })
    }
  })
}
