import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router'
import ReactDOM from 'react-dom'
import { api, apiUtils } from 'app/services'
import {
  opportunitiesActions,
  opportunityInfoActions,
  apiCallFailure,
  successState
} from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { checkObject, getFilterAndQuery, queryFilter } from 'utils/kbUtil'
import {
  KBLoadingContainer,
  KBPopover,
  KBRangeDate,
  KBPagination,
  KBDropdown,
  KBButton,
  KBTableWithoutData,
  KBTipsy,
  KBAvatar
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { KBDropSelect } from 'components/plugins'
import {
  paginate,
  util,
  formatHoursMinutesEN,
  kbCloseDropDown
} from 'utils/kbUtil'
import moment from 'moment'
import * as selectors from 'app/selectors'
import {
  OPPORTUNITY_STATES,
  CRM_USER_TYPE_STAT,
  OPPORTUNITY_STATES_CLASS,
  CRM_OPPORTUNITY_STATE,
  CRM_OPPORTUNITY_DESIRES,
  CRM_USER_TYPE_STATUS,
  CRM_FAILED_REASON_OBJ
} from 'app/constants'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import { getInitialDate } from 'utils/kbData'
import { canAccessSpaceRoles } from 'app/reducers/role'
import CrmOppReportForm from './CrmOppReportForm'
import OrganizationForm from '../organization/OrganizationForm'
import { getUserInfoUrl } from 'utils/kbUrl'

const DROP_DOWNS = [
  'locationsDropdown',
  'userTypeDropdown',
  'participantDropDown',
  'oppStateDropdown',
  'sourceDropDown',
  'oppDesireDropdown',
  'createDateDropDown',
  'updateDateDropDown',
  'followDateDropDown'
]

var tableHeader = [
  {
    name: '客户名称',
    state: 'off'
  },
  // {
  //   name  : '意向工位数',
  //   state : 'off'
  // },
  {
    name: '客户来源',
    state: 'off'
  },
  {
    name: '状态',
    state: 'off'
  },
  // {
  //   name  : '感兴趣分店',
  //   state : 'off'
  // },
  {
    name: '当前执行人',
    state: 'off'
  },
  // {
  //   name  : '最近更新',
  //   key   : 'followed_at',
  //   state : 'empty'
  // },
  {
    name: '创建时间',
    state: 'off'
  },
  {
    name: '更新时间',
    state: 'off'
  },
  {
    name: '更多信息',
    state: 'off'
  }
  /*{
    name  : '赢单分店',
    state : 'win'
  },
  {
    name : '合同状态',
    state : 'off'
  },*/
  // {
  //   name : '合同',
  //   state : 'off'
  // }
]

var CrmOpportunityList = React.createClass({
  displayName: 'CrmOpportunityList',
  getInitialState() {
    const { filters } = this.props
    return {
      opportunities: [],
      filters,
      page: this.props.page || 1,
      queryFiltersStr: '',
      dropDownState: false,
      customer: [],
      value: '',
      loading: false
    }
  },
  componentDidMount() {
    const { queryFilters, filters } = this.props
    this.mounted = true

    if (filters.crmQuery) {
      ReactDOM.findDOMNode(this.refs.queryOpp).value = filters.crmQuery
    }

    this._refresh()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _refresh(key, filters) {
    let dropDown = key + 'Dropdown'
    const { page } = this.props
    this[dropDown] && this[dropDown].hide()

    // force refresh
    setTimeout(() => {
      this._loadData(page, this.state.filters)
    })
  },

  // 解析在数据加载时的 from to
  parseLoadFromTo(params, from, to) {
    delete params.from
    delete params.to

    params.start_time = from ? getInitialDate(from).initStartDateString : null
    params.end_time = to ? getInitialDate(to).initEndDateString : null

    return params
  },

  // 加载数据
  _loadData(newPage, newfilters) {
    const per_page = 10
    const { user, getOpportunity, routerActions, query } = this.props
    const { page, filters } = this.state
    let params = Object.assign({}, filters)
    let { from, to } = params
    var isFilters = filters && Object.keys(filters).length
    let queryFiltersStr = queryFilter({
      ...query,
      ...params,
      page: newPage || page
    })

    // 判断日期
    params = this.parseLoadFromTo(params, from, to)

    if (params.crmQuery) {
      params.query = params.crmQuery
    }

    if (params.user_id) {
      params.user_type = params.user_id
    }

    params.order_by = 'updated_at'
    params.order_type = 'desc'

    this.setState({ loading: true })
    api
      .getOpportunities({ ...params, page: newPage || page })
      .then(
        json => {
          let pagination = paginate(json.response)
          let opportunities = getEntitiesJsonArray(json, 'opportunities')
          if (this.mounted) {
            this.setState({ pagination, opportunities: opportunities || [] })
            getOpportunity.success(json.json, { key: 'opportunities' })
            routerActions.replace(`/admin/crm/opportunities${queryFiltersStr}`)
          }
        },
        errors => {
          getOpportunity.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.page !== this.props.page ||
      (nextProps.opps.length !== this.props.opps.length &&
        nextProps.opps.length != this.state.opportunities.length)
    ) {
      this._loadData(nextProps.page, {})
    }
  },

  createOpp() {
    const { user, sources, apiCallFailureActions, routerActions } = this.props
    const only_contact = 'false'
    if (!sources.length) {
      apiCallFailureActions({
        status: 'error',
        message: '必须先添加CRM来源!'
      })
      return
    }

    // KBPopover.show(<OpportunityForm initialValues={{ only_contact }} u_id = { user.id }  />)
    routerActions.push(`/admin/crm/opportunities/new`)
  },

  deleteOpp(opp) {
    const { id, title } = opp
    const { deleteOpportunity, user, apiCallFailureActions } = this.props
    const { opportunities } = this.state

    if (opp.state == 'success') {
      return apiCallFailureActions({
        status: 'error',
        message: '赢单客户不能删除!'
      })
    }

    KBPopoverConfirm({
      name: '删除客户',
      context: '是否要删除客户 ' + title + ' !',
      callback: () => {
        return api.deleteOpportunity(id).then(
          json => {
            // deleteOpportunity.success(json, {id, key : 'opportunities'})
            let deletedOpp = (json.response && json.response.body) || {}
            let newOpps = opportunities.filter(data => {
              return data.id != deletedOpp.id
            })
            this.setState({
              opportunities: newOpps
            })
            KBPopover.close()
            return true
          },
          error => {
            deleteOpportunity.failure(error)
            KBPopover.close()
          }
        )
      }
    })
  },

  setFilter(obj) {
    const { page } = this.props
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({
      filters: newFilters,
      page: 1
    })
    this.__refresh()
  },

  __refresh() {
    this.userTypeDropdown.hide()
    setTimeout(this._loadData)
  },

  selectCallBack(data) {
    this.setFilter({
      state: data.map(json => json.id).join(',')
    })
  },

  selectDesireCallBack(data) {
    this.setFilter({
      desires: data.map(json => json.id).join(',')
    })
  },

  userSelectCallBack(data) {
    this.setFilter({
      user_id: data.id
    })
  },

  userSourceCallBack(data) {
    this.setFilter({
      sources: data.map(json => json.id).join(',')
    })
  },

  selectLocationsCallback(data) {
    this.setFilter({
      location_ids: data.map(json => json.id).join(',')
    })
  },

  clearFilter() {
    const { page } = this.props

    ReactDOM.findDOMNode(this.refs.queryOpp).value = ''
    this.setState({ filters: {}, page: 1 })
    this.__refresh()
  },

  toOppInfo(opp) {
    const { routerActions } = this.props
    if (!opp.obfuscate) {
      routerActions.push(`/admin/crm/opportunities/${opp.id}`)
    }
  },

  clearFilters() {
    this.clearFilter()
    this.oppStateDropdown.clearData()
    this.locationsDropdown.clearData()
    this.participantDropDown.clearData()
    this.sourceDropDown.clearData()
    this.oppDesireDropdown.clearData()
  },

  showDropDown() {
    this.setState({
      dropDownState: true
    })
  },

  hideDrop() {
    this.setState({ dropDownState: false })
  },

  changeUser(e) {
    const value = e.target.value

    if (value == ' ') {
      return
    }

    util.setTimeout(
      'CrmOpportunityList',
      () => {
        this.setFilter({ crmQuery: value })
      },
      200
    )
  },

  sortData(data) {
    const {
      filters: { order_by, order_type }
    } = this.state
    const { key } = data
    var state = 'desc'
    if (order_by == key) {
      if (order_type == 'asc') {
        state = 'desc'
      } else {
        state = 'asc'
      }
    }

    this.setFilter({
      order_by: key,
      order_type: state
    })
  },

  renderTableHeader() {
    const {
      filters: { order_by, order_type }
    } = this.state
    return (
      <tr>
        {tableHeader.map((data, index) => {
          if (data.state == 'off') {
            return <th key={index}>{data.name}</th>
          }
          var text = ''
          if (order_type == 'desc') {
            text = (
              <i
                className="iconfont triangle-up"
                style={{ verticalAlign: 'middle', marginBottom: 3 }}
              />
            )
          } else if (order_type == 'asc') {
            text = (
              <i
                className="iconfont triangle-down"
                style={{ verticalAlign: 'middle', marginBottom: 3 }}
              />
            )
          } else {
            text = ''
          }

          if (data.state == 'empty') {
            return (
              <th
                key={index}
                onClick={this.sortData.bind(null, data)}
                className="a-hover"
              >
                {data.name}
                {order_by == data.key ? text : ''}
              </th>
            )
          }

          return <th key={index}>{data.name}</th>
        })}
        <th />
      </tr>
    )
  },

  changeDate(range) {
    this.setFilter(range)
  },

  changeUpdateRange(rage) {
    let updateRange = {}
    updateRange.updated_start_time = rage.from
      ? getInitialDate(rage.from).initStartDateString
      : null
    updateRange.updated_end_time = rage.to
      ? getInitialDate(rage.to).initEndDateString
      : null
    this.setFilter(updateRange)
  },

  changeFollowUpDate(rage) {
    let setRange = {}
    setRange.follow_up_start_time = rage.from
      ? getInitialDate(rage.from).initStartDateString
      : null
    setRange.follow_up_end_time = rage.to
      ? getInitialDate(rage.to).initEndDateString
      : null
    this.setFilter(setRange)
  },

  exportOpp() {
    const { filters } = this.state

    return KBPopover.show(<CrmOppReportForm filters={filters || {}} />)
  },

  createOrganization(type, loc_id, customer, industry_id, company_name, opp) {
    const { routerActions } = this.props

    let params = Object.assign(
      {},
      { is_important: false, opportunity_id: customer.id },
      { external_visible: false },
      { is_legal: false },
      { full_name: company_name },
      { industry_id }
    )
    // routerActions.push(`/admin/locations/${loc_id}/new_organization?source=crm&idstry_id=${industry_id}&opportunity_id=${customer.id}&opp_name=${customer.customer.name}&full_name=${company_name}`)
    KBPopover.show(
      <OrganizationForm
        idstry_id={industry_id}
        location_id={loc_id}
        onSubOrgCreated={this.toCreateSubscription.bind(null, opp)}
        status={type}
        initialValues={params}
      />
    )
  },

  createSubscription(opp) {
    if (opp.organization == undefined) {
      // routerActions.push(`/admin/locations/${opp.win_location.id}/organizations?type=crmrecord&id=${opp.id}&industry_id=${opp.customer.industry ? opp.customer.industry.id : ''}`)
      let location_id = opp.win_location && opp.win_location.id
      let customer_id = opp.id
      let industry_id =
        opp.customer && opp.customer.industry ? opp.customer.industry.id : ''
      let company_name = (opp.contact && opp.contact.company) || ''
      this.createOrganization(
        'crmrecord',
        location_id,
        opp,
        industry_id,
        company_name,
        opp
      )
    } else {
      // routerActions.push(`/admin/locations/${opp.win_location.id}/organizations/${opp.organization.id}/subscriptions/new?opportunity_id=${opp.id}`)
      this.toCreateSubscription && this.toCreateSubscription(opp)
    }
  },

  toCreateSubscription(opp, org) {
    const { routerActions } = this.props
    let org_id = (opp.organization && opp.organization.id) || (org && org.id)
    routerActions.push(
      `/admin/crm/opportunities/sub/organizations/${org_id}/subscriptions/new?opportunity_id=${opp.id}&location_id=${opp.win_location.id}&typeSource=crmrecord`
    )
  },

  toSubscription(opp) {
    const { routerActions } = this.props
    routerActions.push(
      `/admin/crm/opportunities/sub/organizations/${opp.organization.id}/subscriptions/${opp.subscription.id}?typeSource=crmrecord`
    )
  },

  toLocation(opp) {
    const { routerActions } = this.props
    routerActions.push(`/admin/locations/${opp.win_location.id}`)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  render() {
    const {
      pagination,
      opportunities,
      filters,
      dropDownState,
      customer,
      loading
    } = this.state
    const {
      users,
      queryFilters,
      sources,
      locations,
      isSpaceCRMAdmin,
      defaultCrmUserTypes,
      defaultCrmSourceTypes,
      defaultCrmDesiresTypes,
      defaultCrmStateTypes,
      defaultCrmLocationsTypes
    } = this.props
    const {
      from,
      to,
      follow_up_start_time,
      follow_up_end_time,
      nextfollow_up_start_time,
      nextfollow_up_end_time,
      updated_start_time,
      updated_end_time
    } = this.state.filters
    const chanceState = OPPORTUNITY_STATES
    const chanceStateClass = OPPORTUNITY_STATES_CLASS

    let user_type = filters.user_type || 'all'
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    let crmUserTypeTitle = CRM_USER_TYPE_STAT[user_type]
    return (
      <div className="kb-content-container clear-fix">
        <section>
          <header className="nav-header border-bottom">
            <div className="nav-section-header-title">
              <span>客户</span>
              <div className="f-right">
                {isSpaceCRMAdmin ? (
                  <button className="c-btn-secondary" onClick={this.exportOpp}>
                    导出Excel
                  </button>
                ) : (
                  ''
                )}
                <button className="bordered-btn" onClick={this.createOpp}>
                  <i className="iconfont icon-add" />
                  添加客户
                </button>
              </div>
            </div>
            <div className="nav-section-action-bar">
              <KBDropSelect
                ref={ref => {
                  this.locationsDropdown = ref
                }}
                selectedData={defaultCrmLocationsTypes}
                options={{ defaultSelectPrompt: '选择分店' }}
                defaultData={locations}
                multi={true}
                dropDown_name="locationsDropdown"
                closeClick={this.closeDropDowns}
                callback={this.selectLocationsCallback}
              />
              <KBDropdown
                ref={ref => {
                  this.userTypeDropdown = ref
                }}
                style={{ marginRight: 20, marginLeft: 20 }}
              >
                <KBDropdown.KBDropdownTrigger>
                  <div
                    className="task-status-title d-iblock"
                    onClick={this.closeDropDowns.bind(null, 'userTypeDropdown')}
                  >
                    <span>{crmUserTypeTitle}</span>
                    <span className="task-deg" />
                  </div>
                </KBDropdown.KBDropdownTrigger>
                <KBDropdown.KBDropdownContent>
                  <ul className="task-type">
                    {CRM_USER_TYPE_STATUS.map((status, index) => {
                      return (
                        <li
                          key={index}
                          onClick={this.setFilter.bind(null, {
                            user_type: status.status
                          })}
                        >
                          <span>{status.title}</span>
                        </li>
                      )
                    })}
                  </ul>
                </KBDropdown.KBDropdownContent>
              </KBDropdown>
              <KBDropSelect
                selectedData={defaultCrmUserTypes}
                ref={ref => {
                  this.participantDropDown = ref
                }}
                singleIsCancel={false}
                defaultData={users}
                options={{ defaultSingleContent: '负责人' }}
                multi={false}
                dropDown_name="participantDropDown"
                closeClick={this.closeDropDowns}
                callback={this.userSelectCallBack}
                style={{ marginRight: 20 }}
              />
              <KBDropSelect
                selectedData={defaultCrmStateTypes}
                ref={ref => {
                  this.oppStateDropdown = ref
                }}
                options={{ defaultSelectPrompt: '选择状态' }}
                showImg={false}
                defaultData={CRM_OPPORTUNITY_STATE}
                multi={true}
                dropDown_name="oppStateDropdown"
                closeClick={this.closeDropDowns}
                callback={this.selectCallBack}
              />
              <KBDropSelect
                selectedData={defaultCrmSourceTypes}
                ref={ref => {
                  this.sourceDropDown = ref
                }}
                showImg={false}
                defaultData={sources}
                multi={true}
                dropDown_name="sourceDropDown"
                closeClick={this.closeDropDowns}
                options={{ defaultSelectPrompt: '选择来源' }}
                callback={this.userSourceCallBack}
                style={{ marginLeft: 20 }}
              />
              <KBDropSelect
                selectedData={defaultCrmDesiresTypes}
                ref={ref => {
                  this.oppDesireDropdown = ref
                }}
                options={{ defaultSelectPrompt: '选择客户意愿' }}
                showImg={false}
                defaultData={CRM_OPPORTUNITY_DESIRES}
                multi={true}
                dropDown_name="oppDesireDropdown"
                closeClick={this.closeDropDowns}
                callback={this.selectDesireCallBack}
                style={{ marginLeft: 20 }}
              />
            </div>
            <div className="nav-section-action-bar">
              <KBRangeDate
                from={from || ''}
                to={to || ''}
                title="选择创建日期"
                callback={this.changeDate}
                closeClick={this.closeDropDowns}
                dropDown_name="createDateDropDown"
                ref={ref => {
                  this.createDateDropDown = ref
                }}
                leftNone={true}
              />
              <KBRangeDate
                from={updated_start_time || ''}
                to={updated_end_time || ''}
                title="选择更新日期"
                callback={this.changeUpdateRange}
                closeClick={this.closeDropDowns}
                dropDown_name="updateDateDropDown"
                ref={ref => {
                  this.updateDateDropDown = ref
                }}
                leftNone={false}
              />
              <KBRangeDate
                from={follow_up_start_time || ''}
                to={follow_up_end_time || ''}
                title="选择跟进日期"
                closeClick={this.closeDropDowns}
                dropDown_name="followDateDropDown"
                ref={ref => {
                  this.followDateDropDown = ref
                }}
                callback={this.changeFollowUpDate}
              />
              <div
                className="search-input m-left-sm"
                style={{ width: '186px', display: 'inline-block' }}
              >
                <input
                  type="text"
                  placeholder="搜索客户"
                  ref="queryOpp"
                  onChange={this.changeUser}
                />
              </div>
            </div>
            {selectFilters.length > 0 ? (
              <div
                className="clear-criteria"
                onClick={this.clearFilters}
                style={{ marginTop: 10 }}
              >
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            ) : (
              ''
            )}
          </header>
          <div className="nav-section-content-container">
            <KBLoadingContainer loading={!opportunities || loading}>
              <table className="content-table edit-table">
                <thead>{this.renderTableHeader()}</thead>
                <KBTableWithoutData
                  hasData={opportunities.length > 0}
                  tableHeadNum="9"
                  tipMessage="暂无客户"
                >
                  {opportunities &&
                    opportunities.map((json, index) => {
                      let leader =
                        (json.sales_team &&
                          json.sales_team.find(team => !!team.is_leader)) ||
                        {}
                      let winState = ['success', 'entered', 'archived']
                      return (
                        <tr
                          key={index}
                          onClick={this.toOppInfo.bind(null, json)}
                        >
                          <td
                            className="t-over color-link"
                            style={{ maxWidth: 120 }}
                          >
                            {json.title}
                          </td>
                          {/*<td>{ json.employee_number || '-' }</td>*/}
                          <td>{json.source ? json.source.name : ''}</td>
                          <td>
                            {' '}
                            <span
                              className={classNames(
                                'receipt-status',
                                chanceStateClass[json.state]
                              )}
                              style={{ width: 64, textAlign: 'center' }}
                            >
                              {' '}
                              {chanceState[json.state] || '-'}{' '}
                            </span>{' '}
                          </td>
                          {/*<td>{ json.locations.length == 0 ? '' : json.locations.map((item) => {return <span>{ item.name }<br/></span> }) }</td>*/}
                          <td>
                            <div
                              className="vf-center"
                              onClick={e => {
                                e.stopPropagation()
                              }}
                            >
                              <KBAvatar
                                style={{
                                  display: 'inline-block',
                                  marginRight: '5px'
                                }}
                                imgStyle={{ marginRight: 5 }}
                                user={leader.sales_user}
                                size={25}
                              />
                              <Link
                                className="color-link"
                                to={getUserInfoUrl(
                                  leader.sales_user && leader.sales_user.id
                                )}
                              >
                                {(leader.sales_user &&
                                  leader.sales_user.name) ||
                                  ''}
                              </Link>
                            </div>
                          </td>
                          {/*<td>{ json.followed_at&&moment(json.followed_at).format('YYYY-MM-DD') }</td>*/}
                          <td>{formatHoursMinutesEN(json.created_at)}</td>
                          <td>
                            {json.updated_at
                              ? moment.unix(json.updated_at).fromNow()
                              : ''}
                          </td>
                          {json.state != 'failed' ? (
                            json.state == 'success' ||
                            json.state == 'entered' ? (
                              <td>
                                <span style={{ display: 'block' }}>
                                  赢单分店：
                                  {json.win_location ? (
                                    this.props.isSpaceCRMOnly ? (
                                      json.win_location.name
                                    ) : (
                                      <KBTipsy content="点击进入分店首页">
                                        <span
                                          onClick={e => {
                                            this.toLocation(json)
                                            e.stopPropagation()
                                          }}
                                          style={{ color: '#2ea1f8' }}
                                        >
                                          {json.win_location.name}
                                        </span>
                                      </KBTipsy>
                                    )
                                  ) : (
                                    '暂无'
                                  )}
                                </span>
                                <span>
                                  合同：
                                  {(json.state == 'success' ||
                                    json.state == 'entered') &&
                                  json.win_location ? (
                                    json.subscription ? (
                                      json.organization ? (
                                        // 此处判断是否已上传扫描件
                                        <span
                                          onClick={e => {
                                            this.toSubscription(json)
                                            e.stopPropagation()
                                          }}
                                        >
                                          <KBTipsy content="点击查看合同详情">
                                            <a style={{ color: '#2ea1f8' }}>
                                              {json.subscription.has_files
                                                ? json.subscription.serial ||
                                                  '已创建合同'
                                                : '未上传扫描件'}
                                            </a>
                                          </KBTipsy>
                                        </span>
                                      ) : (
                                        <span>暂无</span>
                                      )
                                    ) : (
                                      <span>
                                        <KBTipsy content="点击可创建合同">
                                          <span
                                            style={{ color: '#2ea1f8' }}
                                            onClick={e => {
                                              this.createSubscription(json)
                                              e.stopPropagation()
                                            }}
                                          >
                                            未创建
                                          </span>
                                        </KBTipsy>
                                      </span>
                                    )
                                  ) : (
                                    <span>暂无</span>
                                  )}
                                </span>
                              </td>
                            ) : (
                              <td> </td>
                            )
                          ) : (
                            <td>
                              流失原因：
                              {json.failed_reason
                                ? CRM_FAILED_REASON_OBJ[json.failed_reason]
                                : '暂无'}
                            </td>
                          )}
                          <td className="t-right">
                            {!winState.includes(json.state) ? (
                              <i
                                className="iconfont icon-delete"
                                onClick={e => {
                                  this.deleteOpp(json)
                                  e.stopPropagation()
                                }}
                              />
                            ) : null}
                          </td>
                        </tr>
                      )
                    })}
                </KBTableWithoutData>
              </table>
              <KBPagination
                pagination={pagination}
                template={`/admin/crm/opportunities?page=#PAGE#`}
              />
            </KBLoadingContainer>
          </div>
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  const user = selectors.getCurrentUser(state)
  const locations = selectors.getLocationsOfUser(state)
  let page = parseInt(location.query && location.query.page) || 1
  let opps = selectors.getOpportunities(state) || []
  let users = selectors.getSpaceUsers(state)
  let sources = selectors.getCrmSources(state)
  let queryFilters = location.query
  let isSpaceCRMAdmin = canAccessSpaceRoles(user, ['crm_admin', 'admin'])
  let isSpaceCRMOnly =
    canAccessSpaceRoles(user, ['crm_admin', 'crm']) &&
    !canAccessSpaceRoles(user, ['admin'])
  const { entities } = state
  const { opp_id } = props.params
  let u_id = user.id
  let entitiesOpp = checkObject(entities, 'opportunities')
  let opp = entitiesOpp && entitiesOpp[opp_id]
  var FILTER_STATE = [
    'from',
    'to',
    'user_type',
    'user_id',
    'state',
    'location_ids',
    'updated_start_time',
    'sources',
    'desires',
    'crmQuery',
    'updated_end_time',
    'follow_up_start_time',
    'follow_up_end_time',
    'start_time',
    'end_time'
  ]
  var params = getFilterAndQuery(location.query, FILTER_STATE)

  let defaultCrmSourceTypes = []
  if (params.filters && params.filters.sources) {
    let sources_data = params.filters.sources.split(',')
    defaultCrmSourceTypes = sources.filter(data =>
      sources_data.find(id => id == data.id)
    )
  }

  let defaultCrmDesiresTypes = []
  if (params.filters && params.filters.desires) {
    let desires_data = params.filters.desires.split(',')
    defaultCrmDesiresTypes = CRM_OPPORTUNITY_DESIRES.filter(data =>
      desires_data.find(id => id == data.id)
    )
  }

  let defaultCrmLocationsTypes = []
  if (params.filters && params.filters.location_ids) {
    let location_ids = params.filters.location_ids.split(',')
    defaultCrmLocationsTypes = locations.filter(data =>
      location_ids.find(id => id == data.id)
    )
  }

  let defaultCrmStateTypes = []
  if (params.filters && params.filters.state) {
    let states = params.filters.state.split(',')
    defaultCrmStateTypes = CRM_OPPORTUNITY_STATE.filter(data =>
      states.find(id => id == data.id)
    )
  }

  let defaultCrmUserTypes = null
  if (params.filters && params.filters.user_id) {
    defaultCrmUserTypes = users.find(data => data.id == params.filters.user_id)
  }

  return {
    user,
    page,
    opp,
    opps,
    users,
    sources,
    queryFilters,
    locations,
    isSpaceCRMAdmin,
    isSpaceCRMOnly,
    defaultCrmStateTypes,
    defaultCrmUserTypes,
    defaultCrmLocationsTypes,
    defaultCrmDesiresTypes,
    defaultCrmSourceTypes,
    ...params
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getOpportunity: bindActionCreators(opportunitiesActions.get, dispatch),
    deleteOpportunity: bindActionCreators(
      opportunitiesActions.delete,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrmOpportunityList)
