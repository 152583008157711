import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'

var KBASNumberInput = React.createClass({
  getInitialState() {
    return {
      value: parseFloat(this.props.value) || ''
    }
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.value != this.props.value) {
      this.setState({
        value: nextProps.value
      })
    }
  },

  addNumber() {
    const { value } = this.state
    const { callback } = this.props
    let newVal = parseFloat(value) + 1
    this.setState({
      value: newVal
    })
    callback && callback(newVal)
  },

  subtractNumber() {
    const { value } = this.state
    const { callback } = this.props
    let newVal = parseFloat(value) - 1

    this.setState({
      value: isNaN(newVal) ? 0 : newVal
    })
    callback && callback(newVal)
  },

  changeInput(e) {
    const value = e.target.value
    const { callback } = this.props
    this.setState({
      value
    })
    callback && callback(value)
  },

  render() {
    const {
      placeholder,
      type,
      title,
      className,
      inputStyle,
      spanStyle
    } = this.props
    const { value } = this.state
    return (
      <div className={`kb-number-input ${className}`}>
        {title ? <label className="title">{title}</label> : ''}
        <div className="counterBox">
          <span
            className="subtracter"
            onClick={this.subtractNumber}
            style={spanStyle}
          >
            -
          </span>
          <input
            style={inputStyle}
            type={type}
            placeholder={placeholder}
            onChange={this.changeInput}
            value={value}
          />
          <span className="summator" onClick={this.addNumber} style={spanStyle}>
            +
          </span>
        </div>
      </div>
    )
  }
})

KBASNumberInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string
}

KBASNumberInput.defaultProps = {
  type: 'text'
}

export default KBASNumberInput
