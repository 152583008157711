const configObj = {
  dev: {
    urlScheme: 'https',
    url: 'api-kd.cl-sg-dev.kuban.io',
    qiniu_base_url: 'https://media-dev-ssl.kuban.io/',
    qiniu_upload_url: 'https://up.qbox.me/',
    // "logo_url": 'https://media-ssl.kuban.io/static/web/v2/N1.png',
    // "logo_url_horizontal": 'https://media-ssl.kuban.io/static/web/v2/N1.png'
    isAzureUpload: true,
    uploadDir: 'cl/uploads/',
    azure_url: 'https://clsit.blob.core.chinacloudapi.cn/',
    media_container: 'capitastar',
    imgproxy_url: 'https://work-image.capitaland.com.cn',
    init_locale: 1,
    logo_url:
      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/kadelogo1.png',
    logo_url_horizontal:
      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/kadelogo1.png'
  },
  uat: {
    urlScheme: 'https',
    url: 'work-uat-api.capitaland.com.cn',
    qiniu_base_url: 'https://media-ssl.kuban.io/',
    qiniu_upload_url: 'https://up-z1.qbox.me/',
    logo_url:
      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/kadelogo1.png',
    logo_url_horizontal:
      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/kadelogo1.png',
    isAzureUpload: true,
    uploadDir: 'cl/uploads/',
    azure_url: 'https://cncaoren017.blob.core.chinacloudapi.cn/',
    imgproxy_url: 'https://work-image.capitaland.com.cn',
    azure_domain: 'cncaoren017.blob.core.chinacloudapi.cn',
    azure_cdn_domain: 'img.cl-dev.kuban.io',
    media_container: 'cncaoren006'
  },
  prod: {
    urlScheme: 'https',
    url: 'work-api.capitaland.com.cn',
    qiniu_base_url: 'https://media-ssl.kuban.io/',
    qiniu_upload_url: 'https://up-z1.qbox.me/',
    logo_url:
      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/kadelogo1.png',
    logo_url_horizontal:
      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/kadelogo1.png',
    isAzureUpload: true,
    uploadDir: 'kade/prod/uploads/',
    azure_url: 'https://cncaoren017.blob.core.chinacloudapi.cn/',
    imgproxy_url: 'https://work-image.capitaland.com.cn',
    azure_domain: 'cncaoren017.blob.core.chinacloudapi.cn',
    azure_cdn_domain: 'img.cl-dev.kuban.io',
    media_container: 'cncaoren006'
  }
}

let env = process.env.REACT_APP_URLCONFIG;
if (process.env.NODE_ENV === 'development') {
  // 默认链接dev服务器，调试可以看情况链接线上
  env = 'dev'
}

console.log('env='+env)

export default configObj[env]
