import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { successState, apiCallFailure } from 'app/actions'
import { toImageProxyDirect } from 'utils/kbUtil'

var WechatSettings = React.createClass({
  displayName: 'WechatSettings',
  getInitialState() {
    return {
      wechat_mch_id: '',
      wechat_app_id: '',
      wechat_key: '',
      moduleSettings: []
    }
  },
  componentDidMount() {
    this.getWechatSettingData()
  },
  getWechatSettingData() {
    api.getWechatSettings().then(
      res => {
        this.setWechatSettingData(res)
      },
      error => {
        this.setSettingError(error)
      }
    )
  },
  setWechatSettingData(res) {
    const { json } = res
    if (json.wechat_setting) {
      let wechatSetting = json.wechat_setting
      let moduleSettings = json.wechat_setting.module_settings
      this.setState({
        moduleSettings,
        wechat_key: wechatSetting.payment_key,
        wechat_mch_id: wechatSetting.mch_id,
        wechat_app_id: wechatSetting.appid,
        isUpdateWechat: true
      })
    }
  },
  changeAppId(e) {
    let value = e.target.value
    this.setState({
      wechat_app_id: value
    })
  },
  changeKey(e) {
    let value = e.target.value
    this.setState({
      wechat_key: value
    })
  },
  changeMchId(e) {
    let value = e.target.value
    this.setState({
      wechat_mch_id: value
    })
  },
  setSettingError(error) {
    const { apiCallFailureActions } = this.props
    let message = (error && error._error && error._error.message) || ''
    apiCallFailureActions({
      status: 'error',
      message: message
    })
  },
  saveWechatSetting() {
    const { successStateAction, apiCallFailureActions } = this.props
    const {
      wechat_mch_id,
      wechat_app_id,
      wechat_key,
      isUpdateWechat
    } = this.state

    if (!wechat_app_id) {
      return apiCallFailureActions({
        status: 'error',
        message: 'appid 不能为空'
      })
    }

    if (isUpdateWechat) {
      return api
        .putWechatSettings({
          payment_key: wechat_key,
          mch_id: wechat_mch_id,
          appid: wechat_app_id
        })
        .then(
          res => {
            successStateAction({ message: 'Wechat修改成功!' })
            this.setWechatSettingData(res)
          },
          err => {
            this.setSettingError(err)
          }
        )
    }
    api
      .createWechatSettings({
        payment_key: wechat_key,
        mch_id: wechat_mch_id,
        appid: wechat_app_id
      })
      .then(
        res => {
          successStateAction({ message: 'Wechat保存成功!' })
          this.setWechatSettingData(res)
        },
        err => {
          this.setSettingError(err)
        }
      )
  },

  toOpenAuthorization() {
    const { space, apiCallFailureActions, user } = this.props
    const {
      wechat_mch_id,
      wechat_app_id,
      wechat_key,
      isUpdateWechat
    } = this.state
    window.open(
      `https://api.kuban.io/wechats/third_party_auth/authpage?space_id=${space.id}&token=${user.jwt_token}`
    )
  },

  render() {
    const {
      wechat_mch_id,
      wechat_app_id,
      wechat_key,
      moduleSettings
    } = this.state
    return (
      <div style={{ float: 'left', background: '#fff', marginLeft: '20px' }}>
        <header className="nav-header">
          <div className="nav-section-header-title">
            <span>微信小程序设置</span>
          </div>
        </header>
        <div className="kb-form-container">
          <div className="wechat-authorization">
            <img
              src={toImageProxyDirect(
                'https://media-ssl.kuban.io/static/web/v1/images/wechatAuthorization.png',
                360,
                264
              )}
              alt="wechat"
            />
            <div>
              <p>
                在微信小程序中开通微信支付功能，
                <br />
                用于支付预订、商城等功能
              </p>
              <button
                className="certain-btn m-top-sm"
                onClick={this.toOpenAuthorization}
              >
                点击进入授权
              </button>
            </div>
          </div>
          <div className="m-bottom">
            <div className="kb-title">
              <span>微信小程序ID</span>
            </div>
          </div>
          <div className="kb-row kb-form-66">
            <div className="kb-form-group kb-form-c39">
              <label>小程序ID</label>
              <div>
                <input
                  type="text"
                  className="kb-input"
                  value={wechat_app_id}
                  onChange={this.changeAppId}
                />
              </div>
            </div>
            <div className="kb-form-group">
              <button
                className="certain-btn m-left"
                onClick={this.saveWechatSetting}
              >
                保存
              </button>
            </div>
          </div>
          <div className="m-bottom">
            <div className="kb-title">
              <span>微信支付设置</span>
            </div>
          </div>
          <div className="kb-row kb-form-66">
            <div className="kb-form-group kb-form-c39">
              <label>商户ID</label>
              <div>
                <input
                  type="text"
                  className="kb-input"
                  value={wechat_mch_id}
                  onChange={this.changeMchId}
                />
              </div>
            </div>
            <div className="kb-form-group kb-form-c39">
              <label className="label-rt">商户Key</label>
              <div>
                <input
                  type="text"
                  className="kb-input"
                  value={wechat_key}
                  onChange={this.changeKey}
                />
              </div>
            </div>
          </div>
          <div>
            <button className="certain-btn" onClick={this.saveWechatSetting}>
              保存
            </button>
          </div>

          <div className="m-top">
            <div className="kb-title">
              <span>微信公众号菜单设置</span>
            </div>
            <div className="wechat-authorization m-updown">
              <img
                src={toImageProxyDirect(
                  'https://media-ssl.kuban.io/static/web/v1/images/wechatApp.png',
                  360,
                  226
                )}
                alt="wechat"
              />
              <div>
                <p>自定义页面用于关联公众号自定义菜单</p>
              </div>
            </div>
          </div>
          <table className="content-table edit-table">
            <thead>
              <tr>
                <th>名称</th>
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              {moduleSettings
                ? moduleSettings.map(json => {
                    return (
                      <tr>
                        <td>{json.name}</td>
                        <td>{json.url}</td>
                      </tr>
                    )
                  })
                : null}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const space = selectors.getSpace(state)
  const user = selectors.getCurrentUser(state)

  return {
    space,
    user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successStateAction: bindActionCreators(successState, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WechatSettings)
