import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { KBLoadingContainer, KBTipsy, KBPopover } from 'components'
import { areaActions, informActions, apiCallFailure } from 'app/actions'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { AREAS_LIST_STATE } from 'app/constants'
import AreasForm from './AreasForm'
import _ from 'lodash'
import { canAccessLocation, canAccessLocationRoles } from 'app/reducers/role'

const FLOOR_SIZE = 100
const FLOOR_WIDTH = 996

const AREA_TYPE = {
  private_office: '封闭',
  public_office: '开放',
  flexible_office: '灵活'
}

var AreasContainer = React.createClass({
  getInitialState() {
    return {
      filters: {
        state: 'all'
      },
      loading: true
    }
  },

  componentDidMount() {
    this.getAreas()
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.floorInfoId != nextProps.floorInfoId) {
      this.getAreas(nextProps.floorInfoId)
    }
  },

  getAreas(floor_info_id) {
    const { getAreasAction, loc_id } = this.props

    const params = {
      location_id: loc_id,
      per_page: 1000,
      includes: 'subscriptions,organizations'
    }
    if (floor_info_id) {
      params.floor_info_id = floor_info_id
    }

    api
      .getAreas(params)
      .then(
        json => {
          getAreasAction.success(json, {
            location_id: loc_id,
            entityName: 'areas'
          })
        },
        error => {
          getAreasAction.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  toDeskList(area) {
    const { routerActions, loc_id } = this.props
    const { filters } = this.state
    if (filters.state != 'all' && filters.state != area.state) {
      return
    }
    routerActions.push(
      `/admin/locations/${loc_id}/areas/desks/list?area_id=${area.id}`
    )
  },

  _setFilter(key, value) {
    let filters = Object.assign({}, this.state.filters, { [key]: value })
    this.setState({
      filters
    })
  },

  _onAreaCreated(area) {
    const { loc_id } = this.props
    this.props.routerActions.push(
      `/admin/locations/${loc_id}/areas/desks/list?area_id=${area.id}`
    )
  },

  createArea() {
    let { loc_id } = this.props
    KBPopover.showForm(
      <AreasForm loc_id={loc_id} onAreaCreated={this._onAreaCreated} />
    )
  },

  render() {
    const { filters, loading } = this.state
    const { state } = filters
    const { areas_of_floor, floors, isManager } = this.props
    return (
      <section
        className="kb-content"
        style={{ height: 'auto', padding: 30, backgroundColor: '#fff' }}
      >
        <div
          style={{
            marginBottom: 30,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ display: 'flex', flex: 1 }}>
            {AREAS_LIST_STATE.map((json, index) => {
              const isCheckedState = json.id == filters.state
              return (
                <div
                  key={index}
                  className={`border-show_status ${
                    isCheckedState ? 'border-show_status_active' : ''
                  }`}
                  onClick={() => this._setFilter('state', json.id)}
                >
                  {json.id != 'all' ? (
                    <span className={`color-show ${'area-list_' + json.id}`} />
                  ) : (
                    ''
                  )}
                  <span className="status-text">{json.name}</span>
                </div>
              )
            })}
          </div>
          {isManager ? (
            <button className="bordered-btn" onClick={this.createArea}>
              添加新分区
            </button>
          ) : null}
        </div>
        <KBLoadingContainer loading={loading}>
          <div>
            {floors &&
              floors.map((floor, index) => {
                return (
                  <div className="clear-fix m-bottom" key={index}>
                    <div
                      className="f-left"
                      style={{
                        width: 70,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      {floor ? (
                        <span className="area-floor_name">{floor}</span>
                      ) : (
                        <img
                          src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon-area_floor.png"
                          alt="icon"
                          style={{ width: 40, marginTop: 5, marginBottom: 5 }}
                        />
                      )}
                      <span className="area-floor_icon">FLOOR</span>
                    </div>
                    <div className="f-left clear-fix" style={{ width: 996 }}>
                      {areas_of_floor[floor] &&
                        areas_of_floor[floor].map((area, area_index) => {
                          let area_width =
                            _.floor((area.size / FLOOR_SIZE) * FLOOR_WIDTH) - 5
                          return (
                            <KBTipsy content={area.name} key={area_index}>
                              <div
                                onClick={this.toDeskList.bind(null, area)}
                                className={`area-list_default ${
                                  state == 'all'
                                    ? 'area-size_' + area.state
                                    : state == area.state
                                    ? 'area-size_' + area.state
                                    : 'area-list_disabled'
                                }`}
                                style={{ width: area_width }}
                              >
                                <div className="area-size_name">
                                  {area.name}({AREA_TYPE[area.area_type]})
                                </div>
                                <div className="area-size_data">
                                  {area.organization ? (
                                    <div className="area-size_company">
                                      {area.organization.full_name}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <span>
                                    {area.show_size}㎡
                                    {area.area_type != 'private_office' ? (
                                      <span>
                                        , {area.desks_count}个工位{' '}
                                        {area.occupied_desks_count &&
                                        area.occupied_desks_count != 0
                                          ? `, 已分配${area.occupied_desks_count}个工位`
                                          : ''}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                </div>
                              </div>
                            </KBTipsy>
                          )
                        })}
                    </div>
                  </div>
                )
              })}
          </div>
        </KBLoadingContainer>
      </section>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const { params } = ownProps
  const { user } = state
  let loc_id = params.id
  let isManager = canAccessLocation(user, loc_id)
  let isSales = canAccessLocationRoles(user, loc_id, ['sales'])
  let areas = Object.assign([], selectors.getAreasOfLocation(state, loc_id))
  let floors = []
  areas = _.cloneDeep(areas).filter(
    area =>
      area.area_type == 'private_office' ||
      area.area_type == 'public_office' ||
      area.area_type == 'flexible_office'
  )
  areas &&
    areas.map(area => {
      if (!area.size) {
        area.size = 20
        area.show_size = 20
      } else {
        area.show_size = area.size
      }
      floors.push(area.floor)
    })
  floors = _.uniq(floors)
  let areas_of_floor = _.groupBy(areas, 'floor')
  let diff_data = []
  let size_num = 0
  floors.map(floor => {
    areas_of_floor[floor].map((area, index) => {
      let area_out = []
      let area_size = area.size
      if (index == 0) {
        let area_ratio_first = (area.size / FLOOR_SIZE).toString()
        let ratio_array_first = area_ratio_first.split('.')
        let ratio_init_first = ratio_array_first[0]
        if (ratio_init_first > 0) {
          for (let i = 0; i < ratio_init_first; i++) {
            area_out.push(Object.assign({}, area, { size: FLOOR_SIZE }))
          }
        }
        area.size = area_size - ratio_init_first * FLOOR_SIZE
        diff_data.push({
          floor: floor,
          area_out
        })
        size_num = area_size - ratio_init_first * FLOOR_SIZE
      } else {
        size_num = size_num + area_size
        if (size_num > FLOOR_SIZE) {
          let decimal_size = FLOOR_SIZE - (size_num - area_size)
          let size_new = area_size - decimal_size
          let area_ratio_else = (size_new / FLOOR_SIZE).toString()
          let ratio_array_else = area_ratio_else.split('.')
          let ratio_init_else = ratio_array_else[0]
          if (ratio_init_else > 0) {
            for (let i = 0; i < ratio_init_else; i++) {
              area_out.push(Object.assign({}, area, { size: FLOOR_SIZE }))
            }
          }
          area_out.push(Object.assign({}, area, { size: decimal_size }))
          area.size = area_size - (ratio_init_else * FLOOR_SIZE + decimal_size)
          diff_data.push({
            floor: floor,
            area_out
          })
          size_num = area_size - (ratio_init_else * FLOOR_SIZE + decimal_size)
        }
      }
      return area
    })
  })
  floors.map(floor => {
    diff_data.map(data => {
      if (floor == data.floor) {
        data.area_out.map(area_data => {
          let index_n = areas_of_floor[floor].findIndex(
            area_new => area_new.id == area_data.id
          )
          areas_of_floor[floor].splice(index_n, 0, area_data)
        })
      }
    })
    areas_of_floor[floor] = areas_of_floor[floor].filter(area => area.size > 0)
  })
  return {
    areas_of_floor,
    floors,
    loc_id,
    isManager,
    isSales
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    getAreasAction: bindActionCreators(areaActions.replace, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AreasContainer)
