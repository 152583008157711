import React, { Component } from 'react'
import { KBDateInput, KBSelect } from 'components'
import { DAY_TIME_HM } from 'app/constants'
import { formatOnlyMinuteSecondEN, formatMinuteSecondEN } from 'utils/kbUtil'
import moment from 'moment'

function parseHM(date) {
  let hours = date.split(':')[0]
  let minute = date.split(':')[1]
  let t = '1'
  let h = hours
  if (h < 10) {
    h = h.substring(1)
  }
  if (minute == 30) {
    t = '2'
  } else {
    t = '1'
  }

  return h + ',' + t
}

// 传入一个field，返回一个数组: [开始时间, 结束时间]
export default class KBTimeRangeSelect extends Component {
  constructor() {
    super()
    this.state = {
      DEAL_DAY_TIME_HM: DAY_TIME_HM(0, 23),
      startDay: this.parseTime(moment().unix(), 'day'),
      endDay: this.parseTime(moment().unix(), 'day'),
      startHour: this.parseTime(
        moment()
          .startOf('day')
          .unix(),
        'time'
      ),
      endHour: this.parseTime(
        moment()
          .startOf('day')
          .unix(),
        'time'
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    const { startTime, endTime } = nextProps
    if (
      startTime.value !== this.props.startTime.value ||
      endTime.value !== this.props.endTime.value
    ) {
      console.log(11111, startTime, endTime)

      this.setState(
        {
          startDay: this.parseTime(startTime.value, 'day'),
          endDay: this.parseTime(endTime.value, 'day'),
          startHour: this.parseTime(startTime.value, 'time'),
          endHour: this.parseTime(endTime.value, 'time')
        },
        () => {
          this.emitValue()
        }
      )
    }
  }

  setChangeEvents(val, field) {
    return {
      value: val,
      onChange: value => {
        this.setState(
          {
            [field]: value
          },
          () => {
            this.emitValue()
          }
        )
      }
    }
  }

  emitValue() {
    const startTime = this.setHM(this.state.startDay, this.state.startHour)
    const endTime = this.setHM(this.state.endDay, this.state.endHour)
    this.props.startTime.onChange(startTime)
    this.props.endTime.onChange(endTime)
  }

  setHM(startDay, time) {
    let t = time.split(',')
    return moment(
      moment(startDay)
        .clone()
        .set({ hour: t[0], minute: t[1] == '1' ? 0 : 30, second: 0 })
        .toDate()
    ).format('YYYY-MM-DD HH:mm:ss')
  }

  parseTime(unixTime = moment.unix(), type) {
    if (type === 'day') return formatMinuteSecondEN(unixTime)
    if (type === 'time') return parseHM(formatOnlyMinuteSecondEN(unixTime))
  }

  render() {
    const { isRequired, startTime, endTime } = this.props
    const {
      DEAL_DAY_TIME_HM,
      startDay,
      endDay,
      startHour,
      endHour
    } = this.state
    return (
      <div className="kb-row">
        <div className="kb-row kb-form-c48">
          <div className="kb-form-66 clear-fix f-left c_cursor">
            <KBDateInput
              className="kb-form-group kb-form-c48"
              title="开始日期"
              isRequired={isRequired}
              field={this.setChangeEvents(startDay, 'startDay')}
            />
            <KBDateInput
              className="kb-form-group kb-form-c48"
              title="结束日期"
              isRequiredR={isRequired}
              titleClass="label-rt"
              field={this.setChangeEvents(endDay, 'endDay')}
            />
          </div>
        </div>
        <div className="kb-row kb-form-c48">
          <div className="kb-form-66 clear-fix f-left">
            <KBSelect
              className="kb-form-group kb-form-c48"
              title="开始时间"
              isRequired={isRequired}
              arrayData={DEAL_DAY_TIME_HM}
              field={this.setChangeEvents(startHour, 'startHour')}
            />
            <KBSelect
              className="kb-form-group kb-form-c48"
              title="结束时间"
              isRequiredR={isRequired}
              titleClass="label-rt"
              arrayData={DEAL_DAY_TIME_HM}
              field={this.setChangeEvents(endHour, 'endHour')}
            />
          </div>
        </div>
        {startTime.touched && startTime.error && (
          <p className="lr-error">{startTime.error}</p>
        )}
        {endTime.touched && endTime.error && (
          <p className="lr-error">{endTime.error}</p>
        )}
      </div>
    )
  }
}
