import React, { Component, PropTypes } from 'react'
import {
  INVOICES_SUBSCRIPTION_STATES,
  ORG_SUBSCRIPTIONS_STATES,
  ORG_SUBSCRIPTION_STATES_CLASS,
  INVOICES_CONTRACT_STATES
} from 'app/constants'
import {
  KBDropdown,
  KBPagination,
  KBRangeDate,
  KBTableWithoutData,
  KBLoadingContainer
} from 'components'
import classNames from 'classnames'
import { api, apiUtils } from 'app/services'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { subscriptionsAction, successState } from 'app/actions'
import {
  getSubScriptions,
  getLocationsAsDictionary,
  getCurrentUser,
  getCrmSources
} from 'app/selectors'
import { bindActionCreators } from 'redux'
import {
  getEntitiesJsonArray,
  paginate,
  getFilterAndQuery,
  queryFilter,
  kbCloseDropDown,
  arrayToObject
} from 'utils/kbUtil'
import { canAdminSpace } from 'app/reducers/role'
import InvoicesContractBlock from 'containers/invoices/InvoicesContractBlock'
import { getInitialDate } from 'utils/kbData'
import moment from 'moment'
import _ from 'lodash'

const DROP_DOWNS = ['dateDropDown', 'statusDropdown']

var CrmWinSubscriptions = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      filters,
      subscriptions: [],
      pagination: null,
      isContractSetting: false,
      loading: false
    }
  },

  componentDidMount() {
    this.mounted = true

    this.getSubscriptions()

    // api.getSpaceSetting().then(({ json }) => {
    //   this.setState({
    //     isContractSetting : this.isContractApprovalSetting(json.settings)
    //   })
    // })
  },

  componentWillUnmount() {
    this.mounted = false
  },

  /**
   * 是否拥有审批合同的权利
   * @param  {[type]}  settings [当前空间的setting]
   * @return {Boolean}          [是否通过]
   */
  isContractApprovalSetting(settings) {
    const { user } = this.props
    const setting = settings && settings.contract_role_setting
    if (canAdminSpace(user)) {
      return true
    }

    if (!setting || !settings) {
      return false
    }

    if (setting == 'none') {
      return false
    }

    if (!setting.user_group) {
      return false
    }

    let users = setting.user_group.split(',') || []

    if (users.find(user_id => user_id == user.id)) {
      return true
    }
  },

  // 解析在数据加载时的 from to
  parseLoadFromTo(params, from, to) {
    delete params.from
    delete params.to

    params.start_date = from ? getInitialDate(from).initStartDateString : null
    params.end_date = to ? getInitialDate(to).initEndDateString : null

    return params
  },

  getSubscriptions(newPage, newStatus) {
    const { getSubscriptionsAction, query, routerActions, sources } = this.props
    const { filters } = this.state
    const { from, to } = filters
    const preFilters = this.props.filters
    if (from && to) {
      filters.from = from && moment(from).format('YYYY-MM-DD')
      filters.to = to && moment(to).format('YYYY-MM-DD')
    } else {
      delete filters.from
      delete filters.to
    }
    const loc_id = this.getLocationId()
    const status = newStatus == 0 ? '' : filters.status
    const per_page = 10
    let pageObj = {
      per_page,
      page: newPage || this.props.page
    }

    let params = Object.assign(
      {},
      pageObj,
      loc_id ? { location_id: loc_id } : {},
      preFilters,
      filters,
      status ? { status } : {}
    )
    let queryFiltersStr = queryFilter({ ...query, ...filters, ...pageObj })

    // 判断日期
    params = this.parseLoadFromTo(params, from, to)
    this.setState({ loading: true })

    api
      .getSubscriptions({
        ...params,
        sources: filters.sources,
        includes: 'crm',
        subscription_type: 'normal'
      })
      .then(
        json => {
          let pagination = paginate(json.response)
          getSubscriptionsAction.success(json, {
            per_page: per_page,
            ...pagination
          })
          let subscriptions = getEntitiesJsonArray(json, 'subscriptions')
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(`/admin/crm/winsubscription${queryFiltersStr}`)
          this.setState({
            subscriptions,
            pagination
          })
        },
        errors => {
          getSubscriptionsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.getSubscriptions(nextProps.page, '0')
    }
  },

  getLocationId() {
    const { filters } = this.state
    const { locations } = this.props

    let loc_id = filters.location_id

    if (loc_id) {
      return loc_id
    }

    return false
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters, page: 1 })
    setTimeout(this.getSubscriptions.bind(null, 1, {}))
  },

  selectSourcesCallBck(obj) {
    this._setFilter({
      sources: obj.id
    })
  },
  _clearSingleFilter(filter) {
    this.state.filters[filter] = undefined
    setTimeout(this.getSubscriptions.bind(null, 1, {}))
  },

  _clearDate() {
    this.state.filters.from = ''
    this.state.filters.to = ''
    setTimeout(this.getSubscriptions.bind(null, 1, {}))
  },

  _clearFilters() {
    const { routerActions } = this.props
    this.setState({ filters: {}, page: 1 })
    setTimeout(this.getSubscriptions.bind(null, 1, {}))
    routerActions.replace(`/admin/crm/winsubscription`)
  },

  changeDate(range) {
    this._setFilter(range)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  _renderFilters() {
    const { locations, location_id } = this.props
    const { filters } = this.state
    const { from, to, sources, status } = filters
    const loc_id = this.getLocationId()
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    let currentSource
    if (sources) {
      currentSource = this.props.sources.find(source => sources == source.id)
    }

    return (
      <div className="nav-section-action-bar select_wrap">
        <div
          className="select-progress"
          style={{ marginRight: 0, display: 'block' }}
        >
          <div style={{ marginBottom: 20 }}>
            <span className="select_title">来源：</span>
            {this.props.sources.map((source, index) => {
              return (
                <span
                  key={index}
                  className={`crm_select ${
                    sources == source.id ? 'select_active' : ''
                  }`}
                  onClick={() => this._setFilter({ sources: source.id })}
                >
                  {source.name}
                </span>
              )
            })}
          </div>
        </div>
        <div
          className="select-progress"
          style={{ marginRight: 0, display: 'block' }}
        >
          <div style={{ marginBottom: 20 }}>
            <span className="select_title">状态：</span>
            {INVOICES_CONTRACT_STATES.map((state, index) => {
              return (
                <span
                  key={index}
                  className={`crm_select ${
                    status == state.id ? 'select_active' : ''
                  }`}
                  onClick={() => this._setFilter({ status: state.id })}
                >
                  {state.name}
                </span>
              )
            })}
          </div>
        </div>
        <div style={{ paddingBottom: 20, borderBottom: 'solid 1px #DFE4E7' }}>
          <span style={{ color: '#999' }}>日期：</span>
          <KBRangeDate
            from={from || ''}
            to={to || ''}
            closeClick={this.closeDropDowns}
            dropDown_name="dateDropDown"
            ref={ref => {
              this.dateDropDown = ref
            }}
            callback={this.changeDate}
          />
        </div>
        {selectFilters && selectFilters.length > 0 ? (
          <div style={{ paddingTop: 20 }}>
            <span className="select_title">条件：</span>
            {filters.sources ? (
              <span className="selected">
                <span style={{ display: 'inline-block', marginRight: 10 }}>
                  {currentSource.name}
                </span>
                <i
                  className="iconfont icon-close select_close"
                  onClick={this._clearSingleFilter.bind(null, 'sources')}
                />
              </span>
            ) : (
              ''
            )}
            {filters.status ? (
              <span className="selected">
                <span style={{ display: 'inline-block', marginRight: 10 }}>
                  {ORG_SUBSCRIPTIONS_STATES[filters.status]}
                </span>
                <i
                  className="iconfont icon-close select_close"
                  onClick={this._clearSingleFilter.bind(null, 'status')}
                />
              </span>
            ) : (
              ''
            )}
            {filters.from ? (
              <span className="selected">
                <span style={{ display: 'inline-block', color: '#999999' }}>
                  创建日期：
                </span>
                <span
                  style={{ display: 'inline-block', marginRight: 10 }}
                >{`${filters.from} - ${filters.to}`}</span>
                <i
                  className="iconfont icon-close select_close"
                  onClick={this._clearDate}
                />
              </span>
            ) : (
              ''
            )}

            <span
              onClick={this._clearFilters}
              className="clearFilters a-hover"
              style={{ marginLeft: 30, color: '#333', display: 'inline-block' }}
            >
              <i className="iconfont icon-delete" style={{ opacity: 0.7 }} />
              <span>清空</span>
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  },

  deleteSubCallback(sub) {
    let { subscriptions } = this.state
    _.remove(subscriptions, function(s) {
      return s.id === sub.id
    })
    this.setState({
      subscriptions
    })
  },

  render() {
    const { subscriptions, pagination, isContractSetting, loading } = this.state
    const newSubscriptions = this.props.subscriptions
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')

    var subscriptionArray = subscriptions
      ? subscriptions.filter(subscription => {
          if (!subscription.organization_id) {
            return false
          }
          if (!subscription.opportunity_id) {
            return false
          }
          if (!this.state.filters.status) {
            return true
          }

          return subscription.status == this.state.filters.status
        })
      : []

    subscriptionArray = subscriptionArray.map(sub => {
      let subscription = newSubscriptions.find(
        subscription => subscription.id == sub.id
      )
      if (subscription) {
        return subscription
      } else {
        return sub
      }
    })

    return (
      <div className="kb-content-container clear-fix">
        <div>
          <header className="nav-header" style={{ borderBottom: 'none' }}>
            <div
              className=" clear-fix"
              style={{ paddingBottom: 30, borderBottom: '1px solid #DFE4E7' }}
            >
              <div className="nav-section-header-title f-left">
                <span>赢单合同</span>
              </div>
            </div>
            <div>{this._renderFilters()}</div>
          </header>
          <div className="nav-section-content-container">
            <KBLoadingContainer loading={loading} style={{ overflowX: 'auto' }}>
              <table className="content-table edit-table">
                <thead>
                  <tr style={{ whiteSpace: 'nowrap' }}>
                    <th>公司名称</th>
                    <th>客户名称</th>
                    <th>编号</th>
                    <th>合同类型</th>
                    <th>状态</th>
                    <th>开始日期</th>
                    <th>终止日期</th>
                    <th>可入驻</th>
                    <th>已入驻</th>
                    <th>会员费总额</th>
                    <th>保证金</th>
                    <th>税额</th>
                    <th>不含税额</th>
                    <th>操作</th>
                    <th />
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={subscriptionArray.length > 0}
                  tableHeadNum="9"
                  tipMessage="暂无赢单数据"
                >
                  {subscriptionArray &&
                    subscriptionArray.map((m, index) => (
                      <InvoicesContractBlock
                        from="crm"
                        isContractSetting={isContractSetting}
                        subscription={m}
                        key={index}
                        loc_id={this.getLocationId()}
                        deleteCallback={this.deleteSubCallback}
                      />
                    ))}
                </KBTableWithoutData>
              </table>
              <KBPagination
                pagination={pagination}
                template={`/admin/crm/winsubscription?page=#PAGE#`}
              />
            </KBLoadingContainer>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let user = getCurrentUser(state)
  let page = parseInt(location.query && location.query.page) || 1
  let subscriptions = getSubScriptions(state)
  let locations = getLocationsAsDictionary(state)
  var FILTER_STATE = ['status', 'from', 'to', 'sources']
  var params = getFilterAndQuery(location.query, FILTER_STATE)
  let sources = getCrmSources(state)
  if (params.filters.status === 'undefined') {
    delete params.filters.status
    delete params.queryAll.status
  }
  if (params.filters.sources === 'undefined') {
    delete params.filters.sources
    delete params.queryAll.sources
  }

  return {
    user,
    subscriptions,
    locations,
    sources,
    page,
    ...params
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSubscriptionsAction: bindActionCreators(
      subscriptionsAction.get,
      dispatch
    ),
    updateSubscriptionsAction: bindActionCreators(
      subscriptionsAction.update,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrmWinSubscriptions)
