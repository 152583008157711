import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { apiCallFailure } from 'app/actions'
import { KBLoadingContainer, KBNoData } from 'components'
import { getServiceErrorMessage } from 'utils/kbUtil'

const VisitInvite = React.createClass({
  getInitialState() {
    return {
      loading: false,
      virtualFloorDetail: {}
    }
  },

  componentDidMount() {
    const { area_id } = this.props
    this.getFloorDetail(area_id)
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.area_id !== nextProps.area_id) {
      this.getFloorDetail(nextProps.area_id)
    }
  },

  getFloorDetail(area_id) {
    const { loc_id, apiCallFailureActions } = this.props

    this.setState({
      loading: true
    })

    api
      .getAreaInfo(area_id, { location_id: loc_id, includes: 'areas' })
      .then(
        json => {
          const virtualFloorDetail = json.response.body || {}
          this.setState({
            virtualFloorDetail
          })
        },
        error => {
          apiCallFailureActions({
            status: 'error',
            message: getServiceErrorMessage(error)
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  render() {
    const { loading, virtualFloorDetail } = this.state

    return (
      <div className="nav-section-content-container">
        <div className="nav-section-action-bar">所含分区</div>
        <KBLoadingContainer loading={loading}>
          <div className="station-all border-none">
            {(virtualFloorDetail.areas &&
              virtualFloorDetail.areas.length > 0 &&
              virtualFloorDetail.areas.map(area => {
                return (
                  <div className="station-box station-empty">
                    <span
                      style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        fontSize: '15px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        marginTop: 30
                      }}
                    >
                      {area.name}
                    </span>
                  </div>
                )
              })) || (
              <KBNoData
                tipMessage="当前虚拟区域暂无分区"
                outterStyle={{ width: '100%' }}
              />
            )}
          </div>
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitInvite)
