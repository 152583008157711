import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBPopover,
  KBLoadingContainer,
  KBPagination
} from 'components'
import { deskActions } from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import DeskForm from './DeskForm'
import DeskList from './DeskList'
import { paginate } from 'utils/kbUtil'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import { getDesksOfAreaId } from 'app/selectors'
import _ from 'lodash'
import { KBNoData } from 'components'

var Desks = React.createClass({
  displayName: 'Desks',
  getInitialState() {
    return {
      loadingDesks: false,
      desks: null,
      pagination: null
    }
  },
  componentWillReceiveProps(nextProps) {
    const { area, desks, area_id, setLoadDeskFalse } = this.props
    // area 是否发生改变
    const isAreaChange = area && !_.isEqual(area, nextProps.area)
    const isDesksChange = area_id
      ? nextProps.desks.length != desks.length
      : !_.isEqual(nextProps.desks, desks)
    if (
      nextProps.isLoadDesk ||
      isDesksChange ||
      (nextProps.area_id !== this.props.area_id &&
        !(isNaN(nextProps.area_id) && isNaN(this.props.area_id))) ||
      nextProps.page !== this.props.page ||
      nextProps.filters !== this.props.filters
    ) {
      setLoadDeskFalse && setLoadDeskFalse()
      this._loadData(
        nextProps.loc_id,
        nextProps.area_id,
        nextProps.page,
        nextProps.filters
      )
    }
  },
  componentDidMount() {
    let { loc_id, area_id, page } = this.props

    this.mounted = true
    this._loadData(loc_id, area_id, page, this.props.filters)
  },

  changeDeskOfList(newDesk, type) {
    const { desks } = this.state
    const isObject = typeof newDesk === 'object'
    let desk_id = isObject ? newDesk.id : newDesk
    const index = desks.findIndex(desk => desk.id == desk_id)
    if (index == -1) {
      return
    }

    var newDesks = Object.assign([], desks)
    if (isObject) {
      newDesks.splice(index, 1, newDesk)
    } else {
      newDesks.splice(index, 1)
    }

    this.setState({
      desks: newDesks
    })
  },

  _loadData(loc_id, area_id, page, filters = {}) {
    const per_page = 80
    const { getDesksAction } = this.props
    this.setState({ loadingDesks: true })
    let params = Object.assign(
      {},
      {
        location_id: loc_id,
        ...filters,
        area_id,
        page,
        per_page,
        includes: 'users,occupancy'
      }
    )
    if (!area_id) {
      params.area_type = 'public_office,private_office,flexible_office,floor'
    }

    api
      .getDesks(params)
      .then(
        resp => {
          let pagination = paginate(resp.response)
          getDesksAction.success(resp, { location_id: loc_id, area_id })
          let desks = getEntitiesJsonArray(resp, 'desks')
          if (this.mounted) {
            this.setState({ pagination: pagination, desks: desks })
          }
          return true
        },
        errors => {
          getDesksAction.failure(errors)
          KBPopover.close()
        }
      )
      .finally(() => {
        if (this.mounted) {
          this.setState({ loadingDesks: false })
        }
      })
  },

  componentWillUnmount() {
    this.mounted = false
    this.setState({ loadingDesks: false })
  },

  render() {
    const { desks, pagination } = this.state
    const { areas, loc_id, queryFiltersStr, area_id, page, filters } = this.props

    return (
      <KBLoadingContainer loading={this.state.loadingDesks}>
        {desks && desks.length > 0 ? (
          <DeskList
            changeDeskOfList={this.changeDeskOfList}
            loc_id={loc_id}
            desks={desks}
            areas={areas}
            loadData={this._loadData}
            area_id={area_id}
            page={page}
            filters={filters}
          />
        ) : (
            <KBNoData
              tipMessage="暂无工位数据"
              outterStyle={{ marginTop: 100 }}
            />
          )}
        <KBPagination
          pagination={pagination}
          template={`/admin/locations/${loc_id}/areas/desks/list${queryFiltersStr ? queryFiltersStr + '&page=#PAGE#' : '?page=#PAGE#'
            }`}
        />
      </KBLoadingContainer>
    )
  }
})

Desks.PropTypes = {
  loc_id: PropTypes.number.isRequired,
  area_id: PropTypes.number.isRequired,
  areas: PropTypes.array,
  location: PropTypes.object,
  page: PropTypes.number,
  filters: PropTypes.object
}

function mapStateToProps(state, props) {
  const desks = getDesksOfAreaId(state, props.area_id) || []

  return {
    desks
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getDesksAction: bindActionCreators(deskActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Desks)
