import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { DEPARTMENT_SELECT_INPUT } from 'app/constants'

var Select = require('react-select')
var KBDepartmentInput = React.createClass({
  getInitialState() {
    return {
      selectedDepartments: this.props.selectedDepartments || []
    }
  },

  handleSelectChange(value) {
    // update select box
    this.setState({
      selectedDepartments: Array.isArray(value) ? value : [value]
    })
    // update form field value
    this._updateFieldValue(value)
  },

  _updateFieldValue(value) {
    if (!value) {
      this.props.field.onChange(null)
      return
    }
    if (Array.isArray(value)) {
      let fieldValue = value.map(v => v.id).join(',')
      this.props.field.onChange(fieldValue)
    } else {
      this.props.field.onChange(value.id)
    }
  },

  componentDidMount() {
    // initialize field value with selected users
    this._updateFieldValue(this.props.selectedDepartments)
  },

  render() {
    const {
      field,
      placeholder,
      title,
      titleClass,
      departments,
      disabled,
      multi
    } = this.props

    const selectedDepartments = this.state.selectedDepartments
    const selectedValue = multi
      ? selectedDepartments
      : selectedDepartments.length > 0
      ? selectedDepartments[0]
      : null
    return (
      <div
        className={classNames(
          this.props.className,
          field.touched && field.error ? 'kb-input-error' : 'kb-input'
        )}
      >
        {title && <label className={titleClass}>{title}</label>}
        <div>
          <Select
            multi={multi}
            labelKey="name"
            valueKey="id"
            placeholder={placeholder || DEPARTMENT_SELECT_INPUT.placeholder}
            noResultsText={DEPARTMENT_SELECT_INPUT.noResultsText}
            disabled={disabled}
            value={selectedValue}
            options={departments}
            onChange={this.handleSelectChange}
          />

          {field.touched && field.error && (
            <p className="lr-error">{field.error}</p>
          )}
        </div>
      </div>
    )
  }
})

KBDepartmentInput.propTypes = {
  field: PropTypes.object.isRequired,
  departments: PropTypes.array.isRequired,
  selectedDepartments: PropTypes.array,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  titleClass: PropTypes.string
}

export default KBDepartmentInput
