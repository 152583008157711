import React from 'react'
import KBDropdown from '../plugins/kbdropdown'

/**
 * 为了要跟以前的样式统一，封装了以前页面中的<KBDropdown>...</KBDropdown>的一大推逻辑
 * 主要用于列表上面的搜索，props如下：
 * options: [{label: '选项1', value: 1},{label: '选项2', value: 2}]
 * value-值
 * placeholder: String
 * onChange: (string | undefiend) => void
 */

const KBSearchSelect = React.createClass({
  getInitialState() {
    return {}
  },

  itemClick(value) {
    const {onChange} = this.props
    this.refs.dropdown.hide()
    onChange && onChange(value)
  },

  render() {
    const { options = [], value, placeholder = '请选择', ...rest } = this.props
    const valueObj =  options.find(i => i.value === value)

    return (
      <KBDropdown ref="dropdown" {...rest}>
        <KBDropdown.KBDropdownTrigger>
          <div className="task-status-title">
            {valueObj ? valueObj.label : placeholder}
            <span className="task-deg" />
          </div>
        </KBDropdown.KBDropdownTrigger>
        <KBDropdown.KBDropdownContent>
          <div>
            <ul className="task-type">
              <li onClick={() => this.itemClick()}>
                <span>全部</span>
              </li>
              {options.map((state) => {
                return (
                  <li
                    key={state.value}
                    onClick={() => this.itemClick(state.value)}
                  >
                    <span>{state.label}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        </KBDropdown.KBDropdownContent>
      </KBDropdown>
    )
  }
})


export default KBSearchSelect
