import React, { Component, PropTypes } from 'react'

var KBFormError = React.createClass({
  fixedRender() {
    const { error } = this.props
    if (!error) {
      return null
    }

    return <div className="kb-box login-error">{error}</div>
  },
  render() {
    const { type } = this.props
    let err = this.props.error

    if (type === 'fixed') {
      return this.fixedRender()
    }

    if (err) {
      return (
        <div className="kb-box login-error">
          <p>{err.message}</p>
          {this.props.children}
        </div>
      )
    } else {
      return null
    }
  }
})

KBFormError.propTypes = {
  error: PropTypes.object
}

export default KBFormError
