import React, { Component, PropTypes } from 'react'
import {
  INVOICE_APPLICATION_STATE,
  INVOICE_APPLICATION_STATE_OBJ
} from 'app/constants'
import {
  KBDropdown,
  KBPagination,
  KBRangeDate,
  KBPopover,
  KBTableWithoutData,
  KBLoadingContainer,
  KBCheckBox,
  KBTipsy
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { api } from 'app/services'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { successState } from 'app/actions'
import { getSerializeLocationObj, getCurrentUser } from 'app/selectors'
import { bindActionCreators } from 'redux'
import {
  fmoney,
  paginate,
  kbCloseDropDown,
  queryFilter,
  getFilterAndQuery,
  formatYearDaySOL,
  formatOnlyMinuteSecondEN
} from 'utils/kbUtil'
import { getInitialDate } from 'utils/kbData'
import InvoicesApplication from './InvoicesApplication'
import InvoicesApplicationFinishForm from './InvoicesApplicationFinishForm'
import InvoicesApplicationAbortForm from './InvoicesApplicationAbortForm'
import OnlyTimeReportForm from '../invoices/OnlyTimeReportForm'
import { canAccessLocationExceptObserver } from 'app/reducers/role'

const DROP_DOWNS = [
  'statusDropdown',
  'applyDateDropDown',
  'location_idDropdown'
]

var InvoicesApplicationList = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      filters,
      invoice_apps: [],
      pagination: null,
      loading: false,
      selectInvoices: [],
      isAllInvoicesChecked: false,
      allCheckedBtn: false
    }
  },

  componentDidMount() {
    this.mounted = true

    this.getApplicationList()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  getApplicationList(newPage) {
    const { filters } = this.state
    const { routerActions, query, location_id } = this.props
    const per_page = 10
    let pageObj = {
      per_page,
      page: newPage || this.props.page
    }

    let params = Object.assign({}, filters, location_id ? { location_id } : {})
    let queryFiltersStr = queryFilter({ ...query, ...params, ...pageObj })

    // 翻页时初始化页面中 check box 的状态
    this.setState({
      loading: true,
      selectInvoices: [],
      isAllInvoicesChecked: false,
      allCheckedBtn: false
    })
    api
      .getInvoiceApplicationList({ ...params, ...pageObj })
      .then(
        json => {
          let pagination = paginate(json.response)
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin${
              this.props.location_id
                ? `/locations/${this.props.location_id}`
                : ''
            }/invoices/invoices_application${queryFiltersStr}`
          )
          if (this.mounted) {
            this.setState({
              invoice_apps: json.json,
              pagination
            })
          }
        },
        errors => {
          console.log(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.getApplicationList(nextProps.page)
    }
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    Object.keys(obj).map(key => {
      if (!obj[key]) {
        delete newFilters[key]
      }
    })
    this.setState({ filters: newFilters })
    this.refs.statusDropdown.hide()
    this.refs.location_idDropdown && this.refs.location_idDropdown.hide()

    setTimeout(this.getApplicationList)
  },

  _clearFilters() {
    const { routerActions } = this.props
    this.setState({ filters: {} })
    setTimeout(this.getApplicationList)
    routerActions.replace(
      `/admin${
        this.props.location_id ? `/locations/${this.props.location_id}` : ''
      }/invoices/invoices_application`
    )
  },

  changeUpdateRange(rage) {
    let applyDateRange = {}
    applyDateRange.start_at = rage.from
      ? getInitialDate(rage.from).initStartDateString
      : null
    applyDateRange.end_at = rage.to
      ? getInitialDate(rage.to).initEndDateString
      : null
    applyDateRange.start_at &&
      applyDateRange.end_at &&
      this._setFilter(applyDateRange)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  _renderFilters() {
    const { locations, location_id } = this.props
    const { filters } = this.state
    const { status, start_at, end_at } = filters

    return (
      <div className="nav-section-action-bar">
        <div className="select-progress">
          {location_id && Object.keys(locations).length > 0 ? (
            <div
              className="task-status-title"
              style={{ display: 'inline-block' }}
            >
              <i className="iconfont icon-position" />
              <span>{locations[location_id].name || ''}</span>
            </div>
          ) : (
            <KBDropdown ref="location_idDropdown">
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(
                    null,
                    'location_idDropdown'
                  )}
                >
                  <i className="iconfont icon-position" />
                  <span>
                    {this.state.filters.location_id &&
                    Object.keys(locations).length > 0
                      ? locations[this.state.filters.location_id].name
                      : '选择分店'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ location_id: '' })}>
                      全部
                    </li>
                    {locations &&
                      Object.keys(locations).map((location_id, index) => {
                        let location = locations[location_id]
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              this._setFilter({ location_id: location.id })
                            }
                          >
                            <span>{location.name}</span>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          )}
          <KBDropdown ref="statusDropdown">
            <KBDropdown.KBDropdownTrigger>
              <div className="task-status-title" style={{ marginLeft: 20 }}>
                <i className="iconfont icon-finance" />
                <span>
                  {status ? INVOICE_APPLICATION_STATE_OBJ[status] : '发票状态'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li onClick={() => this._setFilter({ status: null })}>
                    <span>全部</span>
                  </li>
                  {INVOICE_APPLICATION_STATE.map((state, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => this._setFilter({ status: state.id })}
                      >
                        <span>{state.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
          <KBRangeDate
            from={start_at || ''}
            to={end_at || ''}
            title="选择申请日期"
            callback={this.changeUpdateRange}
            closeClick={this.closeDropDowns}
            dropDown_name="applyDateDropDown"
            ref={ref => {
              this.applyDateDropDown = ref
            }}
            leftNone={false}
          />
        </div>
      </div>
    )
  },

  applyInvoice(invoiceApp) {
    KBPopover.show(
      <InvoicesApplication
        type="update"
        updateList={this.getApplicationList}
        invoiceApp={invoiceApp}
      />
    )
  },

  processInvoiceApp(invoiceApp) {
    let id = invoiceApp.id
    let location_id = invoiceApp.location_id

    KBPopoverConfirm({
      name: '处理开票',
      context: '是否确认处理当前开票？',
      callback: () => {
        return api.processInvoiceApplication(id, { location_id }).then(
          json => {
            this.getApplicationList()
            KBPopover.close()
          },
          errors => {
            KBPopover.close()
          }
        )
      }
    })
  },

  finishInvoiceApp(invoiceApp) {
    let id = invoiceApp.id
    let location_id = invoiceApp.location_id

    KBPopover.show(
      <InvoicesApplicationFinishForm
        isSingle={true}
        id={id}
        location_id={location_id}
        singleCallback={this.getApplicationList}
      />
    )
  },

  checkAllInvoices() {
    const { invoice_apps, allCheckedBtn } = this.state
    let isAllInvoicesChecked_now = !allCheckedBtn
    let invoices_now = invoice_apps
    if (!isAllInvoicesChecked_now) {
      invoices_now = []
    }
    this.setState({
      isAllInvoicesChecked: isAllInvoicesChecked_now,
      selectInvoices: invoices_now,
      allCheckedBtn: isAllInvoicesChecked_now
    })
  },

  changeInvoice(checked, data) {
    var invoices = Object.assign([], this.state.selectInvoices)
    if (checked) {
      invoices.push(data)
      // 当选中的 invoice 逐个增加时的判断
      if (invoices.length == 10) {
        this.setState({
          selectInvoices: invoices,
          allCheckedBtn: true,
          isAllInvoicesChecked: true
        })
      } else {
        this.setState({
          selectInvoices: invoices,
          allCheckedBtn: false
        })
      }

      return
    }

    let removeIndex = invoices.findIndex(json => json.id == data.id)

    if (removeIndex == -1) {
      return
    }

    invoices.splice(removeIndex, 1)
    // 当选中的 invoice 逐个减少时的判断
    if (invoices.length == 10) {
      this.setState({
        selectInvoices: invoices,
        allCheckedBtn: true
      })
    } else {
      this.setState({
        selectInvoices: invoices,
        allCheckedBtn: false
        // isAllInvoicesChecked : false
      })
    }
  },

  getFormatedInvoices() {
    const { selectInvoices } = this.state

    // 对发票按照 location_id 进行分组
    let tempIds = {}
    selectInvoices &&
      selectInvoices.map(json => {
        let tempIdKeys = Object.keys(tempIds)
        let s_id = json.location_id
        if (tempIdKeys.includes(String(s_id))) {
          tempIds[s_id].push(json)
        } else {
          tempIds[s_id] = []
          tempIds[s_id].push(json)
        }
      })

    let arrayedInvoices = Object.values(tempIds)

    let formatedInvoices = []
    arrayedInvoices &&
      arrayedInvoices.map(d => {
        let loc_id = d[0].location_id
        let ids = []
        d.map(data => {
          ids.push(data.sales_invoice_ids[0])
        })
        formatedInvoices.push({ loc_id, ids })
      })

    return formatedInvoices
  },

  batchProcessInvoiceApp(invoices) {
    const { successAction } = this.props

    invoices &&
      invoices.map(json => {
        api.processInvoiceApplicationBatch({
          location_id: json.loc_id,
          ids: json.ids.join(',')
        })
      })
    setTimeout(() => {
      this._setFilter({})
      this.setState({
        selectInvoices: []
      })
      KBPopover.close()
      successAction({ message: '处理开票成功' })
    }, 500)
  },

  batchFinishCallback() {
    const { successAction } = this.props

    setTimeout(() => {
      this._setFilter({})
      this.setState({
        selectInvoices: []
      })
      KBPopover.close()
      successAction({ message: '完成开票成功' })
    }, 500)
  },

  batchFinishInvoiceApp(invoices) {
    KBPopover.show(
      <InvoicesApplicationFinishForm
        location_id={this.props.location_id}
        locIdGroupedInvoices={invoices}
        callback={this.batchFinishCallback}
      />
    )
  },

  processAndFinishInvoice(type) {
    let formatedInvoices = this.getFormatedInvoices()

    if (type == 'process') {
      return KBPopoverConfirm({
        name: '发票处理开票',
        context: '是否确定所选发票全部处理开票?',
        callback: () => {
          return this.batchProcessInvoiceApp(formatedInvoices)
        }
      })
    }

    return this.batchFinishInvoiceApp(formatedInvoices)
  },

  abortInvoice(app) {
    const { selectInvoices } = this.state
    const { location_id } = this.props

    KBPopover.show(
      <InvoicesApplicationAbortForm
        location_id={location_id}
        invoiceApps={app ? [app] : selectInvoices}
        callback={() => {
          setTimeout(() => {
            this._setFilter({})
            this.setState({
              selectInvoices: []
            })
          }, 500)
        }}
      />
    )
  },

  isAuditInvoice() {
    const { selectInvoices } = this.state
    const { canOperate } = this.props

    if (selectInvoices.length === 0 || !canOperate) {
      return false
    }

    let isAllPending = selectInvoices.every(ele => {
      return ele.status === 'pending'
    })
    let isAllProcess = selectInvoices.every(ele => {
      return ele.status === 'process'
    })
    let isAllIssued = selectInvoices.every(ele => {
      return ele.status === 'issued'
    })

    let isAllNoXforcePlus = selectInvoices.every(ele=>{
      return ele.system_type !== 'xforceplus'
    })
    console.log('isAllNoXforcePlus:',isAllNoXforcePlus);

    return (
      <div className="invoice-table-upon" style={{ paddingTop: 15 }}>
        {isAllPending ? (
          <button
            className="bordered-btn m-none"
            onClick={() => {
              this.processAndFinishInvoice('process')
            }}
          >
            处理开票
          </button>
        ) : null}
        {isAllProcess ? (
          <button
            className="bordered-btn m-none"
            onClick={() => {
              this.processAndFinishInvoice('finish')
            }}
          >
            完成开票
          </button>
        ) : null}
        {isAllIssued && isAllNoXforcePlus ? (
          <button
            className="bordered-btn m-none"
            onClick={() => {
              this.abortInvoice()
            }}
          >
            作废发票
          </button>
        ) : null}
      </div>
    )
  },

  getReport() {
    const { location_id } = this.props
    KBPopover.show(
      <OnlyTimeReportForm loc_id={location_id} type="invoice_app" />
    )
  },

  render() {
    const { invoice_apps, pagination, loading, selectInvoices } = this.state
    const { canOperate } = this.props
    const canOperateStatusArr = ['pending', 'process', 'issued']

    return (
      <div className="kb-content-container clear-fix">
        <div>
          <header className="nav-header">
            <div className=" clear-fix">
              <div className="nav-section-header-title f-left">
                <span>发票</span>
              </div>
              <button
                className="c-btn-secondary f-right"
                onClick={this.getReport}
              >
                导出发票信息
              </button>
            </div>
          </header>
          <div className="nav-section-content-container">
            <div>
              {this._renderFilters()}
              {Object.keys(this.state.filters).length > 0 ? (
                <div className="clear-criteria" onClick={this._clearFilters}>
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              ) : null}
            </div>
            <KBLoadingContainer loading={loading} style={{ overflowX: 'auto' }}>
              {this.isAuditInvoice()}
              <table className="content-table" style={{ whiteSpace: 'nowrap' }}>
                <thead>
                  <tr>
                    <th>
                      <KBCheckBox
                        style={{ marginTop: 2 }}
                        data={invoice_apps}
                        callback={this.checkAllInvoices}
                        checked={this.state.allCheckedBtn}
                      />
                    </th>
                    <th>分店</th>
                    <th>发票抬头</th>
                    <th>发票内容</th>
                    <th>开票状态</th>
                    <th>发票号</th>
                    <th>申请时间</th>
                    <th>联系电话</th>
                    <th>纳税人识别号</th>
                    <th>发票金额</th>
                    <th>备注</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={invoice_apps.length > 0}
                  tableHeadNum="12"
                  tipMessage="暂无发票"
                >
                  {invoice_apps &&
                    invoice_apps.map((app, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            position: 'static',
                            verticalAlign: 'middle'
                          }}
                          onClick={e => e.stopPropagation()}
                        >
                          <KBCheckBox
                            style={{ marginTop: 2 }}
                            data={app}
                            callback={this.changeInvoice}
                            checked={
                              (selectInvoices &&
                                selectInvoices.length &&
                                selectInvoices.find(data => {
                                  return data.id === app.id
                                })) ||
                              false
                            }
                          />
                        </td>
                        <td>{app.location_name}</td>
                        <td>
                          {app.invoice_title ? (
                            <KBTipsy content={app.invoice_title}>
                              <span style={{ display: 'inline-block' }}>
                                {app.invoice_title}
                              </span>
                            </KBTipsy>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td>{app.invoice_content || '-'}</td>
                        <td>
                          <span
                            className={`invoice-application-state invoice-application-state-${
                              app.status ? app.status : 'none'
                            }`}
                          >
                            {app.status
                              ? INVOICE_APPLICATION_STATE_OBJ[app.status]
                              : '未申请'}
                          </span>
                        </td>
                        <td>
                          <div>
                            <div
                              className="t-over"
                              style={{ marginBottom: 10, width: 140 }}
                            >
                              <KBTipsy content={app.invoice_number}>
                                <span style={{ display: 'inline-block' }}>
                                  {app.invoice_number || '-'}
                                </span>
                              </KBTipsy>
                            </div>
                            <span className="color-nine">
                              开票日期:
                              {app.invoice_date
                                ? formatYearDaySOL(app.invoice_date)
                                : '-'}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div style={{ marginBottom: 10 }}>
                            {app.created_at
                              ? formatYearDaySOL(app.created_at)
                              : '-'}
                          </div>
                          <div>
                            {app.created_at
                              ? formatOnlyMinuteSecondEN(app.created_at)
                              : '-'}
                          </div>
                        </td>
                        <td>{app.phone_num || '-'}</td>
                        <td>
                          {app.tax_num ? (
                            <div className="t-over" style={{ width: 155 }}>
                              <KBTipsy content={app.tax_num}>
                                <span style={{ display: 'inline-block' }}>
                                  {app.tax_num}
                                </span>
                              </KBTipsy>
                            </div>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td className="color-red">
                          ¥&nbsp;{fmoney(app.total_amount || 0)}
                        </td>
                        <td>
                          {app.notes ? (
                            <KBTipsy content={app.notes}>
                              <div className="t-over" style={{ width: 40 }}>
                                <span style={{ display: 'inline-block' }}>
                                  {app.notes}
                                </span>
                              </div>
                            </KBTipsy>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td>
                          {canOperateStatusArr.includes(app.status) &&
                          canOperate&&app.system_type!=='xforceplus' ? (
                            <KBDropdown>
                              <KBDropdown.KBDropdownTrigger>
                                <div className="select-drop_down">
                                  <div className="select-dropDown_left">
                                    选择
                                  </div>
                                  <div className="select-dropDown_right">
                                    <i className="iconfont icon-arrow_drop_down_circle" />
                                  </div>
                                </div>
                              </KBDropdown.KBDropdownTrigger>
                              <KBDropdown.KBDropdownContent
                                style={{
                                  left: '50%',
                                  transform: 'translateX(-50%)'
                                }}
                              >
                                <div className="user-dropdown">
                                  {app.status === 'pending' ? (
                                    <a
                                      onClick={this.applyInvoice.bind(
                                        null,
                                        app
                                      )}
                                    >
                                      编辑
                                    </a>
                                  ) : null}
                                  {app.status === 'pending' ? (
                                    <a
                                      onClick={this.processInvoiceApp.bind(
                                        null,
                                        app
                                      )}
                                    >
                                      处理开票
                                    </a>
                                  ) : null}
                                  {app.status === 'process' ? (
                                    <a
                                      onClick={this.finishInvoiceApp.bind(
                                        null,
                                        app
                                      )}
                                    >
                                      完成开票
                                    </a>
                                  ) : null}
                                  {app.status === 'issued' ? (
                                    <a
                                      onClick={this.abortInvoice.bind(
                                        null,
                                        app
                                      )}
                                    >
                                      作废发票
                                    </a>
                                  ) : null}
                                </div>
                              </KBDropdown.KBDropdownContent>
                            </KBDropdown>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                </KBTableWithoutData>
              </table>
            </KBLoadingContainer>
            <KBPagination
              pagination={pagination}
              template={`/admin${
                this.props.location_id
                  ? `/locations/${this.props.location_id}`
                  : ''
              }/invoices/invoices_application?page=#PAGE#`}
            />
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location, params } = props
  let location_id = params.id
  let user = getCurrentUser(state)
  let page = parseInt(location.query && location.query.page) || 1
  let locations = getSerializeLocationObj(state)
  const canOperate = canAccessLocationExceptObserver(user, location_id)
  var FILTER_STATE = ['status', 'start_at', 'end_at', 'location_id']

  var param = getFilterAndQuery(location.query, FILTER_STATE)
  if (param.filters.status == 'null') {
    param.filters.status = null
  }

  return {
    user,
    page,
    location_id,
    locations,
    canOperate,
    ...param
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesApplicationList)
