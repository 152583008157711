import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { invoicesActions, apiCallFailure, successState } from 'app/actions'
import {
  KBInput,
  KBTextarea,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput
} from 'components'
import InvoicesRecipientList from './InvoicesRecipientList'
import { getInvoiceOfId } from 'app/selectors'

var InvoicesSendEmailForm = React.createClass({
  getInitialState() {
    return {
      selectEmail: this.props.InvoiceRecipientsEmail || [],
      templates: []
    }
  },

  componentDidMount() {
    const {
      fields: { bill_email },
      invoice,
      getInvoicesActions,
      invoice_id,
      invoice_ids
    } = this.props

    if (invoice_ids) {
      return
    }

    const { selectEmail } = this.state
    let recipientsEmail = []
    api.getInvoice(invoice_id).then(
      json => {
        getInvoicesActions.success(json)
        if (selectEmail) {
          selectEmail.map(recipient => {
            if (recipient.email && recipient.email.length > 0) {
              recipientsEmail.push(recipient.email)
            }
          })
          bill_email.onChange(recipientsEmail)
        }
      },
      errors => {
        getInvoicesActions.failure(errors)
      }
    )

    this.loadTemplates()
  },

  loadTemplates() {
    api.getTemplates().then(
      json => {
        let templates = json.response.body || []
        this.setState({ templates })
      },
      errors => {}
    )
  },

  changeBillEmailValue(EmailMessage) {
    const {
      fields: { bill_email }
    } = this.props
    const { selectEmail } = this.state
    let recipientsEmail = []
    if (selectEmail) {
      selectEmail.map(recipient => {
        if (EmailMessage.value.length > 0) {
          if (EmailMessage.name == recipient.name) {
            recipient.email = EmailMessage.value
          }
          recipientsEmail.push(recipient.email)
        }
      })
      bill_email.onChange(recipientsEmail)
    }
  },

  sendEmail(model) {
    const {
      invoice_id,
      updateInvoicesActions,
      apiCallFailureActions,
      invoice_ids,
      successActions
    } = this.props

    if (!model.bill_email || model.bill_email.length == 0) {
      apiCallFailureActions({
        status: 'error',
        message: '您没有选择任何邮箱!'
      })
      return
    }

    if (invoice_ids) {
      model.invoice_ids = invoice_ids
      delete model.template_id
      return api.putBatchSendInvoicesEmail(model).then(
        json => {
          updateInvoicesActions.success(json)
          successActions({ message: '账单发送成功' })
          KBPopover.close()
        },
        errors => {
          updateInvoicesActions.failure(errors)
        }
      )
    }

    model.reminder_approach = 'email'
    if (typeof model.bill_email != 'string') {
      model.bill_email = model.bill_email.join(',')
    }
    if (!model.template_id) {
      // apiCallFailureActions({
      //   status: 'error',
      //   message :'您没有选择任何邮箱!'
      // })
      delete model.template_id
      // return
    }
    return api.putSendRemind(invoice_id, model).then(
      json => {
        updateInvoicesActions.success(json)
        successActions({ message: '账单发送成功' })
        KBPopover.close()
      },
      errors => {
        updateInvoicesActions.failure(errors)
      }
    )
  },

  recipientEmailCallback(checked, recipient) {
    const {
      fields: { bill_email }
    } = this.props
    const { selectEmail } = this.state

    let recipientsEmailArray = Object.assign([], selectEmail)
    let recipientEmailUrl = []

    if (checked) {
      recipientsEmailArray.push(recipient)
      recipientsEmailArray.map(recipientEmail => {
        recipientEmailUrl.push(recipientEmail.email)
      })
      this.setState({
        selectEmail: recipientsEmailArray
      })
      bill_email.onChange(recipientEmailUrl)
      return
    }

    let removeIndex = recipientsEmailArray.findIndex(
      recipientEmail => recipientEmail.id == recipient.id
    )

    if (removeIndex == -1) {
      return
    }

    recipientsEmailArray.splice(removeIndex, 1)
    recipientsEmailArray.map(recipientEmail => {
      recipientEmailUrl.push(recipientEmail.email)
    })
    this.setState({
      selectEmail: recipientsEmailArray
    })
    bill_email.onChange(recipientEmailUrl)
  },

  render() {
    const {
      fields: {
        bill_email,
        reminder_approach,
        message_to_customer,
        template_id
      },
      submitting,
      handleSubmit,
      invoice,
      loc_id,
      org_id,
      invoice_ids
    } = this.props
    const { templates } = this.state
    const sysTemplate = [{ id: '', name: '系统模板' }]

    return (
      <form onSubmit={handleSubmit(this.sendEmail)}>
        <header className="kb-form-header">
          <h2 className="header-title">{`${
            invoice_ids ? '批量' : ''
          }发送账单`}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <ul className="recipients-list">
            {!invoice_ids &&
            invoice.recipients &&
            invoice.recipients.length > 0 ? (
              invoice.recipients.map((recipient, index) => {
                return (
                  <InvoicesRecipientList
                    recipient={recipient}
                    bill_email={bill_email}
                    loc_id={loc_id}
                    org_id={org_id}
                    invoice_id={invoice.id}
                    changeBillEmailValue={this.changeBillEmailValue}
                    callback={this.recipientEmailCallback}
                  />
                )
              })
            ) : (
              <KBInput
                className="kb-form-group kb-form-c39 kb-form-alone"
                field={bill_email}
                title="接收人Email地址"
              />
            )}
          </ul>
          {invoice_ids ? null : (
            <div className="kb-row">
              <KBUserInput
                title="选择模板"
                className="kb-form-group kb-form-c39 kb-form-alone"
                field={template_id}
                multi={false}
                users={sysTemplate.concat(templates)}
                selectedUsers={sysTemplate}
              />
            </div>
          )}
          <div className="kb-row">
            <KBTextarea
              field={message_to_customer}
              title="写给客户的信息"
              className="kb-form-group kb-form-c39 kb-form-alone"
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const { invoice_id, invoice_ids } = ownProps

  if (invoice_ids) {
    return {
      invoice_ids
    }
  }

  const invoice = getInvoiceOfId(state, invoice_id)
  const { location_id, organization_id } = invoice

  let InvoiceRecipientsEmail = []
  invoice.recipients &&
    invoice.recipients.map(recipient => {
      if (recipient.email && recipient.email.length > 0) {
        InvoiceRecipientsEmail.push(recipient)
      }
    })

  return {
    invoice,
    InvoiceRecipientsEmail,
    loc_id: location_id,
    org_id: organization_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch),
    getInvoicesActions: bindActionCreators(invoicesActions.get, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    successActions: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  // if (valid.required(values.bill_email)) {
  //   errors.bill_email = '请填写接收人Email地址'
  // }

  return errors
}

const formConfig = {
  form: 'InvoicesSendEmailForm',
  fields: [
    'bill_email',
    'reminder_approach',
    'message_to_customer',
    'invoice_ids',
    'template_id'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoicesSendEmailForm)
