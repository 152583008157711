import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import {  successState, apiCallFailure } from 'app/actions'
import { copyTextToClipboard } from 'utils/kbUtil'
import {
  KBLoadingContainer,
  KBStatisticNumber,
  KBPopover
} from 'components'
import * as selectors from 'app/selectors'
import moment from 'moment'
import { Button, Row, Col, Descriptions, message, Tag } from 'antd'
import ListingPopover from './ListingPopover'
import { allStatus, allTypes, userTypes } from './constants'
import _ from 'lodash';
import ExportButton from './ExportButton';

let CouponInfo = React.createClass({

  getInitialState() {
    return {
      loading: true,
      signInCode: '',
      showSignInCode: false,
      activityDetailCode: '',
      showActivityDetailCode: false,
      info: {}
    }
  },
  componentDidMount() {
    this.getData()
  },

  getData() {
    const { couponId } = this.props
    api
      .getCouponTemplateInfo(couponId)
      .then(
        json => {
          this.setState({
            info: json.json
          })
        },
        error => {
          this.props.apiCallFailureAction(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  toCouponList() {
    const { routerActions, locationId } = this.props
    if (locationId) {
      routerActions.push(`/admin/locations/${locationId}/coupon`)
    } else {
      routerActions.push(`/admin/coupon`)
    }
  },

  toEditActivity() {
    // const { routerActions, locationId, activity } = this.props
    // const { id } = activity
    // if (locationId) {
    //   routerActions.push(
    //     `/admin/locations/${locationId}/activities/edit?act_id=${activity.id}`
    //   )
    // } else {
    //   routerActions.push(
    //     `/admin/memberships/memberManage/activities/edit?act_id=${activity.id}`
    //   )
    // }
  },

  toOrder() {
    const { routerActions, locationId, couponId } = this.props
    if (locationId) {
      routerActions.push(
        `/admin/locations/${locationId}/coupon/${couponId}/orders`
      )
    } else {
      routerActions.push(
        `/admin/coupon/${couponId}/orders`
      )
    }
  },

  getQrCode(type) {
    const { activity } = this.props
    const { showSignInCode, showActivityDetailCode } = this.state
    if (
      (type === 'signInCode' && !showSignInCode) ||
      (type === 'activityDetailCode' && !showActivityDetailCode)
    ) {
      if (type === 'signInCode') {
        this.setState({
          signInCode: activity.bridgego_signin_qrcode,
          showSignInCode: true,
          showActivityDetailCode: false
        })
      } else {
        this.setState({
          activityDetailCode: activity.bridgego_qrcode,
          showActivityDetailCode: true,
          showSignInCode: false
        })
      }
    } else {
      this.setState({ showSignInCode: false, showActivityDetailCode: false })
    }
  },

  downloadCodeImg(path) {
    // let path = 'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/kade/prod/uploads/locations/84f1302c0-8f74-4a0a-a5e5-c1189ee98c54no0.png'
    let filename //图片名称
    let filetype //图片类型
    if (path.indexOf('/') > 0) {
      let file = path.substring(path.lastIndexOf('/') + 1, path.length)
      let fileArr = file.toLowerCase().split('.')
      filename = fileArr[0]
      filetype = fileArr[1]
    }
    let canvas = document.createElement('canvas')
    let img = document.createElement('img')
    img.setAttribute('crossOrigin', 'Anonymous')
    img.src = path
    img.onload = function(e) {
      canvas.width = img.width
      canvas.height = img.height
      let context = canvas.getContext('2d')
      context.drawImage(img, 0, 0, img.width, img.height)
      canvas.toBlob(blob => {
        let a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = filename
        a.click()
      }, `image/${filetype}`)
    }
    img.onerror = function(e) {
      // 图片可能因为跨域问题下载失败，手动
      window.open(path)
    }
  },

  copyText() {
    const text = this.state.info.short_link
    copyTextToClipboard(text)
      .then(() => {
        message.success('复制成功')
      })
      .catch(err => {
        alert(`复制文本失败，请手动复制：${text}`)
      })
  },

  onSale() {
    KBPopover.show(<ListingPopover couponInfo={this.state.info} callBack={this.getData} />)
  },

  offSale() {
    KBPopover.plugins.confirm(
      '下架',
      '优惠券下架后将无法领取，已领取的优惠券仍可使用。请确认是否下架？',
      {
        confirm: () => {
          api
            .downCouponTemplate(this.state.info.id)
            .then(
              json => {
                this.props.successActions({ message: '下架成功' })
                this.getData()
              },
              error => {
                this.props.apiCallFailureAction(error)
              }
            )
            .finally(() => {
              KBPopover.close()
            })
        }
      }
    )
  },

  toCouponForm() {
    const { routerActions, locationId } = this.props
    const {info} = this.state
    if (locationId) {
      routerActions.push(`/admin/locations/${locationId}/coupon/couponForm?fid=${info.id}`)
    } else {
      routerActions.push(`/admin/coupon/couponForm?fid=${info.id}`)
    }
  },

  getTag() {
    const { info } = this.state
    const statusTag = {
      expired: <Tag>已过期</Tag>,
      down: <Tag>已下架</Tag>,
      up: <Tag color="green">已上架</Tag>,
      draft: <Tag color="orange">草稿</Tag>
    }
    return statusTag[info.status]
  },

  getOrderPageUrl() {
    const { locationId } = this.props
    const { info } = this.state
    if(locationId) {
      return `/admin/locations/${locationId}/coupon/${info.id}/orders`
    } else {
      return `/admin/coupon/${info.id}/orders`
    }
  },

  render() {
    const { loading, info } = this.state

    return (
      <div
        className="nav-section-container f-right"
        style={{ height: '100%', overflow: 'auto' }}
      >
        <KBLoadingContainer loading={loading}>
          <header className="nav-section-header clear-fix border-bottom">
            <div className="nav-section-header-title f-left">
              <span
                className="color-nine c-pointer a-hover"
                onClick={this.toCouponList}
              >
                优惠券
              </span>
              <i
                className="iconfont icon-navigate_next color-nine"
                style={{ marginLeft: 15, marginRight: 15 }}
              />
              <span style={{ marginRight: 10 }}>{info.name}</span>
              {this.getTag()}
            </div>
            <div className="f-right">
              {info.status !== 'draft' && (
                <ExportButton couponId={info.id} />
              )}
              {info.status === 'draft' && (
                <span
                  className="bordered-btn f-right"
                  onClick={this.toCouponForm}
                >
                  编辑
                </span>
              )}
              {info.status === 'up' && (
                <dev
                  className="bordered-btn-end f-right"
                  onClick={this.offSale}
                >
                  下架
                </dev>
              )}

              {info.status === 'draft' && (
                <dev className="bordered-btn f-right" onClick={this.onSale}>
                  上架
                </dev>
              )}
            </div>
          </header>
          <div className="activity-chart" style={{textAlign: 'center'}}>
            <div className="chart-box">
              <KBStatisticNumber
                number={info.total_number || 0}
                name="总券数"
              />
            </div>
            <div className="chart-box">
              <KBStatisticNumber
                number={info.user_coupon_templates_count || 0}
                name="领券数"
              />
            </div>
            <div className="chart-box" onClick={this.toOrder}>
              <KBStatisticNumber
                number={info.user_coupon_templates_used_count || 0}
                name="用券数"
              />
            </div>
          </div>
          <div style={{ padding: 30 }}>
            <Row type="flex" justify="space-between">
              <Col>
                <img
                  src={
                    info.cover
                  }
                  style={{ height: 200 }}
                />
              </Col>
              {
                info.status !== 'draft' && (
                  <Col>
                    <div>
                      <img
                        src={info.bridgego_qrcode}
                        style={{ height: 200, display: 'block', margin: '0 auto' }}
                      />
                    </div>
                    <Button type="link" onClick={() => this.downloadCodeImg(info.bridgego_qrcode)}>下载优惠券二维码</Button>
                    <Button type="link" onClick={this.copyText}>
                      复制优惠券链接
                    </Button>
                  </Col>
                )
              }
            </Row>

            <Descriptions title="详细信息" layout="vertical" column={2} bordered style={{marginTop: 40}}>
              <Descriptions.Item label="类型">{_.get(allTypes.find(i => i.value === info.coupon_type), 'title')}</Descriptions.Item>
              <Descriptions.Item label="是否展示在领券中心">{_.get(info, 'display') ? '是' : '否'}</Descriptions.Item>
              <Descriptions.Item label="折扣">{info.coupon_discount}折</Descriptions.Item>
              <Descriptions.Item label="适用范围">
                <div style={{paddingRight: 20}}>
                {((_.get(info.areas, 'length') ? info.areas : info.locations) || []).map(i => i.name).join('、')}
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="价格">
                {info.price || '免费'}
              </Descriptions.Item>
              <Descriptions.Item label="领取有效期">{`${moment(info.start_at).format('YYYY年MM月DD日')}-${moment(info.end_at).format('YYYY年MM月DD日')}`}</Descriptions.Item>
              <Descriptions.Item label="使用有效期">{`${moment(info.expire_start_at).format('YYYY年MM月DD日')}-${moment(info.expire_end_at).format('YYYY年MM月DD日')}`}</Descriptions.Item>
              <Descriptions.Item label="领取对象">{_.get(userTypes.find(i => i.value === info.user_type), 'title')}</Descriptions.Item>
              <Descriptions.Item label="单人限领次数">{info.coupon_limit}</Descriptions.Item>
              <Descriptions.Item label="领券数/总券数">
                <Link className="color-link" to={this.getOrderPageUrl()}>
                  {`${info.user_coupon_templates_count}/${info.total_number}`}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label="用券数/领券数">
                <Link className="color-link" to={this.getOrderPageUrl()}>
                  {`${info.user_coupon_templates_used_count}/${info.user_coupon_templates_count}`}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label="使用规则" span={2}>
                {info.rules || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="审批附件">
                {
                  info.approved_file ? <a className="color-link" target='_blank' href={info.approved_file} style={{wordBreak: 'break-all'}}>{info.approved_file}</a> : <div style={{wordBreak: 'break-all'}}>-</div>
                }

              </Descriptions.Item>
            </Descriptions>
          </div>
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const {  params } = props
  const { couponId, id: locationId } = params
  return {
    locationId,
    couponId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
  }
}

CouponInfo = connect(mapStateToProps, mapDispatchToProps)(CouponInfo)

export default CouponInfo
