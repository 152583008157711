import React, { Component, PropTypes } from 'react'
import ReactDOM from 'react-dom'
import ReactCrop from 'react-image-crop'
import { util } from 'utils/kbUtil'

var KBImageCropper = React.createClass({
  getInitialState: function() {
    return {
      crop: {
        x: 35,
        y: 35,
        width: 30,
        height: 30,
        aspect: this.props.aspect
      },
      width: 0,
      height: 0
    }
  },

  // cropper has finished selection
  onComplete(crop) {
    let imageDesc = ReactDOM.findDOMNode(this.refs.croppedImage)
    this.cropImage(imageDesc, this.props.dataURL, crop)
  },

  loadImage: function(src, callback) {
    var image = new Image()
    image.onload = function(e) {
      callback(image)
      image = null
    }

    image.src = src
  },

  cropImage: function(imgDest, imgSrc, crop) {
    this.loadImage(imgSrc, cropAfterLoad.bind(this))
    function cropAfterLoad(loadedImg) {
      var imageWidth = loadedImg.naturalWidth
      var imageHeight = loadedImg.naturalHeight
      var cropX = (crop.x / 100) * imageWidth
      var cropY = (crop.y / 100) * imageHeight

      var cropWidth = (crop.width / 100) * imageWidth
      var cropHeight = (crop.height / 100) * imageHeight

      var canvas = document.createElement('canvas')
      canvas.width = cropWidth
      canvas.height = cropHeight
      var ctx = canvas.getContext('2d')

      ctx.drawImage(
        loadedImg,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      )
      // canvas.toBlob will be faster and non-blocking but is currently only supported in FF.
      // 会存在jpg格式裁剪之后图片文件大小变大的问题，这里根据源图片的尺寸来裁剪
      const resultFormat = this.props.expand === '.png' ? 'image/png' : 'image/jpeg'
      let url = canvas.toDataURL(resultFormat)
      imgDest.src = url
    }
  },
  onImageLoaded(crop, image) {
    let imageDesc = ReactDOM.findDOMNode(this.refs.croppedImage)
    this.cropImage(imageDesc, image.src, crop)
  },

  // User confirmed crop
  onCompleteCrop(crop) {
    const { dataURL } = this.props
    let imageDesc = ReactDOM.findDOMNode(this.refs.croppedImage)
    let croppedDataURL = imageDesc && imageDesc.src
    this.props.onCrop(croppedDataURL, dataURL)
  },

  render() {
    var {
      dataURL,
      onCancelCrop,
      aspect,
      previewWidth,
      previewHeight,
      previewBorderRadius
    } = this.props

    const { width, height } = this.state

    return (
      <div>
        <div className="kb-form-header">
          <h2 className="header-title">图片编辑</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={onCancelCrop}
          />
        </div>
        <div className="logo-box" style={{ textAlign: 'center' }}>
          <ReactCrop
            crop={this.state.crop}
            src={dataURL}
            aspect={aspect}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onComplete}
          />
        </div>
        <div className="kb-form-container">
          <div className="kb-title">
            <span>图片预览</span>
          </div>
          <div className="certain-logo">
            <img
              ref="croppedImage"
              alt="请选择区域"
              style={{
                background: '#ddd',
                height: previewHeight,
                width: previewWidth,
                borderRadius: previewBorderRadius
              }}
            />
          </div>
          <div className="kb-row f-right">
            <span className="cancel-btn" onClick={onCancelCrop}>
              取消
            </span>
            <button className="certain-btn" onClick={this.onCompleteCrop}>
              确定
            </button>
          </div>
        </div>
      </div>
    )
  }
})

KBImageCropper.PropTypes = {
  dataURL: PropTypes.string.isRequired,
  expand: PropTypes.string.isRequired,
  aspect: PropTypes.number,
  onCrop: PropTypes.func.isRequired,
  onCancelCrop: PropTypes.func.isRequired,
  previewWidth: PropTypes.string,
  previewHeight: PropTypes.string
}

KBImageCropper.defaultProps = {
  aspect: true,
  previewWidth: 'auto',
  previewHeight: '115px'
}

export default KBImageCropper
