import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { KBInput, KBFormError, KBButton } from 'components'

var ResetPassword = React.createClass({
  resetPassword(model) {
    return api
      .resetPassword({
        reset_password_token: this.props.token,
        password: model.password,
        password_confirmation: model.password_confirmation
      })
      .then(json => {
        this.props.routerActions.push('/reset_password_success')
      })
  },
  render() {
    const {
      fields: { password, password_confirmation },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <div className="lr-out-box">
        <div className="comment-box1">
          <div className="kb-box box1-header">
            <span className="lr-text">设置新密码</span>
          </div>
          <form onSubmit={handleSubmit(this.resetPassword)}>
            <KBFormError err={this.props.error} />
            <KBInput
              type="password"
              field={password}
              placeholder="输入新密码"
            />
            <KBInput
              type="password"
              field={password_confirmation}
              placeholder="再次输入新密码"
            />
            <div className="kb-box">
              <KBButton type="submit" disabled={submitting}>
                {submitting ? '修改中' : '修改密码'}
              </KBButton>
            </div>
          </form>

          <div className="kb-box register-exist-account">
            <Link to="/send_reset_password">返回上一级</Link>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const token = ownProps.location.query.reset_password_token || ''
  return {
    token
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.password)) {
    errors.password = '请输入新密码'
  } else if (
    valid.minLength(6)(values.password) ||
    valid.maxLength(20)(values.password)
  ) {
    errors.password = '密码必须在6-20字符之间'
  } else if (values.password !== values.password_confirmation) {
    errors.password_confirmation = '两次输入的密码不一致'
  }
  return errors
}

const formConfig = {
  form: 'resetPassword',
  fields: ['password', 'password_confirmation'],
  touchOnBlur: false,
  validate: validate
}
ResetPassword = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword)

export default ResetPassword
