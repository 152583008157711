import React from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { orgActions, apiCallFailure } from 'app/actions'
import {
  INVOICES_UPDATE_TYPE,
  LINE_ITEM_TYPES,
  LINE_ITEM_TYPES_OBJ,
  INCOME_CONFIRM_STATE_OBJ,
  INCOME_CONFIRM_STATE
} from 'app/constants'
import * as selectors from 'app/selectors'
import {
  KBTableWithoutData,
  KBLoadingContainer,
  KBDropdown,
  KBStatisticNumber,
  KBPopover,
  KBPopoverTop,
  KBToReportCenter,
  KBRangeDate,
  KBPagination,
  KBCheckBox,
  KBButton
} from 'components'
import { KBDropSelect } from 'components/plugins'
import { KBPopoverConfirm } from 'components/tools'
import { KBDayPicker } from 'components/views'
import {
  queryFilter,
  getFilterAndQuery,
  kbCloseDropDown,
  fmoney,
  getServiceErrorMessage,
  formatYearDayEN,
  paginate
} from 'utils/kbUtil'
import moment from 'moment'
import { Object } from 'core-js'
import InvoiceConfirmIncomeForm from './InvoiceConfirmIncomeForm'
import _ from 'lodash'
import { DatePicker, Calendar, Select, Radio, Col, Row,Modal } from 'antd'
import 'antd/dist/antd.css'

const DROP_DOWNS = [
  'location_idDropdown',
  'dateDropDown',
  'paidAtDateDropDown',
  'company',
  'invoiceType',
  'lineItemType',
  'incomeConfirm',
  'incomeConfirmDateDropDown',
  'createDateDropDown'
]

var StatisticsInvoiceConfirmed = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      loading: false,
      pagination: null,
      filters,
      invoices: [],
      selectInvoices: [],
      isAllInvoicesChecked: false,
      allCheckedBtn: false,
      isOnce:true
    }
  },

  componentDidMount() {
    this.mounted = true
    const {filters} =this.state
    // 给定默认日期
    const date = {
      start_date:moment().startOf('month').format('YYYY-MM-DD'),
      end_date:moment().endOf('month').format('YYYY-MM-DD')}
    const filter = {...filters,...date}
    this.setState({filters:filter},()=>{
      this.loadData()
    })

  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page != this.props.page) {
      // 翻页时初始化页面中 check box 的状态
      this.setState({
        selectInvoices: [],
        isAllInvoicesChecked: false,
        allCheckedBtn: false
      })
      this.loadData(null, nextProps.page)
    }
  },

  getOrganizations() {
    const { getOrganizationsAction, location_id } = this.props
    api
      .getOrganizations({
        location_id,
        per_page: 1000,
        includes: 'details',
        organization_type: 'normal'
      })
      .then(
        json => {
          getOrganizationsAction.success(json)
        },
        errors => {
          getOrganizationsAction.failure(errors)
        }
      )
  },

  loadData(filters, newPage) {
    const { routerActions, query, location_id } = this.props
    const per_page = 10
    let pageObj = {
      per_page,
      page:
        newPage ||
        (filters && filters.page) ||
        this.state.filters.page ||
        this.props.page ||
        1
    }

    let params = Object.assign(
      {},
      filters || this.state.filters,
      location_id ? { location_id } : {},
      pageObj
    )

    let queryFiltersStr = queryFilter({ ...query, ...params })
    this.setState({ loading: true },()=>{
      api
      .getConfirmedInvoiceLineItems({ ...params })
      .then(
        json => {
          let pagination = paginate(json.response)
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin${
              this.props.location_id
                ? `/locations/${this.props.location_id}`
                : ''
            }/invoices/confirmed_invoices${queryFiltersStr}`
          )
          this.setState({
            invoices: json.json.monthly_details,
            statistic: json.json.summary,
            pagination,
            filters: params,
            selectInvoices: []
          })
        },
        errors => {
          const { apiCallFailureActions } = this.props
          return apiCallFailureActions({
            status: 'error',
            message: errors.message || getServiceErrorMessage(errors)
          })
        }
      )
      .finally(() => {
        const { orgs } = this.props
        this.setState({
          loading: false
        })
        if (!(orgs && orgs.length)) {
          this.getOrganizations()
        }
      })
    })
  },

  _setFilter(obj) {
    if (_.get(obj, 'line_item_month')) {
      obj.line_item_month = moment(_.get(obj, 'line_item_month')).format(
        'YYYYMM'
      )
    }
    // 剔除 filter 值为空的筛选条件
    let newFilters = Object.assign({}, this.state.filters, obj)
    Object.keys(obj).map(key => {
      if (!obj[key]) {
        delete newFilters[key]
      }
    })

    newFilters.page = 1
    this.setState({ filters: newFilters })
    this.location_idDropdown && this.location_idDropdown.hide()
    this.dateDropDown && this.dateDropDown.hide()
    this.paidAtDateDropDown && this.paidAtDateDropDown.hide()
    this.company && this.company.hide()
    this.incomeConfirm && this.incomeConfirm.hide()
    this.incomeConfirmDateDropDown && this.incomeConfirmDateDropDown.hide()
    this.icomeDateDropDown && this.icomeDateDropDown.hide()
    this.createDateDropDown && this.createDateDropDown.hide()
  },

  _clearFilters() {
    this.setState({ filters: {},isOnce:true },()=>{
      this.loadData()
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  orgSelectCallBack(data) {
    this.setState({isOnce:false})
    this._setFilter({
      sales_customer_id: String((data && data.sales_customer_id) || '')
    })
  },

  changeDate(range) {
    // const start_date = range.from ?  moment(range.from).startOf('month').format('YYYY-MM-DD') : ''
    // const end_date = range.to ? moment(range.to).endOf('month').format('YYYY-MM-DD') : ''

    const start_date = range.from ? moment(range.from).format('YYYY-MM-DD') : ''
    const end_date = range.to ? moment(range.to).format('YYYY-MM-DD') : ''

    this._setFilter({ start_date, end_date })
  },
  changeCreateDate(range) {
    // const start_date = range.from ?  moment(range.from).startOf('month').format('YYYY-MM-DD') : ''
    // const end_date = range.to ? moment(range.to).endOf('month').format('YYYY-MM-DD') : ''

    const create_start_date = range.from ? moment(range.from).format('YYYY-MM-DD') : ''
    const create_end_date = range.to ? moment(range.to).format('YYYY-MM-DD') : ''

    this._setFilter({ create_start_date, create_end_date })
  },
  selectInvoiceTypeCallBack(data) {
    const  invoiceType = data.map(item=>{
      if(typeof item === 'string'){
        return item
      }
      return item.id
    })
    this._setFilter({
      invoice_type: invoiceType.join(',')
    })
  },

  selectLineItemTypeCallBack(data) {
    const  line_itemType = data.map(item=>{
      if(typeof item === 'string'){
        return item
      }
      return item.id
    })
    this._setFilter({
      line_item_type: line_itemType.join(',')
    })
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="账单核销统计导出"
        message="账单核销统计列表已成功导出，可以前往报表中心下载"
      />
    )
  },

  exportInvoiceConfirmStatistics() {
    KBPopoverConfirm({
      name: '导出账单应收款报表',
      context: '您即将导出当前列表筛选条件下的账单应收款报表，是否确认导出？',
      callback: () => {
        const { location_id, apiCallFailureActions, filters } = this.props
        const params = Object.assign(
          {},
          filters,
          location_id ? { location_id } : {}
        )
        return api.getConfirmedInvoicesStatistics(params).then(
          () => {
            this.toReportCenter && this.toReportCenter()
            KBPopover.close()
          },
          error => {
            apiCallFailureActions({
              status: 'error',
              message: error.message || '服务器出问题了，请稍后重试哦~'
            })
            KBPopover.close()
          }
        )
      }
    })
  },

  exportInvoicePaidStatistics() {
    KBPopoverConfirm({
      name: '导出账单实收款报表',
      context: '您即将导出当前列表筛选条件下的账单实收款报表，是否确认导出？',
      callback: () => {
        const { location_id, apiCallFailureActions, filters } = this.props
        const params = Object.assign(
          {},
          filters,
          location_id ? { location_id } : {}
        )
        return api.getPaidInvoicesStatistics(params).then(
          () => {
            this.toReportCenter && this.toReportCenter()
            KBPopover.close()
          },
          error => {
            apiCallFailureActions({
              status: 'error',
              message: error.message || '服务器出问题了，请稍后重试哦~'
            })
            KBPopover.close()
          }
        )
      }
    })
  },
  _renderFilters() {
    const {
      locations,
      location_id,
      orgs,
      currentOrg,
      defaultInvoiceTypes,
      defaultLineItemTypes
    } = this.props
    const { filters,isOnce } = this.state
    const {
      start_date,
      end_date,
      until_paid_at,
      income_confirmed_at,
      line_item_month,
      create_start_date,
      create_end_date,
      invoice_type,
      line_item_type,
      sales_customer_id
    } = filters

    //  筛选条件
    let newDefaultInvoiceTypes = defaultInvoiceTypes
    let newDefaultLineItemTypes = defaultLineItemTypes
    if(invoice_type){
      const data=invoice_type.split(',').map(id=>_.find(INVOICES_UPDATE_TYPE,{id}))
      newDefaultInvoiceTypes = data
    }
    if(line_item_type){
      const data=line_item_type.split(',').map(id=>_.find(LINE_ITEM_TYPES,{id}))
      newDefaultLineItemTypes = data
    }
    let newCurrentOrg = currentOrg
    if(!isOnce){
      newCurrentOrg = _.find(orgs,{sales_customer_id})
    }
    const { MonthPicker } = DatePicker
    const { Group, Button } = Radio
    return (
      <div className="nav-section-action-bar">
        <div className="select-progress">
          {location_id && Object.keys(locations).length > 0 ? (
            <div
              className="task-status-title"
              style={{ display: 'inline-block', marginBottom: 10 }}
            >
              <i className="iconfont icon-position" />
              <span>{locations[location_id].name || ''}</span>
            </div>
          ) : (
            <KBDropdown ref="location_idDropdown">
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  style={{ display: 'inline-block', marginBottom: 10 }}
                  onClick={this.closeDropDowns.bind(
                    null,
                    'location_idDropdown'
                  )}
                >
                  <i className="iconfont icon-position" />
                  <span>
                    {this.state.filters.location_id &&
                    Object.keys(locations).length > 0
                      ? locations[this.state.filters.location_id].name
                      : '选择分店'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ location_id: '' })}>
                      全部
                    </li>
                    {locations &&
                      Object.keys(locations).map((location_id, index) => {
                        let location = locations[location_id]
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              this._setFilter({ location_id: location.id })
                            }
                          >
                            <span>{location.name}</span>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          )}
          <KBDropSelect
            ref={ref => {
              this.company = ref
            }}
            dropDown_name="company"
            selectedData={newCurrentOrg}
            closeClick={this.closeDropDowns}
            options={{ defaultSingleContent: '选择公司' }}
            defaultData={orgs}
            multi={false}
            callback={this.orgSelectCallBack}
            style={{ marginLeft: 20 }}
          />
          <KBRangeDate
            title="账单所属日期范围"
            format="YYYY-MM-DD"
            from={start_date || ''}
            to={end_date || ''}
            callback={this.changeDate}
            closeClick={this.closeDropDowns}
            dropDown_name="dateDropDown"
            ref={ref => {
              this.dateDropDown = ref
            }}
          />
          <KBDropSelect
            selectedData={newDefaultInvoiceTypes}
            ref={ref => {
              this.invoiceType = ref
            }}
            options={{ defaultSelectPrompt: '选择账单类别' }}
            defaultData={INVOICES_UPDATE_TYPE}
            multi={true}
            showImg={false}
            callback={this.selectInvoiceTypeCallBack}
            dropDown_name="invoiceType"
            closeClick={this.closeDropDowns}
            style={{ marginLeft: 20 }}
          />
          <KBDropSelect
            selectedData={newDefaultLineItemTypes}
            ref={ref => {
              this.lineItemType = ref
            }}
            options={{ defaultSelectPrompt: '选择收入明细' }}
            defaultData={LINE_ITEM_TYPES}
            multi={true}
            showImg={false}
            callback={this.selectLineItemTypeCallBack}
            dropDown_name="lineItemType"
            closeClick={this.closeDropDowns}
            style={{ marginLeft: 20, marginRight: 20 }}
          />
          <div className="select-progress">
            <KBDropdown
              ref={ref => {
                this.incomeConfirm = ref
              }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'incomeConfirm')}
                >
                  <i className="iconfont icon-finance" />
                  <span>
                    {this.state.filters.income_confirmed
                      ? INCOME_CONFIRM_STATE_OBJ[
                          this.state.filters.income_confirmed
                        ]
                      : '是否确认收入'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    {INCOME_CONFIRM_STATE.map((type, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({
                              income_confirmed: String(type.id)
                            })
                          }
                        >
                          <span>{type.name}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          </div>

          <KBDropdown
            ref={ref => {
              this.incomeConfirmDateDropDown = ref
            }}
            onClick={this.closeDropDowns.bind(
              null,
              'incomeConfirmDateDropDown'
            )}
          >
            <KBDropdown.KBDropdownTrigger>
              <div className="task-status-title" style={{ marginRight: 20 }}>
                <i
                  className="iconfont icon-calendar"
                  style={{ marginRight: '3px' }}
                />
                <span>
                  确认收入日期
                  {income_confirmed_at
                    ? ': ' + moment(income_confirmed_at).format('YYYY-MM-DD')
                    : ''}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <KBDayPicker
                onChange={date =>
                  this._setFilter({
                    income_confirmed_at: moment(date).format('YYYY-MM-DD')
                  })
                }
                selectedDay={moment(income_confirmed_at).toDate()}
              />
            </KBDropdown.KBDropdownContent>
          </KBDropdown>

          <KBDropdown
            ref={ref => {
              this.paidAtDateDropDown = ref
            }}
            onClick={this.closeDropDowns.bind(null, 'paidAtDateDropDown')}
          >
            <KBDropdown.KBDropdownTrigger>
              <div className="task-status-title">
                <i
                  className="iconfont icon-calendar"
                  style={{ marginRight: '3px' }}
                />
                <span>
                  回款率统计日期
                  {until_paid_at
                    ? ': ' + moment(until_paid_at).format('YYYY-MM-DD')
                    : ''}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <KBDayPicker
                onChange={date =>
                  this._setFilter({
                    until_paid_at: moment(date).format('YYYY-MM-DD')
                  })
                }
                selectedDay={moment(until_paid_at).toDate()}
              />
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
          <KBDropdown
            style={{ marginLeft: 10 }}
            ref={ref => {
              this.icomMonthDropDown = ref
            }}
            onClick={this.closeDropDowns.bind(null, 'icomMonthDropDown')}
          >
            <KBDropdown.KBDropdownTrigger>
              <div className="task-status-title">
                <i
                  className="iconfont icon-calendar"
                  style={{ marginRight: '3px' }}
                />
                <span>
                  收入所属月份
                  {line_item_month
                    ? ': ' +
                      line_item_month.slice(0, 4) +
                      '-' +
                      line_item_month.slice(4, 6)
                    : ''}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div
                style={{
                  background: 'linear-gradient(180deg,#fff,#f2f4f7)',
                  width: 300,
                  border: '1px solid #d9d9d9',
                  borderRadius: 4
                }}
              >
                <Calendar
                  fullscreen={false}
                  mode='year'
                  onPanelChange={date =>
                    this._setFilter({
                      line_item_month: moment(date).format('YYYY-MM')
                    })
                  }
                />
              </div>
              {/* <KBDayPicker onChange={ (date)=>this._setFilter({'line_item_month': moment(date).format('YYYY-MM')}) }/> */}
            </KBDropdown.KBDropdownContent>
          </KBDropdown>

          <KBRangeDate
            title="账单创建日期范围"
            format="YYYY-MM-DD"
            from={create_start_date || ''}
            to={create_end_date || ''}
            callback={this.changeCreateDate}
            closeClick={this.closeDropDowns}
            dropDown_name="createDateDropDown"
            ref={ref => {
              this.createDateDropDown = ref
            }}
          />

          {/* <MonthPicker
				className='task-status-title'
				placeholder="收入所属月份"
				size="small"
				style={{
					background: 'linear-gradient(180deg,#fff,#f2f4f7)',

				}}/> */}
        </div>
      </div>
    )
  },

  checkAllInvoices() {
    const { allCheckedBtn } = this.state
    const { invoices } = this.state
    let isAllInvoicesChecked_now = !allCheckedBtn
    let invoices_now = invoices
    if (!isAllInvoicesChecked_now) {
      invoices_now = []
    }
    this.setState({
      isAllInvoicesChecked: isAllInvoicesChecked_now,
      selectInvoices: invoices_now,
      allCheckedBtn: isAllInvoicesChecked_now
    })
  },

  changeInvoice(checked, data) {
    var invoices = Object.assign([], this.state.selectInvoices)
    if (checked) {
      invoices.push(data)
      // 当选中的 invoice 逐个增加时的判断
      if (invoices.length == 10) {
        this.setState({
          selectInvoices: invoices,
          allCheckedBtn: true,
          isAllInvoicesChecked: true
        })
      } else {
        this.setState({
          selectInvoices: invoices,
          allCheckedBtn: false
        })
      }

      return
    }

    let removeIndex = invoices.findIndex(json => json.id == data.id)

    if (removeIndex == -1) {
      return
    }

    invoices.splice(removeIndex, 1)
    // 当选中的 invoice 逐个减少时的判断
    if (invoices.length == 10) {
      this.setState({
        selectInvoices: invoices,
        allCheckedBtn: true
      })
    } else {
      this.setState({
        selectInvoices: invoices,
        allCheckedBtn: false
        // isAllInvoicesChecked : false
      })
    }
  },

  _renderStatistics(s) {
    if (!s) {
      return null
    }

    const paybackRate =
      Math.round(parseFloat(s.until_paid_rate || 0) * 10000) / 100

    return (
      <div className="chart-out_box t-center m-updown">
        {/* <div className='chart-box t-left' style={{marginRight: 20}}>
					<KBStatisticNumber number={ fmoney(s.detail_total_amount || 0) } unit='元' name='账单明细金额'/>
				</div> */}
        <div className="chart-box t-left" style={{ marginRight: 20 }}>
          <KBStatisticNumber
            number={fmoney(s.invoice_total_amount || 0)}
            unit="元"
            name="账单总金额"
          />
        </div>
        {/* <div className='chart-box t-left' style={{marginRight: 20}}>
					<KBStatisticNumber number={ fmoney(s.detail_paid_amount || 0) } unit='元' name='实收明细金额'/>
				</div> */}
        <div className="chart-box t-left" style={{ marginRight: 20 }}>
          <KBStatisticNumber
            number={fmoney(s.invoice_paid_amount || 0)}
            unit="元"
            name="实收总金额"
          />
        </div>
        <div className="chart-box t-left" style={{ marginRight: 20 }}>
          <KBStatisticNumber
            number={fmoney(s.detail_non_tax_amount || 0)}
            unit="元"
            name="不含税收入"
          />
        </div>
        <div className="chart-box t-left" style={{ marginRight: 20 }}>
          <KBStatisticNumber
            number={Number.isNaN(paybackRate) ? '0.00' : paybackRate.toFixed(2)}
            unit="%"
            name="回款率"
          />
        </div>
      </div>
    )
  },

  resetSelectedInvoices() {
    this.loadData()
  },

  batchConfirmIncome(line_items) {
    const { location_id } = this.props
    let ids = []
    let lineItemIds = []
    line_items.map(line => {
      lineItemIds.push(line.id)
      ids.push(line.sales_invoice.id)
    })

    KBPopover.show(
      <InvoiceConfirmIncomeForm
        location_id={location_id}
        ids={_.uniq(ids)}
        line_item_ids={lineItemIds}
        isBatch={true}
        callback={this.resetSelectedInvoices}
      />
    )
  },

  isBatchAudit() {
    const { selectInvoices } = this.state

    if (selectInvoices.length === 0) {
      return null
    }

    let isAllFalse = selectInvoices.every(ele => {
      return !ele.income_confirmed_at
    })

    return (
      <div className="invoice-table-upon">
        {isAllFalse ? (
          <button
            className="bordered-btn m-none"
            onClick={() => {
              this.batchConfirmIncome(selectInvoices)
            }}
          >
            确认收入
          </button>
        ) : null}
      </div>
    )
  },

  render() {
    const {
      loading,
      invoices,
      pagination,
      statistic,
      selectInvoices
    } = this.state
    console.log(this.state.filters,'filter');
    const { locationsObj } = this.props
    return (
      <div className="kb-content-container clear-fix">
        <div>
          <header className="nav-header">
            <div className=" clear-fix">
              <div className="nav-section-header-title f-left">
                <span>账单核销统计</span>
              </div>
              <div className="f-right">
                <button
                  className="c-btn-secondary"
                  onClick={this.exportInvoiceConfirmStatistics}
                >
                  导出应收款报表
                </button>
                <button
                  className="c-btn-secondary m-left-sm"
                  onClick={this.exportInvoicePaidStatistics}
                >
                  导出实收款报表
                </button>
              </div>
            </div>
          </header>
          <div className="nav-section-content-container">
            <div>
              {this._renderFilters()}
              {Object.keys(this.state.filters).length > 0 ? (
                <div className="clear-criteria" onClick={this._clearFilters}>
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              ) : null}
              <KBButton
                className="certain-btn"
                onClick={()=>this.loadData()}
                style={{ marginLeft: 20 }}>筛选</KBButton>
            </div>
            {this._renderStatistics(statistic)}
            <KBLoadingContainer
              loading={loading}
              style={!loading ? { overflowX: 'scroll' } : {}}
            >
              {this.isBatchAudit()}
              <table className="content-table" style={{ whiteSpace: 'nowrap' }}>
                <thead>
                  <tr>
                    <th>
                      <KBCheckBox
                        style={{ marginTop: 2 }}
                        data={invoices}
                        callback={this.checkAllInvoices}
                        checked={this.state.allCheckedBtn}
                      />
                    </th>
                    <th>分店</th>
                    <th>公司名称</th>
                    {/* <th>工位号</th> */}
                    {/* <th>合同编号</th> */}
                    <th>账单编号</th>
                    <th>账单描述</th>
                    <th>明细类型</th>
                    {/* <th>账单备注</th> */}
                    <th>账单日期</th>
                    {/* <th>账单总金额</th> */}
                    <th>实收总金额</th>
                    <th>收入所属月份</th>
                    <th>账单明细金额</th>
                    <th>实收明细金额</th>
                    <th>待收明细金额</th>
                    <th>不含税收入</th>
                    <th>税额</th>
                    <th>税率</th>
                    <th>账龄</th>
                    {/* <th>实际收款日期</th>
									<th>开票状态</th>
									<th>发票号码</th>
									<th>发票备注</th>
									<th>开票日期</th> */}
                    <th>确认收入日期</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={invoices.length > 0}
                  tableHeadNum="15"
                  tipMessage="暂无账单"
                >
                  {invoices.map((line, index) => {
                    let invoice = line.sales_invoice || {}
                    let currentLoc = locationsObj[invoice.location_id] || {}
                    let year = line.month.slice(0, 4)
                    let month = year + '-' + line.month.slice(4, 6)
                    let taxRate =
                      Math.round(parseFloat(line.tax_rate || 0) * 10000) / 100
                    let taxAmount =
                      Math.round(parseFloat(line.tax_amount || 0) * 100) / 100
                    let noneTaxAmount =
                      Math.round(parseFloat(line.non_tax_amount || 0) * 100) /
                      100

                    return (
                      <tr key={index}>
                        <td
                          style={{
                            position: 'static',
                            verticalAlign: 'middle'
                          }}
                          onClick={e => e.stopPropagation()}
                        >
                          <KBCheckBox
                            style={{ marginTop: 2 }}
                            data={line}
                            callback={this.changeInvoice}
                            checked={
                              (selectInvoices &&
                                selectInvoices.length &&
                                selectInvoices.find(data => {
                                  return data.id === line.id
                                })) ||
                              false
                            }
                          />
                        </td>
                        <td>{currentLoc.name}</td>
                        <td>
                          {invoice.sales_customer &&
                            invoice.sales_customer.name}
                        </td>
                        <td>{invoice.serial_number}</td>
                        <td>{invoice.title}</td>
                        <td>{line.line_item_type_t || '-'}</td>
                        {/* <td>{ invoice.note }</td> */}
                        <td>{formatYearDayEN(invoice.due_date)}</td>
                        {/* <td>¥{ fmoney(invoice.total_amount) }</td> */}
                        <td>¥{fmoney(invoice.paid_amount)}</td>
                        <td>{month}</td>
                        <td>¥{fmoney(line.total_amount)}</td>
                        <td>¥{fmoney(line.paid_amount)}</td>
                        <td>¥{fmoney(line.total_amount - line.paid_amount)}</td>
                        <td>¥{fmoney(noneTaxAmount)}</td>
                        <td>¥{fmoney(taxAmount)}</td>
                        <td>{taxRate}%</td>
                        <td>{line.overdue_days}</td>
                        <td>{formatYearDayEN(line.income_confirmed_at)}</td>
                      </tr>
                    )
                  })}
                </KBTableWithoutData>
              </table>
            </KBLoadingContainer>
            <KBPagination
              pagination={pagination}
              template={`/admin${
                this.props.location_id
                  ? `/locations/${this.props.location_id}`
                  : ''
              }/invoices/confirmed_invoices?page=#PAGE#`}
            />
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let location_id = params.id
  let page = parseInt(location.query && location.query.page, 10) || 1
  let locations = selectors.getSerializeLocationObj(state)
  const locationsObj = selectors.getSerializeLocationObj(state) || {}
  var FILTER_STATE = [
    'location_id',
    'start_date',
    'end_date',
    'sales_customer_id',
    'line_item_type',
    'invoice_type',
    'until_paid_at',
    'income_confirmed',
    'income_confirmed_at',
    'line_item_month',
    'create_start_date',
    'create_end_date'
  ]

  var param = getFilterAndQuery(location.query, FILTER_STATE)

  let orgs = selectors.getOrgsOfLocation(state, location_id)
  let currentOrg
  if (param.filters.sales_customer_id) {
    currentOrg = orgs.find(
      orgs => param.filters.sales_customer_id == orgs.sales_customer_id
    )
  }
  let defaultInvoiceTypes = []
  if (param.filters && param.filters.invoice_type) {
    let invoice_types = param.filters.invoice_type.split(',')
    defaultInvoiceTypes = INVOICES_UPDATE_TYPE.filter(data =>
      invoice_types.find(id => id == data.id)
    )
  }
  let defaultLineItemTypes = []
  if (param.filters && param.filters.line_item_type) {
    let line_item_types = param.filters.line_item_type.split(',')
    defaultLineItemTypes = LINE_ITEM_TYPES.filter(data =>
      line_item_types.find(id => id == data.id)
    )
  }

  return {
    page,
    location_id,
    locations,
    locationsObj,
    orgs,
    currentOrg,
    defaultInvoiceTypes,
    defaultLineItemTypes,
    ...param
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    getOrganizationsAction: bindActionCreators(orgActions.replace, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsInvoiceConfirmed)
