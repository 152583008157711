import {
  mergeArray,
  omitArray,
  get,
  mergeState,
  omitState
} from './reducer_utils'
import _ from 'lodash'
import * as actions from '../actions'

// Saves location orgs in entities.locations.[loc_id].org_ids
var locations = (state = {}, action) => {
  const { type } = action

  if (action.type == actions.USER_LOGGED_OUT) {
    return {}
  }

  if (type === 'DELETEORGANIZATION_SUCCESS') {
    return omitState(state, action, action.location_id, 'org_ids')
  } else if (_.endsWith(type, 'ORGANIZATION_SUCCESS')) {
    return mergeState(state, action, action.location_id, 'org_ids')
  } else if (type === 'DELETEDESK_SUCCESS') {
    return omitState(state, action, action.location_id, 'desk_ids')
  } else if (_.endsWith(type, 'DESK_SUCCESS')) {
    return mergeState(state, action, action.location_id, 'desk_ids')
  } else if (type === 'DELETEAREA_SUCCESS') {
    return omitState(state, action, action.location_id, 'area_ids')
  } else if (_.endsWith(type, 'AREA_SUCCESS')) {
    return mergeState(state, action, action.location_id, 'area_ids')
  } else if (type === 'DELETELOCATIONMEMBERS_SUCCESS') {
    return omitState(state, action, action.location_id, 'member_ids')
  } else if (_.endsWith(type, 'LOCATIONMEMBERS_SUCCESS')) {
    return mergeState(state, action, action.location_id, 'member_ids')
  }

  //state = organizationNotes[type] ? organizationNotes[type](state,action) : state

  return state
}

export default locations
