import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { invoicesActions, successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { checkObject } from 'utils/kbUtil'
import classNames from 'classnames'
import ManualPayForm from './ManualPayForm'
import { KBPopover } from 'components'
import { getEntity } from 'app/selectors'
import {
  INVOICES_STATE,
  INVOICES_STATE_CLASS,
  INVOICES_SENDING_STATE,
  INVOICES_SENDING_STATE_CLASS
} from 'app/constants'
import { get, getEntitiesJsonArray } from 'app/reducers/reducer_utils'

var InvoiceSendEmail = React.createClass({
  getInitialState() {
    return {
      invoice: this.props.invoice,
      bill_email: ''
    }
  },

  componentDidMount() {
    const { getInvoicesActions, invoice_id } = this.props
    const { invoice } = this.state

    !invoice &&
      api.getInvoice(invoice_id).then(
        json => {
          getInvoicesActions.success(json)
          let invoice = getEntitiesJsonArray(json, 'invoices')
          this.setState({
            invoice: invoice,
            bill_email: invoice.bill_email || ''
          })
        },
        errors => {
          getInvoicesActions.failure(errors)
        }
      )
  },

  sendEmail() {
    const {
      updateInvoicesActions,
      successAction,
      invoice_id,
      loc_id
    } = this.props
    const { invoice, bill_email } = this.state
    const { message } = this.refs
    const msg = message.value
    if (!bill_email) {
      successAction({ message: '请填写接收人Email地址!' })
      return
    }

    api
      .putSendRemind(invoice_id, {
        message_to_customer: msg,
        reminder_approach: 'email',
        bill_email: bill_email
      })
      .then(
        json => {
          updateInvoicesActions.success(json)
          successAction({ message: '发送账单成功!' })
          if (loc_id) {
            this.props.routerActions.push(
              `/admin/locations/${invoice.location_id}/invoices/invoices_list/${invoice_id}`
            )
            return
          }

          this.props.routerActions.push(
            `/admin/invoices/invoices_list/${invoice_id}`
          )
        },
        errors => {
          updateInvoicesActions.failure(errors)
        }
      )
  },

  updateBillEmail(e) {
    let value = e.target.value
    this.setState({
      bill_email: value
    })
  },

  invoiceDetailJsx() {
    const status = INVOICES_STATE
    const status_class = INVOICES_STATE_CLASS
    const sending_status = INVOICES_SENDING_STATE
    const sending_status_class = INVOICES_SENDING_STATE_CLASS

    const { invoice_id, loc_id } = this.props
    const { invoice, bill_email } = this.state

    let total_amount = invoice && parseFloat(invoice.total_amount)
    total_amount = isNaN(total_amount) ? 0 : total_amount

    let paid_amount = invoice && parseFloat(invoice.paid_amount)
    paid_amount = isNaN(paid_amount) ? 0 : paid_amount

    const remaining = parseFloat(total_amount - paid_amount).toFixed(2)

    const customer = invoice && get(invoice, ['sales_customer'])
    let customerUrl = null
    if (customer && customer.foreign_type === 'org' && invoice.location_id) {
      customerUrl = `/admin/locations/${
        invoice.location_id
      }/organizations/${get(customer, 'id')}`
    }

    return (
      <div>
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title">
            <span>发送账单</span>
          </div>
          <div className="nav-section-action-bar">
            <span className="f-left" style={{ marginTop: '5px' }}>
              {get(customer, 'name')}
            </span>
            <span className={`finance-default ${status_class[invoice.status]}`}>
              {status[invoice.status]}
            </span>
            <span
              className={`finance-default ${
                sending_status_class[invoice.sending_status]
              }`}
            >
              {sending_status[invoice.sending_status]}
            </span>
          </div>
        </header>
        <div className="nav-section-content-container">
          <div className="kb-section t-left m-bottom">
            <ul>
              <li>
                <span className="section-title">账单编号:</span>
                <span className="section-title-content">
                  {invoice.serial_number}
                </span>
              </li>
              <li>
                <span className="section-title">空间名称:</span>
                <span className="section-title-content">
                  {invoice.location.name}
                </span>
              </li>
              {invoice.location.telephone ? (
                <li>
                  <span className="section-title">空间电话:</span>
                  <span className="section-title-content">
                    {invoice.location.telephone}
                  </span>
                </li>
              ) : null}
            </ul>
          </div>
          <div className="kb-section t-right p-bottom">
            <ul>
              <li>
                <span className="section-title f-bold">生成日期:</span>
                <span className="section-title-content">
                  {invoice.created_at}
                </span>
              </li>
              <li>
                <span className="section-title f-bold">截止日期:</span>
                <span className="section-title-content">
                  {invoice.due_date}
                </span>
              </li>
              {invoice.last_paid_at ? (
                <li>
                  <span className="section-title f-bold">付款日期:</span>
                  <span className="section-title-content">
                    {invoice.last_paid_at}
                  </span>
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
          <table className="content-table m-bottom t-layout">
            <thead>
              <tr>
                <th style={{ width: '50%' }}>说明</th>
                <th>单价</th>
                <th>数量</th>
                <th className="t-right">总价</th>
              </tr>
            </thead>
            <tbody>
              {invoice.line_items.map((json, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {json.description}（&nbsp;
                      <Link
                        className="color-link"
                        to={`/admin/locations/${invoice.location_id}/organizations/${invoice.organization_id}/subscriptions/${json.sales_subscription_id}`}
                      >
                        {json.sales_subscription_serial}
                      </Link>
                      &nbsp;）
                      <br />
                      {json.start_date
                        ? '(' + json.start_date + '至' + json.end_date + ')'
                        : ''}
                    </td>
                    <td>{json.unit_price}</td>
                    <td>{json.units}</td>
                    <td className="t-right">
                      {parseInt(json.units) * parseInt(json.unit_price)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className="p-bottom clear-fix">
            <table className="content-table-half f-right">
              <tbody>
                <tr>
                  <td>应付金额</td>
                  <td className="t-right">{total_amount}</td>
                </tr>
                <tr>
                  <td>实收金额</td>
                  <td className="t-right">{paid_amount}</td>
                </tr>
                <tr>
                  <td className="f-bold ">剩余金额</td>
                  <td
                    className={classNames(
                      't-right',
                      remaining > 0 ? '' : 'color-red'
                    )}
                  >
                    {remaining}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="finance-detail">
            <header>明细</header>
            <table className="content-table t-layout">
              <thead>
                <tr>
                  <th>时间</th>
                  <th>经手人</th>
                  <th>金额</th>
                  <th className="t-right">流水号</th>
                </tr>
              </thead>
              <tbody>
                {invoice.sales_payments.map((json, index) => {
                  return (
                    <tr key={index}>
                      <td>{json.created_at}</td>
                      <td>{json.creator.name}</td>
                      <td>{json.amount}</td>
                      <td className="t-right">{json.reference_number}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="bill-email">
            <header>接收人Email地址</header>
            <div>
              <input
                type="text"
                value={bill_email}
                onChange={this.updateBillEmail}
              />
            </div>
          </div>
          <div className="finance-detail">
            <header>写给客户的信息</header>
            <textarea placeholder="填写信息" ref="message" />
            <div className="finance-detail-footer m-top">
              <button className="certain-btn" onClick={this.sendEmail}>
                发送
              </button>
              {loc_id ? (
                <Link
                  to={`/admin/locations/${invoice.location_id}/invoices/invoices_list/${invoice_id}`}
                >
                  <span className="cancel-btn m-left-sm">取消</span>
                </Link>
              ) : (
                <Link to={`/admin/invoices/invoices_list/${invoice_id}`}>
                  <span className="cancel-btn m-left-sm">取消</span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  },

  render() {
    const { loc_id } = this.props
    const { invoice } = this.state

    if (loc_id) {
      return invoice ? (
        <section className="kb-content" style={{ marginTop: 0 }}>
          <div className="kb-content-container clear-fix">
            {this.invoiceDetailJsx()}
          </div>
        </section>
      ) : null
    }

    return invoice ? (
      <section
        className="nav-section-container  f-right"
        style={{ width: 911 }}
      >
        {this.invoiceDetailJsx()}
      </section>
    ) : null
  }
})

function mapStateToProps(state, props) {
  const { entities } = state
  const { params } = props
  const invoice_id = params.invoice_id
  const loc_id = params.id

  const invoice = getEntity(state, 'invoices', invoice_id)
  return {
    loc_id,
    invoice_id,
    invoice
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getInvoicesActions: bindActionCreators(invoicesActions.get, dispatch),
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch),
    successAction: bindActionCreators(successState, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSendEmail)
