import React, { PropTypes, Component } from 'react'
const pinyin = require('tiny-pinyin')

const colors = {
  a: '#DA6068',
  b: '#70CCB0',
  c: '#FB6E52',
  d: '#6A9CE7',
  e: '#F6D06A',
  f: '#AC92ED',
  g: '#A0D468',
  h: '#4FC0E8',
  i: '#EC88C0',
  j: '#66C5D5',
  k: '#D2691E',
  l: '#FF7F50',
  m: '#6495ED',
  n: '#FFF8DC',
  o: '#B8860B',
  p: '#A9A9A9',
  q: '#006400',
  r: '#BDB76B',
  s: '#8B008B',
  t: '#556B2F',
  u: '#FF8C00',
  v: '#9932CC',
  w: '#E9967A',
  x: '#8FBC8F',
  y: '#483D8B',
  z: '#696969'
}

function stringToInt(letters) {
  if (!letters) {
    return 0
  }
  let total = 0
  for (let i = 0; i < Math.max(2, letters.length); i++) {
    total += letters.charCodeAt(i)
  }
  return total
}

function isEnglish(name) {
  if (!name || name.length === 0) return false

  for (let i = 0; i < name.length; i++) {
    if (name.charCodeAt(i) > 128) {
      return false
    }
  }
  return true
}

function getLetterRepresentation(name, maxLength = 1) {
  if (!name) {
    return '#'
  }
  let letters = ''

  if (isEnglish(name)) {
    letters = name
      .split(' ')
      .map(word => (word.length > 0 ? word : ''))
      .join('')
  } else {
    letters = name
  }
  return letters.substring(0, maxLength)
}

function getLetter(name) {
  if (!name) {
    return '#'
  }
  let letters = ''

  if (isEnglish(name)) {
    letters = name.split(' ')[0]
  } else {
    letters = pinyin.convertToPinyin(name).split('')[0]
  }
  return letters.substring(0)
}

class KBLetterAvatar extends React.Component {
  render() {
    const { className, numberOfChars } = this.props
    const parentStyle = this.props.style
    let { name, pinyin, size, radius } = this.props
    let p = pinyin && pinyin.trim()

    let nameChar = getLetterRepresentation(name, numberOfChars)
    let letter = getLetter(name).toLocaleLowerCase()

    let bgColor = colors[letter] || '#CD5C5C'

    let style = {
      width: size,
      height: size,
      backgroundColor: bgColor,
      fontSize: Math.floor(size / (1 + numberOfChars)) + 1,
      lineHeight: size + Math.floor(size / 10) + 'px',
      fontFamily: 'Lantinghei SC',
      color: 'rgba(255, 255, 255,0.9)',
      textAlign: 'center',
      borderRadius: radius
    }

    style = Object.assign({}, style, parentStyle)
    return (
      <div style={style} className={className}>
        {nameChar}
      </div>
    )
  }
}

KBLetterAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  pinyin: PropTypes.string,
  size: PropTypes.number,
  radius: PropTypes.number,
  numberOfChars: PropTypes.number // show how many chars
}

KBLetterAvatar.defaultProps = {
  name: '',
  pinyin: '',
  size: 50,
  radius: 50,
  numberOfChars: 1
}

export default KBLetterAvatar
