import _ from 'lodash'
import { mergeArray, omitArray } from './reducer_utils'
import * as actions from '../actions'

var areas = (state = {}, action) => {
  const { type } = action

  if (type == 'DELETEDESK_SUCCESS') {
    // remove id from array
    return _.mergeWith(
      {},
      state,
      { [action.area_id]: { desk_ids: action.id } },
      omitArray
    )
  }

  if (_.endsWith(action.type, 'DESK_SUCCESS')) {
    return _.mergeWith(
      {},
      state,
      { [action.area_id]: { desk_ids: action.response.json.result } },
      mergeArray
    )
  }

  if (action.type == actions.USER_LOGGED_OUT) {
    return {}
  }

  return state
}

export default areas
