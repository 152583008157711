import _ from 'lodash'

function groupSetting(state = {}, action) {
  const data = action.response
  let newState = _.cloneDeep(state)

  if(!action.type){
    return
  }
  // if (!action.group_id) {
  //   return state
  // }

  // newState[action.group_id] = newState[action.group_id] || {}
  if (action.type === 'GETGROUPSETTING_SUCCESS') {
    newState = data.json
    return newState
  }

  if (action.type === 'UPDATEGROUPSETTING_SUCCESS' && action.key) {
    let key = action.key

    newState[key] = data.json[key]
    return newState
  }

  return newState || null
}

export default groupSetting
