import React from 'react'
import {
  KBDropdown,
  KBPagination,
  KBCheckBox,
  KBLoadingContainer,
  KBSearchList,
  KBPopover,
  KBRangeDate,
  KBStatisticNumber
} from 'components'
const KBTableSerch = React.createClass({
  getInitialState() {
    return {
    }
  },

  setValue(val) {
    const { onChange } = this.props
    this.dropdown.hide()
    onChange && onChange(val)
  },

  render() {
    const { label, options = [], value } = this.props
    const { dropdown } = this
    let showLabel = label
    const opt = [
      {label: '全部', value: ''},
      ...options
    ]

    if(opt && value) {
      const obj = opt.find(i => i.value == value)
      if(obj) {
        showLabel = obj.label
      }
    }

    return (
      <KBDropdown
        ref={ref => {
          this.dropdown = ref
        }}
        style={{ marginRight: 20 }}
      >
        <KBDropdown.KBDropdownTrigger>
          <div
            className="task-status-title"
            onClick={() => {
              this.dropdown.show()
            }}
          >
            <span>{showLabel}</span>
            <span className="task-deg" />
          </div>
        </KBDropdown.KBDropdownTrigger>
        <KBDropdown.KBDropdownContent>
          <div>
            <ul className="task-type">
              {opt.map(item => {
                return (
                  <li
                    key={item.value}
                    onClick={() => this.setValue(item.value)}
                  >
                    <span>{item.label}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        </KBDropdown.KBDropdownContent>
      </KBDropdown>
    )
  }
})

export default KBTableSerch
