import React from 'react'
import moment from 'moment'
import { Router, Route, Link } from 'react-router'
import CrmRecordCommentList from './CrmRecordCommentList'
import {
  OPPORTUNITY_STATES,
  OPPORTUNITY_STATES_CLASS,
  NODE_STATES
} from 'app/constants'
import { KBAvatar } from 'components'
import _ from 'lodash'

var CrmRecordRow = React.createClass({
  render() {
    const { record, create_by, opportunity } = this.props
    let contentSplit = _.split(record.content, 'SEPARATOR', 2)
    let recordSplit = contentSplit[0] == 'undefined' ? '无' : contentSplit[0]
    let competeSplit = contentSplit[1] == 'undefined' ? '无' : contentSplit[1]
    return (
      <div className="nav-comment-content border-bottom-lg">
        <header className="clear-fix">
          <div className="comment-portrait">
            <KBAvatar user={create_by} size={36} />
          </div>
          <div className="comment-name">
            <span className="comment-people">{create_by.name}</span>
            <span className="comment-time">
              {record.created_at
                ? moment.unix(record.created_at).fromNow()
                : '-'}
            </span>
          </div>
        </header>
        <div className="nav-comment-text">
          <p style={{ marginBottom: 8, color: '#999' }}>
            <span style={{ color: '#333' }}></span>
            {recordSplit}
            {record.note_type ? (
              <span className="flow">#{NODE_STATES[record.note_type]}</span>
            ) : (
              ''
            )}
          </p>
          {opportunity.state == 'success' ? (
            ''
          ) : (
            <p style={{ color: '#999' }}>
              <span style={{ color: '#333' }}>可对比竞品：</span>
              {competeSplit ? competeSplit : '暂无记录'}
            </p>
          )}
          <div className="communicate under-line">
            <span
              className={OPPORTUNITY_STATES_CLASS[record.opportunity_state]}
              style={{ minWidth: 64, textAlign: 'center' }}
            >
              {OPPORTUNITY_STATES[record.opportunity_state]}
            </span>
            <i className="iconfont icon-account_circle" />
            <span>
              {opportunity && opportunity.customer ? (
                <Link
                  style={{ color: '#999' }}
                  to={`/admin/crm/opportunities/${opportunity.id}`}
                >
                  {' '}
                  {opportunity.customer.name}{' '}
                </Link>
              ) : (
                ''
              )}
            </span>
          </div>
        </div>
        <CrmRecordCommentList
          opportunity_id={record.opportunity}
          record={record}
          create_by={create_by}
        />
      </div>
    )
  }
})

export default CrmRecordRow
