import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import classNames from 'classnames'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { KBInput, KBFormError, KBButton } from 'components'
import { currentUserActions, apiCallFailure } from 'app/actions'
import { LoginRedirect } from 'components/mixins'
import intl from 'react-intl-universal'
import config from 'app/config'

var Login = React.createClass({
  mixins: [LoginRedirect],
  login(model, dispatch) {
    // const { remember } = this.refs
    const { loginAction, routerActions } = this.props

    return api
      .loginUser({
        email: model.email.trim(),
        password: model.password
      })
      .then(data => {
        let loggedInUser = data.json.entities.users[data.json.result]
        const user = Object.assign({}, loggedInUser, data.json.entities)
        apiUtils.setCurrentUser(user, { remember: false })
        loginAction.success(data)
        return this.checkAccess(loggedInUser)
      })
  },

  sendSmsCode() {
    const {
      fields: { phone_num }
    } = this.props
    let phone = phone_num.value
    setTimeout(
      () =>
        api
          .sendLoginSmsCode({ phone_num: phone })
          .then(json => {
            this.refs.countDownButton._stopTimer()
            if (process.env.NODE_ENV == 'development') {
              alert(json.json.sms_code)
            }
          })
          .catch(err => {
            this.refs.countDownButton._stopTimer()
          }),
      3000
    )
    return true
  },

  componentDidMount() {
    let url = config.url
    // if(process.env.NODE_ENV === 'development') {
    //   url = 'work-uat-api.capitaland.com.cn'
    // }
    window.location.href = `${config.urlScheme}://${url}/saml/login`
  },

  render() {
    return <div></div>
  },

  render2() {
    const {
      fields: { email, password },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <div
        className="lr-out-box"
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div className="comment-box1">
          <div className="kb-box box1-header">
            <span className="lr-text">{intl.get('LOGIN.TITLE')}</span>
          </div>
          <form onSubmit={handleSubmit(this.login)}>
            <KBFormError error={error}>
              {error && error.reason == 'locked' ? (
                <Link to="/send_unlock_email">
                  {intl.get('LOGIN.SEND_UNLOCK_EMAIL')}
                </Link>
              ) : null}
            </KBFormError>
            <KBInput field={email} placeholder={'username@capitaland.com'} />
            <KBInput
              type="password"
              field={password}
              placeholder={intl.get('LOGIN.INPUT_PASSWORD')}
            />
            {/* <div className="kb-box">
              <label htmlFor="remember-password" className="clear-fix">
                <input
                  className="f-left chk_1"
                  id="remember-password"
                  type="checkbox"
                  ref="remember"
                  style={{ display: 'none' }}
                />
                <span className="f-left color-nine" style={{ marginTop: 1 }} />
                <span className="label-title f-left">
                  {intl.get('LOGIN.SAVE_PASSWORD')}
                </span>
              </label>
            </div> */}
            <div className="kb-box lr-certain">
              <KBButton type="submit" submitting={submitting}>
                {intl.get('LOGIN.LOGIN')}
              </KBButton>
            </div>

            <div className="kb-box">
              {/* <Link className="f-left color-nine" to="/send_reset_password">{ intl.get('LOGIN.FORGET_PASSWORD') }</Link> */}
              {/*<a className="f-right color-nine">没有账号?点击注册</a>*/}
            </div>
          </form>
        </div>
        <div className="label-title" style={{ marginTop: 20 }}>
          使用你的组织帐户登录
          <br />
          Please sign-in with your Windows ID account:
          <br />
          susername@capitaland.com
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, ownProps) {}

// Map action creators dispatch to props
// So we can call this.props.actions.<actionName>(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    loginAction: bindActionCreators(currentUserActions.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.email)) {
    errors.email = '请输入您的注册Email'
  } else if (valid.email(values.email)) {
    errors.email = '请输入正确的Email地址'
  } else if (valid.minLength(6)(values.password)) {
    errors.password = '请输入正确的密码'
  }

  if (valid.required(values.password)) {
    errors.password = '请输入您的密码'
  }
  return errors
}

const formConfig = {
  form: 'login',
  fields: ['email', 'password'],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(formConfig, mapStateToProps, mapDispatchToProps)(Login)
