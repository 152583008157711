import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { successState, apiCallFailure } from 'app/actions'
import {
  KBInput,
  KBRadio,
  KBDateInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import {
  getServiceErrorMessage,
} from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import moment from 'moment'
import * as constants from 'app/constants'
import { EXPORT_HINT } from 'app/constants/hint'
import Select from 'components/v2/Select'

let ReportData = React.createClass({
  getInitialState() {
    return {
      tab: 'invoices'
    }
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="自助认证导出"
        message="自助认证列表已成功导出，可以前往报表中心下载"
      />
    )
  },

  delNull(obj) {
    const result = {}
    for (const key in obj) {
      if(obj[key] && obj[key].length) {
        result[key] = obj[key]
      }
    }

    return result
  },

  exportList(model) {
    const { apiCallFailureAction } = this.props
    const result = this.delNull(model)

    return api
      .exportAutoAut({
        ...result,
        start_at: model.start_at + ' 00:00:00',
        end_at: model.end_at + ' 23:59:59',
      })
      .then(
        () => {
          this.toReportCenter()
        },
        error => {
          apiCallFailureAction({
            status: 'error',
            message: getServiceErrorMessage(error)
          })
        }
      )
  },

  componentDidMount() {},

  getDisabledDays(date) {
    // 最近6个月
    const earliest = moment().subtract(6, 'month')
    const day = moment(date)
    const today = moment()
    return !day.isBetween(earliest, today)
  },

  render() {
    const {
      fields: { start_at, end_at, status, location_id },
      loc_id,
      error,
      submitting,
      handleSubmit,
      allStatus,
      locations
    } = this.props

    return (
      <form
        onSubmit={handleSubmit(this.exportList)}
        ref={ref => {
          this.formScroll = ref
        }}
      >
        <header className="kb-form-header">
          <h2 className="header-title">导出自助认证申请列表</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={error} />
        <div
          className="kb-form-container"
          ref={ref => {
            this.invoicesScroll = ref
          }}
        >
          <div className="kb-row kb-form-66">
            <KBDateInput
              type="date"
              title="开始日期"
              isRequired={true}
              isShowCenter={true}
              className="kb-form-group kb-div-c39"
              field={start_at}
              disabledDays={this.getDisabledDays}
            />
            <KBDateInput
              type="date"
              title="结束日期"
              titleClass="label-rt"
              isShowCenter={true}
              className="kb-form-group kb-div-c48"
              field={end_at}
              disabledDays={this.getDisabledDays}
            />
          </div>
          <div className="kb-row kb-form-alone">
            <Select label="申请状态" field={status} mode="multiple" options={allStatus} />
          </div>
          {!loc_id && (
            <div className="kb-row kb-form-alone">
              <Select label="分店名称" field={location_id} mode="multiple" options={locations} />
            </div>
          )}
          <p style={{ fontSize: '12px', color: '#7F8FA4' }}>
            {EXPORT_HINT.concat_admin}
          </p>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确认导出
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { loc_id } = props

  return {
    initialValues: {
      location_id: loc_id ? [loc_id] : [],
      status: []
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.start_at)) {
    errors.start_at = '请选择开始时间'
  }

  if (valid.required(values.end_at)) {
    errors.end_at = '请选择结束时间'
  }

  return errors
}

const formConfig = {
  form: 'ReportData',
  fields: [
    'start_at',
    'end_at',
    'status',
    'location_id'
  ],
  validate: validate,
  touchOnBlur: false
}

ReportData = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ReportData)

export default ReportData
