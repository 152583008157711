import React, { Component, PropTypes } from 'react'
import { KBTipsy } from 'components'

var KBStatisticNumber = React.createClass({
  render() {
    const { number, unit, name, title, explain, style, color } = this.props

    return (
      <div className="statistic-box" style={style}>
        <div className="header">
          <span className="number" style={{ color: color }}>
            {number}
          </span>
          {unit && <span className="unit">{unit}</span>}
        </div>
        {title && (
          <div className="title">
            <span>{title}</span>
          </div>
        )}
        <div className="name">
          <span>{name}</span>
          {this.props.children}
          {explain && (
            <KBTipsy content={explain}>
              <i className="iconfont explain" />
            </KBTipsy>
          )}
        </div>
      </div>
    )
  }
})

KBStatisticNumber.PropTypes = {
  number: PropTypes.number,
  name: PropTypes.string,
  explain: PropTypes.string
}

export default KBStatisticNumber
