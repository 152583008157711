import React, { Component, PropTypes } from 'react'
import { INVOICES_STATE, INVOICES_SENDING_STATE } from 'app/constants'
import { KBTableWithoutData } from 'components'
import { fmoney, toPercent, formatYearDayEN } from 'utils/kbUtil'
import moment from 'moment'

var InvoicesPrintHtml = React.createClass({
  printDomJsx() {
    const { invoices, refund_type } = this.props

    const printStyle = {
      fontFamily:
        'Lantinghei SC,Open Sans,Arial,Hiragino Sans GB,Microsoft YaHei,微软雅黑,STHeiti,WenQuanYi Micro Hei,SimSun,sans-serif',
      width: 960,
      height: '100%',
      margin: '0 auto'
    }

    let jsx = invoices.map((invoice, index) => {
      let total_amount = invoice && parseFloat(invoice.total_amount)
      total_amount = isNaN(total_amount) ? 0 : total_amount

      let paid_amount = invoice && parseFloat(invoice.paid_amount)
      paid_amount = isNaN(paid_amount) ? 0 : paid_amount

      const remaining = total_amount - paid_amount
      const lineItems =
        (invoice && (invoice.sales_invoice_line_items || invoice.line_items)) ||
        []
      let isTax = lineItems.find(item => item.tax)
      return (
        <div style={printStyle} key={index}>
          <header>
            <div>
              <span style={{ fontSize: 22 }}>
                {invoice.sales_customer && invoice.sales_customer.full_name}
              </span>
              <br />
              <div style={{ marginTop: '5px', fontSize: 14 }}>
                <span style={{ marginTop: 10, fontSize: 14 }}>
                  {(invoice.location && invoice.location.name) || ''}
                </span>
              </div>
            </div>
          </header>
          <div style={{ marginTop: 20 }}>
            <div>
              <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                <li>
                  <span style={{ color: '#666', marginRight: 10 }}>
                    账单编号:
                  </span>
                  <span style={{ color: '#999' }}>{invoice.serial_number}</span>
                </li>
                {/*<li><span style={{color: '#666',marginRight: 10}}>生成日期:</span><span style={{color:'#999'}}>{ formatYearDayEN(invoice.created_at) }</span></li>*/}
                <li>
                  <span style={{ color: '#666', marginRight: 10 }}>
                    截止日期:
                  </span>
                  <span style={{ color: '#999' }}>
                    {formatYearDayEN(invoice.due_date)}
                  </span>
                </li>
              </ul>
            </div>
            {invoice.sales_area ? (
              <div
                style={{
                  marginTop: 30,
                  paddingBottom: 10,
                  borderBottom: '1px solid #e6e6e6'
                }}
              >
                <span style={{ fontSize: 16 }}>租赁情况</span>
              </div>
            ) : (
              ''
            )}
            {invoice.sales_area ? (
              <table
                style={{
                  width: '100%',
                  tableLayout: 'fixed',
                  marginTop: 15,
                  borderCollapse: 'collapse',
                  borderSpacing: 0
                }}
              >
                <thead
                  style={{ textAlign: 'left', backgroundColor: '#d4d9df' }}
                >
                  <tr>
                    <th style={{ padding: 10 }}>场地名称</th>
                    <th>面积/工位</th>
                    <th>单价</th>
                    <th>物业费</th>
                    <th className="t-right">总价</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={invoice.sales_area && invoice.sales_area.length > 0}
                  tableHeadNum="5"
                  tipMessage="暂无租赁信息"
                >
                  {invoice.sales_area &&
                    invoice.sales_area.map((json, index) => {
                      const chargeTypeUnit = {
                        by_desk: '个工位',
                        by_area: '㎡'
                      }
                      const chargePriceUnit = {
                        by_desk: '月/个',
                        by_area: '/月/㎡'
                      }
                      const chargeTypeData = {
                        by_desk: json.units || 0,
                        by_area: json.size || 0
                      }
                      return (
                        <tr
                          key={index}
                          style={{ borderBottom: '1px solid #e6e6e6' }}
                        >
                          <td style={{ padding: 10 }}>{json.name || ''}</td>
                          <td style={{ padding: 10 }}>
                            {chargeTypeData[json.charge_type] +
                              chargeTypeUnit[json.charge_type]}
                          </td>
                          <td>
                            ¥ {json.unit_price}{' '}
                            {chargePriceUnit[json.charge_type]}
                          </td>
                          <td>
                            {json.house_fee ? `¥ ${json.house_fee}/月/㎡` : ''}
                          </td>
                          <td className="t-right">
                            ¥ {fmoney(json.total_price, 2)}/月
                          </td>
                        </tr>
                      )
                    })}
                </KBTableWithoutData>
              </table>
            ) : (
              ''
            )}
            <div
              style={{
                marginTop: 30,
                paddingBottom: 10,
                borderBottom: '1px solid #e6e6e6'
              }}
            >
              <span style={{ fontSize: 16 }}>账单列表</span>
            </div>
            <table
              style={{
                width: '100%',
                marginTop: 15,
                borderCollapse: 'collapse',
                borderSpacing: 0
              }}
            >
              <thead style={{ textAlign: 'left', backgroundColor: '#d4d9df' }}>
                <tr>
                  <th style={{ width: '50%', padding: 10 }}>说明</th>
                  {invoice.invoice_type == 'refund' &&
                  lineItems.find(value => value.refund_type) ? (
                    <th>退款类型</th>
                  ) : null}
                  <th>单价</th>
                  <th>数量</th>
                  {isTax ? <th>税率</th> : <th />}
                  {isTax ? <th>税额</th> : <th />}
                  <th>总价</th>
                </tr>
              </thead>
              <tbody>
                {lineItems.map((json, index) => {
                  const amountTax =
                    json.tax &&
                    (json.unit_price / (1 + json.tax.rate)) * json.tax.rate
                  if (json.resource_type == 'Reservation') {
                    var name = ''

                    if (json.units_name == 'hours') {
                      name = '会议室预定会员费（单位：小时）'
                    } else if (json.units_name == 'minutes') {
                      name = '会议室超时会员费（单位：分钟）'
                    } else {
                      name = json.units_name
                    }

                    return (
                      <tr
                        key={index}
                        style={{ borderBottom: '1px solid #e6e6e6' }}
                      >
                        <td style={{ padding: 10 }}>{name}</td>
                        {invoice.invoice_type == 'refund' &&
                        json.refund_type ? (
                          <td>
                            {refund_type[json.refund_type] &&
                              refund_type[json.refund_type].name}
                          </td>
                        ) : null}
                        <td style={{ whiteSpace: 'nowrap' }}>
                          ¥ {fmoney(json.unit_price, 2)}
                        </td>
                        <td>{json.units}</td>
                        <td />
                        <td />
                        <td>¥ {fmoney(json.total_amount, 2)}</td>
                      </tr>
                    )
                  }

                  return (
                    <tr
                      key={index}
                      style={{ borderBottom: '1px solid #e6e6e6' }}
                    >
                      <td style={{ padding: 10 }}>
                        {json.description}
                        <br />
                        {json.start_date && json.end_date
                          ? '(' + json.start_date + '至' + json.end_date + ')'
                          : ''}
                      </td>
                      {invoice.invoice_type == 'refund' && json.refund_type ? (
                        <td>
                          {refund_type[json.refund_type] &&
                            refund_type[json.refund_type].name}
                        </td>
                      ) : null}
                      <td style={{ whiteSpace: 'nowrap' }}>
                        ¥ {fmoney(json.unit_price, 2)}
                      </td>
                      <td>{json.units}</td>
                      {isTax && json.tax ? (
                        <td>{toPercent(json.tax.rate, 1)}</td>
                      ) : (
                        <td />
                      )}
                      {isTax && json.tax ? (
                        <td style={{ whiteSpace: 'nowrap' }}>
                          ¥ {fmoney(amountTax, 2)}
                        </td>
                      ) : (
                        <td />
                      )}
                      <td style={{ whiteSpace: 'nowrap' }}>
                        ¥ {fmoney(json.total_amount, 2)}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div style={{ marginTop: 30, overflow: 'hidden' }}>
              <table
                style={{
                  float: 'right',
                  width: '50%',
                  textAlign: 'right',
                  borderCollapse: 'collapse',
                  borderSpacing: 0
                }}
              >
                <tbody>
                  <tr style={{ borderBottom: '1px solid #e6e6e6' }}>
                    <td
                      style={{ color: '#666', textAlign: 'left', padding: 10 }}
                    >
                      应付金额
                    </td>
                    <td style={{ color: '#999' }}>
                      ¥ {fmoney(total_amount, 2)}
                    </td>
                  </tr>
                  <tr style={{ borderBottom: '1px solid #e6e6e6' }}>
                    <td
                      style={{ color: '#666', textAlign: 'left', padding: 10 }}
                    >
                      实收金额
                    </td>
                    <td style={{ color: '#999' }}>
                      ¥ {fmoney(paid_amount, 2)}
                    </td>
                  </tr>
                  <tr style={{ borderBottom: '1px solid #e6e6e6' }}>
                    <td
                      style={{ color: '#666', textAlign: 'left', padding: 10 }}
                    >
                      剩余金额
                    </td>
                    <td style={{ color: '#999' }}>¥ {fmoney(remaining, 2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {invoice.location ? (
              <div style={{ marginTop: 30 }}>
                <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                  {invoice.location.provider_info ? (
                    <li>
                      <span
                        style={{
                          color: '#666',
                          marginRight: 10,
                          display: 'inline-block',
                          width: 105
                        }}
                      >
                        收款账户信息:
                      </span>
                      <span style={{ color: '#999' }}>
                        {invoice.location.provider_info}
                      </span>
                    </li>
                  ) : (
                    ''
                  )}
                  {invoice.location.provider_name ? (
                    <li>
                      <span
                        style={{
                          color: '#666',
                          marginRight: 10,
                          display: 'inline-block',
                          width: 105
                        }}
                      >
                        账户联系人:
                      </span>
                      <span style={{ color: '#999' }}>
                        {invoice.location.provider_name}
                      </span>
                    </li>
                  ) : (
                    ''
                  )}
                  {invoice.location.provider_phone_num ? (
                    <li>
                      <span
                        style={{
                          color: '#666',
                          marginRight: 10,
                          display: 'inline-block',
                          width: 105
                        }}
                      >
                        账户联系方式:
                      </span>
                      <span style={{ color: '#999' }}>
                        {invoice.location.provider_name}
                      </span>
                    </li>
                  ) : (
                    ''
                  )}
                  {invoice.location.provider_account_bank ? (
                    <li>
                      <span
                        style={{
                          color: '#666',
                          marginRight: 10,
                          display: 'inline-block',
                          width: 105
                        }}
                      >
                        账户开户行:
                      </span>
                      <span style={{ color: '#999' }}>
                        {invoice.location.provider_account_bank}
                      </span>
                    </li>
                  ) : (
                    ''
                  )}
                  {invoice.location.provider_account_name ? (
                    <li>
                      <span
                        style={{
                          color: '#666',
                          marginRight: 10,
                          display: 'inline-block',
                          width: 105
                        }}
                      >
                        账户名:
                      </span>
                      <span style={{ color: '#999' }}>
                        {invoice.location.provider_account_name}
                      </span>
                    </li>
                  ) : (
                    ''
                  )}
                  {invoice.location.provider_account_number ? (
                    <li>
                      <span
                        style={{
                          color: '#666',
                          marginRight: 10,
                          display: 'inline-block',
                          width: 105
                        }}
                      >
                        账号:
                      </span>
                      <span style={{ color: '#999' }}>
                        {invoice.location.provider_account_number}
                      </span>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )
    })
    return jsx
  },
  render() {
    return <div>{this.printDomJsx()}</div>
  }
})

export default InvoicesPrintHtml
