import React, { Component, PropTypes } from 'react'
import { KBImageCropperInput, KBLogo } from 'components'

var KBLogoCropper = React.createClass({
  getDefaultProps() {
    return {
      aspect: 2
    }
  },
  render() {
    const {
      field,
      aspect,
      className,
      bucket,
      type,
      obj,
      isRequired,
      accept,
      failureCallBack
    } = this.props
    return (
      <KBImageCropperInput
        title="Logo"
        aspect={aspect}
        className={className}
        bucket={bucket}
        isRequired={isRequired}
        field={field}
        type={type}
        accept={accept}
        failureCallBack={failureCallBack}
        isPublic
      >
        <div className="upload-portrait" style={{ marginBottom: 5 }}>
          {!field.dirty && obj && obj.name ? (
            <KBLogo
              size={100}
              numberOfChars={4}
              obj={obj}
              style={{ display: 'inline-block', borderRadius: 0 }}
            />
          ) : field.value ? (
            <img
              src={field.value}
              style={{
                borderRadius: 0,
                width: '100px',
                height: '100px',
                display: 'inline-block',
                marginLeft: 0
              }}
            />
          ) : (
            ''
          )}
          <button
            className="bordered-btn"
            type="button"
            style={field.value ? {} : { marginLeft: 0 }}
          >
            {field.dirty ? '已上传LOGO' : '上传LOGO'}
          </button>
        </div>
        <div style={{ color: '#999', fontSize: 12 }}>
          图片大小至少为100x100, 选择jpg, jpeg, 或png文件
        </div>
      </KBImageCropperInput>
    )
  }
})

KBLogoCropper.propTypes = {
  field: PropTypes.object.isRequired,
  aspect: PropTypes.number,
  type: PropTypes.string.isRequired,
  obj: PropTypes.object,
  bucket: PropTypes.string,
  className: PropTypes.string
}

export default KBLogoCropper
