import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import {
  KBFormError,
  KBButton,
  KBDateInput,
  KBPopover,
  KBForm
} from 'components'
import moment from 'moment'

var InvoicesApplicationAbortForm = React.createClass({
  abortInvoiceApp(model) {
    const { callback, location_id, invoiceApps } = this.props

    if (location_id) {
      model.location_id = location_id
    } else {
      delete model.location_id
    }
    model.invoicing_ids = invoiceApps.map(a => a.id).join(',')

    return api.abortInvoiceApplication(model).then(
      json => {
        callback && callback()
        KBPopover.close()
      },
      errors => {
        KBPopover.close()
      }
    )
  },

  render() {
    const {
      fields: { aborted_at },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.abortInvoiceApp)}
        fields={this.props.fields}
      >
        <KBFormError error={error} />
        <header className="kb-form-header">
          <h2 className="header-title">发票作废</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container-m">
          <div className="kb-row">
            <KBDateInput
              field={aborted_at}
              title="废弃日期"
              className="kb-form-group kb-form-c18 kb-form-alone"
            />
          </div>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state) {
  return {
    initialValues: {
      aborted_at: moment().format('YYYY-MM-DD')
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'InvoicesApplicationAbortForm',
  fields: ['aborted_at'],
  validate: validate,
  touchOnBlur: false
}

InvoicesApplicationAbortForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoicesApplicationAbortForm)

export default InvoicesApplicationAbortForm
