function inform(state = {}, action) {
  const { key, value } = action

  if (action.type.indexOf('INFORM') == -1 || !key) {
    return state
  }

  if (action.type == 'REMOVEINFORM_SUCCESS' && key == 'remove') {
    let newState = Object.assign({}, state)
    if (newState[value]) {
      delete newState[value]
    }
    return newState
  }

  if (action.type == 'RESETINFORM_SUCCESS' && key == 'reset') {
    return {}
  }

  if (key == 'inform' && value) {
    let newState = Object.assign({}, state)
    newState[value] = true
    return newState
  }

  return state
}

export default inform
