import React from 'react'
import { reduxForm } from 'redux-form'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import * as valid from 'utils/validate'
import {
  KBInput,
  KBSelect,
  KBFormError,
  KBButton,
  KBTextarea,
  KBPopoverTop,
  KBPopover,
  KBUserInput
} from 'components'
import { arrayToObject, objectToArray } from 'utils/kbUtil'
import { api, apiUtils } from 'app/services'
import {
  successState,
  reportActions,
  simpleAreaActions,
  intentAreaActions,
  apiCallFailure
} from 'app/actions'
import { CRM_OPPORTUNITY_DESIRES, OPPORTUNITY_DESIRES } from 'app/constants'
import { getIntentAreas, getSerializeLocationObj } from 'app/selectors'

var RecommendOfficeForm = React.createClass({
  displayName: 'RecommendOfficeForm',
  getInitialState() {
    return {
      areasOfLocation: []
    }
  },

  componentDidMount() {},

  addIntentOffice(model, type) {
    const {
      customer,
      createIntentOfficeAction,
      apiCallFailureAction,
      successAction,
      intentAreas,
      resetForm
    } = this.props
    let id = customer && customer.id
    let selectedAreaIds = []
    intentAreas &&
      intentAreas.map(a => {
        selectedAreaIds.push(String(a.area.id))
      })

    if (!id) {
      return apiCallFailureAction({
        status: 'error',
        message: '客户不存在，无法添加推荐房间'
      })
    }

    if (selectedAreaIds.includes(String(model.area_id))) {
      return apiCallFailureAction({
        status: 'error',
        message: '您已推荐过该房间，请重新选择'
      })
    }

    return api.createIntentOffice(id, model).then(
      json => {
        createIntentOfficeAction.success(json)
        if (type === 'create_another') {
          successAction({ message: '添加成功，继续添加下一个!' })
          resetForm()
          return true
        }
        KBPopover.close()
        return true
      },
      errors => {
        createIntentOfficeAction.failure(errors)
      }
    )
  },

  onLocationSelected(value) {
    const {
      getAreasAction,
      fields: { area_id }
    } = this.props

    let loc_id = value && value.id
    api.getAreas({ location_id: loc_id, area_type: 'private_office' }).then(
      json => {
        getAreasAction.success(json, {
          entityName: 'simpleAreas'
        })
        let areasOfLocation = json.response && json.response.body
        this.setState({
          areasOfLocation
        })
        area_id.onChange('')
      },
      errors => {
        getAreasAction.failure(errors)
      }
    )
  },

  chooseDesire(data) {
    const {
      fields: { desire }
    } = this.props

    desire.onChange(data)
  },

  render() {
    const {
      fields: { location_id, area_id, notes, desire },
      error,
      submitting,
      handleSubmit,
      locations,
      intentAreas,
      locationsObj
    } = this.props
    const { areasOfLocation } = this.state

    const halfClass = {
      className: 'kb-form-group kb-form-c39'
    }
    const oneClass = {
      className: 'kb-form-group kb-form-210 kb-form-alone'
    }

    return (
      <form onSubmit={handleSubmit(this.addIntentOffice)}>
        <header className="kb-form-header">
          <h2 className="header-title">添加推荐房间</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError err={error} />
        <div className="kb-form-container">
          <div className="kb-row">
            <div {...oneClass}>
              <label>
                推荐房间<span className="must-fill">*</span>
              </label>
              <div className="kb-form-66">
                <KBUserInput
                  placeholder={'选择分店'}
                  {...halfClass}
                  field={location_id}
                  multi={false}
                  users={locations}
                  callback={this.onLocationSelected}
                />
                <KBUserInput
                  placeholder={'选择房间'}
                  {...halfClass}
                  field={area_id}
                  multi={false}
                  users={areasOfLocation}
                />
              </div>
            </div>
          </div>
          <div className="kb-row">
            <div {...oneClass}>
              <label>兴趣程度</label>
              <div>
                <div className="kb-form-group r1_radios">
                  {CRM_OPPORTUNITY_DESIRES.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="kb-form-group r1_radio"
                        onClick={this.chooseDesire.bind(null, data.id, index)}
                      >
                        <div
                          className={`radio-btn ${
                            desire.value == data.id ? 'radio-btn-active' : ''
                          }`}
                        ></div>
                        <label className="label">{data.name}</label>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="kb-row">
            <KBTextarea title="备注" field={notes} {...oneClass} />
          </div>

          <div className="fixed-form-btn">
            <KBButton
              className="certain-btn"
              type="button"
              style={{ marginRight: '20px' }}
              onClick={handleSubmit(values => {
                this.addIntentOffice(values, 'create_another')
              })}
            >
              添加并继续
            </KBButton>
            <KBButton
              className="certain-btn"
              type="submit"
              style={{ marginRight: '20px' }}
              submitting={submitting}
            >
              添加并关闭
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const intentAreas = getIntentAreas(state) || []
  const locationsObj = getSerializeLocationObj(state)

  return {
    intentAreas,
    locationsObj,
    initialValues: {
      desire: 'ordinary'
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    createIntentOfficeAction: bindActionCreators(
      intentAreaActions.create,
      dispatch
    ),
    getAreasAction: bindActionCreators(simpleAreaActions.replace, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.location_id)) {
    errors.location_id = '请选择分店'
  }

  if (valid.required(values.area_id)) {
    errors.area_id = '请选择房间'
  }

  return errors
}

const formConfig = {
  form: 'RecommendOfficeForm',
  fields: ['location_id', 'area_id', 'desire', 'notes'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(RecommendOfficeForm)
