import React, { Component, PropTypes } from 'react'

var KBA = React.createClass({
  render() {
    var { href } = this.props
    return (
      <a {...this.props} href={href ? href : 'javascript:void(0)'}>
        {this.props.children}
      </a>
    )
  }
})

export default KBA
