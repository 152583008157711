import { routerReducer as router } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import { combineReducers } from 'redux'
import user from './user'
import crm from './crm'
import areas from './areas'
import locations from './locations'
import error from './error'
import inform from './inform'
import spaceSetting from './spaceSetting'
import locationSetting from './locationSetting'
import groupSetting from './groupSetting'
import locationRole from './locationRole'
import spaceRole from './spaceRole'
import loading from './loading'
import * as actions from '../actions'
// This normally imports the entire lodash package, but it can be more
// efficient by using bable-plugin-lodash
// See https://github.com/lodash/babel-plugin-lodash
import _ from 'lodash'
import { arrayToObject } from 'utils/kbUtil'
import {
  get,
  unsetObject,
  updateArray,
  replaceArray,
  unsetBatchObject
} from './reducer_utils'

// Updates an entity cache in response to any action with response.entities.
function entities(state = {}, action) {
  if (process.env.NODE_ENV == 'development') {
    // console.log("Entities: ", action)
  }

  if (action.id && action.key && action.type.indexOf('DELETE') != -1) {
    return unsetObject(state, `${action.key}.${action.id}`)
  }

  if (action.ids && action.key && action.type.indexOf('DELETE') != -1) {
    return unsetBatchObject(state, action.key, action.ids)
  }

  let isEntities = Boolean(
    action.response &&
      ((action.response.json && action.response.json.entities) ||
        action.response.entities)
  )
  let updateState = entitiesUpdate(state, action, isEntities)

  let replaceState = entitiesReplace(state, action, isEntities)
  if (updateState) {
    return updateState
  }

  if (replaceState) {
    return replaceState
  }

  if (action.type == actions.USER_LOGGED_OUT) {
    return {}
  }

  if (isEntities) {
    const newState = _.merge(
      {},
      state,
      action.response.entities || action.response.json.entities
    )
    return newState
  }

  return state
}

function isArrayToObject(data) {
  if (Object.prototype.toString.call(data) == '[object Array]') {
    return arrayToObject(data)
  }

  return data
}

function entitiesReplace(state, action, isEntities) {
  if (isEntities && action.type.indexOf('REPLACE') != -1 && action.entityName) {
    const data = action.response.entities || action.response.json.entities
    if (!Object.keys(data).length) {
      const s = _.cloneDeep(state)
      s[action.entityName] = {}
      return s
    }
    const newState = replaceObjOfKey(action.entityName, data, state, action)

    return newState
  }

  return null
}

function replaceObjOfKey(entityName, data, state, action) {
  const newState = _.cloneDeep(state)
  const keys = Object.keys(data)
  if (!keys) {
    return newState
  }

  if (!action.status) {
    return Object.assign({}, newState, data)
  }

  if (action.status == 'single' && keys.length == 1) {
    const { status, key, removeId } = action

    let modules = key.split('.')
    let list = newState[entityName]
    modules.forEach((json, index) => {
      if (index == modules.length - 1 && !list) {
        list[json] = []
      }
      list = list[json]
    })
    if (removeId) {
      list.splice(
        list.findIndex(json => json.id == removeId),
        1
      )
    }
    let values = Object.values(data[entityName])
    list.unshift(values[0])
    return newState
  }

  return newState
}

function entitiesUpdate(state, action, isEntities) {
  if (isEntities && action.type.indexOf('UPDATE') != -1) {
    const newState = _.mergeWith(
      {},
      state,
      action.response.entities || action.response.json.entities,
      updateArray
    )

    return newState
  }

  return null
}

function success(state = {}, action) {
  const { success } = action

  if (action.type === 'CLEAR_SUCCESS_STATE') {
    return {}
  }

  if (action.type !== 'SUCCESS_STATE') {
    return state
  }

  if (success && success.message) {
    return _.merge({}, state, {
      message: success.message,
      title: success.title
    })
  }

  return _.merge({}, state, { message: '操作成功!' })
}

// Create root reducers
export const rootReducer = asyncReducers => {
  return combineReducers({
    form: formReducer,
    entities,
    error,
    spaceSetting,
    locationSetting,
    groupSetting,
    locationRole,
    spaceRole,
    inform,
    success,
    loading,
    locations,
    crm,
    areas,
    user,
    router,
    ...asyncReducers
  })
}
export default rootReducer
