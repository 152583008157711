import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { successState, invoicesActions } from 'app/actions'
import { routerActions } from 'react-router-redux'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { KBInput, KBPopover, KBButton } from 'components'
import intl from 'react-intl-universal'
import { callbackify } from 'util'

var DeleteBillApprovalForm = React.createClass({
  getInitialState(){
    return {
      loading: false
    }
  },
  rejectSubmit(model) {
    const {
      id,
      successActions,
      routerActions,
      type,
      updateInvoicesActions
    } = this.props
    this.setState({loading:true},()=>{
      if (type == 'approved') {
        //审批通过
        api.deleteWriteBackApproved(id).then(
          json => {
            updateInvoicesActions.success(json)
            successActions({ message: '操作成功!' })
            routerActions.goBack()
            KBPopover.close()
            this.setState({loading:false})
          },
          error => {
            updateInvoicesActions.failure(error)
            KBPopover.close()
            this.setState({loading:false})
          }
        )
      } else {
        //审批拒绝
        api.deleteWriteBackRejected(id, model).then(
          json => {
            json.json.entities.invoices = json.json.entities.self_defined_line_item_types
            updateInvoicesActions.success(json)
            successActions({ message: '操作成功!' })
            KBPopover.close()
            this.setState({loading:false})
          },
          error => {
            updateInvoicesActions.failure(error)
            KBPopover.close()
            this.setState({loading:false})
          }
        )
      }
    })
  },

  render() {
    const {
      fields: { description },
      submitting,
      handleSubmit,
      type
    } = this.props

    return type == 'approved' ? (
      <div>
        <header className="kb-form-header">
          <div className="header-title">删除账单审批通过</div>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              onClick={this.rejectSubmit}
              submitting={this.state.loading}
            >
              确定
            </KBButton>
          </div>
        </div>
      </div>
    ) : (
        <form onSubmit={handleSubmit(this.rejectSubmit)}>
          <header className="kb-form-header">
            <div className="header-title">
              删除账单审批{type == 'approved' ? '通过' : '拒绝'}
            </div>
            <i
              className="iconfont header-close icon-cancel"
              onClick={KBPopover.close}
            />
          </header>

          <div className="kb-form-container">
            <div className="kb-row">
              <KBInput
                title={intl.get(
                  'meeting.meeting_application_reject_form.label.reason'
                )}
                title={`审批拒绝理由`}
                isRequired={true}
                className="kb-form-c18 kb-form-group kb-form-alone"
                field={description}
              />
            </div>
            <div className="kb-row fixed-form-btn">
              <span className="cancel-btn" onClick={KBPopover.close}>
                取消
            </span>
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={this.state.loading}
              >
                确定
            </KBButton>
            </div>
          </div>
        </form>
      )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    successActions: bindActionCreators(successState, dispatch),
    deleteInvoicesActions: bindActionCreators(invoicesActions.delete, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.description)) {
    errors.description = '理由不能为空'
  }

  return errors
}

const formConfig = {
  form: 'DeleteBillApprovalForm',
  fields: ['description'],
  validate: validate,
  touchOnBlur: false
}

DeleteBillApprovalForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(DeleteBillApprovalForm)

export default DeleteBillApprovalForm
