import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import { isListValue, getListFirstValue } from 'utils/kbUtil'

const menu = [
  {
    name: '活动概览',
    value: 'info'
  },
  {
    name: '活动报名',
    value: 'orders'
  },
  {
    name: '活动评论',
    value: 'comments'
  }
]

var ActivitiesContainer = React.createClass({
  displayName: 'ActivitiesContainer',
  componentDidMount() {},
  render() {
    const { section, loc_id, activity_id } = this.props

    return (
      <div className="kb-out-box clear-fix" style={{ paddingTop: 0 }}>
        <section className="kb-content">
          <nav className="nav-container f-left">
            <div className="nav-container-content">
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">活动</h2>
              </header>
              <div className="nav-body">
                <ul className="nav-container-list">
                  {menu.map((data, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          isListValue(data.value, section)
                            ? 'nav-container-list-hover'
                            : ''
                        }
                      >
                        {loc_id ? (
                          <Link
                            to={`/admin/locations/${loc_id}/activities/${activity_id}/${getListFirstValue(
                              data.value
                            )}`}
                          >
                            {' '}
                            {data.name}{' '}
                          </Link>
                        ) : (
                          <Link
                            to={`/admin/memberships/memberManage/activities/${activity_id}/${getListFirstValue(
                              data.value
                            )}`}
                          >
                            {' '}
                            {data.name}{' '}
                          </Link>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </nav>
          {this.props.children}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let loc_id = props.params.id
  let activity_id = props.params.activity_id
  let sections = props.location.pathname.split('/')
  let section = ''
  if (loc_id) {
    section = sections.length > 5 ? sections[6] : ''
  } else {
    section = sections.length > 5 ? sections[6] : ''
  }
  return {
    loc_id,
    activity_id,
    section
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesContainer)
