import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { locationFloorsAction } from 'app/actions'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { KBInput, KBPopover, KBForm, KBFormError, KBButton } from 'components'

var FloorInfoForm = React.createClass({
  floorForm(model) {
    const { loc_id } = this.props
    model.layer_num = model.layer_num_i + '层'

    api.postLocationFloor(loc_id, model).then(
      json => {
        this.props.createLocationFloorAction.success(json)
        KBPopover.close()
      },
      error => {
        this.props.createLocationFloorAction.failure(error)
        KBPopover.close()
      }
    )
  },

  render() {
    const {
      fields: { layer_num_i, size },
      submitting,
      handleSubmit
    } = this.props

    return (
      <KBForm
        type="reduxForm"
        ref="floorForm"
        onSubmit={handleSubmit(this.floorForm)}
        fields={this.props.fields}
      >
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">添加新楼层</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>

        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              title="楼层序号"
              hint="请输入楼层序号，如’14层‘，输入14即可"
              isRequired={true}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={layer_num_i}
              fistFoucs={true}
              type="number"
            />
          </div>
          <div className="kb-row">
            <KBInput
              title="楼层面积"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={size}
              inputClass="input-area"
              type="number"
            />
          </div>

          <div className="kb-row m-top fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createLocationFloorAction: bindActionCreators(
      locationFloorsAction.create,
      dispatch
    ),
    updateLocationFloorAction: bindActionCreators(
      locationFloorsAction.update,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.layer_num_i)) {
    errors.layer_num_i = '请输入楼层序号'
  }

  return errors
}

const formConfig = {
  form: 'FloorInfoForm',
  fields: ['layer_num_i', 'size'],
  validate: validate,
  touchOnBlur: false
}

FloorInfoForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(FloorInfoForm)

export default FloorInfoForm
