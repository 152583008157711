import React, { Component, PropTypes } from 'react'
import { USER_SELECT_INPUT } from 'app/constants'
import _ from 'lodash'

var Select = require('react-select')
var KBUserSelect = React.createClass({
  getInitialState() {
    return {
      selectedUsers: this.props.selectedUsers || []
    }
  },

  getDefaultProps() {
    return {
      filterOption: (value, filterString) => {
        if (!filterString) {
          return true
        }

        if (!value.name && !value.name_pinyin) {
          return false
        }

        if (
          value.name.indexOf(filterString) != -1 ||
          (value.name_pinyin && value.name_pinyin.indexOf(filterString) != -1)
        ) {
          return true
        }
      }
    }
  },

  componentWillReceiveProps(nextProps) {
    const { selectedUsers } = nextProps

    if (selectedUsers && !_.isEqual(selectedUsers, this.props.selectedUsers)) {
      this.setState({
        selectedUsers
      })
    }
  },

  handleSelectChange(value) {
    // update select box
    this.setState({
      selectedUsers: Array.isArray(value) ? value : [value]
    })
    // update form field value
    this.props.onUsersSelected && this.props.onUsersSelected(value)
  },

  onInputChange(name) {
    this.props.onInputChange && this.props.onInputChange(name)
  },

  render() {
    const {
      multi,
      placeholder,
      disabled,
      onUsersSelected,
      valueRenderer,
      filterOption,
      noResultsText
    } = this.props
    var selectedUsers = this.state.selectedUsers
    var selectedValue = multi
      ? selectedUsers
      : selectedUsers.length > 0
      ? selectedUsers[0]
      : null
    var users = this.props.users

    return (
      <Select
        multi={multi}
        labelKey="name"
        valueKey="id"
        filterOption={filterOption}
        searchPromptText="name_pinyin"
        placeholder={placeholder || USER_SELECT_INPUT.placeholder}
        noResultsText={noResultsText || USER_SELECT_INPUT.noResultsText}
        disabled={disabled}
        clearable={false}
        value={selectedValue}
        options={users}
        matchProp={'any'}
        onChange={this.handleSelectChange}
        onInputChange={this.onInputChange}
      />
    )
  }
})

KBUserSelect.propTypes = {
  users: PropTypes.array.isRequired,
  selectedUsers: PropTypes.array,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  placeholder: PropTypes.string,
  onUsersSelected: PropTypes.func
}

export default KBUserSelect
