import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router'
import { apiCallFailure } from 'app/actions'
import { LoginRedirect } from 'components/mixins'

var App = React.createClass({
  mixins: [LoginRedirect],
  componentDidMount() {
    const { user } = this.props
    this.checkAccess(user)
  },
  render() {
    return (
      <div>
        <h2>App</h2>
        <div>
          <Link to="/login">进入登录页面</Link>
          <br />
          <Link to="/admin/locations">进入空间内部(必须登录以后)</Link>
          <br />
          <Link to="/spaces/create">创建空间(必须登录以后)</Link>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state) {
  let user = state.user
  return { entities: state.entities, user }
}

// Map action creators dispatch to props
// So we can call this.props.actions.<actionName>(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
