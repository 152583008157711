import React from 'react'

var CountdownButton = React.createClass({
  displayName: 'CountdownButton',

  propTypes: {
    initialTimeRemaining: React.PropTypes.number.isRequired,
    interval: React.PropTypes.number,
    formatFunc: React.PropTypes.func,
    tickCallback: React.PropTypes.func,
    completeCallback: React.PropTypes.func
  },

  getDefaultProps: function() {
    return {
      interval: 1000,
      formatFunc: null,
      tickCallback: null,
      initialTimeRemaining: 60000,
      completeCallback: null
    }
  },

  getInitialState: function() {
    return {
      timeRemaining: 0,
      timeoutId: null
    }
  },

  componentDidMount: function() {
    this._stopTimer()
  },

  componentWillUnmount: function() {
    this._stopTimer()
  },

  _tick: function() {
    var interval = this.props.interval
    var timeRemaining = Math.max(this.state.timeRemaining - interval, 0)
    var countdownComplete = timeRemaining <= 0

    if (countdownComplete) {
      this._stopTimer()
      this.props.completeCallback && this.props.completeCallback()
    } else {
      this.setState({
        timeRemaining: timeRemaining
      })
    }

    this.props.tickCallback && this.props.tickCallback(timeRemaining)
  },

  _stopTimer: function() {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId)
    }
    this.setState({
      timeRemaining: 0,
      timeoutId: null
    })
  },

  _startTimer: function() {
    this.setState({
      timeRemaining: this.props.initialTimeRemaining,
      timeoutId: null
    })
  },

  _handleClick: function(e) {
    var temp = (this.props.onClick && this.props.onClick(e)) || false

    if (this.state.timeRemaining > 0 || !temp) {
      return false
    }
    this._startTimer()
    this.setState({
      timeoutId: setInterval(this._tick, this.props.interval)
    })
  },

  render: function() {
    var timeRemaining = this.state.timeRemaining
    var children =
      timeRemaining > 0
        ? `剩余(${timeRemaining / 1000})秒`
        : this.props.children
    return (
      <a
        disabled={timeRemaining > 0}
        className={this.props.className}
        style={this.props.style}
        onClick={this._handleClick}
      >
        {children}
      </a>
    )
  }
})

export default CountdownButton
