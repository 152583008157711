import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { successState, reportActions } from 'app/actions'
import {
  KBInput,
  KBRadio,
  KBDateInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import * as selectors from 'app/selectors'
import moment from 'moment'
import * as constants from 'app/constants'
import { EXPORT_HINT } from 'app/constants/hint'

var globalIsLocation = null

let InvoicesReport = React.createClass({
  getInitialState() {
    return {
      tab: 'invoices'
    }
  },

  toReportCenter() {
    const {reportName} = this.props
    KBPopoverTop.show(
      <KBToReportCenter
        title={`${reportName}导出`}
        message={`${reportName}已成功导出，可以前往报表中心下载`}
      />
    )
  },

  invoicesReport(model) {
    const {
      successAction,
      createReportActions,
      loc_id,
      isLocations,
      filters,
      reportType,
      member
    } = this.props
    const { tab } = this.state
    if (isLocations) {
      model.location_id = loc_id
    }
    if (!model.location_id) {
      delete model.location_id
    }

    if (filters && filters.customer_type) {
      model.customer_type = filters.customer_type
    }
    if (filters && filters.customer_id) {
      model.customer_id = filters.customer_id
    }

    delete model.tab_type

    model.start_date = model.begin_date
    delete model.begin_date

    if (reportType == 'invoices') {
      model.source_type = 'contract'
    } else {
      model.source_type = 'personal'
    }

    if (tab == 'renting_invoices') {
      delete model.status
      delete model.source_type
      return api.salesAreaInvoiceReport(model).then(res => {
        const { result } = res.json
        createReportActions.success(res)
        this.toReportCenter && this.toReportCenter()
        return true
      })
    }

    if(member) {
      // 这个接口要sales_customer_id
      model.sales_customer_id = member.sales_customer.id
      delete model.customer_id
    }
    return api.salesInvoiceReport(model).then(res => {
      const { result } = res.json
      createReportActions.success(res)
      this.toReportCenter && this.toReportCenter()
      return true
    })
  },

  componentDidMount() {
    const {
      fields: { begin_date, end_date },
      filters,
      isLocations,
      loc_id,
      locations
    } = this.props

    begin_date.onChange(
      filters.from ||
      moment()
        .date(1)
        .toDate()
    )
    end_date.onChange(filters.to || new Date())
  },

  renderLocation() {
    const {
      fields: { location_id },
      reportName
    } = this.props
    const { locations, isLocations, filters } = this.props
    const oneClass = {
      className: 'kb-form-group kb-form-c18 kb-form-alone'
    }
    if (isLocations) {
      return ''
    }

    let hasLocId = filters && filters.location_id ? true : false
    // 获取已选择的分店
    let selectedLoc =
      locations &&
      locations.filter(data => {
        return data.id == filters.location_id
      })
    return (
      <div className="kb-row" onClick={this.setScrollTop}>
        <KBUserInput
          title="分店"
          placeholder={'请选择分店'}
          {...oneClass}
          field={location_id}
          disabled={hasLocId}
          hint={
            hasLocId
              ? null
              : `选择要导出的分店${reportName}，不选则默认导出当前空间的全部${reportName}`
          }
          selectedUsers={selectedLoc}
          multi={false}
          users={locations}
        />
      </div>
    )
  },

  changeTab(tabs) {
    const {
      fields: { tab_type }
    } = this.props
    let tabId = tabs.id

    tab_type.onChange(tabId)
    this.setState({
      tab: tabId
    })
  },

  handleScroll(e) {
    return this.formScroll.scrollHeight
  },

  setScrollTop(e) {
    let scrollHeight = this.handleScroll()
    this['invoicesScroll'].scrollTop = scrollHeight
  },

  getDisabledDays(date) {
    // 最近6个月
    const earliest = moment().subtract(6, 'month')
    const day = moment(date)
    return day.isBefore(earliest)
  },

  render() {
    const {
      fields: { location_id, begin_date, end_date, status, date_type, invoice_type },
      error,
      submitting,
      handleSubmit,
      orderStateData,
      invoiceTypes,
      reportType,
      reportName
    } = this.props
    const { locations, isLocations, filters } = this.props
    const { tab } = this.state
    const oneClass = {
      className: 'kb-form-group kb-form-c18 kb-form-alone'
    }
    // 获取已选择的 支付状态
    let statusOptions = orderStateData || constants.INVOICES_UPDATE_STATE
    let inStatusArray =
      (filters && filters.status && filters.status.split(',')) || []
    let selectedStatus = statusOptions.filter(data => {
      return inStatusArray.includes(data.id)
    })
    // 获取已选择的账单类型
    let invoiceTypeOptions = invoiceTypes || constants.INVOICES_UPDATE_TYPE
    let inTypeArray =
      (filters && filters.invoice_type && filters.invoice_type.split(',')) || []
    let selectedTypes = invoiceTypeOptions.filter(data => {
      return inTypeArray.includes(data.id)
    })

    const tabs = [
      {
        id: 'invoices',
        name: '账单'
      },
      {
        id: 'renting_invoices',
        name: '租赁情况账单'
      }
    ]

    return (
      <form
        onSubmit={handleSubmit(this.invoicesReport)}
        ref={ref => {
          this.formScroll = ref
        }}
        onScroll={this.handleScroll}
      >
        <header className="kb-form-header">
          <h2 className="header-title">导出{reportName}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div
          className="kb-form-container"
          ref={ref => {
            this.invoicesScroll = ref
          }}
        >
          {reportType == 'invoices' ? (
            <ul
              className="nav-section-tab"
              style={{ marginBottom: 15, borderBottom: '1px solid #e6e6e6' }}
            >
              {tabs.map((data, index) => {
                return (
                  <li
                    key={index}
                    className={`${tab && tab == data.id ? 'nav-section-tab-active' : ''
                      }`}
                    onClick={this.changeTab.bind(null, data)}
                  >
                    <a className="status-text">{data.name}</a>
                  </li>
                )
              })}
            </ul>
          ) : null}
          {/* {
            tab == 'renting_invoices' ? null :
            <p style={{color:'#c8c8c8',fontSize:12,marginBottom:20}}>最多导出3个月账单，时间跨度不能过大, 涉及大量数据导出问题</p>
          } */}
          <KBRadio
            isRequired
            className="kb-form-group kb-form-c178 kb-form-alone"
            inClassName="kb-from-initial"
            divClassName="kb-from-radio"
            rightLabel="label-rt"
            title="导出方式"
            arrayType={[
              {title: '按结算日期导出', value: 'withdrawl'},
              {title: '按会议起止日期导出', value: 'reservation'},
            ]}
            name="date_type"
            field={date_type}
          />
          {this.renderLocation()}
          <div className="kb-row kb-form-66" onClick={this.setScrollTop}>
            <KBDateInput
              type="date"
              title="开始日期"
              isRequired={true}
              isShowCenter={true}
              className="kb-form-group kb-div-c39"
              field={begin_date}
              disabledDays={this.getDisabledDays}
            />
            <KBDateInput
              type="date"
              title="结束日期"
              titleClass="label-rt"
              isShowCenter={true}
              className="kb-form-group kb-div-c48"
              field={end_date}
              disabledDays={this.getDisabledDays}
            />
          </div>
          {tab && tab == 'invoices' ? (
            <div className="kb-row">
              <KBUserInput
                title="支付状态"
                placeholder={'请选择支付状态(可多选)'}
                {...oneClass}
                field={status}
                selectedUsers={selectedStatus}
                multi={true}
                users={orderStateData || constants.INVOICES_UPDATE_STATE}
              />
            </div>
          ) : null}
          {tab && tab == 'invoices' ? (
            <div className="kb-row" onClick={this.setScrollTop}>
              <KBUserInput
                title={`${reportName}类型`}
                placeholder={`请选择${reportName}类型(可多选)`}
                {...oneClass}
                field={invoice_type}
                selectedUsers={selectedTypes}
                multi={true}
                users={invoiceTypes || constants.INVOICES_UPDATE_TYPE}
              />
            </div>
          ) : null}
          <p style={{fontSize: '12px', color: '#7F8FA4'}}>{EXPORT_HINT.concat_admin}</p>
          <div className="kb-row fixed-form-btn" onClick={this.setScrollTop}>
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确认导出
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const locations = selectors.getSerializeLocation(state)
  globalIsLocation = props.isLocations

  return {
    locations,
    reportName: props.reportType === 'invoices' ? '账单' : '订单',
    initialValues: {
      date_type: 'withdrawl'
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    createReportActions: bindActionCreators(reportActions.create, dispatch)
  }
}

const validate = values => {
  const errors = {}

  // if (!globalIsLocation && valid.required(values.location_id)) {
  //   errors.location_id = '请选择分店ID'
  // }

  if (valid.required(values.begin_date)) {
    errors.begin_date = '请选择开始时间'
  }

  if (valid.required(values.end_date)) {
    errors.end_date = '请选择结束时间'
  }

  // const beginDate = moment(values.begin_date)
  // const endDate = moment(values.end_date)
  //
  // if (values.tab_type != 'renting_invoices') {
  //   if (values.begin_date && values.end_date && endDate.diff(beginDate, 'month', 'true') > 3) {
  //     errors.begin_date = '最多导出3个月账单，时间跨度不能过大, 涉及大量数据导出问题'
  //   }
  // }

  return errors
}

const formConfig = {
  form: 'InvoicesReport',
  fields: [
    'location_id',
    'begin_date',
    'end_date',
    'status',
    'date_type',
    'invoice_type',
    'customer_type',
    'customer_id',
    'tab_type'
  ],
  validate: validate,
  touchOnBlur: false
}

InvoicesReport = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoicesReport)

export default InvoicesReport
