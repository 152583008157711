import React, { PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import {
  KBImageCropperInput,
  KBFormError,
  KBAvatar,
  KBButton,
  KBQiniuImage
} from 'components'

//上传图片样本
var LogoCropperForm = React.createClass({
  getInitialState() {
    return {
      description: this.props.description
        ? this.props.description
        : '图片大小至少为232x134, 选择jpg, jpeg, 或png文件，点击保存按钮，将图片同步到系统',
      loading: false
    }
  },
  failureCallBack(errorMsg) {
    this.setState({ errorMsg: errorMsg })
  },
  callback(value) {
    if (!value) {
      return
    }

    this.props.updateImageUrl && this.props.updateImageUrl(value)
  },

  loadingCallBack(loading) {
    this.setState({
      loading
    })
  },
  renderImg() {
    const {
      fields: { avatar, logo, image, logo2, big_logo },
      user,
      filedType,
      type,
      shape,
      imgSize,
      aspect,
      accept
    } = this.props
    const { loading } = this.state

    if (filedType === 'logo2') {
      return (
        <KBImageCropperInput
          aspect={aspect}
          callback={this.callback}
          failureCallBack={this.failureCallBack}
          field={logo2}
          style={{ display: 'inline-block' }}
          type={type}
          accept={accept}
        >
          <div
            className={`${shape == 2 ? 'upload-square' : 'upload-portrait'}`}
          >
            <KBAvatar
              imgSize={imgSize}
              user={
                logo2.value
                  ? { avatar: logo2.value }
                  : {
                      ...user,
                      avatar: user.logo2
                    }
              }
              imgStyle={{ marginLeft: 0, verticalAlign: 'middle' }}
              style={{ display: 'inline-block', marginLeft: 0 }}
            />
            <button className="bordered-btn" type="button">
              {loading ? '上传中...' : logo.dirty ? '上传图片' : '上传新图片'}
            </button>
          </div>
        </KBImageCropperInput>
      )
    } else if (filedType === 'big_logo') {
      return (
        <KBImageCropperInput
          aspect={aspect}
          callback={this.callback}
          failureCallBack={this.failureCallBack}
          field={big_logo}
          style={{ display: 'inline-block' }}
          type={type}
          accept={accept}
        >
          <div
            className={`${shape == 2 ? 'upload-square' : 'upload-portrait'}`}
          >
            {big_logo.value || user.big_logo ? (
              <KBAvatar
                imgSize={imgSize}
                user={
                  big_logo.value
                    ? { avatar: big_logo.value }
                    : {
                        ...user,
                        avatar: user.big_logo
                      }
                }
                imgStyle={{ marginLeft: 0, verticalAlign: 'middle' }}
                style={{ display: 'inline-block', marginLeft: 0 }}
              />
            ) : null}
            <button
              className="bordered-btn"
              type="button"
              style={
                !(big_logo.value || user.big_logo) ? { marginLeft: 0 } : {}
              }
            >
              {loading ? '上传中...' : logo.dirty ? '上传图片' : '上传新图片'}
            </button>
          </div>
        </KBImageCropperInput>
      )
    } else if (filedType === 'logo') {
      return (
        <KBImageCropperInput
          aspect={aspect}
          callback={this.callback}
          failureCallBack={this.failureCallBack}
          field={logo}
          style={{ display: 'inline-block' }}
          type={type}
          accept={accept}
        >
          <div
            className={`${shape == 2 ? 'upload-square' : 'upload-portrait'}`}
          >
            <KBAvatar
              imgSize={imgSize}
              user={
                logo.value
                  ? { avatar: logo.value }
                  : {
                      ...user,
                      avatar: user.logo
                    }
              }
              imgStyle={{ marginLeft: 0, verticalAlign: 'middle' }}
              style={{ display: 'inline-block', marginLeft: 0 }}
            />
            <button className="bordered-btn" type="button">
              {loading ? '上传中...' : logo.dirty ? '上传图片' : '上传新图片'}
            </button>
          </div>
        </KBImageCropperInput>
      )
    } else if (filedType === 'image') {
      return (
        <KBImageCropperInput
          aspect={aspect}
          callback={this.callback}
          failureCallBack={this.failureCallBack}
          field={image}
          style={{ display: 'inline-block' }}
          type={type}
          accept={accept}
        >
          <div
            className={`${shape == 2 ? 'upload-square' : 'upload-portrait'}`}
          >
            <KBAvatar
              imgSize={imgSize}
              user={
                image.value
                  ? { avatar: image.value }
                  : {
                      ...user,
                      avatar: user.image
                    }
              }
              imgStyle={{ marginLeft: 0, verticalAlign: 'middle' }}
              style={{ display: 'inline-block', marginLeft: 0 }}
            />
            <button className="bordered-btn" type="button">
              {loading ? '上传中...' : image.dirty ? '上传图片' : '上传新图片'}
            </button>
          </div>
        </KBImageCropperInput>
      )
    } else {
      return (
        <KBImageCropperInput
          aspect={aspect}
          loadingCallBack={this.loadingCallBack}
          callback={this.callback}
          failureCallBack={this.failureCallBack}
          field={avatar}
          style={{ display: 'inline-block' }}
          type={type}
          accept={accept}
        >
          <div
            className={`${shape == 2 ? 'upload-square' : 'upload-portrait'}`}
          >
            <KBAvatar
              imgSize={imgSize}
              user={avatar.value ? { avatar: avatar.value } : user}
              size={100}
              imgStyle={{ marginLeft: 0, verticalAlign: 'middle' }}
              style={{
                display: 'inline-block',
                marginLeft: 0
              }}
            />
            <button className="bordered-btn" type="button">
              {loading ? '上传中...' : avatar.dirty ? '上传头像' : '上传新头像'}
            </button>
          </div>
        </KBImageCropperInput>
      )
    }
  },
  render() {
    const {
      fields: { avatar, logo, image },
      error,
      submitting,
      handleSubmit,
      imgSubmit,
      user,
      filedType,
      type
    } = this.props
    const { description, errorMsg } = this.state
    return (
      <form onSubmit={handleSubmit(imgSubmit)}>
        <KBFormError err={error} />
        {this.renderImg()}
        <div className="kb-form-group" style={{ float: 'none' }}>
          <div
            className={`description ${errorMsg ? 'lr-error' : ''}`}
            style={{ marginBottom: '20px', color: '#999' }}
          >
            {errorMsg ? errorMsg : description}
          </div>
        </div>
        <div className="kb-row">
          <KBButton
            className="certain-btn"
            type="submit"
            submitting={submitting}
          >
            保存
          </KBButton>
        </div>
      </form>
    )
  }
})

LogoCropperForm.propTypes = {
  imgSubmit: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  description: PropTypes.string
}

const formConfig = {
  form: 'LogoCropperForm',
  fields: ['logo', 'avatar', 'image', 'logo2', 'big_logo'],
  touchOnBlur: false
}

export default reduxForm(formConfig)(LogoCropperForm)
