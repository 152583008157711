import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import AssistBox from './../shuck/AssistBox'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { leadsActions } from 'app/actions'
import { checkObject } from 'utils/kbUtil'
import {
  KBInput,
  KBPopover,
  KBFormError,
  KBButton,
  KBUserInput,
  KBSelect,
  KBDateInput
} from 'components'

var LeadForm = React.createClass({
  displayName: 'LeadForm',
  getInitialState() {
    return {}
  },
  createLeadForm(model) {
    const { lead_id, updateLead } = this.props

    return api.updateLead(lead_id, model).then(json => {
      updateLead.success(json, { key: 'leads' })
      KBPopover.close()
    })
  },
  render() {
    const {
      fields: {
        title,
        company,
        phone,
        mobile,
        email,
        state,
        location_ids,
        source,
        employee_number,
        sales_leader,
        salesmen,
        business_card,
        notes
      },
      error,
      submitting,
      handleSubmit
    } = this.props

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    const leadState = [
      {
        id: 'not_contact',
        name: '未联系'
      },
      {
        id: 'contacted',
        name: '已联系'
      },
      {
        id: 'new_opportunity',
        name: '新机会'
      },
      {
        id: 'no_chance',
        name: '没有机会'
      }
    ]

    return (
      <form onSubmit={handleSubmit(this.createLeadForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">修改线索</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError err={this.props.error} />
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={title}
              title="姓名"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="状态"
              field={state}
              arrayData={leadState}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={company}
              title="公司"
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="电话"
              field={mobile}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { entities } = state

  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createLead: bindActionCreators(leadsActions.create, dispatch),
    updateLead: bindActionCreators(leadsActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.title)) {
    errors.title = '请输入标题'
  }

  if (valid.required(values.company)) {
    errors.company = '请输入公司名称'
  }

  if (valid.required(values.state)) {
    errors.state = '请选择状态'
  }

  if (values.mobile && valid.mobile(values.mobile)) {
    errors.mobile = '请输入正确的电话'
  }

  return errors
}

const formConfig = {
  form: 'LeadForm',
  fields: [
    'title',
    'company',
    'phone',
    'mobile',
    'email',
    'state',
    'location_ids',
    'source',
    'employee_number',
    'sales_leader',
    'salesmen',
    'business_card',
    'notes'
  ],
  validate: validate,
  initialValues: { state: 'pending' },
  touchOnBlur: false
}

LeadForm = reduxForm(formConfig, mapStateToProps, mapDispatchToProps)(LeadForm)

export default LeadForm
