import React from 'react'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { api } from 'app/services'
import { bindActionCreators } from 'redux'
import { KBLoadingContainer } from 'components'
import { apiCallFailure } from 'app/actions'

var ValidateEmail = React.createClass({
  displayName: 'ValidateEmail',
  getInitialState() {
    return {
      loading: true,
      isLoaded: false,
      errors: null,
      tenantLoginUrl: ''
    }
  },

  componentDidMount() {
    const { routerActions, apiCallFailureAction } = this.props

    if(this.props.location.query.autoAuth === 'true') {
      // 自助认证验证邮件激活
      api.authEmployeeVerify({
        verify_token: this.props.location.query.verify_token
      }).then(res => {
        console.log(res)
        this.setState({
          isLoaded: true
        })
      }).catch(error => {
        console.log(error)
        this.setState({ isLoaded: false, errors: error.message })
        apiCallFailureAction({
          status: 'error',
          message: error.message || ''
        })
      }).finally(() => {
        this.setState({
          loading: false
        })
      })
    } else if (this.props.token.length > 0) {
      this.setState({ loading: true, isLoaded: false, errors: null })
      api
        .confirm({ confirmation_token: this.props.token })
        .then(
          ({ json }) => {
            this.setState({
              isLoaded: true
            })
            const { reset_password_token, redirect_url } = json

            if (reset_password_token) {
              routerActions.replace(
                '/reset_password?reset_password_token=' + reset_password_token
              )
            } else if (redirect_url) {
              const tenantLoginUrl = `${redirect_url}user/login`
              window.open(tenantLoginUrl)
              this.setState({
                tenantLoginUrl
              })
            } else {
              routerActions.replace('/login')
            }
          },
          error => {
            this.setState({ isLoaded: false, errors: error.message })
            apiCallFailureAction({
              status: 'error',
              message: error.message || ''
            })
          }
        )
        .finally(() => {
          this.setState({
            loading: false
          })
        })
    } else {
      this.setState({ loading: false, isLoaded: false, errors: '验证失败' })
    }
  },

  render() {
    const { tenantLoginUrl } = this.state
    let body = tenantLoginUrl ? (
      <a href={tenantLoginUrl} style={{ textDecoration: 'underline' }}>
        进入登录页面
      </a>
    ) : (
      <Link style={{ textDecoration: 'underline' }} to="/login">
        进入登录页面
      </Link>
    )
    let title = this.state.loading
      ? '正在验证'
      : this.state.isLoaded
      ? '验证成功'
      : '验证失败'
    // 自助认证激活页面单独处理文案
    if(this.props.location.query.autoAuth === 'true') {
      body = ''
      title = this.state.loading
        ? '正在验证'
        : this.state.isLoaded
        ? '欢迎成为凯德办公会员'
        : '激活失败'
    }
    return (
      <div className="lr-out-box">
        <div className="comment-box1">
          {
            this.state.isLoaded && <div className="kb-box unlock-account-email">
              <i />
            </div>
          }
          <div className="kb-box t-center">
            <span className="green-font" style={{color: this.state.isLoaded ? '' : 'red'}}>{title}</span>
            {!this.state.isLoaded ? (
              <p
                className="green-font"
                style={{ color: 'red', fontSize: 14, textAlign: 'center', wordBreak: 'break-all' }}
              >
                {this.state.errors}
              </p>
            ) : null}
          </div>
          <div className="kb-box t-center unlock-previous-step">
            <KBLoadingContainer loading={this.state.loading}>
              {body}
            </KBLoadingContainer>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const token = ownProps.location.query.confirmation_token || ''
  return {
    token
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch)
  }
}

ValidateEmail = connect(mapStateToProps, mapDispatchToProps)(ValidateEmail)

export default ValidateEmail
