import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import ReactDOM from 'react-dom'
import { reduxForm, getValues } from 'redux-form'
import { areaActions } from 'app/actions'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBDateInput,
  KBUserInput,
  KBRadio,
  KBPopover,
  KBSelect,
  KBFormError,
  KBButton,
  KBCheckBox
} from 'components'
import DeskNumber from 'components/views/DeskNumber'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import { OFFICE_AREA_STATE, RESERVATION_PRIVILEGES } from 'app/constants'
import { ADD_AREA } from 'app/constants/hint'
import * as selectors from 'app/selectors'

const AREA_TYPE = {
  office: '分区',
  room: '场地'
}

var BatchUpdateAreasForm = React.createClass({
  getInitialState() {
    const area = this.props.area && this.props.area.orientation
    const selectedContent = area != null ? area : ''
    return {
      selectedContent: selectedContent,
      lockChecked: this.props.area && this.props.area.lock,
      wallChecked: this.props.area && this.props.area.wall
    }
  },

  componentDidMount() {},

  AreaForm(model) {
    const { loc_id, area_ids, areaType, updateAreaAction } = this.props
    const { lockChecked, wallChecked } = this.state
    model.area_ids = area_ids.join(',')
    model.lock = lockChecked
    model.wall = wallChecked

    if (areaType != 'room') {
      delete model.device_ids
      delete model.management_only
      delete model.max_meanwhile_number
      delete model.floor
    }

    return api.batchUpdateAreas(model).then(
      json => {
        updateAreaAction.success(json)
        KBPopover.close()
      },
      error => {
        updateAreaAction.failure(error)
      }
    )
  },

  changeInvoiceContent(id) {
    const {
      fields: { orientation }
    } = this.props
    orientation.onChange(id)
    this.setState({
      selectedContent: id
    })
  },

  lockSetting(status) {
    this.setState({
      lockChecked: status
    })
  },

  wallSetting(status) {
    this.setState({
      wallChecked: status
    })
  },

  render() {
    const {
      fields: {
        floor,
        capacity,
        desk_serial_numbers,
        size,
        listing_price,
        non_entering_listing_price,
        floor_ids,
        device_ids,
        management_only,
        max_meanwhile_number
      },
      error,
      submitting,
      handleSubmit,
      areaState,
      values,
      loc_id,
      type,
      areaType
    } = this.props

    const { placeState, selectedContent, lockChecked, wallChecked } = this.state
    const hint = ADD_AREA
    const orientationArray = [
      { name: '东', id: 'east' },
      { name: '西', id: 'west' },
      { name: '南', id: 'south' },
      { name: '北', id: 'north' }
    ]

    const lockArray = [
      { title: '有', value: true },
      { title: '无', value: false }
    ]

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    const oneClass = {
      className: 'kb-form-group kb-form-210 kb-form-alone'
    }

    const reservationPrivileges = RESERVATION_PRIVILEGES

    return (
      <form ref="areaForm" onSubmit={handleSubmit(this.AreaForm)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">批量修改{AREA_TYPE[areaType]}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBUserInput
              title="所属区域"
              className="kb-form-group kb-form-c18 kb-form-alone"
              placeholder={`选择${AREA_TYPE[areaType]}所属区域`}
              field={floor_ids}
              multi={true}
              users={this.props.floors}
              selectedUsers={this.props.selectedFloors}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              type="number"
              title="容纳人数"
              field={capacity}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              inputClass="input-area"
              type="text"
              title="面积"
              field={size}
            />
          </div>
          {areaType == 'room' ? (
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-form-c18 kb-form-alone"
                type="text"
                title="楼层"
                field={floor}
              />
            </div>
          ) : null}
          <div className="kb-row">
            <div className={oneClass.className}>
              <label style={{ width: '13%' }}>朝向</label>
              <div>
                <div className="kb-form-group r1_radios">
                  {orientationArray.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="kb-form-group r1_radio"
                        onClick={this.changeInvoiceContent.bind(null, data.id)}
                      >
                        <div
                          className={`radio-btn ${
                            selectedContent == data.id ? 'radio-btn-active' : ''
                          }`}
                        ></div>
                        <label className="label">{data.name}</label>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="kb-row">
            <div
              style={{ color: '#7F8FA4', fontSize: '11px', marginBottom: 5 }}
            >
              硬件设备
            </div>
            <div className="kb-row kb-form-66">
              <div className="f-left">
                <KBCheckBox
                  checked={lockChecked}
                  callback={this.lockSetting}
                  style={{ display: 'inline-block', marginRight: 15 }}
                />
                <span style={{ verticalAlign: 'middle', color: '#354052' }}>
                  有锁
                </span>
              </div>
              <div className="f-right">
                <KBCheckBox
                  checked={wallChecked}
                  callback={this.wallSetting}
                  style={{ display: 'inline-block', marginRight: 15 }}
                />
                <span style={{ verticalAlign: 'middle', color: '#354052' }}>
                  靠墙
                </span>
              </div>
            </div>
          </div>
          <div className="kb-row kb-form-66">
            <KBInput
              {...halfClass}
              isRequired={false}
              inputClass="input-dollar"
              type="text"
              title="价格"
              field={listing_price}
            />
            <KBInput
              className="kb-form-group kb-div-c48"
              titleClass="label-rt"
              type="text"
              inputClass="input-dollar"
              field={non_entering_listing_price}
              title="非入驻价格"
            />
          </div>
          {areaType == 'room' ? (
            <div className="kb-row">
              <KBUserInput
                title="设备"
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={device_ids}
                placeholder="选择设备"
                multi={true}
                users={this.props.devices}
              />
            </div>
          ) : null}
          {areaType == 'room' ? (
            <div className="kb-row kb-form-66">
              <KBSelect
                className="kb-form-group kb-div-c39"
                title="预订权限"
                field={management_only}
                arrayData={reservationPrivileges}
              />
              <KBInput
                className="kb-form-group kb-form-66"
                type="number"
                titleClass="label-rt"
                title="最大同时预订数"
                field={max_meanwhile_number}
              />
            </div>
          ) : null}
          <div className="kb-row m-top fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

BatchUpdateAreasForm.propTypes = {
  onAreaCreated: PropTypes.func,
  areaEdited: PropTypes.func,
  area: PropTypes.object
}

function mapStateToProps(state, props) {
  const { area } = props
  const { loc_id } = props
  const location_id = loc_id || (area && area.location_id)
  const areas = selectors.getAreasOfLocation(state, location_id) || []
  const floors =
    areas.filter(a => {
      return a.area_type === 'floor'
    }) || []
  let selectedFloors = (area && area.floors) || []

  const devices = selectors.getDevices(state)

  return {
    floors,
    selectedFloors,
    devices,
    initialValues: area
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createAreaAction: bindActionCreators(areaActions.create, dispatch),
    updateAreaAction: bindActionCreators(areaActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'areasForm',
  fields: [
    'orientation',
    'size',
    'floor',
    'device_ids',
    'capacity',
    'listing_price',
    'non_entering_listing_price',
    'floor_ids',
    'device_ids',
    'management_only',
    'max_meanwhile_number'
  ],
  validate: validate,
  touchOnBlur: false
}

BatchUpdateAreasForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(BatchUpdateAreasForm)

export default BatchUpdateAreasForm
