import React from 'react'
import { bindActionCreators } from 'redux'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { KBCheckBox, KBButton } from 'components'
import { PAYMENT_SETTING, PAYMENT_TYPE_NEW_OBJ } from 'app/constants'
import _ from 'lodash'
import { apiCallFailure } from 'app/actions'

var PaymentSetting = React.createClass({
  getInitialState() {
    return {
      paymentSettingsObj: this.props.paymentSettings
    }
  },

  componentWillReceiveProps(nextProps) {
    const { paymentSettings } = this.props
    if (!_.isEqual(paymentSettings, nextProps.paymentSettings)) {
      this.setState({
        paymentSettingsObj: nextProps.paymentSettings
      })
    }
  },

  updateSetting(value, data) {
    const { paymentSettingsObj } = this.state
    let paymentSettingsObjClone = _.clone(paymentSettingsObj)
    let src = data.srcKey

    _.set(paymentSettingsObjClone, src, value)
    this.setState({
      paymentSettingsObj: paymentSettingsObjClone
    })
  },

  submitSetting() {
    const { callback, FailureAction } = this.props
    const { paymentSettingsObj } = this.state
    const user = paymentSettingsObj.reservation.user
    let userKeys = Object.keys(user)
    let count = 0
    userKeys.map(key => {
      if (user[key] && user[key] == true) {
        count++
      }
    })
    if (count > 0) {
      return callback && callback(paymentSettingsObj)
    } else {
      FailureAction({
        status: 'error',
        message:
          '请至少勾选一个会议室支付方式，以保证开通服务后用户可以正常支付并使用。'
      })
    }
  },

  render() {
    const { paymentSettingsObj } = this.state
    const psKeys = Object.keys(paymentSettingsObj)
    return (
      <div style={{ padding: '20px 30px' }}>
        <table className="content-table">
          <thead>
            <th>支付种类</th>
            <th>公司支付</th>
            <th>个人支付</th>
          </thead>
          <tbody>
            {psKeys &&
              psKeys.map((pkey, index) => {
                const orgObj =
                  paymentSettingsObj &&
                  paymentSettingsObj[pkey] &&
                  paymentSettingsObj[pkey].org
                const userObj =
                  paymentSettingsObj &&
                  paymentSettingsObj[pkey] &&
                  paymentSettingsObj[pkey].user
                const orgKeys = orgObj && Object.keys(orgObj)
                const userKeys = userObj && Object.keys(userObj)

                return (
                  <tr key={`ps_td_${index}`}>
                    <td>{PAYMENT_TYPE_NEW_OBJ[pkey]}</td>
                    <td>
                      {orgKeys &&
                        orgKeys.map((okey, index) => {
                          if (pkey === 'point_plan' && okey === 'point') {
                            return null
                          }
                          return (
                            <div
                              key={`org_key_${index}`}
                              style={{ marginBottom: 10 }}
                            >
                              <KBCheckBox
                                checked={orgObj[okey]}
                                callback={this.updateSetting}
                                data={{ srcKey: `${pkey}.org.${okey}` }}
                                style={{ display: 'inline-block' }}
                              />
                              <span
                                style={{
                                  marginLeft: 5,
                                  fontSize: 12,
                                  color: orgObj[okey] ? '#7F8FA4' : '#D8DDE3'
                                }}
                              >
                                {PAYMENT_SETTING[okey]}
                              </span>
                            </div>
                          )
                        })}
                    </td>
                    <td>
                      {orgKeys &&
                        userKeys.map((ukey, index) => {
                          if (
                            (pkey === 'point_plan' && ukey === 'point') ||
                            ukey === 'invoice'
                          ) {
                            return null
                          }
                          return (
                            <div
                              key={`user_key_${index}`}
                              style={{ marginBottom: 10 }}
                            >
                              <KBCheckBox
                                checked={userObj[ukey]}
                                callback={this.updateSetting}
                                data={{ srcKey: `${pkey}.user.${ukey}` }}
                                style={{ display: 'inline-block' }}
                              />
                              <span
                                style={{
                                  marginLeft: 5,
                                  fontSize: 12,
                                  color: userObj[ukey] ? '#7F8FA4' : '#D8DDE3'
                                }}
                              >
                                {PAYMENT_SETTING[ukey]}
                              </span>
                            </div>
                          )
                        })}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        <div className="m-top">
          <KBButton className="certain-btn" onClick={this.submitSetting}>
            保存
          </KBButton>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    FailureAction: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSetting)
