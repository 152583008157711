import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'
import Source from './Source'
import _ from 'lodash'

/**
 * 这是一个穿梭框组件
 * @description
 * 参考 https://ant.design/components/transfer-cn/
 * 数据格式:
 * Tcheck 的起名：是为了不和原数据的check冲突，如果原数据就叫Tcheck，请改数据名
 * var source1 = [
   {
     id        : 1,
     name      : '公司1',
     childrens : [
       {
         id   : 11,
         name : '大明'
       },
       {
         id   : 12,
         name : '明1'
       }
     ]
   },
   {
     id        : 2,
     name      : '公司2',
     childrens : [
       {
         id   : 22,
         name : '小明1'
       }
     ]
   },
   {
     id   : 3,
     name : '公司3'
   }
 ]

 var target1 = [
   {
     id   : 2,
     name : 'ccc2'
   },
   {
     id   : 3,
     name : 'ddd3'
   }
 ]
 */
var KBTransfer = React.createClass({
  displayName: 'KBTransfer',
  getInitialState(sourceData, targetData) {
    const { source, target } = this.props
    return {
      source: sourceData || source,
      target: targetData || target,
      editSourceState: false,
      editTargetState: false
    }
  },
  getDefaultProps() {
    return {
      multi: true,
      source: [],
      isSearch: true,
      isChildrenLoad: false,
      isHead: true,
      isMiddle: false,
      target: [],
      sourceTitle: 'Source',
      targetTitle: 'Target'
    }
  },
  componentWillReceiveProps(nextProps) {
    const { source, target } = nextProps
    const oldSource = this.props.source
    const oldTarget = this.props.target
    if (!_.isEqual(oldSource, source) || !_.isEqual(oldTarget, target)) {
      const newSource = this.mountData(source)
      const newTarget = this.mountData(target)
      this.setState(this.getInitialState(newSource, newTarget))
    }
  },
  childContextTypes: {
    setEditState: PropTypes.func,
    getExpandChilds: PropTypes.func,
    filterNoChildrensData: PropTypes.func,
    setDataOfIndex: PropTypes.func,
    setSource: PropTypes.func,
    isHead: PropTypes.bool,
    showSE: PropTypes.func,
    mountData: PropTypes.func,
    multi: PropTypes.bool,
    isSearch: PropTypes.bool,
    isMiddle: PropTypes.bool,
    isChildrenLoad: PropTypes.bool
  },
  getChildContext() {
    return {
      setEditState: this.setEditState,
      mountData: this.mountData,
      showSE: this.showSE,
      setSource: this.setSource,
      filterNoChildrensData: this.filterNoChildrensData,
      isHead: this.props.isHead,
      isMiddle: this.props.isMiddle,
      multi: this.props.multi,
      isSearch: this.props.isSearch,
      setDataOfIndex: this.setDataOfIndex,
      isChildrenLoad: this.props.isChildrenLoad,
      getExpandChilds: this.props.getExpandChilds
    }
  },
  getData() {
    return {
      source: this.state.source,
      target: this.state.target
    }
  },
  // 具体的数据切换function
  filterTarget(source, target) {
    const { multi } = this.props
    var filterItems = []
    var newItems = []
    var newSource = _.cloneDeep(source)
    var newTarget = _.cloneDeep(target)

    // 如果不是多选数据
    if (!multi) {
      newSource.forEach(json => {
        if (json.Tchecked) {
          filterItems.push({ ...json, Tchecked: false })
          return
        }
        newItems.push({ ...json })
      })

      return {
        filterItems,
        newItems,
        newTarget: filterItems.concat(newTarget)
      }
    }

    // 如果是多选数据
    newSource.forEach(json => {
      const maxLength = (json.childrens && json.childrens.length) || 0
      let fitems =
        (json.childrens && json.childrens.filter(json => json.Tchecked)) || []
      let remainItems =
        (json.childrens && json.childrens.filter(json => !json.Tchecked)) || []
      fitems = fitems.map(json => {
        json.Tchecked = false
        return json
      })
      var newFItems = _.cloneDeep(json)
      var newRemainItems = _.cloneDeep(json)

      let flength = fitems.length
      // 当前选中的数据 并且 选中的数据不是整个数据的数量
      if (flength && maxLength != flength) {
        newFItems.childrens = fitems
        newFItems.Tchecked = false
        newRemainItems.childrens = remainItems
        newRemainItems.Tchecked = false
        newItems.push(newRemainItems)
        filterItems.push(newFItems)
      } else if (flength && maxLength == flength) {
        newFItems.childrens = fitems
        newFItems.Tchecked = false
        filterItems.push(newFItems)
      } else {
        newRemainItems.childrens = remainItems
        newRemainItems.Tchecked = false
        newItems.push(newRemainItems)
      }
    })

    filterItems.forEach(t => {
      var findTarget = newTarget.find(json => json.id == t.id)
      if (findTarget) {
        findTarget.childrens = t.childrens.concat(findTarget.childrens)
        return
      }
      newTarget.unshift(t)
    })

    return {
      filterItems,
      newTarget,
      newItems
    }
  },
  // 控制< > 按钮数据切换
  setSource(type = 'left') {
    const { source, target } = this.refs
    const { multi, targetDataOnChange } = this.props
    var sourceData = source.getData()
    var targetData = target.getData()
    var itemsFilter = []
    var newSourceData = []
    var newTargetData = []

    if (type == 'left') {
      const { filterItems, newItems, newTarget } = this.filterTarget(
        targetData,
        sourceData
      )
      newSourceData = newTarget
      newTargetData = newItems
      itemsFilter = filterItems
    } else {
      const { filterItems, newItems, newTarget } = this.filterTarget(
        sourceData,
        targetData
      )
      newSourceData = newItems
      newTargetData = newTarget
      itemsFilter = filterItems
    }

    if (!itemsFilter.length) {
      return
    }

    targetDataOnChange && targetDataOnChange(newTargetData)
    var params = {
      source: newSourceData,
      target: newTargetData
    }

    if (type == 'left') {
      params.editTargetState = false
    } else {
      params.editSourceState = false
    }

    this.setState(params)
  },
  findDataOfIndex(type = 'source', id) {
    const { source, target } = this.state
    var newData = source
    if (type == 'target') {
      newData = target
    }
    newData = _.cloneDeep(newData)
    var dataIndex = newData.findIndex(json => json.id == id)
    if (dataIndex == -1) {
      return
    }

    return {
      newData,
      dataIndex
    }
  },
  setDataOfIndex(type = 'source', id, childrens = []) {
    var { newData, dataIndex } = this.findDataOfIndex(type, id)

    if (type === 'source') {
      childrens = this.filterTargetChildrensOfSource(id, childrens)
    }

    newData[dataIndex].expandState = 'expand'
    newData[dataIndex].isData = true
    newData[dataIndex].childrens = this.mountChildData(childrens, false)

    this.setState({
      [type]: newData
    })
  },
  filterTargetChildrensOfSource(id, childrens) {
    var { target } = this.state

    const targetData = target.find(t => t.id === id)
    if (!targetData || !targetData.childrens) {
      return childrens
    }
    return _.pullAllBy(childrens, targetData.childrens, 'id')
  },
  showSE(type = 'source', id, state) {
    var { newData, dataIndex } = this.findDataOfIndex(type, id)
    newData[dataIndex].expandState = state
    this.setState({
      [type]: newData
    })
  },
  // 过滤childrens为空的数据
  filterNoChildrensData(data) {
    const { isChildrenLoad } = this.props
    return isChildrenLoad
      ? data
      : data.filter(json => json.childrens && json.childrens.length)
  },
  // 修改子级数据 checked状态
  mountChildData(data = [], checked) {
    return data.length && data.map(child => {
      child.Tchecked = checked
      return child
    })
  },
  // 全数据更换数据的checkd状态
  multiMountData(data = [], checked) {
    var newData = this.filterNoChildrensData(data)
    newData = newData.map(json => {
      json.childrens = this.mountChildData(json.childrens, checked)
      json.Tchecked = checked
      return json
    })
    return newData
  },
  // 数据初始化改变数据的Tcheck情况，并且判断是否多级数据还是单级数据
  mountData(data = [], checked) {
    const { multi } = this.props
    var newData = data
    if (multi) {
      return this.multiMountData(newData, checked)
    } else {
      return data.map(json => {
        json.Tchecked = checked
        return json
      })
    }
  },
  // 控制Edit状态的显示 变亮< >
  setEditState(type, value) {
    if (type == 'source') {
      this.setState({
        editSourceState: value
      })
      return
    }
    this.setState({
      editTargetState: value
    })
  },
  renderMiddle() {
    const { isMiddle } = this.props
    const { editSourceState, editTargetState } = this.state

    if (!isMiddle) {
      return ''
    }

    return (
      <div className="kb-transfer-convert">
        <span
          onClick={this.setSource.bind(null, 'left')}
          className={editTargetState ? 'active' : ''}
        >
          &lt;
        </span>
        <span
          onClick={this.setSource.bind(null, 'right')}
          className={editSourceState ? 'active' : ''}
        >
          &gt;
        </span>
      </div>
    )
  },
  render() {
    const { source, target, editSourceState, editTargetState } = this.state
    const { sourceTitle, targetTitle, isHead, isGroupAdd } = this.props
    return (
      <div className="kb-transfer-box">
        <Source
          data={source}
          ref="source"
          className="kb-transfer-source"
          type="source"
          title={sourceTitle}
          isEdit={editSourceState}
          isGroupAdd={isGroupAdd}
        />
        {this.renderMiddle()}
        <div
          className="cut-off-rule"
          style={
            isHead
              ? { height: 'calc(100% - 80px)', top: 60 }
              : { height: 'calc(100% - 40px)', top: 20 }
          }
        ></div>
        <Source
          data={target}
          ref="target"
          className="kb-transfer-target"
          type="target"
          title={targetTitle}
          isEdit={editTargetState}
          isGroupAdd={isGroupAdd}
        />
      </div>
    )
  }
})

KBTransfer.propTypes = {
  // 源数据
  source: PropTypes.array,
  // 目标数据
  target: PropTypes.array,
  getExpandChilds: PropTypes.func,
  // 是否子集是加载数据 必须在multi=true的情况下
  isChildrenLoad: PropTypes.bool,
  // 是否显示搜索框
  isSearch: PropTypes.bool,
  // 是否多选/单选
  multi: PropTypes.bool,
  isMiddle: PropTypes.bool,
  isHead: PropTypes.bool,
  // sourceTitle
  sourceTitle: PropTypes.str,
  // targetTitle
  targetTitle: PropTypes.str,
  targetDataOnChange: PropTypes.func,
  getAllAreasOfCurrentLoc: PropTypes.func
}

export default KBTransfer
