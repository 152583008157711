import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { invoicingPoolsAction } from 'app/actions'
import {
  KBFormError,
  KBButton,
  KBDateInput,
  KBPopover,
  KBForm,
  KBInput,
  KBTextarea
} from 'components'
import moment from 'moment'
import { bindActionCreators } from '../../../node_modules/redux'

var InvoicingPoolForm = React.createClass({
  submitInvoicingPoolForm(model) {
    const {
      location_id,
      isUpdate,
      current_pool,
      createInvoicingPoolsAction,
      updateInvoicingPoolsAction
    } = this.props

    if (location_id) {
      model.location_id = location_id
    } else {
      delete model.location_id
    }

    if (isUpdate) {
      return api.putInvoicingPool(current_pool.id, model).then(
        json => {
          updateInvoicingPoolsAction.success(json)
          KBPopover.close()
        },
        errors => {
          updateInvoicingPoolsAction.failure(errors)
        }
      )
    }

    return api.createInvoicingPool(model).then(
      json => {
        createInvoicingPoolsAction.success(json)
        KBPopover.close()
      },
      errors => {
        createInvoicingPoolsAction.failure(errors)
      }
    )
  },

  render() {
    const {
      fields: {
        name,
        description,
        first_serial,
        purchase_date,
        total_amount,
        remain_amount
      },
      error,
      submitting,
      handleSubmit,
      isUpdate
    } = this.props

    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.submitInvoicingPoolForm)}
        fields={this.props.fields}
      >
        <KBFormError error={error} />
        <header className="kb-form-header">
          <h2 className="header-title">{isUpdate ? '修改' : '添加'}发票库</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container-m">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="发票库名称"
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={description}
              title="发票库说明"
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={first_serial}
              title="发票初始号码"
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBDateInput
              field={purchase_date}
              title="购票日期"
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBInput
              className="kb-form-group kb-form-c39"
              field={total_amount}
              type="number"
              title="购票数量"
              isRequired={true}
            />
            <KBInput
              className="kb-form-group kb-form-c39"
              field={remain_amount}
              type="number"
              title="剩余数量"
            />
          </div>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const { isUpdate, current_pool } = props
  let initialValues = isUpdate
    ? { ...current_pool }
    : { purchase_date: moment().format('YYYY-MM-DD') }

  return {
    initialValues
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createInvoicingPoolsAction: bindActionCreators(
      invoicingPoolsAction.create,
      dispatch
    ),
    updateInvoicingPoolsAction: bindActionCreators(
      invoicingPoolsAction.update,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入发票库名称'
  }

  if (valid.required(values.first_serial)) {
    errors.first_serial = '请输入发票初始号码'
  }

  if (valid.required(values.purchase_date)) {
    errors.purchase_date = '请输入购票日期'
  }

  if (valid.required(values.total_amount)) {
    errors.total_amount = '请输入购票数量'
  }

  return errors
}

const formConfig = {
  form: 'InvoicingPoolForm',
  fields: [
    'name',
    'description',
    'first_serial',
    'purchase_date',
    'total_amount',
    'remain_amount'
  ],
  validate: validate,
  touchOnBlur: false
}

InvoicingPoolForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoicingPoolForm)

export default InvoicingPoolForm
