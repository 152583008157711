import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import _ from 'lodash'
import { KBAvatar } from 'components'
import { util } from 'utils/kbUtil'

var Select = require('react-select')
var KBAsyncInputList = React.createClass({
  getInitialState() {
    const { defaultValue } = this.props
    return {
      dropShow: false,
      str: defaultValue || '',
      data: []
    }
  },
  getDefaultProps() {
    return {
      showImg: true,
      isTextOver: true
    }
  },
  selectDownList(data) {
    const { param } = this.props
    this.props.field.onChange(data.id)
    this.setState({ str: data[param.name] || data.name })
    this.hideDrop()
  },

  showDrop() {
    this.setState({ dropShow: true })
  },

  hideDrop() {
    this.setState({ dropShow: false })
  },

  inputFocus() {
    this.showDrop()
  },

  inputBlur() {
    util.setTimeout('dropDownInput', this.hideDrop, 300)
  },

  clearInput() {
    this.setState({ str: '' })
  },

  changeUser(e) {
    let str = e.target.value
    const { selectCallBack } = this.props

    if (!str || str == ' ') {
      this.setState({ str: '', data: [] })
      selectCallBack && selectCallBack()
      return
    }

    this.props.getData(str, data => {
      this.setState({ data: data })
    })

    this.setState({ str })
  },

  render() {
    const { param, style, showImg, isTextOver } = this.props
    const { dropShow, str, data } = this.state
    const {
      field,
      placeholder,
      type,
      title,
      className,
      titleClass,
      inputClass,
      label,
      isRequired,
      isRequiredR,
      hint,
      disabled
    } = this.props

    return (
      <div className={'add-staff-component ' + className}>
        {title && (
          <label className={titleClass}>
            {isRequiredR ? <span className="must-fillR">*</span> : ''}
            {title}
            {isRequired ? <span className="must-fill">*</span> : ''}
          </label>
        )}
        <div style={{ position: 'relative' }}>
          <input
            className={classNames(
              inputClass,
              field.touched && field.error ? 'kb-input-error' : 'kb-input'
            )}
            style={{ backgroundImage: 'none', paddingLeft: 10 }}
            type="text"
            placeholder={placeholder}
            value={str}
            onChange={this.changeUser}
            onBlur={this.inputBlur}
            onFocus={this.inputFocus}
          />
          {field.touched && field.error ? (
            <p className="lr-error">{field.error}</p>
          ) : (
            <p
              style={{
                color: '#c8c8c8',
                fontSize: 12,
                padding: '2px 0 0 11px'
              }}
            >
              {hint}
            </p>
          )}
          {dropShow &&
            (this.props.children || (
              <ul className="add-staff-list">
                {data.map((json, index) => (
                  <li
                    onClick={this.selectDownList.bind(null, json)}
                    key={index}
                  >
                    <a href="#">
                      {showImg ? (
                        <div className="add-staff-portrait">
                          <KBAvatar
                            user={(param.user && json[param.user]) || json}
                            size={30}
                            style={{
                              width: '30px',
                              height: '30px',
                              lineHeight: '33px'
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      <span
                        className={
                          isTextOver ? `add-staff-name team-search` : ''
                        }
                      >
                        {json[param.name] || json.name}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            ))}
        </div>
      </div>
    )
  }
})

KBAsyncInputList.propTypes = {
  getData: PropTypes.func.isRequired,
  param: PropTypes.object,
  showImg: PropTypes.bool
}

export default KBAsyncInputList
