import _ from 'lodash'

// Returns all roles related to location_id. If location_id is null, then get space roles
export const getRoles = (user, location_id = null) => {
  let roles = []
  if (user && user.space_roles) {
    if (location_id) {
      roles = user.space_roles
        .filter(role => role.location_id === parseInt(location_id))
        .map(role => role.role)
    } else {
      roles = user.space_roles.map(role => role.role)
    }
  }
  return roles
}

export const getLSRoles = (user, location_id = null) => {
  let roles = []
  if (user && user.space_roles) {
    if (location_id) {
      roles = user.space_roles
        .filter(role => role.location_id === parseInt(location_id))
        .map(role => role.role)
    } else {
      roles = user.space_roles.map(role => {
        if (role.role && role.location_id) {
          return role
        }
        return role.role
      })
    }
  }
  return roles
}

export const canAdminSpace = user => {
  return canAccessSpaceRoles(user, ['admin'])
}

// 是否有权限
export const canAccessIsRoles = (user, required_roles = []) => {
  let roles = getLSRoles(user, null)
  // let newRoles = roles.map(role => {
  //   if (typeof role == 'string') {
  //     return role
  //   }
  //   return role.role
  // })
  // return _.intersection(required_roles, newRoles).length
  return roles
}

function checkRoleType(newRoles, role) {
  if (role.role && role.role == 'finance' && role.location_id != null) {
    newRoles.location_finance = 'location_finance'
  } else if (role.role && role.role == 'repair' && role.location_id != null) {
    newRoles.location_repair = 'location_repair'
  } else if (role.role && role.role == 'contract' && role.location_id != null) {
    newRoles.location_contract = 'location_contract'
  } else {
    newRoles[role.role] = role.role
  }

  return newRoles
}

// 空间权限
export const canAccessSpaceRoles = (user, required_roles = []) => {
  let roles = getLSRoles(user, null)
  _.pullAll(roles, [null])
  let newRoles = {}
  let spaceRoles = roles.map(role => {
    if (typeof role == 'string') {
      newRoles[role] = role
    } else {
      newRoles = checkRoleType(newRoles, role)
    }
  })
  spaceRoles = Object.values(newRoles)
  return _.intersection(required_roles, spaceRoles).length > 0
}

// // 分店权限
// export const canAccessSpaceRoles = (user, required_roles = []) => {
//   let roles = getRoles(user, null)
//
//   let locationRoles = roles.filter(role => typeof role != 'string')
//   return _.intersection(required_roles, locationRoles.map(role => role.role)).length > 0
// }

export const canViewSpaceFinance = user => {
  return canAccessSpaceRoles(user, [
    'admin',
    'finance',
    'contract',
    'location_contract',
    'invoice_check',
    'invoice_confirm',
    'invoice_check_notify'
  ])
}

export const canAccessSpaceFinance = user => {
  return canAccessSpaceRoles(user, ['admin', 'finance'])
}

export const canAccessSpaceRepair = user => {
  return canAccessSpaceRoles(user, ['admin', 'repair', 'task_feedback'])
}

export const canAccessSpaceTaskFeedback = user => {
  return canAccessSpaceRoles(user, ['task_feedback'])
}

export const canAccessSpaceCRM = user => {
  return canAccessSpaceRoles(user, ['admin', 'crm', 'crm_admin'])
}

export const canAccessSpaceFaq = user => {
  return canAccessSpaceRoles(user, ['admin', 'faq'])
}

export const canAccessSpaceAPP = user => {
  return canAccessSpaceRoles(user, ['admin', 'app', 'faq'])
}

export const canAccessSpaceStatistic = user => {
  return canAccessSpaceRoles(user, ['admin', 'statistic', 'location_manager'])
}

export const canAccessSpaceEnterprise = user => {
  return canAccessSpaceRoles(user, ['admin', 'enterprise'])
}

export const canAccessSpaceHr = user => {
  return canAccessSpaceRoles(user, ['admin', 'hr'])
}

export const canAccessSpaceContract = user => {
  return canAccessSpaceRoles(user, ['admin', 'contract'])
}

export const canAccessSpaceShop = user => {
  return canAccessSpaceRoles(user, ['admin', 'shop_manager', 'shop'])
}

export const canAccessSpaceContractNotAdmin = user => {
  if (canAdminSpace(user)) {
    return false
  }

  return canAccessSpaceRoles(user, ['contract'])
}

// 积分充值
export const canRechargePoint = user => {
  if (canAdminSpace(user)) {
    return true
  }

  return canAccessSpaceRoles(user, ['point_recharge'])
}

export const spaceRolesWhoCanAccessAllLocations = user => {
  return canAccessSpaceRoles(user, ['admin', 'contract'])
}

export const canSpaceAdmin = (user, required_roles = []) => {
  let isSpaceAdmin = required_roles.find(json => json == 'spaces')

  if (!isSpaceAdmin) {
    return true
  }

  if (!user.spaces) {
    return true
  }

  let spaces = Object.values(user.spaces)

  if (!spaces.length) {
    return false
  }

  let telephone = spaces[0].telephone

  if (
    telephone == '13581898658' ||
    telephone == '18311465758' ||
    telephone == '91111111111' ||
    telephone == '99311465759' ||
    telephone == '18311465759' ||
    telephone == '98311465759'
  ) {
    return true
  }

  return false
}

// 获取当前空间能够设置的权限信息
export const getSettingssOfCurrentSpace = (settings, user, type) => {
  if (!settings) {
    return []
  }

  const {
    admin_role_setting,
    app_role_setting,
    contract_role_setting,
    crm_admin_role_setting,
    crm_role_setting,
    engineer_role_setting,
    enterprise_role_setting,
    events_role_setting,
    faq_role_setting,
    finance_role_setting,
    hr_role_setting,
    location_manager_role_setting,
    location_operator_role_setting,
    manager_role_setting,
    news_role_setting,
    observer_role_setting,
    operator_role_setting,
    repair_role_setting,
    shop_manager_role_setting,
    statistic_role_setting,
    task_feedback_role_setting,
    abnormal_contract_role_setting,
    point_recharge_role_setting,
    visitor_role_setting
  } = settings
  let settingList = [
    {
      id: 'admin',
      name: '空间管理员',
    },
    {
      id: 'crm',
      name: 'CRM权限',
    },
    {
      id: 'crm_admin',
      name: 'CRM管理者权限'
    },
    {
      id: 'repair',
      name: '报修权限'
    },
    {
      id: 'task_feedback',
      name: '意见反馈负责人'
    },
    {
      id: 'finance',
      name: '财务权限'
    },
    {
      id: 'faq',
      name: '内容管理权限',
    },
    {
      id: 'hr',
      name: '员工管理权限',
    },
    {
      id: 'statistic',
      name: '统计查看权限',
    },
    {
      id: 'enterprise',
      name: '服务商管理权限',
    },
    {
      id: 'app',
      name: '应用管理权限',
    },
    {
      id: 'shop_manager',
      name: '商城管理权限',
    },
    {
      id: 'contract',
      name: '合同审批人',
    },
    {
      id: 'point_recharge',
      name: '积分充值权限',
    },
    {
      id: 'abnormal_contract',
      name: '低价合同审批人',
    },
    {
      id: 'visitor',
      name: '访客管理员',
    },
    {
      id: 'helpdesk',
      name: '账号权限管理员'
    }
  ]

  return settingList
}

// 获取当前用户所拥有的空间权限
export const getCurrentUserSettingsOfCurrentSpace = (settings, user, isAll) => {
  if (!settings) {
    return []
  }

  const { id } = user
  let settingList = []

  settings.forEach(s => {
    if (!isAll && s.location_id) {
      return
    }

    switch (s.role) {
      case 'admin':
        settingList.push({
          id: s.role,
          name: '空间管理员'
        })
        break
      case 'app':
        settingList.push({
          id: s.role,
          name: '应用管理权限'
        })
        break
      case 'contract':
        settingList.push({
          id: s.role,
          name: '合同审批人'
        })
        break
      case 'abnormal_contract':
        settingList.push({
          id: s.role,
          name: '低价合同审批人'
        })
        break
      case 'enterprise':
        settingList.push({
          id: s.role,
          name: '服务商管理权限'
        })
        break
      case 'crm':
        settingList.push({
          id: s.role,
          name: 'CRM权限'
        })
        break
      case 'crm_admin':
        settingList.push({
          id: s.role,
          name: 'CRM管理者权限'
        })
        break
      case 'engineer':
        // settingList.push({
        //   id   : s.role,
        //   name : '工程维修权限'
        // })
        break
      case 'faq':
        settingList.push({
          id: s.role,
          name: '内容管理权限'
        })
        break
      case 'finance':
        settingList.push({
          id: s.role,
          name: '财务权限'
        })
        break
      case 'hr':
        settingList.push({
          id: s.role,
          name: '员工管理权限'
        })
        break
      case 'observer':
        settingList.push({
          id: s.role,
          name: '观察者权限'
        })
        break
      case 'repair':
        settingList.push({
          id: s.role,
          name: '报修权限'
        })
        break
      case 'task_feedback':
        settingList.push({
          id: s.role,
          name: '意见反馈负责人'
        })
        break
      case 'shop_manager':
        settingList.push({
          id: s.role,
          name: '商城管理权限'
        })
        break
      case 'statistic':
        settingList.push({
          id: s.role,
          name: '统计查看权限'
        })
        break
      case 'point_recharge':
        settingList.push({
          id: s.role,
          name: '积分充值权限'
        })
        break
      case 'visitor':
        settingList.push({
          id: s.role,
          name: '访客管理员'
        })
        break
      case 'helpdesk':
        settingList.push({
          id: s.role,
          name: '账号权限管理员'
        })
        break
      default:
        break
    }
  })

  return settingList
}

export const isSpaceAdmin = user => {
  if (!user) {
    return false
  }
  let roles = user.roles || user.space_roles
  return roles && roles.find(j => j.role === 'admin')
}

//分店角色权限比较
export const canAccessLocationRoles = (
  user,
  location_id,
  required_roles = []
) => {
  let roles = getRoles(user, location_id)
  let allRoles = getRoles(user, null)
  if (
    // allRoles.indexOf('location_group_manager') > -1 ||
    allRoles.indexOf('location_group_operator') > -1
  ) {
    return true
  }
  return _.intersection(required_roles, roles).length > 0
}

//分店负责人
export const canAdminLocation = (user, location_id) => {
  if (canAdminSpace(user)) {
    return true
  }
  return canAccessLocationRoles(user, location_id, ['location_manager'])
}

export const onlyLocationManger = (user, location_id) => {
  const { space_roles = [] } = user
  if(space_roles&&space_roles.length===1&&space_roles[0].role==='location_manager'&&space_roles[0].location_id==location_id){
    return true
  }else{
    return false
  }
}
// 项目负责人
export const canAdminLocationGroup = user => {
  let allRoles = getRoles(user, null)
  if (allRoles.indexOf('location_group_operator') > -1) {
    return true
  } else {
    return false
  }
}

export const onlyHasLocationGroupOperator = (user, location_id) => {
  const { space_roles = [], locations = [] } = user
  const currentLocation = locations.find(location=> location.id==location_id)||{}
  const { location_group:{ id }={} } = currentLocation
  if(space_roles&&space_roles.length===1&&id&&id==space_roles[0].location_group_id){
    return true
  }else{
    return false
  }
}

// 分店运营人员
export const canAccessLocationOperator = (user, location_id) => {
  if (canAdminSpace(user)) {
    return true
  }

  return canAccessLocationRoles(user, location_id, ['location_operator'])
}

//分店负责人与分店运营人员
export const canAccessLocation = (user, location_id) => {
  if (canAdminSpace(user)) {
    return true
  }

  return canAccessLocationRoles(user, location_id, [
    'location_manager',
    'location_operator'
  ])
}

//分店可审批合同的人员
export const canAccessLocationContract = (user, location_id) => {
  // if (canAdminSpace(user)) {
  //   return true
  // }

  return canAccessLocationRoles(user, location_id, [
    'location_manager',
    'location_operator',
    'contract'
  ])
}
// 访客管理员
export const canAccessLocationVisitor = (user, location_id) => {
  if (canAdminSpace(user)) {
    return true
  }

  return canAccessLocationRoles(user, location_id, ['visitor'])
}

//分店负责人 管理人员 财务人员 观察人员
export const canAccessLocationOfRoles = (user, location_id) => {
  if (canAdminSpace(user)) {
    return true
  }
  return canAccessLocationRoles(user, location_id, [
    'location_manager',
    'location_operator',
    'finance',
    'observer',
    'repair',
    'sales',
    'contract',
    'abnormal_contract',
    'visitor',
    'location_group_operator',
    'location_group_wechat_manager',
  ])
}

//管理分店财务权限
export const canAccessLocationFinance = (user, location_id) => {
  if (canAccessSpaceFinance(user)) {
    return true
  }
  return canAccessLocationRoles(user, location_id, [
    'location_manager',
    'finance'
  ])
}
//分店管理员 运营 财务
export const canAccessLocationExceptObserver = (user, location_id) => {
  // if (canAccessSpaceFinance(user)) {
  //   return true
  // }
  return canAccessLocationRoles(user, location_id, [
    'location_manager',
    'finance',
    'location_operator'
  ])
}

//分店管理员 财务
export const canAccessLocationManager = (user, location_id) => {
  // if (canAccessSpaceFinance(user)) {
  //   return true
  // }
  return canAccessLocationRoles(user, location_id, [
    'location_manager',
    'finance'
  ])
}

//访问分店财务权限
export const canViewLocationFinance = (user, location_id) => {
  if (canAccessSpaceFinance(user)) {
    return true
  }

  return true
  // canAccessLocationRoles(user, location_id, ['location_manager', 'finance', 'observer'])
}

//分店观察员(只具有'observer'，不具有分店其他任何权限)
export const canAccessLocationObserver = (user, location_id) => {
  let isManager = canAccessLocationRoles(user, location_id, [
    'location_manager',
    'finance',
    'location_operator'
  ])
  let isObserver = location_id
    ? canAccessLocationRoles(user, location_id, ['observer'])
    : false
  let isSpaceAdmin = canAdminSpace(user)
  return !isManager && !isSpaceAdmin && isObserver
}

//分店财务(只具有'finance',不具有分店其他任何权限)
export const canAccessLocationOnlyFinance = (user, location_id) => {
  let isManager = canAccessLocationRoles(user, location_id, [
    'location_manager',
    'observer',
    'location_operator'
  ])
  let isFinance = location_id
    ? canAccessLocationRoles(user, location_id, ['finance'])
    : false
  return !isManager && isFinance
}

//分店报修人员(只具有'repair'，不具有分店其他任何权限)
export const canAccessLocationRepairOnly = (user, location_id) => {
  let isManager = canAccessLocationRoles(user, location_id, [
    'location_manager',
    'location_operator'
  ])
  let isRepair = location_id
    ? canAccessLocationRoles(user, location_id, ['repair'])
    : false
  let isSpaceAdmin = canAdminSpace(user)
  return !isManager && !isSpaceAdmin && isRepair
}

//分店访客管理员(只具有'visitor'，不具有分店其他任何权限)
export const canAccessLocationVisitorOnly = (user, location_id) => {
  let isManager = canAccessLocationRoles(user, location_id, [
    'location_manager',
    'location_operator'
  ])
  let isVisitor = location_id
    ? canAccessLocationRoles(user, location_id, ['visitor'])
    : false
  let isSpaceAdmin = canAdminSpace(user)
  return !isManager && !isSpaceAdmin && isVisitor
}

// 获取users
export const getRoleUserByKey = (roles, key) => {
  let r = roles.filter(r => r.role === key && !r.location_id).slice()
  r = r.map(json => {
    return json.user_id
  })
  return r
}


export const canAdminFinance=(user,location_id)=>{
  return canAccessLocationRoles(user, location_id, [
    'location_group_operator',
    'location_manager',
    'observer',
    'finance',
    'location_finance',
    'location_contract',
    'abnormal_contract',
    'location_operator'
  ])
}

// 账号权限管理员
export const helpDesk=(user)=>{
  return canAccessSpaceRoles(user, [
    'helpdesk'
  ])
}

// 项目活动负责人
export const canAdminLocationActivity = (user, location_id) => {
  let allRoles = getRoles(user, null)
  if (allRoles.indexOf('location_group_wechat_manager') > -1) {
    return true
  } else {
    return false
  }
}

// 项目外部管理人员
export const canAccessLandlord = (user, location_id) => {
  let allRoles = getRoles(user, null)
  if (allRoles.indexOf('landlord') > -1) {
    return true
  } else {
    return false
  }
}

// 是否包含小程序管理员
export const canAdminLocationWeChat = (user, location_id) => {
  const { locations=[], space_roles=[] } = user
  const locationGroup = space_roles.find(roles=>roles.role === 'location_group_wechat_manager')||{}
  const { location_group_id } = locationGroup
  const currentLocation = locations.find(location=>location.id == location_id) || {}
  const { location_group:{ id } = {} }= currentLocation
  if(location_group_id&&id&&id == location_group_id){
    return true
  }else{
    return false
  }
}

// 小程序管理员
export const canAdminLocationGroups = (user, group_id) => {
  const { space_roles } = user
  if(!space_roles||!Array.isArray(space_roles)) {
    return false
  }else{
    const locationGroupAdmin = space_roles.filter(roles=>roles.role === 'location_group_wechat_manager')
    // const {location_group_id} = locationGroupAdmin[0]||{}
    let root = false
    locationGroupAdmin.forEach(roles=>{
      if(roles.location_group_id == group_id) root=true
    })
    return root
    // if(location_group_id&&group_id&&location_group_id==group_id){
    //   return true
    // }else{
    //   return false
    // }
  }
}

// 项目内容管理员
export const isGroupContentManager = (user, id, isGroup, pathname) => {
  const {  space_roles=[], locations } = user
  if(!space_roles.length) {
    return false
  }else{
    if(pathname === '/admin/locations') {
      return space_roles.some(i => i.role === 'location_group_content_manager')
    }
    if(isGroup) {
      return space_roles.some(i => i.location_group_id == id && i.role === 'location_group_content_manager')
    } else {
      // 分店下
      return locations.some(i => i.id == id && i.location_group && space_roles.some(j => j.location_group_id == i.location_group.id && j.role === 'location_group_content_manager'))
    }
  }
}

export const onlyHasWeChatAdmin = (user) => {
  const {  space_roles=[] } = user
  if(space_roles&&space_roles.length===1&&space_roles[0].role==='location_group_wechat_manager'){
    return true
  }else{
    return false
  }
}

// 只有小程序管理员
export const onlyCanAdminLocationOfWeChat = (user={}, location_id) => {
  const { locations=[], space_roles=[] } = user
  let isOnlyWeChatManger = true
  space_roles.forEach(roles=>{
    if(roles.role!=='location_group_wechat_manager') isOnlyWeChatManger = false
  })
  if(!isOnlyWeChatManger) return false
  const locationGroup = space_roles.find(roles=>roles.role === 'location_group_wechat_manager')||{}
  const { location_group_id } = locationGroup
  const currentLocation = locations.find(location=>location.id == location_id) || {}
  const { location_group:{ id } = {} }= currentLocation
  if(location_group_id&&id&&id == location_group_id){
    return true
  }else{
    return false
  }
}

// 账号权限管理员
export const canAdminAccount = (user={}) => {
  const { space_roles=[] } = user
  if(space_roles&&space_roles.find(roles=> roles.role==='helpdesk')){
    return true
  }else{
    return false
  }
}

export const onlyHelpdesk = (user) => {
  const { space_roles=[] } = user
  if(space_roles&&space_roles.length===1&&space_roles[0].role==='helpdesk'){
    return true
  }else{
    return false
  }
}
