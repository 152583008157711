import React, { Component, PropTypes } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { util } from 'utils/kbUtil'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearSuccessState } from 'app/actions'

var KBSuccess = React.createClass({
  getInitialState() {
    return {
      success: this.props.success
    }
  },
  _hide() {
    this.setState({ success: {} })
    this._clearSuccessState()
  },
  _clearSuccessState() {
    this.props.clearSuccessAction()
  },
  componentDidMount() {
    if (!Object.keys(this.props.success).length) {
      return
    }
    util.setTimeout(
      'success',
      () => {
        this._hide()
      },
      5000
    )
  },
  componentWillReceiveProps(nextProps) {
    if (!Object.keys(nextProps.success).length) {
      return
    }
    this.setState({ success: nextProps.success })
    util.setTimeout(
      'success',
      () => {
        this._hide()
      },
      5000
    )
  },
  render() {
    const { title, message } = this.state.success

    let success =
      title || message ? (
        <div className="popup-success">
          <span className="icon">
            <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img-icon-yes@3x.png" />
          </span>
          <div className="msg-container">
            {title ? <span>{title}</span> : null}
            {message ? (
              <span
                className="message"
                style={title ? {} : { fontSize: '16px' }}
              >
                {message}
              </span>
            ) : null}
          </div>
          <a onClick={this._hide}>&times;</a>
        </div>
      ) : null

    return (
      <ReactCSSTransitionGroup
        transitionName="background"
        transitionEnterTimeout={750}
        transitionLeaveTimeout={750}
      >
        {success}
      </ReactCSSTransitionGroup>
    )
  }
})

KBSuccess.propTypes = {
  success: PropTypes.object
}

function mapStateToProps(state, ownProps) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    clearSuccessAction: bindActionCreators(clearSuccessState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KBSuccess)
