import React, { Component, PropTypes } from 'react'
import Row from './Row'
var Select = React.createClass({
  getInitialState() {
    return {
      value: ''
    }
  },
  changeValue(e) {
    let value = e.target.value

    if (value == ' ') {
      return
    }

    this.setState({
      value
    })
  },
  getDefaultData() {
    const { value } = this.state
    const { options } = this.props
    let defaultData = Object.assign([], this.props.defaultData)

    defaultData = defaultData.filter(json => {
      let pinyin = json[options.pinyin]
      if (!value) {
        return true
      }

      let isFilter =
        json[options.value].indexOf(value) != -1 ||
        (pinyin ? pinyin.indexOf(value) != -1 : false)

      return isFilter
    })

    // defaultData = this.filterSelect(defaultData)

    return defaultData
  },

  filterSelect(defaultData) {
    const { multi, selectData, options } = this.props
    let t = Object.assign([], defaultData)
    if (!selectData) {
      return t
    }

    if (multi) {
      t = defaultData.filter(json => {
        let id = json[options.key]
        return !selectData.find(data => data[options.key] == id)
      })
    } else {
      t = defaultData.filter(json => {
        return json[options.key] != selectData[options.key]
      })
    }

    return t
  },

  isFlag(rowData) {
    const { changeSelect, showImg, options, multi, selectData } = this.props

    if (!selectData) {
      return false
    }

    let isFlag = false
    if (multi) {
      isFlag = !!selectData.find(
        json => json[options.key] == rowData[options.key]
      )
    } else {
      isFlag = selectData[options.key] == rowData[options.key]
    }

    return isFlag
  },

  render() {
    const { changeSelect, showImg, options, multi, selectData } = this.props
    const { value } = this.state
    let defaultData = this.getDefaultData()

    return (
      <div>
        <div
          className="add-staff-component"
          style={{ width: 200, padding: 15 }}
        >
          <input
            className="KB-select_input"
            type="text"
            onChange={this.changeValue}
            value={value}
          />
        </div>
        <ul className="option-content_list">
          {defaultData &&
            defaultData.map((rowData, index) => {
              let isFlag = this.isFlag(rowData)

              return (
                <Row
                  multi={multi}
                  key={index}
                  isFlag={isFlag}
                  rowData={rowData}
                  changeSelect={changeSelect}
                  showImg={showImg}
                  options={options}
                />
              )
            })}
        </ul>
      </div>
    )
  }
})

export default Select
