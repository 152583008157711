import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'

var KBRadio = React.createClass({
  displayName: 'KBRadio',
  checkValue(fieldValue, dataValue) {
    if (fieldValue == 'false' || fieldValue == null || fieldValue == 'true') {
      return (fieldValue == 'false' || fieldValue == null ? false : true) == dataValue
    }

    return fieldValue === dataValue
  },
  render() {
    const {
      field,
      type,
      title,
      titleClass,
      outlineClass,
      className,
      divClassName,
      inClassName,
      arrayType,
      name,
      isRequired,
      isRequiredR,
      hint,
      groupStyle,
      itemStyle,
      onChange
    } = this.props
    let onchange = onChange || field.onChange
    return (
      <div className={classNames(className)}>
        <label className={titleClass}>
          {isRequiredR ? <span className="must-fillR">*</span> : ''}
          {title}
          {isRequired ? <span className="must-fill">*</span> : ''}
        </label>
        <div className={outlineClass}>
          <div className={inClassName} style={groupStyle}>
            {arrayType.map((data, index) => {
              return (
                <div className={divClassName} key={index} style={{display:'flex', alignItems:'center', ...itemStyle}}>
                  <input
                    type="radio"
                    id={index + name + 'radio'}
                    name={name}
                    value={data.value}
                    checked={this.checkValue(field.value, data.value)}
                    onChange={onchange}
                  />
                  <label
                    htmlFor={index + name + 'radio'}
                    style={{ marginLeft: 5 }}
                    className="label"
                  >
                    {data.title}
                  </label>
                </div>
              )
            })}
          </div>
          {field.touched && field.error ? (
            <p className="lr-error">{field.error}</p>
          ) : (
            <p style={{ color: '#c8c8c8', fontSize: 12 }}>{hint}</p>
          )}
        </div>
      </div>
    )
  }
})

KBRadio.propTypes = {
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  arrayType: PropTypes.array
}

KBRadio.defaultProps = {
  className: '',
  arrayType: []
}

export default KBRadio
