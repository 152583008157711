import React, { Component } from 'react'
import Select from './Select'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { bindActionCreators } from 'redux'
import {
  meetingsActions
} from 'app/actions'
import { api } from 'app/services'
import { getEntitiesJsonArray } from 'utils/kbUtil'
import {  successState, apiCallFailure } from 'app/actions'
import _ from 'lodash';
import { getCurrentLocation } from 'utils/kbUtil'

const LocationSelect = React.createClass({
  getInitialState() {
    return {
      list: []
    }
  },

  componentDidMount() {
    const { apiCallFailureAction } = this.props
    const currentLocation = getCurrentLocation()

    api.getMeetingRooms({ location_id: _.get(currentLocation, 'id'), per_page: 999999 }).then(
      json => {
        this.setState({
          list: getEntitiesJsonArray(json, 'meetingRoots')
        })
      },
      error => {
        apiCallFailureAction(error)
      }
    )
  },

  render() {
    const { props } = this
    const {list} = this.state
    return (
      <Select
        options={list.map(i => ({ label: i.name, value: i.id }))}
        placeholder="请选择会议室"
        showSearch
        {...props}
      />
    )
  }
})

function mapStateToProps(state, props) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
return {
  apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
}
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelect)
