import React, { Component, PropTypes } from 'react'
import { api } from 'app/services'
import { KBPopoverTop, KBQiniuFileUpload } from 'components'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle
} from 'react-sortable-hoc'
import classNames from 'classnames'
import { PopoverShowPicture } from 'components/views'
import config from 'app/config'

const ServiceDragHandle = SortableHandle(() => <div className="sort-handle" />)

const SortableItem = SortableElement(
  ({ item, imgStyle, removeFile, indexValue, img_width, img_height }) => {
    const imgSuffix = [
      '.png',
      '.jpg',
      'jpeg',
      '.gif',
      '.psd',
      'webp',
      'tiff',
      '.bmp'
    ]
    return (
      <li className="sort-image">
        {imgSuffix.includes(item.substr(item.length - 4)) ? (
          <img
            src={item}
            alt="loading"
            style={imgStyle ? imgStyle : { width: '100px', height: '100px' }}
          />
        ) : (
            <div
              className="file-show"
              style={imgStyle ? imgStyle : { width: '100px', height: '100px' }}
            >
              <img
                src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_wenjian@2x.png"
                className="img-file"
              />
              <span className="text">{item.substr(item.length - 4)}</span>
            </div>
          )}

        <div className="delete">
          <i
            className="iconfont icon-close"
            onClick={e => {
              removeFile(indexValue)
              e.stopPropagation()
            }}
          />
        </div>
        <ServiceDragHandle />
      </li>
    )
  }
)

const SortableList = SortableContainer(
  ({ items, imgStyle, removeFile, img_width, img_height }) => {
    return (
      <ul className="kb-more-file">
        {items.map((file, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              indexValue={index}
              item={file}
              imgStyle={imgStyle}
              removeFile={removeFile}
              img_width={img_width}
              img_height={img_height}
            />
          )
        })}
      </ul>
    )
  }
)

var KBMorePhotoUpload = React.createClass({
  getInitialState() {
    const { defaultImages, prefix } = this.props
    return {
      files: [],
      uploading: false,
      prefix: prefix ? prefix : config.uploadDir + 'locations/',
      uploadProgress: null,
      uploaded: false,
      uploadFileUrl: null,
      fileList: defaultImages || []
    }
  },

  getDefaultProps() {
    return {
      baseUrl: config.qiniu_base_url,
      photoShow: true,
      img_width: 100,
      img_height: 100
    }
  },

  componentDidMount() {
    const { isAzureUpload, azure_url } = config

    if (isAzureUpload) {
      this.setState({
        showUrl: azure_url
      })
      return
    }

    api.getUploadToken().then(({ json }) => {
      this.setUrlAndToken(json)
    })
  },

  changeField(value) {
    this.props.field.onChange(value)
  },

  onUpload: function (files) {
    // set onprogress function before uploading
    this.setState({
      uploading: true,
      uploaded: false,
      uploadProgress: 0,
      uploadFileUrl: null
    })
    var _this = this
    files.map(function (f) {
      f.onprogress = function (e) {
        _this.updateProgress(e.percent)
      }
    })
  },

  updateProgress(percent) {
    this.setState({ uploadProgress: percent })
  },

  checkSuffix(files) {
    const { suffix } = this.props
    let isError = false
    let suffixs = suffix.split(',')
    let filterFiles = []
    filterFiles = files.filter(file => {
      const name = file.name
      const fileSuffix = name.substr(name.lastIndexOf('.'), name.length)
      return suffixs.find(value => fileSuffix === '.' + value)
    })

    if (filterFiles.length) {
      return true
    }

    return isError
  },

  onDrop: function (files) {
    const {
      messageCallback,
      getURLCallback,
      suffix,
      getFileNameCallback,
    } = this.props
    const { fileList } = this.state

    var temp = Object.assign([], fileList)
    this.setState({
      files: files
    })

    if (suffix && !this.checkSuffix(files)) {
      this.setState({ uploading: false, uploaded: false })
      return getURLCallback && getURLCallback('suffixError')
    }

    if (getFileNameCallback) {
      let names = []
      files &&
        files.map(file => {
          names.push(file.name)
        })
      getFileNameCallback(names)
    }

    // files is a FileList(https://developer.mozilla.org/en/docs/Web/API/FileList) Object
    // and with each file, we attached two functions to handle upload progress and result
    // file.request => return super-agent uploading file request
    // file.uploadPromise => return a Promise to handle uploading status(what you can do when upload failed)
    // `react-qiniu` using bluebird, check bluebird API https://github.com/petkaantonov/bluebird/blob/master/API.md
    // see more example in example/app.js
    var _this = this
    files.map(f => {
      f.uploadPromise.then(response => {
        // const url = isPublic ? response.url : (this.state.showUrl + response.body.key)
        const url = response.url

        temp.push(url)

        _this.setState({
          uploading: false,
          uploaded: true,
          uploadFileUrl: url,
          fileList: temp
        })

        _this.changeField(temp.join(','))

        getURLCallback && getURLCallback(url)
      })
    })
    messageCallback && messageCallback(files)
  },

  setUploadUrl({ token }, uploadUrl) {
    this.setState({
      token,
      baseUrl: uploadUrl
    })
  },

  setUrlAndToken({ domain, token, upload_url }) {
    let params = {
      uploadToken: token,
      showUrl: domain,
      baseUrl: upload_url
    }

    this.setState(params)
  },

  _reset() {
    this.setState({ uploading: false, uploaded: false, uploadFileUrl: null })
  },

  renderProgress() {
    const { field, hint } = this.props
    return this.state.uploading ? (
      <div>
        <div
          className={classNames(
            this.props.buttonClass ? this.props.buttonClass : 'post-btn-ing'
          )}
        >
          <div
            className="post-btn-progress"
            style={{ width: this.state.uploadProgress + '%' }}
          ></div>
          <div className="post-btn-content">
            <span>{this.props.uploadingText || '正在上传'}</span>
          </div>
        </div>
        {field.touched && field.error ? (
          <p className="lr-error">{field.error}</p>
        ) : (
            <p style={{ color: '#c8c8c8', fontSize: 12, padding: '5px 0' }}>
              {hint}
            </p>
          )}
      </div>
    ) : null
  },

  renderUploaded() {
    const { uploadOneTime, field, hint, multiple } = this.props

    if (this.state.uploaded) {
      return (
        <div>
          <div className="post-btn-success">
            <div className="post-btn-progress" />
            <div className="post-btn-content">
              <span>
                {this.props.uploadedText || multiple
                  ? '点×继续上传'
                  : '上传完毕'}
              </span>
            </div>
            {uploadOneTime ? null : <a onClick={this._reset}>×</a>}
          </div>
          {field.touched && field.error ? (
            <p className="lr-error">{field.error}</p>
          ) : (
              <p style={{ color: '#c8c8c8', fontSize: 12, padding: '5px 0' }}>
                {hint}
              </p>
            )}
        </div>
      )
    }
    return null
  },

  renderUploadField() {
    const { multiple, field, hint, isPublic } = this.props
    const { prefix } = this.state
    return (this.state.uploadToken || config.isAzureUpload) &&
      !this.state.uploading &&
      !this.state.uploaded ? (
        <KBQiniuFileUpload
          uploadUrl={this.state.baseUrl}
          onDrop={this.onDrop}
          multiple={multiple}
          token={this.state.uploadToken}
          prefix={prefix}
          onUpload={this.onUpload}
          updateProgress={this.updateProgress}
          accept={this.props.accept}
          isPublic={isPublic}
        >
          <div
            className={classNames(
              this.props.buttonClass ? this.props.buttonClass : 'post-btn-default'
            )}
          >
            <div className="post-btn-progress"></div>
            <div className="post-btn-content">
              <span>{this.props.text}</span>
            </div>
          </div>
          {field.touched && field.error ? (
            <p className="lr-error">{field.error}</p>
          ) : (
              <p style={{ color: '#c8c8c8', fontSize: 12, padding: '5px 0' }}>
                {hint}
              </p>
            )}
        </KBQiniuFileUpload>
      ) : null
  },

  removeFile(index) {
    const { fileList } = this.state
    const { uploadOneTime } = this.props

    var tempFile = Object.assign([], fileList)
    tempFile.splice(index, 1)

    this.setState(
      Object.assign({}, this.state, {
        fileList: tempFile
      })
    )

    this.changeField(tempFile.join(','))

    if (uploadOneTime) {
      this._reset()
    }
  },

  onSortEnd({ oldIndex, newIndex }) {
    var fileList = this.state.fileList
    const newFilterList = arrayMove(fileList, oldIndex, newIndex)
    this.setState({
      fileList: newFilterList
    })
    this.changeField(newFilterList.join(','))
  },

  enlargeImages(url) {
    KBPopoverTop.show(<PopoverShowPicture photoUrl={url} isPopoverTop={true} />)
  },

  render() {
    const {
      className,
      titleClass,
      title,
      photoShow,
      field,
      hint,
      isRequired,
      imgStyle,
      sort,
      outerStyle,
      enlarge,
      img_width,
      img_height
    } = this.props
    const { fileList } = this.state
    const imgSuffix = [
      '.png',
      '.jpg',
      'jpeg',
      '.gif',
      '.psd',
      'webp',
      'tiff',
      '.bmp'
    ]

    return (
      <div className={className} style={outerStyle}>
        {title && (
          <label className={titleClass}>
            {title}
            {isRequired ? <span className="must-fill">*</span> : ''}
          </label>
        )}
        {this.renderUploadField()}
        {this.renderProgress()}
        {this.renderUploaded()}
        <br />
        {sort ? (
          <SortableList
            items={fileList}
            onSortEnd={this.onSortEnd}
            imgStyle={imgStyle}
            axis="xy"
            img_width={img_width}
            img_height={img_height}
            useDragHandle={true}
            removeFile={this.removeFile}
          />
        ) : (
            photoShow && (
              <ul className="kb-more-file">
                {fileList.map((file, index) => {
                  return (
                    <li
                      key={index}
                      onClick={enlarge ? this.enlargeImages.bind(null, file) : ''}
                      style={enlarge ? { cursor: 'pointer' } : {}}
                    >
                      {imgSuffix.includes(file.substr(file.length - 4)) ? (
                        <img
                          src={file}
                          alt="loading"
                          style={
                            imgStyle
                              ? imgStyle
                              : { width: '100px', height: '100px' }
                          }
                        />
                      ) : (
                          <div
                            className="file-show"
                            style={
                              imgStyle
                                ? imgStyle
                                : { width: '100px', height: '100px' }
                            }
                          >
                            <img
                              src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_wenjian@2x.png"
                              className="img-file"
                            />
                            <span className="text">
                              {file.substr(file.length - 4)}
                            </span>
                          </div>
                        )}

                      <div className="delete">
                        <i
                          className="iconfont icon-close"
                          onClick={this.removeFile.bind(null, index)}
                        />
                      </div>
                    </li>
                  )
                })}
              </ul>
            )
          )}
      </div>
    )
  }
})

KBMorePhotoUpload.propTypes = {
  field: PropTypes.object.isRequired,
  photoShow: PropTypes.bool.isRequired,
  className: PropTypes.string,
  prefix: PropTypes.string,
  multiple: PropTypes.bool,
  text: PropTypes.string.isRequired,
  uploadingText: PropTypes.string,
  uploadedText: PropTypes.string
}

export default KBMorePhotoUpload
