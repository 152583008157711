import React from 'react'
import { KBTableWithoutData, KBCheckBox } from 'components'
import Sortable from 'sortablejs'
import { cloneDeep } from 'lodash'

const KBTable = React.createClass({
  getInitialState() {
    return {
      isSelectAll: false,
      itemsSelectArr: [],
      originData: []
    }
  },

  selectAll(val) {
    const { dataSource, selectChange } = this.props
    const arr = this.state.itemsSelectArr.map(() => val)
    this.setState(
      {
        isSelectAll: val,
        itemsSelectArr: arr
      },
      () => {
        selectChange(dataSource.filter((item, idx) => arr[idx]))
      }
    )
  },

  selectItem(val, idx) {
    const { dataSource, selectChange } = this.props
    this.state.itemsSelectArr[idx] = val
    const arr = [...this.state.itemsSelectArr]
    const sAll = arr.every(i => i)
    this.setState(
      {
        itemsSelectArr: arr,
        isSelectAll: sAll
      },
      () => {
        selectChange(dataSource.filter((item, idx) => arr[idx]))
      }
    )
  },

  initSortContent() {
    const { sortable, sortChange, dataSource } = this.props
    const _this = this
    if (sortable && this.tableElement && this.tableElement.children[1]) {
      this.setState(
        {
          originData: dataSource
        },
        () => {
          this.sortContent = Sortable.create(this.tableElement.children[1], {
            animation: 150,
            ghostClass: 'blue-background-class',
            draggable: '.item',
            // 如果想要通过某个小控件触发拖拽，那么需要为那个控件添加这个calssName
            handle: '.table-sort-handle-item',
            onEnd(evt) {
              console.log(_this.state.originData, _this.props.dataSource)
              const { newIndex, oldIndex } = evt
              const result = [..._this.state.originData]
              const currRow = result.splice(oldIndex, 1)[0]
              result.splice(newIndex, 0, currRow)
              _this.setState({
                originData: result
              })
              sortChange && sortChange(result)
            },
            setData(dataTransfer, dragEl) {
              // console.log('setData', dataTransfer, dragEl)
            },
            onChange(evt) {
              // console.log('onChange', evt, dataSource)
            }
          })
        }
      )
    }
  },

  componentWillReceiveProps(nextProps, props) {
    const { canSelect = false } = this.props
    const { dataSource } = nextProps
    this.setState({
      originData: dataSource
    })
    if (canSelect) {
      const { dataSource, selectChange } = nextProps
      const arr = dataSource.map(() => false)
      this.setState(
        {
          isSelectAll: false,
          itemsSelectArr: arr
        },
        () => {
          selectChange &&
            selectChange(dataSource.filter((item, idx) => arr[idx]))
        }
      )
    }
  },

  componentDidUpdate() {
    // console.log(this.sortContent)
    // this.sortContent && this.sortContent.revertOnSpill()
  },

  componentDidMount() {
    this.initSortContent()
  },

  render() {
    const { dataSource, columns, canSelect = false } = this.props
    const { isSelectAll, itemsSelectArr } = this.state
    return (
      <table
        ref={ref => {
          this.tableElement = ref
        }}
        className="content-table table-align"
      >
        <thead style={{ whiteSpace: 'nowrap' }}>
          <tr>
            {canSelect ? (
              <th>
                <KBCheckBox checked={isSelectAll} callback={this.selectAll} />
              </th>
            ) : null}

            {columns.map(col => {
              const { key, width, align = 'left' } = col
              return (
                <th
                  key={key}
                  style={{
                    width,
                    textAlign: align
                  }}
                >
                  {col.title}
                </th>
              )
            })}
          </tr>
        </thead>
        <KBTableWithoutData
          hasData={dataSource.length > 0}
          tableHeadNum={columns.length}
          tipMessage="no data"
        >
          {dataSource.map((data, idx) => {
            return (
              <tr key={data.id} className="item">
                {/* 存在多选 */}
                {canSelect && (
                  <th>
                    <KBCheckBox
                      checked={itemsSelectArr[idx]}
                      callback={val => this.selectItem(val, idx)}
                    />
                  </th>
                )}
                {columns.map((col, index) => {
                  const { key, width, align = 'left' } = col
                  // 存在render方法
                  if (col.render && typeof col.render === 'function') {
                    return (
                      <td
                        key={key}
                        style={{
                          width,
                          textAlign: align
                        }}
                      >
                        {col.render(data, key, index)}
                      </td>
                    )
                  } else {
                    // 直接展示对应的key字段
                    return (
                      <td
                        key={key}
                        style={{
                          width,
                          textAlign: align
                        }}
                      >
                        {data[key]}
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </KBTableWithoutData>
      </table>
    )
  }
})

export default KBTable
