import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { Modal, notification } from 'antd'
import { reduxForm } from 'redux-form'
import { invoicesActions, successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { routerActions } from 'react-router-redux'
import {
  formatMinuteSecondEN,
  fmoney,
  formatYearDayEN,
  toPercent
} from 'utils/kbUtil'
import classNames from 'classnames'
import InvoiceCertificationForm from './InvoiceCertificationForm'
import InvoiceCertificationFileForm from './InvoiceCertificationFileForm'
import { KBPopoverConfirm } from 'components/tools'
import InvoiceUpdateForm from './InvoiceUpdateForm'
import {
  KBPopover,
  KBButton,
  KBAvatar,
  KBTableWithoutData,
  KBTipsy,
  KBConfirmModal
} from 'components'
import {
  INVOICES_STATE,
  INVOICES_STATE_CLASS,
  INVOICES_SENDING_STATE,
  INVOICES_SENDING_STATE_CLASS,
  INVOICE_SALES_PAYMENTS,
  INVOICE_APPLICATION_STATE_OBJ,
  LINE_ITEM_TYPES_OBJ
} from 'app/constants'
import { getInvoiceOfId, getCurrentUser, getSpaceSetting } from 'app/selectors'
import * as valid from 'utils/validate'
import OrderForm from './OrderForm'
import * as selectors from 'app/selectors'
import InvoicesSendEmailForm from './InvoicesSendEmailForm'
import {
  canAccessLocationFinance,
  canAccessFinance,
  canAccessLocationObserver,
  canAdminLocation,
  canAccessLocationExceptObserver,
  canAccessLocationManager,
  canAccessLocationRoles,
  canAccessLocation,
  canAdminSpace
} from 'app/reducers/role'
import { PopoverShowPicture } from 'components/views'
import InvoicePrintHtml from './InvoicePrintHtml'
import InvoicesApplication from './InvoicesApplication'
import InvoicesApplicationFinishForm from './InvoicesApplicationFinishForm'
import InvoiceCheckPaidForm from './InvoiceCheckPaidForm'
import InvoicesDeleteForm from './InvoicesDeleteForm'
import DeleteBillApprovalForm from './DeleteBillApprovalForm'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy'
import config from 'app/config'

import _ from 'lodash'
import { getUserInfoUrl } from 'utils/kbUrl'

var InvoicesInfo = React.createClass({
  getInitialState() {
    return {
      refund_type: {},
      payment_method: '',
      invoicing_application_status: '',
      invoice: {},
      loading: false
    }
  },
  componentDidMount() {
    const { getInvoicesActions, invoice_id, invoice } = this.props
    api.getSubscriptionLineItemRefundType().then(
      ({ json }) => {
        const { subscriptionLineItemRefundType } = json.entities
        this.setState({
          refund_type: subscriptionLineItemRefundType
        })
      },
      error => {}
    )
    api.getInvoice(invoice_id).then(
      json => {
        getInvoicesActions.success(json)
        let invoice = json.response.body
        let invoicing_application_status =
          invoice.invoicing_application && invoice.invoicing_application.status
        this.setState({
          invoice,
          invoicing_application_status
        })
      },
      errors => {
        getInvoicesActions.failure(errors)
      }
    )
  },

  componentWillReceiveProps(nextProps) {
    const { type, isTeam, location_id, invoice_id, routerActions } = nextProps
    if (type == 'add') {
      if (!location_id) {
        routerActions.replace(`/admin/invoices/invoices_list/${invoice_id}`)
      } else {
        routerActions.replace(
          `/admin/locations/${location_id}/invoices/invoices_list/${invoice_id}`
        )
      }
      setTimeout(this.addInvoiceCert, 200)
    }
  },

  printme() {
    const { invoice_id, user } = this.props
    console.log(invoice_id)
    api.printPage(invoice_id).then(res => {
      const {
        json: { url }
      } = res
      // url = 'https://capitalanddev.blob.core.chinacloudapi.cn/capitastar/uploads/user/files/1722219532%E6%96%87%E5%AD%97%E6%96%87%E7%A8%BF1.docx'
      if (!url) {
        let invoiceDom = this.refs['printDom'].innerHTML
        let newwindow = window.open('', `printInvoice-${invoice_id}`)
        newwindow.document.write(invoiceDom)
        newwindow.window.print()
      } else {
        const urlArr = url.split('/')
        const filename = urlArr[urlArr.length - 1]
        this.setState({
          loading: true
        })
        const xhr = new XMLHttpRequest()
        xhr.open(
          'GET',
          `${config.urlScheme}://${config.url}/managements/v2/uploads/download_private_file?url=${url}`,
          true
        )
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.setRequestHeader('Authorization', `Bearer ${user.jwt_token}`)
        xhr.responseType = 'blob'
        xhr.onload = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              let type = xhr.getResponseHeader('Content-Type')
              let blob = new Blob([xhr.response], { type: type })
              if (typeof window.navigator.msSaveBlob !== 'undefined') {
                /*
                 * For IE
                 * >=IE10
                 */
                window.navigator.msSaveBlob(blob, filename)
              } else {
                /*
                 * For Non-IE (chrome, firefox)
                 */
                let URL = window.URL || window.webkitURL
                let objectUrl = URL.createObjectURL(blob)
                let a = document.createElement('a')
                  if (typeof a.download === 'undefined') {
                    window.location = objectUrl
                  } else {
                    a.href = objectUrl
                    a.download = filename
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                  }
              }
              this.setState({
                loading: false
              })
            } else {
              this.setState({
                loading: false
              })
              notification.error({
                message: '资源下载失败',
                description: '资源下载失败，请稍后再试'
              })
            }
          }
        }
        xhr.send()
        // const urlArr = url.split('/')
        // const name = urlArr[urlArr.length - 1]
        // let xhr = new XMLHttpRequest()
        // xhr.open('GET', url, true)
        // xhr.responseType = 'blob' //web-portal
        // xhr.onreadystatechange = function() {
        //   if (xhr.readyState == 4) {
        //     if (xhr.status == 200) {
        //       let blob = this.response
        //       console.log(blob)
        //       // var src = URL.createObjectURL(blod);
        //       let eleLink = document.createElement('a')
        //       eleLink.download = name
        //       eleLink.href = URL.createObjectURL(blob)
        //       document.body.appendChild(eleLink)
        //       eleLink.click()
        //     }
        //   }
        // }
        // xhr.send()
      }
    },err=>{
      notification.error({message: err.message})
    })
  },

  updateInvoiceNote(model) {
    const { invoice, successAction } = this.props
    return api.putInvoice(invoice.id, model).then(
      json => {
        successAction({ message: '添加成功!' })
        this.props.updateInvoicesActions.success(json)
      },
      errors => {
        this.props.updateInvoicesActions.failure(errors)
      }
    )
  },

  addOrder() {
    const { invoice_id, invoice } = this.props
    const contract_id = invoice.line_items[0].sales_subscription_id
    KBPopover.show(
      <OrderForm invoice_id={invoice_id} contract_id={contract_id} />
    )
  },

  sendFinanceEmail() {
    const { invoice_id } = this.props
    KBPopover.show(<InvoicesSendEmailForm invoice_id={invoice_id} />)
  },

  invoicesReview() {
    const { invoice_id, successAction } = this.props
    KBPopover.show(
      <KBConfirmModal
        title="审核账单"
        context="你是否确认账单审核通过?"
        callback={async () => {
          return api.reviewInvoice(invoice_id, { pass: true }).then(
            json => {
              successAction({ message: '审批通过成功!' })
              this.props.updateInvoicesActions.success(json)
            },
            errors => {
              this.props.updateInvoicesActions.failure(errors)
            }
          )
        }}
      />
    )
    // KBPopoverConfirm({
    //   name: '审核账单',
    //   context: `你是否确认账单审核通过?`,
    //   callback: () => {
    //     return api.reviewInvoice(invoice_id, { pass: true }).then(
    //       json => {
    //         successAction({ message: '审批通过成功!' })
    //         this.props.updateInvoicesActions.success(json)
    //         KBPopover.close()
    //       },
    //       errors => {
    //         this.props.updateInvoicesActions.failure(errors)
    //       }
    //     )
    //   }
    // })
  },

  invoiceConfirmed() {
    const { invoice_id } = this.props
    KBPopover.show(
      <KBConfirmModal
        title="确认核销"
        context="是否确认当前核销?"
        callback={async () => {
          return api.putConfirmPaid(invoice_id).then(
            json => {
              this.props.updateInvoicesActions.success(json)
            },
            errors => {
              this.props.updateInvoicesActions.failure(errors)
            }
          )
        }}
      />
    )
    // KBPopoverConfirm({
    //   name: '确认核销',
    //   context: `是否确认当前核销?`,
    //   callback: () => {
    //     return api.putConfirmPaid(invoice_id).then(
    //       json => {
    //         this.props.updateInvoicesActions.success(json)
    //         KBPopover.close()
    //       },
    //       errors => {
    //         this.props.updateInvoicesActions.failure(errors)
    //         KBPopover.close()
    //       }
    //     )
    //   }
    // })
  },

  invoiceCheckPaid() {
    const { invoice_id, location_id } = this.props

    KBPopover.show(
      <InvoiceCheckPaidForm invoice_id={invoice_id} location_id={location_id} />
    )
  },

  deleteInvoice(id) {
    return KBPopover.show(<InvoicesDeleteForm id={id} />)
  },

  clickPhoto(url) {
    KBPopover.show(<PopoverShowPicture photoUrl={url} />)
  },

  addInvoiceCert() {
    const { invoice } = this.props
    KBPopover.show(
      <InvoiceCertificationForm
        invoice={invoice}
        showSelf={this.addInvoiceCert}
      />
    )
  },

  addInvoiceCertFileOnly(payment) {
    const { invoice } = this.props

    KBPopover.show(
      <InvoiceCertificationFileForm
        invoice={invoice}
        payment={payment}
        updateInvoicePayment={this.updateInvoicePayments}
      />
    )
  },

  deleteInvoiceCertFile(payment, index) {
    const { invoice } = this.props

    KBPopover.show(
      <KBConfirmModal
        title="删除收款凭证"
        context="是否确认删除当前收款凭证文件?"
        callback={async () => {
          let params = {}
          params.payment_id = payment.id
          params.vouchers = payment.vouchers
          params.vouchers.splice(index, 1)
          params.vouchers = params.vouchers.join(',')
          return api.putResetVouchers(invoice.id, params).then(json => {
            let updatedPayment =
              (json && json.response && json.response.body) || {}
            let updatedInvoice = this.updateInvoicePayments(updatedPayment)
            this.props.updateInvoicesActions.success(updatedInvoice)
            return true
          })
        }}
      />
    )
    // KBPopoverConfirm({
    //   name: '删除收款凭证',
    //   context: `是否确认删除当前收款凭证文件?`,
    //   callback: () => {
    //     let params = {}
    //     params.payment_id = payment.id
    //     params.vouchers = payment.vouchers
    //     params.vouchers.splice(index, 1)
    //     params.vouchers = params.vouchers.join(',')
    //     return api.putResetVouchers(invoice.id, params).then(
    //       json => {
    //         let updatedPayment =
    //           (json && json.response && json.response.body) || {}
    //         let updatedInvoice = this.updateInvoicePayments(updatedPayment)
    //         this.props.updateInvoicesActions.success(updatedInvoice)
    //         KBPopover.close()
    //         return true
    //       },
    //       error => {
    //         KBPopover.close()
    //       }
    //     )
    //   }
    // })
  },
  //（获取指定字符后面的所有字符内容）
  getCaption(obj) {
    var index = obj.lastIndexOf('/')
    obj = obj.substring(index + 1, obj.length)
    return obj
  },
  deletePayment() {
    const { invoice } = this.props

    KBPopover.show(
      <KBConfirmModal
        title="删除收款项"
        context="您将删除当前账单的所有收款项记录，并将更改当前账单收款状态，是否确认删除?"
        callback={async () => {
          let params = {}
          params.location_id = invoice.location_id
          return api.deletePayment(invoice.id, params).then(
            ({ json }) => {
              let updating_invoice = {
                json: { entities: { invoices: { [json.id]: json } } }
              }
              this.props.updateInvoicesActions.success(updating_invoice)
              return true
            },
            error => {
              this.props.updateInvoicesActions.failure(error)
            }
          )
        }}
      />
    )
    // KBPopoverConfirm({
    //   name: '删除收款项',
    //   context: `您将删除当前账单的所有收款项记录，并将更改当前账单收款状态，是否确认删除?`,
    //   callback: () => {
    //     let params = {}
    //     params.location_id = invoice.location_id
    //     return api.deletePayment(invoice.id, params).then(
    //       ({ json }) => {
    //         let updating_invoice = {
    //           json: { entities: { invoices: { [json.id]: json } } }
    //         }
    //         this.props.updateInvoicesActions.success(updating_invoice)
    //         KBPopover.close()
    //         return true
    //       },
    //       error => {
    //         this.props.updateInvoicesActions.failure(error)
    //         KBPopover.close()
    //       }
    //     )
    //   }
    // })
  },

  updateInvoicePayments(newPayment) {
    let { invoice } = this.props

    let index =
      invoice.sales_payments &&
      invoice.sales_payments.length &&
      invoice.sales_payments.findIndex(data => {
        return data.id == newPayment.id
      })
    invoice.sales_payments &&
      invoice.sales_payments.length &&
      invoice.sales_payments.splice(index, 1, newPayment)

    let updatedInvoice = {
      json: { entities: { invoices: { [invoice.id]: invoice } } }
    }

    return updatedInvoice
  },

  updateInvoiceApplication() {
    const { invoicing_application_status } = this.state

    if (!invoicing_application_status) {
      this.setState({ invoicing_application_status: 'pending' })
    }
  },

  applyInvoice() {
    const { invoice } = this.props

    let invoices = []
    invoices.push(invoice)
    KBPopover.show(
      <InvoicesApplication
        type="create"
        invoices={invoices}
        updateInvoices={this.updateInvoiceApplication}
      />
    )
  },

  processInvoiceApp() {
    const { invoice } = this.props

    let params = {}
    params.location_id = invoice.location_id
    params.ids = String(invoice.id)
    KBPopover.show(
      <KBConfirmModal
        title="处理开票"
        context="是否处理当前开票?"
        callback={async () => {
          return api
            .processInvoiceApplicationBatch({ ...params })
            .then(json => {
              KBPopover.close()
              this.setState({
                invoicing_application_status: 'process'
              })
            })
        }}
      />
    )
    // KBPopoverConfirm({
    //   name: '处理开票',
    //   context: `是否处理当前开票?`,
    //   callback: () => {
    //     return api.processInvoiceApplicationBatch({ ...params }).then(json => {
    //       KBPopover.close()
    //       this.setState({
    //         invoicing_application_status: 'process'
    //       })
    //     })
    //   }
    // })
  },

  finishInvoiceApp() {
    const { invoice } = this.props

    let invoices = []
    let params = {}
    params.loc_id = invoice.location_id
    params.ids = [invoice.id]
    invoices.push(params)

    KBPopover.show(
      <InvoicesApplicationFinishForm
        location_id={invoice.location_id}
        locIdGroupedInvoices={invoices}
        callback={() => {
          this.setState({ invoicing_application_status: 'issued' })
          KBPopover.close()
        }}
      />
    )
  },

  editInvoice() {
    const { invoice_id, invoice } = this.props
    KBPopover.show(
      <InvoiceUpdateForm invoice_id={invoice_id} invoice={invoice} />
    )
  },

  delayInvoice() {
    const { invoice_id, invoice } = this.props
    KBPopover.show(
      <InvoiceUpdateForm
        update_type="delay_invoice"
        invoice_id={invoice_id}
        invoice={invoice}
      />
    )
  },

  deleteLineItem(item) {
    const { invoice, invoice_id } = this.props

    KBPopover.show(
      <KBConfirmModal
        title="删除账款"
        context="是否确认删除当前账款项?"
        callback={async () => {
          let updatingItems = Object.assign([], invoice.line_items)
          updatingItems.splice(
            invoice.line_items.findIndex(i => i.id === item.id),
            1
          )
          let model = {
            line_items: updatingItems
          }
          return api.updateInvoicesLineItems(invoice_id, model).then(
            json => {
              this.props.updateInvoicesActions.success(json)
            },
            error => {
              this.props.updateInvoicesActions.failure(error)
            }
          )
        }}
      />
    )
  },
  //删除审批通过
  deleteApproved(id) {
    return KBPopover.show(<DeleteBillApprovalForm id={id} type="approved" />)
  },
  //删除审批拒绝
  deleteRejected(id) {
    return KBPopover.show(<DeleteBillApprovalForm id={id} type="rejected" />)
  },

  invoiceJsx() {
    const {
      invoice,
      invoice_id,
      location_id,
      isObserver,
      isManager,
      isOperator,
      isLocManager,
      isSales,
      isBillReview,
      isBillConfirm,
      needConfirm,
      isOnlyFinance,
      checkUsers,
      confirmUsers,
      isSpaceAdmin
    } = this.props
    const { invoicing_application_status } = this.state

    const status = INVOICES_STATE
    const status_class = INVOICES_STATE_CLASS
    const sending_status = INVOICES_SENDING_STATE
    const sending_status_class = INVOICES_SENDING_STATE_CLASS

    let total_amount = invoice && parseFloat(invoice.total_amount).toFixed(2)
    total_amount = isNaN(total_amount) ? 0 : total_amount

    let paid_amount = invoice && parseFloat(invoice.paid_amount).toFixed(2)
    paid_amount = isNaN(paid_amount) ? 0 : paid_amount

    const remaining = parseFloat(total_amount - paid_amount).toFixed(2)
    const {
      fields: { note },
      error,
      submitting,
      handleSubmit,
      user,
      isFinance,
      isConfirmed,
      isCheckPaid,
      isCheckPaidFailed,
      isAdmin
    } = this.props
    const { refund_type, loading } = this.state
    const isCheckUser = isOnlyFinance || isOperator || checkUsers

    const isConfirmUser = confirmUsers || isFinance || isOperator

    let isTax =
      invoice && invoice.line_items && invoice.line_items.find(item => item.tax)

    let sales_areas = []
    let resources = []
    invoice.line_items &&
      invoice.line_items.map(item => resources.push(item.resource))
    resources = _.uniqBy(resources, 'resource_id')
    resources.map(res => {
      if (res.sales_area) {
        sales_areas = sales_areas.concat(res.sales_area)
      }
    })

    const isCancelled = invoice.status === 'cancelled'
    const isParking =
      invoice.invoice_type === 'parking_rent' ||
      invoice.invoice_type === 'parking_deposit' ||
      invoice.invoice_type === 'parking_rent_and_deposit'

    const reviewedStyle = {
      float: 'left',
      display: 'block',
      padding: '3px 6px',
      backgroundColor: '#2ea1f8',
      textAlign: 'center',
      color: '#ffffff',
      fontSize: '12px',
      marginLeft: '16px',
      marginTop: '4px'
    }
    return (
      <div>
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            {location_id ? (
              <span className="f-left">
                <Link
                  className="a-hover"
                  to={`/admin/locations/${location_id}/organizations/${invoice.sales_customer &&
                    invoice.sales_customer.foreign_id}`}
                >
                  {invoice.sales_customer && invoice.sales_customer.name}
                </Link>
              </span>
            ) : (
              <span className="f-left">
                {invoice.sales_customer && invoice.sales_customer.name}
              </span>
            )}
            {invoice.is_reviewed ? (
              <span style={reviewedStyle}>已审核</span>
            ) : (
              <span style={{ ...reviewedStyle, backgroundColor: '#dd5a55' }}>
                未通过审核
              </span>
            )}
            <span
              className={`finance-default ${
                isCheckPaidFailed
                  ? 'receipt-refunding'
                  : isConfirmed || isCheckPaid
                  ? 'receipt-confirmed'
                  : status_class[invoice.status]
              }`}
              style={{ marginTop: 4 }}
            >
              {isCheckPaidFailed
                ? '核销失败'
                : isConfirmed
                ? '确认核销'
                : isCheckPaid
                ? '已核销'
                : invoice.status === 'paid'
                ? '已收款'
                : status[invoice.status]}
            </span>
            {invoice.income_confirmed_at ? (
              <span
                style={{ marginTop: 4 }}
                className={`finance-default receipt-confirmed`}
              >
                已确认收入
              </span>
            ) : null}
            {invoice.status !== 'paid' && (
              <span
                className={`finance-default ${
                  sending_status_class[invoice.sending_status]
                }`}
                style={{ marginTop: 4 }}
              >
                {sending_status[invoice.sending_status]}
              </span>
            )}
            {invoice.status == 'paid' ? (
              <span
                style={{ float: 'left', marginLeft: 16, marginTop: 4 }}
                className={`invoice-application-state invoice-application-state-${
                  invoicing_application_status
                    ? invoicing_application_status
                    : 'none'
                }`}
              >
                {invoicing_application_status
                  ? INVOICE_APPLICATION_STATE_OBJ[invoicing_application_status]
                  : '未申请发票'}
              </span>
            ) : null}
            <br />
            <div className="f-left" style={{ marginTop: '5px', fontSize: 16 }}>
              {location_id ? (
                <span style={{ fontSize: '16px' }}>
                  <Link
                    className="a-hover"
                    to={`/admin/locations/${location_id}`}
                  >
                    {(invoice.location && invoice.location.name) || ''}
                  </Link>
                  <Link
                    className="a-hover"
                    to={`/admin/locations/${location_id}/organizations/${invoice.sales_customer &&
                      invoice.sales_customer.foreign_id}`}
                  >
                    &nbsp;{' '}
                    {invoice.sales_customer && invoice.sales_customer.full_name}
                  </Link>
                </span>
              ) : (
                <span>
                  {(invoice.location && invoice.location.name) || ''} &nbsp;
                  {invoice.sales_customer && invoice.sales_customer.full_name}
                </span>
              )}
            </div>
          </div>
          <div className="f-right" style={{ display: 'flex' }}>
            {/* { isFinance ? <button className="bordered-btn" onClick={ this.editInvoice }>修改账单</button> : '' } */}
            {/*{(isFinance || isOperator) &&
              invoice.status != 'paid' && invoice.status != 'refunded' && invoice.status != 'reverse_approving' &&
              !isCancelled && invoice.status != '_deleted' ? (
                <button className="bordered-btn" onClick={this.delayInvoice}>
                  账单延期
                </button>
              ) : (
                ''
              )}*/}
            {(invoice.status == 'paid' ||
              (invoice.invoice_type == 'refund' &&
                invoice.status == 'refunded')) &&
            isCheckUser &&
            !isCheckPaid &&
            !isConfirmed ? (
              <button className="bordered-btn" onClick={this.invoiceCheckPaid}>
                核销
              </button>
            ) : (
              ''
            )}
            {(invoice.status == 'paid' ||
              (invoice.invoice_type == 'refund' &&
                invoice.status == 'refunded')) &&
            isConfirmUser &&
            isCheckPaid &&
            !isConfirmed &&
            needConfirm ? (
              <button className="bordered-btn" onClick={this.invoiceConfirmed}>
                确认核销
              </button>
            ) : (
              ''
            )}
            {isFinance &&
            !invoice.is_reviewed &&
            !isCancelled &&
            invoice.status != '_deleted' &&
            invoice.status != 'reverse_approving' ? (
              <button className="bordered-btn" onClick={this.invoicesReview}>
                账单审核
              </button>
            ) : (
              ''
            )}
            {(isManager || isSales || isOperator) &&
            invoice.status !== 'paid' &&
            invoice.status != 'refunded' &&
            invoice.is_reviewed ? (
              <button className="bordered-btn" onClick={this.sendFinanceEmail}>
                发送账单给客户
              </button>
            ) : (
              ''
            )}
            {(isManager || isSales || isOperator || isAdmin) &&
            isBillConfirm &&
            invoice.status !== 'paid' &&
            invoice.status != 'refunded' &&
            invoice.status != '_deleted' &&
            invoice.status != 'reverse_approving' &&
            !isCancelled ? (
              <button className="bordered-btn" onClick={this.addInvoiceCert}>
                上传付款凭证
              </button>
            ) : (
              ''
            )}
            {invoice.status != '_deleted' &&
            invoice.status != 'reverse_approving' ? (
              <button
                className="c-btn-secondary m-left-sm"
                onClick={this.printme}
                disabled={loading}
                style={{
                  opacity: loading ? 0.7 : 1
                }}
              >
                {loading ? '下载中' : '打印账单'}
              </button>
            ) : (
              ''
            )}
            {(isManager || isOperator) &&
            invoice.status == 'paid' &&
            (!invoicing_application_status ||
              invoicing_application_status === 'aborted') &&
            (isCheckPaid || isConfirmed) ? (
              <button
                className="bordered-btn m-left-sm"
                onClick={this.applyInvoice}
              >
                申请发票
              </button>
            ) : (
              ''
            )}
            {(isManager || isOperator) &&
            invoicing_application_status == 'pending' &&
            (isCheckPaid || isConfirmed) ? (
              <button
                className="bordered-btn m-left-sm"
                onClick={this.processInvoiceApp}
              >
                处理开票
              </button>
            ) : (
              ''
            )}
            {(isManager || isOperator) &&
            invoicing_application_status == 'process' &&
            (isCheckPaid || isConfirmed) ? (
              <button
                className="bordered-btn m-left-sm"
                onClick={this.finishInvoiceApp}
              >
                完成开票
              </button>
            ) : (
              ''
            )}
            {(isLocManager || isOperator) &&
            (invoice.status == 'unpaid' ||
              (invoice.status == 'overdue' && invoice.paid_amount == 0)) ? (
              <button
                className="bordered-btn-end"
                onClick={this.deleteInvoice.bind(null, invoice.id)}
              >
                删除
              </button>
            ) : (
              ''
            )}
            {isOnlyFinance && invoice.status == 'reverse_approving' ? (
              <button
                className="certain-btn m-left-sm"
                onClick={this.deleteApproved.bind(null, invoice.id)}
              >
                删除账单审批通过
              </button>
            ) : null}
            {isOnlyFinance && invoice.status == 'reverse_approving' ? (
              <button
                className="bordered-btn-end"
                onClick={this.deleteRejected.bind(null, invoice.id)}
              >
                删除账单审批拒绝
              </button>
            ) : null}
          </div>
        </header>
        <div ref="printDom" style={{ display: 'none' }}>
          <InvoicePrintHtml
            invoices={[invoice]}
            location_id={location_id}
            refund_type={refund_type}
          />
        </div>
        <div className="nav-section-content-container">
          <div className="kb-section p-bottom">
            <ul>
              <li>
                <span className="section-title">账单编号:</span>
                <span className="section-title-content">
                  {invoice.serial_number}
                </span>
              </li>
              {/*<li><span className='section-title'>生成日期:</span><span className='section-title-content'>{ formatYearDayEN(invoice.created_at) }</span></li>*/}
              <li>
                <span className="section-title">到期日期:</span>
                <span className="section-title-content">
                  {formatYearDayEN(invoice.due_date)}
                </span>
              </li>
            </ul>
          </div>
          {invoice.invoice_type == 'custom' ? null : (
            <div className="kb-title">
              <span>租赁情况</span>
            </div>
          )}

          {invoice.invoice_type == 'custom' ? null : (
            <table className="content-table m-bottom t-layout">
              <thead>
                <tr>
                  <th>{isParking ? '车位编号' : '场地名称'}</th>
                  {isParking ? null : <th>面积/工位</th>}
                  <th>单价</th>
                  {isParking ? null : <th>物业费</th>}
                  <th className="t-right">总价</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={(invoice.sales_areas || []).length > 0}
                tableHeadNum="5"
                tipMessage="暂无租赁信息"
              >
                {(invoice.sales_areas || []).map((json, index) => {
                  if (!json) {
                    return null
                  }
                  const chargeTypeUnit = { by_desk: '个工位', by_area: '㎡' }
                  const chargePriceUnit = {
                    by_desk: '/月/个',
                    by_area: '/月/㎡'
                  }
                  const chargeTypeData = {
                    by_desk: json.units || 0,
                    by_area: json.size || 0
                  }

                  return (
                    <tr key={index}>
                      <td>{json.name || ''}</td>
                      {isParking ? null : (
                        <td>
                          {chargeTypeData[json.charge_type] +
                            chargeTypeUnit[json.charge_type]}
                        </td>
                      )}
                      <td>
                        ¥ {json.unit_price}{' '}
                        {isParking ? null : chargePriceUnit[json.charge_type]}
                      </td>
                      {isParking ? null : (
                        <td>
                          {json.house_fee
                            ? `¥ ${json.house_fee}${
                                chargePriceUnit[json.charge_type]
                              }`
                            : '0'}
                        </td>
                      )}
                      <td className="t-right">
                        ¥ {fmoney(json.total_price, 2)}/月
                      </td>
                    </tr>
                  )
                })}
              </KBTableWithoutData>
            </table>
          )}

          <div className="kb-title">
            <span>账款列表</span>
          </div>
          <table className="content-table m-bottom">
            <thead>
              <tr>
                <th style={{ width: '55%' }}>说明</th>
                {invoice.invoice_type == 'refund' &&
                invoice.line_items &&
                invoice.line_items.find(value => value.refund_type) ? (
                  <th>退款类型</th>
                ) : (
                  <th />
                )}
                <th>单价</th>
                <th>数量</th>
                {isTax ? <th>税率</th> : <th />}
                {isTax ? <th>税额</th> : <th />}
                {isTax ? <th>不含税额</th> : <th />}
                <th>总价</th>
                <th className="t-right">操作</th>
              </tr>
            </thead>
            <KBTableWithoutData
              hasData={invoice.line_items && invoice.line_items.length > 0}
              tableHeadNum="7"
              tipMessage="暂无账款信息"
            >
              {invoice.line_items &&
                invoice.line_items.map((json, index) => {
                  const amountTax =
                    json.tax &&
                    (json.unit_price / (1 + json.tax.rate)) * json.tax.rate
                  const isSubscription =
                    json.resource &&
                    json.resource.resource_type === 'SalesSubscription'
                  json.sales_subscription_serial =
                    json.resource && json.resource.sales_subscription_serial
                  if (json.refund_type == 'Reservation') {
                    var name = ''

                    if (json.units_name == 'hours') {
                      name = '会议室预定会员费（单位：小时）'
                    } else if (json.units_name == 'minutes') {
                      name = '会议室超时会员费（单位：分钟）'
                    } else {
                      name = json.units_name
                    }

                    return (
                      <tr key={index}>
                        <td>
                          <div style={{ maxWidth: '500px' }}>{name}</div>
                        </td>
                        {invoice.invoice_type == 'refund' &&
                        json.refund_type ? (
                          <td>
                            {refund_type[json.refund_type] &&
                              refund_type[json.refund_type].name}
                          </td>
                        ) : (
                          <td />
                        )}
                        <td style={{ whiteSpace: 'nowrap' }}>
                          ¥ {fmoney(json.unit_price, 2)}
                        </td>
                        <td>{json.units}</td>
                        <td />
                        <td />
                        <td style={{ whiteSpace: 'nowrap' }}>
                          ¥ {fmoney(json.total_amount, 2)}
                        </td>
                        <td className="t-right">
                          {json.created_by ? (
                            <i
                              className="iconfont icon-delete hover-delete"
                              onClick={this.deleteLineItem.bind(null, json)}
                            />
                          ) : null}
                        </td>
                      </tr>
                    )
                  }

                  return (
                    <tr key={index}>
                      <td>
                        <div style={{ maxWidth: '500px' }}>
                          {isSubscription
                            ? (json.line_item_type_t || '')
                            : json.description}
                          &nbsp;
                          {json.sales_subscription_serial ? (
                            location_id ? (
                              <Link
                                to={`/admin/locations/${invoice.location_id}/organizations/${invoice.organization_id}/subscriptions/${json.sales_subscription_id}`}
                                style={{ marginLeft: '5px' }}
                                className="a-hover"
                              >
                                (合同#{json.sales_subscription_serial})
                              </Link>
                            ) : (
                              <Link
                                to={`/admin/invoices/1/organizations/${invoice.organization_id}/subscriptions/${json.sales_subscription_id}`}
                                style={{ marginLeft: '5px' }}
                                className="a-hover"
                              >
                                (合同#{json.sales_subscription_serial})
                              </Link>
                            )
                          ) : (
                            ''
                          )}
                          &nbsp;
                          {json.start_date && json.end_date
                            ? '【' +
                              json.start_date +
                              '至' +
                              json.end_date +
                              '】'
                            : ''}
                        </div>
                      </td>
                      {invoice.invoice_type == 'refund' && json.refund_type ? (
                        <td>
                          {refund_type[json.refund_type] &&
                            refund_type[json.refund_type].name}
                        </td>
                      ) : (
                        <td />
                      )}
                      <td style={{ whiteSpace: 'nowrap' }}>
                        ¥ {fmoney(json.unit_price, 2)}
                      </td>
                      <td>{json.units}个</td>
                      {isTax && json.tax ? (
                        <td>{toPercent(json.tax.rate, 1)}</td>
                      ) : (
                        <td />
                      )}
                      {isTax && json.tax ? (
                        <td style={{ whiteSpace: 'nowrap' }}>
                          ¥ {fmoney(amountTax, 2)}
                        </td>
                      ) : (
                        <td />
                      )}
                      {isTax && json.tax ? (
                        <td style={{ whiteSpace: 'nowrap' }}>
                          ¥ {fmoney(json.total_amount - amountTax, 2)}
                        </td>
                      ) : (
                        <td />
                      )}
                      <td style={{ whiteSpace: 'nowrap' }}>
                        ¥ {fmoney(json.total_amount, 2)}
                      </td>
                      <td className="t-right">
                        {json.created_by ? (
                          <i
                            className="iconfont icon-delete hover-delete"
                            onClick={this.deleteLineItem.bind(null, json)}
                          />
                        ) : null}
                      </td>
                    </tr>
                  )
                })}
            </KBTableWithoutData>
          </table>
          <div className="p-bottom clear-fix">
            {(isManager || isSales || isOperator) &&
            invoice.status !== 'paid' &&
            invoice.status != '_deleted' &&
            invoice.status != 'reverse_approving' &&
            !isCancelled ? (
              <div className="f-left">
                <button
                  className="bordered-btn"
                  style={{ marginLeft: 0 }}
                  onClick={this.addOrder}
                >
                  添加账款
                </button>
              </div>
            ) : (
              ''
            )}
            <table className="content-table-half f-right">
              <tbody>
                <tr>
                  <td>应付金额</td>
                  <td className="t-right">¥ {fmoney(total_amount, 2)}</td>
                </tr>
                <tr>
                  <td>实收金额</td>
                  <td className="t-right">¥ {fmoney(paid_amount, 2)}</td>
                </tr>
                <tr>
                  <td>剩余金额</td>
                  <td
                    className={classNames(
                      't-right',
                      remaining <= 0 ? 'color-red' : ''
                    )}
                  >
                    ¥ {fmoney(remaining, 2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {invoice &&
          invoice.sales_payments &&
          invoice.sales_payments.length > 0 ? (
            <div className="finance-detail">
              <header
                className="kb-title vf-center"
                style={{ justifyContent: 'space-between' }}
              >
                <span>收款明细</span>
                {!isConfirmed && (isManager || isOperator) ? (
                  <button
                    className="c-btn-warning"
                    onClick={this.deletePayment}
                  >
                    清除收款
                  </button>
                ) : null}
              </header>
              <table className="content-table t-layout">
                <thead>
                  <tr>
                    <th>时间</th>
                    <th>金额</th>
                    <th>经手人</th>
                    <th>付款方式</th>
                    {/* <th>流水号</th> */}
                    <th>备注</th>
                    <th className="t-right">付款凭证</th>
                    {/* <th className='t-right'>操作</th> */}
                  </tr>
                </thead>
                <tbody>
                  {invoice.sales_payments.map((json, index) => {
                    let vou = json.vouchers[0]
                    let imgs = ['.png', '.jpg', '.gif']
                    let stl = {
                      color: '#2ea1f8',
                      cursor: 'pointer',
                      marginRight: 25
                    }
                    return (
                      <tr key={index}>
                        <td>{formatYearDayEN(json.payment_time)}</td>
                        <td>¥ {fmoney(json.amount, 2)}</td>
                        <td>
                          <div className="vf-center">
                            <KBAvatar
                              style={{
                                display: 'inline-block',
                                marginRight: '5px'
                              }}
                              imgStyle={{ marginRight: 5 }}
                              user={json.creator}
                              size={20}
                            />
                            <Link
                              className="color-link"
                              to={getUserInfoUrl(
                                json.creator && json.creator.id
                              )}
                            >
                              {(json.creator && json.creator.name) || ''}
                            </Link>
                          </div>
                        </td>
                        <td>
                          {json.payment_mode
                            ? INVOICE_SALES_PAYMENTS[json.payment_mode]
                            : '无'}
                        </td>
                        {/* <td>{ json.reference_number }</td> */}
                        <td>{json.notes || ''}</td>
                        {json.vouchers.length > 0 ? (
                          <td className="t-right">
                            {json.vouchers.map((vou, index) => {
                              return (
                                <div style={{ paddingTop: 5 }}>
                                  {imgs.includes(vou.substr(vou.length - 4)) ? (
                                    <KBTipsy content="查看图片凭证">
                                      <span style={stl}>
                                        <KBDownloadPrivacy url={vou} preview={url => this.clickPhoto(url)}>
                                          <i className="iconfont icon-search" />
                                        </KBDownloadPrivacy>
                                      </span>
                                    </KBTipsy>
                                  ) : (
                                    <KBTipsy content="查看文件凭证">
                                      <KBDownloadPrivacy url={vou}>
                                        <a style={stl}>
                                          <img
                                            src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_down@3x.png"
                                            style={{ width: 15, height: 15 }}
                                          />
                                        </a>
                                      </KBDownloadPrivacy>
                                    </KBTipsy>
                                  )}
                                  {!(isCheckPaid || isConfirmed) ? (
                                    <KBTipsy content="删除付款凭证">
                                      <i
                                        onClick={this.deleteInvoiceCertFile.bind(
                                          null,
                                          json,
                                          index
                                        )}
                                        className="iconfont icon-delete"
                                      />
                                    </KBTipsy>
                                  ) : null}
                                </div>
                              )
                            })}
                          </td>
                        ) : (
                          <td className="t-right">
                            {(isManager || isSales || isOperator || isAdmin) &&
                            isBillConfirm ? (
                              <a
                                href="#"
                                className="color-link"
                                onClick={this.addInvoiceCertFileOnly.bind(
                                  null,
                                  json
                                )}
                              >
                                <i
                                  className="iconfont icon-add"
                                  style={{ marginRight: 5 }}
                                />
                                上传付款凭证
                              </a>
                            ) : (
                              ''
                            )}
                          </td>
                        )}
                        {/* <td className='t-right'>
                        {
                            !(isCheckPaid || isConfirmed) ?
                            <KBTipsy content='删除收款项'>
                              <i onClick={ this.deletePayment.bind(null, json) } className='iconfont icon-delete' />
                            </KBTipsy>
                            : null
                        }
                        </td> */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            ''
          )}
          {invoice && invoice.attrlogs && invoice.attrlogs.length > 0 ? (
            <div className="finance-detail">
              <header className="kb-title">历史记录</header>
              <table className="content-table t-layout">
                <thead>
                  <tr>
                    <th style={{ width: '20%' }}>操作</th>
                    <th style={{ width: '20%' }}>日期</th>
                    <th style={{ width: '15%' }}>经办人</th>
                    <th style={{ width: '15%' }}>备注</th>
                    <th style={{ width: '30%' }}>附件</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.attrlogs.map((log, index) => {
                    return (
                      <tr key={index}>
                        <td>{log.event || ''}</td>
                        <td>{formatMinuteSecondEN(log.created_at)}</td>
                        <td>
                          <div className="vf-center">
                            <KBAvatar
                              style={{
                                display: 'inline-block',
                                marginRight: '5px'
                              }}
                              imgStyle={{ marginRight: 5 }}
                              user={log.operator}
                              size={20}
                            />
                            <Link
                              className="color-link"
                              to={getUserInfoUrl(
                                log.operator && log.operator.id
                              )}
                            >
                              {(log.operator && log.operator.name) || ''}
                            </Link>
                          </div>
                        </td>
                        <td>{log.description || ''}</td>
                        <td>
                          {log.files
                            ? log.files.map(item => (
                                <a
                                  style={{ fontSize: 12, color: '#1890ff' }}
                                  href={item}
                                  target="_blank"
                                >
                                  {this.getCaption(item)}
                                </a>
                              ))
                            : ''}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            ''
          )}
          {isFinance || isOperator ? (
            <div className="finance-detail">
              <header>备注</header>
              <form onSubmit={handleSubmit(this.updateInvoiceNote)}>
                <textarea
                  ref="invoiceNoteTextarea"
                  className={classNames(
                    note.touched && note.error ? 'kb-input-error' : 'kb-input'
                  )}
                  placeholder="添加备注"
                  {...note}
                />
                {note.touched && note.error && (
                  <p className="lr-error">{note.error}</p>
                )}
                <div className="finance-detail-footer">
                  <span>该备注仅限于内部人员可见.</span>
                </div>
                <div className="f-right m-top-sm">
                  <KBButton
                    className="certain-btn"
                    type="submit"
                    submitting={submitting}
                  >{`${note.value ? '修改' : '添加'}备注信息`}</KBButton>
                </div>
              </form>
            </div>
          ) : null}
        </div>
      </div>
    )
  },
  render() {
    const { location_id, invoice } = this.props
    return invoice ? (
      <div className="kb-content-container clear-fix">
        <section>{this.invoiceJsx()}</section>
      </div>
    ) : null
  }
})

function mapStateToProps(state, props) {
  const { entities } = state
  const { params } = props
  const invoice_id = params.invoice_id
  const user = getCurrentUser(state)
  let space_id = user.space

  const invoice = getInvoiceOfId(state, invoice_id)
  const invoiceTemp =
    invoice &&
    Object.assign({}, invoice, {
      note: invoice.note ? invoice.note : ''
    })

  if (
    invoice &&
    invoice.sales_customer &&
    invoice.sales_customer.foreign_type == 'org'
  ) {
    invoice.organization_id = invoice.sales_customer.foreign_id
  }

  let location_id = props.params.id || (invoice && invoice.location_id)
  let isFinance = canAccessLocationRoles(user, location_id, [
    'location_manager',
    'finance'
  ])
  let isManager = canAccessLocationExceptObserver(user, location_id)
  let isLocManager = canAccessLocationManager(user, location_id)
  let isOperator = canAccessLocationRoles(user, location_id, [
    'location_manager',
    'location_operator'
  ])
  const isAdmin = canAdminSpace(user)
  let isObserver = canAccessLocationObserver(user, location_id)
  let isOnlyFinance = canAccessLocationRoles(user, location_id, ['finance'])
  let isSales = canAccessLocationRoles(user, location_id, ['sales'])
  let isBillReview = isFinance || isOperator
  let isBillConfirm = !canAccessLocationObserver(user, location_id)
  let checkUsers = canAccessLocationRoles(user, location_id, ['invoice_check'])
  // location_id
  //   ? selectors.getTeamRolesOfLocation(state, location_id, 'invoice_check')
  //   : []
  let confirmUsers = canAccessLocationRoles(user, location_id, [
    'invoice_confirm'
  ])
  // location_id
  //   ? selectors.getTeamRolesOfLocation(state, location_id, 'invoice_confirm')
  //   : []
  let isSpaceAdmin = canAdminSpace(user)
  let spaceSetting = selectors.getSpaceSetting(state)
  const needConfirm =
    spaceSetting.invoice_settings &&
    spaceSetting.invoice_settings.invoice_need_confirm
  // if(space_id == 6){
  //   isBillReview = canAdminLocation(user, location_id)
  //   isBillConfirm = isFinance || canAccessLocation(user, location_id)
  // }
  let isUnchecked = invoice && invoice.check_status == 'unchecked'
  let isCheckPaid = invoice && invoice.check_status == 'checked'
  let isCheckPaidFailed = invoice && invoice.check_status == 'check_failed'
  let isConfirmed = invoice && invoice.check_status == 'confirmed'
  let isIncomeConfirmed = invoice && invoice.income_confirmed_at ? true : false

  const { location } = props
  const type = location.query.type
  const isTeam = location.query.isTeam

  return {
    user,
    location_id,
    isCheckPaid,
    isCheckPaidFailed,
    isUnchecked,
    isConfirmed,
    isIncomeConfirmed,
    invoice_id,
    invoice,
    initialValues: invoiceTemp,
    isOnlyFinance,
    isFinance,
    isObserver,
    isManager,
    isLocManager,
    isOperator,
    isSales,
    isBillReview,
    isBillConfirm,
    type,
    isTeam,
    checkUsers,
    confirmUsers,
    isSpaceAdmin,
    needConfirm,
    isAdmin
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getInvoicesActions: bindActionCreators(invoicesActions.replace, dispatch),
    deleteInvoicesActions: bindActionCreators(invoicesActions.delete, dispatch),
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.note)) {
    errors.note = '请输入备注信息'
  }

  return errors
}

const formConfig = {
  form: 'InvoicesInfo',
  fields: ['note'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoicesInfo)
