import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import _ from 'lodash'
import { KBAvatar } from 'components'
import { util } from 'utils/kbUtil'

var Select = require('react-select')
var KBDropDownListInput = React.createClass({
  getInitialState() {
    return {
      dropShow: false,
      str: ''
    }
  },

  componentWillReceiveProps(nextProps) {},

  componentDidMount() {},

  selectDownList(data) {
    const { selectCallBack } = this.props
    selectCallBack && selectCallBack(data)
    this.hideDrop()
  },

  showDrop() {
    this.setState({ dropShow: true })
  },

  hideDrop() {
    this.setState({ dropShow: false })
  },

  inputFocus() {
    this.showDrop()
  },

  inputBlur() {
    util.setTimeout('dropDownInput', this.hideDrop, 300)
  },

  changeUser(e) {
    let str = e.target.value

    if (!str) {
      this.setState({ str: null })
      return
    }

    this.setState({ str })
  },

  render() {
    const { placeholder, data, filterIdArray, query, styleWidth } = this.props
    const { dropShow, str } = this.state
    let tempUser = data.filter(user => {
      return filterIdArray.findIndex(id => id == user.id) == -1 ? true : false
    })

    tempUser = tempUser.filter(user => {
      if (!str) {
        return true
      }

      if (!query || !query.length) {
        return true
      }

      let t = _.find(query, json => {
        return user[json].indexOf(str) !== -1
      })

      return t ? true : false
    })

    return (
      <div className="add-staff-component" style={{ width: styleWidth || 186 }}>
        <input
          type="text"
          placeholder={placeholder}
          onChange={this.changeUser}
          onFocus={this.inputFocus}
          onBlur={this.inputBlur}
        />
        {dropShow &&
          (this.props.children || (
            <ul className="add-staff-list">
              {tempUser.map((json, index) => (
                <li onClick={this.selectDownList.bind(null, json)} key={index}>
                  <a href="#">
                    <div className="add-staff-portrait">
                      <KBAvatar
                        user={json}
                        size={30}
                        style={{
                          width: '30px',
                          height: '30px',
                          lineHeight: '33px'
                        }}
                      />
                    </div>
                    <span className="add-staff-name">{json.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          ))}
      </div>
    )
  }
})

KBDropDownListInput.propTypes = {
  data: PropTypes.array.isRequired
}

export default KBDropDownListInput
