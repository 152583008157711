import _ from 'lodash'

function locationRole(state = {}, action) {
  const data = action.response
  let newState = _.cloneDeep(state)

  if (!action.location_id) {
    return state
  }

  let location_id = action.location_id

  newState[location_id] = newState[location_id] || {}

  if (action.type === 'GETLOCATIONROLE_SUCCESS') {
    newState[location_id] = data.json
    return newState
  }

  if (action.type === 'UPDATELOCATIONROLE_SUCCESS' && action.key) {
    newState[location_id].roles = newState[location_id].roles.filter(
      r => r.role !== action.key
    )
    newState[location_id].roles = newState[location_id].roles.concat(
      data.json.roles || []
    )
    newState[location_id].roles_settings = data.json.roles_settings
    return newState
  }

  return state
}

export default locationRole
