import React, { Component, PropTypes } from 'react'
import { KBPopover, KBDropdown } from 'components'
import DayPicker, { DateUtils } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import moment from 'moment'
import _ from 'lodash'

var FromToDropdown = React.createClass({
  getInitialState() {
    return {
      filters: {}
    }
  },
  handleDayClick(e, day) {
    const { from, to } = this.props
    const range = DateUtils.addDayToRange(day, { from, to })
    this._selectRange(range)
  },

  _setDateRange() {
    this._refresh('fromTo')
  },

  _selectRange(range) {
    const { from, to } = range
    this.props.setFilter({ from, to })
  },

  _selectToday() {
    let today = new Date()
    this._selectRange({ from: today, to: today })
    this._refresh('fromTo')
  },

  _selectWeek() {
    let today = new Date()
    let weekAgo = moment(today)
      .startOf('week')
      .toDate()
    let endOfWeek = moment(today)
      .endOf('week')
      .toDate()
    this._selectRange({ from: weekAgo, to: endOfWeek })
    this._refresh('fromTo')
  },

  _selectMonth() {
    let today = new Date()
    let weekAgo = moment(today)
      .startOf('month')
      .toDate()
    let endOfWeek = moment(today)
      .endOf('month')
      .toDate()
    this._selectRange({ from: weekAgo, to: endOfWeek })
    this._refresh('fromTo')
  },

  _selectQuarter() {
    let today = new Date()
    let weekAgo = moment(today)
      .startOf('quarter')
      .toDate()
    let endOfWeek = moment(today)
      .endOf('quarter')
      .toDate()
    this._selectRange({ from: weekAgo, to: endOfWeek })
    this._refresh('fromTo')
  },

  _refresh(key) {
    let dropDown = key + 'Dropdown'
    this.refs[dropDown] && this.refs[dropDown].hide()
  },

  hide() {
    this.refs['fromToDropdown'].hide()
  },

  _resetDateRange() {
    this.props.setFilter({ from: null, to: null })
    this._refresh('fromTo')
  },
  render() {
    const { from, to, closeClick, dropDown_name } = this.props
    return (
      <KBDropdown ref="fromToDropdown">
        <KBDropdown.KBDropdownTrigger>
          <div
            className="task-status-title"
            onClick={() => {
              closeClick && closeClick(dropDown_name)
            }}
          >
            <i
              className="iconfont icon-calendar"
              style={{ marginRight: '3px' }}
            />
            <span>
              {from && to
                ? `${moment(from).format('MMMDo')}-${moment(to).format(
                    'MMMDo'
                  )}`
                : '选择日期'}
            </span>
            <span className="task-deg" />
          </div>
        </KBDropdown.KBDropdownTrigger>
        <KBDropdown.KBDropdownContent>
          <div style={{ width: 500 }}>
            <DayPicker
              dir="zh-cn"
              locale="zh-cn"
              ref="daypicker"
              localeUtils={MomentLocaleUtils}
              numberOfMonths={2}
              selectedDays={day => DateUtils.isDayInRange(day, { from, to })}
              onDayClick={this.handleDayClick}
            />
            <div
              className="date-btn"
              style={{
                padding: 20,
                borderTop: '1px solid #e6e6e6',
                background: '#fff'
              }}
            >
              <div className="f-left">
                <a className="add-btn" href="#" onClick={this._selectWeek}>
                  本周
                </a>
                <a className="add-btn" href="#" onClick={this._selectMonth}>
                  本月
                </a>
                <a className="add-btn" href="#" onClick={this._selectQuarter}>
                  本季度
                </a>
              </div>
              <div className="f-right">
                <span className="cancel-btn" onClick={this._resetDateRange}>
                  重置
                </span>
                <button className="certain-btn" onClick={this._setDateRange}>
                  确定
                </button>
              </div>
            </div>
          </div>
        </KBDropdown.KBDropdownContent>
      </KBDropdown>
    )
  }
})

FromToDropdown.propTypes = {
  from: PropTypes.object,
  to: PropTypes.object,
  setFilter: PropTypes.func
}

export default FromToDropdown
