import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBInput,
  KBSelect,
  KBFormError,
  KBButton,
  KBTextarea,
  KBUserInput
} from 'components'
import { arrayToObject, objectToArray } from 'utils/kbUtil'
import { api, apiUtils } from 'app/services'

const AREA_TYPE_ARRAY = [
  {
    id: 'private_usage',
    name: '封闭区域'
  },
  {
    id: 'public_usage',
    name: '开放区域'
  },
  {
    id: 'flexible_usage',
    name: '灵活区域'
  },
  {
    id: 'all',
    name: '全部'
  }
]

var CrmInventorySearchForm = React.createClass({
  displayName: 'CrmInventorySearchForm',
  getInitialState() {
    return {
      selectState: 'all'
    }
  },

  componentDidMount() {
    this.searchCrmInventory({})
  },

  searchCrmInventory(model) {
    const { callback } = this.props
    const { selectState } = this.state
    let areaType = model.area_type
    delete model.area_type

    if (selectState == 'unabsorbed') {
      model.only_free = true
      model.expire_days = ''
    }
    if (selectState == 'expiring') {
      model.expire_days = 30
      model.only_free = ''
    }
    if (selectState == 'threeMonth') {
      model.expire_days = 90
      model.only_free = ''
    }
    if (selectState == 'all') {
      model.only_free = false
      model.expire_days = ''
    }
    return api.getCrmInventory(model).then(({ json }) => {
      if (areaType == 'public_usage') {
        delete json.private_usage
        delete json.flexible_usage
      }
      if (areaType == 'private_usage') {
        delete json.public_usage
        delete json.flexible_usage
      }
      if (areaType == 'flexible_usage') {
        delete json.public_usage
        delete json.private_usage
      }
      callback && callback(json)
    })
  },

  changeOnlyFree(state) {
    this.setState({
      selectState: state
    })
  },

  render() {
    const {
      fields: {
        location_id,
        price_more_than,
        price_less_than,
        only_free,
        area_type
      },
      error,
      submitting,
      handleSubmit,
      locations
    } = this.props
    const { selectState } = this.state
    return (
      <form onSubmit={handleSubmit(this.searchCrmInventory)}>
        <KBFormError err={error} />
        <div className="kb-form-container" style={{ padding: '30px 0px' }}>
          <div className="kb-row kb-div-66">
            <KBUserInput
              title="分店"
              placeholder="选择分店"
              className="kb-form-group kb-form-39"
              field={location_id}
              multi={false}
              users={locations}
              selectedUsers={[]}
            />
            <KBUserInput
              title="分区类型"
              placeholder="选择分区类型"
              className="kb-form-group kb-form-39"
              field={area_type}
              multi={false}
              users={AREA_TYPE_ARRAY}
              selectedUsers={[]}
              titleClass="label-rt"
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBInput
              className="kb-form-group kb-div-39"
              title="价格不大于"
              field={price_more_than}
            />
            <KBInput
              className="kb-form-group kb-div-39"
              title="价格不小于"
              field={price_less_than}
              titleClass="label-rt"
            />
          </div>
          <div className="kb-row">
            <div
              className="f-left c-pointer"
              onClick={this.changeOnlyFree.bind(null, 'unabsorbed')}
            >
              <input type="radio" name="expiring" id="unabsorbed" />
              <label
                className="c-pointer"
                style={{ color: '#999', marginLeft: 5 }}
                htmlFor="unabsorbed"
              >
                未分配
              </label>
            </div>
            <div
              className="f-left c-pointer m-left-sm"
              onClick={this.changeOnlyFree.bind(null, 'expiring')}
            >
              <input
                type="radio"
                name="expiring"
                style={{ marginLeft: 15 }}
                id="expiring"
              />
              <label
                className="c-pointer"
                style={{ color: '#999', marginLeft: 5 }}
                htmlFor="expiring"
              >
                快到期
              </label>
            </div>
            <div
              className="f-left c-pointer m-left-sm"
              onClick={this.changeOnlyFree.bind(null, 'threeMonth')}
            >
              <input
                type="radio"
                name="expiring"
                style={{ marginLeft: 15 }}
                id="threeMonth"
              />
              <label
                className="c-pointer"
                style={{ color: '#999', marginLeft: 5 }}
                htmlFor="threeMonth"
              >
                三个月到期
              </label>
            </div>
            <div
              className="f-left c-pointer m-left-sm"
              onClick={this.changeOnlyFree.bind(null, 'all')}
            >
              <input
                type="radio"
                name="expiring"
                defaultChecked="checked"
                style={{ marginLeft: 15 }}
                id="all"
              />
              <label
                className="c-pointer"
                style={{ color: '#999', marginLeft: 5 }}
                htmlFor="all"
              >
                显示全部
              </label>
            </div>
          </div>
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              搜索
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

const validate = values => {
  const errors = {}
  return errors
}

function mapStateToProps(state, props) {
  return {}
}

const formConfig = {
  form: 'CrmInventorySearchForm',
  fields: [
    'location_id',
    'price_more_than',
    'price_less_than',
    'only_free',
    'area_type'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(formConfig, mapStateToProps)(CrmInventorySearchForm)
