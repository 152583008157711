import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { successState, apiCallFailure } from 'app/actions'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBPopoverTop,
  KBRadio
} from 'components'
import * as selectors from 'app/selectors'
import InvoiceTitleForm from '../organization/createOrganization/InvoiceTitleForm'

const invoiceContent = [
  { title: '房租', value: '房租' },
  { title: '水费', value: '水费' },
  { title: '电费', value: '电费' },
  { title: '服务费', value: '服务费' },
  { title: '物业管理费', value: '物业管理费' },
  { title: '会议室使用费', value: '会议室使用费' },
  { title: '网络服务费', value: '网络服务费' },
  { title: '其他费用', value: '其他费用' }
]
const invoiceContentArray = [
  '房租',
  '物业管理费',
  '会议室使用费',
  '水费',
  '电费',
  '服务费',
  '网络服务费',
  ''
]

const TITLE_TYPES = [
  {
    title: '个人',
    name: '个人',
    id: 'user',
    value: 'user'
  },
  {
    title: '公司',
    name: '公司',
    id: 'org',
    value: 'org'
  }
]

const INVOICING_TYPES = [
  {
    title: '增值税普通发票',
    value: 'common'
  },
  {
    title: '增值税专用发票',
    value: 'special'
  }
]

let InvoicesApplication = React.createClass({
  getInitialState() {
    const {
      fields: { invoice_content }
    } = this.props

    return {
      selectedContent:
        invoiceContentArray.includes(invoice_content.value) ||
        invoice_content.value === '其他费用'
          ? invoice_content.value
          : '其他费用',
      invoice: {},
      arrayInvoices: [],
      clickNext: false,
      invoiceTitles: [],
      selectedTitle: {}
    }
  },

  applyInvoices(model) {
    const {
      type,
      successAction,
      updateList,
      showSelf,
      index,
      updateInvoices,
      apiCallFailureAction
    } = this.props
    const { selectedTitle } = this.state

    if (type === 'update') {
      let id = model.id

      delete model.invoice_ids
      delete model.id
      delete model.invoice_title_type
      return api.updateInvoiceApplication(id, model).then(json => {
        updateList && updateList()
        successAction({ message: `更新发票成功` })
        KBPopover.close()
        return true
      })
    }

    if (model.invoice_title_type === 'user') {
      model.invoice_title = '个人'
    } else if (!selectedTitle.id) {
      return apiCallFailureAction({
        status: 'error',
        message: '请选择一个发票抬头'
      })
    } else {
      model.invoice_title = selectedTitle.title
      model.tax_num = selectedTitle.tax_num
      model.bank = selectedTitle.bank
      model.address = selectedTitle.address
      model.phone_num = selectedTitle.phone_num
      if (selectedTitle.invoice_type) {
        model.invoice_type = selectedTitle.invoice_type
      }
    }

    delete model.id
    return api.postInvoiceApplication(model).then(json => {
      updateInvoices && updateInvoices()
      successAction({ message: `申请开票成功!` })
      KBPopover.close()
      if (this.state.clickNext) {
        showSelf(index + 1)
      }
      return true
    })
  },

  componentWillMount() {
    const { type } = this.props

    if (type === 'create') {
      const {
        invoices,
        fields: { location_id, invoice_ids, invoice_title_type, invoicing_type }
      } = this.props
      let ids = []
      invoices &&
        invoices.map(json => {
          ids.push(json.id)
        })
      let loc_id = invoices[0].location_id
      invoice_ids.onChange(ids)
      location_id.onChange(loc_id)
      invoice_title_type.onChange('org')
      invoicing_type.onChange('common')
      this.invoice_title_ids = invoices[0].line_items.filter(json => {
        return json.resource.invoice_title_id ? true : false
      })
      this.setState({
        invoice: invoices[0],
        arrayInvoices: invoices
      })
    }

    if (type === 'update') {
      const {
        invoiceApp,
        fields: {
          location_id,
          id,
          invoice_title,
          tax_num,
          invoice_content,
          bank,
          address,
          phone_num,
          invoice_title_type,
          invoicing_type,
          email
        }
      } = this.props
      let a_id = String(invoiceApp.id)
      let loc_id = invoiceApp.location_id
      let in_title = invoiceApp.invoice_title || ''
      let t_num = invoiceApp.tax_num || ''
      let in_content = invoiceApp.invoice_content || ''
      let in_bank = invoiceApp.bank || ''
      let in_address = invoiceApp.address || ''
      let in_phone_num = invoiceApp.phone_num || ''
      id.onChange(a_id)
      location_id.onChange(loc_id)
      invoice_title.onChange(in_title)
      tax_num.onChange(t_num)
      invoice_content.onChange(in_content)
      bank.onChange(in_bank)
      address.onChange(in_address)
      phone_num.onChange(in_phone_num)
      invoice_title_type.onChange(invoiceApp.invoice_title_type)
      invoicing_type.onChange(invoiceApp.invoicing_type)
      email.onChange(invoiceApp.email)
      const isOtherCost = !invoiceContent.find(i => i.value == in_content)
      this.setState({
        selectedContent: isOtherCost ? '其他费用' : in_content
      })
    }
  },

  componentDidMount() {
    const { invoice } = this.state

    let sales_customer_id =
      (invoice.sales_customer && invoice.sales_customer.id) || null
    let location_id = invoice.location_id

    if (!sales_customer_id || !location_id) {
      return
    }

    api
      .getInvoiceTitles({
        location_id,
        sales_customer_id
      })
      .then(json => {
        let invoiceArray = json.response.body || []
        let invoiceTitles = []
        if (this.invoice_title_ids.length > 0) {
          this.invoice_title_ids.map(ids => {
            invoiceArray.map(invoiceItem => {
              if (ids.resource.invoice_title_id == invoiceItem.id) {
                invoiceTitles.push(invoiceItem)
              }
            })
          })
        }

        this.isShowTnvoiceType =
          this.invoice_title_ids.length > 0 ? false : true
        // invoiceTitles.map(item=>{
        //     if(!item.invoice_type){
        //       this.isShowTnvoiceType = true;
        //     }
        // });

        if (invoiceTitles.length > 0) {
          this.selectTitle(invoiceTitles[0])
        }

        this.setState({
          invoiceTitles
        })
      })
  },

  changeInvoiceContent(value) {
    const {
      fields: { invoice_content }
    } = this.props
    let valueState = value

    if (invoiceContentArray.includes(value)) {
      invoice_content.onChange(value)
    } else if (value === '其他费用') {
      invoice_content.onChange('')
    } else {
      invoice_content.onChange(value)
      valueState = '其他费用'
    }

    this.setState({
      selectedContent: valueState
    })
  },

  selectTitle(title) {
    this.setState({
      selectedTitle: title
    })
  },

  pushNewTitle(title) {
    if (!title) {
      return null
    }
    const { invoiceTitles } = this.state
    let newInvoiceTitles = Object.assign([], invoiceTitles)
    newInvoiceTitles.push(title)
    this.setState({
      invoiceTitles: newInvoiceTitles,
      selectedTitle: title
    })
  },

  addNewInvoiceTitle() {
    const {
      location,
      fields: { invoicing_type }
    } = this.props
    const { invoice } = this.state
    let location_id = location && location.id
    let sales_customer_id =
      invoice && invoice.sales_customer && invoice.sales_customer.id
    KBPopoverTop.show(
      <InvoiceTitleForm
        is_special={invoicing_type.value === 'special'}
        callBack={this.pushNewTitle}
        isInvoiceApp={true}
        location_id={location_id}
        sales_customer_id={sales_customer_id}
      />
    )
  },

  render() {
    const {
      fields: {
        invoice_title,
        invoice_content,
        tax_num,
        bank,
        address,
        phone_num,
        invoice_title_type,
        email,
        invoicing_type
      },
      submitting,
      handleSubmit,
      invoices,
      type,
      length
    } = this.props
    const {
      selectedContent,
      invoice,
      invoiceTitles,
      selectedTitle
    } = this.state
    const { locations, invoiceApp } = this.props
    const oneClass = {
      className: 'kb-form-group kb-form-210 kb-form-alone'
    }

    const sales_customer = invoice.sales_customer || invoiceApp.sales_customer
    return (
      <form onSubmit={handleSubmit(this.applyInvoices)}>
        <header className="kb-form-header">
          <h2 className="header-title">
            {type === 'update' ? '编辑发票' : '申请开票'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          {sales_customer ? (
            <div className="kb-row">
              <div className={oneClass.className}>
                <label>公司</label>
                <div>
                  <span
                    style={{ display: 'inline-block', padding: '9px 0 8px' }}
                  >
                    {(sales_customer && sales_customer.full_name) || '无'}
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          <div className="kb-row kb-div-66">
            <KBRadio
              className="kb-form-group kb-form-c48"
              inClassName="kb-form-group kb-div-66"
              groupStyle={{ width: '100%' }}
              divClassName="kb-form-group kb-div-c39"
              rightLabel="label-rt"
              title="发票类型"
              arrayType={TITLE_TYPES}
              name="title_type"
              field={invoice_title_type}
            />
          </div>
          {/* <div className='kb-row'>
     {
				invoice_title_type.value === 'org'  && this.isShowTnvoiceType ?
				<KBRadio className='kb-form-group kb-div-100' inClassName='kb-form-group kb-div-100'  divClassName='kb-form-group kb-div-c18'  groupStyle={{width:'100%'}}  rightLabel='label-rt' title='类别' arrayType={ INVOICING_TYPES } name='invoicing_type'  field={ invoicing_type}/>
				: null
			}
     </div> */}

          {invoice_title_type.value == 'org' ? (
            type === 'update' ? (
              <div>
                <div className="kb-row">
                  <KBInput
                    className={oneClass.className}
                    field={invoice_title}
                    isRequired={true}
                    title="发票抬头"
                  />
                </div>
                <div className="kb-row">
                  <KBInput
                    className={oneClass.className}
                    field={tax_num}
                    isRequired={true}
                    title="纳税人识别号"
                  />
                </div>
                <div className="kb-row">
                  <KBInput
                    className={oneClass.className}
                    field={bank}
                    isRequired={true}
                    title="开户行"
                  />
                </div>
                <div className="kb-row kb-form-66">
                  <KBInput
                    className="kb-form-group kb-div-c48"
                    title="地址"
                    field={address}
                  />
                  <KBInput
                    className="kb-form-group kb-div-c39"
                    title="电话"
                    titleClass="label-rt"
                    field={phone_num}
                  />
                </div>
              </div>
            ) : (
              (invoiceTitles.length > 0 &&
                invoiceTitles.map((title, index) => {
                  const {
                    tax_num,
                    bank,
                    bank_account,
                    contacts_name,
                    phone_num,
                    address,
                    email
                  } = title
                  return (
                    <div className="kb-row">
                      <div
                        key={index}
                        className={`provider-item ${
                          selectedTitle.id === title.id
                            ? 'provider-item_active'
                            : null
                        }`}
                        onClick={this.selectTitle.bind(null, title)}
                      >
                        <input
                          type="radio"
                          id={`providerRadio${index}`}
                          name="radio"
                          checked={
                            selectedTitle.id === title.id ? 'checked' : null
                          }
                        />
                        <div className="location-provider">
                          <div className="provider-line">
                            <div>
                              <span>发票类别</span>
                              <span>
                                {title.invoice_type
                                  ? title.invoice_type == 'common'
                                    ? '增值税普通发票'
                                    : '增值税专用发票'
                                  : '-'}
                              </span>
                            </div>
                          </div>
                          <div className="provider-line">
                            <div>
                              <span>发票抬头</span>
                              <span>{title.title || '-'}</span>
                            </div>
                            <div>
                              <span>纳税人识别号</span>
                              <span>{tax_num || '-'}</span>
                            </div>
                          </div>
                          <div className="provider-line">
                            <div>
                              <span>开户行</span>
                              <span>{bank || '-'}</span>
                            </div>
                            <div>
                              <span>银行账号</span>
                              <span>{bank_account || '-'}</span>
                            </div>
                          </div>
                          <div className="provider-line">
                            <div>
                              <span>联系人</span>
                              <span>{contacts_name || '-'}</span>
                            </div>
                            <div>
                              <span>联系电话</span>
                              <span>{phone_num || '-'}</span>
                            </div>
                          </div>
                          <div className="provider-line">
                            <div>
                              <span>地址</span>
                              <span>{address || '-'}</span>
                            </div>
                            <div>
                              <span>邮箱</span>
                              <span>{email || '-'}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })) || (
                <div
                  className="t-center"
                  style={{ marginTop: 50, color: '#999' }}
                >
                  <img
                    src={'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/que.png'}
                    style={{ width: 150 }}
                    alt=""
                  />
                  <div style={{ marginTop: '15px' }}>暂无发票抬头信息</div>
                  <div
                    className="bordered-btn m-top m-bottom"
                    onClick={this.addNewInvoiceTitle}
                  >
                    点击添加
                  </div>
                </div>
              )
            )
          ) : (
            <div className="kb-row">
              <KBInput
                className={oneClass.className}
                field={email}
                title="邮箱"
              />
            </div>
          )}

          {invoice_title_type.value == 'org' ? (
            <div className="kb-row">
              <div className={oneClass.className}>
                <label>
                  发票内容<span className="must-fill">*</span>
                </label>
                <div>
                  <div className="kb-form-group r1_radios">
                    {invoiceContent.map((data, index) => {
                      return (
                        <div
                          className="kb-form-group r1_radio"
                          onClick={this.changeInvoiceContent.bind(
                            null,
                            data.value
                          )}
                          key={index}
                        >
                          <div
                            className={`radio-btn ${
                              selectedContent == data.value
                                ? 'radio-btn-active'
                                : ''
                            }`}
                          ></div>
                          <label className="label">{data.title}</label>
                        </div>
                      )
                    })}
                  </div>
                  {invoice_content.touched &&
                  invoice_content.error &&
                  selectedContent !== '其他费用' ? (
                    <p className="lr-error">{invoice_content.error}</p>
                  ) : (
                    ''
                  )}
                </div>
                {invoiceContentArray.includes(selectedContent) ? null : (
                  <KBInput
                    className={oneClass.className}
                    field={invoice_content}
                    title=" "
                  />
                )}
              </div>
            </div>
          ) : null}
        </div>
        <div className="kb-row fixed-form-btn">
          <KBButton
            className="certain-btn m-left-sm"
            type="submit"
            submitting={submitting}
            onClick={() => {
              this.setState({ clickNext: false })
            }}
          >
            {type == 'update' ? '保存' : '申请开票并关闭'}
          </KBButton>
          {length && length > 1 ? (
            <KBButton
              className="certain-btn m-left-sm"
              type="submit"
              submitting={submitting}
              onClick={() => {
                this.setState({ clickNext: true })
              }}
            >
              申请开票并下一个
            </KBButton>
          ) : null}
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const locations = selectors.getSerializeLocation(state)
  return {
    locations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  //   if (valid.required(values.invoice_title) && values.invoice_title_type == 'org') {
  //     errors.invoice_title = '请填写发票抬头'
  //   }

  //   if (valid.required(values.tax_num) && values.invoice_title_type == 'org') {
  //     errors.tax_num = '请填写纳税人识别号'
  //   }

  //   if (valid.required(values.bank) && values.invoice_title_type == 'org') {
  //     errors.bank = '请填写发票开户行'
  //   }

  if (
    valid.required(values.invoice_content) &&
    values.invoice_title_type == 'org'
  ) {
    errors.invoice_content = '请选择或填写发票内容'
  }

  return errors
}

const formConfig = {
  form: 'InvoicesApplication',
  fields: [
    'id',
    'location_id',
    'invoice_ids',
    'invoice_title',
    'invoice_content',
    'bank',
    'address',
    'phone_num',
    'tax_num',
    'invoice_title_type',
    'email',
    'invoicing_type'
  ],
  validate: validate,
  touchOnBlur: false
}

InvoicesApplication = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoicesApplication)

export default InvoicesApplication
