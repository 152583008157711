import React from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { connect } from 'react-redux'
import {
  KBDropdown,
  KBPagination,
  KBLoadingContainer,
  KBRangeDate,
  KBStatisticNumber,
  KBPopover,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import { KBDropSelect } from 'components/plugins'
import { routerActions } from 'react-router-redux'
import { apiCallFailure, invoicesActions } from 'app/actions'
import {
  getServiceErrorMessage,
  fmoney,
  queryFilter,
  paginate,
  arrayToObject,
  getFilterAndQuery,
  kbCloseDropDown
} from 'utils/kbUtil'
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts'
import {
  INVOICES_STATE,
  INVOICES_UPDATE_STATE,
  INVOICES_STATE_CLASS
} from 'app/constants'
import moment from 'moment'
import * as selectors from 'app/selectors'
import { KBPopoverConfirm } from 'components/tools'

const DROP_DOWNS = ['invoiceStatus', 'location_idDropdown', 'dateDropDown']

const tdStyle = {
  paddingTop: '140px',
  textAlign: 'center',
  color: '#6e6e6e',
  fontSize: '16px',
  opacity: 0.8,
  cursor: 'default'
}

const scale = {
  month: {
    type: 'cat'
  },
  value: {
    min: 0
  }
}

var DepositList = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      depositStatistic: null,
      depositData: [],
      filters,
      page: this.props.page || 1,
      invoices: [],
      loading: false,
      invoiceLoading: false
    }
  },

  componentDidMount() {
    this.mounted = true
    const { location_id } = this.props
    const startDate = moment()
      .set('date', 1)
      .format('YYYY-MM-DD')
    const endDate = moment()
      .add(8, 'months')
      .endOf('month')
      .format('YYYY-MM-DD')
    this.setState({ loading: true })
    api.getInvoicesDepositStatistic(location_id ? { location_id } : null).then(
      ({ json }) => {
        this.setState({
          depositStatistic: json
        })
      },
      error => {
        const errorMessage = getServiceErrorMessage(error)
        errorMessage &&
          this.props.apiCallFailureActions({
            status: 'error',
            message: errorMessage
          })
      }
    )
    api
      .getInvoicesMonthlyReceivableDepositStatistic(
        Object.assign(
          {},
          {
            location_id,
            start_date: startDate,
            end_date: endDate
          }
        )
      )
      .then(
        ({ json }) => {
          let depositData = []
          json &&
            Object.keys(json).map(key => {
              depositData.push({
                key: key,
                month: `${moment(key).month() + 1}月`,
                value: json[key]
              })
            })
          this.setState({
            depositData
          })
        },
        error => {
          const errorMessage = getServiceErrorMessage(error)
          errorMessage &&
            this.props.apiCallFailureActions({
              status: 'error',
              message: errorMessage
            })
        }
      )
    this._loadData()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.page) {
      return this._loadData({}, nextProps.page)
    }
  },

  _loadData(model, newPage) {
    const per_page = 10
    const { getInvoicesActions, routerActions, query, location_id } = this.props
    const { page, filters } = this.state
    let params = Object.assign({}, model, filters)
    let { from, to } = params
    if (from && to) {
      params.from = from && moment(from).format('YYYY-MM-DD')
      params.to = to && moment(to).format('YYYY-MM-DD')
    } else {
      delete params.from
      delete params.to
    }
    let queryFiltersStr = queryFilter({
      ...query,
      ...params,
      page: newPage || page
    })

    if (location_id) {
      params.location_id = location_id
    }

    this.setState({ invoiceLoading: true })
    return api
      .getInvoices({
        ...params,
        per_page,
        page: newPage || page,
        invoice_type: 'deposit,refund'
      })
      .then(
        ({ json, response }) => {
          const { invoices } = json
          let pagination = paginate(response)
          getInvoicesActions.success({
            entities: { invoices: arrayToObject(invoices) }
          })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin${
              location_id ? `/locations/${location_id}` : ''
            }/invoices/deposit/${queryFiltersStr}`
          )

          if (this.mounted) {
            this.setState({
              invoices: invoices || [],
              pagination,
              queryFiltersStr
            })
          }
          return true
        },
        errors => {
          getInvoicesActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false, invoiceLoading: false })
      })
  },

  _clearFilters() {
    this.setState({ filters: {}, page: 1 })
    //this.refs.dropDownOrg.clearInput()
    this.invoiceStatus.clearData()
    this._refresh()
  },

  _setFilter(obj) {
    let newFilt = obj || {}
    let newFilters = Object.assign({}, this.state.filters, newFilt)
    this.setState({ filters: newFilters, page: 1 })

    // force refresh
    this._refresh()
  },

  _refresh() {
    this['location_idDropdown'] && this['location_idDropdown'].hide()
    this['invoiceStatus'] && this['invoiceStatus'].hide()

    setTimeout(this._loadData)
  },

  selectInvoiceStatusCallBack(data) {
    this._setFilter({
      status: data.map(json => json.id).join(',')
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  changeDate(range) {
    this._setFilter(range)
  },

  toOrgInfo(invoice) {
    const { location, sales_customer } = invoice
    const { routerActions } = this.props
    routerActions.push(
      `/admin/locations/${location.id}/organizations/${sales_customer.foreign_id}`
    )
  },

  toInvoiceInfo(invoice) {
    const { routerActions, location_id } = this.props
    routerActions.push(
      `/admin${
        location_id ? `/locations/${location_id}` : ''
      }/invoices/invoices_list/${invoice.id}`
    )
  },

  _renderFilters() {
    const { from, to } = this.props.filters
    const { locations, location_id, defaultInvoiceStatus } = this.props
    return (
      <div className="nav-section-action-bar">
        <div className="select-progress">
          <KBDropSelect
            selectedData={defaultInvoiceStatus}
            ref={ref => {
              this.invoiceStatus = ref
            }}
            options={{ defaultSelectPrompt: '选择进度' }}
            defaultData={INVOICES_UPDATE_STATE}
            multi={true}
            showImg={false}
            callback={this.selectInvoiceStatusCallBack}
            dropDown_name="invoiceStatus"
            closeClick={this.closeDropDowns}
            style={{ marginLeft: 0 }}
          />
        </div>
        {location_id && Object.keys(locations).length > 0 ? (
          <div
            className="task-status-title"
            style={{ display: 'inline-block' }}
          >
            <i className="iconfont icon-position" />
            <span>{locations[location_id].name || ''}</span>
          </div>
        ) : (
          <KBDropdown
            ref={ref => {
              this.location_idDropdown = ref
            }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'location_idDropdown')}
              >
                <i className="iconfont icon-position" />
                <span>
                  {this.state.filters.location_id &&
                  Object.keys(locations).length > 0
                    ? locations[this.state.filters.location_id].name
                    : '选择分店'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li onClick={() => this._setFilter({ location_id: '' })}>
                    全部
                  </li>
                  {locations &&
                    Object.keys(locations).map((location_id, index) => {
                      let location = locations[location_id]
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ location_id: location.id })
                          }
                        >
                          <span>{location.name}</span>
                        </li>
                      )
                    })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        )}
        <KBRangeDate
          format="YYYY/MM/DD"
          from={from || ''}
          to={to || ''}
          callback={this.changeDate}
          closeClick={this.closeDropDowns}
          dropDown_name="dateDropDown"
          ref={ref => {
            this.dateDropDown = ref
          }}
        />
      </div>
    )
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="账单核销统计导出"
        message="账单核销统计列表已成功导出，可以前往报表中心下载"
      />
    )
  },

  depositInvoiceReport() {
    const { filters } = this.state
    const { location_id, apiCallFailureActions } = this.props

    KBPopoverConfirm({
      name: '导出保证金统计表格',
      context:
        '您即将导出当前列表筛选条件下的保证金统计表格，是否确认导出？（默认导出未来一年的保证金统计）',
      callback: () => {
        const params = Object.assign(
          {},
          filters,
          location_id ? { location_id } : {}
        )
        if (!(params.from && params.to)) {
          params.start_date = moment().format('YYYY-MM-DD')
          params.end_date = moment()
            .add(1, 'years')
            .format('YYYY-MM-DD')
        } else {
          params.start_date = moment(params.from).format('YYYY-MM-DD')
          params.end_date = moment(params.to).format('YYYY-MM-DD')
          delete params.from
          delete params.to
        }
        return api.exportDepositLineItems(params).then(
          () => {
            this.toReportCenter && this.toReportCenter()
            KBPopover.close()
          },
          error => {
            apiCallFailureActions({
              status: 'error',
              message: error.message || '服务器出问题了，请稍后重试哦~'
            })
            KBPopover.close()
          }
        )
      }
    })
  },

  render() {
    const {
      depositStatistic,
      depositData,
      loading,
      invoices,
      pagination,
      queryFiltersStr,
      invoiceLoading
    } = this.state
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')

    return (
      <KBLoadingContainer loading={loading}>
        <div className="nav-section-content-container">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                padding: '44px 50px',
                backgroundColor: '#fff',
                flex: 1,
                borderRight: '20px solid #EFF3F6'
              }}
            >
              <div
                className="chart-out_box"
                style={{
                  marginBottom: 75,
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <div className="chart-box">
                  <KBStatisticNumber
                    number={fmoney(
                      (depositStatistic &&
                        depositStatistic.deposit_total_amount) ||
                        0,
                      2
                    )}
                    name="应收保证金"
                    preUnit="¥"
                  />
                </div>
                <div className="chart-box">
                  <KBStatisticNumber
                    number={fmoney(
                      (depositStatistic &&
                        depositStatistic.deposit_paid_amount) ||
                        0,
                      2
                    )}
                    name="已收保证金"
                    preUnit="¥"
                  />
                </div>
                <div className="chart-box">
                  <KBStatisticNumber
                    number={fmoney(
                      (depositStatistic &&
                        depositStatistic.deposit_unpaid_amount) ||
                        0,
                      2
                    )}
                    name="待收收保证金"
                    preUnit="¥"
                  />
                </div>
              </div>
              <div
                className="chart-out_box"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div className="chart-box">
                  <KBStatisticNumber
                    number={fmoney(
                      (depositStatistic &&
                        depositStatistic.deposit_refund_amount) ||
                        0,
                      2
                    )}
                    name="应退保证金"
                    preUnit="¥"
                  />
                </div>
                <div className="chart-box">
                  <KBStatisticNumber
                    number={fmoney(
                      (depositStatistic &&
                        depositStatistic.deposit_refunded_amount) ||
                        0,
                      2
                    )}
                    name="已退保证金"
                    preUnit="¥"
                  />
                </div>
                <div className="chart-box">
                  <KBStatisticNumber
                    number={fmoney(
                      (depositStatistic &&
                        depositStatistic.deposit_unrefunded_amount) ||
                        0,
                      2
                    )}
                    name="待退保证金"
                    preUnit="¥"
                  />
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: '#fff', width: 340, padding: 20 }}>
              <div style={{ fontSize: 18, color: '#354052', marginBottom: 10 }}>
                应收保证金预测
              </div>
              <Chart
                height={250}
                padding={[30, 'auto']}
                data={depositData}
                scale={scale}
                forceFit
              >
                <Axis name="month" />
                <Axis name="value" visible={false} />
                <Legend visible={false} />
                <Tooltip />
                <Geom
                  type="interval"
                  position="month*value*key"
                  color={['value', ['#00AAFF']]}
                  tooltip={[
                    'month*value*key',
                    (month, value, key) => {
                      return {
                        name: '应收',
                        value: '¥' + fmoney(value, 2),
                        title: key
                      }
                    }
                  ]}
                />
              </Chart>
            </div>
          </div>
          <div style={{ backgroundColor: '#fff', marginTop: 20 }}>
            <header className="nav-header">
              <div className=" clear-fix">
                <div className="nav-section-header-title f-left">
                  <span>保证金</span>
                </div>
                <div className="f-right">
                  <button
                    className="c-btn-secondary"
                    onClick={this.depositInvoiceReport}
                  >
                    导出保证金账单
                  </button>
                </div>
              </div>
            </header>
            <div style={{ padding: '0 20px' }}>
              {this._renderFilters()}
              {selectFilters.length > 0 ? (
                <div
                  className="clear-criteria"
                  style={{ marginTop: 5 }}
                  onClick={this._clearFilters}
                >
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              ) : null}
            </div>
            <div style={{ padding: '20px' }}>
              <KBLoadingContainer loading={invoiceLoading}>
                <table className="content-table table-align">
                  <thead style={{ whiteSpace: 'nowrap' }}>
                    <tr>
                      <th>公司名称</th>
                      <th>所属账单</th>
                      <th>收款状态</th>
                      <th>应付金额</th>
                      <th>已收金额</th>
                      <th>待付金额</th>
                    </tr>
                  </thead>
                  {invoices && invoices.length ? (
                    invoices.map((invoice, index) => {
                      let isCheckPaid =
                        invoice && invoice.check_status === 'checked'
                      let isConfirmed =
                        invoice && invoice.check_status === 'confirmed'
                      let depositItem =
                        (invoice &&
                          invoice.sales_invoice_line_items &&
                          invoice.sales_invoice_line_items[0]) ||
                        {}
                      return (
                        <tbody>
                          <tr>
                            <td>
                              <div
                                className="t-over color-link a-hover"
                                style={{ maxWidth: 100 }}
                                onClick={() => {
                                  this.toOrgInfo(invoice)
                                }}
                              >
                                {invoice.sales_customer.name}
                              </div>
                              <div
                                style={{
                                  color: '#7F8FA4',
                                  fontSize: 12,
                                  marginTop: 5
                                }}
                              >
                                {invoice.location.name}
                              </div>
                            </td>
                            <td>
                              <div
                                className="color-link a-hover"
                                onClick={() => {
                                  this.toInvoiceInfo(invoice)
                                }}
                              >
                                {invoice.serial_number}
                              </div>
                            </td>
                            <td>
                              <span
                                style={{ marginLeft: 0, padding: '5px 6px' }}
                                className={`finance-default ${
                                  isConfirmed || isCheckPaid
                                    ? 'receipt-confirmed'
                                    : INVOICES_STATE_CLASS[invoice.status]
                                }`}
                              >
                                {isConfirmed
                                  ? '确认核销'
                                  : isCheckPaid
                                  ? '已核销'
                                  : invoice.status === 'paid'
                                  ? '已收款'
                                  : INVOICES_STATE[invoice.status]}
                              </span>
                            </td>
                            <td>
                              ¥&nbsp;{fmoney(depositItem.total_amount || 0)}
                            </td>
                            <td>
                              ¥&nbsp;{fmoney(depositItem.paid_amount || 0)}
                            </td>
                            <td>
                              ¥&nbsp;
                              {fmoney(
                                depositItem.total_amount -
                                  depositItem.paid_amount
                              )}
                            </td>
                          </tr>
                        </tbody>
                      )
                    })
                  ) : (
                    <tbody>
                      <tr style={{ border: 0, backgroundColor: '#ffffff' }}>
                        <td style={tdStyle} colSpan="9">
                          暂无数据
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                <KBPagination
                  pagination={pagination}
                  template={`/admin${
                    this.props.location_id
                      ? `/locations/${this.props.location_id}`
                      : ''
                  }/invoices/deposit${
                    queryFiltersStr
                      ? queryFiltersStr + '&page=#PAGE#'
                      : '?page=#PAGE#'
                  }`}
                />
              </KBLoadingContainer>
            </div>
          </div>
        </div>
      </KBLoadingContainer>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let page = parseInt(location.query && location.query.page, 10)
  let locations = selectors.getSerializeLocationObj(state) || {}
  page = isNaN(page) ? 1 : page
  var FILTER_STATE = ['status', 'from', 'to', 'location_id', 'customer_id']
  var params = getFilterAndQuery(location.query, FILTER_STATE)
  let defaultInvoiceStatus = []
  if (params.filters && params.filters.status) {
    let statuses = params.filters.status.split(',')
    defaultInvoiceStatus = INVOICES_UPDATE_STATE.filter(data =>
      statuses.find(id => id == data.id)
    )
  }
  const location_id = props.params.id

  return {
    defaultInvoiceStatus,
    page,
    ...params,
    locations,
    location_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    getInvoicesActions: bindActionCreators(invoicesActions.all, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DepositList)
