import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { routerActions } from 'react-router-redux'
import { reduxForm } from 'redux-form'
import { api, apiUtils } from 'app/services'
import { topicsActions, successState } from 'app/actions'
import {
  KBLoadingContainer,
  KBInput,
  KBUserInput,
  KBPopover,
  KBButton
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { connect } from 'react-redux'
import { OPPORTUNITY_STATES } from 'app/constants'
import { formatYearDayEN, formatMinuteSecondEN } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import * as valid from 'utils/validate'
import moment from 'moment'
import * as constants from 'app/constants'
import KBEditor from 'components/plugins/KBEditor'

/**
 * 编辑文章内容
 */
var FaqTopicsForm = React.createClass({
  displayName: 'FaqTopicsForm',
  getInitialState() {
    return {
      editStatus: this.props.isLinkWay
    }
  },

  componentDidMount() {
    const { section_id, topics_id, getTopicsActions } = this.props

    if (!section_id || !topics_id) {
      return
    }

    api.getTopicsInfo(section_id, topics_id).then(
      json => {
        getTopicsActions.success(json)
      },
      errors => {
        getTopicsActions.failure(errors)
      }
    )
  },

  componentWillReceiveProps(nextProps) {
    const { isLinkWay } = nextProps
    if (this.props.isLinkWay != isLinkWay) {
      this.setState({
        editStatus: isLinkWay
      })
    }
  },

  createTopicsForm(model) {
    const {
      createTopicsActions,
      updateTopicsActions,
      successAction,
      section_id,
      isEdit,
      topics,
      routerActions
    } = this.props
    const { editStatus } = this.state
    var params = Object.assign({}, model, {
      state: model.state ? 'active' : 'hidden'
    })

    /**
     * 判断状态
     * 如果为true 就是 外站文章链接
     * 删除 文本内容
     * 如果为false 就是 文本模式
     * 删除 链接
     */
    if (editStatus) {
      delete params.body
      delete params.body_code
      delete params.body_text
    } else {
      delete params.url
    }

    if (isEdit) {
      return api.updateTopics(section_id, topics.id, params).then(json => {
        updateTopicsActions.success(json)
        successAction({ message: '编辑成功!' })
        routerActions.push(`/admin/faq/sections`)
        return true
      })
    }

    return api.createTopics(section_id, params).then(json => {
      createTopicsActions.success(json)
      successAction({ message: '创建成功!' })
      routerActions.push(`/admin/faq/sections`)
      return true
    })
  },

  selectEditWay() {
    const {
      fields: { url }
    } = this.props
    this.setState({ editStatus: false })
  },

  selectLinkWay() {
    this.setState({ editStatus: true })
  },

  cancelEdit() {
    const { routerActions } = this.props
    routerActions.push(`/admin/faq`)
  },

  draftCallBack(html) {
    const {
      fields: { body }
    } = this.props
    body.onChange(html)
  },

  changeSlide() {
    const {
      fields: { state }
    } = this.props
    state.onChange(!state.value)
  },

  deleteTopics() {
    const { section_id, topics } = this.props
    const { deleteTopicsActions, routerActions } = this.props
    const topics_id = topics.id
    KBPopoverConfirm({
      name: '删除文章',
      context: `是否要删除文章` + topics.title,
      callback: () => {
        return api.deleteTopics(section_id, topics_id).then(json => {
          deleteTopicsActions.success(json, { id: topics_id, key: 'topics' })
          KBPopover.close()
          routerActions.push(`/admin/faq`)
          return true
        })
      }
    })
  },

  render() {
    const {
      fields: { title, body, body_text, body_code, state, url, position },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { editStatus, editorState } = this.state
    const { isEdit, isEditWay, topics, rawData } = this.props

    return (
      <form
        onSubmit={handleSubmit(this.createTopicsForm)}
        style={{ height: '100%' }}
      >
        <section className="nav-section-container  f-right">
          <header className="nav-header clear-fix">
            <div className="nav-section-header-title">
              <span>{isEdit ? '编辑文章' : '创建文章'}</span>
            </div>
          </header>
          <div className="nav-section-content-container m-top p-bottom kb-form-container">
            <header className="item-header">
              <span>标题</span>
              <input type="text" {...title} placeholder="输入标题" />
              <span className="item-time">
                {isEdit
                  ? '上次编辑时间：' + formatMinuteSecondEN(topics.updated_at)
                  : moment().format('YYYY-MM-DD')}
              </span>
              {title.touched && title.error ? (
                <p className="lr-error" style={{ paddingTop: 10 }}>
                  {title.error}
                </p>
              ) : (
                ''
              )}
            </header>
            <div className="select-way border-none">
              <span className="color-three">类型</span>
              <div className="edit-way">
                <span
                  onClick={this.selectLinkWay}
                  className={editStatus ? 'active' : ''}
                >
                  链接
                </span>
                <span
                  onClick={this.selectEditWay}
                  className={editStatus ? '' : 'active'}
                >
                  原创
                </span>
              </div>
            </div>
            {editStatus ? (
              <div className="user-link">
                <span className="title">链接</span>
                <textarea type="text" {...url} />
              </div>
            ) : (
              <KBEditor callBack={this.draftCallBack} defaultData={rawData} />
            )}
            {state.touched && state.error ? (
              <p className="lr-error">{state.error}</p>
            ) : (
              ''
            )}
            <div style={{ width: '100%', marginTop: '20px' }}>
              <KBInput
                className="kb-form-group kb-form-alone faq-position-input"
                field={position}
                title="位置"
                type="number"
              />
            </div>
            <div className="item-option">
              <span className="m-right">权限</span>
              <div
                className="slide-btn"
                onClick={this.changeSlide}
                style={{ display: 'flex' }}
              >
                <span
                  className={`slide-bg ${
                    state.value ? 'slide-left' : 'slide-right'
                  }`}
                >
                  <span className="slide-move" />
                </span>
              </div>
              <span className="color-nine" style={{ marginLeft: 10 }}>
                {state.value ? '用户可见' : '用户不可见'}
              </span>
            </div>
            <footer className="m-top t-right">
              <span className="cancel-btn" onClick={this.cancelEdit}>
                取消
              </span>
              <KBButton
                className="certain-btn m-left-sm"
                type="submit"
                submitting={submitting}
              >
                {isEdit ? '保存' : '创建'}
              </KBButton>
              {isEdit ? (
                <button
                  type="button"
                  className="certain-delete-btn m-left-sm"
                  onClick={this.deleteTopics}
                >
                  删除
                </button>
              ) : (
                ''
              )}
            </footer>
          </div>
        </section>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { location, params } = props
  var topics = null

  const section_id = params.section_id
  const topics_id = params.topics_id

  topics = selectors.getTopicsObjBySectionAndId(state, section_id, topics_id)

  const isEdit = topics && (topics.body || topics.body_code || topics.url)
  const isEditWay = isEdit ? (topics.url ? false : true) : false
  const isLinkWay = isEdit ? (topics.url ? true : false) : true
  // const isLinkWay = false

  var data = {
    section_id,
    topics_id,
    isEdit,
    isEditWay,
    isLinkWay
  }

  /**
   * 判断是否为编辑状态
   */
  if (isEdit) {
    data.initialValues = {
      ...topics,
      url: topics.url || '',
      state: topics.state == 'active' ? true : false
    }

    data.topics = topics
  }
  /**
   * 判断是否为文本(draft)[编辑]
   */
  if (isEditWay) {
    try {
      data.rawData = topics.body || ''
    } catch (err) {
      data.rawData = ''
    }
  }

  return data
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    createTopicsActions: bindActionCreators(topicsActions.create, dispatch),
    getTopicsActions: bindActionCreators(topicsActions.get, dispatch),
    updateTopicsActions: bindActionCreators(topicsActions.update, dispatch),
    deleteTopicsActions: bindActionCreators(topicsActions.delete, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.title)) {
    errors.title = '请输入标题'
  }

  if (valid.required(values.position)) {
    errors.position = '请输入位置数'
  }

  if (!values.body && !values.url) {
    errors.state = '内容和链接必须填一个'
  }

  return errors
}

const formConfig = {
  form: 'FaqTopicsForm',
  fields: [
    'title',
    'body',
    'body_text',
    'body_code',
    'state',
    'url',
    'position'
  ],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(FaqTopicsForm)
