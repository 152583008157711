import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  KBInput,
  KBButton,
  KBPopoverTop,
} from 'components'

import { apiCallFailure } from 'app/actions'
import { bindActionCreators } from 'redux'

var RepairForm = React.createClass({
  getInitialState() {
    return {
      classifyList: [],
      taxList: []
    }
  },

  createLocationProvider(model) {
    const { confirm } = this.props
    console.log(model)
    confirm && confirm(model)
    KBPopoverTop.close()
  },

  render() {
    const {
      fields: { path },
      submitting,
      handleSubmit,
    } = this.props


    return (
      <form onSubmit={handleSubmit(this.createLocationProvider)}>
        <header className="kb-form-header">
          <h2 className="header-title">
            设置URL
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopoverTop.close}
          />
        </header>
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-div-c178 kb-form-alone"
                field={path}
                title="H5链接"
                isRequired={true}
              />
              <div
                style={{
                  fontSize: '12px',
                }}
              >
                下载
                <span
                  style={{
                    color: '#1890ff',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                  onClick={() =>
                    this.downloadFile(
                      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/uploads/static/a2bMyBmNRj.txt',
                      '校验文件'
                    )
                  }
                >
                  校验文件
                </span>
                <span
                  style={{
                    color: '#F62D51',
                    marginLeft: '20px'
                  }}
                >
                  注意：请将此校验文件配置到合作方域名根目录下后，联系小程序管理员在小程序后台添加域名白名单
                </span>
              </div>
            </div>
          </fieldset>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopoverTop.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
              id="locationFormButton"
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { appModule } = props

  return {
    initialValues: {
      path: appModule && appModule.path,
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.path)) {
    errors.path = '请输入H5链接'
  }
  return errors
}

const formConfig = {
  form: 'RepairForm',
  fields: [
    'path',
  ],
  validate: validate,
  touchOnBlur: false
}

RepairForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(RepairForm)

export default RepairForm
