import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import { topicsActions } from 'app/actions'
import { KBLoadingContainer, KBAvatar, KBPopover } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { connect } from 'react-redux'
import { OPPORTUNITY_STATES } from 'app/constants'
import { util, momentFromNow } from 'utils/kbUtil'
import * as selectors from 'app/selectors'

var FaqSectionTopics = React.createClass({
  displayName: 'FaqSectionTopics',
  getInitialState() {
    return {}
  },

  componentDidMount() {},

  deleteItem(topics) {
    const { id } = this.props
    const { deleteTopicsActions } = this.props
    const topics_id = topics.id
    KBPopoverConfirm({
      name: '删除文章',
      context: `是否要删除文章` + topics.title,
      callback: () => {
        return api.deleteTopics(id, topics_id).then(json => {
          deleteTopicsActions.success(json, { id: topics_id, key: 'topics' })
          KBPopover.close()
          return true
        })
      }
    })
  },

  editTopics(topics) {
    const { routerActions, id } = this.props

    routerActions.push(`/admin/faq/sections/${id}/edit/${topics.id}`)
  },

  render() {
    const { topics, id } = this.props
    return (
      <div>
        <ul className="faq-list">
          {topics.map((item, index) => {
            return (
              <li key={index}>
                <div
                  className="faq-list-content"
                  onClick={this.editTopics.bind(null, item)}
                >
                  <span className="title">{item.title}</span>
                  <span
                    className="badge"
                    style={
                      item.state === 'active'
                        ? { marginLeft: 5, color: '#333', borderColor: '#333' }
                        : { marginLeft: 5, color: '#999', borderColor: '#999' }
                    }
                  >
                    {item.state === 'active' ? '已发布' : '未发布'}
                  </span>
                  <i
                    className="iconfont icon-delete"
                    onClick={e => {
                      this.deleteItem(item)
                      e.stopPropagation()
                    }}
                  />
                </div>
              </li>
            )
          })}
        </ul>
        <div style={{ marginLeft: 60, marginTop: 15 }}>
          <Link
            to={`/admin/faq/sections/${id}/edit`}
            className="bordered-btn d-iblock"
            style={{ marginLeft: 0 }}
          >
            <i className="iconfont icon-add" />
            添加新项目
          </Link>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { section } = props
  const { id } = section
  const topics = selectors.getTopicsBySection(state, id)
  topics.sort(function(a, b) {
    return a.position - b.position
  })
  return {
    topics,
    id
  }
}

FaqSectionTopics.propTypes = {
  section: PropTypes.object.isRequired
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    getTopicsActions: bindActionCreators(topicsActions.get, dispatch),
    deleteTopicsActions: bindActionCreators(topicsActions.delete, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqSectionTopics)
