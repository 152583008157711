import React from 'react'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import {
  paginate,
  queryFilter,
  getFilterAndQuery,
  formatMinuteSecondEN,
  kbCloseDropDown,
  getServiceErrorMessage,
  encryptionPhone
} from 'utils/kbUtil'
import { apiCallFailure, successState } from 'app/actions'
import {
  KBPopover,
  KBPagination,
  KBLoadingContainer,
  KBTableWithoutData,
  KBAvatar,
  KBPopoverTop,
  KBToReportCenter,
  KBRangeDate,
  KBTable
} from 'components'
import { canAccessLocation } from 'app/reducers/role'
import moment from 'moment'
import TableSearch from 'components/v2/TableSearch'
import { Icon, Dropdown, Menu } from 'antd'
import ReportData from './ReportData'

const allStatus = [
  { label: '待激活', value: 'pending' },
  { label: '已认证', value: 'active' },
  { label: '已拒绝', value: 'rejected' }
]

var LockEntryRecord = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      filters,
      loading: false,
      data: [],
      pagination: null,
      locations: [],
      organizations: [],
      tableColumns: [
        { title: '姓名', dataIndex: 'name', key: 'name' },
        { title: '邮箱', dataIndex: 'email', key: 'email' },
        { title: '分店', dataIndex: 'location_name', key: 'location_name' },
        {
          title: '公司',
          dataIndex: 'organization_name',
          key: 'organization_name'
        },
        {
          title: '手机号',
          dataIndex: 'secure_phone_num',
          key: 'phone_num',
          width: 150,
          render: rowItem => {
            const phone = rowItem.phone_num || ''
            if (!rowItem.status_str.includes('待'))
              return rowItem.secure_phone_num
            return (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                {rowItem.openEye ? phone : encryptionPhone(phone)}
                {rowItem.openEye ? (
                  <Icon type="eye" onClick={() => this.toggleEye(rowItem)} />
                ) : (
                  <Icon
                    type="eye-invisible"
                    onClick={() => this.toggleEye(rowItem)}
                  />
                )}
              </div>
            )
          }
        },
        {
          title: '申请状态',
          dataIndex: 'status_str',
          key: 'status_str',
          width: 90,
          render: rowItem => {
            const { id, status_str, can_operate } = rowItem
            const disabled = !can_operate
            const menu = (
              <Menu onClick={({ key }) => this.action(key, id)}>
                <Menu.Item key="agree">通过</Menu.Item>
                <Menu.Item key="reject">拒绝</Menu.Item>
              </Menu>
            )
            return status_str.includes('待') ? (
              <Dropdown disabled={disabled} overlay={menu} trigger={['click']}>
                <a
                  style={disabled ? {} : { color: '#40a9ff' }}
                  onClick={e => e.preventDefault()}
                >
                  {status_str} <Icon type="down" />
                </a>
              </Dropdown>
            ) : (
              status_str
            )
          }
        },
        {
          title: '申请时间',
          dataIndex: 'created_at',
          key: 'created_at',
          width: 110,
          render: rowItem => {
            return formatMinuteSecondEN(rowItem.created_at)
          }
        },
        {
          title: '审核时间',
          dataIndex: 'approved_at',
          key: 'approved_at',
          width: 110,
          render: rowItem => {
            return formatMinuteSecondEN(rowItem.approved_at)
          }
        },
        {
          title: '操作人',
          dataIndex: 'operator_name',
          key: 'operator_name',
          width: 90
        },
        {
          title: '认证方式',
          dataIndex: 'auth_type_str',
          key: 'auth_type_str',
          width: 90
        }
      ],
      isInit: true
    }
  },

  toReportCenter() {
    // KBPopoverTop.show(
    //   <KBToReportCenter
    //     title="自助认证导出"
    //     message="自助认证列表已成功导出，可以前往报表中心下载"
    //   />
    // )
  },

  action(key, id) {
    const { filters, pagination = {} } = this.state
    const { successAction, apiCallFailureAction } = this.props
    api[key === 'agree' ? 'autoAuthPass' : 'autoAuthReject'](id)
      .then(res => {
        successAction({ message: '操作成功！' })
        this._loadData(pagination.current_page || 1, filters)
      })
      .catch(err => {
        apiCallFailureAction(err)
      })
  },

  toggleEye(item) {
    item.openEye = !item.openEye

    this.setState({
      data: [...this.state.data]
    })
  },

  exportList() {
    const { locations } = this.state
    const { loc_id } = this.props

    KBPopover.show(
      <ReportData loc_id={loc_id} allStatus={allStatus} locations={locations} />
    )
  },

  componentDidMount() {
    this.mounted = true
    this._loadData(1)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page !== nextProps.page) {
      this._loadData(nextProps.page, { ...this.state.filters, ...filters })
    }
  },

  _loadData(page, filters) {
    const { loc_id, apiCallFailureAction, query, routerActions } = this.props
    const per_page = 10
    let pageObj = { per_page, page }
    let params = Object.assign({}, pageObj, filters, {
      location_id: filters && filters.location_id ? filters.location_id : loc_id
    })
    let queryFiltersStr = queryFilter({ ...query, ...pageObj, ...filters })
    this.setState({ loading: true })
    api
      .getAutoAuthList(params)
      .then(
        json => {
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          if (loc_id) {
            routerActions.replace(
              `/admin/locations/${loc_id}/autoAuth${queryFiltersStr}`
            )
          } else {
            routerActions.replace(`/admin/autoAuth${queryFiltersStr}`)
          }
          let pagination = paginate(json.response)
          let data = (json.json.items || []).map(i => {
            return {
              ...i,
              openEye: false
            }
          })

          if (this.state.isInit) {
            const locations = (json.json.locations || []).map(i => ({
              label: i.name,
              value: i.id
            }))
            const organizations = (json.json.organizations || []).map(i => ({
              label: i.name,
              value: i.id
            }))
            this.setState({
              locations,
              organizations,
              isInit: false
            })
          }

          this.setState({
            pagination,
            data
          })
        },
        errors => {
          apiCallFailureAction({
            status: 'error',
            message: getServiceErrorMessage(errors)
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters })
    this._refresh()
  },

  _refresh() {
    setTimeout(this.showFiltered)
  },

  _clearFilters() {
    this.setState({ filters: {} })
    this._refresh()
  },

  showFiltered() {
    const { filters } = this.state

    let params = Object.assign({}, filters)

    this._loadData(filters.page || 1, { ...params })
    this.setState({
      ...params
    })
  },

  _renderFilters() {
    const { filters, locations, organizations } = this.state
    const { loc_id } = this.props
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters &&
      selectFilters.filter(filter => filter !== '' && filter != undefined)

    return (
      <div style={{ paddingTop: 25 }}>
        {!loc_id && (
          <TableSearch
            label="选择分店"
            value={filters.location_id}
            options={locations}
            onChange={val => this._setFilter({ location_id: val || undefined })}
          />
        )}
        <TableSearch
          label="选择公司"
          value={filters.organization_id}
          options={organizations}
          onChange={val =>
            this._setFilter({ organization_id: val || undefined })
          }
        />
        <TableSearch
          label="选择申请状态"
          value={filters.status}
          options={allStatus}
          onChange={val => this._setFilter({ status: val || undefined })}
        />
        {selectFilters.length > 0 ? (
          <div
            className="clear-criteria"
            style={{ marginTop: 0 }}
            onClick={this._clearFilters}
          >
            <i className="iconfont icon-close" />
            <span>清除所有筛选条件</span>
          </div>
        ) : null}
      </div>
    )
  },

  render() {
    const { loading, pagination, data } = this.state
    const { loc_id } = this.props

    return (
      <section className="kb-content-container clear-fix">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>自助认证申请列表</span>
          </h2>
          <div className="f-right">
            <button className="c-btn-secondary" onClick={this.exportList}>
              导出
            </button>
          </div>
        </header>
        <div className="nav-section-content-container">
          {this._renderFilters()}
          <KBLoadingContainer loading={loading}>
            <KBTable columns={this.state.tableColumns} dataSource={data} />
            <KBPagination
              pagination={pagination}
              template={
                loc_id
                  ? `/admin/locations/${loc_id}/autoAuth?page=#PAGE#`
                  : `/admin/autoAuth?page=#PAGE#`
              }
            />
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const loc_id = params.id

  let isManager = canAccessLocation(state.user, loc_id)

  let page = parseInt(location.query && location.query.page, 10)
  page = isNaN(page) ? 1 : page

  var FILTER_STATE = ['location_id', 'organization_id', 'status']
  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)

  return {
    loc_id,
    isManager,
    page,
    ...paramsAll
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockEntryRecord)
