import React, { Component, PropTypes } from 'react'
import { api } from 'app/services'
import { customerRecordsActions } from 'app/actions'
import classNames from 'classnames'
import { checkObject } from 'utils/kbUtil'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { KBLoadingContainer, KBDropdown } from 'components'
import { KBDropSelect } from 'components/plugins'
import * as valid from 'utils/validate'
import * as selectors from 'app/selectors'
import _ from 'lodash'
import { FromToDropdown } from 'components/views'
import CrmRecordRow from './CrmRecordRow'
import { getEntitiesJsonArray } from 'utils/kbUtil'
import { paginate, kbCloseDropDown } from 'utils/kbUtil'
import moment from 'moment'
import { CRM_OPPORTUNITY_STATES } from 'app/constants'

const DROP_DOWNS = ['userDropdown', 'oppStatusDropdown', 'dateDropDown']

var CrmRecordList = React.createClass({
  getInitialState() {
    return {
      loading: false,
      opp_id: null,
      paginate: null,
      next_page_text: '',
      filters: {},
      isSearch: false,
      records: null,
      queryText: ''
    }
  },

  componentDidMount() {
    this.mounted = true
    this._loadData()
  },

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.opportunity, nextProps.opportunity)) {
      this._loadData()
    }
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(page, options) {
    const { isAll } = this.props
    const PRE_PAGE = 10
    let { getRecordsAction, opportunity, opp_id } = this.props
    if (!opportunity || (!opportunity && !isAll)) {
      return
    }
    if (!opportunity.id && !opp_id) {
      return
    }

    const isSearch = (options && options.isSearch) || false
    const isAppend = (options && options.isAppend) || false

    this.setState({ loading: true })
    if (isAll) {
      return this.getCrmRecords(page, isSearch, isAppend)
    }

    api
      .getCrmRecordsByOpportunity(opportunity.id || opp_id, {
        per_page: PRE_PAGE,
        page: page || 1
      })
      .then(
        json => {
          let newRecords = getEntitiesJsonArray(json, 'crmRecords')
          let records = isAppend
            ? [...this.state.records, ...newRecords]
            : newRecords

          getRecordsAction.success(json)
          this.setState({
            paginate: paginate(json.response),
            records
          })
          return null
        },
        errors => {
          getRecordsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false, next_page_text: '' })
      })
  },

  /**
   * 当显示全部销售记录时Call getSearchCrmRecords
   */
  getCrmRecords(page, isSearch, isAppend) {
    const { isAll } = this.props
    const PRE_PAGE = 10
    let { getRecordsAction, opportunity } = this.props

    if (!opportunity && !isAll) {
      return
    }

    const { filters } = this.state
    var params = {}

    if (isSearch) {
      let { from, to, user, state } = filters
      let { queryText } = this.state
      let fromTo =
        from && to
          ? {
              start_time: moment(from).format('YYYY-MM-DD'),
              end_time: moment(to).format('YYYY-MM-DD')
            }
          : {}
      let user_id = user && user
      let opportunity_state = state && state.id

      params = Object.assign({}, fromTo, {
        user_id,
        opportunity_state,
        query: queryText
      })
    }

    api
      .getAllCrmRecords({ per_page: PRE_PAGE, page: page || 1, ...params })
      .then(
        json => {
          let newRecords = getEntitiesJsonArray(json, 'crmRecords')
          let records = isAppend
            ? [...this.state.records, ...newRecords]
            : newRecords

          getRecordsAction.success(json)
          this.setState({
            paginate: paginate(json.response),
            records,
            isSearch: isSearch ? true : false
          })
          return null
        },
        errors => {
          getRecordsAction.failure(errors)
        }
      )
      .finally(() => {
        if (this.mounted) {
          this.setState({ loading: false, next_page_text: '' })
        }
      })
  },

  nextRecordList() {
    const { paginate, isSearch } = this.state
    if (!paginate.next_page && isNaN(paginate.next_page)) {
      this.setState({
        next_page_text: '没有下一页了'
      })
      return
    }

    this.setState({
      next_page_text: '正在加载'
    })

    this._loadData(paginate.next_page, {
      isSearch: true,
      isAppend: true
    })
  },

  _refresh(key) {
    let dropDown = key + 'Dropdown'
    this[dropDown] && this[dropDown].hide()
    if (this.props.isAll) {
      setTimeout(() => {
        this.getCrmRecords(1, true, false)
      })
    }
  },

  searchRecords() {
    this._loadData(1, {
      isSearch: true,
      isAppend: false
    })
  },

  setFilter(filter) {
    let newFilters = Object.assign({}, this.state.filters, filter)
    this.setState({ filters: newFilters })
    if (this.props.isAll) {
      setTimeout(() => {
        this.getCrmRecords(1, true, false)
      })
    }
  },

  clearFilter() {
    this.setState({ filters: {}, queryText: '' })
    this.userDropdown.clearData()
    this._loadData(1, {
      isSearch: false,
      isAppend: false
    })
  },

  setQueryText(event) {
    let value = event.target.value

    if (value == ' ') {
      value = ''
    }

    this.setState({ queryText: value })
  },

  userSelectCallBack(data) {
    this.setFilter({
      user: data.id
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  filterRender() {
    const {
      filters: { from, to, user, state },
      queryText,
      isSearch
    } = this.state
    const { usersList } = this.props
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    return (
      <div className="m-updown">
        <div className="average-box">
          <FromToDropdown
            setFilter={this.setFilter}
            from={from}
            to={to}
            ref={ref => {
              this.dateDropDown = ref
            }}
            dropDown_name="dateDropDown"
            closeClick={this.closeDropDowns}
          />
          <KBDropSelect
            ref={ref => {
              this.userDropdown = ref
            }}
            singleIsCancel={false}
            defaultData={usersList}
            options={{ defaultSingleContent: '选择负责人' }}
            multi={false}
            dropDown_name="userDropdown"
            closeClick={this.closeDropDowns}
            callback={this.userSelectCallBack}
          />
          <KBDropdown
            ref={ref => {
              this.oppStatusDropdown = ref
            }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={() => {
                  this.closeDropDowns('oppStatusDropdown')
                }}
              >
                <i
                  className="iconfont icon-calendar"
                  style={{ marginRight: '3px' }}
                />
                <span>{state ? state.name : '销售阶段'}</span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <ul className="task-type">
                <li
                  onClick={() => {
                    this.setFilter({ state: '' })
                    this._refresh('oppStatus')
                  }}
                >
                  全部
                </li>
                {CRM_OPPORTUNITY_STATES &&
                  CRM_OPPORTUNITY_STATES.map((state, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          this.setFilter({ state })
                          this._refresh('oppStatus')
                        }}
                      >
                        <span>{state.name}</span>
                      </li>
                    )
                  })}
              </ul>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        </div>
        <div style={{ marginTop: 10 }} className="clear-fix">
          <div className="common-search f-left" style={{ width: 690 }}>
            <input
              type="text"
              value={queryText}
              onChange={this.setQueryText}
              placeholder="按关键词搜索"
            />
          </div>
          <span
            className="s-btn f-left"
            style={{
              marginLeft: 10,
              padding: '8px 20px',
              fontSize: 14,
              borderRadius: 100
            }}
            onClick={this.searchRecords}
          >
            搜索
          </span>
        </div>
        {selectFilters.length > 0 && isSearch ? (
          <div>
            <div className="clear-criteria" style={{ marginTop: 10 }}>
              <i className="iconfont icon-close" />
              <span onClick={this.clearFilter}>清除所有筛选条件</span>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  },

  render() {
    const { users, opps, customers, isAll } = this.props
    const { next_page_text, paginate, loading, records } = this.state

    return (
      <KBLoadingContainer loading={!records && this.state.loading}>
        <div className="nav-comment-container">
          {
            // isAll ? (
            //   this.filterRender()
            // ) : ''
          }
          {records &&
            records.map((record, index) => {
              let create_by = users[record.created_by]

              var opportunity = {}

              if (this.props.opportunity) {
                opportunity = this.props.opportunity
              } else {
                opportunity = opps[record.opportunity] || null
                if (opportunity) {
                  opportunity.customer = customers[record.customer]
                }
              }

              if (opportunity) {
                return (
                  <CrmRecordRow
                    key={index}
                    create_by={create_by}
                    opportunity={opportunity}
                    records={records}
                    record={record}
                  />
                )
              }
            })}
        </div>
        {loading ||
        !paginate ||
        (paginate && parseInt(paginate.total_count)) ==
          ((records && records.length) || 0) ? (
          ''
        ) : (
          <div className="more-message" onClick={this.nextRecordList}>
            <i className="iconfont icon-new" />
            <span>{next_page_text ? next_page_text : '加载更多'}</span>
          </div>
        )}
      </KBLoadingContainer>
    )
  }
})

CrmRecordList.PropTypes = {
  opportunity: PropTypes.object
}

function mapStateToProps(state, props) {
  const { entities } = state
  const { opportunity, isAll } = props
  let users = checkObject(entities, 'users')
  let usersList = selectors.getSpaceUsers(state)
  let records = selectors.getRecords(state)
  let opps = selectors.getObjOpportunities(state)
  let customers = selectors.getObjCustomers(state)

  if (!isAll) {
    records = records.filter(record => opportunity.id == record.opportunity)
  }

  return {
    users,
    usersList,
    opps,
    records,
    customers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getRecordsAction: bindActionCreators(customerRecordsActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrmRecordList)
