import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBPopover,
  KBLoadingContainer,
  KBTipsy,
  KBStatisticNumber,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import { KBNoData } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { areaActions, informActions, apiCallFailure } from 'app/actions'
import { connect } from 'react-redux'
import AreasForm from './AreasForm'
import {
  formatYearDayEN,
  percent,
  getFilterAndQuery,
  queryFilter,
  isXingKuSpace,
  getServiceErrorMessage,
  arrayToObject
} from 'utils/kbUtil'
import Desks from './Desks'
import * as selectors from 'app/selectors'
import { canAccessLocation, canAccessLocationRoles } from 'app/reducers/role'
import * as Constants from 'app/constants'
import AddDeskForm from './AddDeskForm'
import BatchUpdateAreas from './BatchUpdateAreas'
import MeetingTheme from '../meeting/MeetingTheme'
import VisitDeviceAdd from '../visit/VisitDeviceAdd'
import _ from 'lodash'
import OnlyTimeReportForm from '../invoices/OnlyTimeReportForm'
import AreasInVirtualFloor from './AreasInVirtualFloor'
import SettingCoupon from '../meeting/SettingCoupon'

const FILTER_STATE = ['state']

const AREA_TYPE = {
  public_office: {
    name: '开放',
    className: 'open-space'
  },
  private_office: {
    name: '封闭',
    className: 'close-space'
  },
  flexible_office: {
    name: '灵活',
    className: 'flexible-space'
  },
  floor: {
    name: '虚拟区域',
    className: 'floor-space'
  }
}

var Areas = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      loadingAreas: false,
      filters,
      area_type: ['public_office', 'private_office', 'flexible_office'],
      area_class: 'all',
      queryFiltersStr: '',
      isLoadDesk: false,
      showPadsList: false,
      showAddPad: false,
      modalVisible: false
    }
  },

  componentDidMount() {
    this.mounted = true
    this._loadData()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(floor_info_id) {
    const { getAreasAction, loc_id, isTowerLoc, area_id } = this.props

    // load locations
    this.setState({
      loadingAreas: true
    })

    const params = {
      location_id: loc_id,
      includes: 'subscriptions,organizations,areas',
      per_page: 1000
    }
    if (floor_info_id) {
      params.floor_info_id = floor_info_id
    }

    api
      .getAreas(params)
      .then(
        json => {
          getAreasAction.success(json, {
            location_id: loc_id,
            entityName: 'areas'
          })
          return true
        },
        errors => {
          getAreasAction.failure(errors)
        }
      )
      .finally(() => {
        if (this.mounted) {
          if (!area_id || floor_info_id) {
            const { areas } = this.props
            // isTowerLoc &&
            this._onAreaCreated({ id: (areas[0] && areas[0].id) || -1 })
          }
          this.setState({
            loadingAreas: false,
            showPadsList: false,
            showAddPad: false
          })
        }
      })
  },

  componentWillReceiveProps(nextProps) {
    const { inform } = nextProps
    const { areas } = inform
    const { informRemoveActions } = this.props
    if (Object.values(nextProps.query).length === 0) {
      this.setState({
        queryFiltersStr: ''
      })
    }
    if (areas) {
      informRemoveActions.success(null, { key: 'remove', value: 'areas' })
      this._loadData()
    }
    if (this.props.floorInfoId != nextProps.floorInfoId) {
      this._loadData(nextProps.floorInfoId)
    }
  },

  _onAreaCreated(area) {
    const { loc_id } = this.props
    this.props.routerActions.push(
      `/admin/locations/${loc_id}/areas/desks/list?area_id=${area && area.id}`
    )
  },

  createArea() {
    let { loc_id, isTowerLoc } = this.props

    KBPopover.showForm(
      <AreasForm
        isTowerLoc={isTowerLoc}
        loc_id={loc_id}
        onAreaCreated={this._onAreaCreated}
      />
    )
  },

  updateArea(area) {
    const { loc_id, isTowerLoc } = this.props
    KBPopover.showForm(
      <AreasForm
        isTowerLoc={isTowerLoc}
        area={area}
        type={'update'}
        loc_id={loc_id}
        area_id={area && area.id}
      />
    )
  },

  _setFilter(key, value) {
    const { query, routerActions, loc_id } = this.props
    let newFilters = Object.assign({}, this.state.filters, { [key]: value })
    if (query.page) {
      query.page = 1
    }
    let queryFiltersStr = queryFilter({ ...query, ...newFilters })
    this.setState({
      filters: newFilters,
      queryFiltersStr
    })
    routerActions.replace(
      `/admin/locations/${loc_id}/areas/desks/list${queryFiltersStr}`
    )
  },

  _clearFilters() {
    const { query, loc_id, routerActions } = this.props
    if (query.page) {
      query.page = 1
    }
    let queryFiltersStr = queryFilter({ ...query })
    this.setState({
      filters: {},
      queryFiltersStr
    })
    routerActions.replace(
      `/admin/locations/${loc_id}/areas/desks/list${queryFiltersStr}`
    )
  },

  _clearFilter(key) {
    let newFilters = _.omit(this.state.filters, [key])
    this.setState({ filters: newFilters })
  },

  showAreaType(area_type) {
    if (area_type == 'public_office') return '开放办公区'

    if (area_type == 'private_office') return '独立办公室'

    if (area_type == 'flexible_office') return '灵活办公区'
    if (area_type == 'floor') return '虚拟区域'
    return ''
  },

  showOpenOffice() {
    const { publicAreas } = this.props
    this.setState({
      area_type: ['public_office'],
      area_class: 'public',
      queryFiltersStr: ''
    })
    this._onAreaCreated({ id: (publicAreas[0] && publicAreas[0].id) || -1 })
  },

  showCloseOffice() {
    const { privateAreas } = this.props
    this.setState({
      area_type: ['private_office'],
      area_class: 'private',
      queryFiltersStr: ''
    })
    this._onAreaCreated({ id: (privateAreas[0] && privateAreas[0].id) || -1 })
  },

  showFlexibleOffice() {
    const { flexiableAreas } = this.props
    this.setState({
      area_type: ['flexible_office'],
      area_class: 'flexible',
      queryFiltersStr: ''
    })
    this._onAreaCreated({
      id: (flexiableAreas[0] && flexiableAreas[0].id) || -1
    })
  },

  showFloorOffice() {
    const { floorAreas } = this.props
    this.setState({
      area_type: ['floor'],
      area_class: 'floor',
      queryFiltersStr: ''
    })
    this._onAreaCreated({ id: (floorAreas[0] && floorAreas[0].id) || -1 })
  },

  showAllOffice() {
    const { isTowerLoc, areas } = this.props
    this.setState({
      area_type: ['public_office', 'private_office', 'flexible_office'],
      area_class: 'all',
      queryFiltersStr: '',
      showPadsList: false,
      showAddPad: false
    })
    // isTowerLoc &&
    this._onAreaCreated({ id: (areas[0] && areas[0].id) || -1 })
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="分区导出"
        message="分区已成功导出，可以前往报表中心下载"
      />
    )
  },

  addTasks(area) {
    let { loc_id } = this.props
    KBPopover.show(
      <AddDeskForm
        loc_id={loc_id}
        setLoadDesk={this.setLoadDeskTrue}
        area_id={area && area.id}
      />
    )
  },

  areaReport() {
    const { loc_id } = this.props
    return api.areaReport({ location_id: loc_id }).then(() => {
      this.toReportCenter && this.toReportCenter()
    })
  },

  setLoadDeskTrue() {
    this.setState({
      isLoadDesk: true
    })
    setTimeout(this._loadData)
  },

  setLoadDeskFalse() {
    this.setState({
      isLoadDesk: false
    })
  },

  setQueryOfAreaId() {
    this.setState({
      showPadsList: false,
      showAddPad: false
    })
    setTimeout(() => {
      this.setQueryOfAreaIdTime()
    }, 200)
  },

  setQueryOfAreaIdTime() {
    const { query } = this.props
    let queryFiltersStr = queryFilter({ ...query })
    this.setState({
      queryFiltersStr
    })
  },

  deleteArea(area) {
    const {
      deleteAreaAction,
      routerActions,
      loc_id,
      apiCallFailureActions
    } = this.props
    if (area.occupied_desks_count > 0) {
      apiCallFailureActions({
        status: 'error',
        message: '此分区已有工位入驻不可删除!'
      })
      return
    }

    let areaTypeName = '分区'
    let areasInFloor = []

    api.getAreaInfo(area.id, { location_id: loc_id, includes: 'areas' }).then(
      json => {
        if (area.area_type === 'floor') {
          const virtualFloorDetail = json.response.body || {}
          areasInFloor = virtualFloorDetail.areas || []
          if (areasInFloor.length > 0) {
            areaTypeName = '虚拟区域'
          }
        }
        KBPopoverConfirm({
          name: `删除${areaTypeName}`,
          context: `${
            areasInFloor.length
              ? `此虚拟区域还包含${areasInFloor.length}个分区，`
              : ''
          }您确定要删除${areaTypeName} ${area.name} 吗?`,
          callback: () => {
            return api.deleteArea(area.id).then(
              json => {
                routerActions.replace(
                  `/admin/locations/${loc_id}/areas/desks/list`
                )
                deleteAreaAction.success(json, {
                  location_id: loc_id,
                  key: 'areas',
                  id: area.id
                })
                KBPopover.close()
              },
              error => {
                deleteAreaAction.failure(error)
                KBPopover.close()
              }
            )
          }
        })
      },
      error => {
        apiCallFailureActions({
          status: 'error',
          message: getServiceErrorMessage(error)
        })
      }
    )
  },

  batchUpdateAreas() {
    const { areas, loc_id } = this.props
    let filterTypes = ['public_office', 'private_office', 'flexible_office']
    let filteredAreas =
      (areas &&
        areas.filter(a => {
          return filterTypes.includes(a.area_type)
        })) ||
      []
    KBPopover.show(
      <BatchUpdateAreas
        areaType="office"
        loc_id={loc_id}
        areas={filteredAreas}
      />
    )
  },

  setAreaCoupon() {
    this.setState({ modalVisible: true })
  },

  setAreaPad() {
    this.setState({
      showPadsList: true
    })
  },

  showAddPadPage() {
    this.setState({
      showAddPad: true
    })
  },

  updatePadList() {
    this.setState({
      showAddPad: false
    })
  },

  areaDevicesJsx() {
    const { area, loc_id } = this.props
    const { showAddPad, showPadsList } = this.state

    return showAddPad ? (
      <VisitDeviceAdd
        params={{ id: loc_id }}
        location={{ query: { type: 'office' } }}
        area={area}
        updateOfficePadList={this.updatePadList}
      />
    ) : showPadsList ? (
      <MeetingTheme
        params={{ id: loc_id }}
        isOffice={true}
        area={area}
        addOfficePad={this.showAddPadPage}
      />
    ) : null
  },

  officeRentingReport() {
    const { loc_id } = this.props
    KBPopover.show(<OnlyTimeReportForm loc_id={loc_id} type="office" />)
  },

  enableAndDisableArea(area) {
    const updatingStatus = !(area.state === 'disabled')
    const statusText = updatingStatus ? '停用' : '启用'

    KBPopoverConfirm({
      name: statusText,
      context: `是否确定${statusText}该分区?`,
      callback: () => {
        return api.updateArea(area.id, { is_disabled: updatingStatus }).then(
          json => {
            this.props.updateAreaAction.success(json)
            KBPopover.close()
          },
          error => {
            this.props.updateAreaAction.failure(error)
            KBPopover.close()
          }
        )
      }
    })
  },

  renderAreasList() {
    const { loc_id, area_id, areas = [], isManager, isTowerLoc } = this.props
    const { area_type, area_class } = this.state
    let office_areas =
      areas && areas.filter(area => _.includes(area_type, area.area_type))
    return (
      <nav className="nav-container f-left">
        <div className="nav-container-content">
          <header className="nav-header clear-fix">
            <h2 className="header-title f-left">分区</h2>
            {isManager ? (
              <div className="nav-header-btn">
                <button className="bordered-btn" onClick={this.createArea}>
                  添加分区
                </button>
              </div>
            ) : (
              ''
            )}
          </header>
          <div className="nav-body" style={{ padding: '20px 0' }}>
            <KBLoadingContainer
              loading={!areas.length && this.state.loadingAreas}
            >
              <ul className="area-type-list">
                <li
                  onClick={() => {
                    this.showAllOffice()
                  }}
                  className={area_class == 'all' ? 'active' : ''}
                >
                  <Link to={`/admin/locations/${loc_id}/areas/desks/list`}>
                    所有
                  </Link>
                </li>
                {
                  // isTowerLoc ? null :
                  <li
                    onClick={this.showOpenOffice}
                    className={area_class == 'public' ? 'active' : ''}
                  >
                    <a>开放</a>
                  </li>
                }
                <li
                  onClick={this.showCloseOffice}
                  className={area_class == 'private' ? 'active' : ''}
                >
                  <a>封闭</a>
                </li>
                {
                  // isTowerLoc ? null :
                  <li
                    onClick={this.showFlexibleOffice}
                    className={area_class == 'flexible' ? 'active' : ''}
                  >
                    <a>灵活</a>
                  </li>
                }
                {
                  // isTowerLoc ? null :
                  <li
                    onClick={this.showFloorOffice}
                    className={area_class == 'floor' ? 'active' : ''}
                  >
                    <a>虚拟区域</a>
                  </li>
                }
              </ul>
              <ul className="nav-container-list">
                {(office_areas &&
                  office_areas.length > 0 &&
                  office_areas.map(area => {
                    let minus_desks = area.reserved_desks_count || 0
                    return (
                      <li
                        key={area.id}
                        className={
                          area && area_id == area.id
                            ? 'nav-container-list-hover'
                            : ''
                        }
                        onClick={this.setQueryOfAreaId}
                        style={{ margin: '5px 0' }}
                      >
                        <Link
                          to={`/admin/locations/${loc_id}/areas/desks/list?area_id=${area &&
                            area.id}`}
                        >
                          <span
                            className={`default-space ${
                              AREA_TYPE[area.area_type].className
                            }`}
                            style={{ marginRight: '10px' }}
                          >
                            {AREA_TYPE[area.area_type].name}
                          </span>
                          <span>{area.name}</span>
                          {// isTowerLoc ? null :
                          area.area_type === 'flexible_office' ? null : (
                            <div className="proportion-bar">
                              <div
                                className={`proportion-bar_show ${
                                  minus_desks / area.desks_count > 0.5
                                    ? 'color-full'
                                    : 'color-free'
                                }`}
                                style={{
                                  width:
                                    `${(minus_desks / area.desks_count) *
                                      100}` + 'px'
                                }}
                              ></div>
                            </div>
                          )}
                        </Link>
                        {area.area_type === 'floor' ? null : area.area_type ===
                          'flexible_office' ? (
                          <span
                            className="station-num"
                            style={{ marginBottom: 3 }}
                          >{`${area.total_desks_count ||
                            area.desks_count}`}</span>
                        ) : (
                          <span className="station-num">{`${minus_desks}/${area.total_desks_count ||
                            area.desks_count}`}</span>
                        )
                        // <span className="station-num" >{ isTowerLoc ? `${area.size}㎡` : `${minus_desks}/${area.total_desks_count || area.desks_count}`}</span>
                        }
                      </li>
                    )
                  })) || <KBNoData tipMessage="暂无分区数据" />}
              </ul>
            </KBLoadingContainer>
          </div>
        </div>
      </nav>
    )
  },

  renderOccupiedOrg(area) {
    const { loc_id } = this.props

    return (
      <div className="nav-section-content-container">
        <div className="m-top" style={{ color: '#7f8fa4' }}>
          已出租公司信息
        </div>
        {area && area.organization ? (
          <div className="member-credits">
            <div className="credit-message">
              <div className="vf-center">
                <div
                  className="star-icon"
                  style={{
                    backgroundImage: `url(${area.organization.logo ||
                      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/company_default.png'})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                  }}
                ></div>
                <div className="point-con">
                  <div className="point-num">{area.organization.full_name}</div>
                  <div style={{ marginTop: 5 }}>
                    <span>
                      负责人：{area.organization.contacts_name || '暂无'}
                    </span>
                    <span
                      style={{
                        display: 'inline-block',
                        borderLeft: '1px solid #7f8fa4',
                        paddingLeft: 10,
                        marginLeft: 10
                      }}
                    >
                      电话：{area.organization.contacts_phone || '暂无'}
                    </span>
                  </div>
                </div>
              </div>
              <Link
                to={`/admin/locations/${loc_id}/organizations/${area.organization.id}`}
                className="bordered-btn"
              >
                查看
              </Link>
            </div>
          </div>
        ) : (
          <KBNoData tipMessage="暂无公司入驻" />
        )}
      </div>
    )
  },

  render() {
    var {
      location,
      area,
      page,
      areas,
      loc_id,
      area_id,
      isManager,
      location_entering_rate_calc_type,
      space_id,
      isTowerLoc,
      locFloorsObj
    } = this.props
    const {
      filters,
      queryFiltersStr,
      isLoadDesk,
      showPadsList,
      showAddPad,
      modalVisible
    } = this.state

    let statistic = location.statistic
    const enteringRate = percent(
      statistic ? statistic.desks_count : 0,
      statistic ? statistic.renting_desks_count : 0
    )
    const areaSubs =
      (area &&
        area.subscriptions &&
        area.subscriptions.filter(
          s =>
            s.subscription.status !== 'cancelled' &&
            s.subscription.status !== 'ended'
        )) ||
      []
    const isDisabled = area && area.state === 'disabled'
    return (
      <section
        className="kb-content clear-fix"
        style={{ height: '100%', paddingTop: 0 }}
      >
        {this.renderAreasList()}
        {showPadsList || showAddPad ? (
          this.areaDevicesJsx()
        ) : (
          <section className="nav-section-container f-right">
            <header className="nav-header">
              <h2 style={{fontSize:22, fontWeight:100, color:'rgba(0,0,0,0.85)', marginLeft:24}}>
              {(area && area.name) || '所有分区'}
              </h2>
              <div
                className="clear-fix"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <h2
                  className="nav-section-header-title f-left vf-center"
                  style={{ flex: 1 }}
                >
                  {area ? (
                    <span className="area-type-tag-block m-left-sm">
                      {this.showAreaType(area.area_type)}
                    </span>
                  ) : null}
                  {area ? (
                    (area.area_type === 'public_office' &&
                      area.state === 'free') ||
                    area.area_type === 'floor' ? null : (
                      <span
                        className={`area-state-block area-state-color-${area.state} m-left-sm`}
                      >
                        {Constants.AREAS_STATE_OBJ[area.state]}
                      </span>
                    )
                  ) : null}
                </h2>
              </div>
              <div>
                {/* 头部操作按钮 ===================================================================================================== */}
                {isManager && (
                  <div className="f-right">
                    {area ? (
                      <div>
                        {area.area_type === 'flexible_office' && (
                          <a
                            className="bordered-btn"
                            onClick={() => this.setAreaCoupon()}
                          >
                            优惠设置
                          </a>
                        )}
                        {// !isTowerLoc &&
                        !isDisabled && area.area_type !== 'floor' ? (
                          <a
                            className="bordered-btn"
                            onClick={() => this.setAreaPad()}
                          >
                            <i
                              className="iconfont icon-pad"
                              style={{ marginTop: 2 }}
                            />
                            设置显示屏
                          </a>
                        ) : null}
                        {isDisabled ? null : (
                          <a
                            className="bordered-btn"
                            onClick={() => this.updateArea(area)}
                          >
                            <i
                              className="iconfont icon-edit"
                              style={{ marginTop: 2 }}
                            />
                            编辑
                          </a>
                        )}
                        {// !isTowerLoc &&
                        !isDisabled && area.area_type !== 'floor' ? (
                          <a
                            className="bordered-btn"
                            onClick={() => this.addTasks(area)}
                          >
                            <i className="iconfont icon-add" />
                            添加工位
                          </a>
                        ) : null}
                        <a
                          className="c-btn-secondary m-left-sm"
                          onClick={() => this.enableAndDisableArea(area)}
                        >
                          <i
                            className={`iconfont ${
                              isDisabled
                                ? 'icon-Uploadsuccess'
                                : 'icon-Uploadfailed'
                            }`}
                          />
                          {isDisabled ? '启用' : '停用'}
                        </a>
                        {isDisabled ? null : (
                          <a
                            className="c-btn-delete"
                            onClick={() => this.deleteArea(area)}
                          >
                            <i className="iconfont icon-delete" />
                            删除
                          </a>
                        )}
                      </div>
                    ) : (
                      // isTowerLoc ? null :
                      <div>
                        <a
                          className="bordered-btn f-right"
                          onClick={() => this.createArea()}
                        >
                          <i className="iconfont icon-add" />
                          添加新分区
                        </a>
                        <a
                          className="bordered-btn f-right"
                          onClick={() => this.batchUpdateAreas()}
                        >
                          批量修改分区
                        </a>
                        <a
                          className="c-btn-secondary m-left-sm f-right"
                          onClick={() => this.areaReport(area)}
                        >
                          导出工位
                        </a>
                        <a
                          className="c-btn-secondary m-left-sm f-right"
                          onClick={() => this.officeRentingReport()}
                        >
                          导出房间租赁情况
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* 展示所有分区时的统计数据展示 ========================================================================================= */}
              {// !isTowerLoc &&
              location && location.statistic && !area ? (
                <div className="chart-out_box t-center m-updown">
                  <div className="chart-box t-left" style={{ marginRight: 30 }}>
                    {isXingKuSpace(space_id) ? (
                      <KBStatisticNumber
                        number={
                          parseFloat(
                            statistic.outer_entering_rate || 0
                          ).toFixed(2) + '%'
                        }
                        name="实际入驻率"
                      />
                    ) : (
                      <KBStatisticNumber
                        number={enteringRate + '%'}
                        name="工位使用率"
                      />
                    )}
                  </div>
                  <div className="chart-box t-left" style={{ marginRight: 30 }}>
                    {isXingKuSpace(space_id) ? (
                      <KBStatisticNumber
                        number={
                          location_entering_rate_calc_type == 'by_desk'
                            ? statistic.total_capacity || 0
                            : parseFloat(
                                statistic.physical_capacity || 0
                              ).toFixed(2)
                        }
                        unit={
                          location_entering_rate_calc_type == 'by_desk'
                            ? '个'
                            : '㎡'
                        }
                        name={
                          location_entering_rate_calc_type == 'by_desk'
                            ? '出租工位总和'
                            : '出租面积总和'
                        }
                      />
                    ) : (
                      <KBStatisticNumber
                        number={
                          location_entering_rate_calc_type == 'by_desk'
                            ? statistic.total_capacity || 0
                            : parseFloat(statistic.total_capacity || 0).toFixed(
                                2
                              )
                        }
                        unit={
                          location_entering_rate_calc_type == 'by_desk'
                            ? '个'
                            : '㎡'
                        }
                        name={
                          location_entering_rate_calc_type == 'by_desk'
                            ? '额定总工位数'
                            : '额定总面积数'
                        }
                      />
                    )}
                  </div>
                  <div className="chart-box t-left" style={{ marginRight: 30 }}>
                    <KBStatisticNumber
                      number={statistic.desks_count || 0}
                      unit="个"
                      name="实际总工位数"
                    />
                  </div>
                  <div className="chart-box t-left" style={{ marginRight: 30 }}>
                    {isXingKuSpace(space_id) ? (
                      <KBStatisticNumber
                        number={
                          location_entering_rate_calc_type == 'by_desk'
                            ? statistic.occupied_desks_count || 0
                            : parseFloat(
                                statistic.occupied_desks_count || 0
                              ).toFixed(2)
                        }
                        unit={
                          location_entering_rate_calc_type == 'by_desk'
                            ? '个'
                            : '㎡'
                        }
                        name={
                          location_entering_rate_calc_type == 'by_desk'
                            ? '已出租工位总和'
                            : '已出租面积总和'
                        }
                      />
                    ) : (
                      <KBStatisticNumber
                        number={
                          location_entering_rate_calc_type == 'by_desk'
                            ? statistic.occupied_desks_count || 0
                            : parseFloat(
                                statistic.occupied_desks_count || 0
                              ).toFixed(2)
                        }
                        unit={
                          location_entering_rate_calc_type == 'by_desk'
                            ? '个'
                            : '㎡'
                        }
                        name={
                          location_entering_rate_calc_type == 'by_desk'
                            ? '已出租工位数'
                            : '已出租面积数'
                        }
                      />
                    )}
                  </div>
                  <div className="chart-box t-left">
                    <KBStatisticNumber
                      number={statistic.assigned_desks_count || 0}
                      unit="个"
                      name="实际入驻工位数"
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              {/* 分区详情时的数据展示 ================================================================================================ */}
              {// !isTowerLoc &&
              area ? (
                <div
                  className="color-nine clear-fix"
                  style={{ marginTop: '10px' }}
                >
                  {area.area_type === 'floor' ? null : (
                    <div
                      className="clear-fix f-left"
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingTop: 25
                      }}
                    >
                      <div
                        className="chart-box t-left"
                        style={{ marginRight: 30 }}
                      >
                        <KBStatisticNumber
                          number={area.size || 0}
                          unit={'㎡'}
                          name={'总面积数'}
                        />
                      </div>
                      <div
                        className="chart-box t-left"
                        style={{ marginRight: 30 }}
                      >
                        <KBStatisticNumber
                          number={area.total_desks_count || 0}
                          unit="个"
                          name="总工位数"
                        />
                      </div>
                      <div
                        className="chart-box t-left"
                        style={{ marginRight: 30 }}
                      >
                        <KBStatisticNumber
                          number={area.reserved_desks_count || 0}
                          unit="个"
                          name="已出租工位数"
                        />
                      </div>
                      <div className="chart-box t-left">
                        <KBStatisticNumber
                          number={area.assigned_desks_count || 0}
                          unit={'个'}
                          name={'已入驻工位数'}
                        />
                      </div>
                      <div className="chart-box t-left">
                        <KBStatisticNumber
                          number={
                            (locFloorsObj[area.floor_info_id] &&
                              parseInt(
                                locFloorsObj[area.floor_info_id].layer_num
                              )) ||
                            0
                          }
                          unit={'层'}
                          name={'所属楼层'}
                        />
                      </div>
                    </div>
                  )}
                  {/* 封闭分区的公司占用信息 ========================================================================================= */}
                  {!isTowerLoc &&
                  area &&
                  area.area_type === 'private_office' ? (
                    <div className="clear-fix f-left" style={{ width: '100%' }}>
                      <table className="content-table">
                        <tbody>
                          {areaSubs.map(sub => {
                            let subscription = sub.subscription
                            return (
                              <tr className="border-top border-bottom-none">
                                <td>
                                  <div className="area-table-title">
                                    已出租公司名称
                                  </div>
                                  <div className="area-table-content">
                                    {area.organization ? (
                                      <Link
                                        to={`/admin/locations/${loc_id}/organizations/${area.organization.id}`}
                                        className="color-link"
                                      >
                                        {area.organization.full_name}
                                      </Link>
                                    ) : (
                                      '无'
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div className="area-table-title">
                                    合同编号
                                  </div>
                                  <div className="area-table-content vf-center">
                                    <Link
                                      to={`/admin/locations/${loc_id}/organizations/${area.organization &&
                                        area.organization.id}/subscriptions/${
                                        subscription.id
                                      }`}
                                      className="color-link"
                                    >
                                      {subscription.serial}
                                    </Link>
                                    <span
                                      style={{
                                        whiteSpace: 'nowrap',
                                        fontWeight: 'normal',
                                        borderRadius: 2
                                      }}
                                      className={`finance-default ${
                                        Constants
                                          .ORG_SUBSCRIPTION_STATES_CLASS_NEW[
                                          subscription.status
                                        ]
                                      }`}
                                    >
                                      {
                                        Constants.ORG_SUBSCRIPTION_STATES[
                                          subscription.status
                                        ]
                                      }
                                    </span>
                                  </div>
                                </td>
                                <td style={{ width: 250 }}>
                                  <div className="area-table-title">
                                    使用时间
                                  </div>
                                  <div className="area-table-content">{`${formatYearDayEN(
                                    subscription.start_date
                                  )} 至 ${formatYearDayEN(
                                    subscription.end_date
                                  )}`}</div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
              ) : (
                ''
              )}
              {// isTowerLoc &&
              false && area ? (
                <div>
                  {area.images && area.images.length > 0 ? (
                    <div className="area-images">
                      {area.images.map(img => {
                        return <img src={img} alt="Loading" />
                      })}
                    </div>
                  ) : null}
                  <div className="detail-info-container">
                    <div className="info-block">
                      <span className="label">分区名称：</span>
                      <span className="value">{area.name}</span>
                    </div>
                    <div className="info-block">
                      <span className="label">分区类型：</span>
                      <span className="value">
                        {this.showAreaType(area.area_type)}
                      </span>
                    </div>
                    <div className="info-block">
                      <span className="label">所属楼层：</span>
                      <span className="value">
                        {locFloorsObj[area.floor_info_id] &&
                          locFloorsObj[area.floor_info_id].layer_num}
                      </span>
                    </div>
                    {/* <div className="info-block" >
										<span className="label" >面积：</span>
										<span className="value" >{ area.size }㎡</span>
									</div>
									<div className="info-block" >
										<span className="label" >容纳人数：</span>
										<span className="value" >{ area.capacity }</span>
									</div>
									<div className="info-block" >
										<span className="label" >价格：</span>
										<span className="value" >¥{ area.listing_price }</span>
									</div>
									<div className="info-block" >
										<span className="label" >最低价格：</span>
										<span className="value" >¥{ area.lowest_price }</span>
									</div> */}
                  </div>
                </div>
              ) : null}
            </header>
            {this.renderOccupiedOrg(area)}

            {// 虚拟分区展示 =====================================================================================================
            area && area.area_type === 'floor' ? (
              <AreasInVirtualFloor loc_id={loc_id} area_id={area_id} />
            ) : (
              // isTowerLoc ?

              // :
              // 工位展示  =====================================================================================================
              <div className="nav-section-content-container">
                <div className="nav-section-action-bar">
                  <div className="select-progress">
                    <span style={{ marginRight: '8px' }}>选择工位状态</span>
                    {Constants.DESK_LIST_STATES.map((status, index) => {
                      return (
                        <div key={index} className="d-iblock">
                          <div className="color-show">
                            <span
                              className={
                                'station-' +
                                Constants.DESK_STATES_CLASS[status.id]
                              }
                            />
                          </div>
                          <span
                            className={`receipt-status ${
                              Object.keys(filters).length > 0 &&
                              filters.state == status.id
                                ? 'station-' +
                                  Constants.DESK_STATES_CLASS[status.id]
                                : ''
                            }`}
                            onClick={() => this._setFilter('state', status.id)}
                          >
                            {Constants.DESK_STATES[status.id]}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {Object.keys(filters).length > 0 ? (
                  <div className="clear-criteria" onClick={this._clearFilters}>
                    <i className="iconfont icon-close" />
                    <span>清除所有筛选条件</span>
                  </div>
                ) : null}
                {area_id == '-1' || area_id == -1 ? (
                  <KBNoData tipMessage="暂无工位数据" />
                ) : (
                  <Desks
                    loc_id={loc_id}
                    area_id={area_id}
                    location={location}
                    area={area}
                    page={page}
                    areas={areas}
                    filters={this.state.filters}
                    queryFiltersStr={queryFiltersStr}
                    setLoadDeskFalse={this.setLoadDeskFalse}
                    isLoadDesk={isLoadDesk}
                  />
                )}
              </div>
            )}
          </section>
        )}
        {modalVisible && (
          <SettingCoupon
            type="add"
            source_type="desk"
            close={() => this.setState({ modalVisible: false })}
            visible={modalVisible}
            data={area}
          />
        )}
      </section>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const { params, location, locFloors } = ownProps
  const { user } = state
  let space_id = Object.keys(state.entities.spaces || {})[0]
  let loc_id = params.id
  let page = parseInt(location.query && location.query.page) || 1
  let area_id = location.query && location.query.area_id
  let areas = Object.assign([], selectors.getAreasOfLocation(state, loc_id))
  let publicAreas =
    areas &&
    areas.filter(area => {
      return area.area_type === 'public_office'
    })
  let privateAreas =
    areas &&
    areas.filter(area => {
      return area.area_type === 'private_office'
    })
  let flexiableAreas =
    areas &&
    areas.filter(area => {
      return area.area_type === 'flexible_office'
    })
  let floorAreas =
    areas &&
    areas.filter(area => {
      return area.area_type === 'floor'
    })
  let area = selectors.getArea(state, area_id)
  let inform = state.inform
  let isManager = canAccessLocation(user, loc_id)
  let isSales = canAccessLocationRoles(user, loc_id, ['sales'])
  let locFloorsObj = arrayToObject(locFloors)

  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)
  return {
    space_id,
    loc_id,
    area_id,
    inform,
    location: selectors.getLocation(state, loc_id),
    area,
    page,
    areas,
    isManager,
    isSales,
    publicAreas,
    privateAreas,
    flexiableAreas,
    floorAreas,
    locFloorsObj,
    ...paramsAll
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getAreasAction: bindActionCreators(areaActions.replace, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    informRemoveActions: bindActionCreators(informActions.remove, dispatch),
    deleteAreaAction: bindActionCreators(areaActions.delete, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    updateAreaAction: bindActionCreators(areaActions.update, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Areas)
