import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { invoicesActions, successState, apiCallFailure } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { paginate, arrayToObject, util } from 'utils/kbUtil'
import { reduxForm } from 'redux-form'
import {
  KBDropdown,
  KBPagination,
  KBCheckBox,
  KBLoadingContainer,
  KBSearchList,
  KBPopover,
  KBRangeDate,
  KBStatisticNumber
} from 'components'
import { KBDropSelect } from 'components/plugins'
import {
  INVOICES_UPDATE_TYPE,
  INVOICES_UPDATE_STATE,
  INVOICES_IS_REVIEWED,
  INVOICE_APPLICATION_STATE,
  INVOICE_APPLICATION_STATE_OBJ
} from 'app/constants'
import {
  percent,
  mergeArrayWithKey,
  queryFilter,
  getFilterAndQuery,
  kbCloseDropDown
} from 'utils/kbUtil'
import { KBPopoverConfirm } from 'components/tools'
import * as selectors from 'app/selectors'
import { routerActions } from 'react-router-redux'
import {
  canAccessLocationFinance,
  canAccessLocation,
  canAccessLocationRoles,
  canAdminSpace
} from 'app/reducers/role'
import moment from 'moment'
import InvoiceList from './InvoiceList'
import InvoicesReport from './InvoicesReport'
import InvoicesApplication from './InvoicesApplication'
import InvoicePrintHtml from './InvoicePrintHtml'
import InvoicesSendEmailForm from './InvoicesSendEmailForm'
import InvoicesApplicationFinishForm from './InvoicesApplicationFinishForm'

const DROP_DOWNS = [
  'invoiceType',
  'billDropdown',
  'invoiceStatus',
  'reviewedDropdown',
  'location_idDropdown',
  'dateDropDown'
]

let isOnceStatus = true

var InvoicesInfo = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      total: 0,
      unpaid: 0,
      partially_paid: 0,
      paid: 0,
      overdue: 0,
      percentUnpaid: 0,
      percentPartially_paid: 0,
      percentPaid: 0,
      selectInvoices: [],
      isAllInvoicesChecked: false,
      allCheckedBtn: false,
      percentOverdue: 0,
      invoices: [],
      filters,
      page: this.props.page || 1,
      loading: false,
      queryFiltersStr: '',
      refund_type: {},
      invoiceStatusArray: INVOICES_UPDATE_STATE,
      isAllCheckBoxInvoiceStatus: false
    }
  },
  componentDidMount() {
    this.mounted = true
    let newInvoiceStatusArray = [...this.state.invoiceStatusArray]
    let checkArray = []
    let bool = true

    api.getSubscriptionLineItemRefundType().then(
      ({ json }) => {
        const { subscriptionLineItemRefundType } = json.entities
        this.setState({
          refund_type: subscriptionLineItemRefundType
        })
      },
      error => { }
    )
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    if (selectFilters.length < 1) {
      let statusArray = this.state.invoiceStatusArray
      statusArray.map(item => {
        item.check = false
      })
      newInvoiceStatusArray = statusArray
      bool = false
      // this.setState({
      //   isAllCheckBoxInvoiceStatus: false,
      //   invoiceStatusArray: statusArray
      // })
    }
    const {
      location: { query }
    } = this.props
    newInvoiceStatusArray.forEach(x => {
      if (query&&query.status&&query.status.indexOf(x.id) === -1 && query.check_status&&query.check_status.indexOf(x.id) === -1 ) bool = false
    })
    if(query && query.check_status){
      const arr = query.check_status.split(',')
      newInvoiceStatusArray = newInvoiceStatusArray.map(x => {
        arr.forEach(o => {
          if (o == x.id) x.check = true
        })
        return x
      })
    }else if (query && query.status) {
      const arr = query.status.split(',')
      newInvoiceStatusArray = newInvoiceStatusArray.map(x => {
        arr.forEach(o => {
          if (o == x.id) x.check = true
        })
        return x
      })
    } else {
      const arr = this.state.invoiceStatusArray.map(x=>x.id)
      arr.map(item => {
        if (
          item.id == 'unchecked' ||
          item.id == 'checked' ||
          item.id == 'confirmed' ||
          item.id == 'check_failed'
        ) {
          checkArray.push(item)
        } else {
          newInvoiceStatusArray = this.state.invoiceStatusArray.map(x => {
            if( x.id!='unchecked'&&
                x.id == 'checked' &&
                x.id == 'confirmed' &&
                x.id == 'check_failed')
              x.check = true
            return x
          })
        }
      })
    }

    this._loadData()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(model, newPage) {
    const per_page = 10
    const { getInvoicesActions, location_id, routerActions, query } = this.props
    const { page, filters } = this.state
    let params = Object.assign(
      {},
      model,
      filters,
      location_id ? { location_id } : {}
    )
    let { from, to } = params
    if (from && to) {
      params.from = from && moment(from).format('YYYY-MM-DD')
      params.to = to && moment(to).format('YYYY-MM-DD')
    } else {
      delete params.from
      delete params.to
    }
    let queryFiltersStr = queryFilter({
      ...query,
      ...params,
      page: newPage || page
    })

    this.setState({ loading: true })
    return api
      .getInvoices({ ...params, per_page, page: newPage || page })
      .then(
        ({ json, response }) => {
          const { invoices } = json
          let pagination = paginate(response)
          getInvoicesActions.success({
            entities: { invoices: arrayToObject(invoices) }
          })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          if (location_id) {
            routerActions.replace(
              `/admin/locations/${location_id}/invoices/invoices_list${queryFiltersStr}`
            )
          } else {
            routerActions.replace(
              `/admin/invoices/invoices_list/${queryFiltersStr}`
            )
          }

          if (this.mounted) {
            const { total, unpaid, partially_paid, paid, overdue } = json
            this.setState({
              total: total,
              invoices: invoices || [],
              unpaid: unpaid,
              partially_paid: partially_paid,
              paid: paid,
              overdue: overdue,
              percentUnpaid: percent(total, unpaid),
              percentPartially_paid: percent(total, partially_paid),
              percentPaid: percent(total, paid),
              percentOverdue: percent(total, overdue),
              pagination: pagination,
              queryFiltersStr
            })
          }
          return true
        },
        errors => {
          getInvoicesActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.page) {
      // 翻页时初始化页面中 check box 的状态
      if (
        this.state.pagination ||
        !this.state.pagination ||
        this.state.page != this.state.pagination.current_page
      ) {
        this.setState({
          allCheckedBtn: false,
          isAllInvoicesChecked: false,
          selectInvoices: []
        })
      }
      this._loadData({}, nextProps.page)
      return
    }
  },

  printme() {
    const invoices = this.state.selectInvoices
    let invoice_ids = ''
    invoices.map(invoice => {
      invoice_ids += invoice.id
    })
    let invoiceDom = this.refs['printDom'].innerHTML
    let newwindow = window.open('', `printInvoice-${invoice_ids}`)
    newwindow.document.write(invoiceDom)
    newwindow.window.print()
  },

  _setFilter(obj) {
    let newFilt = obj || {}
    let newFilters = Object.assign({}, this.state.filters, newFilt)
    this.setState({ filters: newFilters, page: 1 })

    // force refresh
    this._refresh()
  },

  changeDate(range) {
    this._setFilter(range)
  },

  _refresh() {
    this['location_idDropdown'] && this['location_idDropdown'].hide()
    this['invoiceStatus'] && this['invoiceStatus'].hide()
    this['billDropdown'] && this['billDropdown'].hide()
    this['invoiceType'] && this['invoiceType'].hide()
    this['reviewedDropdown'] && this['reviewedDropdown'].hide()

    setTimeout(this._loadData)
  },

  _clearFilters() {
    this.setState({ filters: {}, page: 1 })
    this.refs.dropDownOrg.clearInput()
    this.invoiceType.clearData()
    this.invoiceStatus && this.invoiceStatus.clearData()
    let statusArray = this.state.invoiceStatusArray
    statusArray.map(item => {
      item.check = false
    })
    this.setState({
      isAllCheckBoxInvoiceStatus: false,
      invoiceStatusArray: statusArray
    })
    this._refresh()
  },

  getOrgs(str, callback) {
    const { location_id } = this.props
    util.setTimeout(
      'invoicesSearchInput',
      () => {
        api
          .getSearchAllType({
            location_id: location_id,
            query: str,
            type: 'org'
          })
          .then(({ json }) => {
            callback && callback(json)
          })
      },
      0
    )
  },

  _selectCallBack(data) {
    var filters = Object.assign({}, this.state.filters)

    if (data) {
      filters = Object.assign({}, filters, {
        customer_type: 'org',
        customer_id: data.id
      })
    } else {
      delete filters.customer_type
      delete filters.customer_id
      filters = Object.assign({}, filters)
    }

    this.setState({ filters })
    this._refresh()
  },

  fromToTextRender(from, to) {
    return from || to
      ? `${from ? moment(from).format('YYYY年MM月DD日') : ''}${to ? '-' + moment(to).format('YYYY年MM月DD日') : ''
      }`
      : '选择日期'
  },

  selectInvoiceTypeCallBack(data) {
    this._setFilter({
      invoice_type: data.map(json => json.id).join(',')
    })
  },

  selectInvoiceStatusCallBack(data) {
    let statusArray = []
    let checkArray = []
    data.map(item => {
      if (
        item.id == 'unchecked' ||
        item.id == 'checked' ||
        item.id == 'confirmed' ||
        item.id == 'check_failed'
      ) {
        checkArray.push(item)
      } else {
        statusArray.push(item)
      }
    })
    this._setFilter({
      status: statusArray.map(json => json.id).join(','),
      check_status: checkArray.map(json => json.id).join(',')
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  _renderFilters() {
    const { from, to } = this.props.filters
    const {
      locations,
      location_id,
      defaultInvoiceTypes,
      defaultInvoiceStatus
    } = this.props
    return (
      <div className="nav-section-action-bar">
        {/* <div className='select-progress'>
          <KBDropSelect
            selectedData={ defaultInvoiceStatus }
            ref={ ref => { this.invoiceStatus = ref } }
            options={ { defaultSelectPrompt: '选择进度' } }
            defaultData={ INVOICES_UPDATE_STATE }
            multi={ true }
            showImg={ false }
            callback={ this.selectInvoiceStatusCallBack }
            dropDown_name='invoiceStatus'
            closeClick={ this.closeDropDowns }
            style={{marginLeft: 0}}/>
        </div> */}
        <div className="select-progress">
          <KBDropdown
            ref={ref => {
              this.billDropdown = ref
            }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'billDropdown')}
              >
                <span>
                  {this.state.filters.invoicing_application_status
                    ? INVOICE_APPLICATION_STATE_OBJ[
                    this.state.filters.invoicing_application_status
                    ]
                    : '发票状态'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li
                    onClick={() =>
                      this._setFilter({ invoicing_application_status: null })
                    }
                  >
                    <span>全部</span>
                  </li>
                  {INVOICE_APPLICATION_STATE.map((state, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          this._setFilter({
                            invoicing_application_status: state.id
                          })
                        }
                      >
                        <span>{state.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        </div>
        <div className="select-progress">
          <KBDropSelect
            selectedData={defaultInvoiceTypes}
            ref={ref => {
              this.invoiceType = ref
            }}
            options={{ defaultSelectPrompt: '账单类型' }}
            defaultData={INVOICES_UPDATE_TYPE}
            multi={true}
            showImg={false}
            callback={this.selectInvoiceTypeCallBack}
            dropDown_name="invoiceType"
            closeClick={this.closeDropDowns}
            style={{ marginLeft: 0 }}
          />
        </div>
        <KBDropdown
          ref={ref => {
            this.reviewedDropdown = ref
          }}
          style={{ marginRight: 20 }}
        >
          <KBDropdown.KBDropdownTrigger>
            <div
              className="task-status-title"
              onClick={this.closeDropDowns.bind(null, 'reviewedDropdown')}
            >
              <span>
                {typeof this.state.filters.is_reviewed == 'boolean' ||
                  this.state.filters.is_reviewed == ('true' || 'false')
                  ? this.state.filters.is_reviewed
                    ? '已审核'
                    : '未审核'
                  : '审核状态'}
              </span>
              <span className="task-deg" />
            </div>
          </KBDropdown.KBDropdownTrigger>
          <KBDropdown.KBDropdownContent>
            <div>
              <ul className="task-type">
                {INVOICES_IS_REVIEWED.map((state, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => this._setFilter({ is_reviewed: state.id })}
                    >
                      <span>{state.name}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
          </KBDropdown.KBDropdownContent>
        </KBDropdown>

        {location_id && Object.keys(locations).length > 0 ? (
          <div
            className="task-status-title"
            style={{ display: 'inline-block' }}
          >
            <i className="iconfont icon-position" />
            <span>{locations[location_id].name || ''}</span>
          </div>
        ) : (
            <KBDropdown
              ref={ref => {
                this.location_idDropdown = ref
              }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'location_idDropdown')}
                >
                  <i className="iconfont icon-position" />
                  <span>
                    {this.state.filters.location_id &&
                      Object.keys(locations).length > 0
                      ? locations[this.state.filters.location_id].name
                      : '选择分店'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ location_id: '' })}>
                      全部
                  </li>
                    {locations &&
                      Object.keys(locations).map((location_id, index) => {
                        let location = locations[location_id]
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              this._setFilter({ location_id: location.id })
                            }
                          >
                            <span>{location.name}</span>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          )}
        <KBRangeDate
          format="YYYY/MM/DD"
          from={from || ''}
          to={to || ''}
          callback={this.changeDate}
          closeClick={this.closeDropDowns}
          dropDown_name="dateDropDown"
          ref={ref => {
            this.dateDropDown = ref
          }}
        />
      </div>
    )
  },

  changeInvoice(checked, data) {
    var invoices = Object.assign([], this.state.selectInvoices)
    if (checked) {
      invoices.push(data)
      // 当选中的 invoice 逐个增加时的判断
      if (invoices.length == 10) {
        this.setState({
          selectInvoices: invoices,
          allCheckedBtn: true,
          isAllInvoicesChecked: true
        })
      } else {
        this.setState({
          selectInvoices: invoices,
          allCheckedBtn: false
        })
      }

      return
    }

    let removeIndex = invoices.findIndex(json => json.id == data.id)

    if (removeIndex == -1) {
      return
    }

    invoices.splice(removeIndex, 1)
    // 当选中的 invoice 逐个减少时的判断
    if (invoices.length == 10) {
      this.setState({
        selectInvoices: invoices,
        allCheckedBtn: true
      })
    } else {
      this.setState({
        selectInvoices: invoices,
        allCheckedBtn: false
        // isAllInvoicesChecked : false
      })
    }
  },

  checkAllInvoices() {
    const {
      isAllInvoicesChecked,
      invoices,
      allCheckedBtn,
      pagination,
      page
    } = this.state
    let isAllInvoicesChecked_now = !allCheckedBtn
    let invoices_now = invoices
    if (!isAllInvoicesChecked_now) {
      invoices_now = []
    }
    this.setState({
      isAllInvoicesChecked: isAllInvoicesChecked_now,
      selectInvoices: invoices_now,
      allCheckedBtn: isAllInvoicesChecked_now
    })
  },

  auditInvoice() {
    const invoices = this.state.selectInvoices
    const {
      updateInvoicesActions,
      successActions,
      apiCallFailureActions,
      location_id
    } = this.props
    const { selectInvoices } = this.state
    if (!this.isAuditInvoice()) {
      apiCallFailureActions({
        status: 'error',
        message: '您没有选择任何账单!'
      })
      return
    }
    let params = {
      ids: invoices.map(json => json.id).join(','),
      pass: true
    }

    if (location_id) {
      params.location_id = location_id
    }

    KBPopoverConfirm({
      name: '账单审核',
      context: '是否确定所选账单全部通过审核?',
      callback: () => {
        return api.putBatchReview(params).then(
          ({ json, response }) => {
            const { invoices } = json
            updateInvoicesActions.success({
              entities: { invoices: arrayToObject(invoices) }
            })
            let oldInvoice = this.state.invoices
            let newInvoice = Object.values(arrayToObject(invoices))

            this.setState({
              selectInvoices: [],
              invoices: mergeArrayWithKey(oldInvoice, newInvoice, 'id')
            })
            KBPopover.close()
            successActions({ message: '审核通过' })
          },
          errors => {
            apiCallFailureActions({
              status: 'error',
              message:
                (errors._error && errors._error.message) || '审核账单失败'
            })
          }
        )
      }
    })
  },

  applyInvoice() {
    const invoices = this.state.selectInvoices
    // 对账单按照 invoice 的 sales_customer 进行分组
    let tempIds = {}
    invoices &&
      invoices.map(json => {
        let tempIdKeys = Object.keys(tempIds)
        let s_id = json.sales_customer.id
        if (tempIdKeys.includes(String(s_id))) {
          tempIds[s_id].push(json)
        } else {
          tempIds[s_id] = []
          tempIds[s_id].push(json)
        }
      })

    let arrayedInvoices = Object.values(tempIds)

    this.setState({
      arrayedInvoices
    })

    setTimeout(this.addMiddleFunc, 100)
  },

  addMiddleFunc() {
    this.addInvoicesApplication(0)
  },

  addInvoicesApplication(index) {
    const { arrayedInvoices } = this.state

    if (index == arrayedInvoices.length) {
      return
    }

    let invoices = arrayedInvoices[index]
    KBPopover.show(
      <InvoicesApplication
        type="create"
        invoices={invoices}
        showSelf={this.addInvoicesApplication}
        updateInvoices={this._setFilter}
        index={index}
        length={arrayedInvoices.length}
      />
    )
  },

  getFormatedInvoices() {
    const { selectInvoices } = this.state

    // 对账单按照 location_id 进行分组
    let tempIds = {}
    selectInvoices &&
      selectInvoices.map(json => {
        let tempIdKeys = Object.keys(tempIds)
        let s_id = json.location_id
        if (tempIdKeys.includes(String(s_id))) {
          tempIds[s_id].push(json)
        } else {
          tempIds[s_id] = []
          tempIds[s_id].push(json)
        }
      })

    let arrayedInvoices = Object.values(tempIds)

    let formatedInvoices = []
    arrayedInvoices &&
      arrayedInvoices.map(d => {
        let loc_id = d[0].location_id
        let ids = []
        d.map(data => {
          ids.push(data.id)
        })
        formatedInvoices.push({ loc_id, ids })
      })

    return formatedInvoices
  },

  batchFinishCallback() {
    const { successActions } = this.props

    setTimeout(() => {
      this._setFilter()
      this.setState({
        selectInvoices: []
      })
      KBPopover.close()
      successActions({ message: '完成开票成功' })
    }, 500)
  },

  batchFinishInvoiceApp(invoices) {
    KBPopover.show(
      <InvoicesApplicationFinishForm
        location_id={this.props.location_id}
        locIdGroupedInvoices={invoices}
        callback={this.batchFinishCallback}
      />
    )
  },

  processAndFinishInvoice(type) {
    let formatedInvoices = this.getFormatedInvoices()

    if (type == 'process') {
      return KBPopoverConfirm({
        name: '账单处理开票',
        context: '是否确定所选账单全部处理开票?',
        callback: () => {
          return this.processInvoiceApp(formatedInvoices)
        }
      })
    }

    return this.batchFinishInvoiceApp(formatedInvoices)
  },

  processInvoiceApp(invoices) {
    const { successActions } = this.props

    invoices &&
      invoices.map(json => {
        api.processInvoiceApplicationBatch({
          location_id: json.loc_id,
          ids: json.ids.join(',')
        })
      })
    setTimeout(() => {
      this._setFilter()
      this.setState({
        selectInvoices: []
      })
      KBPopover.close()
      successActions({ message: '处理开票成功' })
    }, 500)
  },

  batchSendFinanceEmail() {
    const { selectInvoices } = this.state
    let invoice_ids = []
    selectInvoices.map(invoice => {
      invoice_ids.push(invoice.id)
    })
    KBPopover.show(<InvoicesSendEmailForm invoice_ids={invoice_ids} />)
  },

  batchCheckInvoices() {
    const invoices = this.state.selectInvoices
    const {
      updateInvoicesActions,
      successActions,
      apiCallFailureActions,
      location_id
    } = this.props
    const { selectInvoices } = this.state
    if (!this.isAuditInvoice()) {
      apiCallFailureActions({
        status: 'error',
        message: '您没有选择任何账单!'
      })
      return
    }
    let params = {
      ids: invoices.map(json => json.id).join(',')
    }

    if (location_id) {
      params.location_id = location_id
    }

    params.pass = true

    KBPopoverConfirm({
      name: '账单批量核销',
      context: '是否确定核销全部所选账单?',
      callback: () => {
        return api.putBatchCheck(params).then(
          ({ json, response }) => {
            const { invoices } = json
            updateInvoicesActions.success({
              entities: { invoices: arrayToObject(invoices) }
            })
            let oldInvoice = this.state.invoices
            let newInvoice = Object.values(arrayToObject(invoices))

            this.setState({
              selectInvoices: [],
              invoices: mergeArrayWithKey(oldInvoice, newInvoice, 'id')
            })
            KBPopover.close()
            successActions({ message: '核销成功！' })
          },
          errors => {
            apiCallFailureActions({
              status: 'error',
              message:
                (errors._error && errors._error.message) || '账单核销失败'
            })
          }
        )
      }
    })
  },

  resetSelectedInvoices(invoices) {
    let oldInvoice = this.state.invoices
    let newInvoice = Object.values(arrayToObject(invoices))
    this.setState({
      selectInvoices: [],
      invoices: mergeArrayWithKey(oldInvoice, newInvoice, 'id')
    })
  },

  isAuditInvoice() {
    const {
      user,
      isFinance,
      isOperator,
      isOnlyFinance,
      checkUsers,
      isSpaceAdmin
    } = this.props
    const { selectInvoices, loading } = this.state
    const isFinanceOrOperator = isFinance || isOperator
    const isCheckUser = loading
      ? false
      : isSpaceAdmin
        ? true
        : checkUsers && checkUsers.length != 0
          ? !checkUsers.every(data => {
            return data.id != user.id
          })
          : isOnlyFinance

    if (selectInvoices.length == 0) {
      return false
    }

    let isAllUnpaidAndReviewed =
      isFinanceOrOperator &&
      selectInvoices.every(ele => {
        return ele.status !== 'paid' && ele.is_reviewed
      })
    let isAllCheckPaid =
      isFinanceOrOperator &&
      selectInvoices.every(ele => {
        return (
          ele.check_status === 'checked' || ele.check_status === 'confirmed'
        )
      })
    let isAllNotApp =
      isFinanceOrOperator &&
      selectInvoices.every(
        ele =>
          !ele.invoicing_application ||
          ele.invoicing_application.status === 'aborted'
      )
    let isAllPending =
      isFinanceOrOperator &&
      selectInvoices.every(ele => {
        return (
          ele.invoicing_application &&
          ele.invoicing_application.status === 'pending'
        )
      })
    let isAllProcess =
      isFinanceOrOperator &&
      selectInvoices.every(ele => {
        return (
          ele.invoicing_application &&
          ele.invoicing_application.status === 'process'
        )
      })
    let isAllNotReviewed =
      isFinanceOrOperator && selectInvoices.every(ele => !ele.is_reviewed)
    let isAllPaidAndNotChecked =
      isCheckUser &&
      selectInvoices.every(
        ele =>
          (ele.status === 'paid' ||
            (ele.status === 'refunded' && ele.invoice_type == 'refund')) &&
          ele.check_status === 'unchecked' &&
          ele.check_status !== 'confirmed'
      )

    return (
      <div style={{ height: 36 }} className="invoice-table-upon">
        {isAllNotReviewed ? (
          <button
            className="bordered-btn m-none m-right-sm"
            onClick={this.auditInvoice}
          >
            审核通过
          </button>
        ) : null}
        {isAllPaidAndNotChecked ? (
          <button
            className="bordered-btn m-none m-right-sm"
            onClick={() => {
              this.batchCheckInvoices()
            }}
          >
            批量核销
          </button>
        ) : null}
        <button
          className="c-btn-secondary m-none m-right-sm"
          onClick={this.printme}
        >
          打印账单
        </button>
        {isAllCheckPaid && isAllNotApp ? (
          <button
            className="bordered-btn m-none m-right-sm"
            onClick={this.applyInvoice}
          >
            申请发票
          </button>
        ) : null}
        {isAllPending && isAllCheckPaid ? (
          <button
            className="bordered-btn m-none m-right-sm"
            onClick={() => {
              this.processAndFinishInvoice('process')
            }}
          >
            处理开票
          </button>
        ) : null}
        {isAllProcess && isAllCheckPaid ? (
          <button
            className="bordered-btn m-none m-right-sm"
            onClick={() => {
              this.processAndFinishInvoice('finish')
            }}
          >
            完成开票
          </button>
        ) : null}
        {isAllUnpaidAndReviewed ? (
          <button
            className="bordered-btn m-none"
            onClick={() => {
              this.batchSendFinanceEmail()
            }}
          >
            发送账单给客户
          </button>
        ) : null}
      </div>
    )
  },

  invoiceReport() {
    const { filters } = this.state
    const { location_id } = this.props

    let reportFilters = filters
    if (location_id) {
      reportFilters.location_id = location_id
    }
    KBPopover.show(
      <InvoicesReport reportType="invoices" filters={reportFilters} />
    )
  },

  allCheckBoxInvoiceStatus() {
    const { isAllCheckBoxInvoiceStatus, invoiceStatusArray } = this.state
    let status = !isAllCheckBoxInvoiceStatus
    let statusArray = invoiceStatusArray
    statusArray.map(item => {
      item.check = status
    })
    isOnceStatus = false
    this.setState({
      isAllCheckBoxInvoiceStatus: status,
      invoiceStatusArray: statusArray
    })

    if (status) {
      this.selectInvoiceStatusCallBack(statusArray)
    } else {
      this.selectInvoiceStatusCallBack([])
    }
  },
  checkBoxInvoiceStatus(index) {
    const { invoiceStatusArray } = this.state
    let statusArray = invoiceStatusArray
    let check = statusArray[index].check
    statusArray[index].check = !check

    let checkArray = statusArray.filter(item => {
      return item.check
    })
    isOnceStatus = false
    this.setState({
      invoiceStatusArray: statusArray,
      isAllCheckBoxInvoiceStatus:
        checkArray.length == statusArray.length ? true : false
    })

    this.selectInvoiceStatusCallBack(checkArray)
  },

  invoicesJsx() {
    const {
      total,
      invoices,
      unpaid,
      partially_paid,
      paid,
      overdue,
      percentUnpaid,
      percentPartially_paid,
      percentPaid,
      percentOverdue,
      pagination,
      loading,
      selectInvoices,
      queryFiltersStr,
      refund_type,
      isAllCheckBoxInvoiceStatus,
      invoiceStatusArray
    } = this.state
    const { location_id, isFinance, isManager, isOperator } = this.props
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    const tdStyle = {
      paddingTop: '140px',
      textAlign: 'center',
      color: '#6e6e6e',
      fontSize: '16px',
      opacity: 0.8,
      cursor: 'default'
    }
    return (
      <div>
        <header className="nav-header">
          <div className=" clear-fix">
            <div className="nav-section-header-title f-left">
              <span>财务</span>
            </div>
            {isFinance || isManager ? (
              <div className="f-right">
                <button
                  className="c-btn-secondary"
                  onClick={this.invoiceReport}
                >
                  导出账单
                </button>
              </div>
            ) : null}
          </div>
        </header>
        <div ref="printDom" style={{ display: 'none' }}>
          <InvoicePrintHtml
            invoices={selectInvoices}
            location_id={location_id}
            refund_type={refund_type}
          />
        </div>
        <div className="nav-section-content-container">
          <div style={{ margin: '30px 0 15px' }}>
            <KBSearchList
              ref="dropDownOrg"
              getUsers={this.getOrgs}
              selectCallBack={this._selectCallBack}
              placeholder="公司搜索"
              style={{ display: 'inline-block', width: '100%' }}
              itemWidth="full"
            />
          </div>
          <div>
            <span style={{ marginBottom: 30 }}>收款状态</span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 20
              }}
            >
              <label
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginRight: 30
                }}
              >
                <KBCheckBox
                  style={{ marginRight: 10 }}
                  checked={isAllCheckBoxInvoiceStatus}
                  callback={this.allCheckBoxInvoiceStatus}
                />
                全选
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 20,
                flexWrap: 'wrap'
              }}
            >
              {invoiceStatusArray.map((item, index) => {
                return (
                  <label
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginRight: 15,
                      marginTop: 10,
                      width: 100
                    }}
                    key={index}
                  >
                    <KBCheckBox
                      style={{ marginRight: 10 }}
                      checked={item.check}
                      callback={() => this.checkBoxInvoiceStatus(index)}
                    />
                    {item.name}
                  </label>
                )
              })}
            </div>
          </div>
          <div style={{ marginBottom: 30 }}>
            {this._renderFilters()}
            {selectFilters.length > 0 ? (
              <div
                className="clear-criteria"
                style={{ marginTop: 5 }}
                onClick={this._clearFilters}
              >
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            ) : null}
          </div>

          <div className="m-updown t-center">
            <div
              className="chart-box t-left a-hover"
              style={{ marginRight: '100px' }}
              onClick={() => this._setFilter({ status: 'unpaid' })}
            >
              <a>
                <KBStatisticNumber
                  number={unpaid}
                  title={percentUnpaid + '%'}
                  unit="个"
                  name="待支付"
                />
              </a>
            </div>
            <div
              className="chart-box t-left a-hover"
              style={{ marginRight: '100px' }}
              onClick={() => this._setFilter({ status: 'partially_paid' })}
            >
              <a>
                <KBStatisticNumber
                  number={partially_paid}
                  title={percentPartially_paid + '%'}
                  unit="个"
                  name="部分支付"
                />
              </a>
            </div>
            <div
              className="chart-box t-left a-hover"
              style={{ marginRight: '100px' }}
              onClick={() => this._setFilter({ status: 'paid' })}
            >
              <a>
                <KBStatisticNumber
                  number={paid}
                  title={percentPaid + '%'}
                  unit="个"
                  name="已支付"
                />
              </a>
            </div>
            <div
              className="chart-box t-left a-hover"
              onClick={() => this._setFilter({ status: 'overdue' })}
            >
              <a>
                <KBStatisticNumber
                  number={overdue}
                  title={percentOverdue + '%'}
                  unit="个"
                  name="已逾期"
                />
              </a>
            </div>
          </div>
          <KBLoadingContainer loading={loading}>
            {this.isAuditInvoice()}
            <table className="content-table edit-table table-align">
              <thead style={{ whiteSpace: 'nowrap' }}>
                <tr>
                  <th>
                    <KBCheckBox
                      style={{ marginTop: 2 }}
                      data={invoices}
                      callback={this.checkAllInvoices}
                      checked={this.state.allCheckedBtn}
                    />
                  </th>
                  <th style={{ width: 80 }}>收款状态</th>
                  <th>开票状态</th>
                  <th style={{ width: 115 }}>账单类型</th>
                  <th>公司名称</th>
                  <th>应付金额</th>
                  <th>待付金额</th>
                  <th>到期日期</th>
                  <th>实际付款日</th>
                  <th>收款信息</th>
                </tr>
              </thead>
              {invoices && invoices.length ? (
                invoices.map((json, index) => {
                  let obligation =
                    Math.round(json.total_amount * 100) / 100 -
                    Math.round(json.paid_amount * 100) / 100
                  let isReviewed = json.is_reviewed
                  return (
                    <InvoiceList
                      key={index}
                      json={json}
                      location_id={location_id}
                      isReviewed={isReviewed}
                      selectInvoices={this.state.selectInvoices}
                      callback={this.changeInvoice}
                      obligation={obligation}
                      isTeam={false}
                    />
                  )
                })
              ) : (
                  <tbody>
                    <tr style={{ border: 0, backgroundColor: '#ffffff' }}>
                      <td style={tdStyle} colSpan="9">
                        暂无数据
                    </td>
                    </tr>
                  </tbody>
                )}
            </table>
            {location_id ? (
              <KBPagination
                pagination={pagination}
                template={`/admin/locations/${location_id}/invoices/invoices_list${queryFiltersStr
                  ? queryFiltersStr + '&page=#PAGE#'
                  : '?page=#PAGE#'
                  }`}
              />
            ) : (
                <KBPagination
                  pagination={pagination}
                  template={`/admin/invoices/invoices_list${queryFiltersStr
                    ? queryFiltersStr + '&page=#PAGE#'
                    : '?page=#PAGE#'
                    }`}
                />
              )}
          </KBLoadingContainer>
        </div>
      </div>
    )
  },

  render() {
    const { location_id } = this.props
    return (
      <div className="kb-content-container clear-fix">
        <section className="f-left" style={{ width: '100%' }}>
          {this.invoicesJsx()}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  const { user } = state
  const currentUser = selectors.getCurrentUser(state)

  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page

  let location_id = props.params.id

  let locations = selectors.getSerializeLocationObj(state)
  let checkUsers = location_id
    ? selectors.getTeamRolesOfLocation(state, location_id, 'invoice_check')
    : []

  let sections = props.location.pathname.split('/')

  let isFinance = canAccessLocationFinance(user, location_id)
  let isManager = canAccessLocation(user, location_id)
  let isOperator = canAccessLocationRoles(user, location_id, [
    'location_operator'
  ])
  let isOnlyFinance = canAccessLocationRoles(user, location_id, ['finance'])
  let isSpaceAdmin = canAdminSpace(user)
  var FILTER_STATE
  if (location_id) {
    FILTER_STATE = [
      'status',
      'from',
      'to',
      'customer_type',
      'customer_id',
      'stage',
      'is_reviewed',
      'invoice_type',
      'invoicing_application_status'
    ]
  } else {
    FILTER_STATE = [
      'status',
      'from',
      'to',
      'location_id',
      'customer_type',
      'customer_id',
      'stage',
      'is_reviewed',
      'invoice_type',
      'invoicing_application_status'
    ]
  }

  var params = getFilterAndQuery(location.query, FILTER_STATE)

  let defaultInvoiceTypes = []
  if (params.filters && params.filters.invoice_type) {
    let invoice_types = params.filters.invoice_type.split(',')
    defaultInvoiceTypes = INVOICES_UPDATE_TYPE.filter(data =>
      invoice_types.find(id => id == data.id)
    )
  }
  let defaultInvoiceStatus = []
  if (params.filters && params.filters.status) {
    let statuses = params.filters.status.split(',')
    defaultInvoiceStatus = INVOICES_UPDATE_STATE.filter(data =>
      statuses.find(id => id == data.id)
    )
  }
  if (!params.filters.status&&isOnceStatus){
    params.filters.status = INVOICES_UPDATE_STATE
                              .filter(x=>x.id!=='unchecked'&&x.id!=='checked'&&x.id!=='confirmed'&&x.id!=='check_failed')
                              .map(x => x.id).join(',')

  }
  if(!params.filters.check_status&&isOnceStatus) params.filters.check_status="unchecked,checked,confirmed,check_failed"
    //item.id == 'unchecked' ||
    // item.id == 'checked' ||
    // item.id == 'confirmed' ||
    // item.id == 'check_failed'

  return {
    page,
    defaultInvoiceTypes,
    defaultInvoiceStatus,
    locations,
    location_id,
    isFinance,
    isManager,
    isOperator,
    user: currentUser,
    isOnlyFinance,
    checkUsers,
    isSpaceAdmin,
    ...params
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getInvoicesActions: bindActionCreators(invoicesActions.all, dispatch),
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}
  return errors
}

const formConfig = {
  form: 'InvoicesForm',
  fields: ['status', 'from', 'to'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoicesInfo)
