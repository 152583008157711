import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput
} from 'components'
import { sectionsActions } from 'app/actions'
import * as selectors from 'app/selectors'
import * as constants from 'app/constants'

var FaqSectionForm = React.createClass({
  createFaqFiles(model) {
    const {
      createSectionActions,
      updateSectionActions,
      section,
      state
    } = this.props

    if (state) {
      return api.updateSections(section.id, model).then(json => {
        updateSectionActions.success(json)
        KBPopover.close()
        return true
      })
    }

    return api.createSections(model).then(json => {
      createSectionActions.success(json)
      KBPopover.close()
      return true
    })
  },

  render() {
    const {
      fields: { title, location_id },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { locations, state } = this.props
    return (
      <form onSubmit={handleSubmit(this.createFaqFiles)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">{state ? '修改分类' : '创建分类'}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={title}
              title="标题"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton className="certain-btn" submitting={submitting}>
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { section } = props
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createSectionActions: bindActionCreators(sectionsActions.create, dispatch),
    updateSectionActions: bindActionCreators(sectionsActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.title)) {
    errors.title = '请输入分类标题'
  }

  return errors
}

const formConfig = {
  form: 'FaqSectionForm',
  fields: ['title', 'location_id'],
  validate: validate,
  touchOnBlur: false
}

FaqSectionForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(FaqSectionForm)

export default FaqSectionForm
