import React, { Component } from 'react'
import Select from './Select'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'

const MeetingRoomSelect = React.createClass({
  getInitialState() {
    return {}
  },

  render() {
    const { props } = this
    const {locations} = props
    return (
      <Select
        options={locations.map(i => ({ label: i.name, value: i.id }))}
        placeholder="请选择分店"
        showSearch
        {...props}
      />
    )
  }
})

function mapStateToProps(state, props) {
  const locations = selectors.getLocations(state) || []

  return {
    locations
  }
}

export default connect(mapStateToProps)(MeetingRoomSelect)
