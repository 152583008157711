import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { api, apiUtils } from 'app/services'
import classNames from 'classnames'
import { connect } from 'react-redux'
import * as actions from 'app/actions'
import {
  KBPopover,
  KBLoadingContainer,
  KBStatisticNumber,
  KBQiniuImage,
  KBTableWithoutData
} from 'components'
import * as selectors from 'app/selectors'
import { KBPopoverConfirm } from 'components/tools'
import { AREAS_STATE, AREAS_TYPE } from 'app/constants'
import {
  percent,
  isXingKuSpace,
  listIsNullCallBack,
  createFengMap,
  fengmap
} from 'utils/kbUtil'
import _ from 'lodash'

const DEFAULT_COLOR = '#CCCCCC'
const AREA_COLOR = '#000000'
const FREE_COLOR = '#749aef'
const ASSIGN_COLOR = '#9ED562'
const EXPIRING_COLOR = '#F66E73'

const AREA_STATE_COLOR = {
  free: FREE_COLOR,
  assigned: ASSIGN_COLOR,
  expiring: EXPIRING_COLOR
}

// 分区平面图
var AreaFloorPlan = React.createClass({
  displayName: 'AreaFloorPlan',

  getInitialState() {
    return {
      filters: {
        state: 'all',
        type: 'all'
      }
    }
  },
  componentDidMount() {
    const map = createFengMap({
      idName: 'map-container',
      level: 'false',
      loadComplete: this.loadComplete
    })
    // 点击事件
    map.on('mapClickNode', this.mapClickNode)
    this.map = map
    this.searchAnalyser = this.map.searchAnalyser
  },

  loadComplete() {
    this.map.mapScaleLevel = 21
    this.renderColorAllDefault()
    this.renderColorOfArea()
  },

  renderColorAllDefault() {
    const searchReq = new fengmap.FMSearchRequest(fengmap.FMNodeType.MODEL)
    this.searchAnalyser.query(searchReq, function(request, result) {
      result.map(res => {
        res && res.setColor(DEFAULT_COLOR)
      })
    })
  },

  renderColorOfArea(filters = { state: 'all', type: 'all' }) {
    const { areas } = this.props
    const { state, type } = filters

    areas &&
      areas.map(area => {
        let defaultColor = AREA_STATE_COLOR[area.state] || DEFAULT_COLOR
        let area_color = defaultColor
        if (state === 'all' && type === 'all') {
          area_color = defaultColor
        }
        if (state === 'all' && type !== 'all') {
          area_color = area.area_type === type ? defaultColor : DEFAULT_COLOR
        }
        if (state !== 'all' && type === 'all') {
          area_color = area.state === state ? defaultColor : DEFAULT_COLOR
        }
        if (state !== 'all' && type !== 'all') {
          area_color =
            area.state === state && area.area_type === type
              ? defaultColor
              : DEFAULT_COLOR
        }
        this.renderColorOfMapId(area.map_id, area_color)
      })
  },

  renderColorOfMapId(map_id, color) {
    const searchReq = new fengmap.FMSearchRequest(fengmap.FMNodeType.MODEL)
    searchReq.FID(map_id)
    this.searchAnalyser.query(searchReq, (request, result) => {
      if (!result.length) {
        return
      }
      result[0].setColor(color)
    })
  },

  mapClickNode(event) {
    var model = event
    var d
    var eventID = ''
    switch (event.nodeType) {
      case fengmap.FMNodeType.FLOOR:
        if (event.eventInfo.eventID == eventID) return
        d = {
          name: '地图',
          x: event.eventInfo.coord.x,
          y: event.eventInfo.coord.y
        }
        break
      case fengmap.FMNodeType.MODEL:
        if (event.eventInfo.eventID == eventID) {
          this.map.selectNull()
          return
        }
        // 过滤类型为墙的model
        if (event.typeID == '30000') {
          // 其他操作
          return
        }
        //模型高亮
        this.map.storeSelect(model)
        this.showModalMapId(event, model.FID)
        break
      case fengmap.FMNodeType.FACILITY:
      case fengmap.FMNodeType.IMAGE_MARKER:
        eventID = event.eventInfo.eventID
        d = {
          name: '公共设施',
          x: event.target.x,
          y: event.target.y
        }
        break
      default:
        break
    }
  },

  showModalMapId(event, map_id) {
    const { areas } = this.props
    const selectArea = areas && areas.find(area => area.map_id === map_id)
    if (selectArea) {
      var ctlOpt = new fengmap.controlOptions({
        mapCoord: {
          //设置弹框的x轴
          x: event.target.x,
          //设置弹框的y轴
          y: event.target.y,
          height: 6, //控制信息窗的高度
          //设置弹框位于的楼层
          groupID: 1
        },
        //设置弹框的宽度
        width: 250,
        //设置弹框的高度
        height: 80,
        marginTop: 10,
        //设置弹框的内容
        content: `分区名称: ${selectArea.name}`
      })

      //添加弹框到地图上
      var popMarker = new fengmap.FMPopInfoWindow(this.map, ctlOpt)

      //popMarker.close(); 关闭信息窗
    }
  },

  filterState(state, field) {
    const { id } = state
    const filters_new = {
      ...this.state.filters,
      [field]: id
    }
    this.setState({
      filters: filters_new
    })
    this.renderColorOfArea(filters_new)
  },

  render() {
    const { location_entering_rate_calc_type, location, space_id } = this.props
    const {
      filters: { state, type }
    } = this.state
    let statistic = location && location.statistic
    const enteringRate = percent(
      statistic ? statistic.desks_count : 0,
      statistic ? statistic.renting_desks_count : 0
    )

    return (
      <div style={{ height: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#f5f5f5'
          }}
        >
          <div>
            <div style={{ marginBottom: 30, display: 'flex' }}>
              {AREAS_STATE.map((json, index) => {
                const isCheckedState = json.id === state
                return (
                  <div
                    key={index}
                    className={`border-show_status ${
                      isCheckedState ? 'border-show_status_active' : ''
                    }`}
                    onClick={() => {
                      this.filterState(json, 'state')
                    }}
                  >
                    {json.id !== 'all' ? (
                      <span className={`color-show ${'area-' + json.id}`} />
                    ) : (
                      ''
                    )}
                    <span className="status-text">{json.name}</span>
                  </div>
                )
              })}
            </div>
            <div style={{ display: 'flex', marginBottom: 30 }}>
              {AREAS_TYPE.map((json, index) => {
                const isCheckedType = json.id === type
                return (
                  <div
                    key={index}
                    className={`border-show_status ${
                      isCheckedType ? 'border-show_status_active' : ''
                    }`}
                    onClick={() => {
                      this.filterState(json, 'type')
                    }}
                  >
                    <span className="status-text">{json.name}</span>
                  </div>
                )
              })}
            </div>
          </div>
          {location && location.statistic ? (
            <div className="chart-out_box t-center">
              <div className="chart-box t-left" style={{ marginRight: 10 }}>
                {isXingKuSpace(space_id) ? (
                  <KBStatisticNumber
                    number={
                      parseFloat(statistic.outer_entering_rate || 0).toFixed(
                        2
                      ) + '%'
                    }
                    name="实际入驻率"
                    style={{ backgroundColor: '#f5f5f5' }}
                  />
                ) : (
                  <KBStatisticNumber
                    number={enteringRate + '%'}
                    name="工位使用率"
                    style={{ backgroundColor: '#f5f5f5' }}
                  />
                )}
              </div>
              <div className="chart-box t-left" style={{ marginRight: 10 }}>
                {isXingKuSpace(space_id) ? (
                  <KBStatisticNumber
                    number={
                      location_entering_rate_calc_type === 'by_desk'
                        ? statistic.total_capacity || 0
                        : parseFloat(statistic.physical_capacity || 0).toFixed(
                            2
                          )
                    }
                    unit={
                      location_entering_rate_calc_type === 'by_desk'
                        ? '个'
                        : '㎡'
                    }
                    name={
                      location_entering_rate_calc_type === 'by_desk'
                        ? '出租工位总和'
                        : '出租面积总和'
                    }
                    style={{ backgroundColor: '#f5f5f5' }}
                  />
                ) : (
                  <KBStatisticNumber
                    number={
                      location_entering_rate_calc_type === 'by_desk'
                        ? statistic.total_capacity || 0
                        : parseFloat(statistic.total_capacity || 0).toFixed(2)
                    }
                    unit={
                      location_entering_rate_calc_type === 'by_desk'
                        ? '个'
                        : '㎡'
                    }
                    name={
                      location_entering_rate_calc_type === 'by_desk'
                        ? '额定总工位数'
                        : '额定总面积数'
                    }
                    style={{ backgroundColor: '#f5f5f5' }}
                  />
                )}
              </div>
              <div className="chart-box t-left" style={{ marginRight: 10 }}>
                <KBStatisticNumber
                  number={statistic.desks_count || 0}
                  unit="个"
                  name="实际总工位数"
                  style={{ backgroundColor: '#f5f5f5' }}
                />
              </div>
              <div className="chart-box t-left" style={{ marginRight: 10 }}>
                {isXingKuSpace(space_id) ? (
                  <KBStatisticNumber
                    number={
                      location_entering_rate_calc_type === 'by_desk'
                        ? statistic.occupied_desks_count || 0
                        : parseFloat(
                            statistic.occupied_desks_count || 0
                          ).toFixed(2)
                    }
                    unit={
                      location_entering_rate_calc_type === 'by_desk'
                        ? '个'
                        : '㎡'
                    }
                    name={
                      location_entering_rate_calc_type === 'by_desk'
                        ? '已出租工位总和'
                        : '已出租面积总和'
                    }
                    style={{ backgroundColor: '#f5f5f5' }}
                  />
                ) : (
                  <KBStatisticNumber
                    number={
                      location_entering_rate_calc_type === 'by_desk'
                        ? statistic.occupied_desks_count || 0
                        : parseFloat(
                            statistic.occupied_desks_count || 0
                          ).toFixed(2)
                    }
                    unit={
                      location_entering_rate_calc_type === 'by_desk'
                        ? '个'
                        : '㎡'
                    }
                    name={
                      location_entering_rate_calc_type === 'by_desk'
                        ? '已出租工位数'
                        : '已出租面积数'
                    }
                    style={{ backgroundColor: '#f5f5f5' }}
                  />
                )}
              </div>
              <div className="chart-box t-left">
                <KBStatisticNumber
                  number={statistic.renting_desks_count || 0}
                  unit="个"
                  name="实际入驻工位数"
                  style={{ backgroundColor: '#f5f5f5' }}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div
          id="map-container"
          style={{ height: 'calc(100% - 180px)', position: 'relative' }}
        />
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const space_id = Object.keys(state.entities.spaces || {})[0]
  const loc_id = props.params.id
  const areas = Object.assign([], selectors.getAreasOfLocation(state, loc_id))
  const location = selectors.getLocation(state, loc_id)

  return {
    areas,
    location,
    space_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    successStateAction: bindActionCreators(actions.successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaFloorPlan)
