import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { api, apiUtils } from 'app/services'
import { opportunitiesActions, apiCallFailure } from 'app/actions'
import FaqSectionForm from './FaqSectionForm'
import { KBLoadingContainer, KBAvatar, KBPopover } from 'components'
import { connect } from 'react-redux'
import { OPPORTUNITY_STATES } from 'app/constants'
import { util, momentFromNow } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import moment from 'moment'
import FaqSectionBox from './FaqSectionBox'

var FaqMessage = React.createClass({
  displayName: 'FaqMessage',
  getInitialState() {
    return {}
  },

  createSection() {
    KBPopover.show(<FaqSectionForm />)
  },

  render() {
    const { sections } = this.props
    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span>FAQ管理</span>
          </div>
          <button className="bordered-btn f-right" onClick={this.createSection}>
            <i className="iconfont icon-add" />
            添加新栏目
          </button>
        </header>
        <div className="nav-section-content-container">
          <p style={{ marginBottom: 15, marginTop: 15 }}>
            编辑App中"常见问题"模块内容，用户可以在App中方便的查阅常见问题解答，减少客服工作。
          </p>
          {sections.map((section, index) => {
            return <FaqSectionBox key={index} section={section} isShow={true} />
          })}
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const sections = selectors.getSections(state)
  sections.sort(function(a, b) {
    return a.position - b.position
  })
  return {
    sections
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqMessage)
