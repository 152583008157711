import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { appModulesActions } from 'app/actions'
import { checkObject, getEntitiesJsonArray } from 'utils/kbUtil'
import {
  KBInput,
  KBPopover,
  KBFormError,
  KBButton,
  KBLogoCropper,
  KBSelect,
  KBRadio,
  KBTextarea,
  KBCheckBox
} from 'components'
import * as constants from 'app/constants'
import * as selectors from 'app/selectors'
import { APPLICATION_FORM } from 'app/constants/hint'

var ApplicationForm = React.createClass({
  displayName: 'ApplicationForm',

  getInitialState() {
    return {
      useDefaultLogo: false
    }
  },

  createLockdForm(model) {
    const {
      createAppModulesActions,
      callback,
      updateAppModulesActions,
      state,
      appModule,
      loc_id
    } = this.props

    if (loc_id) {
      model.location_id = loc_id
    }

    if (state == 'update') {
      return api.updateAppModule(appModule.id, model).then(json => {
        updateAppModulesActions.success(json)
        KBPopover.close()
        return
      })
    }
    return api.createAppModule(model).then(json => {
      createAppModulesActions.success(json)
      KBPopover.close()
      return
    })
  },

  changeDefaultIcon(checked) {
    const {
      fields: { icon }
    } = this.props

    if (checked) {
      icon.onChange(
        'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/default_app_icon.png'
      )
    } else {
      icon.onChange('')
    }
  },

  render() {
    const {
      fields: {
        title,
        icon,
        description,
        app_type,
        entry_url,
        module_id,
        visible_type,
        enabled,
        category
      },
      error,
      submitting,
      handleSubmit,
      state,
      appModule
    } = this.props
    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }
    const isApply = [
      { id: true, name: '是' },
      { id: false, name: '否' }
    ]

    return (
      <form onSubmit={handleSubmit(this.createLockdForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">
            {state ? '修改自定义模块' : '添加自定义模块'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={title}
              title="名称"
              isRequired={true}
              fistFoucs={true}
              hint={APPLICATION_FORM.name}
            />
          </div>
          {
            //app_type.value == 'native' ? (
            // <div className='kb-row'>
            //  <KBInput className='kb-form-group kb-form-c18 kb-form-alone' field={ module_id } title='模块的id' />
            // </div>
            //  ) : ''
          }
          {app_type.value == 'h5' ? (
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={entry_url}
                title="URL"
                hint={APPLICATION_FORM.url}
              />
            </div>
          ) : (
            ''
          )}
          {/*<div className='kb-row kb-form-66'>
            <KBRadio { ...halfClass } inClassName='kb-form-group kb-div-33' groupStyle={{width:'100%'}} divClassName='kb-form-group kb-div-66' rightLabel='label-rt' title='是否启用' arrayType={ constants.IS_FINISH } name='is_enabled'  field={enabled} />
          </div>*/}
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-div-c18 kb-form-alone"
              title="是否启用"
              field={enabled}
              arrayData={isApply}
            />
          </div>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              arrayData={constants.APPMODULES_CATEGORY}
              field={category}
              title="类别"
            />
          </div>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              arrayData={constants.APP_MODULES_VISIBLE_ARRAY}
              field={visible_type}
              title="可见性"
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              title="描述"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={description}
            />
          </div>
          <div className="kb-row kb-form-84">
            <KBLogoCropper
              aspect={1}
              className="kb-form-group kb-div-210"
              field={icon}
              obj={appModule}
              type="serviceProviders"
              accept="image/*,.psd,.sketch"
            />
            <div
              className="kb-form-group kb-div-48"
              style={{ width: '88.66667%', float: 'right', marginTop: 30 }}
            >
              <label style={{ width: '20%' }}>App内效果预览</label>
              <div className="application-logo">
                {icon.value && <img src={icon.value} />}
              </div>
            </div>
          </div>
          <div className="kb-row kb-form-group kb-form-c18 kb-form-alone">
            <label />
            <div>
              <div className="download-icon-template">
                <label>下载Logo模板：</label>
                <div>
                  <span>
                    <i className="iconfont icon-relevance" />
                    <a
                      style={{ color: '#2ea1f8' }}
                      href="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon_psd_app_template.psd"
                      download="Logo模板文件"
                    >
                      PSD
                    </a>
                  </span>
                  <span>
                    <i className="iconfont icon-relevance" />
                    <a
                      style={{ color: '#2ea1f8' }}
                      href="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon_sketch_app_template.sketch"
                      download="Logo模板文件"
                    >
                      Sketch
                    </a>
                  </span>
                </div>
              </div>
              <div className="download-icon-template">
                <label>使用缺省Logo：</label>
                <div>
                  <KBCheckBox
                    style={{ marginTop: 2 }}
                    callback={this.changeDefaultIcon}
                    checked={this.state.useDefaultLogo}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { entities } = state
  const { loc_id, lock } = props
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    updateAppModulesActions: bindActionCreators(
      appModulesActions.update,
      dispatch
    ),
    createAppModulesActions: bindActionCreators(
      appModulesActions.create,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.title)) {
    errors.title = '请输入名称'
  }

  if (values.title && values.title.length > 5) {
    errors.title = '名称不能超过5个字'
  }

  if (valid.required(values.entry_url)) {
    errors.entry_url = '请输入正确的URL'
  }

  return errors
}

const formConfig = {
  form: 'LockForm',
  fields: [
    'title',
    'icon',
    'description',
    'app_type',
    'entry_url',
    'module_id',
    'enabled',
    'category',
    'visible_type'
  ],
  validate: validate,
  touchOnBlur: false
}

ApplicationForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ApplicationForm)

export default ApplicationForm
