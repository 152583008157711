import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBExcelUpload
} from 'components'
import { announcementsActions, successState } from 'app/actions'
import { ANNOUNCEMENT_FORM } from 'app/constants/hint'
import { notification } from 'antd'

var ActivitiesOrderUploadFileForm = React.createClass({
  getInitialState() {
    return {}
  },

  componentDidMount() {},

  // createFaqFiles(model) {
  //   console.log(111);
  //   KBPopover.close()
  // },
  onHandle() {
    const { callback } = this.props
    callback && callback()
    KBPopover.close()
  },

  failureCallBack(err) {
    if (!err) return
    notification.error({ message: '上传失败', description: err })
  },

  render() {
    const { error, submitting, handleSubmit, activity_id, callback } = this.props
    const { state } = this.props

    return (
      <form>
        <KBFormError error={error} />
        <header className="kb-form-header">
          <h2 className="header-title">批量签到</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBExcelUpload
              type="csv"
              url={api.signInActivity(activity_id)}
              params={{ activity_id: activity_id }}
              callback={callback}
            />

            <div className="m-top-sm" style={{ textAlign: 'center' }}>
              <span>提示:可以将导出的活动清单编辑后上传。会员编号为必填项，不需要批量签到的请从表格中冊除，不要做隐藏。</span>
            </div>
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="button"
              onClick={() => this.onHandle()}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createAnnouncementActions: bindActionCreators(
      announcementsActions.create,
      dispatch
    ),
    updateAnnouncementActions: bindActionCreators(
      announcementsActions.update,
      dispatch
    ),
    successActions: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.title)) {
    errors.title = '请输入公告标题'
  }

  if (valid.required(values.image)) {
    errors.image = '请选择图片'
  }

  if (valid.required(values.link) || valid.Link(values.link)) {
    errors.link = '请输入正确的URL地址'
  }

  return errors
}

const formConfig = {
  form: 'ActivitiesOrderUploadFileForm',
  fields: [],
  validate: validate,
  touchOnBlur: false
}

ActivitiesOrderUploadFileForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesOrderUploadFileForm)

export default ActivitiesOrderUploadFileForm
