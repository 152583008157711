import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { invoicesActions, successState, apiCallFailure } from 'app/actions'
import { api, apiUtils } from 'app/services'
import {
  paginate,
  arrayToObject,
  util,
  fmoney,
  formatYearDayEN
} from 'utils/kbUtil'
import {
  KBDropdown,
  KBPagination,
  KBCheckBox,
  KBLoadingContainer,
  KBSearchList,
  KBPopover,
  KBTipsy
} from 'components'
import {
  INVOICES_STATE,
  INVOICES_TYPE,
  INVOICES_UPDATE_STATE,
  INVOICES_STATE_CLASS,
  INVOICES_SENDING_STATE,
  INVOICES_SENDING_STATE_CLASS,
  INVOICE_APPLICATION_STATE,
  INVOICE_APPLICATION_STATE_OBJ
} from 'app/constants'
import {
  canAccessLocationExceptObserver,
  canAccessLocationRoles
} from 'app/reducers/role'
import { percent, mergeArrayWithKey } from 'utils/kbUtil'
import { routerActions } from 'react-router-redux'
import moment from 'moment'
import { getUrlOfOrgDetailPage } from 'utils/kbUrl'

var InvoiceList = React.createClass({
  getInitialState() {
    return {
      expandStatus: false,
      loadingLineItems: {},
      all_line_items: {}
    }
  },

  _toOppInfo(invoice, operation, isTeam) {
    const { routerActions, location_id } = this.props

    if (!location_id) {
      routerActions.push(
        `/admin/invoices/invoices_list/${invoice.id}${
          operation == 'add' ? `?type=add&isTeam=${isTeam}` : ''
        }`
      )
    } else {
      routerActions.push(
        `/admin/locations/${invoice.location_id}/invoices/invoices_list/${
          invoice.id
        }${operation == 'add' ? `?type=add&isTeam=${isTeam}` : ''}`
      )
    }
  },

  changeExpand(invoice_id) {
    let uponStatus = !this.state.expandStatus

    if (uponStatus) {
      const { getInvoicesActions } = this.props

      let loadingLineItems = Object.assign({}, this.state.loadingLineItems)
      loadingLineItems[invoice_id] = true
      this.setState({
        loadingLineItems
      })

      api
        .getInvoice(invoice_id)
        .then(
          json => {
            let invoice = json.response.body
            let line_items = invoice.line_items || []
            let all_line_items = Object.assign({}, this.state.all_line_items)
            all_line_items[invoice_id] = line_items
            this.setState({
              all_line_items
            })
          },
          errors => {
            getInvoicesActions.failure(errors)
          }
        )
        .finally(() => {
          let loadingLineItems = Object.assign({}, this.state.loadingLineItems)
          loadingLineItems[invoice_id] = false
          this.setState({
            loadingLineItems
          })
        })
    }

    this.setState({
      expandStatus: uponStatus
    })
  },

  render() {
    const {
      json,
      isReviewed,
      callback,
      obligation,
      location_id,
      selectInvoices,
      isTeam,
      isApplicationShow,
      isLastItem,
      isManager
    } = this.props
    const { expandStatus, all_line_items, loadingLineItems } = this.state
    let dueDate = moment(json.due_date)
    let nowDate = moment()
    let overDueDays = nowDate.diff(dueDate, 'days')
    const reviewedStyle = {
      display: 'inline-block',
      padding: '0 5px',
      height: '20px',
      backgroundColor: '#2ea1f8',
      textAlign: 'center',
      lineHeight: '20px',
      color: '#ffffff',
      fontSize: '12px',
      marginLeft: '5px'
    }

    let isCheckPaid = json && json.check_status === 'checked'
    let isCheckPaidFailed = json && json.check_status === 'check_failed'
    let isConfirmed = json && json.check_status === 'confirmed'

    const invoicing_application_status =
      json.invoicing_application && json.invoicing_application.status

    return (
      <tbody className={`${expandStatus ? 'upon-tbody' : ''}`}>
        <tr onClick={this._toOppInfo.bind(null, json)}>
          {isTeam ? null : (
            <td
              style={{ position: 'static', verticalAlign: 'middle' }}
              onClick={e => e.stopPropagation()}
            >
              <KBCheckBox
                style={{ marginTop: 2 }}
                data={json}
                callback={callback}
                checked={
                  (selectInvoices &&
                    selectInvoices.length &&
                    selectInvoices.find(data => {
                      return data.id == json.id
                    })) ||
                  false
                }
              />
            </td>
          )}
          {json.status ? 
          <td
            style={{ position: 'static', width: 90, verticalAlign: 'middle' }}
          >
            <span
              style={{ marginLeft: 0, padding: '5px 6px' }}
              className={`finance-default ${
                isCheckPaidFailed
                  ? 'receipt-refunding'
                  : isConfirmed || isCheckPaid
                  ? 'receipt-confirmed'
                  : INVOICES_STATE_CLASS[json.status]
              }`}
            >
              {isCheckPaidFailed
                ? '核销失败'
                : isConfirmed
                ? '确认核销'
                : isCheckPaid
                ? '已核销'
                : json.status === 'paid'
                ? '已收款'
                : json.total_amount == 0
                ? '已完成'
                : INVOICES_STATE[json.status]}
            </span>
          </td>:
          ''
          }
          {!isApplicationShow ? 

          <td style={{ position: 'static', verticalAlign: 'middle' }}>
            <span
              className={`invoice-application-state invoice-application-state-${invoicing_application_status ||
                'none'}`}
              style={{ padding: '3px 6px' }}
            >
              {invoicing_application_status
                ? INVOICE_APPLICATION_STATE_OBJ[invoicing_application_status]
                : '未申请'}
            </span>
          </td>:
          ''
          }
          {json.invoice_type ?
          <td
            style={{ position: 'static', width: 90, verticalAlign: 'middle' }}
          >
            {INVOICES_TYPE[json.invoice_type]}
            <KBTipsy content={expandStatus ? '点击收起' : '点击展开'}>
              <i
                className={`iconfont ${
                  expandStatus
                    ? 'icon-arrow_drop_down_circle'
                    : 'icon-navigate_next'
                }`}
                onClick={e => {
                  e.stopPropagation()
                  this.changeExpand(json.id)
                }}
                style={{
                  fontSize: 18,
                  verticalAlign: 'middle',
                  color: '#999',
                  marginLeft: 10
                }}
              />
            </KBTipsy>
          </td>:
          ''
          }
          <td style={{ position: 'static', verticalAlign: 'middle' }}>
            <span
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <Link
                className="color-link"
                to={getUrlOfOrgDetailPage(
                  location_id,
                  json.sales_customer && json.sales_customer.foreign_id
                )}
              >
                {json.sales_customer && json.sales_customer.name}
              </Link>
              {json.status === 'overdue' && overDueDays > 0 ? (
                <span style={{ color: '#dd5a55', marginLeft: 5 }}>
                  {'(已逾期' + overDueDays + '天)'}
                </span>
              ) : (
                ''
              )}
            </span>
            <div style={{ marginTop: 10 }} className="color-nine">
              <span>
                账单:&nbsp;
                <KBTipsy content={json.serial_number}>
                  <span>{json.serial_number.slice(0, 6) + '...'}</span>
                </KBTipsy>
                {isReviewed ? <span style={reviewedStyle}>已审核</span> : ''}
              </span>
            </div>
          </td>
          <td style={{ position: 'static', verticalAlign: 'middle' }}>
            {fmoney(json.total_amount, 2)}
          </td>
          <td
            className={obligation < 0 ? 'color-red' : ''}
            style={{ position: 'static', verticalAlign: 'middle' }}
          >
            {fmoney(obligation, 2)}
          </td>
          <td style={{ position: 'static', verticalAlign: 'middle' }}>
            {json.due_date ? formatYearDayEN(json.due_date) : '无'}
          </td>
          <td style={{ position: 'static', verticalAlign: 'middle' }}>
            {json.last_paid_at ? formatYearDayEN(json.last_paid_at) : '无'}
          </td>
          {!isLastItem ? 
          <td style={{ position: 'static', verticalAlign: 'middle' }}>
            {json.income_confirmed_at ? (
              '已确认收入'
            ) : json.status == 'paid' ? (
              '已收款'
            ) : isManager ? (
              <KBTipsy content="点击添加收款信息">
                <span
                  style={{ color: '#2ea1f8' }}
                  onClick={e => {
                    this._toOppInfo(json, 'add', isTeam)
                    e.stopPropagation()
                  }}
                >
                  未上传
                </span>
              </KBTipsy>
            ) : (
              <span>未上传</span>
            )}
          </td>:
          ''
          }
        </tr>
        {expandStatus ? (
          <tr className="expand-th" style={{ border: 'none' }}>
            {isTeam ? null : (
              <td
                className="border-none"
                style={{ backgroundColor: '#f0f2f3' }}
              />
            )}
            <td colSpan="5">说明</td>
            <td>单价</td>
            <td>数量</td>
            <td>总价</td>
            <td
              className="border-none"
              style={{ backgroundColor: '#f0f2f3' }}
            />
          </tr>
        ) : null}
        {(expandStatus &&
          !loadingLineItems[json.id] &&
          all_line_items[json.id].map((items, index) => {
            if (items.resource_type == 'Reservation') {
              var name = ''

              if (items.units_name == 'hours') {
                name = '会议室预定会员费（单位：小时）'
              } else if (items.units_name == 'minutes') {
                name = '会议室超时会员费（单位：分钟）'
              } else {
                name = items.units_name
              }

              return (
                <tr
                  key={index}
                  className="expand-tr"
                  style={{ border: 'none' }}
                >
                  {isTeam ? null : (
                    <td
                      className="border-none"
                      style={{ backgroundColor: '#f0f2f3' }}
                    />
                  )}
                  <td colSpan="5">{name}</td>
                  <td>{'¥' + fmoney(items.unit_price, 2)}</td>
                  <td>{items.units}</td>
                  <td>{'¥' + fmoney(items.total_amount, 2)}</td>
                  <td
                    className="border-none"
                    style={{ backgroundColor: '#f0f2f3' }}
                  />
                </tr>
              )
            }

            return (
              <tr key={index} className="expand-tr" style={{ border: 'none' }}>
                {isTeam ? null : (
                  <td
                    className="border-none"
                    style={{ backgroundColor: '#f0f2f3' }}
                  />
                )}
                <td colSpan="5">
                  {items.description}
                  {items.sales_subscription_serial ? (
                    location_id ? (
                      <Link
                        to={`/admin/locations/${json.location_id}/organizations/${json.organization_id}/subscriptions/${items.sales_subscription_id}`}
                        className="a-hover"
                      >
                        {' '}
                        (合同#{items.sales_subscription_serial})
                      </Link>
                    ) : (
                      <Link
                        to={`/admin/invoices/1/organizations/${json.organization_id}/subscriptions/${items.sales_subscription_id}`}
                        className="a-hover"
                      >
                        {' '}
                        (合同#{items.sales_subscription_serial})
                      </Link>
                    )
                  ) : (
                    ''
                  )}
                  {items.start_date && items.end_date
                    ? '【' + items.start_date + '至' + items.end_date + '】'
                    : ''}
                </td>
                <td>{'¥' + fmoney(items.unit_price, 2)}</td>
                <td>{items.units}</td>
                <td>{'¥' + fmoney(items.total_amount, 2)}</td>
                <td
                  className="border-none"
                  style={{ backgroundColor: '#f0f2f3' }}
                />
              </tr>
            )
          })) || (
          <tr style={{ border: 'none', backgroundColor: '#f0f2f3' }}>
            <td colSpan="10" style={{ padding: 0 }}>
              <KBLoadingContainer
                loading={loadingLineItems[json.id]}
                innerStyle={{ margin: '5px auto' }}
              />
            </td>
          </tr>
        )}
      </tbody>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { location_id } = props
  let isManager =
    canAccessLocationExceptObserver(user, location_id) ||
    canAccessLocationRoles(user, location_id, ['sales'])

  return {
    isManager
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList)
