import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import AssistBox from './../shuck/AssistBox'
import { reduxForm } from 'redux-form'
import { successState } from 'app/actions'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { KBInput, KBFormError, KBButton } from 'components'

var SendResetPassword = React.createClass({
  sendResetPassword(model, dispatch) {
    const { successAction } = this.props
    return api.sendPassWordEmail(model).then(json => {
      successAction({ message: '发送重置密码邮件成功!' })
    })
  },
  render() {
    const {
      fields: { email },
      error,
      submitting,
      handleSubmit
    } = this.props
    return (
      <div className="lr-out-box">
        <div className="comment-box1">
          <div className="kb-box box1-header">
            <span className="lr-text">重置密码</span>
          </div>
          <div className="kb-box send-link">
            <p className="common-font-1">请输入你的注册email,</p>
            <p className="common-font-1">我们将发送重置密码链接到该邮箱</p>
          </div>
          <form onSubmit={handleSubmit(this.sendResetPassword)}>
            <KBFormError err={this.props.error} />
            <KBInput field={email} placeholder="输入电子邮件地址" />
            <div className="kb-box lr-certain">
              <KBButton type="submit" submitting={submitting}>
                {submitting ? '发送' : '发送密码重置邮件'}
              </KBButton>
            </div>
          </form>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, ownProps) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validate = values => {
  const errors = {}
  if (valid.required(values.email)) {
    errors.email = '请输入你的注册email'
  } else if (valid.email(values.email)) {
    errors.email = 'Email地址不正确'
  }
  return errors
}

const formConfig = {
  form: 'sendResetPassword',
  fields: ['email'],
  touchOnBlur: false,
  validate: validate
}

SendResetPassword = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SendResetPassword)

export default SendResetPassword
