import React, { Component, PropTypes } from 'react'
import { api, apiUtils } from 'app/services'
import { customerRecordCommentsActions } from 'app/actions'
import { KBInput, KBButton, KBAvatar } from 'components'
import moment from 'moment'

var CrmRecordListContent = React.createClass({
  render() {
    const { create_by, comment } = this.props
    return (
      <div className="nav-comment-content" key={comment.id}>
        <header className="clear-fix">
          <div className="comment-portrait">
            <KBAvatar user={comment.created_by} size={36} />
          </div>
          <div className="comment-name">
            <span className="comment-people">{comment.created_by.name}</span>
            <span className="comment-reply">回复</span>
            <span className="comment-people">{create_by.name}</span>
            <span className="comment-time">
              {moment.unix(comment.created_at).fromNow()}
            </span>
          </div>
          <div className="reply-btn-box">
            <div className="reply-btn d-hidden">
              <div className="reply-arrow"></div>
              <div className="reply-inner">回复</div>
            </div>
          </div>
        </header>
        <div className="nav-comment-text">
          <p>{comment.content}</p>
        </div>
      </div>
    )
  }
})

export default CrmRecordListContent
