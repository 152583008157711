import React, { Component, PropTypes } from 'react'
import { isInt } from 'validator'
import classNames from 'classnames'

var KBASNumberInput = React.createClass({
  componentDidMount() {
    const { fistFoucs, field } = this.props
    if (fistFoucs) {
      this.refs[field.name].focus()
    }
  },

  addNumber() {
    const { field, maxValue } = this.props
    let value = parseFloat(field.value || 0)
    value = value + 1
    if (maxValue) {
      if (value >= maxValue) {
        value = maxValue
      }
    }
    field.onChange(value)
  },

  subtractNumber() {
    const { field } = this.props
    let value = parseFloat(field.value || 0)
    value = value - 1
    if (value < 0) {
      value = 0
    }
    field.onChange(value)
  },

  render() {
    const {
      field,
      placeholder,
      type,
      disabled,
      title,
      className,
      inputStyle,
      spanStyle,
      counterStyle,
      onChange
    } = this.props
    console.log();
    const value = (isInt(String(field.value))&&field.value!=0)?String(field.value).replace(/\b(0+)/gi,'') : String(field.value)

    // const onChange = field.onChange
    return (
      <div className={`kb-number-input ${className}`}>
        {title ? <label className="title">{title}</label> : ''}
        <div className="counterBox" style={counterStyle}>
          <span
            className="subtracter"
            onClick={this.subtractNumber}
            style={spanStyle}
          >
            -
          </span>
          <input
            ref={field.name}
            disabled={disabled ? 'disabled' : ''}
            style={inputStyle}
            type={type}
            placeholder={placeholder}
            onChange={onChange||field.onChange}
            value={value}
          />
          <span className="summator" onClick={this.addNumber} style={spanStyle}>
            +
          </span>
        </div>
      </div>
    )
  }
})

KBASNumberInput.propTypes = {
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string
}

KBASNumberInput.defaultProps = {
  type: 'text'
}

export default KBASNumberInput
