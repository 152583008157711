import React, { Component, PropTypes } from 'react'
import { Link } from 'react-router'
import classNames from 'classnames'

const MAX_INT = 4294967295

var KBPagination = React.createClass({
  _pageLink: function (page) {
    const { query } = this.props
    let re = new RegExp(query + '=(\\S+)&?', 'g')
    return this.props.template.replace(re, query + '=' + page)
  },

  render() {
    let {
      pagination,
      withoutUrl,
      pageClickCallback,
      posRight,
      outterStyle,
      isSimple
    } = this.props

    if (!pagination) {
      return null
    }
    let nextPage = pagination.next_page
    let prevPage = pagination.prev_page

    let span = isSimple ? 3 : 5
    let lowPageNumber = Math.max(pagination.current_page - span, 1)
    let highPageNumber = Math.min(
      pagination.current_page + span,
      isNaN(pagination.total_pages) ? MAX_INT : pagination.total_pages
    )
    let pages = []
    for (let i = lowPageNumber; i <= highPageNumber; i++) {
      pages.push(i)
    }

    if (pages.length === 1) {
      pages = []
    }

    if (withoutUrl) {
      return pages.length === 0 ? null : (
        <div
          className="page-break"
          style={
            posRight ? { textAlign: 'right', ...outterStyle } : outterStyle
          }
        >
          <ul style={{ display: 'inline-block' }}>
            {!isNaN(prevPage) ? (
              <li className="previous-page">
                <a
                  onClick={e => {
                    e.stopPropagation()
                    pageClickCallback(prevPage)
                  }}
                >
                  <i className="iconfont icon-navigate_before" />
                  {isSimple ? '' : '上一页'}
                </a>
              </li>
            ) : (
                <li className="previous-page disabled-page">
                  <a href="javascript:void(0);">
                    <i className="iconfont icon-navigate_before" />
                    {isSimple ? '' : '上一页'}
                  </a>
                </li>
              )}
            {isSimple ? null : pagination.current_page != 1 ? (
              <li className="previous-page">
                <a
                  onClick={e => {
                    e.stopPropagation()
                    pageClickCallback(1)
                  }}
                >
                  首页
                </a>
              </li>
            ) : (
                <li className="previous-page disabled-page">
                  <a href="javascript:void(0);">首页</a>
                </li>
              )}
            {pages.map((page, index) => {
              let isCurrent = page === pagination.current_page
              return (
                <li className={isCurrent ? 'border-none' : ''} key={index}>
                  <a
                    onClick={e => {
                      e.stopPropagation()
                      pageClickCallback(page)
                    }}
                  >
                    {page}
                  </a>
                </li>
              )
            })}
            {isSimple ? null : pagination.current_page !=
              pagination.total_pages ? (
                <li className="previous-page">
                  <a
                    onClick={e => {
                      e.stopPropagation()
                      pageClickCallback(pagination.total_pages)
                    }}
                  >
                    末页
                </a>
                </li>
              ) : (
                <li className="previous-page disabled-page">
                  <a href="javascript:void(0);">末页</a>
                </li>
              )}
            {!isNaN(nextPage) ? (
              <li className="next-page">
                <a
                  onClick={e => {
                    e.stopPropagation()
                    pageClickCallback(nextPage)
                  }}
                >
                  {isSimple ? '' : '下一页'}
                  <i className="iconfont icon-navigate_next" />
                </a>
              </li>
            ) : (
                <li className="next-page disabled-page">
                  <a href="javascript:void(0);">
                    {isSimple ? '' : '下一页'}
                    <i className="iconfont icon-navigate_next" />
                  </a>
                </li>
              )}
          </ul>
        </div>
      )
    }

    return pages.length === 0 ? null : (
      <div
        className="page-break"
        style={posRight ? { textAlign: 'right', ...outterStyle } : outterStyle}
      >
        <ul style={{ display: 'inline-block' }}>
          {!isNaN(prevPage) ? (
            <li className="previous-page">
              <Link to={this._pageLink(prevPage)}>
                <i className="iconfont icon-keyboard_arrow_left" />
                {isSimple ? '' : '上一页'}
              </Link>
            </li>
          ) : (
              <li className="previous-page disabled-page">
                <a href="javascript:void(0);">
                  <i className="iconfont icon-keyboard_arrow_left" />
                  {isSimple ? '' : '上一页'}
                </a>
              </li>
            )}
          {isSimple ? null : pagination.current_page != 1 ? (
            <li className="previous-page">
              <Link to={this._pageLink(1)}>首页</Link>
            </li>
          ) : (
              <li className="previous-page disabled-page">
                <a href="javascript:void(0);">首页</a>
              </li>
            )}
          {pages.map((page, index) => {
            let isCurrent = page === pagination.current_page
            return (
              <li className={isCurrent ? 'border-none' : ''} key={index}>
                <Link to={this._pageLink(page)}>{page}</Link>
              </li>
            )
          })}
          {isSimple ? null : pagination.current_page !=
            pagination.total_pages ? (
              <li className="previous-page">
                <Link to={this._pageLink(pagination.total_pages)}>末页</Link>
              </li>
            ) : (
              <li className="previous-page disabled-page">
                <a href="javascript:void(0);">末页</a>
              </li>
            )}
          {!isNaN(nextPage) ? (
            <li className="next-page">
              <Link to={this._pageLink(nextPage)}>
                {isSimple ? '' : '下一页'}
                <i className="iconfont icon-keyboard_arrow_right" />
              </Link>
            </li>
          ) : (
              <li className="next-page disabled-page">
                <a href="javascript:void(0);">
                  {isSimple ? '' : '下一页'}
                  <i className="iconfont icon-keyboard_arrow_right" />
                </a>
              </li>
            )}
        </ul>
      </div>
    )
  }
})

KBPagination.propTypes = {
  pagination: PropTypes.object,
  template: PropTypes.string.isRequired
}

KBPagination.defaultProps = {
  query: 'page',
  outterStyle: {}
}

export default KBPagination
