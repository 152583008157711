import React, { Component, PropTypes } from 'react'
import classNames from 'classnames'

var KBSelect = React.createClass({
  getInitialState() {
    return {
      value: this.props.value || ''
    }
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.value != this.props.value) {
      this.setState({
        value: nextProps.value
      })
    }
  },

  changeSelect(e) {
    const value = e.target.value
    const { callback } = this.props
    this.setState({
      value
    })
    callback && callback(value)
  },
  render() {
    const {
      defaultValue,
      defaultName,
      arrayData,
      selectClass,
      selectStyle,
      isRequired
    } = this.props
    const { value } = this.state
    return (
      <select
        value={value}
        className={selectClass}
        style={selectStyle}
        onChange={this.changeSelect}
      >
        {defaultValue ? (
          <option value={defaultValue}>{defaultName}</option>
        ) : (
          ''
        )}
        {arrayData &&
          Object.keys(arrayData).map(function(value, index) {
            let data = arrayData['' + value]
            return (
              <option value={data.id} key={index}>
                {data.name}
              </option>
            )
          })}
      </select>
    )
  }
})

KBSelect.propTypes = {
  defaultValue: PropTypes.string,
  defaultName: PropTypes.string,
  className: PropTypes.string
}

KBSelect.defaultProps = {
  className: '',
  defaultValue: '',
  defaultName: '请选择'
}

export default KBSelect
