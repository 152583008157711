import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBPopover,
  KBLoadingContainer,
  KBTipsy,
  KBStatisticNumber,
  KBPopoverTop,
  KBToReportCenter,
  KBDropdown,
  KBTableWithoutData
} from 'components'
import { Input } from 'antd'
import {
  formatYearDayEN,
  formatYearDayCHS,
  formatMinuteSecondEN,
  queryFilter,
  kbCloseDropDown
} from 'utils/kbUtil'
import { KBDayPicker } from 'components/views'
import { connect } from 'react-redux'
import moment from 'moment'

const DROP_DOWNS = ['filterDate']
const STATUS = {
  active:<span className='order-default receipt-finish'>预定成功</span>,
  reserved:<span className='order-default receipt-unpaid' >已预定</span>,
  cancelled:<span className='order-default receipt-debt'>已取消</span>
}

var DeskReservationList = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      desk_reservations: [],
      filters,
      searchTime: moment().toDate(),
      loading: true,
      desk_count:'',
      reserved_desks_count:''
    }
  },

  componentDidMount() {
    const { searchTime } = this.state
    this.mounted = true
    this.changeDayPicker(searchTime)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(params) {
    const { params:{ id } } = this.props
    api
      .getDeskReservations({
        location_id: id,
        page: 1,
        per_page: 100,
        ...params
      })
      .then(({ json }) => {
        if (!this.mounted) {
          return
        }
        const desk_reservations = json.reservations
        this.setState({
          desk_reservations,
          desk_count:json.desk_count,
          reserved_desks_count:json.reserved_desks_count
        })
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  showFiltered() {
    const { filters } = this.state
    let params = Object.assign({}, filters)

    this._loadData({ ...params })
    this.setState({
      ...params
    })
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters, loading: true })

    // force refresh
    this._refresh()
  },

  _clearFilters() {
    this.setState({ filters: {}, searchTime: '', loading: true, phone_num: '' })
    // force refresh
    this._refresh()
  },

  _refresh() {
    this.filterDate && this.filterDate.hide()

    setTimeout(this.showFiltered)
  },

  changeDayPicker(date) {
    this.filterDate && this.filterDate.hide()
    let selectedDay = formatYearDayEN(date)
    // let start_at = formatMinuteSecondEN(moment(selectedDay).startOf('day'))
    // let end_at = formatMinuteSecondEN(moment(selectedDay).endOf('day'))
    this._setFilter({ date:selectedDay })
    if (this.mounted) {
      this.setState({
        searchTime: date,
        loading: true
      })
    }
  },

  changePhone(phone){
    this._setFilter({phone_num:phone})
    if (this.mounted) {
      this.setState({
        phone_num: phone,
        loading: true
      })
    }
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  render() {
    const { searchTime, loading, desk_reservations, phone_num, desk_count, reserved_desks_count } = this.state
    const today = moment().toDate()
    const tomorrow = moment()
      .add(1, 'days')
      .toDate()
    const yesterday = moment()
      .subtract(1, 'days')
      .toDate()
    let selectFilters = Object.values(this.state.filters || {})
    selectFilters =
      (selectFilters && selectFilters.filter(filter => filter != '')) || []

    return (
      <div className="kb-content" style={{ paddingTop: 0 }}>
        <div className="kb-content-container clear-fix">
          <header className="nav-section-header clear-fix border-bottom">
            <div className="nav-section-header-title f-left" style={{display:'flex', alignItems:'center'}}>
              <span>工位预订记录</span>
              <span style={{marginLeft:120, fontSize:20}}>
                <div>{desk_count||0}个</div>
                <div>总工位数</div>
              </span>
              <span style={{marginLeft:50, fontSize:20}}>
                <div>{reserved_desks_count||0}个</div>
                <div>已出租工位数</div>
              </span>
            </div>
          </header>

          <div style={{ padding: '20px 30px 0' }}>
            <div className="time-filter" style={{ margin: '0 20px 0 0' }}>
              <button
                className={
                  searchTime && moment(searchTime).isSame(yesterday, 'day')
                    ? 'active'
                    : ''
                }
                onClick={this.changeDayPicker.bind(null, yesterday)}
              >
                昨天
              </button>
              <button
                className={
                  searchTime && moment(searchTime).isSame(today, 'day')
                    ? 'active'
                    : ''
                }
                onClick={this.changeDayPicker.bind(null, today)}
              >
                今天
              </button>
              <button
                className={
                  searchTime && moment(searchTime).isSame(tomorrow, 'day')
                    ? 'active'
                    : ''
                }
                onClick={this.changeDayPicker.bind(null, tomorrow)}
              >
                明天
              </button>
            </div>
            <KBDropdown
              ref={ref => {
                this.filterDate = ref
              }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={() => {
                    this.closeDropDowns('filterDate')
                  }}
                >
                  <i
                    className="iconfont icon-calendar"
                    style={{ marginRight: '3px' }}
                  />
                  <span>
                    选择预订日期{' '}
                    {(searchTime && moment(searchTime).format('YYYY-MM-DD')) ||
                      ''}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <KBDayPicker
                  onChange={this.changeDayPicker}
                  selectedDay={moment(searchTime || new Date()).toDate()}
                />
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
            <Input value={phone_num} onChange={(e)=>this.changePhone(e.target.value)} maxLength={11} placeholder="请输入手机号" style={{width:'200px',height:'37px',marginLeft:'20px'}}/>
            {/* <br /> */}
            {/* {selectFilters && selectFilters.length > 0 ? (
              <div
                className="clear-criteria"
                style={{ marginTop: 5 }}
                onClick={this._clearFilters}
              >
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            ) : null} */}
          </div>
          <div className="nav-section-content-container">
            <KBLoadingContainer loading={loading}>
              <table className="content-table edit-table">
                <thead>
                  <tr>
                    <th>预订人</th>
                    <th>电话</th>
                    <th>预订时间</th>
                    <th>预订数量</th>
                    <th>订单状态</th>
                    <th>备注</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={desk_reservations && desk_reservations.length > 0}
                  tableHeadNum="7"
                  tipMessage="暂无工位预订记录"
                >
                  {desk_reservations &&
                    desk_reservations.length > 0 &&
                    desk_reservations.map((res, index) => {
                      return (
                        <tr
                          key={index}
                          style={{ background: '#fff', cursor: 'default' }}
                        >
                          <td>{res.contacts_name || ''}</td>
                          <td>{res.contacts_phone || ''}</td>
                          <td>
                            {res.reserved_dates&&res.reserved_dates.map(x=><span style={{marginRight:10}}>{x}</span>)}
                          </td>
                          <td>{res.desk_count || ''}</td>
                          <td>{STATUS[res.state]}</td>
                          <td>{res.note || ''}</td>
                        </tr>
                      )
                    })}
                </KBTableWithoutData>
              </table>
            </KBLoadingContainer>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, ownProps) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeskReservationList)
