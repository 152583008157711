import React, { Component, PropTypes } from 'react'

var KBCheckBox = React.createClass({
  getInitialState() {
    return {
      checked: this.props.checked || false
    }
  },
  componentWillReceiveProps(nextProps) {
    if (
      this.props.checked != nextProps.checked &&
      nextProps.checked != null &&
      nextProps.checked != undefined
    ) {
      this.setState({
        checked: nextProps.checked
      })
    }
  },
  changeBox() {
    const { callback, data } = this.props
    const { checked } = this.state
    let changeChecked = !checked

    this.setState({
      checked: changeChecked
    })
    callback && callback(changeChecked, data)
  },
  render() {
    const { submitting, className, style, disabled = false } = this.props
    const { checked } = this.state
    return (
      <div
        style={{ display: 'flex', cursor: 'pointer', ...style }}
        onClick={e => {
          e.stopPropagation()
          !disabled && this.changeBox()
        }}
      >
        <div className="squaredThree">
          <input
            type="checkbox"
            value="None"
            disabled={disabled ? 'disabled' : ''}
          />
          {disabled ? (
            <label
              htmlFor="squaredThree"
              style={{ backgroundColor: '#eee', cursor: 'no-drop' }}
            />
          ) : (
            <label
              className={checked ? 'active' : ''}
              htmlFor="squaredThree"
              style={
                checked
                  ? { backgroundColor: '#39B54A', borderColor: '#39B54A' }
                  : {}
              }
            />
          )}
        </div>
        {this.props.label && (
          <span style={{ marginLeft: '10px' }}>{this.props.label}</span>
        )}
      </div>
    )
  }
})

KBCheckBox.PropTypes = {
  checked: PropTypes.bool,
  callback: PropTypes.func,
  data: PropTypes.object
}

export default KBCheckBox
