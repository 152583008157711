import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { Router, Route, Link } from 'react-router'
import { api } from 'app/services'
import { bindActionCreators } from 'redux'
import { KBLoadingContainer } from 'components'

var UnlockUser = React.createClass({
  displayName: 'UnlockUser',
  getInitialState() {
    return { loading: true, isLoaded: false, errors: null }
  },

  componentDidMount() {
    if (this.props.token.length > 0) {
      this.setState({ loading: true, isLoaded: false, errors: null })
      api
        .unlock({ unlock_token: this.props.token })
        .then(json => {
          this.setState({ loading: false, isLoaded: true, errors: null })
        })
        .catch(err => {
          this.setState({ loading: false, isLoaded: false, errors: err })
        })
    } else {
      this.setState({ loading: false, isLoaded: false, errors: '解锁失败' })
    }
  },

  render() {
    let body = this.state.errors ? (
      <Link className="a-blue" to="/send_unlock_email">
        {' '}
        返回发送解锁邮件页面{' '}
      </Link>
    ) : (
      <Link className="kb-bt-a" to="/login">
        {' '}
        回到登录页面{' '}
      </Link>
    )
    let title = this.state.loading
      ? '正在解锁'
      : this.state.isLoaded
      ? '解锁成功'
      : '解锁失败'
    return (
      <div className="lr-out-box">
        <div className="comment-box1">
          <div className="kb-box unlock-account-email">
            <i />
          </div>
          <div className="kb-box t-center">
            <span className="green-font">{title}</span>
          </div>
          <div className="kb-box t-center unlock-previous-step">
            <KBLoadingContainer loading={this.state.loading}>
              {body}
            </KBLoadingContainer>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const token = ownProps.location.query.unlock_token || ''
  return {
    token
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

UnlockUser = connect(mapStateToProps, mapDispatchToProps)(UnlockUser)

export default UnlockUser
