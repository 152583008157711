import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { api, apiUtils } from 'app/services'
import {
  announcementsActions,
  apiCallFailure,
  broadcastMessagesActions
} from 'app/actions'
import {
  KBLoadingContainer,
  KBPagination,
  KBPopover,
  KBTableWithoutData
} from 'components'
import { connect } from 'react-redux'
import { SEND_MESSAGE_RANGE_OBJ, DUTIES_TYPE_OBJ } from 'app/constants'
import { util, momentFromNow } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import { paginate, formatMinuteSecondEN } from 'utils/kbUtil'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import SendMessageForm from '../spaces/LocationSendMessageForm'
import { getUserInfoUrl, getUrlOfLocationHomePage } from 'utils/kbUrl'

var MessagesManage = React.createClass({
  getInitialState() {
    return {
      messageLists: [],
      loading: false
    }
  },

  componentDidMount() {
    const { page } = this.props
    this._loadData(page)
  },

  _loadData(page) {
    const { broadcastMessagesActions } = this.props
    this.setState({ loading: true })
    api
      .getBroadcastMessages({ page: page })
      .then(json => {
        broadcastMessagesActions.success(json)
        let messageLists = getEntitiesJsonArray(json, 'messageLists')
        let pagination = paginate(json.response)
        this.setState({
          messageLists,
          pagination
        })
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this._loadData(nextProps.page)
    }
  },

  sendMessage() {
    KBPopover.show(<SendMessageForm role={''} memberType={'message'} />)
  },

  render() {
    const { messageLists, pagination, loading } = this.state
    const { locations } = this.props
    return (
      <section className="kb-content-container">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span>消息列表</span>
          </div>
          <button className="bordered-btn f-right" onClick={this.sendMessage}>
            发送消息
          </button>
        </header>
        <div className="nav-section-content-container">
          <p style={{ marginBottom: 15, marginTop: 15 }}>
            向会员发送的App内消息列表，以App通知的方式发送。用户必须安装客户端App后方可接收到消息。目前可以向所有会员或各公司负责人发送消息。
          </p>
          <KBLoadingContainer loading={loading}>
            <table className="content-table table-align">
              <thead>
                <tr>
                  <th>消息内容</th>
                  <th>图片地址</th>
                  <th>分店</th>
                  <th>发送人</th>
                  <th>接收人</th>
                  <th>发送时间</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={messageLists.length > 0}
                tableHeadNum="6"
                tipMessage="暂无消息"
              >
                {messageLists &&
                  messageLists.map((message, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ width: 300 }}>
                          {message.message_content}
                        </td>
                        <td style={{ width: 150 }}>
                          <div>
                            <a
                              style={{ width: 150, wordBreak: 'break-all' }}
                              className="color-link"
                              target="_blank"
                              href={message.open_image_url}
                            >
                              {message.open_image_url}
                            </a>
                          </div>
                        </td>
                        <td style={{ width: 180 }}>
                          {message.location_ids.map(location_id => {
                            return (
                              <div>
                                <Link
                                  className="color-link"
                                  to={getUrlOfLocationHomePage(location_id)}
                                >
                                  {locations[location_id] &&
                                    locations[location_id].name}
                                </Link>
                              </div>
                            )
                          })}
                        </td>
                        <td>
                          <div className="vf-center">
                            <Link
                              className="color-link"
                              to={getUserInfoUrl(
                                message.creator && message.creator.id
                              )}
                            >
                              {(message.creator &&
                                message.creator.creator_name) ||
                                ''}
                            </Link>
                          </div>
                        </td>
                        <td>
                          {message.to_type == 'all_ceo' ||
                          message.to_type == 'all_user'
                            ? SEND_MESSAGE_RANGE_OBJ[message.to_type]
                            : DUTIES_TYPE_OBJ[message.to_type]}
                        </td>
                        <td>{formatMinuteSecondEN(message.created_at)}</td>
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
            <KBPagination
              pagination={pagination}
              template={`/admin/memberships/memberManage/messages?page=#PAGE#`}
            />
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page
  let locations = selectors.getSerializeLocationObj(state)
  return {
    page,
    locations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    broadcastMessagesActions: bindActionCreators(
      broadcastMessagesActions.get,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesManage)
