import React, { PropTypes } from 'react'
import { KBFormError, KBPopover, KBButton } from 'components'
import { GLOBAL_USER } from 'app/services/api_utils'
import request from 'superagent-bluebird-promise'

var KBFileUpload = React.createClass({
  getInitialState() {
    return {
      uploadFileUrl: null,
      uploadStateError: false,
      uploadStateFinish: false,
      uploadStateStart: false,
      uploadStateLoading: false,
      errorMsg: ''
    }
  },

  getDefaultProps() {
    return {
      single: true
    }
  },

  componentDidMount() {
    this.setState({ uploadStateStart: true })
  },

  uploadFile() {
    const { file } = this.refs
    file.click()
  },

  changeFile(e) {
    let file = e.target.files[0]
    let fileType = ['xlsx', 'xls']
    let fillName = file ? file.name : '上传文件'
    let name = fillName.substr(0, fillName.lastIndexOf('.'))
    let type = fillName.substr(fillName.lastIndexOf('.') + 1)
    let index = fileType.findIndex(json => json == type)

    if (index == -1) {
      this.setState({
        errorMsg: '上传文件格式不正确,必须为xlsx、xls'
      })
      return
    }

    this.setState({ uploadStateLoading: true })
    this.sendFile(file)
  },

  sendFile(file) {
    const { url, params, fileField } = this.props

    var r = request
      .put(url)
      .attach(
        fileField ? fileField : 'file',
        file,
        file ? file.name : '上传文件'
      )

    if (params && params.location_id) {
      r.field('location_id', params.location_id)
    }

    r.set('Authorization', 'Bearer ' + GLOBAL_USER.jwt_token).end(
      e => {
        var xhr = r.xhr
        if (xhr.status == '422') {
          this.uploadFileError(JSON.parse(xhr.response))
        } else if (xhr.status == '200' || xhr.status == '201') {
          this.setCurrentState({
            uploadStateFinish: true,
            uploadStateLoading: false
          })
        }
        this.setState({ uploadStateLoading: false })
      },
      e => {
        this.setCurrentState({
          uploadStateFinish: true,
          uploadStateLoading: false
        })
      }
    )
  },

  uploadFileError(err) {
    if (err.status) {
      return
    }

    this.setCurrentState({
      errorMsg: err.errors,
      uploadStateError: true
    })
  },

  renderUploadField() {
    const { uploadStateError } = this.state

    if (!uploadStateError) {
      return (
        <form ref="submit">
          <KBButton
            className="post-btn-default"
            onClick={this.uploadFile}
            type="button"
          >
            <div className="post-btn-content" style={{ top: 0 }}>
              <span>上传EXCEL文档</span>
            </div>
          </KBButton>
          <input
            name="file"
            type="file"
            accept=".xls,.xlsx"
            onChange={this.changeFile}
            ref="file"
            style={{ display: 'none' }}
          />
        </form>
      )
    }

    return null
  },

  renderUploadError() {
    return (
      <KBButton className="post-btn-default" type="button">
        <div className="post-btn-content" style={{ top: 0 }}>
          <span>上传EXCEL文档</span>
        </div>
      </KBButton>
    )
  },

  renderProgress() {
    return (
      <KBButton className="post-btn-default" type="button">
        <div className="post-btn-content" style={{ top: 0 }}>
          <span>上传Excel中</span>
        </div>
      </KBButton>
    )
  },

  renderUploaded() {
    return (
      <KBButton
        className="post-btn-default"
        type="button"
        onClick={this.resetSate}
      >
        <div className="post-btn-content" style={{ top: 0 }}>
          <span>上传Excel完成</span>
        </div>
      </KBButton>
    )
  },

  defaultState() {
    return {
      uploadStateError: false,
      uploadStateFinish: false,
      uploadStateStart: false,
      errorMsg: ''
    }
  },

  setCurrentState(newState) {
    let defaultState = this.defaultState()

    this.setState(Object.assign({}, defaultState, newState))
  },

  resetSate() {
    this.setCurrentState({ uploadStateStart: true })
  },

  render() {
    const {
      errorMsg,
      uploadStateError,
      uploadStateStart,
      uploadStateFinish,
      uploadStateLoading
    } = this.state

    return (
      <div>
        {!uploadStateLoading && uploadStateStart
          ? this.renderUploadField()
          : ''}
        {uploadStateLoading ? this.renderProgress() : ''}
        {!uploadStateLoading && uploadStateFinish ? this.renderUploaded() : ''}
        {uploadStateError ? this.renderUploadError() : ''}
        {errorMsg ? (
          <div className="upload-text">
            <span className="lr-error">{errorMsg}</span>
          </div>
        ) : (
          ''
        )}
        {/*
          uploadStateError || uploadStateFinish ? <button className="certain-btn" onClick={ this.resetSate } style={{ marginTop : '10px' }}>重新添加</button> : ''
        */}
      </div>
    )
  }
})

KBFileUpload.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired
}

export default KBFileUpload
