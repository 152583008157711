import React, { Component, PropTypes } from 'react'
import moment from 'moment'
import { Link } from 'react-router'
import { KBAvatar, KBDropDownListInput, KBTipsy } from 'components'
import {
  OPPORTUNITY_STATES,
  CRM_AREA_TYPE_OBJ,
  CRM_COMPANY_TYPE_OBJ,
  CRM_FAILED_REASON_OBJ,
  CRM_DESIRE_OBJ,
  OPPORTUNITY_DESIRES,
  OPPORTUNITY_DESIRES_CLASS,
  REQUIREMENT_TYPE_OBJ
} from 'app/constants'
import {
  opportunitiesActions,
  intentAreaActions,
  simpleAreaActions
} from 'app/actions'
import { api, apiUtils } from 'app/services'
import { bindActionCreators } from 'redux'
import CrmRecordList from './CrmRecordList'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import _ from 'lodash'
import { getUserInfoUrl } from 'utils/kbUrl'

var CrmRelation = React.createClass({
  addFollower(user) {
    const { opp, updateOpportunityAction } = this.props
    let id = opp.id
    const salesmen =
      user.id +
      ',' +
      opp.sales_team
        .filter(json => !json.is_leader)
        .map(json => json.sales_user.id)
        .join(',')
    api.updateOpportunity(id, { salesmen }).then(
      json => {
        updateOpportunityAction.success(json)
      },
      errors => {
        updateOpportunityAction.failure(errors)
      }
    )
  },

  addLeader(user) {
    const { opp, updateOpportunityAction } = this.props
    let id = opp.id
    const salesmen_ids = []
    opp.sales_team.map(json => salesmen_ids.push(json.sales_user.id))
    const salesmen = _.uniq(salesmen_ids).join(',')

    api.updateOpportunity(id, { salesmen, sales_leader: user.id }).then(
      json => {
        updateOpportunityAction.success(json)
      },
      errors => {
        updateOpportunityAction.failure(errors)
      }
    )
  },

  removeFollower(user) {
    const { opp, updateOpportunityAction } = this.props
    let id = opp.id

    const salesmen = opp.sales_team
      .filter(json => !json.is_leader)
      .map(json => json.sales_user.id)
      .filter(json => json != user.id)
      .join(',')
    api.updateOpportunity(id, { salesmen }).then(
      json => {
        updateOpportunityAction.success(json)
      },
      errors => {
        updateOpportunityAction.failure(errors)
      }
    )
  },

  render() {
    const { leader, opp, users, areas, intentAreas, opp_id } = this.props
    const next_follow_at = opp.next_follow_at
    const crmIntentions = opp.requirement_type || []
    let salesTeamUsers = []
    opp.sales_team &&
      opp.sales_team.map(team => salesTeamUsers.push(team.sales_user.id))
    salesTeamUsers = _.uniq(salesTeamUsers)

    return (
      <nav className="nav-content-2 m-top f-right">
        {opp && (opp.state == 'entered' || opp.state == 'archived') ? null : (
          <div>
            <div className="kb-title m-bottom-sm">
              <span className="color-six">跟进信息</span>
            </div>
            <div className="nav-body">
              <ul className="nav-container-list-else">
                <li>
                  <span>跟进人</span>
                  <Link
                    className="color-link"
                    to={getUserInfoUrl(
                      leader.sales_user && leader.sales_user.id
                    )}
                  >
                    {(leader.sales_user && leader.sales_user.name) || ''}
                  </Link>
                </li>
                <li>
                  <span>跟进时间</span>
                  <a href="##">
                    {next_follow_at
                      ? moment.unix(next_follow_at).fromNow()
                      : '暂无'}
                  </a>
                </li>
                {opp.state == 'failed' ? (
                  <li>
                    <span>流失原因</span>
                    <a href="##">
                      {CRM_FAILED_REASON_OBJ[opp.failed_reason]}
                    </a>{' '}
                  </li>
                ) : (
                  ''
                )}
              </ul>
            </div>
          </div>
        )}

        <div>
          <div className="kb-title m-bottom-sm">
            <span className="color-six">历史状态</span>
          </div>
          <CrmRecordList opportunity={this.props.opp} opp_id={opp_id} />
        </div>
        <div>
          <header
            className="nav-header clear-fix"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <h2 className="header-title f-left">销售团队</h2>
          </header>
          <div className="m-top-sm m-bottom-sm">
            <KBDropDownListInput
              data={users}
              placeholder={'添加参与人'}
              selectCallBack={this.addFollower}
              styleWidth={'100%'}
              filterIdArray={salesTeamUsers}
              query={['name', 'name_pinyin']}
            />
          </div>
          <ul className="desk-1-ul">
            {opp.sales_team &&
              opp.sales_team.map(follower => {
                let isAssignedTo = follower.is_leader
                return (
                  <li key={follower.id}>
                    {isAssignedTo ? (
                      <div className="select-principal principal-select">
                        <a href="#" />
                      </div>
                    ) : (
                      <div className="select-principal">
                        <KBTipsy content="设为执行人">
                          <a
                            href="#"
                            onClick={() => this.addLeader(follower.sales_user)}
                          />
                        </KBTipsy>
                      </div>
                    )}
                    <div className="desk-user-img">
                      <KBAvatar user={follower.sales_user} size={30} />
                    </div>
                    <div className="desk-user-message">
                      <div className="desk-user-name">
                        <span>{follower.sales_user.name}</span>
                      </div>
                    </div>
                    {isAssignedTo ? (
                      <div className="repairs-principal">
                        <span>执行人</span>
                      </div>
                    ) : null}
                    {!isAssignedTo ? (
                      <div className="desk-user-close">
                        <KBTipsy content="删除">
                          <i
                            className="iconfont icon-close"
                            onClick={() =>
                              this.removeFollower(follower.sales_user)
                            }
                          />
                        </KBTipsy>
                      </div>
                    ) : null}
                  </li>
                )
              })}
          </ul>
        </div>

        <div className="nav-container-content border-none">
          <header
            className="nav-header clear-fix"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <h2 className="header-title f-left">备注</h2>
          </header>
          <div className="nav-body">
            <p>{opp.notes || '暂无备注'}</p>
          </div>
        </div>
      </nav>
    )
  }
})

function mapStateToProps(state, props) {
  const { user, entities } = state
  const users = selectors.getSpaceUsers(state) || []

  return {
    users
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateOpportunityAction: bindActionCreators(
      opportunitiesActions.update,
      dispatch
    )
  }
}

// const users = selectors.getSpaceUsers(state) || []

export default connect(mapStateToProps, mapDispatchToProps)(CrmRelation)
