import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import AssistBox from './../shuck/AssistBox'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  opportunitiesActions,
  industriesActions,
  apiCallFailure,
  successState
} from 'app/actions'
import { checkObject, listIsNullCallBack } from 'utils/kbUtil'
import {
  KBInput,
  KBPopover,
  KBFormError,
  KBButton,
  KBUserInput,
  KBSelect,
  KBDateInput,
  KBTextarea,
  KBRadio
} from 'components'
import * as selectors from 'app/selectors'
import {
  CRM_OPPORTUNITY_STATES,
  CRM_AREA_TYPE,
  CRM_COMPANY_TYPE,
  CRM_DESIRE,
  CRM_EMPLOYEE_NUMBER,
  REQUIREMENT_TYPE,
  CRM_INTENT_RENT_AREA_SIZE
} from 'app/constants'
import { ADD_CLIENT } from 'app/constants/hint'
import _ from 'lodash'

var OpportunityForm = React.createClass({
  getInitialState() {
    const { opp } = this.props

    return {
      employeeNumberInput: false,
      employeeNumberValue: 11,
      employeeMonthInput: false,
      employeeMonthValue: 13,
      rentIntentType: this.props.fields.requirement_type.initialValue || [],
      deskType: this.props.fields.area_types.initialValue || [],
      selectedRentType: (opp && opp.rent_type) || ''
    }
  },

  createChanceForm(model) {
    const {
      createOpportunity,
      u_id,
      opp_id,
      apiCallFailureAction,
      opp,
      state,
      updateOpportunity,
      callback,
      successActions
    } = this.props
    const { selectedRentType } = this.state
    if (
      state == 'update' &&
      opp.state == 'success' &&
      model.state != 'success'
    ) {
      return apiCallFailureAction({
        status: 'error',
        message: '在赢单状态下，不能修改为其他状态'
      })
    }

    if (model.industry_id == -1) {
      delete model.industry_id
    }

    if (selectedRentType == 'by_area') {
      delete model.employee_number
    } else if (selectedRentType == 'by_desk') {
      delete model.rentable_area
    }

    if (state == 'update') {
      return api.updateOpportunity(opp_id, model).then(json => {
        updateOpportunity.success(json, { key: 'opportunities' })
        successActions({ message: '客户信息更新成功！' })
        KBPopover.close()
        return true
      })
    }

    return api.createOpportunity(model).then(json => {
      const result_id = json.json.result
      result_id &&
        callback &&
        callback(json.json.entities.opportunities[result_id])
      createOpportunity.success(json, { key: 'opportunities' })
      successActions({ message: '客户添加成功！' })
      KBPopover.close()
      return true
    })
  },
  componentDidMount() {
    this._loadDate()
  },

  _loadDate() {
    const { allIndustries, industries } = this.props

    listIsNullCallBack(industries, () => {
      api.getIndustries().then(
        json => {
          allIndustries.success(json)
        },
        errors => {
          allIndustries.failure(errors)
        }
      )
    })
  },

  employeeNumberChange(e) {
    const {
      fields: { employee_number }
    } = this.props
    let value = e.target.value
    this.setState({
      employeeNumberValue: value
    })
    employee_number.onChange(value)
  },

  employeeMonthChange(e) {
    const {
      fields: { employee_months }
    } = this.props
    let value = e.target.value
    this.setState({
      employeeMonthValue: value
    })
    employee_months.onChange(value)
  },

  selectRentIntentType(type) {
    const {
      fields: { requirement_type }
    } = this.props
    const { rentIntentType } = this.state
    let newTypes = rentIntentType
    if (rentIntentType.includes(type)) {
      newTypes.splice(
        newTypes.findIndex(ele => {
          return ele == type
        }),
        1
      )
    } else {
      newTypes.push(type)
    }
    requirement_type.onChange(newTypes.toString())
    this.setState({
      rentIntentType: newTypes
    })
  },
  selectDeskType(type) {
    const {
      fields: { area_types }
    } = this.props
    const { deskType } = this.state
    let newTypes = deskType
    if (deskType.includes(type)) {
      newTypes.splice(
        newTypes.findIndex(ele => {
          return ele == type
        }),
        1
      )
    } else {
      newTypes.push(type)
    }
    area_types.onChange(newTypes.toString())
    this.setState({
      deskType: newTypes
    })
  },

  changeRentType(type) {
    const {
      fields: { rent_type }
    } = this.props

    rent_type.onChange(type)
    this.setState({
      selectedRentType: type
    })
  },

  render() {
    const {
      fields: {
        name,
        company,
        content,
        wechat,
        contact_name,
        mobile,
        agent_mobile,
        email,
        company_type,
        industry_id,
        source_text,
        employee_months,
        area_types,
        desire,
        source,
        state,
        location_ids,
        employee_number,
        area_capacity,
        sales_leader,
        salesmen,
        notes,
        requirement_text,
        rentable_area
      },
      error,
      submitting,
      handleSubmit,
      oppLocations,
      oppMasters,
      oppUsers,
      sources,
      employeeNumbersArry,
      employee_monthsArray,
      industries,
      userSpaceId,
      update_type
    } = this.props
    const hint = ADD_CLIENT
    const {
      employeeNumberInput,
      employeeNumberValue,
      employeeMonthInput,
      employeeMonthValue,
      rentIntentType,
      deskType,
      selectedRentType
    } = this.state

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }
    let chanceState = CRM_OPPORTUNITY_STATES.filter(json => {
      return json.id != 'success'
    })
    const changeState = this.props.state
    const oneClass = { className: 'kb-form-group kb-form-c18 kb-form-alone' }
    const rentType = [
      {
        id: 'by_area',
        name: '按面积出租'
      },
      {
        id: 'by_desk',
        name: '按工位出租'
      }
    ]
    const showCusInfo = ['customer_info', 'all']
    const showCompanyInfo = ['company_info', 'all']
    const showCustomerIntention = ['customer_intention', 'all']
    const showAll = ['all']

    return (
      <form onSubmit={handleSubmit(this.createChanceForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">
            {changeState == 'update' ? '修改' : '添加新'}客户
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={error} />
        <div className="kb-form-container">
          {showCusInfo.includes(update_type) ? (
            <div className="kb-row">
              <KBInput
                isRequired={true}
                hint={hint.name}
                title="客户名称"
                {...oneClass}
                field={name}
              />
            </div>
          ) : null}
          {showCompanyInfo.includes(update_type) ? (
            <div className="kb-row">
              <KBInput title="公司名称" {...oneClass} field={company} />
            </div>
          ) : null}
          {showCompanyInfo.includes(update_type) ? (
            <div className="kb-row">
              <KBSelect
                defaultValue="-1"
                {...oneClass}
                field={industry_id}
                title="行业"
                arrayData={industries}
              />
            </div>
          ) : null}
          {showCompanyInfo.includes(update_type) ? (
            <div className="kb-row">
              <KBSelect
                title="公司类型"
                {...oneClass}
                field={company_type}
                arrayData={CRM_COMPANY_TYPE}
              />
            </div>
          ) : null}
          {showCusInfo.includes(update_type) ? (
            <div className="kb-row">
              <KBInput
                isRequired={true}
                title="联系人"
                {...oneClass}
                field={contact_name}
              />
            </div>
          ) : null}
          {showCusInfo.includes(update_type) ? (
            <div className="kb-row kb-form-66">
              <KBInput title="手机" {...halfClass} field={mobile} />
              <KBInput
                titleClass="label-rt"
                title="代理人电话"
                className="kb-form-group kb-div-c48"
                field={agent_mobile}
              />
            </div>
          ) : null}
          {showCusInfo.includes(update_type) ? (
            <div className="kb-row kb-form-66">
              <KBInput title="微信" {...halfClass} field={wechat} />
              <KBInput
                title="邮箱"
                className="kb-form-group kb-div-c48"
                titleClass="label-rt"
                field={email}
              />
            </div>
          ) : null}
          {showAll.includes(update_type) ? (
            <div className="kb-row">
              <KBUserInput
                title="分店"
                {...oneClass}
                placeholder="选择感兴趣的分店"
                field={location_ids}
                multi={true}
                users={this.props.locations}
                selectedUsers={oppLocations}
              />
            </div>
          ) : null}
          {showCustomerIntention.includes(update_type) ? (
            <div className="kb-row">
              <div className="kb-form-group kb-form-c18 kb-form-alone">
                <label>客户意向</label>
                <div className="">
                  <div className="f-left tabs">
                    {REQUIREMENT_TYPE.map(type => {
                      return (
                        <label className="f-left intentions">
                          <input
                            className="chk_1"
                            type="checkbox"
                            checked={
                              rentIntentType.includes(type.id) ? true : false
                            }
                            style={{ display: 'none' }}
                            onClick={this.selectRentIntentType.bind(
                              null,
                              type.id
                            )}
                          />
                          <span
                            className="f-left color-nine"
                            style={{ marginTop: 1 }}
                          />
                          <span className="f-left title">{type.name}</span>
                        </label>
                      )
                    })}
                  </div>
                  <div
                    className={
                      rentIntentType.length != 0 ? 'f-left content' : ''
                    }
                  >
                    {rentIntentType.includes(REQUIREMENT_TYPE[0].id) ? (
                      <div className="kb-row">
                        <label>办公区类型</label>
                        {CRM_AREA_TYPE.slice(0, 2).map(type => {
                          return (
                            <label className="desk-types">
                              <input
                                className="f-left chk_1"
                                type="checkbox"
                                checked={
                                  deskType.includes(type.id) ? true : false
                                }
                                style={{ display: 'none' }}
                                onClick={this.selectDeskType.bind(
                                  null,
                                  type.id
                                )}
                              />
                              <span
                                className="f-left color-nine"
                                style={{ marginTop: 1 }}
                              />
                              <span className="title">{type.name}</span>
                            </label>
                          )
                        })}
                      </div>
                    ) : null}

                    {rentIntentType.includes(REQUIREMENT_TYPE[0].id) ? (
                      <div className="kb-row">
                        <label style={{ display: 'inline-block', width: 56 }} />
                        {rentType &&
                          rentType.map(data => {
                            return (
                              <span
                                onClick={this.changeRentType.bind(
                                  null,
                                  data.id
                                )}
                                style={{ marginRight: 20 }}
                              >
                                <span
                                  className={`radio ${
                                    selectedRentType == data.id
                                      ? 'radio-active'
                                      : ''
                                  }`}
                                ></span>
                                <span
                                  style={{ color: '#666', cursor: 'pointer' }}
                                >
                                  {data.name}
                                </span>
                              </span>
                            )
                          })}
                      </div>
                    ) : null}

                    {rentIntentType.includes(REQUIREMENT_TYPE[0].id) ? (
                      <div className="kb-row-s">
                        {selectedRentType == rentType[1].id ? (
                          <KBSelect
                            defaultValue="-1"
                            className="m-right-sm"
                            title="入驻人数"
                            field={employee_number}
                            arrayData={employeeNumbersArry}
                          />
                        ) : null}
                        {selectedRentType == rentType[0].id ? (
                          <KBSelect
                            defaultValue="-1"
                            className="m-right-sm"
                            title="租赁面积"
                            field={rentable_area}
                            arrayData={CRM_INTENT_RENT_AREA_SIZE}
                          />
                        ) : null}
                        <KBSelect
                          defaultValue="-1"
                          title="入驻月数"
                          field={employee_months}
                          arrayData={employee_monthsArray}
                        />
                      </div>
                    ) : null}

                    {rentIntentType.includes(REQUIREMENT_TYPE[1].id) ? (
                      <div className="kb-row-m">
                        <KBSelect
                          defaultValue="-1"
                          title="容纳人数"
                          field={area_capacity}
                          arrayData={employeeNumbersArry}
                        />
                      </div>
                    ) : null}

                    {rentIntentType.includes(REQUIREMENT_TYPE[2].id) ? (
                      <div className="kb-row-e">
                        <KBTextarea
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          field={requirement_text}
                          title="具体信息"
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {showCusInfo.includes(update_type) ? (
            <div className="kb-row">
              <KBSelect
                isRequired={true}
                title="客户来源"
                {...oneClass}
                field={source}
                arrayData={sources}
              />
            </div>
          ) : null}
          {showCusInfo.includes(update_type) ? (
            <div className="kb-row">
              <KBInput
                isRequired={userSpaceId == 6 ? true : false}
                title="具体来源"
                {...oneClass}
                field={source_text}
              />
            </div>
          ) : null}
          {showAll.includes(update_type) ? (
            <div className="kb-row">
              <KBSelect
                title="入驻意愿"
                {...oneClass}
                field={desire}
                arrayData={CRM_DESIRE}
              />
            </div>
          ) : null}
          {showAll.includes(update_type) ? (
            <div className="kb-row">
              <KBSelect
                isRequired={true}
                hint={hint.market_stage}
                title="销售阶段"
                {...oneClass}
                field={state}
                arrayData={chanceState}
              />
            </div>
          ) : null}
          {showAll.includes(update_type) && changeState !== 'update' ? (
            <div className="kb-row">
              <KBTextarea
                title="销售记录"
                hint={hint.market_record}
                {...oneClass}
                field={content}
              />
            </div>
          ) : null}
          {showAll.includes(update_type) ? (
            <fieldset className="fieldset">
              <legend>销售团队</legend>
              <p style={{ color: '#c8c8c8', fontSize: 12, marginBottom: 20 }}>
                {hint.market_team}
              </p>
              <div className="kb-row">
                <KBUserInput
                  isRequired={true}
                  hint={hint.principal}
                  title="执行人"
                  {...oneClass}
                  field={sales_leader}
                  multi={false}
                  users={this.props.users}
                  selectedUsers={oppMasters}
                />
              </div>
              <div className="kb-row">
                <KBUserInput
                  title="团队人员"
                  hint={hint.team_member}
                  {...oneClass}
                  field={salesmen}
                  multi={true}
                  users={this.props.users}
                  selectedUsers={oppUsers}
                />
              </div>
              <div className="kb-row">
                <KBTextarea
                  hint={hint.remark}
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={notes}
                  title="备注"
                />
              </div>
            </fieldset>
          ) : null}
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              保存
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function _getUsers(state, props) {
  let user = selectors.getCurrentUser(state)
  if (props.state != 'update') {
    return {
      oppUsers: [user],
      oppMasters: [user]
    }
  }

  let master =
    _.find(props.opp.sales_team, team => {
      return team.is_leader
    }).sales_user || []

  let users = props.opp.sales_team.map(team => {
    if (team.is_leader != true) {
      return team.sales_user
    }
  })

  const { interest_locations, locations } = props.opp

  let interested_loc_ids = []
  interest_locations &&
    interest_locations.length &&
    interest_locations.map(loc => {
      if (!loc.visit_date) {
        interested_loc_ids.push(loc.location_id)
      }
    })
  interested_loc_ids = _.uniq(interested_loc_ids)
  let interested_locs = _.uniqBy(locations, 'id')
  interested_locs = interested_locs.filter(loc => {
    return interested_loc_ids.includes(loc.id)
  })

  return {
    oppLocations: interested_locs,
    oppMasters: [master],
    oppUsers: users
  }
}

function mapStateToProps(state, props) {
  const { user, entities } = state
  const userSpaceId = user.space
  let users = selectors.getSpaceUsers(state)
  const industriesObj = checkObject(entities, 'industries')
  const industries = (industriesObj && Object.values(industriesObj)) || []

  let sources = selectors.getCrmSources(state)
  let locations = selectors.getSerializeLocation(state)
  let obj = _getUsers(state, props)
  let employeeNumbersArry = CRM_EMPLOYEE_NUMBER(1, 50)
  let employee_monthsArray = CRM_EMPLOYEE_NUMBER(1, 24)

  var params = {
    users,
    sources,
    industries,
    locations,
    ...obj,
    employeeNumbersArry,
    employee_monthsArray,
    userSpaceId
  }

  if (props.state != 'update') {
    params.initialValues = {
      state: CRM_OPPORTUNITY_STATES[0].id,
      source: sources[0].id,
      area_type: CRM_AREA_TYPE[0].id,
      company_type: CRM_COMPANY_TYPE[0].id,
      desire: CRM_DESIRE[0].id,
      employee_number: 1,
      employee_months: 1,
      user_space_id: userSpaceId
    }
  }

  return params
}

function mapDispatchToProps(dispatch) {
  return {
    createOpportunity: bindActionCreators(
      opportunitiesActions.create,
      dispatch
    ),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    updateOpportunity: bindActionCreators(
      opportunitiesActions.update,
      dispatch
    ),
    allIndustries: bindActionCreators(industriesActions.all, dispatch),
    successActions: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入客户姓名'
  }

  if (valid.required(values.contact_name)) {
    errors.contact_name = '请输入联系人姓名'
  }

  if (valid.integer(values.employee_number)) {
    errors.employee_number = '请输入数值'
  }

  if (valid.integer(values.employee_months)) {
    errors.employee_months = '请输入数值'
  }

  // if(values.user_space_id == 6 && valid.required(values.source_text)){
  //   errors.source_text = '请输入客户具体来源'
  // }

  return errors
}

const formConfig = {
  form: 'ChanceForm',
  fields: [
    'name',
    'company',
    'source',
    'content',
    'wechat',
    'employee_months',
    'user_space_id',
    'industry_id',
    'source_text',
    'company_type',
    'area_types',
    'contact_name',
    'mobile',
    'agent_mobile',
    'email',
    'address',
    'desire',
    'gender',
    'only_contact',
    'number',
    'level',
    'source',
    'title',
    'state',
    'expected_date',
    'customer_type',
    'location_ids',
    'employee_number',
    'area_capacity',
    'total_number',
    'sales_leader',
    'salesmen',
    'notes',
    'requirement_type',
    'requirement_text',
    'rentable_area',
    'rent_type'
  ],
  validate: validate,
  touchOnBlur: false
}

OpportunityForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OpportunityForm)

export default OpportunityForm
