import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import {
  KBInput,
  KBTextarea,
  KBFormError,
  KBButton,
  KBDateInput,
  KBPopover,
  KBForm,
  KBUserInput
} from 'components'
import moment from 'moment'

var InvoicesApplicationFinishForm = React.createClass({
  getInitialState() {
    return {
      invoicingPools: []
    }
  },

  componentDidMount() {
    this.getAllInvoicingPools()
  },

  getAllInvoicingPools() {
    const { location_id } = this.props

    let params = {
      page: 1,
      per_page: 500
    }

    if (location_id) {
      params.location_id = location_id
    } else {
      delete params.location_id
    }

    api.getInvoicingPools(params).then(
      json => {
        let invoicingPools = json.response.body
        this.setState({
          invoicingPools
        })
      },
      error => {
        console.log(error)
      }
    )
  },

  finishInvoiceApp(model) {
    const {
      locIdGroupedInvoices,
      callback,
      isSingle,
      id,
      location_id,
      singleCallback
    } = this.props

    if (!model.invoicing_pool_id) {
      delete model.invoicing_pool_id
    }

    if (isSingle) {
      return api.finishInvoiceApplication(id, { location_id, ...model }).then(
        json => {
          singleCallback && singleCallback()
          KBPopover.close()
        },
        errors => {
          KBPopover.close()
        }
      )
    }

    locIdGroupedInvoices &&
      locIdGroupedInvoices.map(json => {
        api.finishInvoiceApplicationBatch({
          location_id: json.loc_id,
          ids: json.ids.join(','),
          ...model
        })
      })
    callback && callback()
  },

  render() {
    const {
      fields: { invoice_date, invoice_number, notes, invoicing_pool_id },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { invoicingPools } = this.state

    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.finishInvoiceApp)}
        fields={this.props.fields}
      >
        <KBFormError error={error} />
        <header className="kb-form-header">
          <h2 className="header-title">完成开票</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container-m">
          <div className="kb-row">
            <KBDateInput
              field={invoice_date}
              title="开票日期"
              className="kb-form-group kb-form-c18 kb-form-alone"
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="发票库"
              placeholder={'请选择发票库'}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={invoicing_pool_id}
              selectedUsers={[]}
              multi={false}
              users={invoicingPools}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={invoice_number}
              title="发票号"
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={notes}
              title="备注"
            />
          </div>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state) {
  return {
    initialValues: {
      invoice_date: moment().format('YYYY-MM-DD')
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  if (valid.required(values.invoice_number)) {
    errors.invoice_number = '请输入发票号'
  }

  return errors
}

const formConfig = {
  form: 'InvoicesApplicationFinishForm',
  fields: ['invoice_date', 'invoice_number', 'invoicing_pool_id', 'notes'],
  validate: validate,
  touchOnBlur: false
}

InvoicesApplicationFinishForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoicesApplicationFinishForm)

export default InvoicesApplicationFinishForm
