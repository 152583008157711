import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm, getValues } from 'redux-form'
import { areaActions } from 'app/actions'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import DeskNumber from 'components/views/DeskNumber'
import { OFFICE_AREA_STATE } from 'app/constants'
import { KBFormError, KBPopover, KBButton, KBInput } from 'components'

var AddDeskForm = React.createClass({
  createDeskForm(model) {
    const { area_id, createDesksAction, setLoadDesk } = this.props
    return api.addDesks(area_id, model).then(json => {
      createDesksAction.success(json)
      setLoadDesk && setLoadDesk()
      KBPopover.close()
      return true
    })
  },

  render() {
    const {
      fields: { desk_serial_numbers, desk_count },
      error,
      submitting,
      handleSubmit,
      values,
      loc_id,
      type
    } = this.props
    return (
      <form ref="areaForm" onSubmit={handleSubmit(this.createDeskForm)}>
        <KBFormError err={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">添加工位</h2>
          <i
            className="header-close iconfont icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={desk_count}
              title="工位数"
            />
          </div>
          <DeskNumber
            loc_id={loc_id}
            number={desk_count.value}
            field={desk_serial_numbers}
            error={desk_serial_numbers.touched && desk_serial_numbers.error}
          />
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              添加
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

AddDeskForm.propTypes = {
  area_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  loc_id: PropTypes.string.isRequired
}

function mapStateToProps(state, props) {
  return {
    initialValues: {
      desk_count: 10
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createDesksAction: bindActionCreators(areaActions.create, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.desk_serial_numbers)) {
    errors.desk_serial_numbers = '请输入工位'
  }

  return errors
}

const formConfig = {
  form: 'AddDeskForm',
  fields: ['desk_serial_numbers', 'desk_count', ],
  validate: validate,
  touchOnBlur: false
}

AddDeskForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(AddDeskForm)

export default AddDeskForm
